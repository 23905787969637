import { Card, Grid } from "@mui/material";
import React, { useState } from "react";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";

const DeptTeam = ({ departmentId }) => {
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [deptTeam, setDeptTeam] = useState([])
  const getApi = () => {
    apiService(`department/team/list?departmentId=${departmentId}`, "", "get")
      .then((result) => {
        if (result && result.data && result.data.teams) {
          var team = result.data.teams
          team.forEach(element => {
            element.email = cryptodecryptJS(element.email)
          });
          setDeptTeam(team)
        }
      })
      .catch((err) => { });
  };
  return (
    <div className="team-card-dept">
      {
        deptTeam.length > 0 ? (
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="start"
            spacing={3}
          >
            {deptTeam.map((data) => (
              <Grid key={data.id} item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Card className="card-team-dept">
                  <div className="img-team-div">
                    <img src={data.image ? data.image : '/images/user.png'} alt="team" className="comp-logo-teams" />
                  </div>
                  <div className="card-team-details">
                    <h6>{data.name}</h6>
                    <p>{data.designation}</p>
                    <span className="mail-text-dep">Mail ID :</span>
                    <p className="mail-id-dept">{data.email}</p>
                  </div>
                </Card>
              </Grid>
            ))}

          </Grid>
        ) : (
          <div className='text-center p-3'>
            <h5 className='mb-4'>Team data not found</h5>
            <div>
              <img src="/images/onetn/Frame (2).png" alt="blue horizontal.png" className='img-fluid' />
            </div>
          </div>
        )
      }

    </div>
  );
};

export default DeptTeam;
