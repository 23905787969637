import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useContext } from 'react';
import notifyService from '../../api/notifySerivce';
import DataContext from '../../context/DataContext';
import useDidMountEffect from '../../hooks/useDidMountEffect';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
const Captchatext = () => {
    const { captchaValue,setCaptchaValue, captcha, setCaptcha, inputValue, setInputValue, generateCaptcha } = useContext(DataContext)

    const [errorMessage, setErrorMessage] = useState('');

    useDidMountEffect(() => {
        setCaptchaValue(null)
        generateCaptcha();
    }, []);



    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (value.length === captcha.length) {
            if (value === captcha) {
                setErrorMessage('');
                // notifyService("success", "Success", "Captcha Verified Successfully, Now You may Login");
                setCaptchaValue(true)
            } else {
                setErrorMessage('Try Again');
                setInputValue('');
                generateCaptcha();
                // notifyService('danger', 'Invalid Captcha')
                setCaptchaValue(false)
            }
        } else {
            setErrorMessage('');
        }
    };

    const handleInputFocus = () => {
        if (inputValue === 'Type CAPTCHA Here') {
            setInputValue('');
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
    };

    return (

        <div className="box-capt">
            <div className="captcha_box" >
                <div className='capimg-divs'>
                    <div className="captcha-text" unselectable="on">{captcha}</div>
                    <div className="reload" onClick={generateCaptcha}><img src={'/images/icon/refresh.png'} alt="refresh-icon" className='ref-imag' /></div>
                </div>
                <div className='inp-but'>
                    <TextField fullwidth size="small" value={inputValue} onChange={handleInputChange} onFocus={handleInputFocus}
                        onPaste={handlePaste} placeholder="Enter Captcha Here" inputProps={{ maxLength: 6 }} error={Boolean(errorMessage)}
                        InputProps={{
                            endAdornment: captchaValue === true ? (
                                <InputAdornment position="end">
                                    <CheckCircleIcon color="success" />
                                </InputAdornment>
                            ) : captchaValue === false ? (
                                <InputAdornment position="end">
                                    <CloseIcon color="error" />
                                </InputAdornment>
                            ) : null,
                        }} />
                </div>
            </div>
        </div>
    );
};

export default Captchatext;
