import { Autocomplete, FormControl, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import DataContext from '../../../../context/DataContext'
import apiService from '../../../../api/apiService'
import notifyService from '../../../../api/notifySerivce'

const AddressEcosystem = () => {
    const { navigator, validateNumberonly, setPageLoading, handleNextClick, isWhitespace } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors }, } = useForm({ values: formData });
    const roleval = localStorage.getItem('role')
    useDidMountEffect(() => {
        handleNextClick(70)
        getApi()
        dropdownApi()
        statesdropdownApi()
        countrydropdownApi()
    }, [])
    const [district, setDistrict] = useState([])
    const [country, setCountry] = useState([])
    const [states, setStates] = useState([])
    const dropdownApi = () => {
        apiService(`home/district/list?hubId=${0}`, "", "get")
            .then((result) => {

                if (result) {
                    if (result.data) {
                        if (result.data.districts) {
                            var values = result.data.districts;
                            setDistrict(values);
                        }
                    }
                }
            })
            .catch((err) => { });
    };
    const countrydropdownApi = () => {
        apiService(`admin/dropdownvalues/get?key=${'country'}`, '', 'unauthget').then((result) => {
            if (result) {
                if (result.data) {
                    if (result.data.dropDownValues) {
                        var dropDown = result.data.dropDownValues
                        setCountry(dropDown.values)

                    }
                }
            }
        }).catch((err) => {
        });
    }
    const statesdropdownApi = () => {
        apiService(`admin/dropdownvalues/get?key=${'states'}`, '', 'unauthget').then((result) => {
            if (result) {
                if (result.data) {
                    if (result.data.dropDownValues) {
                        var dropDown = result.data.dropDownValues
                        setStates(dropDown.values)
                    }
                }
            }
        }).catch((err) => {
        });
    }
    const getApi = () => {
        setPageLoading(true)
        var id = localStorage.getItem("id");
        var type = localStorage.getItem('type')
        apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    var data = result.data;
                    data.country = data.country ? data.country : "India"
                    data.state = data.state ? data.state : "Tamil Nadu"
                    setFormData(data);
                }
            })
            .catch((err) => { });
        // var id = localStorage.getItem('id')
        // if (roleval === 'INCUBATOR' || roleval === 'ACCELARATOR') {
        //     apiService(`incubacc/get?id=${id}`, '', 'get').then((result) => {
        //         if (result && result.data) {
        //             var data = result.data
        //             // data.state = ''
        //             // data.country = ''
        //             setFormData(data)
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'MEDIA-AGENCY' || roleval === 'BLOGGER-INFLUENCER' || roleval === 'NGO') {
        //     apiService(`mediablogngo/get?id=${id}`, '', 'get').then((result) => {
        //         if (result && result.data) {
        //             var data = result.data
        //             // data.state = ''
        //             // data.country = ''
        //             setFormData(data)
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'GOVT-AGENCY') {
        //     apiService(`govermentagencies/get?id=${id}`, '', 'get').then((result) => {
        //         if (result && result.data) {
        //             var data = result.data
        //             // data.state = ''
        //             // data.country = ''
        //             setFormData(data)
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'BANK') {
        //     apiService(`bank/get?id=${id}`, '', 'get').then((result) => {
        //         if (result && result.data) {
        //             var data = result.data
        //             // data.state = ''
        //             // data.country = ''
        //             setFormData(data)
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'CORPORATE') {
        //     apiService(`corporates/get?id=${id}`, '', 'get').then((result) => {
        //         if (result && result.data) {
        //             var data = result.data
        //             // data.state = ''
        //             // data.country = ''
        //             setFormData(data)
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'BUSINESS-SOCIAL-FORM') {
        //     apiService(`busssocial/get?id=${id}`, '', 'get').then((result) => {
        //         if (result && result.data) {
        //             var data = result.data
        //             // data.state = ''
        //             // data.country = ''
        //             setFormData(data)
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'EDU-INST') {
        //     apiService(`eduinstitute/get?id=${id}`, '', 'get').then((result) => {
        //         if (result && result.data) {
        //             var data = result.data
        //             // data.state = ''
        //             // data.country = ''
        //             setFormData(data)
        //         }
        //     }).catch((err) => {
        //     });
        // }
    }
    const handleSelectDistrict = (value) => {
        setValue("district", value);
        clearErrors("district");
    };

    const handleSelectCountryType = (value) => {
        setValue("country", value);
        clearErrors("country");

        setValue("otherCountry", "");
        clearErrors("otherCountry");

        if (value === "India") {
            setValue("state", "Tamil Nadu");
        } else {
            setValue("state", "");
            clearErrors("state");
        }

        setValue("district", "");
        clearErrors("district");

        setValue("pincode", "")
        clearErrors("pincode");
    }

    const handleSelectState = (value) => {
        setValue("state", value);
        clearErrors("state");
        setValue("district", "")
        clearErrors("district");
        setValue("pincode", "")
        clearErrors("pincode");
    };

    const onSubmit = (data) => {
        console.log('Form Data:', data);
        if (data.country === 'India') {
            if (data.state === "Tamil Nadu") {
                const districtId = district.filter(val => val.name === data.district);
                data.districtId = districtId[0].id.toString()
            } else {
                data.districtId = 0;
            }
        } else {
            data.districtId = 0;
        }

        apiService("userprofile/save", data, "post").then((result) => {
            setPageLoading(false)
            if (result && result.data && result.data.responseStatus === "Success") {
                navigator("/ecosystem/social-links");
            }
        })
            .catch((err) => { });

        // if (roleval === 'INCUBATOR' || roleval === 'ACCELARATOR') {
        //     apiService('incubacc/save', data, 'post').then((result) => {
        //         if (result.data.responseStatus === 'Success') {


        //             navigator('/ecosystem/social-links')
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'MEDIA-AGENCY' || roleval === 'BLOGGER-INFLUENCER' || roleval === 'NGO') {
        //     apiService('mediablogngo/save', data, 'post').then((result) => {
        //         if (result.data.responseStatus === 'Success') {


        //             navigator('/ecosystem/social-links')
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'GOVT-AGENCY') {
        //     apiService('govermentagencies/save', data, 'post').then((result) => {
        //         if (result.data.responseStatus === 'Success') {


        //             navigator('/ecosystem/social-links')
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'BANK') {
        //     apiService('bank/save', data, 'post').then((result) => {
        //         if (result.data.responseStatus === 'Success') {


        //             navigator('/ecosystem/social-links')
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'CORPORATE') {
        //     apiService('corporates/save', data, 'post').then((result) => {
        //         if (result.data.responseStatus === 'Success') {


        //             navigator('/ecosystem/social-links')
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'BUSINESS-SOCIAL-FORM') {
        //     apiService('busssocial/save', data, 'post').then((result) => {
        //         if (result.data.responseStatus === 'Success') {


        //             navigator('/ecosystem/social-links')
        //         }
        //     }).catch((err) => {
        //     });
        // } else if (roleval === 'EDU-INST') {
        //     apiService('eduinstitute/save', data, 'post').then((result) => {
        //         if (result.data.responseStatus === 'Success') {


        //             navigator('/ecosystem/social-links')
        //         }
        //     }).catch((err) => {
        //     });
        // }
    };
    const onError = (event) => {
        console.log('error Data:', event);
        notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
    }


    const validatePincode = (value) => {
        // if (value === '000000') {
        //     return 'Pincode cannot be six zeros';
        // }
        if (/^(\d)\1{5}$/.test(value)) {
            return 'Pincode cannot be a sequence of six identical digits';
        }
        return true;
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='signup-data_div'>
                        <div className='forms-title d-flex'>
                            <p>Organization Info {`>`} <span className='path_sapn'>Address</span></p>
                            <p className='ml-auto'><span className='count_num'>2</span>/4</p>
                        </div>
                        <div className='signup_box_div mt-4'>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <Grid container spacing={1}>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                        <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                                name="country"
                                                control={control}
                                                defaultValue="India"
                                                rules={{ required: "Country is required" }}
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        {...field}
                                                        options={country}
                                                        onChange={(e, selectedOptions) => handleSelectCountryType(selectedOptions)}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Country *" error={Boolean(errors.country && errors.country)} />
                                                        )}
                                                    // disabled={readyOnlyValue}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.country && errors.country.message}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {
                                        getValues('country') === "India" ? (
                                            <>
                                                <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <Controller
                                                            name="state"
                                                            control={control}
                                                            defaultValue="Tamil Nadu"
                                                            rules={{ required: "State is required" }}
                                                            render={({ field }) => (
                                                                <Autocomplete
                                                                    disableClearable
                                                                    disablePortal
                                                                    {...field}
                                                                    // limitTags={2}
                                                                    options={states}
                                                                    onChange={(e, selectedOptions) =>
                                                                        handleSelectState(selectedOptions)
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label="State *" error={Boolean(errors.state && errors.state)} />
                                                                    )}
                                                                // disabled={readyOnlyValue}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText className="text-danger">
                                                            {errors.state && errors.state.message}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    {
                                        getValues('country') === "Others" ? (
                                            <>
                                                <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <Controller
                                                            name="otherCountry"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: false }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    variant="outlined"
                                                                    label="Country Name*"
                                                                    placeholder="Enter Country Name"
                                                                    fullWidth
                                                                    // inputProps={{ readOnly: readyOnlyValue }}
                                                                    error={Boolean(errors.otherCountry && errors.otherCountry)}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText className="text-danger">
                                                            {errors.otherCountry && errors.otherCountry.message}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <Controller
                                                            name="state"
                                                            control={control}
                                                            defaultValue=""
                                                            // rules={{ required: "State is required" }}
                                                            rules={{
                                                                required: false,
                                                                //  validate: {
                                                                //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                                // },
                                                            }}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    variant="outlined"
                                                                    label="State "
                                                                    placeholder="Enter State"
                                                                    fullWidth
                                                                    // inputProps={{ readOnly: readyOnlyValue }}
                                                                    error={Boolean(errors.state && errors.state)}

                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText className="text-danger">
                                                            {errors.state && errors.state.message}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        getValues("country") === "India" && getValues('state') === "Tamil Nadu" ? (
                                            <>
                                                <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <Controller
                                                            name="district"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: true }}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <Autocomplete
                                                                    {...field}
                                                                    disablePortal
                                                                    disableClearable
                                                                    id="combo-box-demo"
                                                                    options={district.map((option) => option.name)}
                                                                    // getOptionLabel={(option) => option.name}
                                                                    onChange={(e, selectedOption) =>
                                                                        handleSelectDistrict(selectedOption)
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="District*"
                                                                            error={!!error}
                                                                            helperText={
                                                                                error ? "District is required" : ""
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText className="text-danger">
                                                            {errors.district && errors.district.message}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )
                                    }
                                    {
                                        getValues('state') !== "Tamil Nadu" && getValues('country') === "India" ? (
                                            <>
                                                <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                    <Controller
                                                        name="district"
                                                        control={control}
                                                        defaultValue=""
                                                        // rules={{ required: "District is required" }}
                                                        rules={{
                                                            required: false, 
                                                            // validate: {
                                                            //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                            // },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label="District *"
                                                                placeholder="Enter District"
                                                                fullWidth
                                                                type="text"
                                                                {...field}
                                                                error={Boolean(errors.district && errors.district)}

                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText className="text-danger">
                                                        {errors.district && errors.district.message}
                                                    </FormHelperText>
                                                </Grid>
                                            </>) : (<>

                                            </>)
                                    }
                                    {
                                        getValues('country') === "India" ? (
                                            <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                <Controller
                                                    name="pincode"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: false,
                                                         validate: validatePincode }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Pincode "
                                                            placeholder="Enter Pincode"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            onKeyDown={(e) => validateNumberonly(e)}
                                                            inputProps={
                                                                { maxLength: 6, minLength: 6 }
                                                            }
                                                            error={Boolean(errors.pincode && errors.pincode)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.pincode && errors.pincode.message}
                                                </FormHelperText>
                                            </Grid>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        getValues('country') === "Others" ? (
                                            <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                                <Controller
                                                    name="pincode"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: false }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            variant="outlined"
                                                            label="Zip Code *"
                                                            placeholder="Enter Zip Code"
                                                            fullWidth
                                                            type="text"
                                                            {...field}
                                                            onKeyDown={(e) => validateNumberonly(e)}
                                                            inputProps={
                                                                { maxLength: 11, minLength: 5 }
                                                            }
                                                            error={Boolean(errors.pincode && errors.pincode)}
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.pincode && errors.pincode.message}
                                                </FormHelperText>
                                            </Grid>) : (<></>)
                                    }

                                    <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                                        <Controller
                                            name="address"
                                            control={control}
                                            defaultValue=""
                                            // rules={{ required: "Address is required" }}
                                            rules={{
                                                required: false,
                                                //  validate: {
                                                //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                                // },
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    label="Address *"
                                                    placeholder="Enter Address"
                                                    fullWidth
                                                    type="text"
                                                    {...field}
                                                    error={Boolean(errors.address && errors.address)}
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.address && errors.address.message}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                                        <button className='next-btn' type="submit">Next</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default AddressEcosystem
