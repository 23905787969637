import { Button, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";
import { BsFillRocketFill } from "react-icons/bs";
import DataContext from '../../../context/DataContext';
const BorderLinearProgress = styled("div")(({ theme }) => ({
  height: 500,
  width: 20,
  backgroundColor: 'none',
  position: "relative",
  borderRadius: 20,
  overflow: "hidden",
  border: "2px solid #ccc",
  display: "flex",
  flexDirection: "column-reverse",
}));

const ProgressFill = styled("div")(({ theme, value }) => ({
  width: "75%",
  backgroundColor: theme.palette.mode === "light" ? "#083FB2" : "#308fe8",
  position: "absolute",
  bottom: 0,
  height: `${value}%`,
  transition: "height 0.5s ease-in-out",
  borderRadius: 20,
  margin: '2px'
}));

const IconWrapper = styled("div")(({ value }) => ({
  position: "absolute",
  bottom: `${value - 5}%`,
  left: "10px",
  transform: "translate(-50%, 0)",
  transition: "bottom 0.5s ease-in-out",
}));
const Signuplayout = () => {
  const { navigator, progress, handleHomePage } = useContext(DataContext)

  const backBtn = () => {
    // navigator('profile')
    window.history.back();
  }
  let role = localStorage.getItem('role')
  let profile = localStorage.getItem('type')

  const location = useLocation();
  const hideBackButtonPages = ['/sign-up/info', '/sign-up/verify', '/startups/basicinfo', '/aspirants/basicinfo', '/coreteam/basicinfo', '/ecosystem/basicinfo', '/innovators/basicinfo', '/investor/basicinfo', '/mentors/basicinfo', '/service-provider/basicinfo', ];

  const shouldHideBackButton = hideBackButtonPages.includes(location.pathname);

  return (
    <div >
      <div className='signup-details-bg-dev'>
        <img src='/images/oneTn/new_logo.png' alt='' className='startup_img img-fluid' onClick={handleHomePage} />
        {/* <img src="./images/TamilNadu_Logo.svg" alt="" className="img-fluid startup_img2" onClick={handleHomePage}/> */}
      </div>
      <Grid container spacing={2} className='p-4'>
        <Grid item xs={12}>
          <div className='pt-5 ml-5 '>
            {!shouldHideBackButton && (
              <Button style={{fontWeight:"700"}} startIcon={<ArrowBackIcon />} onClick={backBtn}>Back</Button>
            )}
          </div>
        </Grid>
        <Grid item lg={4} md={4} sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
          <div className='raket-pos'>
            <Container style={{ marginTop: '20px', display: 'flex' }}>
              <div>
                {/* , border: '1px solid darkgrey', padding: '10px', width: '42.2px', borderRadius: '20px' */}
                <div style={{ position: "relative" }}>
                  <BorderLinearProgress>
                    <ProgressFill value={progress} />
                  </BorderLinearProgress>
                  <IconWrapper value={progress}>
                    <img src='/images/startup-white2.png' className='raket_logo' alt='' />
                  </IconWrapper>
                </div>
                <div style={{ marginTop: '10px' }}>

                </div>
              </div>
              <div className='w-100'>
                <div className='level_info'>
                  {
                    (
                      profile === 'STARTUP' || profile === 'STARTUPS-SERVPROV' ||
                      (profile === 'STARTUP-ECO' && (role === 'NGO' || role === 'BLOGGER-INFLUENCER' || role === 'MEDIA-AGENCY')) ||
                      (profile === 'INNOVATOR' && role === 'RANDD-AGENCY')
                    ) && (
                      <>
                        <p className={progress > 99 ? 'progreeStatus' : ''}>Team Info</p>
                        <p className={progress > 75 ? 'progreeStatus' : ''}>Organization Info</p>
                        <p className={progress > 10 ? 'progreeStatus' : ''}>Sign Up</p>
                      </>
                    )
                  }
                  {
                    (
                      profile === 'ASPIRANT' ||
                      profile === 'MENTOR' ||
                      profile === 'INVESTOR' ||
                      (profile === 'STARTUP-ECO' && (role !== 'NGO' && role !== 'BLOGGER-INFLUENCER' && role !== 'MEDIA-AGENCY')) ||
                      (profile === 'INNOVATOR' && role !== 'RANDD-AGENCY')
                    ) && (
                      <>
                        <p className={progress > 99 ? 'progreeStatus' : ''}>Organization Info</p>
                        <p></p>
                        <p className={progress > 10 ? 'progreeStatus' : ''}>Sign Up</p>
                      </>
                    )
                  }
                </div>
              </div>
            </Container>
          </div>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <Outlet></Outlet>
        </Grid>
        <Grid item lg={4} md={2} sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
        </Grid>
      </Grid>

    </div>
  )
}

export default Signuplayout