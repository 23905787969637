import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Edtech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/StartupTN-Edtech.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
        <h3 className="banner-title-sector">Ed Tech</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4><br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5><br />
                  <ul>
                    <li>NEP 2020: In 2020, the Union Government introduced the New Education Policy (NEP 2020) to promote inclusive, quality-based education, learning, and development in the Indian education sectors. It emphasises the use of EdTech to advance education, particularly in rural regions. NEP lays its accent on more skilling, especially in 21st-century learning skills, helping Startups in skilling, and K12 gain more prominence, STEM-based education and gamified learning and educational toys have also gained traction.</li><br />
                    <li>DIKSHA: Digital Infrastructure for Knowledge Sharing is a nationwide platform for school education launched in 2017 to provide students, instructors and parents with curriculum-based engaging learning materials. The portal supports more than 18 Indian languages.</li><br />
                    <li>PM eVIDYA: In order to promote and develop digital education in India, PM eVIDYA was launched in 2020 to make e-learning more accessible for Indian students and teachers. The scheme helps pupils by bringing together all online and digital education-related activities.The programme includes creation of original digital content for students who are visually challenged or have hearing impairment as well as providing radio/podcasts and QR-coded digital textbooks for students of class one to twelve via the DIKSHA portal.</li><br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4><br />
                  <p style={{ lineHeight: '30px' }}>For the Ed Tech sector to thrive and grow in Tamil Nadu, several critical resources are available abundantly. Some are:</p>

                  <p style={{ lineHeight: '30px' }}><b>Manpower</b></p>
                  <p>Tamil Nadu is home to a large pool of skilled professionals, including engineers, IT experts, educators and content creators. The availability of this talent pool makes it relatively easier to find the necessary manpower.</p>

                  <p style={{ lineHeight: '30px' }}><b>Materials</b></p>
                  <p>The State has a well-developed IT and technology ecosystem, which includes various companies and service providers specialising in content creation, software development and e-learning solutions. The availability of materials is generally good.</p>

                  <p style={{ lineHeight: '30px' }}><b>Capital</b></p>
                  <p>Tamil Nadu has a thriving Startup ecosystem and access to venture capital firms, angel investors and government schemes that support entrepreneurship. Raising capital can be facilitated through various channels within the State.</p>


                  <p style={{ lineHeight: '30px' }}><b>Infrastructure</b></p>
                  <p>The state has a robust IT infrastructure with technology parks, data centres and connectivity options. This makes it conducive for the establishment and growth of EdTech Startups.</p>

                  <p style={{ lineHeight: '30px' }}><b>Other Assets</b></p>
                  <p>Tamil Nadu has a well-established education system with numerous universities, colleges and schools. This provides opportunities for collaborations and partnerships within the education sector.</p>
                  <p>However, it is important to note that the specifics may change over time due to economic, technological and regulatory factors. It is advisable to conduct a thorough market research and consult with local industry experts or organisations for the most up-to-date information.</p>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Edtech
