import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import SmartCardPage from './smart-card-page/SmartCardPage'
import SupportSmartCardService from './smart-card-page/SupportSmartCardService'
import AboutCompany from './smart-card-page/AboutCompany'
import SmartCardLayout from './SmartCardLayout'
import SmartProfileLayout from './smart-profile-page/SmartProfileLayout'
import SmartProfilePage from './smart-profile-page/smart-card-profile-details/SmartProfilePage'
import SmartProductList from './smart-profile-page/smart-card-product-details/SmartProductList'
import SmartRequestList from './smart-profile-page/smart-card-service-details/SmartRequestList'
import SmartRateReviewPage from './smart-profile-page/smart-card-rate-review-details/SmartRateReviewPage'
import Qrscanner from './Qrscanner'

const SmartCardRoute = () => {
    return (
        <div>
            <Routes>
                <Route path="/smartcard" element={<SmartCardLayout />}>
                    <Route path="/smartcard" element={<Navigate replace to="/smartcard/home" />} />
                    <Route path="/smartcard/home" element={<SmartCardPage name={'home'} />} />
                    <Route path="/smartcard/service" element={<SupportSmartCardService name={'home'} />} />
                    <Route path="/smartcard/aboutCompany/:id" element={<AboutCompany name={'home'} />} />
                    <Route path="/smartcard/:id" element={<SmartProfileLayout />}>
                        <Route path="/smartcard/:id" element={<Navigate replace to="/smartcard/:id/profile" />} />
                        <Route path="/smartcard/:id/profile" element={<SmartProfilePage />} />
                        <Route path="/smartcard/:id/product" element={<SmartProductList />} />
                        <Route path="/smartcard/:id/service" element={<SmartRequestList />} />
                        <Route path="/smartcard/:id/rate-review" element={<SmartRateReviewPage />} />
                    </Route>
                    
                </Route>
                <Route path="/qrscanner" element={<Qrscanner />} />
            </Routes>
        </div>
    )
}

export default SmartCardRoute