import { Autocomplete, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useContext, useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from '../../../../api/notifySerivce';
const MoreinfoServiceProvider = () => {
  const { navigator,handleNextClick} = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {handleSubmit,control,formState: { errors }} = useForm({ values: formData });
  const [natureEntities, setNatureEntities] = useState([])
  useDidMountEffect(() => {
    handleNextClick(80);
      dropdownApi()
      sectordropdownApi()
      entitiesdropdownApi()
      stagesdropdownApi()
      industriesdropdownApi()
  }, []);
  useDidMountEffect(() => {
      getApi()
  }, []);
  const getApi = () => {
    var id = localStorage.getItem('id')
    var type = localStorage.getItem('type') 
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
        }
      })
      .catch((err) => {});
  };
  const [support, setSupport] = useState([]);
  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"support"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setSupport(dropDown.values);
            }
          }
        }
      })
      .catch((err) => {});
  };
  const [sectors, setSectors] = useState([]);
  const sectordropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => { 
        if (result) {
          if (result.data) {
            if (result.data) {
              var values = result.data.sectors;
              setSectors(values);
              
            }
          }
        }
      })
      .catch((err) => {});
  };
  const entitiesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"entities"}`, "", "unauthget")
      .then((result) => {
        
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;            
              setNatureEntities(dropDown.values)
            }
          }
        }
      })
      .catch((err) => {});
  };
  const [startupStages, setStartupStages] = useState([]);
  const stagesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"stages"}`, "", "unauthget")
      .then((result) => {
        
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;             
              setStartupStages(dropDown.values);
            }
          }
        }
      })
      .catch((err) => {});
  };
  const [industry, setIndustry] = useState([]);
  const industriesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"industries"}`, "", "unauthget")
      .then((result) => { 
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;            
              setIndustry(dropDown.values);
            }
          }
        }
      })
      .catch((err) => {});
  };
  const onSubmit = (data) => {
    console.log("Form Data:", data);
    apiService('userprofile/save', data, 'post').then((result) => {
      if (result.data.responseStatus==='Success') {    
        navigator('/service-provider/founder')
      }
    }).catch((err) => {
    });
  };
  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">More Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">5</span>/5
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="industry"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                        disableClearable
                          options={industry}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Industry"
                              placeholder="Select Industry "
                              error={!!error}
                              helperText={error ? "Industry is required" : ""}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.industry && errors.industry.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="natureOfEntity"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                        disableClearable
                          options={natureEntities}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nature of Entity *"
                              placeholder="Select Nature of Entity"
                              error={!!error}
                              helperText={error ? 'Nature of Entity is required' : ''}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.natureOfEntity && errors.natureOfEntity.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="about"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Brief About Your Expertise   is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-multiline-static"
                          label="Brief About Your Expertise *"
                          fullWidth
                          multiline
                          rows={4}
                          defaultValue=""
                          error={Boolean(errors.about && errors.about)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.about && errors.about.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="smartcard"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                          Are you willing to be onboarded to StartupTN Smart card?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            {...field}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                    </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MoreinfoServiceProvider;
