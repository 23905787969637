import React, { useContext, useState } from 'react'
import DataContext from '../../../context/DataContext';
import axios from 'axios';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const Profiletype = () => {
  const { navigator } = useContext(DataContext)
  const [data, setData] = useState([])
  useDidMountEffect(() => {
    profileGet()
  }, [])
  const profileGet = () => {
    axios.get('/json/profiledetails.json', '').then((result) => {
      if (result.data) {
        setData(result.data)
      }
    }).catch((err) => {

    });
  }

  const [selectedType, setSelectedType] = useState("");
  const handleBoxClick = (type, name) => {    
    setSelectedType(type);
    localStorage.setItem('type', type)
    navigator(`/profile/${name}`)
  };

  return (
    <div>
      <section className='container pl-0'>
        <h5 className='gry-clr  mb-4 ml-5'>Select your Profile Type</h5>
        <div className='vector-bg row m-0'>
          {data.map((item, index) => (
            <div className='col-lg-4 col-md-6 mb-2 pl-0' key={index}>
              <div className={selectedType === item.profileType ? 'mainbox activeProfile' : 'mainbox'} onClick={() => handleBoxClick(item.profileType, item.profileName)} >
                <center><h5 className='radiotext font-weight-bold'>{item.profileName}</h5></center>
              </div>
            </div>
          ))}
        </div>
        {/* <div className=''>
          <h5 className='gry-clr font-weight-bold'>Selected Roles</h5>
          <div className='row' data-aos="fade-right" data-aos-delay="200">
            {selectedRoles.map((role, index) => (
              <div className='col-lg-4 pl-0' key={index}>
                <div className='boxx' >
                  <center><p className='font-weight-bold m-0'>{role}</p></center>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </section>
    </div>
  )
}

export default Profiletype