// import {
//   ExpandLess,
//   ExpandMore,
//   Padding,
//   StarBorder,
// } from "@mui/icons-material";
// import {
//   Grid,
//   Box,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Collapse,
//   Tooltip,
//   Button,
//   FormControl,
//   InputLabel,
//   Input,
//   InputAdornment,
//   TableContainer,
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   Paper,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
// import Profiletype from "../../auth-module/profile-type-page/Profiletype";
// import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import axios from "axios";
// import apiService from "../../../api/apiService";

// const Adminlayout = () => {
//   function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
//   }

//   // const rows = [
//   //   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   //   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   //   createData('Eclair', 262, 16.0, 24, 6.0),
//   //   createData('Cupcake', 305, 3.7, 67, 4.3),
//   //   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   // ];
//   const [openCollapse, setOpenCollapse] = useState(false);
//   const [selectedOption, setSelectedOption] = useState("");

//   const handleSelectChange = (event) => {
//     
//     setSelectedOption(event.target.value);
//   };
//   useDidMountEffect(() => {
//     profileGet();
//     userlistApi();
//   }, []);

//   const persona = localStorage.getItem("userGet");
//   const roletype = localStorage.getItem("role");
//   const userlistApi = () => {
//     var data = {
//       listSize: "",
//       pageNumber: "",
//       persona: persona,
//       role: roletype,
//     };
//     apiService("user/list", data, "post")
//       .then((result) => {
//         if (result) {
//           if (result.data) {
//             console.log(result.data);
//             // if (result.data.dropDownValues) {
//             //   var values = result.data.dropDownValues;
//             //   setSupport(values.support);
//             //   setSectors(values.sectors);

//             // }
//           }
//         }
//       })
//       .catch((err) => {});
//   };

//   const [rows, setRows] = useState([]);
//   useEffect(() => {
//     axios
//       .get("./json/userdetails.json")
//       .then((response) => {
//         setRows(response.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const listItemBtn = {
//     minHeight: 48,
//     justifyContent: "initial",
//     px: 2.5,
//     m: 1,
//   };
//   const listItemSubBtn = {
//     minHeight: 48,
//     justifyContent: "initial",
//     pl: 4,
//     m: 1,
//   };
//   const listItemSubIcon = {
//     minWidth: 0,
//     mr: 2,
//     justifyContent: "center",
//     color: "#000",
//   };
//   const listItemText = {
//     opacity: 1,
//     color: "black",
//   };
//   const [data, setData] = useState([]);
//   const [roleDetails, setSoleDetails] = useState([]);
//   const profileGet = () => {
//     axios
//       .get("./json/profiledetails.json", "")
//       .then((result) => {
//         if (result.data) {
//           setData(result.data);
//           var sam = localStorage.getItem("userGet");
//           const values = result.data.find((name) => name.profileName === sam);
//           setSoleDetails(values.role);
//           
//         }
//       })
//       .catch((err) => {});
//   };
//   const userGet = (type) => {
//     
//     localStorage.setItem("userGet", type);
//     setSelectedOption(type)
//     profileGet();
//   };
//   const [selectedRole, setSelectedRole] = useState("");
//   const handleRoleButtonClick = (roleValue) => {
//     // setSelectedRole(roleValue);
//     setSelectedRole((prevSelectedRoles) =>
//       prevSelectedRoles.includes(roleValue)
//         ? prevSelectedRoles.filter((role) => role !== roleValue)
//         : [...prevSelectedRoles, roleValue]
//     );
//   };
//   return (
//     <div>
//       <div className="signup-details-bg-dev admin-nav-bg">
//         <img
//           src="/images/startup-white.png"
//           alt=""
//           className="startup_img img-fluid"
//         />
//       </div>
//       <Grid
//         container
//         direction="row"
//         justifyContent="flex-start"
//         alignItems="flex-start"
//       >
//         <Grid item xs={12} sm={12} md={3} lg={3}>
//           <Box>
//             <List>
//               <ListItem>
//                 <ListItemButton onClick={() => setOpenCollapse(!openCollapse)}>
//                   <ListItemText primary="Users" />
//                   {openCollapse ? (
//                     <ExpandLess sx={{ color: "black" }} />
//                   ) : (
//                     <ExpandMore sx={{ color: "black" }} />
//                   )}
//                 </ListItemButton>
//               </ListItem>
//               <Collapse in={openCollapse} timeout="auto" unmountOnExit>
//                 <List component="div" disablePadding>
//                   {data &&
//                     data.map((option, i) => (
//                       <ListItemButton
//                         key={option || option.profileName}
//                         className={
//                           selectedOption === option.profileName
//                             ? "admindrop-btn activedropval"
//                             : "admindrop-btn"
//                         }
//                         // key={option}

//                         // className="menulink"
//                         sx={listItemSubBtn}
//                         // sx={{
//                         //   ...listItemSubBtn,
//                         //   backgroundColor: selectedOption === option ? 'blue' : 'inherit',
//                         // }}
//                         onClick={() => userGet(option.profileName)}
//                       >
//                         <Tooltip title="Products" placement="right">
//                           <ListItemIcon sx={listItemSubIcon}>
//                             {/* <StarBorder /> */}
//                           </ListItemIcon>
//                         </Tooltip>
//                         <ListItemText
//                           sx={listItemText}
//                           // className={
//                           //   selectedOption === option
//                           //     ? "admindrop-btn2 activedropval2"
//                           //     : "admindrop-btn2"
//                           // }
//                           // onClick={() =>
//                           //   userGet(option.profileType, option.profileName)
//                           // }
//                         >
//                           {option.profileName}
//                         </ListItemText>
//                       </ListItemButton>
//                     ))}
//                 </List>
//               </Collapse>
//             </List>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={12} md={9} lg={9}>
//           <div className="tab-container mt-5">
//             {roleDetails.map((tab, index) => (
//               <div
//                 key={index}
//                 className={
//                   selectedRole.includes(tab.roleValue)
//                     ? "admin-btn activeProfile"
//                     : "admin-btn"
//                 }
//               >
//                 <div
//                   variant="outlined"
//                   sx={{ margin: "5px" }}
//                   onClick={() => handleRoleButtonClick(tab.roleValue)}
//                 >
//                   <span>{tab.roleValue}</span>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div className="newTable">
//             {selectedRole && <p>{selectedRole} List</p>}
//             <TableContainer component={Paper}>
//               <Table sx={{ minWidth: 650 }} aria-label="simple table">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Name</TableCell>
//                     <TableCell align="right">Email</TableCell>
//                     <TableCell align="right">Mobile</TableCell>
//                     <TableCell align="right">Address</TableCell>
//                     <TableCell align="right">Pincode</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {rows.map((row) => (
//                     <TableRow
//                       key={row.name}
//                       sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                     >
//                       <TableCell component="th" scope="row">
//                         {row.name}
//                       </TableCell>
//                       <TableCell align="right">{row.email}</TableCell>
//                       <TableCell align="right">{row.mobile}</TableCell>
//                       <TableCell align="right">{row.address}</TableCell>
//                       <TableCell align="right">{row.pincode}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </div>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default Adminlayout;
import { Box, CssBaseline } from '@mui/material'
import React from 'react'
import { Outlet,useNavigate } from 'react-router-dom'
import AdminLayoutsidebar from './AdminLayoutsidebar'
import AdminLayoutNavbar from './AdminLayoutNavbar'
import useDidMountEffect from '../../hooks/useDidMountEffect'

const Adminlayout = () => {
    const navigator = useNavigate()
    let path
    useDidMountEffect(() => {
        if (!localStorage.getItem('jwttoken')) navigator('/login');
        path = window.location.hash        
    }, [])
    return (
        <Box sx={{ display: 'flex', padding: '25px' }} className="admin_main_css">
            <CssBaseline />
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <AdminLayoutsidebar />
            </Box>
            <Box component="main" className='main_dev'>
                <AdminLayoutNavbar />
                <div className='set-scroll-code'>
                    <div className='setmb'>
                        <Outlet></Outlet>
                    </div>
                </div>
            </Box>
        </Box>
    )
}

export default Adminlayout