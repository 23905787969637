import React from 'react'
import { Outlet } from 'react-router-dom'
import InvestorEventFilters from './InvestorEventFilters'

const InvestorEventLayout = () => {
    return (
        <div>
            <InvestorEventFilters />
            <Outlet></Outlet>
        </div>
    )
}

export default InvestorEventLayout