import React, { useContext, useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, } from "@mui/material";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
import { useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";

const SmartProfilePage = () => {

  const { id } = useParams()
  let ids
  if (id !== null) {
    ids = atob(id)
  }

  const { setPageLoading, getApi, userProfileId, para } = useContext(DataContext)
  useDidMountEffect(() => {
    getApi();
  }, [userProfileId]);
  const [showFull, setShowFull] = useState(false);
  const toggleReadMore = () => {
    setShowFull(!showFull);
  };
  // const fullText = "Apple Inc. is a global Apple Inc. is a globalApple Inc. is a globalApple Inc. is a global";
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const wordLimit = 30;
  const displayText = showFull ? para : truncateText(para, wordLimit);
  const isTruncated = para.split(" ").length > wordLimit;

  useDidMountEffect(() => {
    getSmartCardApi()
  }, [])

  useEffect(() => {
    size();
  }, []);

  const [screenSize, setScreenSize] = useState(2);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(2);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(2);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(2);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(2);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(1);
    } else {
      setScreenSize(1);
    }
  };

  // const [smartCardData, setSmartCardData] = useState([])
  const [privilegesData, setPrivilegesData] = useState([])
  const getSmartCardApi = () => {
    apiService(`smartcard/get?id=${ids}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        if (result.data.privileges) {
          // setSmartCardData(result.data);
          setPrivilegesData(result.data.privileges)
        }
      }
    }).catch((err) => {

    });
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={12} >
          <div className="investment-whole-div">
            <div className="profile-box2">
              <h5>About</h5>
              <p className="para">
                {displayText}
                {isTruncated && (
                  <span
                    onClick={toggleReadMore}
                    style={{ cursor: "pointer", color: "#7F9AD2" }}
                  >
                    {showFull ? " Read Less" : " Read More..."}
                  </span>
                )}
              </p>
            </div>
            {
              privilegesData.length > 0 ? (
                <div className="profile-box2 mt-3">
                  <div className="text-center mb-2">
                    <h5>Privileges</h5>
                  </div>
                  <div className="smcrd-prof-tables">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead sx={{ background: '#EDF1FF' }}>
                          <TableRow>
                            <TableCell>Sl.no</TableCell>
                            <TableCell>Product / Service</TableCell>
                            <TableCell>Market Price / Month</TableCell>
                            <TableCell>Smart card Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {privilegesData &&
                            privilegesData.map((row, index) => (
                              <TableRow key={row.id}>
                                <TableCell>
                                  <Typography variant="h6">
                                    {index + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <h5>
                                    <span className="prod-name">{row.name ? row.name : '-'}</span>
                                  </h5>
                                  <p className="table-desc"> {row.description}</p>
                                </TableCell>
                                <TableCell>
                                  <h6 className="prod-name">₹{row.marketPrice}</h6>

                                  <p className="table-para-sm">
                                    {row.addChargeDesc}:
                                    <span className="prod-name mt-1">
                                      ₹{row.addChargeMarketPrice}
                                    </span>
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <h6 className="prod-name">₹{row.smartCardPrice}</h6>
                                  <p className="table-para-sm">
                                    {row.addChargeDesc}:
                                    <span className="prod-name mt-1">
                                      {" "}
                                      ₹{row.addChargeSmartCardPrice}
                                    </span>
                                  </p>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ) : (
                <div className="profile-box3 mt-2">
                  <div className="text-center">
                    <h5 style={{ fontWeight: "600" }}>Privileges</h5>
                  </div>
                </div>
              )
            }

            {/* <div className="Services-box">
              <span>Portfolio Startups</span>  
              <Swiper
                slidesPerView={screenSize}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="event-box-surya">
                    <div className="service-card-back-layers">
                      <div className="layer-crd-back-img-inv">
                        <div className="service-card-back-profss">
                          <img
                            src="/images/GREENPOD_LOGO1 (1).png"
                            alt="logo"
                            className="smcrd-card-back-image"
                          />
                        </div>
                      </div>
                      <div className="card-content-offers">
                        <span>50 Fin</span>
                      </div>
                      <div className="card-content-offers1">
                        <img src={"/images/Page-1.png"} alt="globe" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="event-box-surya">
                    <div className="service-card-back-layers">
                      <div className="layer-crd-back-img-inv">
                        <div className="service-card-back-profss">
                          <img
                            src="/images/GREENPOD_LOGO2.png"
                            alt="logo"
                            className="smcrd-card-back-image"
                          />
                        </div>
                      </div>
                      <div className="card-content-offers">
                        <span>ab Coffee</span>
                      </div>
                      <div className="card-content-offers1">
                        <img src={"/images/Page-1.png"} alt="globe" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="event-box-surya">
                    <div className="service-card-back-layers">
                      <div className="layer-crd-back-img-inv">
                        <div className="service-card-back-profss">
                          <img
                            src="/images/GREENPOD_LOGO3.png"
                            alt="logo"
                            className="smcrd-card-back-image"
                          />
                        </div>
                      </div>
                      <div className="card-content-offers">
                        <span>accio Robotics</span>
                      </div>
                      <div className="card-content-offers1">
                        <img src={"/images/Page-1.png"} alt="globe" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div> */}
            <div className="profile-box3 mt-2">
              <div className="text-center">
                <h5 style={{ fontWeight: "600" }}>Portfolio Startups</h5>
              </div>
            </div>
            {/* <div className="investment-whole-div mt-3">
              <div className="profile-box2 mt-2">
                <h5 style={{ fontWeight: "600" }} className="mb-3">Ratings & Reviews</h5>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <h5 className="color-blue"><img src={"/images/star-rate.png"} /> 4.2 / 5 (10)</h5>
                  <div className="my-3">
                    <div className="review-details">
                      <div className="mr-2">
                        <img src="/images/ecopic.png" className="review_logo" alt="" />
                      </div>
                      <div>
                        <div className="d-flex align-items-center">
                          <h6>BCS Software Solutions</h6>
                          <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} /> 4.2</p>
                        </div>
                        <p className="mb-0 paras">Find helpful customer reviews and review ratings for Google Search at Amazon.com. Read honest and unbiased product reviews from our users.</p>
                      </div>
                    </div>
                    <div className="review-details">
                      <div className="mr-2">
                        <img src="/images/ecopic.png" className="review_logo" alt="" />
                      </div>
                      <div>
                        <div className="d-flex align-items-center">
                          <h6>BCS Software Solutions</h6>
                          <p className="ml-auto list-para mr-5"><img src={"/images/star-rate.png"} /> 4.2</p>
                        </div>
                        <p className="mb-0 paras">Find helpful customer reviews and review ratings for Google Search at Amazon.com. Read honest and unbiased product reviews from our users.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SmartProfilePage;
