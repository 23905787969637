import React from 'react'
import Medialayout from './Media-layout-page/Medialayout'
import Mediatap from './Media-layout-page/Mediatap'
import {BrowserRouter as Router,  Navigate, Route, Routes } from 'react-router-dom'
import Eventlist from './Media-layout-page/Event-Page/Eventlist'
import BlogDetails from './Media-layout-page/Blog-Page/BlogDetails'

const Mediaroute = () => {
  return (
    <div>
       <Routes>
                <Route path='/events' element={<Medialayout />}>
                    <Route path="/events" element={<Navigate replace to="/events/list" />} />
                    <Route path="/events/list" element={<Mediatap />} />
                    
                    </Route>
                    <Route path="/blog/details/:id" element={<BlogDetails />} />
            </Routes>
    </div>
  )
}

export default Mediaroute
