import React, { useContext, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Container,
  Grid,
} from "@mui/material";
import { useParams } from "react-router-dom";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const AboutCompany = ({ name }) => {
  const { navigator, setPageLoading } = useContext(DataContext);
  const { id } = useParams()
  let smartCardId
  if (id !== null) {
    smartCardId = atob(id)
  }
  const tableData = [
    {
      id: 1,
      service: "Retail Android Mobile App for shops (one-time cost)",
      marketPrice: "12000",
      smartCardPrice: "10000",
    },
    {
      id: 2,
      service: "Retail iOS Mobile App for shops (one-time cost)",
      marketPrice: "25000",
      smartCardPrice: "20000",
    },
    {
      id: 3,
      service: "Ecommerce website for shops (per annum)",
      marketPrice: "5999",
      smartCardPrice: "3999",
    },
    {
      id: 4,
      service: "CRM per user/month",
      marketPrice: "800",
      smartCardPrice: "600",
    },
    {
      id: 5,
      service: "Text SMS (per SMS)",
      marketPrice: "13 Paise",
      smartCardPrice: "12 Paise",
    },
    {
      id: 6,
      service: "Voice SMS (in multiples of 30 sec)",
      marketPrice: "30 Paise",
      smartCardPrice: "20 Paise",
    },
    {
      id: 7,
      service: "Cloud telephony inbound (per channel/per month)",
      marketPrice: "1000",
      smartCardPrice: "600",
    },
    {
      id: 8,
      service: "Cloud telephony inbound (per channel/per month)",
      marketPrice: "1000",
      smartCardPrice: "600",
    },
    {
      id: 9,
      service: "FOS Android App per user ",
      marketPrice: "800",
      smartCardPrice: "600",
    },
  ];
  useDidMountEffect(() => {
    getApi()
  }, [])
  const [getData, setGetData] = useState({})
  const [getPrivileges, setGetPrivileges] = useState([])
  const [getPrivilegeTerms, setGetPrivilegeTerms] = useState([])
  const getApi = () => {
    setGetPrivileges([])
    setGetData({})
    setGetPrivilegeTerms([])
    setPageLoading(true)
    apiService(`smartcard/get?id=${smartCardId}`, '', 'unauthget').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        setGetData(result.data)
        if (result.data.privileges) {
          setGetPrivileges(result.data.privileges)
        } else {
          setGetPrivileges([])
        }
        if (result.data.privilegeTerms) {
          setGetPrivilegeTerms(result.data.privilegeTerms)
        } else {
          setGetPrivilegeTerms([])
        }

      }
    }).catch((err) => {

    });
  }
  const handleBack = () => {
    if (name === 'home') {
      navigator(`/smartcard/service`)
    } else {
      navigator(`/initiative/support/smartcard/service`)
    }

  }
  return (
    <div className="smartCard-page">
      <div className="radial-back-layers">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <div>
                  <img
                    src={getData.companyLogoName}
                    alt="Company-Logos"
                    className="about-imag"
                  />
                </div>
                <h6>{getData.subCategory}</h6>
                <div className="banner-content-actions">
                  <button className="aboutpage-viewbtn">Apply Services</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <div className="container acce-content-sec ">

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ paddingBottom: "5px" }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container>
                <div>
                  <span
                    style={{ color: "#2260FF", cursor: "pointer" }}
                    onClick={handleBack}
                  >
                    <ArrowBackIcon /> Back
                  </span>
                </div>
                <div className="center-aboutPage">
                  {/* <div className="d-flex"> */}

                  <h3>About Company</h3>
                  {/* </div> */}
                  <p>{getData.about}</p>
                  {/* <div className="left-content-actions">
                  <button className="ser-viewbtn">Explore Services</button>
                </div> */}
                </div>
              </Container>
            </Grid>
          </Grid>
        </div>
        <div className="Previlgies-div text-center pb-5">
          {
            getPrivileges.length > 0 && (
              <>
                <h3 className="mb-5">Previlgies</h3>
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container maxWidth="md">
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="table-borders">S.No</TableCell>
                              <TableCell className="table-borders">
                                Service/Product
                              </TableCell>
                              <TableCell className="table-borders">
                                Market Price (Rs)
                              </TableCell>
                              <TableCell className="table-borders">
                                Smart Card Price (Rs)
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {getPrivileges.map((row, index) => (
                              <TableRow key={row.id}>
                                <TableCell className="table-borders">
                                  {index + 1}
                                </TableCell>
                                <TableCell className="table-borders">
                                  {row.serviceOfProduct}
                                </TableCell>
                                <TableCell className="table-borders" align="center">
                                  {row.marketPrice}
                                </TableCell>
                                <TableCell className="table-borders" align="center">
                                  {row.smartCardPrice}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Container>
                  </Grid>
                </Grid>
              </>
            )
          }

          <Container maxWidth="md">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                {
                  getPrivilegeTerms.length > 0 && (
                    <>
                      <h5 className="terms-cond mt-5">Terms & Conditions</h5>
                      <ol className="order-li-aboutpg">
                        {
                          getPrivilegeTerms.map((data, index) => (
                            <li>{data.termsAndConditions}</li>
                          ))
                        }
                      </ol>
                    </>
                  )
                }
              </Grid>
              <Grid item xs={12} sm={12} md={getPrivilegeTerms.length > 0 ? 4 : 12} lg={getPrivilegeTerms.length > 0 ? 4 : 12} xl={getPrivilegeTerms.length > 0 ? 4 : 12}>
                <div className="banner-content-actions">
                  <button className="aboutpage-viewbtn">Apply Now</button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
