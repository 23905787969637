import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import Navbar from '../../../home-module/navbar-page/Navbar'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import { TabContext, TabPanel } from '@mui/lab'
import { useState } from 'react'
import OpenInnovationCorporate from './OpenInnovationCorporate'
import OpenInnovationGovernment from './OpenInnovationGovernment'

const OpenInnovationLayout = ({ name }) => {

    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="smartCard-page">
                <Navbar />
                <div className="radial-back-layer">
                    <div className="round-layers"></div>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="banner-text">
                                <div className="sm-banner-chip-text">
                                    <span>{`${name}   >  Open Innovation`}</span>
                                    <h1 className="banner-text-gradient">Open Innovation</h1>
                                    <h6>Tamil Nadu’s solution to Global solution</h6>
                                    <div className="banner-content-actions">
                                        <button className="banner-viewbtn" >Explore Challenge</button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className='investor-filters'>
                    <TabContext value={value} className="tab-div">
                        <div className="inv-name-tab">
                            <Box className="inv-tabs-boxs-surya-v2">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant='scrollable'
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    className='scroll-inv-tabs'
                                >
                                    <Tab
                                        label="Corporate"
                                        value="1"
                                        style={{
                                            backgroundColor: value === "1" ? "#083FB2" : "",
                                            color: value === "1" ? "white" : "#000",
                                        }}
                                        className="setFont"
                                    />
                                    <Tab
                                        label="Government"
                                        value="2"
                                        style={{
                                            backgroundColor: value === "2" ? "#083FB2" : "",
                                            color: value === "2" ? "white" : "#000",
                                        }}
                                        className="setFont"
                                    />
                                </Tabs>
                            </Box>
                        </div>
                        <TabPanel value="1" className="catalyst-tab"><OpenInnovationCorporate/></TabPanel>
                        <TabPanel value="2" className="catalyst-tab"><OpenInnovationGovernment/></TabPanel>
                    </TabContext>
                </div>
            </div>
        </>
    )
}

export default OpenInnovationLayout