import React from "react";
import { Outlet } from "react-router-dom";


const EcosystemEnablersLayout = () => {
    return (
        <>
            <Outlet></Outlet>
        </>

    );
};


export default EcosystemEnablersLayout