import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import Navbar from "../navbar-page/Navbar";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Footer from "../footer-page/Footer";

const PublicProcurement = () => {
    const navigator = useNavigate();

    const handleBackBtn = () => {
        navigator(`/`);
    };

    const url = 'https://startuptn-public-files.s3.ap-south-1.amazonaws.com/GovernmentOrder.pdf';

    const url2 = 'https://startuptn-public-files.s3.ap-south-1.amazonaws.com/GovernmentOrder_2.pdf';

    return (
        <>
            <Navbar />
            <div className="back-color">
                <div className="back-layer"></div>
                <div className="back-layer1"></div>

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Container>
                            <div className="blog-details-bg">
                                <div className="image-container">
                                    <img
                                        src='/images/usernav.jpg'
                                        alt=""
                                        className="img-fluid blog-res-img"
                                    />
                                    <div className="overlay"></div>
                                </div>
                                <div className="tender-head">
                                    <h3>Public Procurement Support for Startups</h3>
                                </div>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <div className="back-blog">
                    <div className="allticket-list">
                        <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                            <FaArrowLeft />
                            <span style={{ marginLeft: "5px" }}>Back</span>
                        </Button>
                    </div>
                </div>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="container my-5">
                            <h2>Concessions to the registered Startups for Procurement by a Procuring Entity:</h2>
                            <p className="mt-3">To achieve this, the Government of Tamil Nadu provided relaxations in bidding parameters for public procurement by the State Government through
                               <a href={url} target="_blank" rel="" style={{ color: 'blue', textDecoration: 'none' }}> Government Order No.40 MSME (A) Department dated 22 July 2021. </a>
                            </p>
                            <p className="mt-3">The Government after careful examination has decided to provide the following concessions to
                                the registered Startups for public procurements by a Procuring Entity (as defined in Transparency
                                in Tenders Act, 1998) for procurements that are below Rs.20 Lakh in value to improve the Startup
                                ecosystem in the State.
                            </p>
                            <p className="mt-3">
                                <b>As per the Government Order stated above, the following concessions have been provided to Startups:</b>
                            </p>

                            <div className="mt-3 ml-3">
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>1</b></span>Exemption from Payment of Earnest Money Deposit</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>2</b></span>Exemption from the condition of prior turnover.</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>3</b></span>Exemption from the condition of prior experience.</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>4</b></span>Waiver of tender cost.</p>
                            </div>

                            <p className="mt-3">
                                <b>Procurement Entity shall mean the following:</b>
                            </p>

                            <div className="mt-3 ml-3">
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>1</b></span>Government Departments.</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>2</b></span>Public Sector Undertakings of the Government.</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>3</b></span>Local Bodies in the State.</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>4</b></span>Co-operative Institutions in the State.</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>5</b></span>Universities</p>
                                <p><span style={{ color: '#2b377a' }} className="mr-3" ><b>6</b></span>Societies formed by the Government.</p>
                            </div>

                            <p className="mt-3">Tamil Nadu Startup and Innovation Mission (StartupTN) shall act as a tender/ public procurement information centre for startups through the startuptn.in portal.</p>

                            <p className="mt-3"><b>Low Value Procurement relaxation for Startups</b></p>

                            <p className="mt-3">In 2022, the Government of Tamil Nadu examined the “Tamil Nadu Transparency in Tenders Rules, 2000” and added an amendment through <a href={url2} target="_blank" rel="" style={{ color: 'blue', textDecoration: 'none' }}> G.O. Ms. No. 367 dated December 5, 2022.</a> This amendment provides relaxation for “Low-Value Procurement” for startups located within the state up to a value of Rs. 50 lakh.</p>

                            <p className="mt-3"><b>GeM Portal workshops for Startups</b></p>

                            <p className="mt-3">
                                StartupTN encourages Tamil Nadu Startups to register on the (Government e-Marketplace) GeM portal and participate actively in the public procurement processes listed on the portal. Also, dedicated workshops are conducted by StartupTN and Startup India to ease access to the portal.
                            </p>
                            <p className="mt-3">
                                Government e-Marketplace (GeM) is a one-stop portal to facilitate online procurement of common use Goods & Services required by various Government Departments / Organizations / PSUs. GeM aims to enhance transparency, efficiency and speed in public procurement. It provides e-bidding, reverse e-auction and demand aggregation tools to facilitate the government users to achieve the best value for their money.
                            </p>
                            <p className="mt-3">
                                Startups can list multiple products and services with minimal technical specifications and sell their products to Govt buyers under globally recognized Startup sub-sectors. The platform offers Startups all the marketplace functionalities that are available to regular sellers.
                            </p>

                            <p className="mt-3"><b>Procedure to avail:</b></p>

                            <p className="mt-3"><CheckCircleIcon style={{ color: '#2b377a' }} className="mr-2" />Register the startup as a seller/service provider on GeM, the DIPP number would be mandatory for registering.</p>
                            <p><CheckCircleIcon style={{ color: '#2b377a' }} className="mr-2" />Startups can access all the necessary information related to GeM portal on https://gem.gov.in/training/training_module</p>

                            <p className="mt-3"><b>S2G (Startup-to-Government) Sandbox – for Government Pilot Projects:</b></p>

                            <p className="mt-3">S2G Sandbox is a pilot initiative that was organized this February 2021 with the aim to showcase, demo and connect Startups with the Government Departments and support them by providing test beds for their innovative solutions. The objective of this initiative is to deepen the engagement of the Government of Tamil Nadu with the State Startup Ecosystem. </p>

                            <p className="mt-3">Likewise, StartupTN will organize and facilitate various sectoral and sector-agnostic Demo Days / Showcase Opportunities for the startups to interact and work with the State Government Departments.</p>

                            <p><b>Government Department Facilitation:</b></p>

                            <p className="mt-3">State Government departments and undertakings may make use of the services of startups registered with TANSIM for low-value procurements, subject to the condition that the Tender Inviting Authority can purchase not more than two products/services in a given financial year from one startup. </p>
                        
                            <p className="mt-3"><CheckCircleIcon style={{ color: '#2b377a' }} className="mr-2" /><b>Open Innovation Challenges:</b></p>
                            <p>Govt. Departments from Tamil Nadu State in partnership with StartupTN will conduct various Hackathons and Innovation Challenges to the problem statements.</p>
                        
                            <p className="mt-3"><CheckCircleIcon style={{ color: '#2b377a' }} className="mr-2" /><b>StartupTN connects the startups with respective Government Departments:</b></p>
                            <p>Startups registered on the startuptn.in portal and reaching out to StartupTN with their solution to easier the process of respective Govt. Departments are connected when the problem statement matches with the respective Government Department.</p>
                        
                        
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Footer />
        </>
    )
}

export default PublicProcurement