import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Sports = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives'?'1':'2');

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };
  const handleBackBtn = () => {
      navigator(`/ecosystem-info`);
    };
  return (
    <div>
    <Navbar />
    <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/Sports-Tech-Gaming.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
        <h3 className="banner-title-sector">Sports Tech & Gaming</h3>
        </div>

        <div className="mt-5">
        <div className="back-blog">
  <div className="allticket-list">
    <Button className="bloggingss-btnss" onClick={handleBackBtn}>
      <FaArrowLeft />
      <span style={{ marginLeft: "5px" }}>Back</span>
    </Button>
  </div>
</div>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Container maxWidth="md">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="fullWidth"
                                textColor="inherit"

                                // TabIndicatorProps={{
                                //     sx: {
                                //         borderBottom: "3px solid #083fb2",
                                //     },
                                // }}
                                TabIndicatorProps={{
                                    sx: {
                                        height: 2, 
                                        backgroundColor: "#083fb2", 
                                        borderBottom: "3px solid #083fb2",  
                                        width: "100px",  
                                    },
                                }}
                            >
                                <Tab
                                    label="Initiatives"
                                    value="1"
                                    className="resource-tab-style"
                                    sx={{
                                        color: value === "1" ? "#0a2a5b" : "#757575",
                                        "&:hover": {
                                            color: "#0a2a5b",
                                        },
                                        "&.Mui-selected": {
                                            color: "#0a2a5b",
                                            border: '1px solid lightgray'
                                        },
                                    }}
                                />
                                <Tab
                                    label="Resources"
                                    value="2"
                                    className="resource-tab-style"
                                    sx={{

                                        "&:hover": {
                                            color: "#0a2a5b",
                                        },
                                        "&.Mui-selected": {
                                            color: "#0a2a5b",
                                            border: '1px solid lightgray'
                                        },

                                    }}
                                />

                            </Tabs>
                        </Box>
                    </Container>
                    <TabPanel value="1" sx={{ padding: "16px" }}>
                    <div className="sector-page-bg">
                    <h5 style={{ color: "#39364e" }}>Initiatives</h5><br />
                    <h5 style={{ color: "#39364e" }}>Union Government</h5><br />
                    <ul>
                    <li>Fit India Movement</li><br />
                    <li>Khelo India</li><br />
                    <li>Target Olympic Podium Scheme (TOPS)</li><br />
                    <li>The National Sports Development Fund (NSDF).</li><br />
                    <li>Schemes for the Sports Authority of India (SAI)</li><br />
                    <li>Mission Olympics 2024</li><br />
                    <li>Scheme for National Sports Awards</li><br />
                    <li>Scheme for Sports & Games for People with Disabilities</li><br />
                    <li>Portal for Sports Talent Search</li><br />
                    <li>Rajiv Gandhi Khel Abhiyan</li><br />
                    <li>National Centre of Sports Sciences and Research (NCSSR)</li><br />
                    </ul> <br/>
                    <h5 style={{ color: "#39364e" }}>State Government</h5><br />
                    <p>The Sports Development Authority of Tamil Nadu offers several schemes to identify and nurture sporting talent. They include the World Beaters Talent Spotting Scheme, Champions Development Scheme, SDAT Sports Scholarship Scheme and Scholarship for Elite Sportspersons.</p>
                </div>

                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "16px" }}>
                    <div className="sector-page-bg">
                    <h5 style={{ color: "#39364e" }}>Resources</h5><br />
                    <ul>
                    <li><b>National Sports University:</b> The Imphal-based NSU has been established to promote sports education in the areas of sports sciences, sports technology, sports management and sports coaching.</li><br />
                    <li><b>Tamil Nadu Physical Education and Sports University:</b> This exclusive university for sports functions with the objective of developing physical education and sports through innovative programmes in teaching, coaching, research and outreach activities.</li><br />
                    <li><b>Sports Hostel:</b> The Sports Development Authority of Tamil Nadu runs 29 sports hostels across the State to nurture budding sportspersons. Each district has an integrated sports complex to identify and train sportspersons.</li><br />
                    <li><b>Sports Academies and Training Centres:</b> Tamil Nadu has numerous sports academies and training centres, where athletes receive coaching and support to excel in various disciplines. These facilities contribute to talent development and offer potential partnerships for businesses in the sports industry.</li><br />
                    <li><b>Exotic Sports:</b> Facilities exist in the State for exotic sports like scuba diving in Rameswaram; water surfing in Muttukadu and air adventure sports like hang gliding in Chennai.</li><br />
                    <li><b>Traditional Sports:</b> Traditional sports events are mostly identified by their arenas. Jallikattu, for example, is synonymous with Alanganallur, though it is organised in different forms (eru thazhuvuthal, manju virattu) all over the State.</li><br />
                    </ul> 
                </div>

                    </TabPanel>

                </TabContext>
            </Box>

        </div>
    </div>
    <Footer />
</div>
  )
}

export default Sports
