import {
  Grid,
  Container,
  Button,
  Chip,
  Divider,
  Rating,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Dialog,
  FormHelperText,
} from "@mui/material";
import { IoCheckmark } from "react-icons/io5";
import { IoMailOpenOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa6";
import React, { useContext, useRef, useState } from "react";
import DataContext from "../../../../context/DataContext";
import { Link, useParams } from "react-router-dom";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import $ from "jquery";
const SupportTicketQuery = () => {
  const { id } = useParams();
  let ids;
  if (id !== null) {
    ids = atob(id);
  }
  const { navigator, setPageLoading, isWhitespace } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ values: formData });
  let name = localStorage.getItem("name");
  const [convo, setConvo] = useState({});
  const [message, setMessage] = useState("");
  const [supportId, setSupportId] = useState();
  const [userId, setUserId] = useState();
  const [position, setPosition] = useState({});
  const [statusBtn, setStatusBtn] = useState();
  useDidMountEffect(() => {
    getApi();
    // getConversationApi();
  }, []);
  const getApi = () => {
    // setPageLoading(true)
    apiService(`support/ticket/get?id=${ids}`, "", "get")
      .then((result) => {
        // setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
          setSupportId(result.data.id);
          setUserId(result.data.userId);
          setStatusBtn(result.data.status);
          setRating(result.data.rating);
          var data = result.data.conversation;
          setConvo(data);
          setPosition(result.data.position);
          setHeight();
        }
      })
      .catch((err) => { });
  };
  const handleReopenClick = () => {
    var req = {
      id: supportId,
      status: 2,
    };

    apiService("support/ticket/status/save", req, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          getApi();
        }
      })
      .catch((err) => { });
  };

  const {
    fields: photosFields,
    append: photosAppend,
    remove: photosRemove,
  } = useFieldArray({
    control,
    name: "attachments",
  });

  const handleBackBtn = () => {
    navigator(`startup/support/${name}/${btoa(ids)}`);
  };

  const [isClosed, setIsClosed] = useState(false);

  const handleClosedClick = () => {
    setIsClosed(true);
  };

  const handleBackClick = () => {
    setIsClosed(false);
  };

  const onSubmit = (data) => {
    // var data = {
    //   "supportId": supportId,
    //   "userId": userId,
    //   "message": message
    // }
    var getform = data;
    getform.supportId = supportId;
    getform.userId = userId;

    apiService("support/conversation/save", getform, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          getApi();
        }
      })
      .catch((err) => { });
  };

  const [isClosedd, setIsClosedd] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rating, setRating] = useState(0);

  const handleRatingChange = (event, value) => {
    setIsClosedd(true);
    setDialogOpen(true);
    setRating(value);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleRating = () => {
    var req = {
      rating: rating,
      feedback: feedback,
      id: ids,
    };
    apiService("support/ticket/rating/save", req, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          setDialogOpen(false);
          getApi();
        }
      })
      .catch((err) => { });
  };
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const setHeight = () => {
    const div1Height = div1Ref.current.offsetHeight;
    div2Ref.current.style.height = `${div1Height}px`;
    div3Ref.current.style.height = `${div1Height - 70}px`;
  };
  return (
    <div>
      <div className="container-fluid main-lists-support">
        <div className="list-divs">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{ position: "relative" }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="ticketadd mb-5 pt-4" ref={div1Ref}>
                <div className="pad-x">
                  <div className="">
                    <Button className="back-btns" onClick={handleBackBtn}>
                      <FaArrowLeft />
                      <span style={{ marginLeft: "5px" }}>Back</span>
                    </Button>
                  </div>
                  <div className="empower-box mb-3">
                    <p className="empower-p text-center">
                      {formData.queryTitle}
                    </p>
                    <p className="text-center pb-3">
                      <span className="gray">Ticket no : </span>
                      <span className="blue">{formData.ticketNo}</span>
                    </p>
                  </div>
                  <div className="mb-3">
                    <div className="text-center mb-2">
                      <div className="">
                        <span className="dar-blue">Created at : </span>
                        <span className="grey">{formData.creationTime}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mb-4">
                    <div className="my-4">
                      <span className="dar-blue">Department</span>
                    </div>
                    <div className="">
                      <span>{formData.departmentName}</span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="my-4">
                      <span className="dar-blue">Project</span>
                    </div>
                    <div className="">
                      <span>{formData.projectName}</span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="my-4">
                      <span className="dar-blue">Query Description</span>
                    </div>
                    <div className="queryDesc">
                      <span>{formData.queryDesc}</span>
                    </div>
                  </div>
                  <div>
                    <div className="attach-blue my-4">
                      <span>Attachments</span>
                    </div>
                    <div className="d-flex">
                      {photosFields.map((field, index) => (
                        <div key={index} className="d-flex">
                          {getValues(`attachments.${index}.fileName`) && (
                            <>
                              <div className="">
                                <img
                                  src={getValues(
                                    `attachments.${index}.fileName`
                                  )}
                                  className="vehicle_logo"
                                  alt={`attachments.${index}.fileName`}
                                  style={{
                                    width: "67px",
                                    objectFit: "contain",
                                    marginLeft: "10px",
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                    {/* {statusBtn === 0 && (
                      <center>
                        <Button
                          style={{
                            background: "#ffc107",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                        >
                          Not Assigned
                        </Button>
                      </center>
                    )} */}
                    {/* {statusBtn === 1 && (
                      <center>
                        <Button
                          style={{
                            background: "#269949",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                          startIcon={<IoMailOpenOutline />}
                          onClick={handleReopenClick}
                        >
                          Mark as Resolved
                        </Button>
                      </center>
                    )} */}
                    {/* {statusBtn === 3 && (
                      <center>
                        <Button
                          style={{
                            fontSize: "16px",
                            color: "#020671",
                            borderColor: "#020671",
                            padding: "12px 40px",
                            textTransform: "none",
                            borderRadius: "8px",
                            border: "1px solid",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                          // startIcon={<IoMailOpenOutline />}
                          // onClick={handleReopenClick}
                        >
                          Mark as Resolved
                        </Button>
                      </center>
                    )}
                    {statusBtn === 4 && (
                      <center>
                        <Button
                          style={{
                            background: "#269949",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                          // startIcon={<IoCheckmark />}
                          // onClick={handleClosedClick}
                        >
                          Closed
                        </Button>
                      </center>
                    )} */}
                  </div>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <div
                style={{
                  borderRadius: "12px",
                  border: "0.5px solid #d9d9d9",
                  background: "#EFEFF7",
                  boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px",
                  marginBottom: "20px",
                  height: "auto",
                  position: "relative",
                }}
              >
                <div
                
                  style={{
                    padding: "24px",
                    height: "calc(100vh - 120px)",
                    overflowY: "auto",
                  }}
                >
                  {convo && convo.length > 0 ? (
                    convo.map((list, index) => (
                      <div>
                        {list.position === "LEFT" ? (
                          <div className="left-mess">
                            <div className="message">
                              <div>
                                <img
                                  className="photo1"
                                  src={
                                    list.logoFileName
                                      ? list.logoFileName
                                      : "/images/user.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <p className="text1">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="team">
                                    <p>{list.responderName}</p>
                                  </div>
                                  <div>
                                    <p className="time1">{list.creationTime}</p>
                                  </div>
                                </div>
                                <div>{list.message}</div>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="right-mess">
                              <div
                                className="message1"
                                style={{
                                  marginTop: "25px",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "90%",
                                }}
                              >
                                <div className="text3">
                                  <div
                                    className="date"
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="team">
                                      <p>{list.responderName}</p>
                                    </div>
                                    <div>
                                      <p className="time2">
                                        {list.creationTime}
                                      </p>
                                    </div>
                                  </div>
                                  <div>{list.message}</div>
                                </div>
                                <div>
                                  <img
                                    className="photo2"
                                    src={
                                      list.logoFileName
                                        ? list.logoFileName
                                        : "/images/user.png"
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      <center>
                        <p>No Chat Found</p>
                      </center>
                    </>
                  )}
                </div>
                <div className="msg_box">
                  {statusBtn !== 2 && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box className="message-box">
                        <Controller
                          name="message"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Message is required",
                            validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Write a Message"
                                id="fullWidth"
                                style={{ background: "white" }}
                                error={!!error}
                              
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Button type="submit">
                                        <SendIcon />
                                      </Button>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.message && errors.message.message}
                        </FormHelperText>
                      </Box>
                     
                    </form>
                  )}
                
                  {statusBtn === 2 && (
                    <div className="bg-feedback">
                      <div className="feedback">
                        <Typography
                          style={{ fontSize: "18px" }}
                          component="legend"
                        >
                          Your Rating
                        </Typography>
                        {rating > 0 ? (
                          <Rating
                            style={{ fontSize: "40px" }}
                            name="size-large"
                            value={rating}
                            size="large"
                            readOnly
                          />
                        ) : (
                          <Rating
                            style={{ fontSize: "40px" }}
                            name="size-large"
                            defaultValue={0}
                            size="large"
                            onChange={handleRatingChange}
                          />
                        )}
                        <Typography
                          style={{ fontSize: "18px" }}
                          component="legend"
                        >
                          Your Feedback :
                        </Typography>
                        {formData.feedback}
                      </div>
                    </div>
                  )}
                 
                  <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    maxWidth="sm"
                    fullWidth
                  >
                    <div style={{ padding: 20 }}>
                      <TextField
                        label="Feedback"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        value={feedback}
                        onChange={handleFeedbackChange}
                      />
                      <center>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="mt-3"
                          onClick={handleRating}
                        >
                          Submit Feedback
                        </Button>
                      </center>
                    </div>
                  </Dialog>
                </div>
              </div>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div
                className="box1"
                style={{
                  padding: "24px 24px 24px 24px",
                  height: "auto",
                  borderRadius: "12px",
                  background: "#EFEFF7",
                  boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px",
                }}
              >
                <div
                  className="chat"
                  style={{ height: "calc(100vh - 120px)", overflowY: "auto" }}
                >
                  {convo && convo.length > 0 ? (
                    <div className="messages-chat">
                      {convo.map((list, index) => (
                        <>
                          {list.position === "LEFT" ? (
                            <div>
                              <div className="message">
                                <div>
                                  <img
                                    className="photo1"
                                    src={
                                      list.logoFileName
                                        ? list.logoFileName
                                        : "/images/user.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <p className="text1">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="team">
                                      <p>{list.responderName}</p>
                                    </div>
                                    <div className="ml-auto">
                                      <p className="time1">
                                        {list.creationTime}
                                      </p>
                                    </div>
                                  </div>
                                  <div>{list.message}</div>
                                </p>
                              </div>
                              {/* <div className="message4 text-only">
                                      <p className="text2">
                                        {" "}
                                        <Link>www.startuptn.in/funding-programs//</Link>
                                      </p>
                                    </div> */}
                            </div>
                          ) : (
                            <div
                              className="message1"
                              style={{
                                marginTop: "25px",
                                display: "flex",
                              }}
                            >
                              <div className="text3">
                                <div
                                  className="dates"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="team">
                                    <p>{list.responderName}</p>
                                  </div>
                                  <div className="ml-auto">
                                    <p className="time2">{list.creationTime}</p>
                                  </div>
                                </div>
                                <div>{list.message}</div>
                              </div>

                              <div>
                                <img
                                  className="photo2"
                                  src={
                                    list.logoFileName
                                      ? list.logoFileName
                                      : "/images/user.png"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  ) : (
                    <>
                      <center>
                        <p>No Chat Found</p>
                      </center>
                    </>
                  )}
                </div>
                <div className="msg_box">
                  {statusBtn !== 2 && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box className="message-box">
                        <Controller
                          name="message"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Message is required",
                            validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Write a Message"
                                id="fullWidth"
                                style={{ background: "white" }}
                                error={!!error}
                                // helperText={error ? error.message : ""}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Button type="submit">
                                        <SendIcon />
                                      </Button>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.message && errors.message.message}
                        </FormHelperText>
                      </Box>
                      {/* <Button type="submit">Save</Button> */}
                    </form>
                  )}
                  {/* {statusBtn === 2 && (
                    <div className="bg-feedback">
                      <div className="feedback">
                        <Typography
                          style={{ fontSize: "18px" }}
                          component="legend"
                        >
                          Rating
                        </Typography>

                        <Rating
                          style={{ fontSize: "40px" }}
                          name="size-large"
                          value={rating}
                          size="large"
                          readOnly
                        />
                        <Typography
                          style={{ fontSize: "18px" }}
                          component="legend"
                        >
                          Feedback :
                        </Typography>
                        {formData.feedback}
                      </div>
                    </div>
                  )} */}
                  {
                    statusBtn === 2 && (
                      <div className="bg-feedback">
                        <div className="feedback">
                          <Typography style={{ fontSize: "18px" }} component="legend">
                            Your Rating
                          </Typography>
                          {rating > 0 ? (
                            <Rating
                              style={{ fontSize: "40px" }}
                              name="size-large"
                              value={rating}
                              size="large"
                              readOnly
                            />
                          ) : (
                            <Rating
                              style={{ fontSize: "40px" }}
                              name="size-large"
                              defaultValue={0}
                              size="large"
                              onChange={handleRatingChange}
                            />
                          )}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <div style={{ padding: 20 }}>
          <TextField
            label="Feedback"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            value={feedback}
            onChange={handleFeedbackChange}
          />
          <center>
            <Button
              variant="outlined"
              color="primary"
              className="mt-3"
              onClick={handleRating}
            >
              Submit Feedback
            </Button>
          </center>
        </div>
      </Dialog>
    </div>
  );
};

export default SupportTicketQuery;
