import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const FoodTech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTNsector-Agripage.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Agriculture & FoodTech</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <p style={{ color: "#39364e" }}>Union Government</p>
                  <p>
                    The Ministry of Agriculture accounts for 2.8 per cent of the
                    Union Budget. It has been allocated Rs 1,25,036 crore in
                    2023-24, 5% higher than the revised estimates for 2022-23.
                  </p>
                  <ul>
                    <li>
                      <b>Digital Public Infrastructure: </b>It will be built as
                      an open source, open standard and interoperable public
                      good. This will enable inclusive, farmer-centric solutions
                      through relevant information services for crop planning
                      and health and improved access to farm inputs, credit and
                      insurance.
                    </li>
                    <br />
                    <li>
                      <b>Agriculture Accelerator Fund:</b>The Fund is meant to
                      encourage Startups in agriculture by young entrepreneurs
                      in rural areas. It aims to bring modern technology to
                      increase agricultural productivity and profitability.
                    </li>
                    <br />
                    <li>
                      <b>Agriculture Credit:</b>The agriculture credit target
                      has been increased to Rs 20 lakh crore with a focus on
                      animal husbandry, dairy and fisheries.
                    </li>
                    <br />
                    <li>
                      <b>PM Matsya Sampada Yojana:</b>A new scheme with a
                      targeted investment of Rs 6,000 crore has been launched to
                      enable activities of fishermen and fish vendors and
                      improve value chain efficiencies.
                    </li>
                    <br />
                    <li>
                      <b>Storage:</b>A plan will be implemented to set up
                      decentralised storage capacity to help farmers store their
                      produce and realise remunerative prices through sale at
                      appropriate times.
                    </li>
                    <br />
                    <li>
                      <b>Cooperatives:</b> The government will also facilitate
                      setting up of multipurpose cooperative societies, primary
                      fishery societies and dairy cooperative societies in
                      uncovered panchayats and villages in the next five years.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />
                  <p>
                    Tamil Nadu has allocated 6.5% of its expenditure towards
                    agriculture for 2023-24. This is higher than the average
                    allocation towards agriculture by States (5.8%).
                  </p>
                  <ul>
                    <li>
                      <b>
                        Kalaignarin All Village Integrated Agricultural
                        Development Programme:
                      </b>
                      This scheme is designed for the holistic development of
                      agriculture in hamlets based on the availability of water
                      and soil fertility, through the creation of necessary
                      infrastructure .This scheme is designed for the holistic
                      development of agriculture in hamlets based on the
                      availability of water and soil fertility, through the
                      creation of necessary infrastructure .
                    </li>
                    <br />
                    <li>
                      <b>Tamil Nadu Millet Mission:</b>Under the Tamil Nadu
                      Millet Mission, subsidy is given for bringing millet
                      cultivation to fallow lands and crop diversification to
                      millets in 50,000 acres.
                    </li>
                    <br />
                    <li>
                      <b>Digital Agriculture : </b>Grower Online Registration of
                      Agriculture Input System is being introduced in Tamil Nadu
                      in which the basic details like bank account, Aadhaar
                      number of landowners and cultivators, land details and
                      crop cultivation details will be collected and digitised.
                      This platform will enable the farmers to avail various
                      benefits from a single source (one stop solution).
                    </li>
                    <br />
                    <li>
                      <b>
                        Cultivation of Horticultural Crops on a cluster basis :{" "}
                      </b>
                      Exclusive clusters are to be formed for horticultural
                      crops such as jasmine, curry leaves, chillies and
                      jackfruit in districts where they are cultivated on a
                      large scale, to give special focus on production, value
                      addition and export. The scheme will be implemented by
                      creating an exclusive brand for the produce generated from
                      the cluster and link the farmers with the domestic and
                      global value chains, thus providing higher income to the
                      farmers and improving their livelihoods.
                    </li>
                    <br />
                    <li>
                      Moringa Mission :Considering the multiple health benefits
                      of Moringa, the Government of Tamil Nadu has announced a
                      Special Export Zone for Moringa, covering Theni, Dindigul,
                      Karur, Thoothukudi, Tirupur, Ariyalur and Madurai
                      districts. A Special Export Facilitation Centre is
                      functioning in Madurai.
                    </li>
                    <br />
                    <li>
                      Palmyra Development Mission: To encourage Palmyra
                      cultivation, ten lakh Palmyra seed nuts are provided to
                      farmers and NGOs to plant in public places like
                      riverbanks.
                    </li>
                    <br />
                    <li>
                      Securing Geographical Indication tag: It is proposed to
                      get Geographical Indication (GI) for ten more products –
                      Krishnagiri Arasampatti Coconut, Krishnagiri Paneer Rose,
                      Thanjavur Peravoorani Coconut, Mulanoor Kuttai Moringa,
                      Sattur Cucumber, Thanjavur Veeramangudi Jaggery (Achu
                      vellam), Thoothukudi Vilathikulam Chilli, Cuddalore
                      Kotimulai Brinjal, Madurai Sengarumbu and Sivagangai
                      Karuppukavuni rice.
                    </li>
                    <br />
                    <li>
                      Banana Research Centre: To address the needs of farmers of
                      the southern districts, an exclusive research centre for
                      Banana is to be established at Agricultural College,
                      Killikulam, Thoothukudi district.
                    </li>
                    <br />
                    <li>
                      Tamil Nadu Food Processing and Agri Export Promotion
                      Corporation (TN-ApEx) : This is a lead organisation for
                      the promotion of food processing and agricultural exports.
                      Assistance to establish micro food processing units and
                      creation of common infrastructure is provided under Prime
                      Minister Formalization of Micro Food Processing
                      Enterprises and State Government funds.
                    </li>
                    <br />
                    <li>
                      Skill development training for rural youth: Skill
                      development training is imparted to 500 rural youth for
                      operation, maintenance and handling of tractors and
                      harvesters in six workshops functioning at Vellore,
                      Coimbatore, Tiruchi, Madurai, Tiruvarur and Tirunelveli.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Food Park</h5>
                  <br />
                  <p>
                    Food park is a place where food processing units are
                    established predominantly for production of processable
                    agriculture, horticulture, animal husbandry, meat, poultry,
                    dairy and fisheries products.
                  </p>
                  <ul>
                    <li>
                      Eight food parks are being established in the State in a
                      phased manner by the Department of Agricultural Marketing
                      and Agri. Business.
                    </li>
                    <br />
                    <li>
                      A mega food Park is being established on an area of 53.36
                      acres at Gangaikondan, Tirunelveli district.
                    </li>
                    <br />
                    <li>
                      {" "}
                      Apart from the above, eight agro processing clusters are
                      coming up in different parts of the State under the
                      Pradhan Mantri Kisan SAMPADA Yojana.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    INCUBATION CENTRES – Agri & Allied Sector
                  </h5>
                  <br />
                  <ul>
                    <li>TamilNadu Organic Farming Policy 2023</li>
                    <br />
                    <li>
                      https://drive.google.com/file/d/1UuDHa4wTQqT7vpGc_TYecf3CatWVD-ol/view?usp=sharing
                    </li>
                    <br />
                    <li>TamilNadu Food Processing Policy 2018</li>
                    <br />
                    <li>
                      https://drive.google.com/file/d/1UuDHa4wTQqT7vpGc_TYecf3CatWVD-ol/view?usp=sharing
                    </li>
                    <br />
                  </ul>
                  <p style={{ color: "#39364e" }}>Useful Links</p>
                  <ul>
                    <li>http://www.tnagriculture.in/dashboard/book</li>
                    <br />
                    <li>https://des.tn.gov.in/en/node/346</li>
                    <br />
                    <li>https://www.tnagrisnet.tn.gov.in/home/index/en</li>
                    <li>https://www.tanuvas.ac.in/</li>
                    <li>https://agritech.tnau.ac.in/</li>
                    <li>https://www.tnjfu.ac.in/</li>
                    <li>
                      https://www.indiastat.com/tamil-nadu-state/data/agriculture
                    </li>
                    <li>
                      https://play.google.com/store/apps/details?id=agri.tnagri&hl=ta&pli=1
                    </li>
                    <li>https://tnscm.co.in/about-tnscm/</li>
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <ul>
                    <li>
                      <b>TNSCM:</b> Tamil Nadu Supply Chain Management Project
                      for fruits, vegetables and other perishables. The project
                      aims to establish a supply chain with the required social
                      and physical infrastructure through proper market
                      integration to provide primarily processed fruits,
                      vegetables and other perishables in multiple forms. In
                      this project, Tamil Nadu is divided into 10 Production
                      Clusters.
                    </li>
                    <br />
                    <li>
                      <b>Regulated Markets:</b> They are established to better
                      regulate the buying and selling of agricultural produce.
                      In Tamil Nadu, 278 Regulated Markets are functioning under
                      23 Market Committees to enforce the provisions of Tamil
                      Nadu Agricultural Produce Marketing (Regulation) Act 1987
                      and Rules 1991 to ensure fair prices to farmers, reduce
                      marketing charges, protect farmers from the hands of
                      traders and provide better marketing facilities, basic
                      infrastructure, etc.
                    </li>
                    <br />
                    <li>
                      <b>Uzhavar Santhai:</b> In Tamil Nadu, there are 185
                      Uzhavar Santhais, the places where both farmers, as well
                      as consumers, get benefits.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Agricultural Research Stations
                  </h5>
                  <br />
                  <ul>
                    <li>Agricultural Research Station, Bhavanisagar</li>
                    <br />
                    <li>Agricultural Research Station, Kovilpatti</li>
                    <br />
                    <li>Agricultural Research Station, Paramakudi</li>
                    <br />
                    <li>Agricultural Research Station, Thirupathisaram</li>
                    <br />
                    <li>Agricultural Research Station, Vaigaidam</li>
                    <br />
                    <li>Agricultural Research Station, Virinjipuram</li>
                    <br />
                    <li>Agricultural Research Station, Pattukottai</li>
                    <br />
                    <li>Centre of Excellence in Millets, Athiyanthal</li>
                    <br />
                    <li>Coastal Saline Research Centre, Ramanathapuram</li>
                    <br />
                    <li>Cotton Research Station, Srivilliputhur</li>
                    <br />
                    <li>Cotton Research. Station, Perambalur</li>
                    <br />
                    <li>Dryland Agricultural Research Station, Chettinadu</li>
                    <br />
                    <li>Hybrid Rice Evaluation Centre, Gudalur</li>
                    <br />
                    <li>Maize Research Station, Vagarai</li>
                    <br />
                    <li>National Pulses Research Centre, Vamban</li>
                    <br />
                    <li>Oilseed Research Station, Tindivanam</li>
                    <br />
                    <li>Rice Research Station, Ambasamudram</li>
                    <br />
                    <li>Rice Research Station, Tirur</li>
                    <br />
                    <li>Regional Research Station, Aruppukottai</li>
                    <br />
                    <li>Regional Research Station, Paiyur</li>
                    <br />
                    <li>Regional Research Station, Vridhachalam</li>
                    <br />
                    <li>Sugarcane Research Station, Cuddalore</li>
                    <br />
                    <li>Sugarcane Research Station, Melalathur</li>
                    <br />
                    <li>Sugarcane Research Station, Sirugamani,</li>
                    <br />
                    <li>
                      Soil and Water Management Research Institute, Thanjavur
                    </li>
                    <br />
                    <li>Tamil Nadu Rice Research Institute, Aduthurai</li>
                    <br />
                    <li>Tapioca and Castor Research Station, Yethapur</li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Veterinary Research Stations
                  </h5>
                  <br />
                  <ul>
                    <li> Livestock Farm Complex, Chennai</li>
                    <br />
                    <li>Poultry Research Station, Chennai</li>
                    <br />
                    <li>
                      Postgraduate Research Institute in Animal Sciences,
                      Kattupakkam
                    </li>
                    <br />
                    <li>Institute of Animal Nutrition, Kattupakkam</li>
                    <br />
                    <li>Central Feed Technology Unit, Kattupakkam</li>
                    <br />
                    <li>Agricultural Research Station, Virinjipuram</li>
                    <br />
                    <li>
                      Regional Research and Educational Centre, Pudukkottai
                    </li>
                    <br />
                    <li>Mecheri Sheep Research Station, Potteneri</li>
                    <br />
                    <li>
                      Sheep Breeding Research Station, Sandynallah, The Nilgiris
                    </li>
                    <br />
                    <li>Bargur Cattle Research Station, Bargur</li>
                    <br />
                    <li>Kangayam Cattle Research Station, Sathyamangalam</li>
                    <br />
                    <li>Pulikulam Cattle Research Station, Manamadurai</li>
                    <br />
                    <li>Alambadi Cattle Breed Research Centre, Dharmapuri</li>
                    <br />
                  </ul>
                  <h4 style={{ color: "#39364e" }}>
                    INCUBATION CENTRES – Agri & Allied Sector
                  </h4>
                  <br />
                  <p style={{ color: "#39364e" }}>Agriculture</p>
                  <p style={{ color: "#39364e" }}>
                    TNAU has eight incubation centres
                  </p>
                  <ul>
                    <li>
                      Technology Business Incubator, DADB, TNAU, Coimbatore
                    </li>
                    <br />
                    <li>
                      Post Harvest Technology Centre, Technology Business
                      Incubator, Coimbatore,
                    </li>
                    <br />
                    <li>
                      Madurai Agribusiness Incubation Forum, Agricultural
                      College and Research Institute, Madurai.
                    </li>
                    <br />
                    <li>
                      EDII-Tiruchi AgriBusines Incubation Forum, Agricultural
                      College, Tiruchi.
                    </li>
                    <br />
                    <li>
                      EDII-Killikulam AgriBusiness Incubation Forum,
                      Agricultural College, Killikulam.
                    </li>
                    <br />
                    <li>
                      EDII- Periyakulam HortiBusiness Incubation Forum,
                      Horticulture College, Periyakulam.
                    </li>
                    <br />
                    <li>
                      EDII- Mettupalayam AgroForestry Business Incubation Forum,
                      Forest College, Mettupalayam.
                    </li>
                    <br />
                  </ul>
                  <p style={{ color: "#39364e" }}>Food</p>
                  <ul>
                    <li>
                      Periyar University Business Incubation Confederation,
                      Periyar University, Salem.
                    </li>
                    <br />
                    <li>
                      EDII- Marine Products Business Incubation Forum, Fisheries
                      College, Thoothukudi.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FoodTech;
