import { Button, Card, Grid } from '@mui/material'
import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import { useEffect } from 'react';
import { IoLocationOutline } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";

const InvEventNotification = () => {

  useEffect(() => {
    size();
  }, []);

  const [screenSize, setScreenSize] = useState(4);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(3);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(2);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(2);
    } else {
      setScreenSize(1);
    }
  };

  return (
    <>
      <section>
        <div className='event-noti my-4'>
          <h5>Event Notification</h5>
        </div>

        <div className='eventnotihead'>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item sm={12} md={4} lg={3}>
              <img src='/images/event.png' className='img-fluid' alt='' />
            </Grid>
            <Grid item sm={12} md={4} lg={6}>
              <div>
                <h4>StartupTN - Sai Ranganathan Invited you to Investor Connect Program</h4>
                <div className="events-date-box mt-3">
                  <span className="date-blue-box-surya mr-2">
                    <p className='mb-0'>09</p>
                    <p className='mb-0'>May</p>
                  </span>
                  <span className='investor-time-loc'>
                    <p className='mb-0'>
                      <span><img src='/images/Vector-clock.png' alt='' className='img-fluid' /> &nbsp; 11.00 A.M - 05:00 P.M</span>
                    </p>
                    <p className='mb-0'>
                      <span><img src='/images/Vector-location.png' alt='' className='img-fluid' /> &nbsp; StartupTN Office</span>
                    </p>
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item sm={12} md={4} lg={3} sx={{ textAlign: 'right' }}>
              <Button className='investornotibtn'>
                I'm Interesed
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className='swiper-backg'>
          <h5>Investment Related Events</h5>
          <Swiper
            slidesPerView={screenSize}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="event-box-surya">
                <div className="">
                  <img
                    src="/images/event-noti-swiper1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="event-content">
                  <div>
                    <h6>Investor Connect Event</h6>
                  </div>
                  <div className="feeds-loaction">
                    <IoLocationOutline />
                    <span className="ml-2">StartupTN Office</span>
                  </div>
                </div>
                <div className="event-days">
                  <div>
                    <FaCalendarAlt />
                    <span className="ml-2 text-dark">11th May,2024</span>
                  </div>
                  <div>
                    <FaRegClock />
                    <span className="ml-2 text-dark">03 - 06 p.m</span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "100%",
                    marginTop: "10px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  Register Now
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="event-box-surya">
                <div className="">
                  <img
                    src="/images/event-noti-swiper2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="event-content">
                  <div>
                    <h6>Investor Connect Event</h6>
                  </div>
                  <div className="feeds-loaction">
                    <IoLocationOutline />
                    <span className="ml-2">StartupTN Office</span>
                  </div>
                </div>
                <div className="event-days">
                  <div>
                    <FaCalendarAlt />
                    <span className="ml-2 text-dark">11th May,2024</span>
                  </div>
                  <div>
                    <FaRegClock />
                    <span className="ml-2 text-dark">03 - 06 p.m</span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "100%",
                    marginTop: "10px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  Register Now
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="event-box-surya">
                <div className="">
                  <img
                    src="/images/event-noti-swiper1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="event-content">
                  <div>
                    <h6>Investor Connect Event</h6>
                  </div>
                  <div className="feeds-loaction">
                    <IoLocationOutline />
                    <span className="ml-2">StartupTN Office</span>
                  </div>
                </div>
                <div className="event-days">
                  <div>
                    <FaCalendarAlt />
                    <span className="ml-2 text-dark">11th May,2024</span>
                  </div>
                  <div>
                    <FaRegClock />
                    <span className="ml-2 text-dark">03 - 06 p.m</span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "100%",
                    marginTop: "10px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  Register Now
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="event-box-surya">
                <div className="">
                  <img
                    src="/images/event-noti-swiper2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="event-content">
                  <div>
                    <h6>Investor Connect Event</h6>
                  </div>
                  <div className="feeds-loaction">
                    <IoLocationOutline />
                    <span className="ml-2">StartupTN Office</span>
                  </div>
                </div>
                <div className="event-days">
                  <div>
                    <FaCalendarAlt />
                    <span className="ml-2 text-dark">11th May,2024</span>
                  </div>
                  <div>
                    <FaRegClock />
                    <span className="ml-2 text-dark">03 - 06 p.m</span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "100%",
                    marginTop: "10px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  Register Now
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="event-box-surya">
                <div className="">
                  <img
                    src="/images/event-noti-swiper2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="event-content">
                  <div>
                    <h6>Investor Connect Event</h6>
                  </div>
                  <div className="feeds-loaction">
                    <IoLocationOutline />
                    <span className="ml-2">StartupTN Office</span>
                  </div>
                </div>
                <div className="event-days">
                  <div>
                    <FaCalendarAlt />
                    <span className="ml-2 text-dark">11th May,2024</span>
                  </div>
                  <div>
                    <FaRegClock />
                    <span className="ml-2 text-dark">03 - 06 p.m</span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "100%",
                    marginTop: "10px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  Register Now
                </Button>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="event-box-surya">
                <div className="">
                  <img
                    src="/images/event-noti-swiper1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="event-content">
                  <div>
                    <h6>Investor Connect Event</h6>
                  </div>
                  <div className="feeds-loaction">
                    <IoLocationOutline />
                    <span className="ml-2">StartupTN Office</span>
                  </div>
                </div>
                <div className="event-days">
                  <div>
                    <FaCalendarAlt />
                    <span className="ml-2 text-dark">11th May,2024</span>
                  </div>
                  <div>
                    <FaRegClock />
                    <span className="ml-2 text-dark">03 - 06 p.m</span>
                  </div>
                </div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "100%",
                    marginTop: "10px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  Register Now
                </Button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

      </section >
    </>
  )
}

export default InvEventNotification