import React, { useContext, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, Tabs, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import notifyService from '../../../../../api/notifySerivce';
import DataContext from '../../../../../context/DataContext';

const InvAddAppointmentModal = ({ open, onClose }) => {
  const { setPageLoading, VisuallyHiddenInput } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, reset, formState: { errors } } = useForm({ values: formData });

  const onSubmit = (data) => {
    setPageLoading(true)
    // var req = {
    //   // id: id,
    //   remarks: data.remarks,
    // };
    // apiService('assign/save', req, "post").then((res) => {
    //   setPageLoading(false)
    //   if (res) {
    //     if (res.data.responseStatus === "Success") {
    //       // listApi()
    //       handleCloseModal()
    //       notifyService("success", "Success", "");
    //     }
    //   }
    // });
  }
  const onError = (data) => {
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }

  return (
    <>
      <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogTitle>
          Add Appointment
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 44,
              top: 30,
              backgroundColor: 'red',
              borderRadius: '50%',
              color: 'white',
              padding: '1px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='m-0'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box className='bx-sh'>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Title is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Add Title"
                      placeholder="Please Enter Title"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.title && errors.title.message}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className='bx-sh' >
                <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Date is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Date"
                      placeholder="Please Enter Date"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.date && errors.date.message}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className='bx-sh'>
                <Controller
                  name="startTime"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Time is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Start Time"
                      placeholder="Please Enter Time"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.startTime && errors.startTime.message}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className='bx-sh'>
                <Controller
                  name="endTime"
                  control={control}
                  defaultValue=""
                  rules={{ required: "End Time is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="End Time"
                      placeholder="Please Enter Time"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.endTime && errors.endTime.message}
                </FormHelperText>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions >
          <div className='text-center mb-3 w-100'>
            <Button onClick={onClose} className="discard-btn">Close</Button>
            <Button
              type="submit" className="publish-button"
            >
              <span>Save</span>
            </Button>
          </div>
        </DialogActions>
      </form>
    </>
  )
}

export default InvAddAppointmentModal