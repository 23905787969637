import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa6";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

const UserSchemeservice = () => {
  const service = [
    "Funding",
    "Mentoring",
    "Literacy",
    "Smart Card Services",
    "Market Access",
    "Incubation",
    "Co- Working Spaces",
    "Others",
  ];
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (service) => {
    setActiveButton(service);
  };
  useEffect(() => {
    setActiveButton("Funding");
    size()
  }, []);
  const [screenSize, setScreenSize] = useState(2)
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(3)
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(3)
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(2)
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(2)
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(2)
    } else {
      setScreenSize(1)
    }
  }
 
  return (
    <div className="scheme-bg">
      <div className="">
        <div className="scheme-box-1">
          <div className="scheme-search">
            <input
              type="input"
              placeholder="Search for Services What You’re Looking for"
              class="form-control input-height"
            />
            <div className="scheme-search-button search-icon-profile">
              <Button
                variant="contained"
                startIcon={<BiSearchAlt />}
                sx={{
                  backgroundColor: "#020671",
                  textTransform: "none",
                 
                  "&:hover": { backgroundColor: "#020671" },
                }}
              >
                Search
              </Button>
            </div>
            <div className="scheme-search-button search-icon-profile-res">
            <BiSearchAlt/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="Services-box">
              <span>Services</span>
              <div className="service-name mt-3">
                {/* <h6>Funding</h6> */}

                {/* {service &&
                    service.map((option) => (
                      <Button variant="contained" sx={{  backgroundColor: '#020671', width:'100%',padding:'10px',margin:'10px', textTransform:'none','&:hover':{backgroundColor:'#020671'} }}>{option} </Button>
                    ))} */}
                {service.map((service) => (
                  <Button
                    key={service}
                    variant="contained"
                    onClick={() => handleButtonClick(service)}
                    sx={{
                      backgroundColor:
                        activeButton === service ? "#020671" : "transparent",
                      width: "100%",
                      padding: "10px",
                      margin: "10px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      fontWeight: "600",
                      textTransform: "none",
                      border: "1px solid",
                      borderColor: activeButton === service ? "#020671" : "",
                      color: activeButton === service ? "white" : "#020671",
                      "&:hover": {
                        backgroundColor:
                          activeButton === service ? "#020671" : "transparent",
                      },
                    }}
                  >
                    {service}
                  </Button>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="Services-box">
              <span>Funding Services</span>
              <div className="funding-content">
                <div className="funding-logo">
                  <img src="/images/logo-5.png" alt="" className="img-fluid" />
                </div>
                  <div className="funding-details">
                    <h6>TANSEED 6.0</h6>
                    <span>StartupTN</span>
                    <p>
                      TANSEED, shall support early stage financing requirements
                      of the Startups in the form of grants to fill the gap in
                      fund requirement for research & innovations, anything from
                      mind to marketplace
                    </p>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#020671",
                        "&:hover": { backgroundColor: "#020671" },
                      }}
                    >
                      Apply
                    </Button>
                  </div>
              </div>
              <div className="funding-content">
                <div className="funding-logo">
                  <img src="/images/logo-5.png" alt="" className="img-fluid" />
                </div>
                  <div className="funding-details">
                    <h6>TANSEED 6.0</h6>
                    <span>StartupTN</span>
                    <p>
                      TANSEED, shall support early stage financing requirements
                      of the Startups in the form of grants to fill the gap in
                      fund requirement for research & innovations, anything from
                      mind to marketplace
                    </p>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#020671",
                        "&:hover": { backgroundColor: "#020671" },
                      }}
                    >
                      Apply
                    </Button>
                  </div>
              </div>
              <div className="funding-content">
                <div className="funding-logo">
                  <img src="/images/logo-5.png" alt="" className="img-fluid" />
                </div>
                  <div className="funding-details">
                    <h6>TANSEED 6.0</h6>
                    <span>StartupTN</span>
                    <p>
                      TANSEED, shall support early stage financing requirements
                      of the Startups in the form of grants to fill the gap in
                      fund requirement for research & innovations, anything from
                      mind to marketplace
                    </p>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#020671",
                        "&:hover": { backgroundColor: "#020671" },
                      }}
                    >
                      Apply
                    </Button>
                  </div>
              </div>
              <div className="funding-content">
                <div className="funding-logo">
                  <img src="/images/logo-5.png" alt="" className="img-fluid" />
                </div>
                  <div className="funding-details">
                    <h6>TANSEED 6.0</h6>
                    <span>StartupTN</span>
                    <p>
                      TANSEED, shall support early stage financing requirements
                      of the Startups in the form of grants to fill the gap in
                      fund requirement for research & innovations, anything from
                      mind to marketplace
                    </p>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#020671",
                        "&:hover": { backgroundColor: "#020671" },
                      }}
                    >
                      Apply
                    </Button>
                  </div>
              </div>
            </div>
            <div className="Services-box">
              <span>Events</span>
              <div>
              <Swiper
        slidesPerView={screenSize}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
          
          <SwiperSlide>
        <div className="event-box">
             <div className="">
             <img src="/images/gov.png" alt="" className="img-fluid" />
             </div>
             <div className="event-content">
              <div>
              <h6>Startup Thamizha Road Show</h6>
              </div>
              <div className="feeds-loaction">
              <IoLocationOutline />
              <span className="ml-2">StartupTN Office</span>
              </div>
              
             </div>
             <div className="event-days">
                <div>
              <FaCalendarAlt />
              <span className="ml-2 text-dark">11th May,2024</span>
              </div>
                <div>
                <FaRegClock />
              <span className="ml-2 text-dark">03 - 06 p.m</span>
              </div>
              </div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#020671",
                  width:'100%',
                  marginTop:'10px',
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#020671" },
                }}
              >
                Register Now
              </Button>
            </div>
        </SwiperSlide>
          
          <SwiperSlide>
        <div className="event-box">
             <div className="">
             <img src="/images/gov.png" alt="" className="img-fluid" />
             </div>
             <div className="event-content">
              <div>
              <h6>Startup Thamizha Road Show</h6>
              </div>
              <div className="feeds-loaction">
              <IoLocationOutline />
              <span className="ml-2">StartupTN Office</span>
              </div>
              
             </div>
             <div className="event-days">
                <div>
              <FaCalendarAlt />
              <span className="ml-2 text-dark">11th May,2024</span>
              </div>
                <div>
                <FaRegClock />
              <span className="ml-2 text-dark">03 - 06 p.m</span>
              </div>
              </div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#020671",
                  width:'100%',
                  marginTop:'10px',
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#020671" },
                }}
              >
                Register Now
              </Button>
            </div>
        </SwiperSlide>
          <SwiperSlide>
        <div className="event-box">
             <div className="">
             <img src="/images/gov.png" alt="" className="img-fluid" />
             </div>
             <div className="event-content">
              <div>
              <h6>Startup Thamizha Road Show</h6>
              </div>
              <div className="feeds-loaction">
              <IoLocationOutline />
              <span className="ml-2">StartupTN Office</span>
              </div>
              
             </div>
             <div className="event-days">
                <div>
              <FaCalendarAlt />
              <span className="ml-2 text-dark">11th May,2024</span>
              </div>
                <div>
                <FaRegClock />
              <span className="ml-2 text-dark">03 - 06 p.m</span>
              </div>
              </div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#020671",
                  width:'100%',
                  marginTop:'10px',
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#020671" },
                }}
              >
                Register Now
              </Button>
            </div>
        </SwiperSlide>
          <SwiperSlide>
        <div className="event-box">
             <div className="">
             <img src="/images/gov.png" alt="" className="img-fluid" />
             </div>
             <div className="event-content">
              <div>
              <h6>Startup Thamizha Road Show</h6>
              </div>
              <div className="feeds-loaction">
              <IoLocationOutline />
              <span className="ml-2">StartupTN Office</span>
              </div>
              
             </div>
             <div className="event-days">
                <div>
              <FaCalendarAlt />
              <span className="ml-2 text-dark">11th May,2024</span>
              </div>
                <div>
                <FaRegClock />
              <span className="ml-2 text-dark">03 - 06 p.m</span>
              </div>
              </div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#020671",
                  width:'100%',
                  marginTop:'10px',
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#020671" },
                }}
              >
                Register Now
              </Button>
            </div>
        </SwiperSlide>
         
        
       
     
      </Swiper>
              </div>
              </div>
              <div className="Services-box">
              <span>GoTN - Departments for Funding Services</span>
              <div className="mt-4">
              <Swiper
        slidesPerView={4}
        spaceBetween={30}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
         <SwiperSlide>
         <img src="/images/Frame-1.png" alt="" className="img-fluid" />
        </SwiperSlide>
         <SwiperSlide>
         <img src="/images/Frame-2.png" alt="" className="img-fluid" />
        </SwiperSlide>
         <SwiperSlide>
         <img src="/images/Frame-3.png" alt="" className="img-fluid" />
        </SwiperSlide>
         <SwiperSlide>
         <img src="/images/Frame-4.png" alt="" className="img-fluid" />
        </SwiperSlide>
         <SwiperSlide>
         <img src="/images/Frame-5.png" alt="" className="img-fluid" />
        </SwiperSlide>
        </Swiper>
              </div>
              </div>
          </div>
          <div className="col-lg-3">
            <div className="Services-box service-res">
              <span>Top Feeds </span>
             <div className="mt-3 service-img text-center">
             <img src="/images/gov.png" alt="" className="img-fluid" />
             </div>
             <div className="Feeds-content">
              <div>
              <h6>Startup Thamizha Road Show</h6>
              </div>
              <div className="feeds-loaction">
              <IoLocationOutline />
              <span className="ml-2">Madurai</span>
              </div>
              
             </div>
             <div className="feeds-days">
                <div>
              <FaCalendarAlt />
              <span className="ml-2 text-dark">11th May,2024</span>
              </div>
                <div>
                <FaRegClock />
              <span className="ml-2 text-dark">03 - 06 p.m</span>
              </div>
              </div>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#020671",
                  width:'100%',
                  marginTop:'10px',
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#020671" },
                }}
              >
                Register Now
              </Button>
            </div>
            <div className="Services-box">
              <span>Our Latest News</span>
              <div className="funding-contents">
                <div className="News-logo">
                  <img src="/images/news-img.png" alt="" className="img-fluid" />
                </div>
                  <div className="news-detailss">
                    <h6 className="m-0">StartupTN launches Agri and Food Tech Forum at TNAU, Coimbatore</h6>
                    <p className="m-0">As part of its efforts to make Tamil Nadu the preferred global startup destination, StartupTN...</p>
                    <span>7 Mar 2024</span>
                  </div>
              </div>
              <div className="funding-contents">
                <div className="News-logo">
                  <img src="/images/news-img.png" alt="" className="img-fluid" />
                </div>
                  <div className="news-detailss">
                    <h6 className="m-0">StartupTN launches Agri and Food Tech Forum at TNAU, Coimbatore</h6>
                    <p className="m-0">As part of its efforts to make Tamil Nadu the preferred global startup destination, StartupTN...</p>
                    <span>7 Mar 2024</span>
                  </div>
              </div>
              <div className="funding-contents">
                <div className="News-logo">
                  <img src="/images/news-img.png" alt="" className="img-fluid" />
                </div>
                  <div className="news-detailss">
                    <h6 className="m-0">StartupTN launches Agri and Food Tech Forum at TNAU, Coimbatore</h6>
                    <p className="m-0">As part of its efforts to make Tamil Nadu the preferred global startup destination, StartupTN...</p>
                    <span>7 Mar 2024</span>
                  </div>
              </div>
              <div className="funding-contents">
                <div className="News-logo">
                  <img src="/images/news-img.png" alt="" className="img-fluid" />
                </div>
                  <div className="news-detailss">
                    <h6 className="m-0">StartupTN launches Agri and Food Tech Forum at TNAU, Coimbatore</h6>
                    <p className="m-0">As part of its efforts to make Tamil Nadu the preferred global startup destination, StartupTN...</p>
                    <span>7 Mar 2024</span>
                  </div>
              </div>
              </div>
          </div>
        </div>
      </div>      
    </div>

    
  );
};

export default UserSchemeservice;
