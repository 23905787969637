import { Badge } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Typography, Stack, Pagination } from '@mui/material';
import DataContext from '../../../../context/DataContext';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import apiService from '../../../../api/apiService';


const InvSupportDeckList = () => {
  const { navigator, getSupportStatus, setPageLoading } = useContext(DataContext);
  const [ticketStatus, setTicketStatus] = useState(1)
  const handleTicketStatus = (value) => {
    setTicketStatus(value)
  }
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  useDidMountEffect(()=>{
    listApi()
  },[])
  const listApi = () => {
    setPageLoading(true)
    setSupportList([])
    var req = {
      listSize: 12,
      pageNumber: page,
      status: ticketStatus,      
    }
    apiService('support/ticket/list', req, 'post').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        setTotalPage(result.data.totalPages);
        if (result.data.responseDTOList) {
          setSupportList(result.data.responseDTOList);
        }
      }
    }).catch((err) => {

    });
  }
  const [supportList, setSupportList] = useState([])
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <div>
      <header className='team_header'>
        <div>
          <h4 className='mb-4'>Support Ticket</h4>
          <div className='d-flex align-items-center'>
            <p className={ticketStatus === 1 ? 'filiter_p die pr-4 active' : 'filiter_p die pr-4'} onClick={() => handleTicketStatus(1)}>All Support</p>
            <p className={ticketStatus === 0 ? 'filiter_p pl-4 active' : 'filiter_p pl-4'} onClick={() => handleTicketStatus(0)}>
              Pending
              <Badge color="error" badgeContent={10} className='ml-4'>
              </Badge>
            </p>
          </div>
        </div>
      </header>
      <div className='team_list_div'>
        {
          supportList.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                <TableHead>
                  <TableRow className='bar'>
                    <TableCell>Organization Name</TableCell>
                    <TableCell>Query Title</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ position: 'relative' }}>
                  {supportList.map((list, i) => (
                    <>
                      <TableRow
                        sx={{
                          position: 'relative',
                          '&:after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            border: '1px solid rgba(224, 224, 224, 1)',
                            borderRadius: '20px',
                            pointerEvents: 'none',
                            marginBottom: '-1px',
                          },
                          '& > *': {
                            padding: '10px',
                            backgroundColor: '#fff',
                            '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                          },
                          marginBottom: '10px',
                        }}
                        key={i}
                        className="Sup-list"
                      >
                        <TableCell>
                          <Typography variant="h6" className="list-2-clr">{list.serviceName}</Typography>
                          <div className="list-2-dflex">
                            <span className="list-2-grey">Connect thru:</span>
                            <span className="list-2-black">{list.userProfile}</span>
                          </div>
                          <div>
                            <span className="list-2-grey">Smartcard ID:</span>
                            <span className="list-2-black">{list.smartCardNumber}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <b>{list.queryTitle}</b>
                        </TableCell>
                        <TableCell>
                          <span className='list-6-clr'>{getSupportStatus(list.status)}</span>
                        </TableCell>
                        <span className='date mt-2 mr-3'>{list.creationTime}</span>
                      </TableRow>
                      <span className='mt-2 mr-3'>{' '}</span>
                    </>
                  ))}
                </TableBody>
              </Table>
              {totalPage > 1 && (
                <div className="event-pagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChange}
                    />
                  </Stack>
                </div>
              )}
            </TableContainer>
          ) : (
            <div className='text-center p-5'>
              <h5 className='mb-4'>Investor support ticket not found</h5>
              <div>
                <img src="/images/tamilnadu_logo_high.svg" alt="tamilnadu_logo_high.svg" className='logo_dash img-fluid' />
              </div>
              <div className='mt-5'>
                <h3 className='title_p opa'>
                  {/* StartupTN 3.0 */}
                </h3>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default InvSupportDeckList