import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import DocumentsAspirants from './organization-info-page/DocumentsAspirants';
import SociallinksAspirants from './organization-info-page/SociallinksAspirants';
import AddressAspirants from './organization-info-page/AddressAspirants';
import BasicinfoAspirants from './organization-info-page/BasicinfoAspirants';
import Signuplayout from '../../auth-module/signup-details-page/Signuplayout';
import Quickbrief from '../../auth-module/signup-details-page/Quickbrief';
import MoreinfoAspirants from './organization-info-page/MoreinfoAspirants';
import QuickbriefAspirants from './organization-info-page/QuickbriefAspirants';
const Aspirantsroutes = () => {
    return (
        <div>
            <Routes>
                <Route path='/aspirants' element={<Signuplayout />}>
                    <Route path="/aspirants" element={<Navigate replace to="/aspirants/basicinfo" />} />
                    <Route path='/aspirants/basicinfo' element={<BasicinfoAspirants />} />
                    <Route path='/aspirants/address' element={<AddressAspirants />} />
                    <Route path='/aspirants/social-links' element={<SociallinksAspirants />} />
                    <Route path='/aspirants/documents' element={<DocumentsAspirants />} />
                    <Route path='/aspirants/moreinfo' element={<MoreinfoAspirants />} />                    
                    <Route path='/aspirants/quick-brief' element={<QuickbriefAspirants />} />
                </Route>
            </Routes>

        </div>
    )
}

export default Aspirantsroutes