import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Itites = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/startuptn-sector-IT-ITES.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector"> IT/ITES & SaaS, Software</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <p>
                    In the past few years, the Indian SaaS ecosystem has
                    witnessed a lot of growth as more than 35 Indian SaaS
                    enterprises had $20 million or more in annual recurring
                    revenue (ARR) in 2021 and investments in the space increased
                    by 170% over 2020, reaching $4.5 billion in 2021.
                  </p>
                  <ul>
                    <li>
                      India’s technology industry revenue is estimated to be
                      $245 Bn in FY 2023. Technology exports, at $194 Bn, are
                      expected to grow at 9.4% in reported currency terms. In
                      terms of FDI equity inflow, the computer software and
                      hardware sectors attracted the highest FDI in FY 2021-22.
                    </li>
                    <li>
                      Between April 2000 and December 2022, it attracted FDI
                      equity inflow of $93.58 Bn. India secured 1st rank in ‘AI
                      talent concentration’. Also, it was ranlked 5th in
                      ‘FTTH/Building Internet subscriptions’ and ‘AI scientific
                      publications’
                    </li>
                    <li>
                      India is one of the most preferred destinations for
                      setting up Global Capability Centres (GCCs) and it has
                      over 1570 GCCs. The total installed GCC talent is 1.38
                      Mn+. Over 45 new data centres are expected to come up by
                      2025. Data centres have attracted investment of $10 Bn
                      since 2020. There are 162.52 Mn registered users on the
                      DigiLocker in India.
                    </li>
                    <li>
                      Indian SaaS companies saw 2x growth in share of global
                      markets. The country has as many as 59 SaaS unicorns and
                      potential unicorns. Internet connections rose to 83.69
                      crore in 2022 from 25.15 crore in 2014.
                    </li>
                    <li>
                      The presence of optical fibre cable networks has increased
                      from less than 100 panchayats in 2014 to 1.7 lakh
                      panchayats in 2022. As per NASSCOM, the total amount of IT
                      export is estimated to be $194 Bn in FY 2023.
                    </li>
                    <li>
                      Till date, 63 STPI centres have been set up across the
                      country. Additionally, the government has approved 22 new
                      STPI centres.
                    </li>
                    <li>
                      The Union Government has announced GENESIS (Gen-Next
                      Support for Innovative Startups) to discover, support,
                      grow and make successful Startups in small cities with
                      emphasis on collaborative engagement among Startups,
                      government and corporates for promoting digitisation,
                      based on the principles of inclusivity, accessibility,
                      affordability, leading to growth in employment and
                      economic outputs.
                    </li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Policies of Union Government
                  </h5>
                  <ul>
                    <li>Special Economic Zones (SEZs)</li>
                    <li>National Strategy on Blockchain</li>
                    <li>National Cyber Security Policy</li>
                    <li>National Policy on Software Products</li>
                    <li>National Policy on Information Technology</li>
                    <li>National e-Governance Plan [NeGP]</li>
                    <li>Software Technology Parks (STP Scheme)</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <p>
                    Tamil Nadu has a thriving SaaS ecosystem with a robust
                    infrastructure, a skilled workforce and government support.
                    The SaaS industry in the State continues to grow and
                    contribute to the broader Indian and global IT landscape.
                  </p>
                  <ul>
                    <li>
                      <b>IT Parks and SEZs:</b> Tamil Nadu has several IT parks
                      and Special Economic Zones (SEZs) in cities like Chennai,
                      Coimbatore and Madurai, which provide infrastructure and
                      facilities for IT and SaaS companies. Some notable ones
                      include Tidel Park and MEPZ SEZ.
                    </li>
                    <li>
                      <b>TIDEL Park (Chennai):</b> This is one of the largest IT
                      parks in Tamil Nadu, particularly in Chennai. It offers
                      office space for IT companies, including those in the SaaS
                      sector, and facilitates networking and collaboration.
                    </li>
                    <li>
                      <b>IT Industry Associations:</b> Organisations like the
                      National Association of Software and Service Companies
                      (NASSCOM) and the Computer Society of India (CSI) have a
                      significant presence in Tamil Nadu. They provide support,
                      advocacy and networking opportunities for IT and SaaS
                      professionals.
                    </li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    State Government Policies
                  </h5>
                  <ul>
                    <li>Tamil Nadu Data Centre Policy 2021</li>
                    <li>Tamil Nadu Industrial Policy 2021</li>
                    <li>Tamil Nadu IT Policy 2018</li>
                    <li>Tamil Nadu & Startup Innovation Policy</li>
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <p>
                    Tamil Nadu is a prominent hub for the IT and SaaS (Software
                    as a Service) industries in India. Here are various
                    resources available for IT and SaaS companies and
                    professionals in Tamil Nadu:
                  </p>
                  <ul>
                    <li>
                      <b>Startup Incubators and Accelerators:</b> Many
                      incubators and accelerators, such as the Indian Institute
                      of Technology Madras (IITM) Incubation Cell and Nasscom
                      10,000 Startups, focus on nurturing early-stage IT and
                      SaaS Startups. They provide mentorship, resources and
                      access to a network of experts.
                    </li>
                    <li>
                      <b>Educational Institutions:</b> Tamil Nadu is home to
                      prestigious educational institutions like IIT Madras and
                      Anna University, which have technology and
                      entrepreneurship programmes. These institutions can be
                      sources of talent, research collaboration and
                      entrepreneurial support.
                    </li>
                    <li>
                      <b>Startup Hubs:</b> Startup hubs and coworking spaces
                      like The Startup Centre, WorksBee, and Coimbatore
                      Innovation and Business Incubator (CIBI) offer spaces for
                      IT and SaaS Startups to work, network and collaborate.
                    </li>
                    <li>
                      <b>Industry-Specific Events:</b> Industry-specific events
                      and conferences in Tamil Nadu, such as SaaSx and other
                      IT-related conferences. These events offer networking
                      opportunities and exposure to the local IT and SaaS
                      community.
                    </li>
                    <li>
                      <b>Chennai Tech Community: </b> Online forums and
                      communities like Chennai Geeks and other IT-focused groups
                      on platforms like LinkedIn can be great places to connect
                      with professionals and learn about industry trends.
                    </li>
                    <li>
                      <b>Investor Networks:</b> Angel investor and venture
                      capital networks in Tamil Nadu, as they can provide
                      funding opportunities for IT and SaaS Startups.
                      Organisations like The Chennai Angels are active in this
                      space.
                    </li>
                    <li>
                      <b>Legal and Regulatory Support:</b> Law firms and
                      organisations specialising in technology and IT law to
                      navigate legal and regulatory aspects of IT and SaaS
                      businesses.
                    </li>
                  </ul>
                  <p>
                    These resources collectively form a strong support system
                    for the IT and SaaS industries in Tamil Nadu. Leveraging
                    these resources can help IT and SaaS professionals and
                    companies thrive in the region.
                  </p>
                  <h5 style={{ color: "#39364e" }}>BPO, KPO, LPO</h5>
                  <p>
                    Well-developed tier II cities with lower real estate cost
                    and availability of technically qualified English-speaking
                    workforce is a huge attraction to BPO companies in Tamil
                    Nadu.
                  </p>
                  <h5 style={{ color: "#39364e" }}>Data Centres</h5>
                  <p>
                    Strategic location, excellent OFC network and surplus power
                    have ensured the emergence of Chennai as a hub for
                    commissioning, operation and maintenance of data centres.
                  </p>
                  <h5 style={{ color: "#39364e" }}>
                    Engineering and R&D Services
                  </h5>
                  <p>
                    A unique blend of manufacturing base and technology
                    ecosystem provides an ideal environment for research and
                    development centres.
                  </p>
                  <h5 style={{ color: "#39364e" }}>Centres of Excellence</h5>
                  <p>
                    The government encourages industry to collaborate with
                    academia in creating centres of excellence in emerging
                    technologies
                  </p>

                  <h5 style={{ color: "#39364e" }}>
                    Central Government Industry Associations
                  </h5>
                  <ul>
                    <li>Indian Software Product Industry Round Table</li>
                    <li>
                      National Association of Software and Services Companies
                    </li>
                    <li>Other Service Providers Association of India</li>
                    <li>Internet & Mobile Association of India (IAMAI)</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Tamil Nadu Industry Associations
                  </h5>
                  <ul>
                    <li>SaaSBoomi</li>
                    <li>ICT Academy</li>
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Itites;
