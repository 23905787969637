import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Navbar from "../../home-module/navbar-page/Navbar";

const Breakthebarrierspage = () => {

  const navigate = useNavigate();
  
  const handlenow = () => {
    navigate('/login')
  }
  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-texts">
                <span>{`Literacy   >  Break the Barriers`}</span>
                <h1 className="banner-text-gradient">
                  BREAK THE BARRIERS (THADAI ATHAI UDAI)
                </h1>
                <h6>
                  Entrepreneurship education plays a powerful role in unlocking
                  innovation
                </h6>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="smart-card-info">
        <div className="grid-1-hacka">
          <div className="hack-layer"></div>
          <Container>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="left-side-smartcardPage mt-5">
                  <h4>What is Break the Barriers (Thadai Athai Udai)? ?</h4>
                  <p className="mt-3">
                    Break-the-Barrier (Thadai Athai Udai) Thadai Athai Udai is a
                    series of motivational sessions conducted by StartupTN for
                    educational institutions interested in bringing
                    entrepreneurship development to their campus. Thadai Athai
                    Udai, which is conducted in partnership with educational
                    institutions and business communities, equips the trainees
                    with the skills and mindset required to succeed as
                    entrepreneurs.
                  </p>
                  <ul>
                    <li>Explore the startup ecosystem</li>
                    <li>Network with professionals</li>
                    <li>Build community with peers</li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="s2g-crd-img mt-5">
                  <img
                    className="s2g-imag"
                    src={"/images/startupLogo.png"}
                    alt="smart-card"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="how-works-div">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="works-section mt-5">
                  <h4>How it Works?</h4>
                  <p>
                    StartupTN conducts Thadai Athai Udai sessions across Tamil
                    Nadu with the objective of creating awareness, nurturing
                    innovation and fostering entrepreneurship in the state. They
                    target both students and young aspirants. The sessions
                    provide an overview of the critical first steps associated
                    with starting a venture.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="layer-bg-smcrd">
                  <div className="works-mesh-layer"></div>
                  <div className="how-works-details">
                    <div className="sm-crd-process">
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd-reg.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              01 <span> Coordination and Planning: </span>
                            </h3>
                            <p>
                              Organized by regional hubs of StartupTN in
                              partnership with institutions or bodies. Partner
                              accepts topics, resource persons, and
                              administrators.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/smcrd.png"}
                              alt="signup"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              02 <span>Partner Agreement:</span>
                            </h3>
                            <ul>
                              <li>
                                Acceptance from session partners for the
                                session.
                              </li>
                              <li>
                                Designated faculty/admin persons confirmed.
                              </li>
                              <li>Commitment to the required audience size.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/fill-application.png"}
                              alt="fill-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              03 <span>Expert Sessions:</span>
                            </h3>
                            <ul>
                              <li>2 to 3 hours of expert-led sessions.</li>
                              <li>
                                Resource persons coordinated by StartupTN.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/submit-application.png"}
                              alt="submit-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <div>
                              <h3>
                                04{" "}
                                <span>Co-Sponsorship / Co-Organization:</span>
                              </h3>
                            </div>
                            <div>
                              <p>
                                Sessions will be either co-sponsered or
                                co-organized by Startup-TN and partners
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/track-application.png "}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>

        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={5}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="join-us-now-box">
                <div className="join-us-now-content">
                  <h3>Break the Barriers(Thadai Athai Udai) </h3>
                  <p> Direct Startup Procurement for Government Efficiency</p>
                  <Button
                    variant="contained"
                    size="large"
                    className="join-now-btn"
                    onClick={handlenow}
                  >
                    Join us Now
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Breakthebarrierspage;
