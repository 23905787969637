import { Pagination, Stack, Grid, Divider } from "@mui/material";
import React, { useContext, useState } from "react";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import Mediafilter from "../Mediafilter";
import Norecordsfound from "../../../common-pages/Norecordsfound";
import { DisabledByDefault } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const { setPageLoading } = useContext(DataContext);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [showFull, setShowFull] = useState(false);
  const [showAllBlogs, setShowAllBlogs] = useState(20);

  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId, showAllBlogs]);
  const [eventList, setEventList] = useState([]);
  const [blogImage, setBlogImage] = useState([]);

  const showViewMoreButton = eventList.length > showAllBlogs;
  const listApi = () => {
    setEventList([]);
    setPageLoading(true);
    var req = {
      searchString: searchInputValue,
      categoryId: categoryId,
      listSize: showAllBlogs,
      pageNumber: page,
    };
    apiService(`blog/home/list`, req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.blog) {

            setEventList(result.data.blog);
            // setPara(result.data.blog.description);
            result.data.blog.forEach((item) => {
              // Assuming setPara and setEventList are state setters defined using useState
              setPara(item.description);
            });
            setTotalPage(result.data.totalPages);

          }
        }
      })
      .catch((err) => { });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [para, setPara] = useState("");
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };
  const wordLimit = 30;
  const displayText = showFull ? para : truncateText(para, wordLimit);
  const isTruncated = para.split(" ").length > wordLimit;
  const navigate = useNavigate();
  const toggleReadMore = (id) => {

    navigate(`/blog/details/${btoa(id)}`)

    // setShowFull(!showFull);
  };
  const handleupdatelist = () => {
    if (totalPage > 1) {
      setShowAllBlogs(showAllBlogs + 20);
    } else {
      setShowAllBlogs(20);
    }
  }
  return (
    <>
      <div className="event-title">
        <h2 className="event-titles">Blogs</h2>
        <div className="event-line">
          <img src="/images/event-line.png" alt="" />
        </div>
      </div>
      <div className="container">
        <table
          className="table"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <tbody>
            {eventList.length > 0 ? (
              eventList.map((item, i) => (
                <tr key={i}  onClick={() => toggleReadMore(item.id)}>
                  <td style={{ padding: "0.75rem", verticalAlign: "top", width: "150px" }}>
                    <div>
                      <img
                        src={item.imageUrl}
                        alt=""
                        className="img-fluid newssed-img"
                      />
                    </div>
                  </td>
                  <td style={{ padding: "0.75rem", verticalAlign: "top" }}>
                    <div className="news-letter-details">
                      <div className="d-flex">
                        <div>
                          <h5>{item.title}</h5>

                          {/* <p className="news-letter-para">
                            {displayText}
                            {isTruncated && (
                              <span
                                onClick={() => toggleReadMore(item.id)}
                                style={{ cursor: "pointer", color: "#083fb2" }}
                              >
                                {showFull ? " Read Less" : " Read More..."}
                              </span>
                            )}
                          </p> */}
                          <p className="news-letter-para">
                            {truncateText(item.description, wordLimit)}
                            {item.description.split(" ").length > wordLimit && (
                              <span
                                onClick={() => toggleReadMore(item.id)}
                                style={{ cursor: "pointer", color: "#083fb2" }}
                              >
                                {" Read More..."}
                              </span>
                            )}
                          </p>

                          {item.readingTime && (<p className="news-letter-source font-sm">
                            Reading Time : {item.readingTime}
                          </p>)}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="bloggings-tabled" style={{ padding: "0.75rem", verticalAlign: "top", width: "120px" }}>
                    <div className="bloggings-dates">
                      <span className="ml-auto font-sm">{item.createdTime}</span>
                    </div>
                  </td>
                  <div className="grid-border"></div>
                </tr>
              ))
            ) : (
              <Norecordsfound name="Blogs Not Found" />
            )}
          </tbody>

        </table>

        {eventList.length >= 20 && (
          <div className="text-center">
            <button
              onClick={handleupdatelist}
              style={{
                background: 'linear-gradient(90deg, rgba(218, 69, 137, 1) 11%, rgba(224, 87, 128, 1) 24%, rgba(248, 161, 91, 1) 98%)',
                color: "#fff",
                fontSize: '12px',
                padding: "8px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {totalPage > 1 ? "View More" : "View Less"}
            </button>
          </div>
        )}

      </div>
    </>
  );
};

export default Blog;
