import { Rating } from '@mui/material';
import React, { useState } from 'react';
import { BiMessageRounded } from "react-icons/bi";

const Logsandactivities = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="logs-container mt-3">
      <div className="log-entry">
        <div className="log-date">
          <div className="date-number">15</div>
          <div className="date-month">Aug</div>
        </div>
        <div className="log-content">
          <p><b>Prabhar</b> from <b>Amizhth Techno Solutions</b> attended <span className='log-sp'>Exclusive Helpdesk for Healthcare and Life Sciences Startups</span></p>

          {isEditing ? (
            <div className="edit-mode">
              <div className="rating">
                <Rating name="half-rating" defaultValue={0} precision={0.5} size="small" />
              </div>
              <input
                type="text"
                placeholder="Write a Comment"
                className="comment-input"
              />
            </div>
          ) : (
            <div className="log-feedback">
              <span className="rating"><span style={{ color: "#F8AC22", fontSize: '18px' }}>★</span> <span style={{ fontWeight: "600" }}>4.5</span>/5</span>
              <span className="comments"><BiMessageRounded className='mes-ico' /><b>1</b> Comment</span>
            </div>
          )}
        </div>
        <div className="log-edit">
          <button onClick={handleEditClick} className='his-btn'>
            {isEditing ? 'Save' : 'Edit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logsandactivities;
