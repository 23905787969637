import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import DataContext from "../../../context/DataContext";
import { FaArrowRight } from "react-icons/fa";

const EnablerMenus = ({handleCloseEnablersMenu}) => {
    const { navigator } = useContext(DataContext);
    const [tabDetailsList, setTabDetailsList] = useState([]);
  
    useEffect(() => {
      getApi();
    }, []);
  
    const getApi = () => {
      axios.get("/json/enablersNavMenu.json").then((result) => {
        if (result.data) {
          setTabDetailsList(result.data);
        }
      });
    };
  
    const handleRouteValue = (path) => {
      if (path) {
        handleCloseEnablersMenu();
        navigator(path);
      }
    };

    const handleExploremore = (path) => {
      navigator(path)
      handleCloseEnablersMenu();
    }
  
    return (
      <div className="set-bg-menu">
        <div className="startup-menu">
          {tabDetailsList.map((tab,i) => (
            <div className="tab" key={i}>
              <div className="tab-header">
                <img src={tab.mainMenuLogo} alt={tab.mainMenuLogo} />
                <h6>{tab.mainMenuName}</h6>
              </div>
              <ul className="service-list">
                {tab.subMenu.map((service,i) => (
                  <li  className="service" key={i}>
                    <span
                      className="c-pointer"
                      onClick={() => handleRouteValue(service.path)}
                    >
                      {service.subMenuName}
                    </span>
                  </li>
                ))}
                <p className="explore-more" onClick={() => handleExploremore(tab.path)}>
                {tab.more} <FaArrowRight />
              </p>
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default EnablerMenus
