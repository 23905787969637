import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import DataContext from "../../context/DataContext";

const AdminLayoutsidebar = ({ onClose }) => {
  const { hasPrivilege,navigator } = useContext(DataContext);
  // const role = localStorage.getItem("role");
  const handleNavigate = ()=>{
        navigator('/')
    }
  return (
    <div className="sidebar-div">
      <div onClick={()=>handleNavigate()} className="c-pointer">
        <img src="/images/oneTn/new_logo.png" className="sidebar-logo" alt="" />
      </div>
      <div className="rec_div">
        <ul className="rec_ul">
          {hasPrivilege("1") && (
            <li className="rec_li">
              <NavLink to={"/admin/dashboard"} onClick={onClose}>
                <img
                  src="/images/admin/home.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Home</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("11") && (
            <li className="rec_li">
              <NavLink to={"/admin/department"} onClick={onClose}>
                <img
                  src="/images/admin/Departments.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Departments</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("45") && (
            <li className="rec_li">
              <NavLink to={"/admin/project"} onClick={onClose}>
                <img
                  src="/images/admin/Departments.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Projects</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("15") && (
            <li className="rec_li">
              <NavLink to={"/admin/event"} onClick={onClose}>
                <img
                  src="/images/admin/event.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Events</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("55") && (
            <li className="rec_li">
              <NavLink to={"/admin/blog"} onClick={onClose}>
                <img
                  src="/images/oneTn/blogging.png"
                  className="side-sml-img mb-2"
                  style={{width: '100%',
                    maxWidth: '38px'}}
                  alt=""
                />
                <p className="mb-0">Blog</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("60") && (
            <li className="rec_li">
              <NavLink to={"/admin/newsletter"} onClick={onClose}>
                <img
                  src="/images/oneTn/newsletter.png"
                  className="side-sml-img mb-2"
                  style={{width: "100%",
                    maxWidth: "35px"}}
                  alt=""
                />
                <p className="mb-0">Newsletter</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("65") && (
            <li className="rec_li">
              <NavLink to={"/admin/latestnews"} onClick={onClose}>
                <img
                  src="/images/oneTn/newspaper.png"
                  className="side-sml-img mb-2"
                  style={{width: '100%',
                    maxWidth: '39px'}}
                  alt=""
                />
                <p className="mb-0">Latest News</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("16") && (
            <li className="rec_li">
              <NavLink to={"/admin/meeting"} onClick={onClose}>
                <img
                  src="/images/admin/Meetings.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Meetings</p>
              </NavLink>
            </li>
          )}

          {hasPrivilege("30") && (
            <li className="rec_li">
              <NavLink to={"/admin/user"} onClick={onClose}>
                <img
                  src="/images/admin/user.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">User</p>
              </NavLink>
            </li>
          )}
          {(hasPrivilege("34") || hasPrivilege("38")) && (
            <li className="rec_li">
              <NavLink to={"/admin/budget"} onClick={onClose}>
                <img
                  src="/images/admin/Budget.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Budget</p>
              </NavLink>
            </li>
          )}

          {hasPrivilege("39") && (
            <li className="rec_li">
              <NavLink to={"/admin/report"} onClick={onClose}>
                <img
                  src="/images/admin/Reports.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Reports</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("51") && (
            <li className="rec_li">
              <NavLink
                to={"/admin/smartcard"}
                onClick={onClose}
                activeClassName="active"
              >
                <img
                  src="/images/icon/smart-card.png"
                  className="side-sml-img mb-2"
                  alt=""
                  style={{ width: "43px" }}
                />
                <p className="mb-0">SmartCard</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("52") && (
            <li className="rec_li">
              <NavLink
                to={"/admin/servicepartners"}
                onClick={onClose}
                activeClassName="active"
              >
                <img
                  src="/images/icon/service-team.png"
                  className="side-sml-img mb-2"
                  alt=""
                  style={{ width: "40px" }}
                />
                <p className="mb-0">Service Partners</p>
              </NavLink>
            </li>
          )}
          {hasPrivilege("21") && (
            <li className="rec_li">
              <NavLink to={"/admin/support"} onClick={onClose}>
                <img
                  src="/images/admin/support.png"
                  className="side-sml-img mb-2"
                  alt=""
                />
                <p className="mb-0">Support</p>
              </NavLink>
            </li>
          )}
          {/* {
                        hasPrivilege("52") && (
                        
                           

                              <li className='rec_li'>
                                <NavLink to={'/admin/servicepartners'} onClick={onClose} activeClassName='active' >
                                    <img src="/images/icon/service-team.png" className='side-sml-img mb-2' alt=''style={{width:"40px"}} />
                                    <p className='mb-0'>Service Partners</p>
                                </NavLink>
                            </li>
                            
                        )
                    }   */}

          {/* <li className='rec_li'>
                    <NavLink to={'/admin/portfolio'} onClick={onClose}>
                        <img src="/images/admin/portfilo.png" className='side-sml-img mb-2' alt='' />
                        <p className='mb-0'>Portfolio</p>
                    </NavLink>
                </li> */}
        </ul>
      </div>
    </div>
  );
};

export default AdminLayoutsidebar;
