import { Grid, Pagination, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";

const DeptReport = () => {
  const { setPageLoading } = useContext(DataContext);

  useDidMountEffect(() => {
    categoryDropDown();
  }, []);
  const [store, setStore] = useState([]);
  const categoryDropDown = () => {
    apiService(`home/category/list?type=report`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.categories) {
          setStore(result.data.categories);
          setCategoryId(result.data.categories[0].id)
        }
      })
      .catch((err) => { });
  };

  const [searchInputValue, setSearchInputValue] = useState();
  const [activeButton, setActiveButton] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState('')
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, categoryId]);

  const listApi = () => {
    setReports([])
    setPageLoading(true);
    var data = {
      listSize: 10,
      pageNumber: page,
      categoryId: categoryId,
      searchString: searchInputValue,
    };
    apiService("reports/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.reports) {
            setReports(result.data.reports);
          }
        }
      })
      .catch((err) => { });
  };


  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleButtonClick = (id, i) => {
    setCategoryId(id)
    setActiveButton(i);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <div className="depart-report-div">
      <div className="depart-report-title">
        <div>
          <p className="doc-rep-tit">Documents & Reports</p>
        </div>
        <div>
          <div class="search-container">
            <button type="submit">
              <BiSearchAlt style={{ fontSize: "18px", marginLeft: "5px" }} />
            </button>
            <input
              type="search"
              placeholder="Search Documents & Reports"
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <div className="classreport-filter-btn mt-5">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <div className="profile-box-filter">
              {store &&
                store.map((a, i) => (
                  <div key={i}>
                    <button
                      className={activeButton === i ? "report-buttons active" : "report-buttons"}
                      onClick={() => handleButtonClick(a.id, i)}
                    >
                      {a.category}
                    </button>
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.No</TableCell>
                    <TableCell align="center">Documents & Reports</TableCell>
                    <TableCell align="center">Report in PDF</TableCell>
                    <TableCell align="center">Category</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reports.length > 0 ? (
                    reports.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          <Link
                            to={row.fileName}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <span
                              style={{ color: "#0061D4", fontSize: "13px" }}
                            >
                              Download-({row.fileSize})
                            </span>
                          </Link>
                        </TableCell>
                        <TableCell align="center">{row.category}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No reports available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {totalPage > 1 && (
          <div className="event-pagination">
            <Stack spacing={2}>
              <Pagination
                count={totalPage}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeptReport;
