import React from 'react'
import './Pagenotfound.css'
import { Link } from 'react-router-dom'

const Pagenotfound = () => {
  return (
    <>      
      <main className='sesstion_404'>
        <div class="container pageCss">
          <div class="row">
            <div class="col-md-6 align-self-center">
              <img src='/images/404-1.gif' className='img-fluid' alt=''/>
            </div>
            <div class="col-md-6 align-self-center page404">
              <h1>404</h1>
              <h2>Page Not Found.</h2>
              <p className='mb-0'>The page you are looking for does not exists.
              </p>
              <p>But you can click the button below to go back to the homepage.</p>
              <Link class="btn404 green" to="/">HOME</Link>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Pagenotfound