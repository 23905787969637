import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { FaCircleArrowRight } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoIosAddCircle } from "react-icons/io";
import Navbar from "../../home-module/navbar-page/Navbar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const EmergingSectorHomePage = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`Funding   >  TN Emerging Sector Seed Fund`}</span>
                  <h1 className="banner-text-gradient">
                    TN Emerging Sector Seed Fund{" "}
                  </h1>
                  <h6 className="fund-title">
                    A fund by Government of Tamil Nadu for investing in
                    companies and startups from emerging sector
                  </h6>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info1"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage1">
                <h4>What is TN Emerging Sector Seed Fund?</h4>
                <p>
                  Tamil Nadu Emerging Sector Seed Fund (TNESSF) is a SEBI
                  registered is CAT-I Alternative Investment Fund with an anchor
                  investment of Rs 100 Cr. The fund is managed by Tamil Nadu
                  Infrastructure Fund Management Corporation Limited (TNIFMC).
                  StartupTN is associated with TNIFMC in managing the fund.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/Sector fund.svg"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
          {/* <div className="smartcrd-details-div ">
            <div className="">
              <div className="">
                <img
                  src="/images/TANSEED 6.0.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className=""></div>
            </div>
          </div> */}
          <div className="how-works-div">
            <Container>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="works-section1 mt-5">
                    <h4>Evaluation</h4>
                    <p>
                      The prospective applicants are evaluated based on the
                      following
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <div className="layer-bg-smcrd">
                    <div className="works-mesh-layer1"></div>
                    <div className="how-works-details1">
                      <div className="1">
                        <div className="sm-crd-process-steps1">
                          <div className="process-div1">
                            <div className="rec-img1">
                              <img
                                src={"/images/Rec-1.png"}
                                alt="registration"
                                className="process-img"
                              />
                            </div>
                            <div className="process-text">
                              <h3>
                                01 <span>Track record of the promoters</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="sm-crd-process-steps1">
                          <div className="process-div1">
                            <div className="rec-img2">
                              <img
                                src={"/images/Rec-2.png"}
                                alt="registration"
                                className="process-img"
                              />
                            </div>
                            <div className="process-text">
                              <h3>
                                02 <span>Growth prospects</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="sm-crd-process-steps2">
                          <div className="process-div1">
                            <div className="rec-img2">
                              <img
                                src={"/images/Rec-3.png"}
                                alt="registration"
                                className="process-img"
                              />
                            </div>
                            <div className="process-text">
                              <h3>
                                03 <span>Business model efficiency</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                        
                        <div className="sm-crd-process-steps2">
                          <div className="process-div1">
                            <div className="rec-img2">
                              <img
                                src={"/images/Rec-4.png"}
                                alt="registration"
                                className="process-img"
                              />
                            </div>
                            <div className="process-text">
                              <h3>
                                04 <span>Social impact</span>
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="sm-crd-process-steps2">
                          <div className="process-div1">
                            <div className="rec-img2">
                              <img
                                src={"/images/Rec-5.png"}
                                alt="registration"
                                className="process-img"
                              />
                            </div>
                            <div className="process-text">
                              <h3>
                                05 <span>Exit potential</span>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
            <Container>
              <div className="application">
                <div>
                  <h3 className="down">Download Application</h3>
                  <p className="below">
                    Interested applicants can download the application form
                    below
                  </p>
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="large"
                    className="down-now-btn"
                  >
                    Download Form
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </Container>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>
                      A fund by Government of Tamil Nadu for investing in
                      companies and startups from emerging sector
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default EmergingSectorHomePage;
