import React, { useContext, useState } from 'react'
import Navbar from '../navbar-page/Navbar'
import { Card, CardContent, Container, Dialog, Grid, Pagination, Stack, Typography } from '@mui/material'
import Footer from '../footer-page/Footer'
import DepartmentDetails from './DepartmentDetails'
import DataContext from '../../../context/DataContext'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import apiService from '../../../api/apiService'
import Norecordsfound from '../../common-pages/Norecordsfound'

const DepartmentPage = () => {
    const { fullWidth, setPageLoading } = useContext(DataContext)
    const [hoveredCard, setHoveredCard] = useState(null)
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [gotnDeptData, setGotnDeptData] = useState([])
    useDidMountEffect(() => {
        listApi();
    }, [page]);
    const listApi = () => {
        setPageLoading(true)
        var data = {
            listSize: 12,
            pageNumber: page,
        };
        apiService("department/gotn/list", data, "post")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    setTotalPage(result.data.totalPages);
                    if (result.data.goDepartmentDTOList) {
                        setGotnDeptData(result.data.goDepartmentDTOList);
                    }
                }
            })
            .catch((err) => {
                console.error("Error fetching events:", err);
            });
    };

    // const [meetingList, setmeetingList] = useState([
    //     {
    //         "name": "EDII",
    //         "logoname": "/images/Vlogo/EDII.png",
    //         "titleName": "EDII",
    //         "depName": "MSME Department",
    //         "descrip": "Established in 2001, the Entrepreneurship Development and Innovation Institute (EDII) in Chennai is a government-administered non profit promoting entrepreneurship.",
    //         "redirect": " https://www.editn.in/"

    //     },
    //     {
    //         "name": "FaMe TN",
    //         "logoname": "/images/Vlogo/FameTN.png",
    //         "titleName": "FaMe Tn",
    //         "depName": "Industries, Investment Promotion & Commerce",
    //         "descrip": "Development Corporation Limited (TIDCO) promotes balanced industrial growth through joint ventures and manages industrial corridor projects in Tamil Nadu.",
    //         "redirect": "https://fametn.com/"
    //     },
    //     {
    //         "name": "FORT",
    //         "logoname": "/images/Vlogo/FORT.png",
    //         "titleName": "FORT",
    //         "depName": "Industries, Investment Promotion & Commerce Department",
    //         "descrip": "A SIPCOT-powered innovation ecosystem, established with Forge under the Industries Department of Tamil Nadu, aims to drive industrial transformation by integrating manufacturing excellence, technology, and entrepreneurship.",
    //         "redirect": "https://www.fort.forgeforward.in/"
    //     },
    //     {
    //         "name": "InnovateTN",
    //         "logoname": "/images/Vlogo/InnovateTN.png",
    //         "titleName": "InnovateTN",
    //         "depName": "Industries, Investment Promotion & Commerce",
    //         "descrip": "A SIPCOT-powered innovation ecosystem, established with Forge under the Industries Department of Tamil Nadu, aims to drive industrial transformation by integrating manufacturing excellence, technology, and entrepreneurship.",
    //         "redirect": "https://www.forge-iv.co/"
    //     },
    //     {
    //         "name": "iTNT",
    //         "logoname": "/images/Vlogo/iTNT.png",
    //         "titleName": "ITNT",
    //         "depName": "Information Technolgy Department",
    //         "descrip": "iTNT Hub unites startups, academia, and industry to drive Deeptech innovation through a pioneering network.",
    //         "redirect": "https://itnthub.tn.gov.in/"
    //     },
    //     {
    //         "name": "Nan Mudhalvan",
    //         "logoname": "/images/Vlogo/NaanMudhalvan.png",
    //         "titleName": "Naan Mudhalvan",
    //         "depName": "Tamil Nadu Skill Development Corporation",
    //         "descrip": "The Naan Mudhalvan platform provides Tamil Nadu college students with industry-specific skill training and career guidance to enhance employability.",
    //         "redirect": "https://www.naanmudhalvan.tn.gov.in/"
    //     },
    //     {
    //         "name": "SIDCO",
    //         "logoname": "/images/Vlogo/SIDCO.png",
    //         "titleName": "SIDCO",
    //         "depName": "MSME Department",
    //         "descrip": "Tamil Nadu Small Industries Development Corporation Limited (TANSIDCO) playing a significant role in facilitating the promotion and development of MSME",
    //         "redirect": "https://tansidco.org/"
    //     },
    //     {
    //         "name": "SIPCOT",
    //         "logoname": "/images/Vlogo/SIPCOT.png",
    //         "titleName": "SIPCOT",
    //         "depName": "Industries, Investment Promotion & Commerce",
    //         "descrip": "The initiative aims to boost medium and large-scale industries in Tamil Nadu by developing industrial parks in backward areas to enhance regional growth and employment.",
    //         "redirect": "https://sipcotweb.tn.gov.in/"
    //     },
    //     {
    //         "name": "StartupTN",
    //         "logoname": "/images/Vlogo/Startuptn.png",
    //         "titleName": "Startup TN",
    //         "depName": "MSME Department",
    //         "descrip": "Propelling Tamil Nadu into the Top 20 Global Startup Destinations by 2032, fostering innovation, wealth creation, and a globally emulated, equitable startup ecosystem.",
    //         "redirect": "https://startuptn.in/"
    //     },
    //     {
    //         "name": "TNRTP",
    //         "logoname": "/images/Vlogo/TNRTP.png",
    //         "titleName": "TNRTP",
    //         "depName": "Rural Development & Panchayat Raj Department",
    //         "descrip": "The Vazhndhu Kattuvom Project, supported by the World Bank, aims to transform rural Tamil Nadu by promoting sustainable enterprises, improving access to finance, and creating employment opportunities.",
    //         "redirect": "https://www.vkp-tnrtp.org/"
    //     },
    //     {
    //         "name": "TVA",
    //         "logoname": "/images/Vlogo/TVA.png",
    //         "titleName": "Tamil Virtual Academy",
    //         "depName": "Information Technology",
    //         "descrip": "Tamil Virtual Academy creates digital library with digital resources of rare printed books, periodicals, palm-leaf manuscripts, rare paper manuscripts, photographs, audio-visual materials of Tamil Language, Literature and Culture",
    //         "Redirect": "https://www.tamilvu.org/"
    //     },
    //     {
    //         "name": "TN Apex",
    //         "logoname": "/images/Vlogo/TNAPEx.png",
    //         "titleName": "TNAPEX",
    //         "depName": "MSME Department",
    //         "descrip": "The Tamil Nadu Food Processing and Agri Export Promotion Corporation focuses on promoting food processing industries and enhancing agricultural exports from Tamil Nadu to drive economic growth and support farmers.",
    //         "redirect": 'https://www.tn-pmfme.com/login'
    //     },
    //     {
    //         "name": "TICEL",
    //         "logoname": "/images/Vlogo/Ticel.jpg",
    //         "titleName": "TICEL",
    //         "depName": "MSME Department",
    //         "descrip": "TICEL Bio Park Limited (TICEL) providing infrastructure facility for Biotechnology / Pharmaceutical Research & Development activities at Taramani, Chennai.",
    //         "redirect": "https://ticelbiopark.com/"
    //     },
    //     {
    //         "name": "TIDCO",
    //         "logoname": "/images/dep-17.png",
    //         "titleName": "TIDCO",
    //         "depName": "Industries, Investment Promotion & Commerce",
    //         "descrip": "Development Corporation Limited (TIDCO) promotes balanced industrial growth through joint ventures and manages industrial corridor projects in Tamil Nadu.",
    //         "redirect": " https://tidco.com/"
    //     },
    //     {
    //         "name": "TNGCC",
    //         "logoname": "/images/Vlogo/TNGCC.png",
    //         "titleName": "TNGCC",
    //         "depName": "Environment, Climate Change & Forests Department",
    //         "descrip": "The Tamil Nadu Green Climate Company (TNGCC) oversees climate missions to promote sustainability and resilience through collaboration with various stakeholders.",
    //         "redirect": "https://tngreencompany.com/home"
    //     },
    //     {
    //         "name": "TNIFMC",
    //         "logoname": "/images/Vlogo/TNIFMC.png",
    //         "titleName": "TNIFMC",
    //         "depName": "Tamil Nadu Infrastructure Development Board",
    //         "descrip": "TNIFMC is the asset management company promoted by the Tamil Nadu Infrastructure Development Board (TNIDB), to strengthen Tamil Naduâ€™s leadership in business, industrial and commercial sectors by attracting investments into the state resulting in responsible economic growth and thereby resulting in improving employment opportunities. TNIFMC manages multiple thematic funds, regulated by SEBI, in areas of key focus of the Government of Tamil Nadu.",
    //         "redirect": "https://tnifmc.com/"

    //     },
    //     {
    //         "name": "TNRISE",
    //         "logoname": "/images/Vlogo/TNRISE.png",
    //         "titleName": "TNRISE",
    //         "depName": "Rural Development & Panchayat Raj Department",
    //         "descrip": "TN-RISE empowers women entrepreneurs by offering mentorship, advisory services, and resources to overcome challenges and drive economic growth.",
    //         "redirect": "https://www.tnrise.co.in/"
    //     },
    //     {
    //         "name": "TAHDCO",
    //         "logoname": "images/Vlogo/TAHDCO.jpeg",
    //         "titleName": "TAHDCO",
    //         "depName": "Department of Adi Dravidar & Tribal Welfare",
    //         "descrip": "TAHDCO, established in 1974, promotes income generation and skill development for Scheduled Castes and Tribes in Tamil Nadu through various economic development schemes.",
    //         "redirect": "https://tahdco.com/"
    //     },
    //     {
    //         "name": "TAICO",
    //         "logoname": "images/Vlogo/TAICO.jpg",
    //         "titleName": "TAICO",
    //         "depName": "The Micro, Small and Medium Enterprises Department",
    //         "descrip": "Tamilnadu Industrial Cooperative Bank Ltd., popularly known as \"TAICO Bank\" is the first of its kind in the Cooperative Sector in the Country for catering the needs of Industrial Cooperatives and MSME.",
    //         "redirect": "https://www.taicobank.in/"
    //     },
    //     {
    //         "name": "TNCCM",
    //         "logoname": "images/Vlogo/TNCCM.png",
    //         "descrip": "The Tamil Nadu Climate Change Mission was launched to address and mitigate the impact of climate change, emphasizing adaptation and comprehensive climate action.",
    //         "redirect": "https://tnclimatechangemission.in/home/"
    //     },
    // ])
    const [openDepartment, setOpenDepartment] = useState(false)
    // const handleDepartmentDetails = (name) => {
    //     debugger
    //     if (name !== undefined) {

    //         window.open(name, '_blank',)
    //     }
    // }
    const handleDepartmentDetails = (name) => {
        if (name !== undefined) {
            const fullUrl = name.startsWith('http://') || name.startsWith('https://') ? name : 'https://' + name;
            
            window.open(fullUrl, '_blank');
        }
    }
    
    const handleDepartmentClose = () => {
        setOpenDepartment(false)
    }
    const handleMouseEnter = (name) => {
        setHoveredCard(name)
    }

    const handleMouseLeave = () => {
        setHoveredCard(null)
    }

    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <div className="back-color2">
            <Navbar />

            <div className="mb-5">
                <Container>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={12} >

                            <div className="main-title">
                                <div className='dep-new-tit-div'>
                                    {/* <div className='small-title-depnew'>
                                        <p>Go-TN Entities</p>
                                    </div> */}
                                    <div className='big-title-depnew'>
                                        <h1>Unite to Build a <span>One Trillion Economy</span> with <b>OneTN</b></h1>
                                    </div>
                                </div>
                            </div>

                        </Grid>
                    </Grid>
                </Container>
                <div className='z-value my-5'>
                    <Container>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={3}
                        >
                            {gotnDeptData.length > 0 ? (<>
                                {
                                    gotnDeptData.map((item, i) => (

                                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                            <div className="card_sec c-pointer" onMouseEnter={() => handleMouseEnter(item.name)}
                                                onMouseLeave={handleMouseLeave}>

                                                {hoveredCard !== item.name ? (<>
                                                    <Card className="card-comp-adm1" key={i}
                                                    // onClick={() => handleDepartmentDetails(item.name)}
                                                    //     onMouseEnter={() => handleMouseEnter(item.name)}
                                                    // onMouseLeave={handleMouseLeave}
                                                    >
                                                        <div className="imag-border1">
                                                            <img
                                                                className="comp-logo1"
                                                                src={item.logoName ? item.logoName : '/images/racket-img.png'}
                                                                alt="company-logo"
                                                            />
                                                        </div>
                                                        <div className='deps-name-new'>
                                                          {item.shortName ?  (<p className="admcrd-para-color1">{item.shortName}</p>):(<p className="admcrd-para-color1">{item.name.length > 10 ? item.name.slice(0, 10) + '...' : item.name}</p>)}
                                                        </div>
                                                    </Card>

                                                </>) : (<>
                                                    <Card className="card-comp-adm12" key={i}>
                                                        <div className='deps-name-new'>
                                                        {item.shortName ?  (<p className="admcrd-para-color1">{item.shortName}</p>):(<p className="admcrd-para-color1">{item.name.length > 10 ? item.name.slice(0, 10) + '...' : item.name}</p>)}
                                                        </div>
                                                        <div className="hover-card-det mt-2">
                                                            <h5 >{item.titleName}</h5>
                                                        </div>
                                                        <p className="hover-depName">{item.departmentName}</p>
                                                        <p className="hover-descrip">{item.description}</p>
                                                        {item.redirect ? (
                                                            <div className="know-more-new">
                                                                <p className="mb-0" onClick={() => handleDepartmentDetails(item.redirect)}>
                                                                    Know More
                                                                    <span className="ml-1">
                                                                        <img src="/images/oneTn/Group 1707479843.png" alt="Know More" />
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="know-more-new" style={{ visibility: 'hidden' }}>
                                                                <p className="mb-0">
                                                                    Know More
                                                                    <span className="ml-1">
                                                                        <img src="/images/oneTn/Group 1707479843.png" alt="Know More" />
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        )}

                                                    </Card>
                                                </>)}
                                            </div>
                                        </Grid>
                                    ))
                                }
                                {totalPage > 1 && (
                                    <Grid item sm={12} md={12} lg={12}>
                                        <div className="event-pagination">
                                            <Stack spacing={2} >
                                                <Pagination
                                                    count={totalPage}
                                                    page={page}
                                                    onChange={handleChange}
                                                    sx={{
                                                        '& .MuiPaginationItem-root': {
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                            },
                                                            '&.Mui-selected': {
                                                                backgroundColor: 'white',
                                                                color: 'black',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Stack>
                                        </div>
                                    </Grid>
                                )}
                            </>) : (<><Norecordsfound name="No Departments Found" /></>)}

                        </Grid>
                    </Container>
                </div>
                <Dialog fullWidth={fullWidth} open={openDepartment} maxWidth="lg">
                    <DepartmentDetails handleDepartmentClose={handleDepartmentClose} />
                </Dialog>
            </div>
            <Footer />
        </div>
    )
}

export default DepartmentPage