import React, { useContext, useRef, useState } from "react";
import {
  Grid,
  Container,
  Button,
  Chip,
  Divider,
  Rating,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from "@mui/material";
import { IoCheckmark } from "react-icons/io5";
import { IoMailOpenOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa6";

import SendIcon from "@mui/icons-material/Send";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import AdminSupportAssign from "../AdminSupportAssign";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";

const StartupSupportDetails = ({ name }) => {
  const { id } = useParams();
  let supportId;
  if (id != null) {
    supportId = atob(id);
  }
  const { navigator, setPageLoading ,isWhitespace} = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({ values: formData });
  useDidMountEffect(() => {
    getApi();
  }, []);
  const [convo, setConvo] = useState({});
  const [userId, setUserId] = useState();
  const [photoList, setPhotoList] = useState([]);
  const getApi = () => {
    setFormData({});
    apiService(`support/ticket/get?id=${supportId}`, "", "get")
      .then((result) => {
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
          if (result.data.status) {
            setStatusBtn(result.data.status);
          }
          setRating(result.data.rating);
          if (result.data.userId) {
            setUserId(result.data.userId);
          }
          if (result.data.attachments) {
            setPhotoList(result.data.attachments);
          }
          if (result.data.conversation) {
            setConvo(result.data.conversation);
          }
          setHeight();
        }
      })
      .catch((err) => { });
  };

  const handleReopenClick = () => {
    var req = {
      id: supportId,
      status: 2,
    };
    apiService("support/ticket/status/save", req, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          getApi();
        }
      })
      .catch((err) => { });
  };
  const [isClosedd, setIsClosedd] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [statusBtn, setStatusBtn] = useState();

  const handleRatingChange = (event, value) => {
    setIsClosedd(true);
    setDialogOpen(true);
    setRating(value);
  };

  const [isClosed, setIsClosed] = useState(false);

  const handleClosedClick = () => {
    setIsClosed(true);
  };

  // const handleReopenClick = () => { };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleRating = () => { };

  const onSubmit = (data) => {
    var getform = data;
    getform.supportId = supportId;
    getform.userId = userId;
    apiService("support/conversation/save", getform, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          getApi();
        }
      })
      .catch((err) => { });
  };
  const handleBackBtn = () => {
    navigator(`/admin/support/${name}/list`);
  };
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const setHeight = () => {
    const div1Height = div1Ref.current.offsetHeight;
    div2Ref.current.style.height = `${div1Height}px`;
    div3Ref.current.style.height = `${div1Height - 70}px`;
  };
  const [openModal, setOpenModal] = useState(false);
  const [statusValue, setStatusValue] = useState();
  const handleStatusBtn = (value) => {
    setOpenModal(true);
    setStatusValue(value);
  };
  const handleStatusSave = () => {
    setPageLoading(true);
    var req = {
      id: supportId,
      status: statusValue,
    };

    apiService("support/ticket/status/save", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          getApi();
          handleCloseModal();
          notifyService("success", "Success", "");
        }
      }
    });
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <div>
      <div className="container-fluid main-lists-support">
        <div className="list-divs">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{ position: "relative" }}
          >
            <Grid item xs={12} sm={12}>
              <div className="d-flex align-items-center">
                <Button className="back-btns" onClick={handleBackBtn}>
                  <FaArrowLeft />
                  <span style={{ marginLeft: "5px" }}>Back</span>
                </Button>
                {/* <div className="ml-auto set-two">
                  <div className="phone">
                    <LocalPhoneIcon />
                  </div>
                  <div className="msg">
                    <EmailIcon />
                  </div>
                  <div>
                    {formData.status === 0 && (
                      <AdminSupportAssign
                        name={name}
                        supportId={supportId}
                        getApi={getApi}
                      />
                    )}
                    {formData.status === 1 && (
                      <Button
                        className="clon"
                        variant="contained"
                        color="success"
                        onClick={() => handleStatusBtn(3)}
                      >
                        Mark as Resolved
                      </Button>
                    )}
                    {formData.status === 3 && (
                      <Button
                        className="clon"
                        variant="contained"
                        color="success"
                        onClick={() => handleStatusBtn(4)}
                      >
                        Close
                      </Button>
                    )}
                    {formData.status === 4 && (
                      <Button
                        className="clon"
                        variant="contained"
                        color="success"
                        onClick={() => handleStatusBtn(0)}
                      >
                        Reopen
                      </Button>
                    )}
                  </div>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="ticketadd_admin mb-5" ref={div1Ref}>
                <div>
                  <div className="empower-box mb-3">
                    <p className="empower-p text-center">
                      {formData.queryTitle}{" "}
                    </p>
                    <p className="text-center pb-3">
                      <span className="gray">Ticket Number : </span>
                      <span className="blue">{formData.ticketNo}</span>
                    </p>
                  </div>
                  <div className="mb-3">
                    <div className="text-center mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <span className="list-2-grey">Project Name :</span>{" "}
                          <span className="list-2-black">
                            {formData.projectName}
                          </span>
                        </div>
                        <div>
                          <span className="list-2-grey">Department Name :</span>{" "}
                          <span className="list-2-black">
                            {formData.departmentName}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <span className="list-2-grey">Created at :</span>{" "}
                          <span className="list-2-black">
                            {formData.creationTime}
                          </span>
                        </div>
                        {/* <div>
                          <span className="list-2-grey">Assigned to : </span>{" "}
                          <span className="list-2-black">
                            {formData.assignedUser
                              ? formData.assignedUser
                              : "Not Assigned"}{" "}
                            {formData.assignedUserEmail &&
                              `(${formData.assignedUserEmail})`}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div className="mb-4">
                    <div className="my-4">
                      <span className="dar-blue">Query Description</span>
                    </div>
                    <div className="queryDesc">
                      <span>{formData.queryDesc}</span>
                    </div>
                  </div>

                  <div>
                    {photoList.length > 0 && (
                      <>
                        <div className="attach-blue my-4">
                          <span>Attachments</span>
                        </div>
                        <div className="d-flex" style={{flexWrap:'wrap'}}>
                          {photoList.map((list, index) => (
                            <div key={index} className="d-flex">
                              <div className="">
                                <img
                                  src={list.fileName}
                                  className="vehicle_logo img-fluid"
                                  alt={index}
                                  style={{
                                    width: "67px",
                                    height:'65px',
                                    objectFit: "contain",
                                    marginLeft: "10px",
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {/* {statusBtn === 0 && (
                      <center>
                        <Button
                          style={{
                            background: "#ffc107",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                        >
                          Not Assigned
                        </Button>
                      </center>
                    )}
                    {statusBtn === 1 && (
                      <center>
                        <Button
                          style={{
                            background: "#269949",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                            marginTop: "30px",
                            marginBottom: "30px",
                          }}
                          variant="outlined"
                        >
                          Responded
                        </Button>
                      </center>
                    )}
                    {statusBtn === 2 && (
                      <div
                        className="pb-5"
                        style={{
                          display: "flex",
                          marginTop: "30px",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <Button
                          style={{
                            fontSize: "16px",
                            color: "#020671",
                            borderColor: "#020671",
                            padding: "12px 40px",
                            textTransform: "none",
                            borderRadius: "8px",
                            border: "1px solid",
                          }}
                          variant="outlined"
                          startIcon={<IoMailOpenOutline />}
                          onClick={handleReopenClick}
                        >
                          Reopen
                        </Button>
                        <Button
                          style={{
                            background: "#269949",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                          }}
                          variant="outlined"
                          startIcon={<IoCheckmark />}
                          onClick={handleClosedClick}
                        >
                          Closed
                        </Button>
                      </div>
                    )} */}
                    {formData.status === 1 && (
                      <div
                        className="pb-5"
                        style={{
                          display: "flex",
                          marginTop: "30px",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <Button
                          style={{
                            background: "#269949",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                          }}
                          variant="outlined"
                          startIcon={<IoMailOpenOutline />}
                          onClick={handleReopenClick}
                        >
                          Mark as Resolved
                        </Button>
                        {/* <Button
                          style={{
                            background: "#269949",
                            color: "white",
                            border: "none",
                            borderRadius: "8px",
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 40px",
                            textTransform: "none",
                          }}
                          variant="outlined"
                          startIcon={<IoCheckmark />}
                          onClick={handleClosedClick}
                        >
                          Closed
                        </Button> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div
                className="box1"
                style={{
                  padding: "24px 24px 24px 24px",
                  height: "auto",
                  borderRadius: "12px",
                  background: "#EFEFF7",
                  boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px",
                }}
              >
                <div
                  className="chat"
                  style={{ height: "calc(100vh - 120px)", overflowY: "auto" }}
                >
                  {convo && convo.length > 0 ? (
                    <div className="messages-chat">
                      {convo.map((list, index) => (
                        <>
                          {list.position === "LEFT" ? (
                            <div>
                              <div className="message">
                                <div>
                                  <img
                                    className="photo1"
                                    src={
                                      list.logoFileName
                                        ? list.logoFileName
                                        : "/images/user.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <p className="text1">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="team">
                                      <p>{list.responderName}</p>
                                    </div>
                                    <div className="ml-auto">
                                      <p className="time1">
                                        {list.creationTime}
                                      </p>
                                    </div>
                                  </div>
                                  <div>{list.message}</div>
                                </p>
                              </div>
                              {/* <div className="message4 text-only">
                                      <p className="text2">
                                        {" "}
                                        <Link>www.startuptn.in/funding-programs//</Link>
                                      </p>
                                    </div> */}
                            </div>
                          ) : (
                            <div
                              className="message1"
                              style={{
                                marginTop: "25px",
                                display: "flex",
                              }}
                            >
                              <div className="text3">
                                <div
                                  className="dates"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="team">
                                    <p>{list.responderName}</p>
                                  </div>
                                  <div className="ml-auto">
                                    <p className="time2">{list.creationTime}</p>
                                  </div>
                                </div>
                                <div>{list.message}</div>
                              </div>

                              <div>
                                <img
                                  className="photo2"
                                  src={
                                    list.logoFileName
                                      ? list.logoFileName
                                      : "/images/user.png"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  ) : (
                    <>
                      <center>
                        <p>No Chat Found</p>
                      </center>
                    </>
                  )}
                </div>
                <div className="msg_box">
                  {statusBtn !== 2 && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box className="message-box">
                        <Controller
                          name="message"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Message is required",
                            validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Write a Message"
                                id="fullWidth"
                                style={{ background: "white" }}
                                error={!!error}
                                // helperText={error ? error.message : ""}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Button type="submit">
                                        <SendIcon />
                                      </Button>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </>
                          )}
                        />
                         <FormHelperText className="text-danger">
                          {errors.message && errors.message.message}
                        </FormHelperText>
                      </Box>
                      {/* <Button type="submit">Save</Button> */}
                    </form>
                  )}
                  {statusBtn === 2 && (
                    <div className="bg-feedback">
                      <div className="feedback">
                        <Typography
                          style={{ fontSize: "18px" }}
                          component="legend"
                        >
                          Rating
                        </Typography>

                        <Rating
                          style={{ fontSize: "40px" }}
                          name="size-large"
                          value={rating}
                          size="large"
                          readOnly
                        />
                        <Typography
                          style={{ fontSize: "18px" }}
                          component="legend"
                        >
                          Feedback :
                        </Typography>
                        {formData.feedback}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Dialog open={openModal} maxWidth="lg" onClose={handleCloseModal}>
        <Box sx={{ minWidth: "500px" }}>
          <form className="text-center">
            <DialogContent>
              <img src="/images/04-06-10-848_512.webp" className="are_you" />
              <h3>Are yor sure</h3>
            </DialogContent>
            <DialogActions>
              <div className="text-center mb-3 w-100">
                <Button onClick={handleCloseModal} className="discard-btn">
                  No
                </Button>
                <Button onClick={handleStatusSave} className="publish-button">
                  {" "}
                  Yes
                </Button>
              </div>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </div>
  );
};

export default StartupSupportDetails;