import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Artculture = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/Arts-Culture-and-Architecture.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Art, Culture & Architecture</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <ul>
                    <li>
                      Scheme for Safeguarding the Intangible Cultural Heritage:
                      The objective of the scheme is to support and strengthen
                      the efforts of various stakeholders in preservation and
                      promotion of the country’s cultural heritage. Assistance
                      is provided as grant for documentation, preservation and
                      education..
                    </li>
                    <br />
                    <li>
                      Kala Sanskriti Vikas Yojana: This scheme is aimed at
                      promoting and disseminating the art and culture of the
                      country by providing financial support to drama, theatre
                      and dance groups, music ensembles, folk theatre and music
                      and other genres of performing arts.
                    </li>
                    <br />
                    <li>
                      Scheme of Financial Assistance for Promotion of Art and
                      Culture: This scheme covers all not-for-profit
                      organisations, NGOs, societies, trusts and universities
                      for supporting seminars, conferences, research, workshops,
                      festivals, exhibitions, symposia, production of dance,
                      drama, music etc. and small research projects on different
                      aspects of Indian Culture.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <ul>
                    <li>
                      Six Regional Cultural Centres, four Government Music
                      Colleges, 17 District Government Music Schools, two
                      Government Colleges of Fine Arts, one Government College
                      of Architecture and Sculpture, 36 Jawahar Bal Bhavans and
                      district art societies are functioning in the State to
                      foster art and culture.
                    </li>
                    <br />
                    <li>
                      The Tamil Nadu Eyal Isai Nataka Mandram encourages
                      artistes involved in fostering traditional Tamil arts.
                    </li>
                    <br />
                    <li>
                      The Tamil Nadu Folk Artistes’ Welfare Board functions
                      exclusively for the economic upliftment of folk artistes.
                    </li>
                    <br />
                    <li>
                      Common Facility Centres: The Tamil Nadu Handicrafts
                      Development Corporation runs 11 common facility centres
                      with modern machinery and tools, including 3D printers and
                      electric furnaces, for the benefit of artisans. Two common
                      facility centres are in operation for revival of
                      languishing craft, like Pathamadai Korai mat weaving. An
                      exclusive common facility centre is operational in
                      Kayalpattinam of Thoothukudi district for palm jaggery.
                    </li>
                    <br />
                    <li>
                      Urban Haat: This permanent infrastructure facility is
                      available in tourist centres and metros to facilitate
                      direct marketing of their products by artisans and
                      weavers.
                    </li>
                    <br />
                    <li>
                      The World Crafts Council has recognised Mamallapuram as a
                      World Stone Carving City.
                    </li>
                    <br />
                    <li>
                      One District One Product: This scheme is intended to focus
                      sales initiatives on a particular product made by women
                      self-help groups, farmer producer organisations or MSMEs
                      for a district.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Artculture;
