import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Alml = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };

  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTN-AIioT.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">AI, ML & IoT</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <p style={{ color: "#39364e" }}>Union Government</p>
                  <ul>
                    <li>
                      In 2020, the Union Government increased the outlay for
                      Digital India to $477 million to boost AI, IoT, big data,
                      cybersecurity, machine learning and robotics. India’s
                      flagship digital initiative aims to make the Internet more
                      accessible, promoting e-governance, e-banking, e-education
                      and e-health.
                    </li>
                    <br />
                    <li>
                      In the 2019 Union Budget, Finance Minister Nirmala
                      Sitharaman said the government would offer
                      industry-relevant skill training for 10 million youth in
                      India in technologies like AI, Big Data and Robotics.
                    </li>
                    <br />
                    <li>
                      Additionally, policy-level initiatives by the Minidtry of
                      Electronics and Information Technology and programmes
                      around AI by NASSCOM and Defence Research and Development
                      Organisation (DRDO) have laid the groundwork for future
                      disruption and created a roadmap for AI in India.
                    </li>
                    <br />
                    <li>
                      Centre for Artificial Intelligence and Robotics (CAIR) is
                      a laboratory of the DRDO, for research and development in
                      AI, Robotics, command and control, networking, information
                      and communication security. CAIR is involved in the
                      development of mission-critical products for battlefield
                      communication and management systems.
                    </li>
                    <br />
                    <li>
                      <b>US-India AI Initiative:</b>The Indo-US Science and
                      Technology Forum (IUSSTF) launched the US-India Artificial
                      Intelligence Initiative in 2021 to foster AI innovation by
                      sharing ideas and experiences, identifying new
                      opportunities in research and development and bilateral
                      collaboration.
                    </li>
                    <br />
                    <li>
                      <b>Applied AI Research Centre, Telangana:</b>The Telangana
                      Government, in collaboration with Intel India,
                      International Institute of Information Technology,
                      Hyderabad (IIIT-H), and Public Health Foundation of India
                      (PHFI) launched INAI (Intel AI), an applied AI research
                      centre in Hyderabad. The centre focuses on solving
                      challenges in India’s healthcare and smart mobility
                      segment.
                    </li>
                    <br />
                    <li>
                      <b>Responsible AI for Youth:</b> Responsible AI for Youth
                      is a national programme for government schools to empower
                      the young generation to become AI-ready and reduce the
                      skill gap in India. Established by the National
                      e-Governance Division of MeitY, the platform aims to help
                      the students develop a new-age tech mindset and relevant
                      skill-sets.
                    </li>
                    <br />
                    <li>
                      <b>MCA 3.0 portal:</b> The Ministry of Corporate Affairs
                      has launched a new version of its portal, Version 3.0, MCA
                      21, which leveragees data analytics, AI, an d ML, to
                      simplify regulatory filings for companies. The idea behind
                      the revamp is to promote ease of doing business and
                      compliance monitoring.
                    </li>
                    <br />
                    <li>
                      <b>AI portal:</b> Jointly developed by MeitY and NASSCOM
                      in June 2020, the Union Government launched a dedicated AI
                      portal, India AI, as a central hub for everything. The
                      portal is a one-stop-shop for all AI-related developments
                      and initiatives in India.
                    </li>
                    <br />
                    <li>
                      <b>National Research Foundation:</b> NRF, an autonomous
                      body under the new National Education Policy (NEP) 2020,
                      has been established to boost research across segments,
                      including AI.
                    </li>
                    <br />
                    <li>
                      <b>Promoting AI in schools:</b> The National Council of
                      Educational Research and Training (NCERT) has prepared a
                      new National Curriculum Framework for School Education in
                      pursuance of the National Education Policy 2020. This aims
                      at introducing a basic course on AI at the secondary
                      level.
                    </li>
                    <br />
                    <li>
                      <b>Policy on Internet of Things (IoT):</b> The Policy on
                      IoT lays a framework that includes setting up of 100 smart
                      cities in the country and automating several industries
                      such as energy, health and disaster management via
                      remotely connected devices.
                    </li>
                    <br />
                    <li>
                      <b>Smart Cities: </b>IoT plays a pivotal role in the Smart
                      Cities project. The government has dedicated an investment
                      of Rs 7,060 crore on this project.
                    </li>
                    <br />
                    <li>
                      <b>Centre of Excellence for IoT, Bengaluru: </b>The Union
                      Government, in association with NASSCOM, has launched a
                      Centre of Excellence for IoT.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />
                  <ul>
                    <li>
                      The Tamil Nadu Government has set up of a Centre of
                      Excellence in Emerging Technologies (CEET) under the aegis
                      of Tamil Nadu e-Governance Agency (TNeGA). It is mandated
                      to work with government departments and help them solve
                      their key governance problems with the help of Emerging
                      Technologies such as AI, IoT, Blockchain, Drones, AR /VR
                      and others.
                    </li>
                    <br />
                    <li>
                      Digital Accelerator under Yaadhum Oorae : The Government
                      of Tamil Nadu has announced a Digital Accelerator scheme
                      under Yaadhum Oorae in association with American Tamil
                      Entrepreneurs Association (ATEA) to promote Startups
                      investing in Tamil Nadu from US in various fields, such as
                      IT/healthcare/EV/emerging areas on IoT, AI, Cloud
                      Computing/SDGs.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <ul>
                    <li>
                      <b>iTNT Hub:</b> iTNT Hub is India’s First Emerging and
                      DeepTech Innovation Network established by the Government
                      of Tamil Nadu. This acts as a catalyst that engages,
                      activates and brings together diverse industries, a
                      forward-thinking government, global alliances, the State’s
                      thriving entrepreneurial capabilities and India’s vast,
                      untapped capability to create a thriving innovation
                      ecosystem that delivers outcomes that benefit the world.
                    </li>
                    <br />
                    <li>
                      <b>Research and Educational Institutions:</b>Tamil Nadu is
                      home to several prestigious educational institutions and
                      universities, including the Indian Institute of Technology
                      (IIT) Madras, which has been actively involved in AI
                      research and education. These institutions contribute
                      significantly to AI knowledge and talent development.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Incubators</h5>
                  <br />
                  <ul>
                    <li>
                      Vel’s University Incubation Centre has embarked on
                      nurturing innovation and entrepreneurship focusing on AI
                      and IoT.
                    </li>
                    <br />
                    <li>
                      Forge, Kumaraguru Incubation Centre empowers government,
                      industry and academia to exploit strategic opportunities
                      through innovation, powered by deep-technologies such as
                      AI, Data Science, Robotics, Power Electronics, AR/VR and
                      IoT.
                    </li>
                    <br />
                    <li>
                      Software Technology Parks of India : Software Technology
                      Parks of India (STPI) has its focus on boosting up
                      software exports from the country. It has 63 centres
                      spread across the country and works with an objective to
                      implement STP/EHTP scheme of the Union Government and set
                      up and manage infrastructural facilities.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Alml;
