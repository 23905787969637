import React from "react";
import InvestorStartupFilters from "../InvestorStartupFilters";
import { Card, Grid } from "@mui/material";
import Rating from '@mui/material/Rating';

const AssignedStartup = ({name}) => {
  const cardData = [
    {
      id: 1,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",

      title: "abCoffee",
      imageSrc: "/images/Page-1.png",
    },
    {
      id: 2,
      logoSrc: "/images/GREENPOD_LOGO2.png",
      title: "50 Fin",
      imageSrc: "/images/Page-1.png",
    },
    {
      id: 3,
      logoSrc: "/images/GREENPOD_LOGO3.png",
      title: "Accio Robotics",
      imageSrc: "/images/Page-1.png",
    },
    {
      id: 4,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",
      title: "50 Fin",
      imageSrc: "/images/Page-1.png",
    },
    {
      id: 5,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",

      title: "ab Coffee",
      imageSrc: "/images/Page-1.png",
    },
    {
      id: 6,
      logoSrc: "/images/GREENPOD_LOGO2.png",
      title: "50 Fin",
      imageSrc: "/images/Page-1.png",
    },
    {
      id: 7,
      logoSrc: "/images/GREENPOD_LOGO3.png",
      title: "Accio Robotics",
      imageSrc: "/images/Page-1.png",
    },
    {
      id: 8,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",
      title: "50 Fin",
      imageSrc: "/images/Page-1.png",
    },
  ];
  
  return (
    <div>
      <InvestorStartupFilters name={'assignedStartups'}/>
      <div className="mt-5">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          spacing={5}
        >
          {cardData.map((card) => (
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Card key={card.id} className="sup-sm-crds-inv-start  mb-3">
                <div className="service-card-back-layers">
                  <div className="layer-crd-back-img-inv-start">
                    <div className="ratings-star">
                    <Rating name="size-small" size="small"  max={1} />
                    </div>
                    <div className="service-card-back-profss">
                      <img
                        src={card.logoSrc}
                        alt="logo"
                        className="smcrd-card-back-image"
                      />
                    </div>
                  </div>
                  <div className="card-content-offers-startup">
                    <span>{card.title}</span>
                  </div>
                  <div className="card-content-offers1-startup">
                    <img src={card.imageSrc} alt="globe" />
                  </div>
                </div>
                <div className="inv-crd-acts">
                    <button className="inv-view-btons">View Profile</button>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default AssignedStartup;
