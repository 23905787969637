import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Textiletech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTN-TextileTech-Fashion.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Textile Tech & Fashion</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Production Linked Incentive (PLI) Scheme
                  </h5>
                  <br />
                  <p>
                    The PLI Scheme offers incentives for manufacturing and
                    exporting specific man-made fibre textile products, with a
                    government-approved budget of Rs. 10,683 crore (US$ 1.44
                    billion).
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>PM-Mitra Scheme</h5>
                  <br />
                  <p>
                    The Union Budget 2021-22 approved the creation of 7 PM Mega
                    Integrated Textile Region and Apparel (PM MITRA) Parks,
                    investing US$ 541.82 million (Rs. 4,445 crore) over three
                    years to establish state-of-the-art textile parks with
                    common utilities and an R&D lab by 2027–28.
                  </p>
                  <br />

                  <h5 style={{ color: "#39364e" }}>
                    Amended Technology Up-gradation Fund Scheme (A-TUFS)
                  </h5>
                  <br />
                  <p>
                    The Amended Technology Upgradation Fund Scheme (ATUFS) was a
                    Capital Investment Subsidy (CIS) scheme implemented from
                    2016 to 2022 with a budget of Rs. 17,822 crores. As of
                    December 2022, it disbursed a total of US$ 75.74 million
                    (Rs. 621.41 crore) in subsidies across 3,159 cases under
                    this scheme.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>SAATHI Scheme</h5>
                  <br />
                  <p>
                    SAATHI Scheme, a collaboration between the Ministry of
                    Textiles and Energy Efficiency Services Ltd. (EESL), aims to
                    upgrade textile technologies and rejuvenate India’s power
                    loom sector.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Weavers MUDRA Scheme</h5>
                  <br />
                  <p>
                    To support the handloom weavers/weaver entrepreneurs, the
                    Weavers MUDRA Scheme was launched to provide margin money
                    assistance at 20% of the loan amount subject to a maximum of
                    Rs. 10,000 (US$ 127.72) per weaver. The loan is provided at
                    an interest rate of 6% with a credit guarantee of three
                    years.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Government e-Marketplace</h5>
                  <br />
                  <p>
                    The government has facilitated the onboarding of 1.77 lakh
                    weavers, artisans, and handloom entities on Government
                    e-Marketplace (GeM). This move aims to expand their market
                    reach, enabling direct sales to government departments and
                    organizations, thus supporting the handloom and handicrafts
                    sector.
                  </p>
                  <br />
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Incubator and R & D Institutions
                  </h5>
                  <br />

                  <ul>
                    <li>
                      SITRA (South India Textile Research Association) for
                      Technical Textile -Medical Tech, Coimbatore
                    </li>
                    <br />
                    <li>
                      NIFT-TEA Incubator-Knitwear Fashion Institute, Tiruppur
                    </li>
                    <br />
                    <li>Bombay Textile Research Association, Mumbai.</li>
                    <br />
                    <li>Northern India Textile Research Association, UP</li>
                    <br />
                  </ul>
                  <p>Government Ministry/ Department</p>
                  <br />
                  <ul>
                    <li>Ministry of Textiles</li>
                    <br />
                    <li>The Department of Handlooms and Textiles</li>
                  </ul>
                  <br />
                  <p>
                    APEX INSTITUTIONS (Under Department of Handloom & textile,
                    Tamil nadu)
                  </p>
                  <br />
                  <ul>
                    <li>
                      Tamilnadu Handloom Weavers’ Co-operative Society Ltd.
                      (CO-OPTEX)
                    </li>
                    <br />
                    <li>
                      Tamilnadu Co-operative Textile Processing Mills Ltd (TCTP)
                    </li>
                    <br />
                    <li>
                      Tamilnadu Co-operative Spinning Mills Federation Ltd
                      (TANSPIN)
                    </li>
                    <br />
                    <li>Tamil Nadu Zari Ltd. (ZARI)</li>
                    <br />
                    <li>
                      Tamil Nadu Handloom Development Corporation Ltd (THDC)
                    </li>
                    <br />
                    <li>Tamilnadu Textile Corporation Ltd (TNTC)</li>
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Textiletech;
