import React from "react";
import { Autocomplete, Button, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Switch, TextField, } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useContext } from "react";
import { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import dayjs from "dayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { useParams } from "react-router-dom";
const tomorrow = dayjs().add(0, "day");
const MeetingAdd = () => {
  const { id } = useParams()
  let meetingId
  if (id !== undefined) {
    meetingId = atob(id)
  }
  const { setPageLoading, validateNumberonly, navigator, } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, getValues, reset, formState: { errors } } = useForm({ values: formData });
  useDidMountEffect(() => {
    getVenusNameList()
    departmentListApi()
    if (meetingId) {
      getApi()
    }
  }, [])
  const getApi = () => {
    setPageLoading(true);
    apiService(`meeting/get?id=${meetingId}`, '', 'get').then((result) => {
      setPageLoading(false);
      if (result && result.data) {
        var data = result.data
        data.startDate = dayjs(data.startDate, "YYYY-MM-DD");
        data.endDate = dayjs(data.endDate, "YYYY-MM-DD");
        data.startTime = dayjs(data.startTime, "HH:mm");
        data.endTime = dayjs(data.endTime, "HH:mm");
        if (data.mode === 'Offline') {
          data.venueId = data.venue
          getVenusNameList()
        }
        data.venueId = data.venue
        getVenusNameList()        
        setFormData(data)
      }
    }).catch((err) => {

    });
  }
  const [startdate, setStartDate] = useState(null);
  const handleStartDateFormat = (newValue) => {
    setStartDate(newValue);
    setValue("startDate", newValue);
    clearErrors("startDate");
    // const datePart = newValue.format('YYYY-MM-DD');
  };
  const [enddate, setEndDate] = useState(null);
  const handleEndDateFormat = (newValue) => {
    setEndDate(newValue);
    setValue("endDate", newValue);
    clearErrors("endDate");
    // const datePart = newValue.format('YYYY-MM-DD');
  };
  const [startTime, setStartTime] = useState(null);
  const handleStartTimeFormat = (newValue) => {
    setStartTime(newValue);
    setValue("startTime", newValue);
    clearErrors("startTime");
    // const timePart = newValue.format('HH:mm');
  };
  const [endTime, setEndTime] = useState(null);
  const handleEndTimeFormat = (newValue) => {
    setEndTime(newValue);
    setValue("endTime", newValue);
    clearErrors("endTime");
    // const timePart = newValue.format('HH:mm');
  };
  const handleEventMode = (value) => {
    setValue("mode", value);
    clearErrors('mode')
  }
  const [eventVenueList, setEventVenueList] = useState([])
  const getVenusNameList = () => {
    apiService(`venue/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.venues) {
        let data = []
        result.data.venues.forEach(element => {
          data.push(element)
        });
        const val = {
          "id": 0,
          "name": 'Add Venue',
          "location": '',
          "pincode": '',
          "mapLink": '',
        }
        data.push(val)
        setEventVenueList(data)

      } else {
        let data = []
        const val = {
          "id": 0,
          "name": 'Add Venue',
          "location": '',
          "pincode": '',
          "mapLink": '',
        }
        data.push(val)
        setEventVenueList(data)
      }
    }).catch((err) => {

    });
  }
  const handleSelectEventVenue = (value) => {
    var data = eventVenueList.filter((list) => list.name === value)
    setValue('venueId', data[0].name)
    clearErrors('venueId')
    setValue('location', data[0].location)
    clearErrors('location')
    setValue('pincode', data[0].pincode)
    clearErrors('pincode')
    setValue('mapLink', data[0].mapLink)
    clearErrors('mapLink')
  }
  const [departmentList, setDepartmentList] = useState([])
  const departmentListApi = () => {
    apiService(`department/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.department) {
        setDepartmentList(result.data.department)
      }
    })
  }
  const handleDepartment = (val) => {
    setValue("department", val);
    clearErrors("department");
  }

  const onSubmit = (data) => {
    setPageLoading(true);
    data.startDate = data.startDate.format('YYYY-MM-DD');
    data.endDate = data.endDate.format('YYYY-MM-DD');
    data.startTime = data.startTime.format('HH:mm');
    data.endTime = data.endTime.format('HH:mm');
    var depValue = departmentList.filter((list) => list.name === data.department)
    data.departmentId = depValue[0].id
    if (data.mode === 'Offline' && data.venueId) {
      var venuData = eventVenueList.filter((list) => list.name === data.venueId)
      data.venueId = venuData[0].id
    }

    apiService('meeting/save', data, "post").then((res) => {
      setPageLoading(false)
      if (res && res.data) {
        if (res.data.responseStatus === "Success") {
          onClose()
          notifyService("success", "Success", "");
        }
      }
    });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };
  const onClose = () => {
    reset()
    navigator('/admin/meeting/list')
  };
  return (
    <div>
      <div className="meeting-title">
        <h2>{meetingId ? 'Update' : 'Create'} Meeting</h2>
        <p>Setup your Meeting Details to start listing meetings</p>
      </div>
      <div className="container-fluid">
        <div className="meeting-sub-title mb-4">
          <h5>Meeting Details</h5>
        </div>
        {/* <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item lg={6} md={6} xs={12} sm={12}>
            <div className="meeting-sub-title">
              <h5>Meeting Details</h5>
            </div>
          </Grid>
        </Grid> */}
        <form
          className="signin-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item lg={6} md={6} xs={12} sm={12} className="mb-3">
              <div className='input_background mb-4'>
                <Controller
                  name="title"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Title is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Meeting Title"
                      placeholder="Please Enter Meeting Title"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.title && errors.title.message}
                </FormHelperText>
              </div>
              <div className='input_background mb-4'>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Meeting Desription is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-multiline-static"
                      label="Meeting Desription"
                      type="text"
                      fullWidth
                      {...field}
                      multiline
                      rows={4}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.description && errors.description.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12} className="mb-3">
              <p>Date & Time</p>
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                  <div className='input_background'>
                    <Controller
                      name={`startDate`}
                      control={control}
                      rules={{ required: "Start Date is required" }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]} fullWidth>
                            <DatePicker
                              label="Start Date"
                              format="DD/MM/YYYY"
                              minDate={tomorrow}
                              value={startdate}
                              {...field}
                              sx={{ width: "100%" }}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) =>
                                handleStartDateFormat(newValue)
                              }
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startDate && errors.startDate.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                  <div className='input_background'>
                    <Controller
                      name={`endDate`}
                      control={control}
                      rules={{ required: "End Date is required" }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]} fullWidth>
                            <DatePicker
                              label="End Date"
                              format="DD/MM/YYYY"
                              minDate={tomorrow}
                              value={enddate}
                              {...field}
                              sx={{ width: "100%" }}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) => handleEndDateFormat(newValue)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.endDate && errors.endDate.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                  <div className='input_background'>
                    <Controller
                      name={`startTime`}
                      control={control}
                      rules={{ required: "Start Time is required" }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]} fullWidth>
                            <TimePicker
                              label="Start Time "
                              format="HH:mm"
                              value={startTime}
                              {...field}
                              sx={{ width: "100%" }}
                              inputFormat="HH:mm"
                              onChange={(newValue) =>
                                handleStartTimeFormat(newValue)
                              }
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startTime && errors.startTime.message}
                    </FormHelperText>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                  <div className='input_background'>
                    <Controller
                      name={`endTime`}
                      control={control}
                      rules={{ required: "End Time is required" }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]} fullWidth>
                            <TimePicker
                              label="End Time "
                              format="HH:mm"
                              value={endTime}
                              {...field}
                              sx={{ width: "100%" }}
                              inputFormat="HH:mm"
                              onChange={(newValue) => handleEndTimeFormat(newValue)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.endTime && errors.endTime.message}
                    </FormHelperText>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={12} className="mb-3">
              <div className='input_background mb-4'>
                <Controller
                  name="mode"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Meeting Mode is required' }}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel> Choose Meeting Mode </FormLabel>
                      <RadioGroup row  {...field} onChange={(e) => handleEventMode(e.target.value)}>
                        <FormControlLabel value={'Online'} control={<Radio />} label="Online" />
                        <FormControlLabel value={'Offline'} control={<Radio />} label="Offline" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.mode && errors.mode.message}
                </FormHelperText>
              </div>
              <div className='input_background mb-4'>
                <Controller
                  name="department"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Department is required" }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      {...field}
                      options={departmentList.map((val) => val.name)}
                      onChange={(e, selectedOptions) =>
                        handleDepartment(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Department" />
                      )}
                    // disabled={readyOnlyValue}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.department && errors.department.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12} className="mb-3">
              {
                getValues('mode') !== '' && (
                  <>
                    <p className='font-color-gray'>Venue details</p>
                    <Grid container spacing={3}>
                      {
                        getValues('mode') === 'Online' ? (
                          <>
                            <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                              {/* <p className='font-color-gray'>Online Meeting Details</p> */}
                              <div className='input_background'>
                                <Controller
                                  name="meetLink"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "Zoom / Other Meet Link  is required" }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Zoom / Other Meet Link "
                                      placeholder="Enter meeting Link"
                                      fullWidth
                                      type="text"
                                      {...field}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.meetLink && errors.meetLink.message}
                                </FormHelperText>
                              </div>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                              <div className='input_background'>
                                <Controller
                                  name="venueId"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "Venue is required" }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      disableClearable
                                      disablePortal
                                      {...field}
                                      options={eventVenueList.map((val => val.name))}
                                      onChange={(e, selectedOptions) =>
                                        handleSelectEventVenue(selectedOptions)
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} label="Venue" />
                                      )}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.venueId && errors.venueId.message}
                                </FormHelperText>
                              </div>
                            </Grid>
                            {
                              getValues('venueId') === 'Add Venue' && (
                                <Grid item lg={5} md={5} sm={12} xs={12}>
                                  <div className='input_background'>
                                    <Controller
                                      name="venue"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: "Venue is required" }}
                                      render={({ field }) => (
                                        <TextField
                                          variant="outlined"
                                          label="Venue Name"
                                          placeholder="Enter Venue Name"
                                          fullWidth
                                          type="text"
                                          {...field}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.venue && errors.venue.message}
                                    </FormHelperText>
                                  </div>
                                </Grid>
                              )
                            }
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                              <div className='input_background'>
                                <Controller
                                  name="location"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "Location is required" }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Location"
                                      placeholder="Enter Full Address "
                                      fullWidth
                                      type="text"
                                      {...field}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.location && errors.location.message}
                                </FormHelperText>
                              </div>
                            </Grid>
                            <Grid item lg={3} md={3} sm={12} xs={12}>
                              <div className='input_background'>
                                <Controller
                                  name="pincode"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "Pincode is required" }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Pincode"
                                      placeholder="Enter Pincode "
                                      fullWidth
                                      type="text"
                                      {...field}
                                      onKeyDown={(e) => validateNumberonly(e)}
                                      inputProps={{ maxLength: 6, minLength: 6 }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.pincode && errors.pincode.message}
                                </FormHelperText>
                              </div>
                            </Grid>
                            <Grid item lg={9} md={9} sm={12} xs={12}>
                              <div className='input_background'>
                                <Controller
                                  name="mapLink"
                                  control={control}
                                  defaultValue=""
                                  rules={{ required: "Map Link is required" }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Map Link"
                                      placeholder="Enter Map Destination link"
                                      fullWidth
                                      type="text"
                                      {...field}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.mapLink && errors.mapLink.message}
                                </FormHelperText>
                              </div>
                            </Grid>
                            {
                              getValues('venueId') === 'Add Venue' && (
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                  <div className='input_background mb-4'>
                                    <Controller
                                      name="saveAddress"
                                      control={control}
                                      defaultValue=""
                                      rules={{ required: false }}
                                      render={({ field }) => (
                                        <FormControl>
                                          <FormGroup row  {...field}>
                                            <FormControlLabel control={<Switch />} label="Save to Address Book" />
                                          </FormGroup>
                                        </FormControl>
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.saveAddress && errors.saveAddress.message}
                                    </FormHelperText>
                                  </div>
                                </Grid>
                              )
                            }

                          </>
                        )
                      }

                    </Grid>
                  </>
                )
              }
            </Grid>
            {/* <Grid item lg={3} md={6} sm={6} xs={12}>
              <Box className="bx-sh">
                <FormControl sx={{ width: "100%" }}>
                  <Controller
                    name="venue"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Venue is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        options={dep}
                        onChange={(e, selectedOptions) =>
                          handleVenue(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Venue *" />
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.venue && errors.venue.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <p className="mb-0">Online Meeting Details</p>
              <Box className="bx-sh">
                <Controller
                  name="meeting"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Zoom / Other Meet Link  is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Zoom / Other Meet Link "
                      placeholder="Enter meeting Link"
                      fullWidth
                      type="text"
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.meeting && errors.meeting.message}
                </FormHelperText>
              </Box>
            </Grid> */}
            {/* <Grid item lg={6} md={6} xs={12} sm={12}>
              <div className='input_background'>
                <Controller
                  name="department"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Department is required" }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      {...field}
                      // limitTags={2}
                      options={departmentList}
                      onChange={(e, selectedOptions) =>
                        handleDepartment(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Department *" />
                      )}
                    // disabled={readyOnlyValue}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.department && errors.department.message}
                </FormHelperText>
              </div>
            </Grid> */}
            <div className="text-center mb-3 mt-4 w-100">
              <Button onClick={onClose} className="discard-btn">
                Close
              </Button>
              <Button type="submit" className="publish-button">
                {meetingId ? 'Update' : 'Save'}
              </Button>
            </div>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default MeetingAdd;
