import React from "react";
import InvestorStartupFilters from "../InvestorStartupFilters";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import { IoAddCircle } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const ICCohorts = () => {
  return (
    <div>
      <InvestorStartupFilters name={"Iccohorts"} />
      <div className="mt-5">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
          sx={{rowGap:'15px',columnGap:'15px'}}
        >
          <Grid item xs={12} sm={12} md={5} lg={5}>
           <NavLink to='/investor/startup/iccohorts/list'>
           <Card sx={{ maxWidth: 400 ,borderRadius:'8px' }}>
            <div className="all-boxes-iccs">
              <div className="icc-company-logos-crd">
                <div className="crd-grey-box-icc">
                  <img src={'/images/GREENPOD_LOGO1 (1).png'} alt="company-logo" className="icc-company-logos"/>
                </div>
                <div className="crd-grey-box-icc">
                <img src={' /images/GREENPOD_LOGO2.png'} alt="company-logo" className="icc-company-logos"/>
               
                </div>
              </div>
              <div className="icc-company-logos-crd">
                <div className="crd-grey-box-icc">
                <img src={' /images/GREENPOD_LOGO3.png'} alt="company-logo" className="icc-company-logos"/>
                </div>
                <div className="crd-grey-box-icc">
                <img src={'/images/GREENPOD_LOGO1 (1).png'} alt="company-logo" className="icc-company-logos"/>
                </div>
              </div>
              </div>
              <CardContent>
                <div className="icc-details">
                  <h6>IC Cohort 1</h6>
                  <p>
                    <span>Created by : </span>Sai Ranganathan StartupTN
                  </p>
                  <div className="icc-subhead-dis">
                    <p>
                      <span>Updated :</span> 04 May, 2024
                    </p>
                    <p className="blue-sub-icc">
                      <span>Total :</span> 33 Startups
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
            </NavLink> 
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
          <Card sx={{ maxWidth: 400 ,borderRadius:'8px' }}>
            <div className="all-boxes-iccs">
              <div className="icc-company-logos-crd">
                <div className="crd-grey-box-icc">
                  <img src={'/images/GREENPOD_LOGO1 (1).png'} alt="company-logo" className="icc-company-logos"/>
                </div>
                <div className="crd-grey-box-icc">
                <img src={' /images/GREENPOD_LOGO2.png'} alt="company-logo" className="icc-company-logos"/>
               
                </div>
              </div>
              <div className="icc-company-logos-crd">
                <div className="crd-grey-box-icc">
                <img src={' /images/GREENPOD_LOGO3.png'} alt="company-logo" className="icc-company-logos"/>
                </div>
                <div className="crd-grey-box-icc">
                <img src={'/images/GREENPOD_LOGO1 (1).png'} alt="company-logo" className="icc-company-logos"/>
                </div>
              </div>
              </div>
              <CardContent>
                <div className="icc-details">
                  <h6>IC Cohort 2</h6>
                  <p>
                    <span>Created by : </span>Sai Ranganathan StartupTN
                  </p>
                  <div className="icc-subhead-dis">
                    <p>
                      <span>Updated :</span> 04 May, 2024
                    </p>
                    <p className="blue-sub-icc">
                      <span>Total :</span> 33 Startups
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
          <Card sx={{ maxWidth: 400 ,borderRadius:'8px' }}>
              <div className="all-boxes-icc">
              <div className="icc-company-logos-crd">
                <div className="crd-grey-box-icc-3"></div>
                <div className="crd-grey-box-icc-3"></div>
              </div>
              <IoAddCircle className="Add-icc-crd"/>

              <div className="icc-company-logos-crd">
                <div className="crd-grey-box-icc-3"></div>
                <div className="crd-grey-box-icc-3"></div>
              </div>
              </div>
              <CardContent>
                <div className="icc-details1">
                  <h6>Create More Cohorts</h6>
                  <p>
                    Create cohorts and invite startups to further validation
                  </p>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ICCohorts;
