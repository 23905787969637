// import React from 'react';
// import { DataProvider } from './context/DataContext';
// import { ReactNotifications } from 'react-notifications-component';
// import './App.css';
// import 'react-notifications-component/dist/theme.css';
// import Baseroute from './components/Baseroute';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Undermaintenance from './components/common-pages/under-maintenance-page/Undermaintenance';
// import Sesstiontimeout from './components/common-pages/sesstion-time-out-page/Sesstiontimeout';
// import Pagenotfound from './components/common-pages/page-not-found/Pagenotfound';
// import ScrolltoTop from './components/common-pages/ScrolltoTop';

// function App() {
  
//   return (
//     <div className="App">
//       <ReactNotifications />
//       <DataProvider>
//         <ScrolltoTop />
//         <Baseroute />
//       </DataProvider>
//       <Routes>
//         <Route path='/under-maintenance' element={<Undermaintenance />} />
//         <Route path='/sesstion-timeout' element={<Sesstiontimeout />} />
//         <Route path='404' element={<Pagenotfound />} />
//       </Routes>
//     </div >
//   );
// }

// export default App;

// "start": "NODE_ENV=development node scripts/start.js",
//   "start-prod": "NODE_ENV=production node scripts/start.js",
//     "build-dev": "NODE_ENV=development node scripts/build.js",
//       "build-prod": "NODE_ENV=production node scripts/build.js",


import React, { useEffect } from 'react';
import { DataProvider } from './context/DataContext';
import { ReactNotifications } from 'react-notifications-component';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import Baseroute from './components/Baseroute';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Undermaintenance from './components/common-pages/under-maintenance-page/Undermaintenance';
import Sesstiontimeout from './components/common-pages/sesstion-time-out-page/Sesstiontimeout';
import Pagenotfound from './components/common-pages/page-not-found/Pagenotfound';
import ScrolltoTop from './components/common-pages/ScrolltoTop';

function App() {
  const navigate = useNavigate();

  const extractAuthToken = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const handleTokenExpiration = (token) => {
    const payload = extractAuthToken(token);
  
    if (payload) {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const expiryTime = payload.exp; // Get the expiration time from the payload
  
      if (expiryTime < currentTime) {
        // Token is expired
        localStorage.clear(); // Clear local storage
        navigate('/login'); // Navigate to the login page
      }
    }
  };
  
  useEffect(() => {
    // Check the token on app load
    const token = localStorage.getItem('jwttoken'); // Replace 'jwttoken' with your actual token key
    if (token) {
      handleTokenExpiration(token);
    }
  }, [navigate]);

  return (
    <div className="App">
      <ReactNotifications />
      <DataProvider>
        <ScrolltoTop />
        <Baseroute />
      </DataProvider>
      <Routes>
        <Route path='/under-maintenance' element={<Undermaintenance />} />
        <Route path='/sesstion-timeout' element={<Sesstiontimeout />} />
        <Route path='404' element={<Pagenotfound />} />
      </Routes>
    </div>
  );
}

export default App;
