import React from 'react'
import Navbar from '../home-module/navbar-page/Navbar'
import { Outlet } from 'react-router-dom'

const InitiativeLayout = () => {
  return (
    <div >
      <Navbar/>
      <Outlet></Outlet>
    </div>
  )
}

export default InitiativeLayout