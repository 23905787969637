import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Automotive = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTN-sectorAutomative-and-Ev.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">
            Automotive, EV & Smart Mobility
          </h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h6>State Government</h6>
                  <br />
                  <ul>
                    <li>
                      Tamil Nadu Government’s new Electric Vehicle Policy 2023
                      spells out that
                    </li>
                    <br />
                    <ul>
                      <li>
                        The government will promote Chennai, Coimbatore,
                        Tiruchi, Madurai, Salem and Tirunelveli as pilot cities
                        for implementing e-mobility solutions.
                      </li>
                      <br />
                      <li>
                        EV projects will be provided 100% exemption from
                        electricity tax for a period of five years on the power
                        purchased from TANGEDCO or generated and consumed from
                        captive sources.
                      </li>
                      <br />
                    </ul>
                    <li>
                      Tamil Nadu plans to electrify part of its public transport
                      fleet, including taxis and buses, by nurturing local
                      players. It plans to go beyond incentives and create
                      demand by raising awareness and improving charging
                      infrastructure while including EVs in the list of
                      priority-sector lending.
                    </li>
                    <br />
                    <li>
                      StartupTN offers a special package of assistance to
                      GreenTech Startups, especially those involved in the EV
                      sector.The package includes an enhanced assistance of Rs
                      15 lakh under TANSEED.
                    </li>
                    <br />
                  </ul>
                  <h6>Union Government</h6>
                  <br />
                  <ul>
                    <li>
                      The Union Government is implementing cheaper road fees,
                      scrapping and refit incentives to help meet its aim of 30%
                      EVs by 2030.
                    </li>
                    <br />
                    <li>
                      It is promoting the installation of EV charging stations
                      by providing capital subsidies through the FAME India
                      Programme Phase II.
                    </li>
                    <br />
                    <li>
                      PLIs are financial initiatives for electric vehicles to
                      enhance productivity to make it easy to sell EVs at a
                      lesser price.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <p>Infrastructural Testing Facilities & Knowledge Centres</p>
                  <br />
                  <h6>India</h6>
                  <br />
                  <ul>
                    <li>
                      International Centre For Automotive Technology (iCAT) at
                      Manesar (Haryana).
                    </li>
                    <br />
                    <li>
                      National Automotive Test Tracks (NATRAX) at Pithampur near
                      Indore (Madhya Pradesh).
                    </li>
                    <br />
                    <li>Automotive Research Association of India (ARAI).</li>
                    <br />
                  </ul>
                  <h6>Tamil Nadu</h6>
                  <br />
                  <ul>
                    <li>
                      Global Automotive Research Centre (GARC) at Oragadam near
                      Chennai (Tamil Nadu).
                    </li>
                    <br />
                    <li>
                      Centre for Battery Engineering & Electric Vehicles, IITM.
                    </li>
                    <br />
                    <li>
                      Electrical Mobility Research Laboratory (EMRL), SRM
                      Institute of Science & Technology, Chennai.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Automotive;
