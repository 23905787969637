import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Signuplayout from '../../auth-module/signup-details-page/Signuplayout'
import Quickbrief from './Quickbrief'
import FoundedetailsInnovators from './team-info-page/FoundedetailsInnovators';
import DocumentsInnovators from './organization-info-page/DocumentsInnovators';
import SociallinksInnovators from './organization-info-page/SociallinksInnovators';
import AddressInnovators from './organization-info-page/AddressInnovators';
import BasicinfoInnovators from './organization-info-page/BasicinfoInnovators';
import MoreinfoInnovators from './organization-info-page/MoreinfoInnovators';
import Foundedetails from '../startup-profile/team-info-page/Foundedetails';
const Innovatorroutes = () => {
    return (
        <div>
            <Routes>
                <Route path='/innovators' element={<Signuplayout />}>
                    <Route path="/innovators" element={<Navigate replace to="/innovators/basicinfo" />} />
                    <Route path='/innovators/basicinfo' element={<BasicinfoInnovators />} />
                    <Route path='/innovators/address' element={<AddressInnovators />} />
                    <Route path='/innovators/social-links' element={<SociallinksInnovators />} />
                    <Route path='/innovators/documents' element={<DocumentsInnovators />} />
                    <Route path='/innovators/moreinfo' element={<MoreinfoInnovators />} />
                    <Route path='/innovators/founder' element={<Foundedetails url={'innovators/randdagencies'} navigate={'/innovators/quick-brief'}/>} />
                    {/* <Route path='/innovators/teamsize' element={<TeamsizeInnovators />} />
                    <Route path='/innovators/contact-details' element={<ContactdetailsInnovators />} /> */}
                    <Route path='/innovators/quick-brief' element={<Quickbrief />} />
                </Route>
            </Routes>
        </div>
    )
}

export default Innovatorroutes