import { Box, Button, Grid, InputBase, MenuItem, Select, Tab, Tabs, ToggleButton, styled } from '@mui/material'
import React, { useState } from 'react'
import { useContext } from 'react';
import DataContext from '../../../../context/DataContext';
import { TabContext, TabPanel } from '@mui/lab';
import { useForm } from 'react-hook-form';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '24px',
  backgroundColor: 'rgb(255 255 255)',
  border: '1px solid #00000017',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#d9d9d9',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '18ch',
      },
    },
  },
}));


const OpenInnovationCorporate = () => {
  const { navigator, setSearchSupportInputValue, setSelectedSupportPeriod } = useContext(DataContext);
  const { handleSubmit, control, clearErrors, reset, formState: { errors } } = useForm({})
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = (e) => {
    setSearchSupportInputValue(e.target.value);
  };
  return (
    <>
      <section className='container mt-5'>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item sm={12} md={6} lg={6}>
            <h3 className='blueee-clrr font-weight-bold mb-3'>Corporate</h3>
            <p>
              Corporates interested in utilising external expertise to accelerate innovation can post their problem
              statements in the portal to source solutions from startups and innovators.
            </p>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <center>
              <div className="float-right mt-5 pb-5">
                <button className="bule-clr-button">Post Challenge</button>
              </div>
            </center>
          </Grid>
        </Grid>
      </section>


      <section className='mt-5'>
        <TabContext value={value} className="tab-div">
          <div className="inv-name-tab-surya">
            <Box className="inv-tabs-boxs-surya-v4">
              <Tabs
                value={value}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className='scroll-inv-tabs'
              >
                <Tab
                  label="Live Challenge"
                  value="1"
                  style={{
                    backgroundColor: value === "1" ? "#083FB2" : "",
                    color: value === "1" ? "white" : "#000",
                  }}
                  className="setFont"
                />
                <Tab
                  label="Past Challenge"
                  value="2"
                  style={{
                    backgroundColor: value === "2" ? "#083FB2" : "",
                    color: value === "2" ? "white" : "#000",
                  }}
                  className="setFont"
                />
              </Tabs>

              <span className='tab-dflex'>
                <Search>
                  <SearchIconWrapper>
                    <img src="/images/admin/search-black.png" alt="" />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search Challenge"
                    inputProps={{ 'aria-label': 'Search Challenge' }}
                    onChange={handleSearch}
                    type="search"
                  />
                </Search>
                <ToggleButton className="toggle-fil ml-3">{<img src="/images/filter icon.svg" alt="" />}</ToggleButton>
              </span>
            </Box>


          </div>
          <TabPanel value="1" className="catalyst-tab"></TabPanel>
          <TabPanel value="2" className="catalyst-tab"></TabPanel>
        </TabContext>
      </section>

      <section className='container mt-5'>
        <div className="event-box-surya-v1">
          <div className="layer-crd-back-img-sur ">
            <h6 className='dar-blu pt-2 text-center font-weight-bold'>Saahas Zero Waste Innovation Challenge</h6>
            <p className='p-2'>The innovation challenge is to fuel the development of innovative solutions in Waste management.</p>
            <div className='cardflex p-2'>
              <div>
                <p className='light-bul m-0'> Start Date</p>
                <p style={{ fontSize: '12px' }}>8th May 2024</p>
              </div>
              <div>
                <p className='light-bul m-0'> End Date</p>
                <p style={{ fontSize: '12px' }}>30th June 2024</p>
              </div>
            </div>
          </div>
          <center>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#020671",
                width: "75%",
                marginTop: "20px",
                textTransform: "none",
                "&:hover": { backgroundColor: "#020671" },
              }}
            >
              View More
            </Button>
          </center>
        </div>
      </section>
    </>
  )
}

export default OpenInnovationCorporate
