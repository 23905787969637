import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  TextField,
  FormLabel,
  Divider,
  InputAdornment
} from "@mui/material";
import React, { useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FaArrowLeft } from "react-icons/fa6";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import TodayIcon from '@mui/icons-material/Today';
import SearchableDropdown from "../event-details/SearchableDropdown";

const AdminSupportRaiseTicket = ({ name }) => {
  const { VisuallyHiddenInput, setPageLoading, validateNumberonly } = useContext(DataContext);
  const navigator = useNavigate();
  const { handleSubmit, control, setValue, getValues, reset, clearErrors, formState: { errors }, } = useForm({
    values: {
      attachments: [
        {
          id: 0,
          file: "",
          fileName: "",
          imageURL: "",
        },
      ],
    },
  });
  const {
    fields: photosFields,
    append: photosAppend,
    remove: photosRemove,
  } = useFieldArray({
    control,
    name: "attachments",
  });
  const [isIndividualChecked, setIsIndividualChecked] = useState(false);
  const [selectDept, setSelectDept] = useState(null);
  const handleCheckboxChange = () => {
    setIsIndividualChecked(!isIndividualChecked);
  };
  useDidMountEffect(() => {
    listCateApi()
    groupListApi()
    ProjectDrop()
  }, [])
  const [categories, setCategories] = useState([]);
  const listCateApi = () => {
    apiService(`home/category/list?type=${"support"}`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.categories) {
          setCategories(result.data.categories);
        }
      })
      .catch((err) => { });
  };
  const [projectDatas, setProjectDatas] = useState([]);
  const ProjectDrop = () => {
    var req = {
     id: 0
    };
    apiService(`project/list`, req, "post")
      .then((result) => {
        if (result && result.data && result.data) {
          setProjectDatas(result.data.projects);
        }
      })
      .catch((err) => { });
  };
  
  const [groupNameList, setGroupNameList] = useState([])
  const groupListApi = () => {
    setGroupNameList([])
    apiService('support/group/list', '', 'get').then((result) => {
      if (result && result.data && result.data.responsemodelList) {
        setGroupNameList(result.data.responsemodelList)
      }
    }).catch((err) => {

    });
  }
  const [checkedList, setCheckedList] = useState([]);
  const [indexValue, setIndexValue] = useState([])
  const handleCheckGroup = (e, index, name) => {
    const { value, checked } = e.target;
    var num = Number(value)
    if (checked) {
      setCheckedList([...checkedList, value * 1]);
      setIndexValue([indexValue, index])
      setSelectAnyOne(false)

    } else {
      setIndexValue(indexValue => indexValue.filter((item) => item !== index))
      setCheckedList(checkedList => checkedList.filter((item) => item !== num));
      if (checkedList.length === 0) {
        setCheckedList([])
        setSelectAnyOne(true)
      } else {
        setSelectAnyOne(false)
      }
    }
    if (name === 'Individual') {
      setIsIndividualChecked(!isIndividualChecked);
    }
  }
  const [isOpen, setIsOpen] = useState(false);
  const handleOffParticipantDropDown = (options) => {
    setIsOpen(false)
    var val = options
    if (val !== '') {
      setValue('individualUserId', val.roleId)
      clearErrors('individualUserId')
    }
  }
  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleSelect = (event, value) => {
    setValue("categoryName", value);
    clearErrors("categoryName");
    setSelectedCategory(value);
  };

  const handleProjSelect = (event, value) => {
    setValue("project", value);
    clearErrors("project");
    setSelectDept(value);
    debugger
  };
  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    fileURL,
    arrayName,
    index
  ) => {
    // 
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png"
        ) {
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            // setValue(filekey, ImageValue)
            // setValue(fileName, img_name)
            // setValue(fileURL, logourl1)
            if (arrayName !== "") {
              setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
              setValue(`${arrayName}.${index}.${fileName}`, img_name);
              setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
            } else {
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileURL, logourl1);
            }
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            addPhotoDetails();
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalid",
            "Please check your file format"
          );
        }
      }
    }
  };
  const addPhotoDetails = () => {
    photosAppend({
      id: 0,
      file: "",
      fileName: "",
      imageURL: "",
    });
  };
  const deletePhotos = (index) => {
    photosRemove(index);
  };
  const [selectAnyOne, setSelectAnyOne] = useState(false)
  const onSubmit = (data) => {
   
    // if (checkedList.length > 0) {
      setPageLoading(true);
      const categoryId = data.categoryName.id;
      delete data.categoryName;
      data.categoryId = categoryId;
      data.project = selectDept.name;
      debugger
      // data.supportGroupIds = checkedList;/=
      const trimmedArray = trimEmptyObjects(data.attachments);
      data.attachments = trimmedArray
      data.type = name === 'startup' ? 0 : 1
      apiService("support/ticket/create", data, "post")
        .then((result) => {
          setPageLoading(false);
          if (result.data.responseStatus === "Success") {
            handleClear();
          }
        })
        .catch((err) => { });
    // } else {
    //   setSelectAnyOne(true)
    // }

  };
  const trimEmptyObjects = (arr) => {
    return arr.filter(obj => obj.fileName !== '');
  };
  const onError = (error) => {
    console.log(error);
    if (checkedList.length === 0) {
      setSelectAnyOne(true)
    }
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  const handleClear = () => {
    photosRemove();
    reset();
    handleBackBtn()
  };
  const handleBackBtn = () => {
    navigator(`/admin/support/${name}/list`);
  };
  return (
    <div>
      <div className="support-form-div mt-3">
        <div className="allticket-list">
          <Button className="back-btnss" onClick={handleBackBtn}>
            <FaArrowLeft />
            <span style={{ marginLeft: "5px" }}>Back</span>
          </Button>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={5}
          >
            {/* <Grid item xs={12} sm={12}>
            
          </Grid> */}

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

              {/* <div className="choose-group-div1">
                <div className="sup-title ">
                  <h4 className="mb-4">Choose Group to raise support</h4>
                  <div className="left-side-supp-div">
                    {
                      groupNameList.length > 0 && groupNameList.map((list, index) => (
                        <div className="suppo-lef-list">
                          <div>
                            <p className="mb-0">{list.name} </p>
                          </div>
                          <div>
                            <Checkbox
                              item={list}
                              value={list.id}
                              checked={checkedList.includes(list.id)}
                              onChange={e => handleCheckGroup(e, index, list.name)}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 22 },
                                "&.Mui-checked": { color: "#1C2655" },
                              }}
                            />
                          </div>
                        </div>
                      ))
                    }
                    
                    {isIndividualChecked && (
                      <div>
                        <div className="support-title-field ">
                          <SearchableDropdown isOpen={isOpen} setIsOpen={setIsOpen} handleOffDropDown={handleOffParticipantDropDown} lblName="Search Individual Name" />
                         
                        </div>
                      
                      </div>
                    )}
                    <FormHelperText className="text-danger">
                      {errors.name && errors.name.message}
                    </FormHelperText>
                    {
                      selectAnyOne === true && (
                        <FormHelperText className="text-danger">
                          Select any one Group is required
                        </FormHelperText>
                      )
                    }
                  </div>
                </div>
                
              </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="choose-group-div2">
                <div className="sup-title mb-5">
                  <h4>Raise support Ticket</h4>
                </div>

                <div className="support-title-field ">
                  <Controller
                    name="queryTitle"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Support Title is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        placeholder="Please Enter Support Title"
                        fullWidth
                        type="text"
                        label="Support Title"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.queryTitle && errors.queryTitle.message}
                  </FormHelperText>
                </div>
                <div className="support-title-field">
                  <Controller
                    name="categoryName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Support Category is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        value={selectedCategory}
                        onChange={handleSelect}
                        options={categories}
                        getOptionLabel={(option) => option.category}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose Support Category "
                            label="Support Category"
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger m-0">
                    {errors.categoryName && errors.categoryName.message}
                  </FormHelperText>
                </div>
                <div className="support-title-field">
                  <Controller
                    name="project"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Project is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        value={selectDept}
                        onChange={handleProjSelect}
                        options={projectDatas}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose Project "
                            label="Project"
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger m-0">
                    {errors.project && errors.project.message}
                  </FormHelperText>
                </div>
                {/* <div className="support-title-field">
                  <Controller
                    name="projects"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Project is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        value={selectedCategory}
                        onChange={handleDeptSelect}
                        options={departments}
                        // getOptionLabel={(option) => option.category}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose Project "
                            label="Project"
                          />
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger m-0">
                    {errors.projects && errors.projects.message}
                  </FormHelperText>
                </div> */}
                <div className="support-title-field">
                  <Controller
                    name="queryDesc"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Support Description is required" }}
                    render={({ field }) => (
                      <TextField
                        id="outlined-multiline-static"
                        placeholder="Please Enter Support Summary"
                        fullWidth
                        multiline
                        {...field}
                        rows={6}
                        defaultValue=""
                        label="Support Description"
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.queryDesc && errors.queryDesc.message}
                  </FormHelperText>
                </div>
                <div className="support-title-field">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      defaultValue={dayjs("2022-04-17")}
                      label="Set Duration"
                    />
                  </DemoItem>
                </LocalizationProvider> */}
                  <Controller
                    name={`duration`}
                    control={control}
                    rules={{ required: 'Duration is required' }}
                    render={({ field }) => (
                      <TextField
                        placeholder="Please Enter Duration Days"
                        fullWidth
                        {...field}
                        defaultValue=""
                        label="Set Duration (Days)"
                        onKeyDown={(e) => validateNumberonly(e)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <TodayIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.duration &&
                      errors.duration.message}
                  </FormHelperText>
                </div>
                <div className="photo-attach-field">
                  <div className="form-group m-0 ">
                    <div className="d-flex align-items-center mb-3">
                      <FormLabel shrink="true" className="input_Lable">
                        <span className="thick-blue">
                          {" "}
                          Attachments (Optional){" "}
                        </span>
                      </FormLabel>
                    </div>

                    <div className="d-flex">
                      {photosFields.map((field, index) => (
                        <div>
                          <Controller
                            name={`attachments.${index}.imageURL`}
                            control={control}
                            defaultValue=""
                            rules={{
                              required: false,
                            }}
                            render={({ field }) => (
                              <>
                                <Button
                                  {...field}
                                  fullWidth
                                  className="imgupload_div"
                                  component="label"
                                  size="small"
                                  onChange={(e) =>
                                    handleFileUpload(
                                      e,
                                      "file",
                                      "fileName",
                                      "imageURL",
                                      "attachments",
                                      index
                                    )
                                  }
                                >
                                  {getValues(`attachments.${index}.imageURL`) ? (
                                    <>
                                      <div className="">
                                        <img
                                          src={getValues(
                                            `attachments.${index}.imageURL`
                                          )}
                                          className="vehicle_logo"
                                          alt={`attachments.${index}.fileName`}
                                          style={{
                                            width: "67px",
                                            height: "67px",
                                            objectFit: "contain",
                                            border: "1px solid gainsboro",
                                            borderRadius: " 5px",
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div className="disPhotos">
                                      <VisuallyHiddenInput type="file" />
                                      <img
                                        src="/images/Attachment.png"
                                        className="no_logo"
                                        alt={`attachments.${index}.fileName`}
                                        style={{
                                          width: "67px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div></div>
                                </Button>
                                {getValues(`attachments.${index}.imageURL`) && (
                                  <div className="text-center">
                                    <DeleteForeverIcon
                                      color="error"
                                      onClick={() => deletePhotos(index)}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="sup-act-btns text-center mt-3">
                  <Button className="clear-btn-query" onClick={handleClear}>
                    Clear
                  </Button>
                  <Button className="create-btn-query ml-3" type="submit">
                    Create
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default AdminSupportRaiseTicket;
