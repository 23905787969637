import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const BrandLabs = ({ name }) => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`${name}  >  Startup BrandLabs`}</span>
                  <h1 className="banner-text-gradient">Startup BrandLabs </h1>
                  <h6 className="fund-title">
                    Empowers Startups with Strategic Branding
                  </h6>
                  <div className="banner-content-actions">
                    <button className="banner-viewbtn">Get in Touch</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info5">
        <div className="layer-aadu">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
              className="tanseed-info1"
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="left-side-smartcardPage1">
                  <h4>What is Startup BrandLabs ?</h4>
                  <p className="para-all">
                    Branding is crucial in establishing a business' identity and
                    differentiating it from competitors based on more than just
                    products and services. However, many Startups lack
                    understanding of the art and science of branding. To bridge
                    this gap, Startup has created BrandLabs, an initiative
                    that enables Startup aspirants to learn about branding from
                    experts and gain valuable insights. This community forum
                    will be enhanced with membership benefits, providing
                    aspiring entrepreneurs with a mentor-linked tutorial on
                    branding strategies.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className=" img-fluid"
                  src={"/images/tamilnadu_logo_high.svg"}
                  alt="startupLogo"
                />
              </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>
                      Startup BrandLabs Empowers Startups with Strategic
                      Branding
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default BrandLabs;
