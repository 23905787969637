import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Fintech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/StartupTN-sector-fIn-tech-insurance.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
          <h3 className="banner-title-sector">Fintech & InsurTech</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4><br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5><br />
                  <h5 style={{ color: "#39364e" }}>Union Budget 2023</h5><br />
                  <p>The Union Budget 2023 announced several measures to boost the digital economy and encourage innovation in the FinTech and InsurTech sectors. Some of them are:</p>
                  <ul>
                    <li>Expanding the scope of DigiLocker services to include insurance policies, pension accounts, mutual funds and other financial documents.</li><br />
                    <li>Allocating ₹1,500 crore for a scheme to promote digital payments and incentivize FinTech start-ups.</li><br />
                    <li>Setting up a Fintech Development Fund of ₹1,000 crore under the National Bank for Agriculture and Rural Development to support FinTech solutions for agriculture and rural development.</li><br />
                    <li>Launch of a National Monetisation Pipeline to monetise public sector assets such as roads, railways, airports, power transmission lines, gas pipelines, etc. This will create opportunities for FinTech and InsurTech firms to provide innovative financing and insurance solutions.</li><br />
                  </ul>
                  <h4>Reserve Bank of India Regulations and Facilitations</h4>
                  <p>The Reserve Bank of India has taken several steps to regulate and facilitate the growth of the FinTech and InsurTech sectors. Some of them are:</p>
                  <ul>
                    <li>Guidelines for licensing of small finance banks, payments banks, peer-to-peer lending platforms, account aggregators, etc. to promote financial inclusion and innovation.</li><br />
                    <li>A regulatory sandbox framework for FinTech firms to test their products and services in a controlled environment with limited regulatory relaxations.</li><br />
                    <li>An Innovation Hub to support ideation and incubation of new capabilities that can be leveraged to deepen financial inclusion and enhance the efficiency and security of the financial system.</li><br />
                    <li>An Inter-Regulatory Technical Group on FinTech and Digital Banking to coordinate with other regulators such as SEBI, IRDAI, PFRDA, etc. on FinTech and InsurTech-related issues.</li><br />
                  </ul>
                  <h3 style={{ color: "#39364e" }}>State Government</h3><br />
                  <h4 style={{ color: "#39364e" }}>Tamil Nadu FinTech Policy 2021</h4><br />
                  <p>This policy aims to transform the State into a leading global destination for FinTech firms by 2025. It provides various incentives and benefits for FinTech and InsurTech firms, such as reimbursement of operational expenses, SGST refund, training and marketing support, funding avenues, regulatory sandbox, etc. The policy also seeks to promote regionally balanced development by providing higher incentives for firms in Tier II and III regions.</p>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4><br />
                  <ul>
                    <li><span><b>FinTech Associations:</b></span>There are various associations and networks that promote and support the FinTech industry in India, such as the Internet and Mobile Association of India (IAMAI), the FinTech Association for Consumer Empowerment (FACE), the FinTech Convergence Council (FCC), the India FinTech Forum (IFF), and the Payments Council of India (PCI).</li><br />
                    <li><span><b>FinTech Accelerators and Incubators:</b></span>There are several programmes and platforms that provide mentorship, funding and networking opportunities to FinTech Startups in India, such as PayPal Incubator, YES FINTECH Accelerator, Axis Bank Thought Factory, HDFC Bank Digital Innovation Summit and ICICI Bank Innovation Lab.</li><br />
                    <li><span><b>FinTech Events and Conferences:</b></span>There are various events and conferences that showcase and discuss the latest trends and developments in the FinTech industry in India, such as India Fintech Awards (IFTA), India Fintech Summit (IFS), India Fintech Day (IFD), Global Fintech Fest (GFF) and Fintech Storm India Summit.</li><br />
                    <li><span><b>FinTech News and Magazines: </b></span>There are many sources of information and analysis on the FinTech industry in India, such as Fintech Asia, Fintech Times, Fintech News Network, Fintech Circle and Fintech Futures.</li><br />
                    <li><span><b>InsurTech Associations: </b></span>There are some associations and networks that promote and support the InsurTech industry in India, such as the Insurtech Forum India (IFI), the Insurtech Community of India (ICI) and the Insurtech Foundation of India (IFI).</li><br />
                    <li><span><b>InsurTech Accelerators and Incubators: </b></span>There are programmes and platforms to provide mentorship, funding and networking opportunities to InsurTech Startups in India, such as the Swiss Re Insurtech Accelerator, HDFC Ergo Insurtech Accelerator, Future Generali Insurtech Incubator and ICICI Lombard Insurtech Innovation Lab.</li><br />
                    <li><span><b>InsurTech Events and Conferences:</b></span>There are events and conferences that showcase and discuss the latest trends and developments in the InsurTech industry, such as the Insurtech India Summit, Insurtech Asia Summit, Insurtech World Forum and Insurtech Connect Asia.</li><br />
                    <li><span><b>InsurTech News and Magazines:</b></span>There are several sources of information and analysis on the InsurTech industry, such as the Insurance Technology Magazine, Insurance CIO Outlook Magazine, Insurance Business Magazine and Insurance Post Magazine.</li><br />
                  </ul >
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Fintech
