import React, { useState } from 'react'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import apiService from '../../../api/apiService'
import { Container, Grid } from '@mui/material'
import cryptodecryptJS from '../../../hooks/cryptodecryptJS'

const MapSection = () => {

    useDidMountEffect(() => {
        mapGetApi()
    }, [])
    const [districtMapDetails, setDistrictMapDetails] = useState([])
    const [selectedHub, setselectedHub] = useState({})
    const mapGetApi = () => {
       
        apiService('home/district/list?hubId=0', "", 'get').then((result) => {
            if (result && result.data && result.data.districts) {
                setDistrictMapDetails(result.data.districts)
                const filtered = result.data.districts.filter((item) =>
                    item.name.toLowerCase().includes('chennai')
                );
                setselectedHub(filtered[0])
                // result.data.hubs.forEach(element => {

                //     if (element.name === 'chennai') {
                //         setselectedHub(element)
                //     }
                // });
            }
        }).catch((err) => {

        });
    }
    const [selectedDistrict, setSelectedDistrict] = useState('chennai')
    const [districtName, setDistrictName] = useState('chennai')
    const handleDistrict = (value) => {
        setSelectedDistrict(value)
        setDistrictName(value)
        const filtered = districtMapDetails.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        setselectedHub(filtered[0])
        // debugger
        // districtMapDetails.forEach(element => {

        //     if (element.name === value) {
        //         setselectedHub(element)
        //     }
        // });
    }
    const handleMouseEnter = (value) => {
        setSelectedDistrict(value)
    }
    const handleMouseLeave = () => {
        setSelectedDistrict(districtName)
    }
    const [filterMapValue, setFilterMapValue] = useState('')
    const handleFilterMap = (value) => {
        setFilterMapValue(value)
    }
    return (
        <section className="presence-section">
            <div className="presence">
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Container>
                            <div className="map-div">
                                <div className='map-div-bg'>
                                    <img src='/images/map/map-bg.png' alt='' className='img-fluid map-bg' />
                                    <img src='/images/map/Thiruvallur.png' alt='' className='img-fluid Thiruvallur' onMouseEnter={() => handleMouseEnter('Tiruvallur')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Chennai.png' alt='' className='img-fluid Chennai c-pointer'
                                        onMouseEnter={() => handleMouseEnter('chennai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Chengalpattu.png' alt='' className='img-fluid Chengalpattu' onMouseEnter={() => handleMouseEnter('Chengalpattu')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Kancheepuram.png' alt='' className='img-fluid Kancheepuram' onMouseEnter={() => handleMouseEnter('Kancheepuram')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Viluppuram.png' alt='' className='img-fluid Viluppuram' onMouseEnter={() => handleMouseEnter('Villupuram')}
                                        onMouseLeave={handleMouseLeave}/>
                              
                                    <img src='/images/map/Ranipet.png' alt='' className='img-fluid Ranipet' onMouseEnter={() => handleMouseEnter('Ranipet')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Vellore.png' alt='' className='img-fluid Vellore' onMouseEnter={() => handleMouseEnter('Vellore')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Tiruvannamalai.png' alt='' className='img-fluid Tiruvannamalai' onMouseEnter={() => handleMouseEnter('Tiruvannamalai')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Tirupathur.png' alt='' className='img-fluid Tirupathur' onMouseEnter={() => handleMouseEnter('Tirupathur')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Krishnagiri.png' alt='' className='img-fluid Krishnagiri c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Krishnagiri')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                      <img src='/images/map/Kallakurichi.png' alt='' className='img-fluid Kallakurichi'  onMouseEnter={() => handleMouseEnter('Kallakurichi')}
                                    onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Cuddalore.png' alt='' className='img-fluid Cuddalore c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Cuddalore')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Salem.png' alt='' className='img-fluid Salem c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Salem')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Erode.png' alt='' className='img-fluid Erode c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Erode')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Nilgiris.png' alt='' className='img-fluid Nilgiris' onMouseEnter={() => handleMouseEnter('The Nilgiris')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Thanjavur.png' alt='' className='img-fluid Thanjavur c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Thanjavur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Tiruchirappalli.png' alt='' className='img-fluid Tiruchirappalli c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Tiruchirappalli')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/Karur.png' alt='' className='img-fluid Karur' onMouseEnter={() => handleMouseEnter('Karur')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Tiruppur.png' alt='' className='img-fluid Tiruppur' onMouseEnter={() => handleMouseEnter('Tiruppur')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Coimbatore.png' alt='' className='img-fluid Coimbatore c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Coimbatore')}
                                        onMouseLeave={handleMouseLeave} />
                                        <img src='/images/map/Madurai.png' alt='' className='img-fluid Madurai c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Madurai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                     <img src='/images/map/Tirunelveli.png' alt='' className='img-fluid Tirunelveli c-pointer'
                                        onMouseEnter={() => handleMouseEnter('Tirunelveli')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                     <img src='/images/map/Mayiladuthurai.png' alt='' className='img-fluid Mayiladuthurai'  onMouseEnter={() => handleMouseEnter('Mayiladuthurai')}
                                        onMouseLeave={handleMouseLeave}/>

                                    <img src='/images/map/Ariyalur.png' alt='' className='img-fluid Ariyalur' onMouseEnter={() => handleMouseEnter('Ariyalur')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Perambalur.png' alt='' className='img-fluid Perambalur' onMouseEnter={() => handleMouseEnter('Perambalur')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Dharmapuri.png' alt='' className='img-fluid Dharmapuri' onMouseEnter={() => handleMouseEnter('Dharmapuri')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Tiruvarur.png' alt='' className='img-fluid Tiruvarur'  onMouseEnter={() => handleMouseEnter('Thiruvarur')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Nagapattinam.png' alt='' className='img-fluid Nagapattinam' onMouseEnter={() => handleMouseEnter('Nagapattinam')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Namakkal.png' alt='' className='img-fluid Namakkal' onMouseEnter={() => handleMouseEnter('Namakkal')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Dindigul.png' alt='' className='img-fluid Dindigul' onMouseEnter={() => handleMouseEnter('Dindigul')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Pudukkottai.png' alt='' className='img-fluid Pudukkottai' onMouseEnter={() => handleMouseEnter('Pudukkottai')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Theni.png' alt='' className='img-fluid Theni' onMouseEnter={() => handleMouseEnter('Theni')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Sivagangai.png' alt='' className='img-fluid Sivagangai' onMouseEnter={() => handleMouseEnter('Sivagangai')}
                                        onMouseLeave={handleMouseLeave} />
                                    <img src='/images/map/Ramanathapuram.png' alt='' className='img-fluid Ramanathapuram'  onMouseEnter={() => handleMouseEnter('Ramanathapuram')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Virudhunagar.png' alt='' className='img-fluid Virudhunagar' onMouseEnter={() => handleMouseEnter('Virudhunagar')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Tenkasi.png' alt='' className='img-fluid Tenkasi'  onMouseEnter={() => handleMouseEnter('Tenkasi')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Thoothukudi.png' alt='' className='img-fluid Thoothukudi'  onMouseEnter={() => handleMouseEnter('Thoothukudi')}
                                        onMouseLeave={handleMouseLeave}/>
                                    <img src='/images/map/Kanyakumari.png' alt='' className='img-fluid Kanyakumari'  onMouseEnter={() => handleMouseEnter('Kanniyakumari')}
                                        onMouseLeave={handleMouseLeave}/>
                                   
                                    {/* selected Map Design */}
                                    <img src='/images/map/selected_map/Kanyakumari.png' alt='' className='img-fluid Kanyakumari_dist c-pointer' style={{ display: selectedDistrict === 'Kanniyakumari' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Kanniyakumari')}
                                        onMouseEnter={() => handleMouseEnter('Kanniyakumari')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Thoothukudi.png' alt='' className='img-fluid Thoothukudi_dist c-pointer' style={{ display: selectedDistrict === 'Thoothukudi' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Thoothukudi')}
                                        onMouseEnter={() => handleMouseEnter('Thoothukudi')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Tenkasi.png' alt='' className='img-fluid Tenkasi_dist c-pointer' style={{ display: selectedDistrict === 'Tenkasi' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tenkasi')}
                                        onMouseEnter={() => handleMouseEnter('Tenkasi')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Virudhunagar.png' alt='' className='img-fluid Virudhunagar_dist c-pointer' style={{ display: selectedDistrict === 'Virudhunagar' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Virudhunagar')}
                                        onMouseEnter={() => handleMouseEnter('Virudhunagar')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Ramanathapuram.png' alt='' className='img-fluid Ramanathapuram_dist c-pointer' style={{ display: selectedDistrict === 'Ramanathapuram' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Ramanathapuram')}
                                        onMouseEnter={() => handleMouseEnter('Ramanathapuram')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Sivagangai.png' alt='' className='img-fluid Sivagangai_dist c-pointer' style={{ display: selectedDistrict === 'Sivagangai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Sivagangai')}
                                        onMouseEnter={() => handleMouseEnter('Sivagangai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Theni.png' alt='' className='img-fluid Theni_dist c-pointer' style={{ display: selectedDistrict === 'Theni' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Theni')}
                                        onMouseEnter={() => handleMouseEnter('Theni')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/pudukottai.png' alt='' className='img-fluid Pudukkottai_dist c-pointer' style={{ display: selectedDistrict === 'Pudukkottai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Pudukkottai')}
                                        onMouseEnter={() => handleMouseEnter('Pudukkottai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Dindigul.png' alt='' className='img-fluid Dindigul_dist c-pointer' style={{ display: selectedDistrict === 'Dindigul' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Dindigul')}
                                        onMouseEnter={() => handleMouseEnter('Dindigul')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Namakkal.png' alt='' className='img-fluid Namakkal_dist c-pointer' style={{ display: selectedDistrict === 'Namakkal' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Namakkal')}
                                        onMouseEnter={() => handleMouseEnter('Namakkal')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Nagapatinam.png' alt='' className='img-fluid Nagapattinam_dist c-pointer' style={{ display: selectedDistrict === 'Nagapattinam' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Nagapattinam')}
                                        onMouseEnter={() => handleMouseEnter('Nagapattinam')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Thiruvarur.png' alt='' className='img-fluid Tiruvarur_dist c-pointer' style={{ display: selectedDistrict === 'Thiruvarur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Thiruvarur')}
                                        onMouseEnter={() => handleMouseEnter('Thiruvarur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Dharmapuri.png' alt='' className='img-fluid Dharmapuri_dist c-pointer' style={{ display: selectedDistrict === 'Dharmapuri' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Dharmapuri')}
                                        onMouseEnter={() => handleMouseEnter('Dharmapuri')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Perambalur.png' alt='' className='img-fluid Perambalur_dist c-pointer' style={{ display: selectedDistrict === 'Perambalur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Perambalur')}
                                        onMouseEnter={() => handleMouseEnter('Perambalur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Ariyalur.png' alt='' className='img-fluid Ariyalur_dist c-pointer' style={{ display: selectedDistrict === 'Ariyalur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Ariyalur')}
                                        onMouseEnter={() => handleMouseEnter('Ariyalur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Mayiladuthurai.png' alt='' className='img-fluid Mayiladuthurai_dist c-pointer' style={{ display: selectedDistrict === 'Mayiladuthurai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Mayiladuthurai')}
                                        onMouseEnter={() => handleMouseEnter('Mayiladuthurai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Villupuram.png' alt='' className='img-fluid Viluppuram_dist c-pointer' style={{ display: selectedDistrict === 'Villupuram' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Villupuram')}
                                        onMouseEnter={() => handleMouseEnter('Villupuram')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Tiruvannamalai.png' alt='' className='img-fluid Tiruvannamalai_dist c-pointer' style={{ display: selectedDistrict === 'Tiruvannamalai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tiruvannamalai')}
                                        onMouseEnter={() => handleMouseEnter('Tiruvannamalai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Kallakurichi.png' alt='' className='img-fluid Kallakurichi_dist c-pointer' style={{ display: selectedDistrict === 'Kallakurichi' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Kallakurichi')}
                                        onMouseEnter={() => handleMouseEnter('Kallakurichi')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Nilgiris.png' alt='' className='img-fluid Nilgiris_dist c-pointer' style={{ display: selectedDistrict === 'The Nilgiris' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('The Nilgiris')}
                                        onMouseEnter={() => handleMouseEnter('The Nilgiris')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Tiruppur.png' alt='' className='img-fluid Tiruppur_dist c-pointer' style={{ display: selectedDistrict === 'Tiruppur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tiruppur')}
                                        onMouseEnter={() => handleMouseEnter('Tiruppur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Karur.png ' alt='' className='img-fluid Karur_dist c-pointer' style={{ display: selectedDistrict === 'Karur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Karur')}
                                        onMouseEnter={() => handleMouseEnter('Karur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Tirupattur.png' alt='' className='img-fluid Tirupathur_dist c-pointer' style={{ display: selectedDistrict === 'Tirupattur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tirupattur')}
                                        onMouseEnter={() => handleMouseEnter('Tirupattur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Ranipet.png' alt='' className='img-fluid Ranipet_dist c-pointer' style={{ display: selectedDistrict === 'Ranipet' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Ranipet')}
                                        onMouseEnter={() => handleMouseEnter('Ranipet')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Vellore.png' alt='' className='img-fluid Vellore_dist c-pointer' style={{ display: selectedDistrict === 'Vellore' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Vellore')}
                                        onMouseEnter={() => handleMouseEnter('Vellore')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/thiruvallur.png' alt='' className='img-fluid Thiruvallur_dist c-pointer' style={{ display: selectedDistrict === 'Tiruvallur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tiruvallur')}
                                        onMouseEnter={() => handleMouseEnter('Tiruvallur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Kanchepuram.png' alt='' className='img-fluid Kancheepuram_dist c-pointer' style={{ display: selectedDistrict === 'Kancheepuram' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Kancheepuram')}
                                        onMouseEnter={() => handleMouseEnter('Kancheepuram')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/chengalpatu.png' alt='' className='img-fluid Chengalpattu_dist c-pointer' style={{ display: selectedDistrict === 'Chengalpattu' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Chengalpattu')}
                                        onMouseEnter={() => handleMouseEnter('Chengalpattu')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Chennai.png' alt='' className='img-fluid Chennai_hub c-pointer' style={{ display: selectedDistrict === 'chennai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('chennai')}
                                        onMouseEnter={() => handleMouseEnter('chennai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/krishnagiri.png' alt='' className='img-fluid Krishnagiri c-pointer' style={{ display: selectedDistrict === 'Krishnagiri' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Krishnagiri')}
                                        onMouseEnter={() => handleMouseEnter('Krishnagiri')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Cuddalore.png' alt='' className='img-fluid Cuddalore_hub c-pointer' style={{ display: selectedDistrict === 'Cuddalore' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Cuddalore')}
                                        onMouseEnter={() => handleMouseEnter('Cuddalore')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/salem.png' alt='' className='img-fluid Salem_hub c-pointer' style={{ display: selectedDistrict === 'Salem' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Salem')}
                                        onMouseEnter={() => handleMouseEnter('Salem')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Erode.png' alt='' className='img-fluid Erode_hub c-pointer' style={{ display: selectedDistrict === 'Erode' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Erode')}
                                        onMouseEnter={() => handleMouseEnter('Erode')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/coimbatore.png' alt='' className='img-fluid Coimbatore_hub c-pointer' style={{ display: selectedDistrict === 'Coimbatore' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Coimbatore')}
                                        onMouseEnter={() => handleMouseEnter('Coimbatore')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Trichy.png ' alt='' className='img-fluid Tiruchirappalli_hub c-pointer' style={{ display: selectedDistrict === 'Tiruchirappalli' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tiruchirappalli')}
                                        onMouseEnter={() => handleMouseEnter('Tiruchirappalli')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/thanjavur.png' alt='' className='img-fluid Thanjavur_hub c-pointer' style={{ display: selectedDistrict === 'Thanjavur' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Thanjavur')}
                                        onMouseEnter={() => handleMouseEnter('Thanjavur')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Madurai.png' alt='' className='img-fluid Madurai_hub c-pointer' style={{ display: selectedDistrict === 'Madurai' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Madurai')}
                                        onMouseEnter={() => handleMouseEnter('Madurai')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <img src='/images/map/selected_map/Tirunelveli.png' alt='' className='img-fluid Tirunelveli_hub c-pointer' style={{ display: selectedDistrict === 'Tirunelveli' ? 'block' : 'none' }}
                                        onClick={() => handleDistrict('Tirunelveli')}
                                        onMouseEnter={() => handleMouseEnter('Tirunelveli')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </div>
                            </div>

                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Container>
                            <div className="map-content">
                                <h2 className="heading-line">
                                    <span className="heading-line_span"></span> <span className="chennai-text">{selectedHub.name}</span>
                                </h2>

                                <p className="map-detail-parag">{selectedHub.brief}</p>
                                {/* <p className='text-white'>
                                    Districts - <span className='district_span'>{selectedHub.districts}</span>
                                </p> */}
                                <div className="map-boxdetails mt-3">
                                    <div className="map-det-flex">
                                        <div className="map-boxdetails-div">
                                            <h4>{selectedHub.startups}</h4>
                                            <p>Startups</p>
                                        </div>
                                        <div className="map-boxdetails-div">
                                            <h4>{selectedHub.universities}
                                            </h4>
                                            <p>Educational Institutions</p>
                                        </div>
                                        <div className="map-boxdetails-div">
                                            <h4>{selectedHub.incubationCenter}</h4>
                                            <p>Incubation Centers</p>
                                        </div>
                                       
                                       
                                        <div className="map-boxdetails-div">
                                            <h4>{selectedHub.corporates}</h4>
                                            <p>Corporates</p>
                                        </div>
                                    </div>
                                    <div className="map-det-flex mt-5">
                                        <div>
                                            {/* <div className="map-boxdetailseds-div mb-3">
                                            <p>{selectedHub.address}</p>
                                        </div> */}
                                            {/* <div className="hub-details mt-2">
                                            <div className="hub_img-div">
                                                <img src={selectedHub.inchargeLogoName ? selectedHub.inchargeLogoName : '/images/user.png'} alt="hub-img" className="hub_img" />
                                            </div>
                                            <div className="hub_name_details">
                                                <p className="hubname mb-0">{selectedHub.name} Incharge</p>
                                                <p className="hubhead mb-0">{selectedHub.inchargeName}</p>
                                                {
                                                    selectedHub.inchargEmail && (
                                                        <p className="hubmail mb-0"><img src="/images/hub-mail.png" alt="hubmailid" /> {cryptodecryptJS(selectedHub.inchargEmail)}</p>
                                                    )
                                                }
                                            </div>
                                        </div> */}
                                        </div>
                                        {/* <div className='map-boxdetailseds-div-map '>

                                        <iframe
                                            src={selectedHub.mapLink}
                                            width="100"
                                            height="200"
                                            style={{ border: 0 }}
                                            allowFullScreen
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    </div> */}
                                    </div>
                                </div>
                                {/* <div className="map-filiter-home mt-5">
                                    <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('Hub')}>
                                        <div className={filterMapValue === 'Hub' ? 'seletedHub defauildRound' : 'seletedHub defauildRound'}></div>
                                        <span>Selected Districts</span>
                                    </div>
                                    {/* <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('DistrictsMap')}>
                                        <div className={filterMapValue === 'DistrictsMap' ? 'seletedDistrictsMapped defauildRound' : 'seletedDistrictsMapped defauildRound'}></div>
                                        <span>Districts mapped for Selected Hub</span>
                                    </div>
                                    <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('Hubs')}>
                                        <div className={filterMapValue === 'Hubs' ? 'seletedHubs defauildRound' : 'seletedHubs defauildRound'}></div>
                                        <span>Hubs</span>
                                    </div> */}
                                    {/* <div className="map-filter-content mr-3 " onClick={() => handleFilterMap('seletedDistricts')}>
                                        <div className={filterMapValue === 'seletedDistricts' ? 'seletedDistricts defauildRound' : 'seletedDistricts defauildRound'}></div>
                                        <span>Districts</span>
                                    </div>
                                </div> */}
                            </div>
                        </Container>
                    </Grid>
                </Grid>


            </div>
        </section>
    )
}

export default MapSection