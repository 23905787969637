import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  TextField,
  ToggleButton,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import { MdArrowDropDownCircle } from "react-icons/md";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Chip from "@mui/material/Chip";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaRegCalendar } from "react-icons/fa6";
import { IoIosList } from "react-icons/io";
import { FiGrid } from "react-icons/fi";
import { Badge } from "@mui/material";
import {
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const SmartRequestList = ({ name }) => {
  const [value, setValue] = useState("1");
  const [searchInputValue, setSearchInputValue] = useState();
  const [showForm, setShowForm] = useState(false);
  const [sectorId, setSectorId] = useState();
  const [activeButtonMain, setActiveButtonMain] = useState("secs-btn-1");
  const [selectedPeriod, setSelectedPeriod] = useState("All");

  const handleButtonClicks = (btnClass3) => {
    setActiveButtonMain(btnClass3);
  };

  useDidMountEffect(() => {
    sectorsdropdownApi();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            setSectors(result.data.sectors);
          }
        }
      })
      .catch((err) => {});
  };

  const [sectorValue, setSectorValue] = useState("");
  const [selectSect, setSelectSect] = useState(false);
  const handleSelectSector = (e, name) => {
    setSectorValue(name);
    var value = sectors.filter((option) => option.name === name);
    setSectorId(value[0].id);
    setSelectSect(true);
  };

  const handleDeleteSector = () => {
    setSectorValue("");
    setSectorId(0);
    setSelectSect(false);
  };
  const handleSelectPeriod = (value) => {
    setSelectedPeriod(value);
  };

  return (
    <div>
      <div className="profile-box2 mt-3">
        <div className="inv-dynamic-text">
          <div>
            <h5>
              Service Requests{" "}
              <Badge color="error" badgeContent={10} className="ml-4"></Badge>
            </h5>
          </div>
        </div>

        <div className="inv-startup-filters-divs mt-3">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              {/* <div className="search-bar-inv-prof">
                <div className="gray-back-search-inv-prof ">
                  <span>
                    <BiSearchAlt
                      style={{ fontSize: "18px", marginLeft: "5px" }}
                    />
                    <span className="eco-search"> Search Startups</span>
                  </span>
                </div>
                <input
                  type="text"
                  className="search-input"
                  onChange={handleSearch}
                />
              </div> */}
                 <div class="search-bar">
        <BiSearchAlt className="upd-search-eco"/> <input type="text" class="search-input" onChange={handleSearch} placeholder="Search Keyword" autoFocus={true}/>
      </div>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <div className="list-item-calender-button">
                <div className="frt-fil">
                  <div className="all-btn-bg">
                    <button
                      className={`secs-btn-1 ${
                        activeButtonMain === "secs-btn-1" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClicks("secs-btn-1")}
                    >
                      Pending
                    </button>
                    <button
                      className={`secs-btn-2 ${
                        activeButtonMain === "secs-btn-2" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClicks("secs-btn-2")}
                    >
                      Closed
                    </button>
                  </div>
                </div>
                <div className="ml-3">
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ width: 120 }}
                  >
                    <InputLabel id="dropdown-label">Duration</InputLabel>
                    <Select
                      labelId="dropdown-label"
                      label="Select Option"
                      value={selectedPeriod}
                      variant="outlined"
                      style={{ borderRadius: 10 }}
                      onChange={(e) => handleSelectPeriod(e.target.value)}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"This Week"}>This Week</MenuItem>
                      <MenuItem value={"This Month"}>This Month</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <ToggleButton className="toggle-fil ml-3">
                    {<img src="/images/filter icon.svg" alt="" />}
                  </ToggleButton>
                </div>
                <div className="filter-btns c-pointer ml-3">
                  <span>
                    <img
                      src={"/images/filtericn.png"}
                      alt="filtericn"
                      onClick={() => setShowForm(!showForm)}
                    />
                  </span>
                  <p
                    className="filter-text"
                    onClick={() => setShowForm(!showForm)}
                  >
                    Filters
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="name-filters-sm-page mt-3">
            {showForm && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    value={sectorValue}
                    options={sectors.map((option) => option.name)}
                    popupIcon={<MdArrowDropDownCircle />}
                    onChange={(e, selectedOption) =>
                      handleSelectSector(e, selectedOption)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Service"
                        placeholder="Select Service"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{ textAlign: "end" }}
                >
                  <div className="inv-chip-selection">
                    <p>
                      Showing Results : <span>32 </span>
                    </p>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
      <div className="mt-3">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item sm={12} md={4} lg={4}>
            <Box className="widget-boxs">
              <p>Total Request</p>
              <h3>32</h3>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <Box className="widget-boxs">
              <p>Leads Converted</p>
              <h3>08</h3>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={4}>
            <Box className="widget-boxs">
              <p>Total Income </p>
              <h3>$12000</h3>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div>
        <TableContainer component={Paper} className="table-container">
          <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
            <TableHead>
              <TableRow className="titles1">
                <TableCell align="center">SmartCard ID</TableCell>
                <TableCell align="center">Startup Name</TableCell>
                <TableCell align="center">City</TableCell>
                <TableCell align="center">Service Requested</TableCell>
                <TableCell align="center">Lead Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  position: "relative",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    border: "1px solid rgba(224, 224, 224, 1)",

                    pointerEvents: "none",
                    marginBottom: "-1px",
                  },
                  "& > *": {
                    padding: "10px",
                    backgroundColor: "#fff",
                    "&:last-of-type": {},
                  },
                  marginBottom: "10px",
                }}
                className="Sup-list"
              >
                <TableCell  align="center">
                  <span className="tab1-clr">STN001</span>
                </TableCell >
                <TableCell  align="center">
                  <span className="list-aca">Karam Academy</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list-place">Chennai</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list2-prof">E- Varuvaai - Lite</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list2-ran">
                    <span className="new-btn-6-clr c-pointer">New</span>
                  </span>
                </TableCell>

                <TableCell  align="center">
                  <div className="act-links">
                    <Link>
                      {" "}
                      <p>Choose Appointments |</p>
                    </Link>
                    <Link>
                      <p>Call Demo |</p>
                    </Link>
                    <Link>
                      <p> View Profile</p>
                    </Link>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  position: "relative",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    border: "1px solid rgba(224, 224, 224, 1)",

                    pointerEvents: "none",
                    marginBottom: "-1px",
                  },
                  "& > *": {
                    padding: "10px",
                    backgroundColor: "#fff",
                    "&:last-of-type": {},
                  },
                  marginBottom: "10px",
                }}
                className="Sup-list-k"
              >
                <TableCell  align="center">
                  <span className="tab1-clr">STN001</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list-aca">Karam Academy</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list-place">Chennai</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list2-prof">E- Varuvaai - Lite</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list2-ran">
                    <span className="new-btn-7-clr c-pointer">Demo Call</span>
                  </span>
                </TableCell >

                <TableCell  align="center">
                  <div className="act-links">
                    <Link>
                      {" "}
                      <p>Choose Appointments |</p>
                    </Link>
                    <Link>
                      <p>Call Demo |</p>
                    </Link>
                    <Link>
                      <p> View Profile</p>
                    </Link>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  position: "relative",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    border: "1px solid rgba(224, 224, 224, 1)",

                    pointerEvents: "none",
                    marginBottom: "-1px",
                  },
                  "& > *": {
                    padding: "10px",
                    backgroundColor: "#fff",
                    "&:last-of-type": {},
                  },
                  marginBottom: "10px",
                }}
                className="Sup-list-k"
              >
                <TableCell  align="center">
                  <span className="tab1-clr">STN001</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list-aca">Karam Academy</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list-place">Chennai</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list2-prof">E- Varuvaai - Lite</span>
                </TableCell>
                <TableCell  align="center">
                  <span className="list2-ran">
                    <span className="new-btn-8-clr c-pointer">Rejected</span>
                  </span>
                </TableCell>

                <TableCell  align="center">
                  <div className="act-links">
                    <Link>
                      {" "}
                      <p>Choose Appointments |</p>
                    </Link>
                    <Link>
                      <p>Call Demo |</p>
                    </Link>
                    <Link>
                      <p> View Profile</p>
                    </Link>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SmartRequestList;
