import React, { useContext } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
} from "@mui/material";
import DataContext from "../../../../context/DataContext";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MentorTeam = ({ name,url }) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const navigate = useNavigate();

  const mentorItems = [
    {
      mentorImage: "/images/subu.png",
      mentorName: "Balasubramanian AJ",
      mentorDesignation:'Director at Aigilx Health Technologies Private Ltd'
    },
    {
      mentorImage: "/images/mentor2.png",
      mentorName: "K Suresh Kumar",
      mentorDesignation:'Executive Director at PSG- Step'
    },
    {
      mentorImage: "/images/mentor3.png",
      mentorName: "Jitendra Jain",
      mentorDesignation:'EDII Periyakulam Horti Business Incubation Forum'
    },
    {
      mentorImage: "/images/mentor4.png",
      mentorName: "Priya Raghavan",
      mentorDesignation:'Senior Director & Product managemnet at Yubi'
    },
    {
      mentorImage: "/images/mentor5.png",
      mentorName: "Chandran Krishnan",
      mentorDesignation:'CEO & Managing Director at Campus Angels Network Private Limited'
    },
  ];

  const handlebacked = () => {
    navigate(`/initiative/${url}/mentortn/home`)
  }

  return (
    <div className="smartCard-page">
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}  >  Mentors`}</span>
                <h1 className="banner-text-gradient">MentorTN</h1>
                <h6>Right Mentor for the right founder!</h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Join Us</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="join-us-now-sec1">
        <Container>
        <div>
            <Button className="about-backed" onClick={handlebacked}>
              <FaArrowLeft />
              Back
            </Button>
          </div>
          <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="service-partners-title mt-5">
                <h4>Discover Our Mentors</h4>
              </div>
            </Grid>
          </Grid>
          <div className="service-crd mt-4">
            <Container>
              <Grid container spacing={3}>
                {mentorItems.map((item, i) => (
                  <Grid item xs={12} sm={6} md={3} key={i} className="discovery">
                    <Card className="mentors-listeds-crds1">
                      <div className="service-card-back-layers1">
                        <div className="layer-crd-back-img1">
                          <div className="service-card-back-profss1">
                            <img
                              src={item.mentorImage}
                              alt="crd-img1"
                              className="mentor-carded-back-image1"
                            />
                          </div>
                        </div>
                      </div>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          padding: "24px 0px 0px 0px",
                        }}
                      >
                        <p className="ser-crd-title-text">{item.mentorName}</p>
                        <span className="mentor-title-design">{item.mentorDesignation}</span>
                        <div className="mt-2">
                          <Button className="ses-now">Connect</Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MentorTeam;
