import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";


const Industry = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/startuptn-sector-industry4.0.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
        <h3 className="banner-title-sector">Industry 4.0 & Advanced Manufacturing</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4><br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5><br />
                  <p><span><b>Make in India:</b></span>  This initiative is intended to facilitate investment, foster innovation, build best in class infrastructure and make India a hub for manufacturing, design and innovation.</p><br />
                  <p><span><b>Industrial Corridor Development Programme: </b></span>Greenfield industrial regions/aeas/nodes are being developed with sustainable infrastructure under this programme.</p><br />
                  <p><span><b>Smart Advanced Manufacturing and Rapid Transformation Hub (SAMARTH Udyog Bharat 4.0)</b></span><br />
                    is an initiative of the Union Ministry of Heavy Industry and Public Enterprises for enhancement of competitiveness in the Indian capital goods sector. Five centres with a unique identity are functioning under the scheme to spread awareness of Advanced Manufacturing among Indian industries.
                  </p><br />
                  <p><span><b>They include the Centre for Industry 4.0 Lab, Pune;</b></span>Centre of Excellence in Advanced Manufacturing, IIT-Kharagpur.</p><br />

                  <h5 style={{ color: "#39364e" }}>State Government</h5><br />
                  <ul>
                    <li>Subsidy for Fund Raising from SME Exchange</li><br />
                    <li>Support for development of mini tool room</li><br />
                    <li>Financial assistance for participation in international trade fairs – reimbursement provided for 50% of the rent paid towards stall or space charges, subject to a maximum of Rs 15 lakh per international trade fair/ exhibition.</li><br />
                    <li>Subsidy provided for 75% of the cost of filing the application for patent registration, subject to a maximum of Rs 3 lakh.</li><br />

                    <li><b>Incentives for setting up new cluster units on the outskirts of towns and cities:</b> Grant of up to 50% of the total development cost, subject to a maximum of Rs 15 crore is provided for obtaining infrastructure facilities like roads, drainage, culverts, stormwater drains, street lights, water supply, electricity, conference halls, training halls and other common facility centres.</li><br />
                  </ul >
                  <h5 style={{ color: "#39364e" }}>Policies</h5><br />
                  <ul>
                    <li>Tamil Nadu Industrial Policy 2021</li><br />
                    <li>Tamil Nadu Aerospace and Defence Industrial Policy 2022</li><br />
                    <li>Tamil Nadu R&D Policy</li><br />
                    <li>Industries, Investment Promotion and Commerce Department – Policy Note 2023-24</li><br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4> <br />
                  <ul>
                    <li>FabLabs (StartupTN)</li><br />
                    <li>Centre of Excellence (TIDCO)
                      <ul style={{ lineHeight: '30px' }}>
                        <li>TANSAM (Tamil Nadu Smart and Advanced Manufacturing)</li><br />
                        <li>TANCAM (Tamil Nadu Centre of Excellence for Advanced Manufacturing)</li><br />
                        <li>TAMCOE (Tamil Nadu Advanced Manufacturing Centre of Excellence )</li><br />
                      </ul>
                    </li><br />
                    <li>Guidance Tamil Nadu (AM HUB)</li><br />
                    <li>Tamil Nadu Technology Hub (iTNT)</li><br />
                    <li>SIPCOT (Industrial Innovation Centre)</li><br />
                  </ul >
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Industry
