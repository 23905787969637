import React, { useContext, useState } from "react";
import { Autocomplete, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";

const MoreinfoAspirants = () => {
  const { navigator, setPageLoading, handleNextClick, isWhitespace, fastUrlPath } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, formState: { errors }, } = useForm({ values: formData });

  useDidMountEffect(() => {
    dropdownApi();
    sectorsdropdownApi();
  }, []);

  useDidMountEffect(() => {
    handleNextClick(98);
    getApi();
  }, []);



  const [support, setSupport] = useState([]);

  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"support"}`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.dropDownValues) {
          var dropDown = result.data.dropDownValues;
          setSupport(dropDown.values);
        }
      })
      .catch((err) => { });
  };

  const [sectors, setSectors] = useState([]);

  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.sectors) {
          var values = result.data.sectors;
          setSectors(values);
        }
      })
      .catch((err) => { });
  };

  const handleSelectSector = (value) => {
    setValue("sector", value);
    clearErrors("sector")

  };

  const getApi = () => {
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          setFormData(data);

        }
      })
      .catch((err) => { });
  };

  const onSubmit = (data) => {
    setPageLoading(true)
    console.log("Form Data:", data);
    const sector = sectors.filter(val => val.name === data.sector);
    data.sectorId = sector[0].id.toString()
    // data.profileUpdated = true
    // data.receiveStartupSmartcard = data.receiveStartupSmartcard === 'true' ? true : false
    // data.supportToIncorporateStartup = data.supportToIncorporateStartup === 'true' ? true : false
    // data.startupBasics = data.startupBasics === 'true' ? true : false
    apiService("userprofile/save", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result.data.responseStatus === "Success") {
          navigator('/aspirants/quick-brief')          
        }
      })
      .catch((err) => { });
  };
  const onError = () => {
    notifyService("danger", "Submit Failed", "Please check the mandatory fields");
  };



  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">More Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">5</span>/5
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <p>
                      What is the problem you are trying or aspiring to solve?
                    </p>
                    <div>
                      <div className="my-3">
                        <Controller
                          name="problemResolve"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: 'Description is required', validate: {
                              noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-multiline-static"
                              label="Enter your Description *"
                              fullWidth
                              multiline
                              {...field}
                              rows={4}
                              defaultValue=""
                              error={Boolean(
                                errors.problemResolve && errors.problemResolve
                              )}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.problemResolve &&
                            errors.problemResolve.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="sector"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          disableClearable
                          {...field}
                          options={sectors.map((option) => option.name)}
                          onChange={(event, newValue) => handleSelectSector(newValue)}
                          renderInput={(params) => (
                            <TextField
                              value={field.value}
                              {...params}
                              label="Sector *"
                              placeholder="Select Sector"
                              error={!!error}
                              helperText={error ? "Sector is required" : ""}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.sector && errors.sector.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <p>What support are you seeking from StartupTN?</p>
                    <Controller
                      name="supportSeekingStartup"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          disableClearable
                          options={support}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder="Select Option"
                              error={!!error}
                              helperText={error ? "Select is required" : ""}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.supportSeekingStartup &&
                        errors.supportSeekingStartup.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <p>Do you want to learn the basics of starting a startup?</p>
                    {/* <Controller
                      name="startupBasics"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl>
                          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label3"
                            name="row-radio-buttons-group"
                            {...field}
                            value={field.value === "true" ? "true" : "false"}
                          >
                            <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"false"} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                    /> */}
                    <Controller
                      name="startupBasics"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl component="fieldset" className="d-flex" >
                          <RadioGroup {...field} row>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.startupBasics && errors.startupBasics.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <p>Do you want our support to incorporate your startup?</p>
                    {/* <Controller
                      name="supportToIncorporateStartup"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label2"
                            name="row-radio-buttons-group"
                            {...field}
                            value={field.value === "true" ? "true" : "false"}
                          >
                            <FormControlLabel value={"true"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"false"} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                    /> */}
                    <Controller
                      name="supportToIncorporateStartup"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl component="fieldset" className="d-flex" >
                          <RadioGroup {...field} row>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.supportToIncorporateStartup &&
                        errors.supportToIncorporateStartup.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <p>Have you received a startupTN smart card?</p>
                    {/* <Controller
                      name="receiveStartupSmartcard"
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label1"
                            name="row-radio-buttons-group"
                            {...field}
                            value={field.value === "true" ? "true" : "false"}
                          >
                            <FormControlLabel  value={"true"} control={<Radio />} label="Yes"/>
                            <FormControlLabel value={"false"}control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                   
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.receiveStartupSmartcard &&
                        errors.receiveStartupSmartcard.message}
                    </FormHelperText> */}
                    <Controller
                      name="receiveStartupSmartcard"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl component="fieldset" className="d-flex" >
                          <RadioGroup {...field} row>
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.receiveStartupSmartcard && errors.receiveStartupSmartcard.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
                    <button className="next-btn" type="submit">
                      SAVE
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MoreinfoAspirants;
