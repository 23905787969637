import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Signuplayout from '../../auth-module/signup-details-page/Signuplayout'
import BasicinfoServiceprovider from './organization-info-page/BasicinfoServiceprovider'
import AddressServiceProvider from './organization-info-page/AddressServiceProvider'
import SociallinksServiceProvider from './organization-info-page/SociallinksServiceProvider'
import MoreinfoServiceProvider from './organization-info-page/MoreinfoServiceProvider'
import DocumentsServiceProvider from './organization-info-page/DocumentsServiceProvider'
import FounderddetailsServiceProvider from './team-info-page/FounderddetailsServiceProvider'
import TeamsizeServiceProvider from './team-info-page/TeamsizeServiceProvider'
import Foundedetails from '../startup-profile/team-info-page/Foundedetails'

const ServiceproviderRoutes = () => {
    return (
        <main>
            <Routes>
                <Route path='/service-provider' element={<Signuplayout />}>
                    <Route path="/service-provider" element={<Navigate replace to="/service-provider/basicinfo" />} />
                    <Route path='/service-provider/basicinfo' element={<BasicinfoServiceprovider />} />
                    <Route path='/service-provider/address' element={<AddressServiceProvider />} />
                    <Route path='/service-provider/social-links' element={<SociallinksServiceProvider />} />
                    <Route path='/service-provider/moreinfo' element={<MoreinfoServiceProvider />} />
                    <Route path='/service-provider/documents' element={<DocumentsServiceProvider/>} />
                    <Route path='/service-provider/founder' element={<Foundedetails url={'startupsserviceproviders'} navigate={'/service-provider/profile'}/>} />
                    <Route path='/service-provider/teamsize' element={<TeamsizeServiceProvider/>} />
                </Route>
            </Routes>
        </main>
    )
}

export default ServiceproviderRoutes