import { Button, FormHelperText, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa6";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import Viewimage from "../../../api/Viewimage";
import CloudDoneIcon from '@mui/icons-material/CloudDone';

const NewslettersAdd = () => {
  const { id } = useParams();
  let newsletterId;
  if (id !== undefined) {
    newsletterId = atob(id);
  }

  const { navigator, setPageLoading, VisuallyHiddenInput } =
    useContext(DataContext);
  const [formData, setFormData] = useState({});
  const [publishBtn, setPublishBtn] = useState(false)
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  let departmentId = localStorage.getItem("departmentId");
  useDidMountEffect(() => {
    if (newsletterId) {
      getApi(newsletterId);
    }
  }, []);
  const [publishId, setPublishId] = useState(0)
  const getApi = (val) => {
    setPageLoading(true);
    apiService(`newsletter/get?id=${val}`, "", "get")
      .then((result) => {
        setPageLoading(false);
        if (result?.data) {
          var data = result.data;
          setFormData(data);
          if (data.publish) {
            setPublishBtn(data.publish)
          }
          if (data.id) {
            setPublishId(data.id)
          }
        }
      })
      .catch((err) => {
        console.log(err);

      });
  };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileUrl, format) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            (format === "file" && img_crt_type === "pdf") ||
            (format === "image" &&
              (img_crt_type === "jpeg" ||
                img_crt_type === "jpg" ||
                img_crt_type === "png"))
          ) {
            setValue(filekey, "");
            setValue(fileName, "");
            setValue(fileUrl, "");
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1)
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileUrl, logourl1);
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileUrl);
              //
              // clearErrors('dpiitCertificateName')
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const handleClickOpen = (value, name) => {
    setViewImage(value);
    setViewImageName(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data, isPublish = false) => {
    setPageLoading(true);
    console.log(data);
    data.departmentId = departmentId;
    data.publish = publishBtn
    apiService("newsletter/save", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data?.id) {
          setPublishId(result.data.id)
        }
        if (result?.data?.responseStatus === "Success") {
          // backBtn();
          if (isPublish === true) {
            publishEvent();
          }
          getApi(result.data.id)
        }
      })
      .catch((err) => {
        console.log(err);

      });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };
  const handlePublish = (e) => {
    e.preventDefault();
    const data = getValues();
    debugger
    onSubmit(data, true);
  };

  const publishEvent = () => {
    const req = { id: publishId, type: "newsletter" };
    setPageLoading(true);
    apiService("publish", req, "post")
      .then((res) => {
        setPageLoading(false);
        if (res?.data?.responseStatus === "Success") {
          handlDiscard();
        }
      })
      .catch((err) => {
        setPageLoading(false);
        console.error(err);
      });
  };
  const handlDiscard = () => {
    reset();
    navigator("admin/newsletter/list");
  };
  const backBtn = () => {
    reset();
    navigator("admin/newsletter/list");
  };

  return (
    <main className="event_add_section">
      <div className="event_add_header mb-4">
        <div className="d-flex w-100 align-items-center">
          <div>
            <p className="back-btns mb-0" onClick={backBtn}>
              <FaArrowLeft />
              <span style={{ marginLeft: "5px" }}>Back</span>
            </p>
          </div>
          {newsletterId ? (
            <div className="m-auto">
              <h4 className="font-color-black">{formData.title}</h4>
            </div>
          ) : (
            <div className="m-auto">
              <h4 className="font-color-black"> Create Newsletter</h4>
            </div>
          )}
        </div>
      </div>
      <p className="font-color-black">Newsletter Details</p>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="event_add_details_div mb-4">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="input_background mb-4">
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Newsletter Title is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Newsletter Title *"
                        placeholder="Please Enter Newsletter Title"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.title && errors.title.message}
                  </FormHelperText>
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <div className="input_background">
                  {getValues("imageName") ? (
                    <>
                      <label>
                        Upload Newsletter Banner
                        <span className="text-danger ml-1">*</span>
                      </label>
                      <div>
                        <div className="row">
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-3 text-center"
                          >
                            <Controller
                              name="imageName"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "Newsletter Banner is required",
                              }}
                              render={({ field }) => (
                                <Button
                                  component="label"
                                  variant="contained"
                                  color="success"
                                  {...field}
                                  sx={{ width: '90%', fontSize: '11px', textTransform: 'none', padding: '8px 0px' }}
                                  onChange={(e) =>
                                    handleFileUpload(
                                      e,
                                      "image",
                                      "imageName",
                                      "imageUrl",
                                      "image"
                                    )
                                  }
                                  startIcon={<CloudDoneIcon />}
                                  href="#file-upload"
                                >
                                  Uploaded ( Click to Change)
                                  <VisuallyHiddenInput type="file" />
                                </Button>
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <Button
                              className="c-pointer ml-2"
                              size="medium"
                              sx={{ width: "90%" }}
                              variant="outlined"
                              onClick={() =>
                                handleClickOpen(
                                  getValues("image"),
                                  getValues("imageUrl")
                                )
                              }
                            >
                              Preview
                            </Button>
                          </Grid>
                        </div>
                        <div>
                          <span className="font-sm d-flex  mt-1 ">
                            Maximum 5 mb allowed doc (Accepted Format: jpeg,
                            jpg, png)
                            <span className="text-danger">*</span>
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <Controller
                        name="imageName"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Newsletter Banner is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Upload Newsletter Banner *"
                            placeholder="Upload Image "
                            fullWidth
                            type="text"
                            {...field}
                            disabled
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.imageName && errors.imageName.message}
                      </FormHelperText>
                      <div className="d-flex align-items-center w-100 my-3">
                        <p className="font-sm">
                          Recommended Size 265 X 350 (px)
                          <br />
                          Max size upto 5mb <br />
                          Accepted Format: jpeg, jpg, png
                        </p>
                        <label
                          className="ml-auto lbl_btn"
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "image",
                              "imageName",
                              "imageUrl",
                              "image"
                            )
                          }
                          href="#file-upload"
                        >
                          <img
                            src="/images/admin/upload.png"
                            className="mr-3"
                            alt=""
                          />
                          Upload
                          <VisuallyHiddenInput type="file" />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <div className="input_background">
                  {getValues("fileName") ? (
                    <>
                      <label>
                        Upload Newsletter Document
                        <span className="text-danger ml-1">*</span>
                      </label>
                      <div>
                        <div className="row">
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-3 text-center"
                          >
                            <Controller
                              name="fileName"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "Newsletter Document is required",
                              }}
                              render={({ field }) => (
                                <Button
                                  component="label"
                                  variant="contained"
                                  color="success"
                                  {...field}
                                  sx={{ width: '90%', fontSize: '11px', textTransform: 'none', padding: '8px 0px' }}
                                  fullWidth
                                  onChange={(e) =>
                                    handleFileUpload(
                                      e,
                                      "file",
                                      "fileName",
                                      "fileUrl",
                                      "file"
                                    )
                                  }
                                  startIcon={<CloudDoneIcon />}
                                  href="#file-upload"
                                >
                                  Uploaded ( Click to Change)
                                  <VisuallyHiddenInput type="file" />
                                </Button>
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            className="mb-3"
                          >
                            <Button
                              className="c-pointer ml-2"
                              size="medium"
                              sx={{ width: "90%" }}
                              variant="outlined"
                              onClick={() =>
                                handleClickOpen(
                                  getValues("file"),
                                  getValues("fileUrl")
                                )
                              }
                            >
                              Preview
                            </Button>
                          </Grid>
                        </div>
                        <div>
                          <span className="font-sm d-flex  mt-1 ">
                            Maximum 5 mb allowed doc (Accepted Format: pdf)
                            <span className="text-danger">*</span>
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="form-group mt-2">
                      <Controller
                        name="fileName"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Newsletter Document is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Upload Newsletter Document *"
                            placeholder="Upload Newsletter Document "
                            fullWidth
                            type="text"
                            {...field}
                            disabled
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.fileName && errors.fileName.message}
                      </FormHelperText>
                      <div className="d-flex align-items-center w-100 my-3">
                        <p className="font-sm">
                          Maximum 5 mb allowed doc (Accepted Format: pdf)
                        </p>
                        <label
                          className="ml-auto lbl_btn1"
                          onChange={(e) => {
                            handleFileUpload(
                              e,
                              "file",
                              "fileName",
                              "fileUrl",
                              "file"
                            );
                          }}
                          href="#file-upload"
                        >
                          <img
                            src="/images/admin/upload.png"
                            className="mr-3"
                            alt=""
                          />
                          Upload
                          <VisuallyHiddenInput type="file" />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="text-center">
            <Button className="discard-btn" onClick={() => handlDiscard()}>
              Discard
            </Button>
            <Button type="submit" className="publish-button">
              {publishBtn === false ? "Save as Draft" : "Save & Publish"}
            </Button>
            {(publishBtn === false && publishId !== 0) && (<Button className="publish-button-draft" onClick={(e) => handlePublish(e)}>
              Publish
            </Button>)}
          </div>
        </form>
      </div>
      <Viewimage
        viewImage={viewImage}
        viewImageName={viewImageName}
        open={open}
        handleClose={handleClose}
      />
    </main>
  );
};

export default NewslettersAdd;
