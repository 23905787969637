import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";

const Blueeconomy = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/StartupTNSector-BlueEconomy.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
          <h3 className="banner-title-sector">Blue Economy</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <ul>
                    <li><b>United Nations Ocean Decade:</b> United Nations Ocean Decade, also known as the “Ocean Decade,” is an initiative launched by the United Nations to address the critical challenges facing the world’s oceans. The programme was launched in June 2021 and runs till 2030. The primary goal of Ocean Decade is to galvanize international efforts to protect, conserve and sustainably manage our oceans and marine resources.</li>
                    <li><b>National Policy on Marine Fisheries (NPMF):</b> The Government of India has formulated NPMF to promote responsible and sustainable fisheries management. It includes measures to prevent overfishing, support small-scale fishermen and encourage the adoption of modern fishing techniques.</li>
                    <li><b>Pradhan Mantri Matsya Sampada Yojana (PMMSY):</b> PMMSY is a flagship scheme aimed at modernising fisheries, increasing fish production and doubling farmers’ incomes. It includes initiatives for infrastructure development, capacity building and value chain enhancement in the fisheries and aquaculture sectors.</li>
                    <li><b>Sagarmala Programme:</b> This programme focuses on port-led development and aims to harness India’s 7,500-kilometre-long coastline for economic growth. It includes initiatives for improving port infrastructure, connectivity and promoting coastal shipping.</li>
                    <li><b>National Mission on Sustainable Agriculture (NMSA):</b> NMSA includes measures to promote sustainable agriculture practices in coastal areas, reduce the use of chemical inputs and enhance the resilience of coastal communities to climate change.</li>
                    <li><b>Integrated Coastal Zone Management (ICZM):</b> Tamil Nadu has an ICZM plan in place to ensure the sustainable development of its coastal areas. It focuses on conserving biodiversity, managing coastal erosion and promoting sustainable tourism.</li>
                    <li><b>Fisheries Department Initiatives:</b> The State Government has various schemes and initiatives to support the fisheries sector that include financial assistance for fishermen, fishery development programmes and the promotion of aquaculture.</li>
                    <li><b>Tamil Nadu Maritime Board:</b> The State has established the Tamil Nadu Maritime Board to oversee the development of ports, shipping and maritime activities. It aims to enhance the State’s maritime infrastructure and connectivity.</li>
                    <li><b>Marine and Coastal Education:</b>Tamil Nadu has several educational institutions and research centres, such as the Tamil Nadu Dr. J. Jayalalithaa Fisheries University and the National Centre for Sustainable Coastal Management, dedicated to marine and coastal research and education.</li>
                    <li><b>Tourism Promotion:</b> The State promotes marine tourism, including beach resorts and water-based recreational activities, to boost tourism and generate employment in coastal areas</li>
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <h5 style={{ color: "#39364e" }}>Policies</h5>
                  <h5 style={{ color: "#39364e" }}>INDIA’S BLUE ECONOMY POLICY</h5>
                  <Link to={'https://incois.gov.in/documents/Blue_Economy_policy.pdf'} target="_blank">– https://incois.gov.in/documents/Blue_Economy_policy.pdf</Link>

                  <h5 style={{ color: "#39364e" }}>BONDS TO FINANCE THE SUSTAINABLE BLUE ECONOMY</h5>
                  <Link to={'https://media.licdn.com/dms/document/media/D4D1FAQFywrXF0vGkrQ/feedshare-document-pdf-analyzed/0/1694317278417?e=1695859200&v=beta&t=kLMMzr5yU8sLCzcGVW3Zg2ELIcTkgb94uZq6yUvhbw0'} target="_blank">– https://media.licdn.com/dms/document/media/D4D1FAQFywrXF0vGkrQ/feedshare-document-pdf-analyzed/0/1694317278417?e=1695859200&v=beta&t=kLMMzr5yU8sLCzcGVW3Zg2ELIcTkgb94uZq6yUvhbw0</Link>
                  <h5 style={{ color: "#39364e" }}>KEY RESOURCES AND ASSETS REQUIRED IN SECTOR</h5>
                  <p>To support the growth of the Blue Economy sector in Tamil Nadu, several key resources and assets are required. They include</p>
                  <ul>
                    <li><b>Skilled Manpower:</b> Marine biologists, oceanographers and environmental scientists for research and conservation efforts. Fishermen and aquaculture workers for sustainable fisheries and aquaculture practices. Shipbuilders, engineers and sailors for maritime transport and logistics. Tourism professionals for coastal tourism development.</li>
                    <li><b>Materials:</b> Fishing gear and equipment; aquaculture infrastructure such as ponds, cages and hatcheries; maintenance materials for ships and boats and construction materials for coastal infrastructure development.</li>
                    <li><b>Capital:</b> Investment in research and development for innovative ocean technologies and sustainable practices. Financing for the expansion of existing businesses and the establishment of new ones. Access to grants, subsidies and incentives for Blue Economy projects. Infrastructure development funding for ports, harbours and coastal tourism facilities.</li>
                    <li><b>Infrastructure:</b> Coastal infrastructure like jetties, fish landing centres and cold storage facilities for fisheries. Research and development centres, marine laboratories and aquaculture facilities. Tourism infrastructure, including resorts, hotels and recreational facilities.</li>
                    <li><b>Technology and Innovation:</b>Access to advanced technologies for sustainable fishing practices, including GPS, fish finders and vessel monitoring systems. Research and development centres for ocean-related innovation. Renewable energy infrastructure like offshore wind farms and wave energy converters.</li>
                    <li><b>Market Access:</b> Promotion of local seafood brands and products to boost exports. Access to domestic and international markets for fish and seafood products. Marketing and distribution networks for Blue Economy products.</li>
                    <li><b>Education and Training:</b> Programmes for fishermen and aquaculture workers on sustainable practices. Educational institutions offering courses related to marine and coastal studies. Workforce development programmes to create a skilled Blue Economy workforce.</li>
                    <li><b>Environmental Conservation:</b> Efforts for the conservation and protection of critical marine ecosystems, such as coral reefs and mangroves. Pollution control measures to maintain water quality and safeguard marine life. Sustainable fisheries management practices to prevent overfishing. Collaboration with neighbouring countries and international organisations for joint research, conservation efforts and disaster management in the Indian Ocean region.</li>

                  </ul>
                  <h5 style={{ color: "#39364e" }}>Learning Resources</h5>
                  <h5 style={{ color: "#39364e" }}>World Ocean Initiative Newsletter:</h5>
                  <Link to={'https://impact.economist.com/ocean'} target="_blank">https://impact.economist.com/ocean</Link>
                  <p>Produced by The Economist Group, this newsletter covers a wide range of topics related to the Blue Economy, including sustainable fishing, marine technology and ocean governance. It provides in-depth analysis and reports on key ocean-related issues.</p>
                  <h5 style={{ color: "#39364e" }}>UN Decade of Ocean Science for Sustainable Development Publications</h5>
                  <Link to={'https://oceandecade.org/decade-publications/ocean-decade-publications/'} target="_blank">https://oceandecade.org/decade-publications/ocean-decade-publications/</Link>
                  <p>Ocean Decade focuses on updates and initiatives related to the United Nations Decade of Ocean Science for Sustainable Development (2021-2030). It highlights scientific research, events and efforts to address ocean challenges.</p>

                  <h5 style={{ color: "#39364e" }}>Blue Economy CRC Newsletter</h5>
                  <Link to={'https://www.marinetechnologynews.com/magazine'} target="_blank">https://www.marinetechnologynews.com/magazine</Link>
                  <p>The Blue Economy Cooperative Research Centre (CRC) is an Australian organisation focused on research and innovation for sustainable marine industries. The newsletter provides updates on research projects, events and industry developments.</p>

                  <h5 style={{ color: "#39364e" }}>Marine Technology News</h5>
                  <Link to={'https://www.marinetechnologynews.com/magazine'} target="_blank">https://www.marinetechnologynews.com/magazine</Link>
                  <p>This publication focuses on technological advancements in the maritime and marine industries. It covers topics like underwater robotics, autonomous vessels and ocean exploration tools.</p>

                  <h5 style={{ color: "#39364e" }}>The Maritime Executive</h5>
                  <Link to={'https://www.maritime-executive.com/'} target="_blank">https://www.maritime-executive.com/</Link>
                  <p>While not exclusively focused on the Blue Economy, this publication provides insights into the maritime and shipping industries, which are integral components of the sector.</p>

                  <h5 style={{ color: "#39364e" }}>Ocean Wise Conservation Association Blog</h5>
                  <Link to={'https://ocean.org/learn-explore/'} target="_blank">https://ocean.org/learn-explore/</Link>
                  <p>Ocean Wise is a Canadian non-profit organisation dedicated to marine conservation. Their blog covers a wide range of topics related to the Blue Economy, including sustainable seafood, ocean conservation and marine education.</p>

                  <h5 style={{ color: "#39364e" }}>International Ocean Governance Forum Newsletter</h5>
                  <Link to={'https://www.marineboard.eu/international-ocean-governance-forum'} target="_blank">https://www.marineboard.eu/international-ocean-governance-forum</Link>
                  <p>This forum focuses on discussions and knowledge-sharing related to international ocean governance, policy and law. It provides updates on events, publications and initiatives in this field.</p>

                  <h5 style={{ color: "#39364e" }}>MarineBio Conservation Society</h5>
                  <Link to={'https://www.marinebio.org/news/'} target="_blank">https://www.marinebio.org/news/</Link>
                  <p>MarineBio offers a wealth of knowledge resources, including articles, educational materials and conservation resources, related to marine life and ecosystems.</p>

                  <h5 style={{ color: "#39364e" }}>Indian Ocean Rim Association (IORA)</h5>
                  <Link to={'https://www.iora.int/en/events-media-news/news-updates'} target="_blank">https://www.iora.int/en/events-media-news/news-updates</Link>
                  <p>IORA is an inter-governmental organisation that promotes regional cooperation in the Indian Ocean region. The newsletter provides information on Blue Economy initiatives and collaborations among member countries.</p>

                  <h5 style={{ color: "#39364e" }}>Insider</h5>
                  <Link to={'https://www.businessinsider.com/category/the-blue-economy?IR=T'} target="_blank">https://www.businessinsider.com/category/the-blue-economy?IR=T</Link>
                  <p>Insider’s Blue Economy page has a mix of cutting edge business and technology happenings, entrepreneurial initiatives, creative exchanges, lifestyle concepts and leadership insights.</p>

                  <h5 style={{ color: "#39364e" }}>Startups</h5>
                  <p>Some of the top Tamil Nadu-based Blue Economy Startups:</p>
                  <ul>
                    <li>Aqua Connect (Aquaculture)</li>
                    <li>Vikra Ocean Tech (Aqua Monitoring Robots)</li>
                    <li>Xera Robotics (Ocean Monitoring)</li>
                    <li>Lemurian Ventures Private Limited.</li>
                    <li>Planys Technologies Pvt. Ltd (Underwater Visual Inspection Services)</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>R&D Organisations</h5>
                  <ul>
                    <li>National Institute of Ocean Technology, Chennai</li>
                    <li>ICAR – Central Institute of Brackishwater Aquaculture, Chennai</li>
                    <li>Central Marine Fisheries Research Institute, Thoothukudi.</li>
                    <li>Bay of Bengal Programme – an InterGovernmental Organisation, Chennai</li>
                    <li>National Centre for Sustainable Coastal Management, Chennai</li>
                    <li>Centre for Ocean Research, Sathyabama Institute of Science and Technology, Chennai</li>
                    <li>Indian Maritime University, Chennai</li>
                    <li>AMET University, Chennai</li>
                    <li>Faculty of Marine Sciences, Annamalai University, Chidambaram</li>
                    <li>Department of Marine Science, Bharathidasan University, Tiruchi.</li>
                    <li>Centre for Marine Science and Technology (CMST), Rajakkamangalam, Kanniyakumari.</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Infrastructure</h5>
                  <ul>
                    <li>Sathyabama TBI, Chennai (Startup Incubator)</li>
                    <li>Rajiv Gandhi Centre for Aquaculture, Sirkali (Seabass & Mudcrab Hatchery)</li>
                    <li>Aquatic Rainbow Technology Park, Chennai (SPV)</li>
                    <li>Aquatic Rainbow Mall, TNJFU Chennai (SPV)</li>
                    <li>DCeSA Campus, Thanjavur – (Sales Point)</li>
                    <li>Thanjavur Centre (Sales Point)</li>
                    <li>FCRI, Ponneri (Sales Point)</li>
                    <li>FCRI, Thoothudi (Sales Point)</li>
                    <li>Cofe, Nagapattinam (Sales Point)</li>
                    <li>Muttukadu Experimental Station of ICAR-CIBA (Hatchery Facility)</li>
                    <li>State Referral Lab for Aquatic Animal Health, Madhavaram</li>
                    <li>TNJFU Referral Lab for Aquatic Animal Health, Thoothukudi</li>
                    <li>TNJFU Referral Lab for Feed Quality Testing</li>
                    <li>TNJFU Referral Lab for Fish Quality Monitoring & Certification, Thoothukudi</li>
                    <li>TNJFU Referral Lab for Aquatic Animal Health, Nagapattinam</li>
                    <li>Aquaculture Quality Testing Lab, Ponneri</li>
                    <li>Aquaculture Quality Testing Lab, Madhavaram</li>
                    <li>Aquaculture Quality Testing Lab, OMR, Chennai</li>
                    <li>Aquaculture Quality Testing Lab, Pulicat</li>
                    <li>Aquaculture Quality Testing Lab, Thanjavur</li>
                    <li>Aquaculture Quality Testing Lab, Nagapattinam</li>
                    <li>Aquaculture Quality Testing Lab, Ganapathipuram</li>
                    <li>Molecular PCR Lab, Thoothukudi</li>
                    <li>Molecular PCR Lab, Ponneri</li>
                    <li>Molecular PCR Lab, Madhavaram</li>
                    <li>Molecular PCR Lab, Nagapattinam</li>
                    <li>Mobile Aquaculture Quality Testing Lab, Ponneri</li>
                    <li>Mobile Aquaculture Quality Testing Lab, Nagapattinam</li>
                    <li>Mobile Aquaculture Quality Testing Lab, Madhavaram Milk Colony</li>
                    <li>Fish Quality Monitoring & Certification Lab, Thoothukudi</li>
                    <li>Fish Quality Monitoring & Certification Lab, Ponneri</li>
                    <li>Advanced Research Farm Facility (ARFF), Madhavaram</li>
                    <li>Brackishwater Research Farm Facility (BRFF), OMR</li>
                    <li>Pulicat Field Research Facility (PRFF), Pulicat</li>
                    <li>Mariculture Research Farm Facility(MRFF), Tharuvaikulam</li>
                    <li>Fish Processing Incubation Facility (FPIF), Nagapatinam</li>
                    <li>Shore Lab Field Research Facility (SRFF), Thoothukudi</li>
                    <li>EDII Marine Products Incubation Forum Incubator, Thoothukudi</li>
                  </ul>
                </div>
              </TabPanel>

            </TabContext>
          </Box>

        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Blueeconomy
