import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import Navbar from "../navbar-page/Navbar";
import Footer from "../footer-page/Footer";

const GrievanceMechanism = () => {
    const navigator = useNavigate();

    const handleBackBtn = () => {
        navigator(`/`);
    };
    return (
        <>
            <Navbar />
            <div className="back-color">
                <div className="back-layer"></div>
                <div className="back-layer1"></div>
                <div className="grievance_mechanism">
                    <div className="container">
                        <div className="header_grievance">
                            <button>Resources</button>
                            <h3>Grievance Mechanism</h3>
                        </div>
                        <div className="content_grievance">
                            <h1 className="mb-4">Nodal Officers For Grievances</h1>
                            <div className="d-flex w-100 justify-content-center">
                                <div className="Officers_Grievances d-flex align-items-center">
                                    <div>
                                        <img src="/images/sivaraj.png" alt="" className="logo-siva" />
                                    </div>
                                    <div className="ml-3">
                                        <h5>Thiru. Sivarajah Ramanathan</h5>
                                        <p className="mb-0">Chief Executive Officer, StartupTN</p>
                                        <p className="mb-0">ceo@startuptn.in</p>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5">
                                <h1>Submit Your Grievances.</h1>
                                <h1 className="mb-4">Connect with us at ceo@startuptn.in</h1>
                                <h5 className="color-blue">Here you may request/submit grievances regarding incubation support, funding support, public procurement, regular issues or any other query. The grievances raised will be forwarded to the officers concerned and will be resolved in 5 working days.</h5>
                            </div>
                            <div className="mb-4">

                                <Link to={'https://startuptn.in/wp-content/uploads/2023/02/Tansim-Order-scaled.jpg'} target="_blank"> <Button className="publish-button">  View Order</Button></Link>

                            </div>
                            <div>
                                <Link to={'/login'}><Button className="discard-btn">Raise an issues</Button></Link>
                                <Link to={'/login'}><Button className="publish-button">Track an issues</Button></Link>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Container>
                            <div className="blog-details-bg">
                                <div className="image-container">
                                    <img
                                        src='/images/usernav.jpg'
                                        alt=""
                                        className="img-fluid blog-res-img"
                                    />
                                    <div className="overlay"></div>
                                </div>
                                <div className="tender-head">
                                    <h3>Careers</h3>
                                </div>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <div className="back-blog">
                    <div className="allticket-list">
                        <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                            <FaArrowLeft />
                            <span style={{ marginLeft: "5px" }}>Back</span>
                        </Button>
                    </div>
                </div>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="container">
                            <h3 className="my-5 text-center">Be a part of something great!</h3>
                            <h4 className="my-5">Details regarding opening of careers will be posted here. Kindly mark this page for future reference.</h4>
                        </div>

                    </Grid>
                </Grid> */}
            </div>

            <Footer />
        </>
    )
}

export default GrievanceMechanism