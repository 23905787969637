import { Container, Grid } from '@mui/material'
import React from 'react'
import Navbar from '../navbar-page/Navbar'
import Footer from '../footer-page/Footer'
import { Link } from 'react-router-dom'

const ContactUs = () => {
    return (
        <>
            <div className="back-color1">
                <Navbar />
                <div className="back-layer"></div>
                <div className="back-layer1"></div>
                <div className="top-layer"></div>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Container>
                            <div className="main-title-head">
                                <h3 className='res-h3 mb-4 text-center'>Contact Us</h3>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    spacing={2}
                                    className='my-5'
                                >
                                    <Grid item xs={12} sm={12} md={4} lg={4} >
                                        <div className='white-box text-center'>
                                            <center><img src='/images/CS-Ph.png' className='img-fluid mb-3' alt='' /></center>
                                            <h6 className='belu-clr mt-2'><b>Contact - 155343</b></h6>
                                            <h6 className='belu-clr'><b>(+91) 44 22256789</b></h6>
                                            <p className='gru-clr'>Dedicated helpline for startups</p>
                                            <p className='timings-cont'>Monday - Friday<br />10.00 AM - 06.00 PM</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className='white-box text-center'>
                                            <center><img src='/images/CS-loc.png' className='img-fluid mb-3' alt='' /></center>
                                            <h6 className='belu-clr mt-2'><b>Office Address</b></h6>
                                            <p className='gru-clr'>Office Space, 10th Floor, Chennai Metro Rail Limited, Metro Station, Tamil Nadu Startup and Innovation Mission (StartupTN, near Nandanam, Nandanam, Chennai, Tamil Nadu 600035)</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <div className='white-box text-center'>
                                            <center><img src='/images/CS-supp.png' className='img-fluid mb-3' alt='' /></center>
                                            <p className='gru-clr'>For Support:</p>
                                            <h6 className='belu-clr mt-2'><b><img src='/images/Cs-Mail.png' alt='' className='img-fluid mr-2' />tech@startuptn.in</b></h6>
                                            <p className='gru-clr'>Quick Links</p>
                                            <div className='text-center'>
                                                <div className='social_div'>
                                                    <ul className='social_medias'>
                                                        <li>
                                                            <Link to={'https://www.facebook.com/TheStartupTN/'} target='_blank'>
                                                                <img src='/images/facebook_blue.svg' alt='' className='' />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'https://www.youtube.com/channel/UCr0du18taGeXH35dZZD4RnQ'} target='_blank'>
                                                                <img src='/images/youtube_blue.svg' alt='' className='' />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'https://www.instagram.com/thestartuptn/'} target='_blank'>
                                                                <img src='/images/instagram_blue.svg' alt='' className='' />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'https://x.com/TheStartupTN'} target='_blank'>
                                                                <img src='/images/twitter_blue.svg' alt='' className='' />
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'https://www.linkedin.com/company/thestartuptn/?originalSubdomain=in'} target='_blank'>
                                                                <img src='/images/linkedin_blue.svg' alt='' className='' />
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <img src='/images/cs-map.png' className='img-fluid mb-5' alt='' />
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>

    )
}

export default ContactUs
