import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardContent, Container, Grid} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useNavigate } from "react-router-dom";
const SmartCardPage = ({ name }) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const [serviceData, setServiceData] = useState([]);
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useDidMountEffect(() => {
    getServiceApi();
  }, []);
  const getServiceApi = () => {
    var req = {
      listSize: 4,
      pageNumber: 1,
    };
    apiService("smartcard/list", req, "unauthpost")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          if (result.data.responsemodelList) {
            setServiceData(result.data.responsemodelList);
          }
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    size();
  }, []);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };
  const handlenow = () => {
    navigate('/login')
  }
  const handleAllServicePage = () => {
    
    if (name === "home") {
      navigator("/smartcard/service");
    } else {
      navigator("/initiative/support/smartcard/service");
    }
  };
  return (
    <div className="smartCard-page">
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}` && `Support >`} Smart Card</span>
                <h1 className="banner-text-gradient">SMART CARD</h1>
                <h6>
                  Unlock exclusive benefits, access essential services, and
                  propel your startup towards success.
                </h6>
                <div className="banner-content-actions">
                  <Link to={'/login'}><button className="banner-viewbtn">Apply Smart Card </button></Link>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage">
                <h4>What is Smart Card ?</h4>
                <p>
                  The Startup Smart Card is a unique identification card
                  provided to eligible startups registered through the Startup
                  portal. It features a special Startup ID and a Lifetime QR
                  code that serves as an entry point to access various services,
                  track startup data, and participate in events organized by
                  Startup and other supporting departments in Tamil Nadu.
                </p>
                <div className="left-content-actions">
                  <button
                    className="ser-viewbtn"
                    onClick={handleAllServicePage}
                  >
                    Explore Services
                  </button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/smartcard-page-img.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
          <div className="smartcrd-details-div">
            <div className="service-partner-overlay"></div>
            <div className="det-lines">
              <div className="smcrd-services">
                <h3>
                  Service <span>Partners</span>
                </h3>
                <p>
                  Over 100+ Service Partners Onboarded Successfully – Join the
                  Revolution!"
                </p>
                <div className="partners-company-logos-scroll">
                  <div className="partners-company-logos">
                    {serviceData.map((logo, i) => (
                      <div className="partners-company-sec" key={i}>
                        <img
                          src={logo.companyLogoName}
                          alt={logo.subCategory}
                          className="part-logos"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="why-smartcrd-sec mt-5">
          <div className="why-sm-title">
            <h3>
              Why <span> Smart Card?</span>
            </h3>
          </div>
          <div className="why-sm-paras">
            <Container>
              <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img src={"/images/access-key.png"} alt="Acess Key" />
                    </div>
                    <div className="why-sm-det">
                      <h6>Streamlined Access to Services:</h6>
                      <p className="text-justify">
                        The Smart Card offers startups a seamless way to access
                        a comprehensive selection of products and services at
                        subsidized rates. These services are tailored to meet
                        the unique needs of startups in their early stages.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img
                        src={"/images/comprehensive.png "}
                        alt="comprehensive data"
                      />
                    </div>
                    <div className="why-sm-det">
                      <h6>Comprehensive Data Tracking :</h6>
                      <p className="text-justify">
                        The Smart Card enables startups to track all relevant
                        data and metrics in one place. This centralized tracking
                        helps in making informed decisions and optimizing
                        business strategies for better outcomes.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts1 text-center">
                    <div className="why-sm-icns">
                      <img
                        src={" /images/exclusive-event.png"}
                        alt="exclusive events"
                      />
                    </div>
                    <div className="why-sm-det">
                      <h6>Exclusive Event Participation :</h6>
                      <p className="text-justify">
                        The Smart Card grants startups exclusive access to
                        Startup and other departmental events, offering
                        valuable networking, learning sessions, and exposure to
                        potential investors and partners.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="join-us-now-sec1">
          <Container>
            <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start">
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="service-partners-title">
                  <h4>Service Partners</h4>
                  <p>
                    To enhance the value of the Smart Card and support our
                    startup community, we are seeking service partners to
                    provide discounts on their products and services.
                  </p>
                </div>
              </Grid>
            </Grid>
            <div className="service-crd">
              <Swiper
                slidesPerView={screenSize}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
                style={{ paddingBottom: "50px" }}
              >
                {serviceData.map((item, i) => (
                  <SwiperSlide key={i}>
                    <Card className="sup-sm-crds1">
                      <div className="service-card-back-layers1">
                        <div className="layer-crd-back-img1">
                          <div className="service-card-back-profss1">
                            <img
                              src={item.companyLogoName}
                              alt="crd-img1"
                              className="service-card-back-image1"
                            />
                          </div>
                        </div>
                      </div>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          padding: "24px 0px 0px 0px",
                        }}
                      >
                        <p className="ser-crd-title-text">{item.subCategory}</p>
                      </CardContent>
                    </Card>
                  </SwiperSlide>
                ))}
                <div className="ser-content-actions">
                  <button
                    className="ser-viewbtn"
                    onClick={handleAllServicePage}
                  >
                    Explore all services partners
                  </button>
                </div>
              </Swiper>
            </div>
          </Container>
        </div>
        <div className="faq-section">
          <div className="faq-content">
            <Container>
              <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                  <div className="faq-box-div">
                    <h3>Frequently Asked Questions</h3>
                    <div className="accordion">
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{ border: "none" }}
                        >
                          <div className="d-flex w-100">
                            <p>How to avail Startup Smart Card?</p>
                            <div className="ml-auto">
                              {expanded === "panel1" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          To obtain the startup SmartCard, visit the smart
                          card information page on the startup portal and
                          apply.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel2-content"
                          id="panel2-header"
                        >
                          <div className="d-flex w-100">
                            <p>How to onboard as partner in smart Card?</p>
                            <div className="ml-auto">
                              {expanded === "panel2" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                        className="sm-accordion-divs"
                      >
                        <AccordionSummary
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <div className="d-flex w-100">
                            <p>Do you offer services for websites and apps ?</p>
                            <div className="ml-auto">
                              {expanded === "panel3" ? (
                                <span className="round-black">-</span>
                              ) : (
                                <span className="round-black">+</span>
                              )}
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse malesuada lacus ex, sit amet blandit
                          leo lobortis eget.
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <div className="faq-smcrd-div">
                    <div className="faq-box-2-flex">
                      <div className="faq-box2-img">
                        <img
                          src={"/images/smcrd-get-img.png"}
                          alt="smartcard-img"
                          className="smcrd-get"
                        />
                      </div>
                      <div className="faq-box2-content">
                        <h4>Get Smart Card to Access Services Now</h4>
                        <p>100+ Service Partners </p>
                        <div className="ser-content-actions">
                          <button className="faq-viewbtn" onClick={handlenow}>Apply Now</button>
                        </div>
                      </div>
                    </div>
                    <div className="box-2-link">
                      <Link>
                        Click to explore more Smart Card Service Partners{" "}
                        <img
                          src="/images/smcrd-arrow.png"
                          alt="right-arrow"
                          className="smcrd-arrow-right"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="set-support-div">
                    <div className="faq-support-content">
                      <div className="faq-support-img">
                        <img src={"/images/smcrd-person.png"} alt="person" />
                      </div>
                      <div className="faq-support-para">
                        <p>
                          Need support for a Smart Card-related query? Don't
                          worry, we're here to help you!
                        </p>
                        <span className="support-links">
                        <p onClick={handlenow}>Click here to raise a support ticket</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid container direction="row" justifyContent="center" alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>Let’s Get Started</h3>
                    <p>
                      Smart Card support our startup community, we are seeking
                      service partners to provide discounts on their products
                      and services.
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default SmartCardPage;
