// import React from 'react'

// const VentureLabpage = () => {
//   return (
//     <div>

//     </div>
//   )
// }

// export default VentureLabpage

import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const VentureLabpage = ({name}) => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`${name}  >  Venture Lab `}</span>
                  <h1 className="banner-text-gradient">
                    Social Justice - Venture lab
                  </h1>
                  <h6 className="fund-title">
                    “Inclusive Innovation for social Impact”
                  </h6>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info1">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info1"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage1">
                <h4>What is Social Justice - Venture Lab?</h4>
                <p>
                  Social Justice Venture Lab is a pioneering initiative aimed at
                  fostering inclusive economic growth and social impact through
                  entrepreneurship. The objective is to empower marginalized
                  communities, drive social change, and promote sustainable
                  development through entrepreneurship. By nurturing a culture
                  of innovation and inclusivity, this lab seeks to address
                  pressing societal challenges, create opportunities for
                  underrepresented entrepreneurs, and catalyze positive
                  transformation across the region.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="smart-crd-img">
                <img
                  className=" img-fluid"
                  src={"/images/tamilnadu_logo_high.svg"}
                  alt="startupLogo"
                />
              </div>
            </Grid>
          </Grid>
          {/* <div className="smartcrd-details-div ">
            <div className="">
              <div className="">
                <img
                  src="/images/TANSEED 6.0.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className=""></div>
            </div>
          </div> */}
        </Container>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Social Justice - Venture Lab</h4>
                    <p>“Inclusive Innovation for Social Impact</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default VentureLabpage;
