import { Button, Grid, List, Pagination, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import ReportFilter from "./ReportFilter";
const ReportDocuments = () => {
  const { navigator, setPageLoading, startDate, endDate, } = useContext(DataContext);

  useDidMountEffect(() => {
    categoryDropDown();
  }, []);
  const [categoryList, setCategoryList] = useState([]);
  const categoryDropDown = () => {
    apiService(`home/category/list?type=report`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.categories) {
          setCategoryList(result.data.categories);
          setCategoryId(result.data.categories[0].id)
        }
      })
      .catch((err) => { });
  };


  const [searchInputValue, setSearchInputValue] = useState();
  const [totalPage, setTotalPage] = useState(1);
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [categoryId, setCategoryId] = useState('')
  const [sortBy, setSortBy] = useState(true)
  const [departmentId, setDepartmentId] = useState(0);
  useDidMountEffect(() => {
    listApi();
  }, [page, searchInputValue, startDate, endDate, categoryId, sortBy, departmentId]);

  const listApi = () => {
    setReports([])
    setPageLoading(true);
    var data = {
      listSize: 10,
      pageNumber: page,
      categoryId: categoryId,
      searchString: searchInputValue,
      startDate: startDate ? (startDate + 'T00:00:00.174Z') : '',
      endDate: endDate ? (endDate + 'T00:00:00.174Z') : '',
      sort: sortBy ? 'asc' : 'desc',
      departmentId: departmentId
    };
    apiService("reports/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.reports) {
            setReports(result.data.reports);
          }
        }
      })
      .catch((err) => { });
  };
  const [activeButton, setActiveButton] = useState(0);
  const handleButtonClick = (id, i) => {
    setActiveButton(i);
    setCategoryId(id)
    console.log(i, "index");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <ReportFilter
        setSearchInputValue={setSearchInputValue}
        setSortBy={setSortBy} sortBy={sortBy}
        setDepartmentId={setDepartmentId}
      />
      <div className="event-datas-list py-5">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={4}
        >
          <Grid item xs={12} md={3}>
            <div className="my-5">
              {/* <h6>Choose category</h6> */}
            </div>
            {categoryList.map((a, i) => (
              <div key={i}>
                <Button
                  fullWidth
                  className={activeButton === i ? "report-button active" : "report-button"}
                  onClick={() => handleButtonClick(a.id, i)}
                  sx={{ mb: 1 }}
                >
                  {a.category}
                </Button>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="mt-3">
              <h6>Documents & Reports</h6>
            </div>
            <div className="table_main_div">
              {
                reports.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}
                    >
                      <TableBody sx={{ position: "relative" }}>
                        {reports.map((list, index) => (
                          <>
                            <TableRow
                              sx={{
                                position: "relative",
                                "&:after": {
                                  content: '""',
                                  position: "absolute",
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                  borderRadius: "20px",
                                  pointerEvents: "none",
                                  marginBottom: "-1px",
                                },
                                "& > *": {
                                  padding: "10px",
                                  backgroundColor: "#fff",
                                  "&:last-of-type": {
                                    borderTopRightRadius: "20px",
                                    borderBottomRightRadius: "20px",
                                  },
                                },
                                marginBottom: "10px",
                              }}
                              key={index}
                            >
                              <TableCell style={{ border: "none" }}>
                                <div className="px-3">
                                  <div className="list-2-dflex">
                                    <span className="doc-report-title">
                                      {list.name}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="list-2-grey">
                                      {list.department}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ border: "none" }}>
                                <div className="events-date-box">
                                  <div className=" mr-2">
                                    <p className="m-1 report-date">Date</p>
                                    <p className="m-1 meeting-report-dates">
                                      {list.date}
                                    </p>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell style={{ border: "none" }}>
                                <div className="events-time-box mr-3">
                                  <div className="">
                                    <p className="m-1 report-pdf">Reports in PDF</p>
                                    <Link
                                      to={list.fileName}
                                      target="_blank"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <p className="m-1 reportpdf">Download-({list.fileSize})</p>
                                    </Link>

                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                      {
                        totalPage > 1 && (
                          <div className="event-pagination">
                            <Stack spacing={2}>
                              <Pagination
                                count={totalPage}
                                page={page}
                                onChange={handleChange}
                              />
                            </Stack>
                          </div>
                        )
                      }
                    </Table>
                  </TableContainer>
                ) : (
                  <>
                    <div className='text-center nodatacss_admin'>
                      <h5 className='mb-4'>Report data not found</h5>
                      <div>
                        <img src="/images/blue horizontal.png" alt="blue horizontal.png" className='logo_dash img-fluid' />
                      </div>
                      <div className='mt-5'>
                        <h3 className='title_p opa'>
                          {/* StartupTN 3.0 */}
                          OneTN
                        </h3>
                      </div>
                    </div>
                  </>
                )
              }

            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ReportDocuments;
