import { Autocomplete, Box, Button, FormHelperText, Grid, TextField } from '@mui/material'
import React from 'react'
import { useContext } from 'react';
import DataContext from '../../../context/DataContext';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import notifyService from '../../../api/notifySerivce';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
const BudgetAdd = () => {
  const { id } = useParams()
  let budgetId
  if (id !== undefined) {
    budgetId = atob(id)
  }
  const { setPageLoading, navigator, validateNumberonly } = useContext(DataContext)
  const [formData, setFormData] = useState({
    budgetBreakdown: [{ id: 0, title: '', amount: '' }]
  });
  const { handleSubmit, control, setValue, reset, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
  const {
    fields: breakdownFields,
    append: breakdownAppend,
    remove: breakdownRemove,
  } = useFieldArray({
    control,
    name: "budgetBreakdown",
  });
  useDidMountEffect(() => {
    departmentListApi()
    statedropdownApi()
  }, [])
  const [departmentList, setDepartmentList] = useState([])
  const departmentListApi = () => {
    apiService(`department/list`, {}, 'post').then((result) => {
      if (result && result.data && result.data.department) {
        setDepartmentList(result.data.department)
      }
    })
  }
  const [breakDownValues, setBreakDownValues] = useState([]);
  const statedropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"budgetbreakdown"}`, "", "unauthget")
      .then((result) => {

        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setBreakDownValues(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  useDidMountEffect(() => {
    if (budgetId) {
      getApi()
    }
  }, [])
  const getApi = () => {
    apiService(`budget/get?id=${budgetId}`, '', 'get').then((result) => {
      if (result && result.data) {
        var data = result.data
        setFormData(data)
      }
    }).catch((err) => {

    });
  }
  const handleDepartment = (value) => {
    setValue("department", value);
    clearErrors("department");
  }
  const handleAddRow = () => {
    breakdownAppend({
      id: 0,
      title: '',
      amount: ''
    });
  };
  const handleBudgetBreakDelete = (index) => {
    var id = getValues(`budgetBreakdown.${index}.id`)
    if (id !== 0) {
      breakdownRemove(index)
      // apiService(`event/management/remove?id=${id}`, '', 'delete').then((result) => {
      //   if (result && result.data) {
      //     breakdownRemove(index)
      //   }
      // }).catch((err) => {

      // });
    } else {
      breakdownRemove(index)
    }
  }
  const handleBreakTitle = (value, index) => {
    setValue(`budgetBreakdown.${index}.title`, value);
    clearErrors(`budgetBreakdown.${index}.title`);
  }
  const onSubmit = (data) => {
    setPageLoading(true)
    var depValue = departmentList.filter((list) => list.name === data.department)
    data.departmentId = depValue[0].id
    apiService('budget/save', data, "post").then((res) => {
      setPageLoading(false)
      if (res && res.data && res.data.responseStatus === "Success") {
        onClose()
        notifyService("success", "Success", "");
      }
    });
  }
  const onError = (event) => {
    console.log(event)
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  const onClose = () => {
    reset()
    navigator('/admin/budget/allocation')
  };
  return (
    <>
      <h4 className='bluee-clrr text-center mt-5'>
        {
          budgetId ? (
            <span>Update</span>
          ) : (
            <span>Add</span>
          )
        } Budgets</h4>
      <p className='progress-title text-center'>
        {
          budgetId ? (
            <span>Update</span>
          ) : (
            <span>Add</span>
          )
        } & Breakdown the Budgets</p>

      <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <section className='px-5'>
          <div className='special_title_header d-flex align-items-center mb-3'>
            <p className='bluee-clrr'>
              {
                budgetId ? (
                  <span>Update</span>
                ) : (
                  <span>Add</span>
                )
              } Budgets</p>
          </div>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item sm={12} md={6} lg={6}>
              <div className='input_background'>
                <Controller
                  sx={{ width: '600' }}
                  name="department"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Department is required" }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      {...field}
                      options={departmentList.map((val) => val.name)}
                      onChange={(e, selectedOptions) =>
                        handleDepartment(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Department " />
                      )}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.department && errors.department.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <div className='input_background'>
                <Controller
                  name="amount"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Budget amount is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Choose Budget Amount"
                      placeholder="Please Enter Budget amount"
                      fullWidth
                      type="text"
                      {...field}
                      onKeyDown={(e) => validateNumberonly(e)}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.amount && errors.amount.message}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item sm={12} md={12} lg={12}>
              <div className='input_background'>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Budget Description is required" }}
                  render={({ field }) => (
                    <TextField
                      variant="outlined"
                      label="Budget Description"
                      placeholder="Please Enter Budget Description"
                      fullWidth
                      multiline
                      rows={4}
                      {...field}
                    />
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.description && errors.description.message}
                </FormHelperText>
              </div>
            </Grid>
          </Grid>
        </section>

        <section className='px-5 my-3'>
          <div className='special_title_header d-flex align-items-center mb-3'>
            <p className='bluee-clrr'>Breakdown</p>
            <div className='ml-auto'>
              <Button className='add_icon_btn' onClick={handleAddRow}>+ Add</Button>
            </div>
          </div>
          <div className='event_add_details_div'>
            {
              breakdownFields.map((field, index) => (
                <Grid key={field.id} container spacing={3} alignItems={'center'} className='mb-3'>
                  <Grid item sm={12} md={5} lg={5}>
                    <div className='input_background'>
                      <Controller
                        name={`budgetBreakdown.${index}.title`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Title is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={breakDownValues}
                            onChange={(e, selectedOptions) => handleBreakTitle(selectedOptions, index)}
                            renderInput={(params) => (
                              <TextField {...params} label="Title" />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.budgetBreakdown?.[index]?.title && errors.budgetBreakdown?.[index]?.title.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item sm={12} md={5} lg={5}>
                    <div className='input_background'>
                      <Controller
                        name={`budgetBreakdown.${index}.amount`}
                        control={control}
                        defaultValue=""
                        rules={{ required: "Budget amount is required" }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Budget Amount"
                            placeholder="Enter Budget Amount"
                            fullWidth
                            type="text"
                            {...field}
                            onKeyDown={(e) => validateNumberonly(e)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.budgetBreakdown?.[index]?.amount && errors.budgetBreakdown?.[index]?.amount.message}
                      </FormHelperText>
                    </div>
                  </Grid>
                  <Grid item sm={12} md={2} lg={2}>
                    <div className='text-center'>
                      <DeleteIcon onClick={() => handleBudgetBreakDelete(index)} sx={{ color: 'red', cursor: 'pointer' }} />
                    </div>
                  </Grid>
                </Grid>
              ))}
          </div>
          <div className='text-center my-5 w-100'>
            <Button onClick={onClose} className="discard-btn">
              Close
            </Button>
            <Button type="submit" className="publish-button">
              {
                budgetId ? (
                  <span>Update</span>
                ) : (
                  <span>Save</span>
                )
              }
            </Button>
          </div>
        </section>
      </form>
    </>
  )
}

export default BudgetAdd