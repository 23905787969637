import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';
import notifyService from '../../../api/notifySerivce';
import { Button, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton, TextField } from '@mui/material';
import cryptoJS from '../../../hooks/cryptoJS';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ResendTimer from './ResendTimer';
import Captchatext from '../../common-pages/Captchatext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
const ForgotPassword = ({ forgotPasswordClose }) => {
    const { setPageLoading, validateEmail, validatePassword, captchaValue, setCaptchaValue, setInputValue } = useContext(DataContext)
    const { handleSubmit, control, reset,getValues, formState: { errors } } = useForm({ mode: 'onChange' });
    const [showPassword, setShowPassword] = React.useState(false);
    const [checkSamePassword, setCheckSamePassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [showRePassword, setShowRePassword] = React.useState(false);
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useDidMountEffect(() => {
        setCaptchaValue('')
    }, [])
    const [sendOTP, setSendOTP] = useState(false)
    const [idValue, setIdValue] = useState(0)
    const onSubmit = (data) => {
        if (sendOTP === true) {
            if (data.password === data.newPassword) {
                setPageLoading(true)
                setCheckSamePassword(false);
                data.id = idValue
                data.otp = cryptoJS(data.otp);
                data.password = cryptoJS(data.password);
                data.newPassword = cryptoJS(data.newPassword);
                apiService('password/reset/verify', data, "unauthpost").then((res) => {
                    setPageLoading(false)
                    if (res) {
                        if (res.data.responseStatus) {
                            setSendOTP(false)
                            reset()
                            setInputValue('')
                            forgotPasswordClose();
                            notifyService("success", "Success", res.data.responseStatus);
                            localStorage.removeItem('email')
                        }
                    }
                });
            } else {
                setCheckSamePassword(true);
            }
        } else {
            if (!captchaValue) {
                notifyService('danger', 'Enter Captcha', 'Please Verify  Captcha')
                return;
            }
            setPageLoading(true)
            var emailValue = cryptoJS(data.email.toLowerCase());
            localStorage.setItem('emailenc', emailValue)
            resenOtp()
        }
    }
    const onError = (data) => {
        notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
    }
    const handleCloseModal = () => {
        reset()
        forgotPasswordClose();
    };
    const [seconds, setSeconds] = useState(60);
    const resenOtp = () => {
        setPageLoading(true)
        var req = {
            email: localStorage.getItem('emailenc')
        }
        apiService('password/reset', req, "unauthpost").then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data) {
                    setIdValue(res.data.id)
                    setSendOTP(true)
                    setSeconds(60)
                    notifyService("success", "Success", res.data.responseStatus);
                    reset()
                }
            }
        });
    }
    return (
        <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
            <div className='d-flex justify-content-between'>
                <DialogTitle>
                    {
                        sendOTP === true ? (
                            'Verify !'
                        ) : (
                            'Forgot Password'
                        )
                    }
                </DialogTitle>
            </div>
            <DialogContent sx={{ width: '400px' }}>
                <div className='row' >
                    {
                        sendOTP === true ? (
                            <>
                                <div className='col-12 mb-3' >
                                    <Controller
                                        name="otp"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "OTP is required"
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="OTP"
                                                placeholder="Enter OTP"
                                                fullWidth
                                                type="text"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.otp && errors.otp.message}
                                    </FormHelperText>
                                    <ResendTimer resent={resenOtp} seconds={seconds} setSeconds={setSeconds} />
                                </div>
                                <div className='col-12 mb-3' >
                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Password is required", validate: validatePassword }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Password"
                                                placeholder="Enter Password"
                                                fullWidth
                                                {...field}
                                                type={showPassword ? "text" : "password"}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.password && errors.password.message}
                                    </FormHelperText>
                                </div>
                                {/* <div className='col-12 mb-3' >
                                    <Controller
                                        name="newPassword"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Enter Confirm Password", }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Confirm Password"
                                                placeholder="Confirm Password"
                                                fullWidth
                                                {...field}
                                                type={showRePassword ? "text" : "password"}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={handleClickShowRePassword}>
                                                            {showRePassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.newPassword && errors.newPassword.message}
                                    </FormHelperText>
                                    <FormHelperText className="text-danger">
                                        {checkSamePassword === true ? "Password Mismatch" : <></>}
                                    </FormHelperText>
                                </div> */}
                                <div className='col-12 mb-3'>
                                    <Controller
                                        name="newPassword"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Enter Confirm Password",
                                            validate: {
                                                matchPassword: value => value === getValues("password") || "Password Mismatch"
                                            }
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Confirm Password"
                                                placeholder="Confirm Password"
                                                fullWidth
                                                {...field}
                                                type={showRePassword ? "text" : "password"}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton onClick={handleClickShowRePassword}>
                                                            {showRePassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.newPassword && errors.newPassword.message}
                                    </FormHelperText>
                                </div>
                            </>) : (
                            <>
                                <div className='col-12 mb-3' >
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Email Address is required",
                                            validate: validateEmail,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Email Address"
                                                placeholder="Enter Email Address"
                                                fullWidth
                                                type="text"
                                                {...field}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.email && errors.email.message}
                                    </FormHelperText>

                                </div>
                                <div className="col-12 mb-3">
                                    <Captchatext />
                                </div>
                            </>
                        )
                    }

                </div>
            </DialogContent>
            <DialogActions>
                <div className='text-center mb-3 w-100'>
                    <Button onClick={handleCloseModal} variant="outlined" className='form-btn'>Close</Button>

                    {
                        sendOTP === true ? (
                            <Button
                                type="submit"
                                variant="contained"
                                className='form-btn b-green-btn ml-3'
                            >
                                Update
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                variant="contained"
                                className='form-btn b-green-btn ml-3'
                            >
                                Sent OTP
                            </Button>

                        )
                    }
                </div>

            </DialogActions>
        </form>
    )
}


export default ForgotPassword