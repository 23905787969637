import React, { useContext, useState } from 'react'
import { Button, Checkbox, FormHelperText, Grid, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import DataContext from '../../../../context/DataContext'
import notifyService from '../../../../api/notifySerivce'
import { MuiFileInput } from 'mui-file-input'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import apiService from '../../../../api/apiService'
import Viewimage from '../../../../api/Viewimage'

const DocumentsAspirants = () => {
  const { navigator, setPageLoading, checkAlphanumeric, handleNextClick, VisuallyHiddenInput } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, getValues, formState: { errors }, } = useForm({ values: formData });
  var id = localStorage.getItem("id");
  var type = localStorage.getItem('type')

  useDidMountEffect(() => {
    handleNextClick(90)
    getApi()
  }, [])



  const getApi = () => {
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        var data = result.data
        setFormData(data)
        // if (data.pitchdeckUrl) {
        //   data.pitchdeckUrl = data.pitchdeckUrl
        // }
        // if (data.aadhaarCardUrl) {
        //   data.aadhaarCardUrl = data.aadhaarCardUrl
        // }
      }

    }).catch((err) => {

    });
  }

  const [open, setOpen] = useState(false);
  const [fileLable, setFileLable] = useState("");
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");

  const handleClickOpen = (value, name, label) => {
    setViewImage(value);
    setViewImageName(name);
    setFileLable(label);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let logoselecetdFile = ''
  const handleFileUpload = (event, filekey, fileName) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024;
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type
          var imagedatatype = imagetype.split("/")
          var img_crt_type = imagedatatype[1]
          if (img_crt_type === "pdf") {
            setValue(filekey, "")
            setValue(fileName, "")
            // setValue(fileUrl, '')
            var fileValue = logoselecetdFile
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(',')
              var ImageValue = spl[1]
              var img_name = fileValue.name
              setValue(filekey, ImageValue)
              setValue(fileName, img_name)
              // setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
            }
          } else {
            notifyService('danger', 'File Format Invalided', 'Please check your file format')
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    } else {
    }

  }
  const handleCheck = (e) => {
    setValue(`aadhaarConsern`, e.target.checked);
    clearErrors(`aadhaarConsern`);
  };

  const onSubmit = (data) => {
    console.log('Form Data:', data);
    setPageLoading(true)
    apiService('userprofile/save', data, 'post').then((result) => {
      if (result.data.responseStatus === 'Success') {
        navigator('/aspirants/moreinfo')
      }
    }).catch((err) => {

    });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Organization Info {`>`} <span className='path_sapn'>Documents</span></p>
              <p className='ml-auto'><span className='count_num'>4</span>/5</p>
            </div>
            <div className='signup_box_div mt-4'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  {/* <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="aadhaarNumber"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Udyog Aadhaar No. is required", minLength: { value: 12, message: 'Enter Minimum 12 Digits' }, maxLength: { value: 12, message: 'Enter Maximum 12 Digits' } }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Udyog Aadhaar No *"
                          placeholder="Enter Udyog Aadhaar No."
                          fullWidth
                          type="text"
                          inputProps={{ maxLength: 12 }}
                          onKeyDown={(e) => checkAlphanumeric(e)}
                          {...field}
                          error={Boolean(errors.aadhaarNumber && errors.aadhaarNumber)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.aadhaarNumber && errors.aadhaarNumber.message}
                    </FormHelperText>
                    <div className="d-flex align-items-center  mt-2 ">
                      <Controller
                        name={`aadhaarConsern`}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Concern Required",
                        }}
                        render={({ field: props }) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) =>
                              handleCheck(e)
                            }
                          />
                        )}
                      />
                      <p className="format-details  mt-3 " style={{ fontSize: "11px" }}>
                        I provide my consent to Tanfund to share
                        my details with the issuers for the
                        purpose of fetching my documents.
                      </p>
                    </div>
                    <FormHelperText className="text-danger">
                      {errors.aadhaarConsern &&
                        errors.aadhaarConsern.message}
                    </FormHelperText>
                  </Grid> */}
                  {/* <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>                    
                    {getValues('aadhaarCardUrl') ? (
                      <>
                        <div className="px-3">
                          <p className="mb-0">Aadhar Document </p>
                          <div className="row">
                            <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                              <Controller
                                name="aadhaarCardName"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Aadhar Document is required" }}
                                render={({ field }) => (
                                  <Button
                                    component="label"
                                    variant="contained"
                                    {...field}
                                    fullWidth
                                    onChange={(e) =>
                                      handleFileUpload(e, "aadhaarCard", "aadhaarCardName")
                                    }
                                    startIcon={<CloudUploadIcon />}
                                    href="#file-upload"
                                  >
                                    Change
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                )}
                              />
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                              <Button
                                className="c-pointer ml-2"
                                size="medium"
                                fullWidth
                                variant="outlined"
                                onClick={() =>
                                  handleClickOpen(
                                    getValues("aadhaarCard"),
                                    getValues("aadhaarCardUrl"),
                                  )
                                }
                              >
                                Preview
                              </Button>
                            </Grid>
                          </div>
                        </div>
                        <div>
                          <span className="font-sm d-flex  mt-1 ">
                            Maximum 5 mb allowed doc (Accepted Format PDF){" "}
                            <span className="text-danger">*</span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="form-group mt-2">
                        <Controller
                          name="aadhaarCardName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Aadhar Document is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Aadhar Document *"
                              placeholder="Upload your Aadhar Document"
                              fullWidth
                              type="text"
                              {...field}
                              disabled
                              error={Boolean(errors.aadhaarCardName && errors.aadhaarCardName)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.aadhaarCardName && errors.aadhaarCardName.message}
                        </FormHelperText>
                        <div className='d-flex align-items-center w-100 my-3'>
                          <p className='font-sm'>Maximum 5 mb allowed doc <br />(Accepted Format PDF)</p>
                          <label className='ml-auto lbl_btn'
                            onChange={(e) => handleFileUpload(e, "aadhaarCard", "aadhaarCardName")}
                            href="#file-upload">
                            <img src='/images/admin/upload.png' className='mr-3' alt='' />
                            Upload Image
                            <VisuallyHiddenInput type="file" />
                          </label>
                        </div>
                      </div>
                    )}
                  </Grid> */}
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    {getValues('pitchdeckUrl') ? (
                      <>
                        <div className="px-3">
                          <p className="mb-0">Pitch Deck </p>
                          <div className="row">
                            <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                              <Controller
                                name="pitchdeck"
                                control={control}
                                defaultValue=""
                                rules={{ required: false }}
                                render={({ field }) => (
                                  <Button
                                    component="label"
                                    variant="contained"
                                    {...field}
                                    fullWidth
                                    onChange={(e) =>
                                      handleFileUpload(e, "pitchdeck", "pitchdeckName")
                                    }
                                    startIcon={<CloudUploadIcon />}
                                    href="#file-upload"
                                  >
                                    Change
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                )}
                              />
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                              <Button
                                className="c-pointer ml-2"
                                size="medium"
                                fullWidth
                                variant="outlined"
                                onClick={() =>
                                  handleClickOpen(
                                    getValues("pitchdeck"),
                                    getValues("pitchdeckUrl"),
                                  )
                                }
                              >
                                Preview
                              </Button>
                            </Grid>
                          </div>
                        </div>
                        <div>
                          <span className="font-sm d-flex  mt-1 ">
                            Maximum 5 mb allowed doc (Accepted Format PDF){" "}
                            <span className="text-danger">*</span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="form-group mt-2">
                        <Controller
                          name="pitchdeckName"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Pitch Deck"
                              placeholder="Upload your Pitch Deck"
                              fullWidth
                              type="text"
                              {...field}
                              disabled
                              error={Boolean(errors.pitchdeckName && errors.pitchdeckName)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pitchdeckName && errors.pitchdeckName.message}
                        </FormHelperText>
                        <div className='d-flex align-items-center w-100 my-3'>
                          <p className='font-sm'>Maximum 5 mb allowed doc <br />(Accepted Format PDF)</p>
                          <label className='ml-auto lbl_btn'
                            onChange={(e) => handleFileUpload(e, "pitchdeck", "pitchdeckName")}
                            href="#file-upload">
                            <img src='/images/admin/upload.png' className='mr-3' alt='' />
                            Upload PDF
                            <VisuallyHiddenInput type="file" />
                          </label>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <button className='next-btn' type="submit">Next</button>
                  </Grid>
                </Grid>
              </form>
              <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
                fileLable={fileLable}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default DocumentsAspirants