import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  // dev & qa code
  // apiKey: "AIzaSyB5iDtv2WuPHXioyoHOZGDdMzTVtFb8O-o",
  // authDomain: "tnstartup-ecosystem-dev.firebaseapp.com",
  // projectId: "tnstartup-ecosystem-dev",
  // storageBucket: "tnstartup-ecosystem-dev.appspot.com",
  // messagingSenderId: "372913154338",
  // appId: "1:372913154338:web:fea50ecdd54fff944ddd7b",
  // measurementId: "G-0REMSP12F7"

  // new live code ->Date: 26/11/24
  apiKey: "AIzaSyDcW_KIXNbdfPMNNthJkR0zz1PAXxcyIPg",
  authDomain: "onetn-34b44.firebaseapp.com",
  projectId: "onetn-34b44",
  storageBucket: "onetn-34b44.firebasestorage.app",
  messagingSenderId: "81147110270",
  appId: "1:81147110270:web:8b96528dabf120c52dc92f",
  measurementId: "G-YE44JTZP0M",
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const firestore = app.firestore();

// Create Google Auth Provider instance
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// Create Facebook Auth Provider instance
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export { auth, firestore, googleAuthProvider, facebookAuthProvider };
export default app;
