import { Button, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import DataContext from '../../../../context/DataContext'
import apiService from '../../../../api/apiService'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import { MuiFileInput } from 'mui-file-input'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import notifyService from '../../../../api/notifySerivce'
import Viewimage from '../../../../api/Viewimage'
const DocumentsServiceProvider = () => {
  const { navigator, VisuallyHiddenInput, checkAlphanumeric, handleNextClick, validateCinOrPanNo } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors }, } = useForm({ values: formData });
  const [checkMethod, setCheckMethod] = useState('save')
  const [pitchDeckValue, setPitchDeckValue] = useState(null)
  const [pitchdeckName, setPitchDeckName] = useState(null)
  const [pitchDeckURL, setPitchDeckURL] = useState(null)
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const [fileLable, setFileLable] = useState("");
  useDidMountEffect(() => {
    handleNextClick(70)
    getApi()
  }, [])
  const getApi = () => {
    var id = localStorage.getItem('id')
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get").then((result) => {
      if (result && result.data) {
        var data = result.data
        if (data.proposalUrl) {
          data.proposalName = data.proposalUrl
        }
        setFormData(data)
      }

    }).catch((err) => {
    });
  }
  let logoselecetdFile = ''
  const handleFileUpload = (event, filekey, fileName) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type
          var imagedatatype = imagetype.split("/")
          var img_crt_type = imagedatatype[1]
          if (img_crt_type === "pdf") {
            setValue(filekey, "")
            setValue(fileName, "")
            // setName('')
            // setUrl('')
            // if (event.target === undefined) {
            //   setdata(event)
            // } else {
            //   setdata(event.target.files[0])
            // }
            var fileValue = logoselecetdFile
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(',')
              var ImageValue = spl[1]
              var img_name = fileValue.name
              // setUrl(logourl1)
              setValue(filekey, ImageValue)
              setValue(fileName, img_name)
              // setName(img_name)
              clearErrors(filekey);
              clearErrors(fileName);
            }
          } else {
            notifyService('danger', 'File Format Invalided', 'Please check your file format')
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  }
  const handleClickOpen = (value, name, lable) => {
    setViewImage(value);
    setViewImageName(name);
    setFileLable(lable);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (data) => {
    console.log('Form Data:', data);
    apiService('userprofile/save', data, 'post').then((result) => {
      if (result.data.responseStatus === 'Success') {
        navigator('/service-provider/moreinfo')
      }
    }).catch((err) => {
    });
  };
  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Organization Info {`>`} <span className='path_sapn'>Documents</span></p>
              <p className='ml-auto'><span className='count_num'>4</span>/5</p>
            </div>
            <div className='signup_box_div mt-4'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  {/* <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="cinOrPanNo"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "CIN / PAN is required",
                        validate: validateCinOrPanNo
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="CIN / PAN*"
                          placeholder="Enter CIN / PAN"
                          fullWidth
                          type="text"
                          inputProps={{ maxLength: 21 }}
                          onKeyDown={(e) => checkAlphanumeric(e)}
                          {...field}
                          error={Boolean(errors.cinOrPanNo && errors.cinOrPanNo)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.cinOrPanNo && errors.cinOrPanNo.message}
                    </FormHelperText>

                  </Grid> */}
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    {/* {checkMethod === 'save' ? (
                      <div className="form-group mt-2">
                        <Controller
                          name="proposal"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Proposal deck to partner is required" }}
                          render={({ field }) => (
                            <MuiFileInput
                              {...field}
                              onChange={(e) => handleFileUpload(e, "proposal", "proposalName", setPitchDeckValue, setPitchDeckName, setPitchDeckURL, 'file')}
                              value={pitchDeckValue}
                              variant="outlined"
                              fullWidth
                              label="Proposal deck to Partner *"
                              placeholder="Upload your Proposal deck to partner"
                              error={Boolean(errors.proposal && errors.proposal)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.pitchdeck && errors.pitchdeck.message}
                        </FormHelperText>
                        <div>
                          <span className="font-sm">
                            Maximum 5 mb allowed doc (Accepted Format PDF){" "}
                            <span className="text-danger">*</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="px-3">
                        <p className="mb-0">Pitch Deck </p>
                        <div className="row">
                          <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                            <Controller
                              name="proposal"
                              control={control}
                              defaultValue=""
                              rules={{ required: "Proposal deck to partner is required" }}
                              render={({ field }) => (
                                <Button
                                  component="label"
                                  variant="contained"
                                  {...field}
                                  fullWidth
                                  onChange={(e) => handleFileUpload(e, "proposal", "proposalName", setPitchDeckValue, setPitchDeckName, setPitchDeckURL, 'file')}
                                  startIcon={<CloudUploadIcon />}
                                  href="#file-upload"
                                >
                                  Change
                                  <VisuallyHiddenInput type="file" />
                                </Button>
                              )}
                            />
                            <div>
                              <span className="font-sm d-flex  mt-1 ">
                                Maximum 5 mb allowed doc (Accepted Format PDF){" "}
                                <span className="text-danger">*</span>
                              </span>
                            </div>
                          </Grid>
                          <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                            <Button
                              className="c-pointer ml-2"
                              size="medium"
                              fullWidth
                              variant="outlined"
                              onClick={() =>
                                handleClickOpen(
                                  getValues("proposal"),
                                  getValues("proposalUrl"),
                                )
                              }
                            >
                              Preview
                            </Button>
                          </Grid>
                        </div>
                      </div>
                    )} */}
                    {getValues('proposalUrl') ? (
                      <>
                        <div className="px-3">
                          <p className="mb-0">Proposal deck to Partner </p>
                          <div className="row">
                            <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                              <Controller
                                name="proposalName"
                                control={control}
                                defaultValue=""
                                rules={{ required: "pitchDeck File is required" }}
                                render={({ field }) => (
                                  <Button
                                    component="label"
                                    variant="contained"
                                    {...field}
                                    fullWidth
                                    onChange={(e) =>
                                      handleFileUpload(e, "proposal", "proposalName")
                                    }
                                    startIcon={<CloudUploadIcon />}
                                    href="#file-upload"
                                  >
                                    Change
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                )}
                              />
                            </Grid>
                            <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3">
                              <Button
                                className="c-pointer ml-2"
                                size="medium"
                                fullWidth
                                variant="outlined"
                                onClick={() =>
                                  handleClickOpen(
                                    getValues("proposal"),
                                    getValues("proposalUrl"),
                                  )
                                }
                              >
                                Preview
                              </Button>
                            </Grid>
                          </div>
                        </div>
                        <div>
                          <span className="font-sm d-flex  mt-1 ">
                            Maximum 5 mb allowed doc (Accepted Format PDF){" "}
                            <span className="text-danger">*</span>
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="form-group mt-2">
                        <Controller
                          name="proposalName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Proposal deck to Partner is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Proposal deck to Partner *"
                              placeholder="Upload your Proposal deck to Partner"
                              fullWidth
                              type="text"
                              {...field}
                              disabled
                              error={Boolean(errors.proposalName && errors.proposalName)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.proposalName && errors.proposalName.message}
                        </FormHelperText>
                        <div className='d-flex align-items-center w-100 my-3'>
                          <p className='font-sm'>Maximum 5 mb allowed doc <br />(Accepted Format PDF)</p>
                          <label className='ml-auto lbl_btn'
                            onChange={(e) => handleFileUpload(e, "proposal", "proposalName")}
                            href="#file-upload">
                            <img src='/images/admin/upload.png' className='mr-3' alt='' />
                            Upload File
                            <VisuallyHiddenInput type="file" />
                          </label>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <button className='next-btn' type="submit">Next</button>
                  </Grid>
                </Grid>
              </form>
              <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
                fileLable={fileLable}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default DocumentsServiceProvider
