import React, { useContext, useState } from 'react'
import InvestorEventFilters from '../InvestorEventFilters'
import { Button, FormControl, InputBase, InputLabel, MenuItem, Select, ToggleButton, styled } from '@mui/material'
import { FaRegCalendar } from "react-icons/fa6";
import { IoIosList } from "react-icons/io";
import CloseIcon from '@mui/icons-material/Close';
import DataContext from '../../../../../context/DataContext';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { useEffect } from 'react';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '24px',
    backgroundColor: 'rgb(255 255 255)',
    border: '1px solid #00000017',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#d9d9d9',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '16ch',
            '&:focus': {
                width: '18ch',
            },
        },
    },
}));

const InvMyEvents = () => {
    const { navigator, setSearchSupportInputValue, setSelectedSupportPeriod } = useContext(DataContext);

    const handleSearch = (e) => {
        setSearchSupportInputValue(e.target.value);
    };

    const [activemainTabs, setActiveMainTabs] = useState("secs-btn-1");
    const handleButtonClicks = (btnClass3) => {
        setActiveMainTabs(btnClass3);
    };

    const handleSelectPeriod = (value) => {
        setSelectedSupportPeriod(value)
    }

    const [currentDate, setCurrentDate] = useState(new Date());
    const [daysInMonth, setDaysInMonth] = useState([]);

    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const dates = [
        {
            date: 2,
            events: [
                {
                    title: "Event Investor Connect",
                    location: "StartupTN Office",
                    time: "11:00PM - 12:00PM",
                },
            ],
        },
        {
            date: 11,
            events: [
                {
                    title: "Session Future Of Fintech",
                    location: "Online Webinar",
                    time: "02:00PM - 04:00PM",
                },
            ],
        },
        {
            date: 14,
            events: [
                {
                    title: "Appointments With ABC Startup",
                    location: "Online Webinar",
                    time: "11-12PM",
                },
            ],
        },
    ];

    useEffect(() => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const daysArray = [];

        for (
            let i = 0;
            i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1);
            i++
        ) {
            daysArray.push(null);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }

        setDaysInMonth(daysArray);
    }, [currentDate]);

    const changeMonth = (offset) => {
        const newDate = new Date(
            currentDate.setMonth(currentDate.getMonth() + offset)
        );
        setCurrentDate(newDate);
    };

    return (
        <>
            <section>
                <div className='sec-clr-surya'>
                    <div className='sec-filters-event'>
                        <h5>My Events & Schedule</h5>
                        <Search>
                            <SearchIconWrapper>
                                <img src="/images/admin/search-black.png" alt="" />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Event"
                                inputProps={{ 'aria-label': 'Event' }}
                                onChange={handleSearch}
                                type="search"
                            />
                        </Search>
                        <div className="all-btn-bg">
                            <button
                                className={`secs-btn-1 ${activemainTabs === "secs-btn-1" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClicks("secs-btn-1")}
                            >
                                <span>
                                    <IoIosList className="lists-icons" />
                                </span>
                                List
                            </button>
                            <button
                                className={`secs-btn-2 ${activemainTabs === "secs-btn-2" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClicks("secs-btn-2")}
                            >
                                <span>
                                    <FaRegCalendar className="calander-icons" />
                                </span>
                                Calender View
                            </button>
                        </div>
                        <div>
                            <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 120 }}
                            >
                                <InputLabel id="dropdown-label">This Week</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    label="Select Option"
                                    variant="outlined"
                                    style={{ borderRadius: 10 }}
                                    onChange={(e) => handleSelectPeriod(e.target.value)}
                                >
                                    <MenuItem value={"All"}>All</MenuItem>
                                    <MenuItem value={"This Week"}>This Week</MenuItem>
                                    <MenuItem value={"This Month"}>This Month</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div >
                            <ToggleButton className="toggle-fil">{<img src="/images/filter icon.svg" alt="" />}</ToggleButton>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="calendar">
                    <div className="calendar-header">
                        {daysOfWeek.map((day) => (
                            <div key={day} className="calendar-header-day">
                                {day}
                            </div>
                        ))}
                    </div>
                    <div className="calendar-body">
                        {daysInMonth.map((day, index) => {
                            const dateEvents = dates.find((d) => d.date === day);
                            return (
                                <div key={index} className="calendar-day">
                                    {day && (
                                        <>
                                            {dateEvents &&
                                                dateEvents.events.map((event, eventIndex) => (
                                                    <div
                                                        key={eventIndex}
                                                        className={`calendar-event calendar-event-${event.title
                                                            .split(" ")[0]
                                                            .toLowerCase()}`}
                                                    >
                                                        <div className="event-title">{event.title}</div>
                                                        {event.location && (
                                                            <div className="event-location">{event.location}</div>
                                                        )}
                                                        <div className="event-time">{event.time}</div>
                                                    </div>
                                                ))}
                                            <div className="calendar-date">{day}</div>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="calendar-controls">
                        <Button className="year-btn" onClick={() => changeMonth(-1)}>
                            <FaChevronLeft />
                        </Button>
                        <span>
                            {currentDate.toLocaleDateString("en-US", {
                                month: "long",
                                year: "numeric",
                            })}
                        </span>
                        <Button className="year-btn" onClick={() => changeMonth(1)}>
                            <FaChevronRight />
                        </Button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InvMyEvents