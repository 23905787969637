import React from 'react'

const UserProfileReport = () => {

  return (


    <div className="report-modal">
      <div className="report-modal-full">
            <h6>Recommendations</h6>
            <p>Exclusively access by Investors and Core Team Members</p>
          </div>
          <div className="modal-box">
         <div className="report-title">
          <h6>HeadStart {`>`} Evaluator Name</h6>
          <span>Tanseed 6.0</span>
         </div>
         <div className="remark">
          <div className="green"></div>
          <span className="ml-2">Remark</span>
         </div>
         <p className="mt-2">This AI startup has shown impressive growth and innovation. Their machine learning algorithms are ahead of the competition, and their customer base is expanding rapidly. The founding team has a strong background in AI and a clear vision for scaling. We are confident in their potential for high returns.</p>
         </div>
         <div className="modal-box">
         <div className="report-title">
          <h6>100xVC</h6>
          <span>Investor Connect Program</span>
         </div>
         <div className="remark">
          <div className="red"></div>
          <span className="ml-2">Remark</span>
         </div>
         <p className="mt-2">While the technology is promising, the market adoption has been slower than expected. The team needs to refine their go-to-market strategy and focus on customer acquisition. We're concerned about the burn rate and recommend re-evaluating their spending plan.</p>
         </div>
         <div className="modal-box">
         <div className="report-title">
          <h6>Caspian Debt</h6>
          <span>Investor Connect Program</span>
         </div>
         <div className="remark">
          <div className="green"></div>
          <span className="ml-2">Remark</span>
         </div>
         <p className="mt-2">The telemedicine platform has seen exponential growth, especially post-pandemic. Their technology is reliable, and patient feedback is overwhelmingly positive. The team has a solid blend of medical and technical expertise. We see significant potential in expanding their services and market reach</p>
         </div>
      {/* <div className='text-center text-dark p-4'>
        
        <div className='text-center w-100'>
          <h5 className='mb-4'>No Recommendation Found</h5>
          <div>
            <img src="/images/tamilnadu_logo_high.svg" alt="tamilnadu_logo_high.svg" className='logo_dash img-fluid' />
          </div>
          <div className='mt-5'>
            <h3 className='title_p opa'>
            </h3>
          </div>
        </div>
      </div> */}
    </div>


  )
}

export default UserProfileReport
