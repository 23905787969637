import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import Navbar from "../navbar-page/Navbar";
import Footer from "../footer-page/Footer";

const Tender = () => {
    const navigator = useNavigate();

    const handleBackBtn = () => {
        navigator(`/`);
    };
    return (
        <>
            <Navbar />
            <div className="back-color">
                <div className="back-layer"></div>
                <div className="back-layer1"></div>

                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Container>
                            <div className="blog-details-bg">
                                <div className="image-container">
                                    <img
                                        src='/images/usernav.jpg'
                                        alt=""
                                        className="img-fluid blog-res-img"
                                    />
                                    <div className="overlay"></div>
                                </div>
                                <div className="tender-head">
                                    <h3>Tender</h3>
                                </div>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <div className="back-blog">
                    <div className="allticket-list">
                        <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                            <FaArrowLeft />
                            <span style={{ marginLeft: "5px" }}>Back</span>
                        </Button>
                    </div>
                </div>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="container">
                            <h4 className="my-5">Details regarding opening of tenders will be posted here. Kindly mark this page for future reference.</h4>
                        </div>
                        <h5 className="my-5 text-center">No new tenders available</h5>
                    </Grid>
                </Grid>

                <Footer />
            </div>
        </>
    )
}

export default Tender