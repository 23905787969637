import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DataContext from "../../../../context/DataContext";
import notifyService from "../../../../api/notifySerivce";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import AddIcon from "@mui/icons-material/Add";

const SupportTicketAdd = () => {
  const { VisuallyHiddenInput, setPageLoading, supportistApi, isWhitespace } =
    useContext(DataContext);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      attachments: [
        {
          id: 0,
          file: "",
          fileName: "",
          imageURL: "",
        },
      ],
    },
    mode:'onChange'
  });
  const {
    fields: photosFields,
    append: photosAppend,
    remove: photosRemove,
  } = useFieldArray({
    control,
    name: "attachments",
  });
  useDidMountEffect(() => {
    departmentApi();
  }, []);
  const [departmentId, setDepartmentId] = useState(0);
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [isDepartmentSelected, setIsDepartmentSelected] = useState(false);
  useDidMountEffect(() => {
    listProjectApi();
  }, [departmentId]);

  const [projects, setProjects] = useState([]);
  const listProjectApi = () => {
    setProjects([]);
    let req = {
      departmentId: departmentId,
    };
    apiService(`project/name/list`, req, "post")
      .then((result) => {
        if (result?.data?.projects) {
          setProjects(result.data.projects);
        }
      })
      .catch((err) => {});
  };
  const [department, setDepartment] = useState([]);
  const departmentApi = () => {
    var req = {};
    apiService("department/name/list", req, "post")
      .then((result) => {
        if (result?.data?.department) {
          setDepartment(result.data.department);
        }
      })
      .catch((err) => {});
  };
  const [selectedProject, setSelectedProject] = useState(null);
  const handleSelect = (event, value) => {
    setIsProjectSelected(true);
    setValue("project", value);
    clearErrors("department");
    clearErrors("project");
    setSelectedProject(value);
  };
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const handleSelectDepartment = (value) => {
    setIsDepartmentSelected(true);
    setValue("department", value);
    clearErrors("department");
    clearErrors("project");
    setSelectedDepartment(value);
    setDepartmentId(value.id);
    setValue("project", "");
    setSelectedProject(null);
  };

  const addPhotoDetails = () => {
    photosAppend({
      id: 0,
      file: "",
      fileName: "",
      imageURL: "",
    });
  };

  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    fileURL,
    arrayName,
    index
  ) => {
    //
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png"
        ) {
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            // setValue(filekey, ImageValue)
            // setValue(fileName, img_name)
            // setValue(fileURL, logourl1)
            if (arrayName !== "") {
              setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
              setValue(`${arrayName}.${index}.${fileName}`, img_name);
              setValue(`${arrayName}.${index}.${fileURL}`, logourl1);
            } else {
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileURL, logourl1);
            }
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
            addPhotoDetails();
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalid",
            "Please check your file format"
          );
        }
      }
    }
  };

  const deletePhotos = (index) => {
    photosRemove(index);
  };

  const onSubmit = (data) => {
    setPageLoading(true);
    const projectId = data.project.id;
    delete data.project;
    data.projectId = projectId;
    const departmentId = data.department.id;
    delete data.department;
    data.departmentId = departmentId;
    apiService("support/ticket/create", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result.data.responseStatus === "Success") {
          handleClear();
          supportistApi();
          setIsDepartmentSelected(false);
          setIsProjectSelected(false);
        }
      })
      .catch((err) => {
        console.log(err);
        
      });
  };

  const handleClear = () => {
    setValue("categoryName", "");
    setSelectedProject(null);
    setSelectedDepartment(null);
    photosRemove();
    reset();
  };

  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  return (
    <>
      <div className="ticketadd mb-5 py-4">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <p className="text-center mb-5">Create New Support Ticket</p>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12} className="pad-x mb-3">
              <FormLabel id="demo-row-radio-buttons-group-label">
                <span className="thick-blue">
                  Query Title <span className="text-danger">*</span>
                </span>
              </FormLabel>
              <Controller
                name="queryTitle"
                control={control}
                defaultValue=""
                rules={{
                  required: "Query Title is required",
                  validate: {
                    noWhitespace: (value) =>
                      !isWhitespace(value) || "Whitespace not allowed",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    placeholder="Enter Query Title"
                    fullWidth
                    type="text"
                    {...field}
                    error={Boolean(errors.queryTitle && errors.queryTitle)}
                  />
                )}
              />
              <FormHelperText className="text-danger">
                {errors.queryTitle && errors.queryTitle.message}
              </FormHelperText>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="pad-x mb-3">
              <FormLabel id="demo-row-radio-buttons-group-label">
                <span className="thick-blue">
                  Department <span className="text-danger">*</span>
                </span>
              </FormLabel>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="department"
                  control={control}
                  defaultValue=""
                  rules={{ required: !isProjectSelected ? "Department is required" : false }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      {...field}
                      value={selectedDepartment}
                      onChange={(event, value) => handleSelectDepartment(value)}
                      options={department}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Department"
                          error={Boolean(
                            errors.department && errors.department
                          )}
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText className="text-danger m-0">
                  {errors.department && errors.department.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="pad-x mb-3">
              <FormLabel id="demo-row-radio-buttons-group-label">
                <span className="thick-blue">
                  Project <span className="text-danger">{!isDepartmentSelected ? "*" : ""}</span>
                </span>
              </FormLabel>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="project"
                  control={control}
                  defaultValue=""
                  rules={{ required: !isDepartmentSelected ? "Project is required" : false }}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      disablePortal
                      {...field}
                      value={selectedProject}
                      onChange={handleSelect}
                      options={projects}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Project"
                          error={Boolean(errors.project && errors.project)}
                        />
                      )}
                    />
                  )}
                />
                <FormHelperText className="text-danger m-0">
                  {errors.project && errors.project.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="pad-x mb-3">
              <FormLabel id="demo-row-radio-buttons-group-label">
                <span className="thick-blue">
                  Query Description <span className="text-danger">*</span>
                </span>
              </FormLabel>
              <Controller
                name="queryDesc"
                control={control}
                defaultValue=""
                rules={{
                  required: "Query Description is required",
                  validate: {
                    noWhitespace: (value) =>
                      !isWhitespace(value) || "Whitespace not allowed",
                    maxLength: (value) =>
                      value.length <= 500 || "Max 500 characters",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    id="outlined-multiline-static"
                    placeholder="Enter Query Summary"
                    fullWidth
                    multiline
                    {...field}
                    rows={6}
                    defaultValue=""
                    error={Boolean(errors.queryDesc && errors.queryDesc)}
                  />
                )}
              />
              <FormHelperText className="text-danger">
                {errors.queryDesc && errors.queryDesc.message}
              </FormHelperText>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="pad-x mb-3">
              <div className="form-group m-0 ">
                <div className="d-flex align-items-center mb-3">
                  <FormLabel shrink="true" className="input_Lable">
                    <span className="thick-blue"> Attachments (Optional) </span>
                  </FormLabel>
                  {/* {photosFields.length < 5 && (
                    <Button className="ml-auto" onClick={addPhotoDetails}>
                      Add
                      <AddIcon />
                    </Button>
                  )} */}
                </div>
                {/* <div className="row">
                  <Controller
                    name="challengeImage"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Button
                        fullWidth
                        className="imgupload_div"
                        component="label"
                        onChange={(e) =>
                          handleFileUpload(
                            e,
                            "challengeImage",
                            "challengeImageName",
                            "challengeImageURL",
                            "",
                            ""
                          )
                        }
                      >
                        {getValues(`challengeImageName`) ? (
                          <div className="disPhotos">
                            <img
                              src={getValues(`challengeImageURL`)}
                              className="logo"
                              alt={`challengeImageName`}
                            />
                            <div className="hoverDelete">
                              <div className="center_display">
                                <DeleteForeverIcon
                                  onClick={() => deletePhotos()}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="camera_icon disPhotos">
                            <VisuallyHiddenInput type="file" />
                            <img
                              src="/images/Attachment.png"
                              style={{ width: "67%" }}
                              className="no_logo"
                              alt={`challengeImageName`}
                            />
                          </div>
                        )}
                      </Button>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.challengeImage && errors.challengeImage.message}
                  </FormHelperText>
                </div> */}
                <div className="d-flex">
                  {photosFields.map((field, index) => (
                    <div>
                      <Controller
                        name={`attachments.${index}.imageURL`}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: false,
                        }}
                        render={({ field }) => (
                          <>
                            <Button
                              {...field}
                              fullWidth
                              className="imgupload_div"
                              component="label"
                              size="small"
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "file",
                                  "fileName",
                                  "imageURL",
                                  "attachments",
                                  index
                                )
                              }
                            >
                              {getValues(`attachments.${index}.imageURL`) ? (
                                <>
                                  <div className="">
                                    <img
                                      src={getValues(
                                        `attachments.${index}.imageURL`
                                      )}
                                      className="vehicle_logo"
                                      alt={`attachments.${index}.fileName`}
                                      style={{
                                        width: "67px",
                                        height: "67px",
                                        objectFit: "contain",
                                        border: "1px solid gainsboro",
                                        borderRadius: " 5px",
                                      }}
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="disPhotos">
                                  <VisuallyHiddenInput type="file" />
                                  <img
                                    src="/images/Attachment.png"
                                    className="no_logo"
                                    alt={`attachments.${index}.fileName`}
                                    style={{
                                      width: "67px",
                                      objectFit: "contain",
                                    }}
                                  />
                                </div>
                              )}
                              <div></div>
                            </Button>
                            {getValues(`attachments.${index}.imageURL`) && (
                              <div className="text-center">
                                <DeleteForeverIcon
                                  color="error"
                                  onClick={() => deletePhotos(index)}
                                />
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className='create-surya'>
                <Button className='clear-btn' >
                  Clear
                </Button>
                <Button className='create-btn ml-3' type='submit' >
                  Create
                </Button>
              </div> */}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <center>
                <div className="">
                  <Button className="clear-btn" onClick={handleClear}>
                    Clear
                  </Button>
                  <Button className="create-btn ml-3" type="submit">
                    Create
                  </Button>
                </div>
              </center>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default SupportTicketAdd;
