import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Signuplayout from '../../auth-module/signup-details-page/Signuplayout'
// import ContactdetailsMentors from './team-info-page/ContactdetailsMentors';
// import TeamsizeMentors from './team-info-page/TeamsizeMentors';
// import FounderdetailsMentots from './team-info-page/FounderdetailsMentots';
import DocumentsMentors from './organization-info-page/DocumentsMentors';
import SociallinksMentors from './organization-info-page/SociallinksMentors';
import AddressMenteors from './organization-info-page/AddressMenteors';
import BasicinfoMentors from './organization-info-page/BasicinfoMentors';
import Quickbrief from '../../auth-module/signup-details-page/Quickbrief';
import MoreinfoMenteors from './organization-info-page/MoreinfoMenteors';
const Mentorsroutes = () => {
    return (
        <div>
            <Routes>
                <Route path='/mentors' element={<Signuplayout />}>
                    <Route path="/mentors" element={<Navigate replace to="/mentors/basicinfo" />} />
                    <Route path='/mentors/basicinfo' element={<BasicinfoMentors />} />
                    <Route path='/mentors/address' element={<AddressMenteors />} />
                    <Route path='/mentors/social-links' element={<SociallinksMentors />} />
                    <Route path='/mentors/documents' element={<DocumentsMentors />} />
                    <Route path='/mentors/moreinfo' element={<MoreinfoMenteors />} />
                    <Route path='/mentors/quick-brief' element={<Quickbrief />} />
                </Route>
            </Routes>
        </div>
    )
}

export default Mentorsroutes