// import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
// import DataContext from "../../context/DataContext";

const ResendTimer = ({ resent, seconds, setSeconds }) => {
    const [minutes, setMinutes] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);
    // const resendOTP = () => {
    //   setMinutes(0);
    //   setSeconds(15);
    //   setAnimation();
    // };

    return (
        <div
            className="card"
            style={{ border: "none", backgroundColor: "transparent" }}
        >
            <div className="countdown-text">    
                {seconds > 0 || minutes > 0 ? (
                    <p style={{ color: "#000000",fontSize:'14px' }}>
                        Time Remaining :{" "}
                        <span style={{ fontWeight: 500 }}>
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                    </p>
                ) : (
                    // Display if countdown timer reaches 0
                    <p>Didn’t receive the OTP? <span className='resend_span' onClick={resent}>Resend OTP</span></p>
                )}

                {/* Button to resend OTP */}

                {/* {seconds === 0 ? (
                <Button
                variant="outlined"
                  style={{
                    color: seconds > 0 || minutes > 0 ? "#fff" : "#fff",
                    background:"green",
                    marginTop:"20px"
                  }}
                  onClick={resent}
                >
                {t('signin.resendOtp')}
                </Button>
              ) : (
                ""
              )} */}
            </div>

            {/* Button to submit OTP */}
            {/* <button className="submit-btn">SUBMIT</button> */}
        </div>
    );
}

export default ResendTimer