import React from 'react'
import { MdOutlineMailOutline } from "react-icons/md";
import { LuPhone } from "react-icons/lu";

const SupportFooter = () => {
  return (
    <div className='support-footer'>
        
          <div className='footer-menues'>
            <div className="foot-msg">
            <p>For Additional Queries</p>
            </div>
            <div className="helpline-no">
              <p><span className='phn-icn mr-3'><LuPhone/></span>155343</p>
            </div>
            <div className="helpline-mail">

              <p><span className='mail-icn mr-3'><MdOutlineMailOutline/></span>tech@startuptn.in</p>
            </div>
          </div>
       
    </div>
  )
}

export default SupportFooter