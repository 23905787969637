import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Supplychain = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTn-sectorPage-Supplychain.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Supply Chain & Logistics</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <p>
                    The Unified Logistics Interface Platform is an initiative of
                    the Central Government to integrate data from various
                    sources under one umbrella to support improvement of
                    logistics efficiency and reduction of logistics costs. The
                    platform has the ability to promote Multi Modal Logistics
                    Transport/efficient operations with integration of digital
                    initiatives of various agencies (Freight Operations
                    Information System (FOIS), Port Community System (PCS), Air
                    Cargo Community System (ACCS), Indian Customs Electronic
                    Gateway (ICEGATE), etc). This platform also enables
                    interfaces that enable tracking and tracing concepts towards
                    improving logistics service and reliability.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Tamil Nadu State Logistics Policy 2023
                  </h5>
                  <br />
                  <p>
                    For the fiscal incentives provided under the Tamil Nadu
                    State Logistics Policy 2023, TIDCO will be responsible for
                    formulation of operational guidelines. For all investment
                    above Rs.50 crore, Tamil Nadu Guidance will be responsible
                    for proposal/application evaluation and recommendation to
                    the Industries Department for sanction of incentives. For
                    all investments up to Rs. 50 crore, the evaluation of
                    proposals and applications will be carried out by FaMe TN
                    and sanctioned by the Commissionerate of Industries and
                    Commerce
                  </p>
                  <br />
                  <p>
                    State Industries Promotion Corporation of Tamil Nadu Limited
                    (SIPCOT) will be the disbursal agency of incentives listed
                    out in this policy for investment category above Rs. 50
                    crore. For all investments up to Rs.50 crore, the Industries
                    Commissioner and Director of Industries and Commerce (ICDIC)
                    will be the responsible agency for disbursement of
                    incentives.
                  </p>
                  <br />
                  <p>
                    Tamil Nadu Guidance, as the designated nodal agency for
                    facilitating single window clearances, will also be the
                    nodal agency for the same service to logistics enterprises
                    in the State for all investments above Rs. 50 crore. The
                    single window facilitation for MSME segment will be
                    undertaken by FaMe TN, Commissionerate of Industries and
                    Commerce and District Industries Centre.
                  </p>
                  <br />
                  <ul>
                    <li>
                      GoTN will leverage the GIS layers of the State Master Plan
                      being prepared under the PM Gati Shakti National Master
                      Plan for enabling integrated planning and connectivity
                      enhancement.
                    </li>
                    <br />
                    <li>
                      It will encourage capacity expansion of existing ICDs/
                      CFSs/ AFSs/ FTWZs and development of new ICDs/ CFSs/ AFSs/
                      FTWZs, through provision of government land in accordance
                      with extant land allocation policy and increase in demand
                      at strategic locations in the State, as per the provisions
                      of Policy and Guidelines for setting up of Inland
                      Container Depots (ICDs), Container Freight Stations (CFSs)
                      and Air Freight Station (AFSs) by Government of India.
                    </li>
                    <br />
                    <li>
                      GoTN will identify unutilised factory sheds within the
                      existing industrial parks/estates/clusters in the State
                      and make them available for logistics activities, wherever
                      feasible.
                    </li>
                    <br />
                    <li>
                      It will undertake development of primary processing
                      centres in the State to cater to the requirements of
                      primary processing of horticulture produce. Further, it
                      will identify locations/clusters for development and
                      strengthening of cold chain infrastructure in the State.
                    </li>
                    <br />
                    <li>
                      It will assess and identify strategic coastal shipping
                      routes to encourage and promote inter-modal transport that
                      will result in cost effective services.
                    </li>
                    <br />
                    <li>
                      It will coordinate with the relevant agencies/airport
                      operators to improve quality of air cargo-related services
                      at air cargo complexes in Tamil Nadu.
                    </li>
                    <br />
                    <li>
                      It will engage with the Central Bureau of Indirect Taxes
                      and Customs (CBIC)/airport operators/port authorities and
                      operators on a continuous basis to take appropriate steps
                      for reducing the dwell time at air cargo complexes and
                      seaports in the State.
                    </li>
                    <br />
                    <li>
                      It will encourage Startups in the logistics sector to
                      avail funds and benefits within the Tamil Nadu Emerging
                      Sector Seed Fund and/or TANSEED under the Tamil Nadu
                      Startup and Innovation Policy.
                    </li>
                    <br />
                    <li>
                      It will develop a Logistics Labour Market Information
                      System that will be a repository of details of the
                      logistics sector workforce in the State, accredited by
                      relevant agencies for their skills.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Incentives – State logistics Policy 2023
                  </h5>
                  <br />
                  <ul>
                    <li>
                      Providing one-time reimbursement of 100% of the patent
                      registration fees in India to technology providers,
                      Startups and other business units developing
                      technology-enabled solutions for logistics efficiency
                      improvement, subject to a cap of Rs. 25,000 per patent.
                      The incentive will be available to enterprises having
                      registered offices in Tamil Nadu and to individual
                      entrepreneurs who are residents of the State.
                    </li>
                    <br />
                    <li>
                      One-time cash award of 50% of the technology adoption
                      cost, capped at Rs. 1 crore, to three applicants annually
                      for adopting modern technologies such as RFID, Global
                      Positioning System (GPS), IoT, Robotics & Automation,
                      Blockchain, Artificial Intelligence and Data Analytics,
                      among others. The incentive will be made available to
                      logistics service providers having registered offices in
                      Tamil Nadu. TIDCO will call out applications annually and
                      shortlist them to encourage entities towards technology
                      adoption.
                    </li>
                    <br />
                    <li>
                      One-time reimbursement of 100% of reefer vehicle
                      registration charges in the State, and road tax for one
                      year for reefer trucks, including retrofitting of capacity
                      15 MT, by logistics trucking companies and enterprises
                      registered in Tamil Nadu. The fiscal incentive will be
                      provided to the registration of first 500 reefer trucks in
                      the State during the policy period.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <table border="1" cellpadding="5" cellspacing="0">
                    <thead>
                      <tr>
                        <th>Parameters</th>
                        <th>Unit</th>
                        <th>Value</th>
                        <th>Year</th>
                        <th>Source</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Road Length</td>
                        <td>Km</td>
                        <td>NH = 6858, SH = 11275</td>
                        <td>2020-21</td>
                        <td>MoRTH/NHAI State Govt</td>
                      </tr>
                      <tr>
                        <td>Railway track</td>
                        <td>Track-km</td>
                        <td>6836</td>
                        <td>2019-20</td>
                        <td>MoR</td>
                      </tr>
                      <tr>
                        <td>Inland Container Depot (ICD)</td>
                        <td>nos.</td>
                        <td>11</td>
                        <td>2021-22</td>
                        <td>CBIC</td>
                      </tr>
                      <tr>
                        <td>Container Freight Station (CFS)</td>
                        <td>nos.</td>
                        <td>46</td>
                        <td>2020-21</td>
                        <td>State Govt</td>
                      </tr>
                      <tr>
                        <td>Private Freight Terminal (PFT)</td>
                        <td>nos.</td>
                        <td>1</td>
                        <td>2020-21</td>
                        <td>State Govt</td>
                      </tr>
                      <tr>
                        <td>Air cargo terminals</td>
                        <td>nos.</td>
                        <td>6</td>
                        <td>2020-21</td>
                        <td>AAI &amp; State Govt</td>
                      </tr>
                      <tr>
                        <td>Rail goods sheds</td>
                        <td>nos.</td>
                        <td>116</td>
                        <td>2020-21</td>
                        <td>State Govt</td>
                      </tr>
                      <tr>
                        <td>
                          Warehouse Capacity (incl. State, Central, private
                          owned) (MTPA)
                        </td>
                        <td>MT</td>
                        <td>1472098</td>
                        <td>2020-21</td>
                        <td>State Govt</td>
                      </tr>
                      <tr>
                        <td>Cold storage capacity</td>
                        <td>MT</td>
                        <td>400210</td>
                        <td>2020-21</td>
                        <td>State Govt</td>
                      </tr>
                      <tr>
                        <td>Port traffic</td>
                        <td>MT</td>
                        <td>108.6</td>
                        <td>2019-20</td>
                        <td>State Govt</td>
                      </tr>
                      <tr>
                        <td>Logistics training centres</td>
                        <td>nos.</td>
                        <td>1</td>
                        <td>2020-21</td>
                        <td>State Govt</td>
                      </tr>
                      <tr>
                        <td>No of individuals trained in logistics</td>
                        <td>nos.</td>
                        <td>2000</td>
                        <td>2020-21</td>
                        <td>State Govt</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p>
                    The State has awarded works (Package 1 for Chennai
                    peripheral ring road) to ease movement. NHAI is constructing
                    a two-tier elevated corridor to Chennai Port.
                  </p>
                  <br />
                  <ul>
                    <li>
                      NHAI is constructing Chennai-Bengaluru Industrial Corridor
                      and NH connecting Tambaram to Chengalpattu. In addition,
                      the State is also increasing first/last mile connectivity
                      of industrial clusters under CRIDP & IEF.
                    </li>
                    <br />
                    <li>
                      Vallandu bridge, Madurai-Thoothukudi Road and Thoothukudi
                      ring road are being developed by NHAI.
                    </li>
                    <br />
                    <li>
                      The State has initiated the development of Palayamkottai
                      road stretch under the Chennai-Kanniyakumari Industrial
                      Corridor Programme (SH 40).
                    </li>
                    <br />
                    <li>
                      The State is in the process of developing truck parking
                      facilities at industrial clusters of SIPCOT/TIDCO. The
                      truck parking facility at Nilakkottai and 13 other
                      industrial locations is operational from 2021.
                    </li>
                    <br />
                    <li>
                      The State is taking necessary measures towards development
                      of truck lay-bys along major State Highways under various
                      road sector programmes like TNRDC and TNRSP.
                    </li>
                    <br />
                    <li>
                      The State has initiated the upgradation and expansion of
                      Cuddalore minor port which shall enhance its capacity. The
                      State, jointly with central agencies (NHLML/NHAI), is
                      taking multiple initiatives towards development of
                      logistics parks at strategic locations. Development of
                      Chennai MMLP is under bid evaluation and DPR and site
                      identification for Coimbatore MMLP is under way.
                    </li>
                    <br />
                    <li>
                      The State is taking necessary steps towards development of
                      primary processing centres at strategic location under the
                      Tamil Nadu Supply Chain Management Project. Additional 20
                      locations have been identified for establishment of PPCs
                      under NABARD Rural Infrastructure Development Fund.
                    </li>
                    <br />
                    <li>
                      The cargo terminals in Tamil Nadu are offering services
                      round-the-clock. There are no restrictions on the number
                      of working days. The operation cycles are planned as per
                      the shipment and end user requirement.
                    </li>
                    <br />
                    <li>
                      The State has launched its flagship scheme Tamil Nadu Naan
                      Mudhalvan Scheme in March 2022 which covers upskilling
                      training courses related to the Logistics sector.
                    </li>
                    <br />
                    <li>
                      The State is promoting various skill development
                      programmes for the Logistics sector through TN Apex Skill
                      Development Centre (Logistics). In addition, the CII
                      Institute of Logistics and TVS Supply Chain Solutions are
                      also conducting various certified logistics skill courses
                      in the State.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Supplychain;
