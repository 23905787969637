import React from 'react'
import { Outlet } from 'react-router-dom'

const ServicePartnersLayout = () => {
  return (
    <div>
    <div><Outlet></Outlet></div>
  </div>
  )
}

export default ServicePartnersLayout