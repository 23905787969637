import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material';
import notifyService from '../../../../api/notifySerivce';
import { Controller, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import DataContext from '../../../../context/DataContext';
import apiService from '../../../../api/apiService';
const InvestorTeamAdd = () => {
  const { setPageLoading, VisuallyHiddenInput } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control,setValue,clearErrors, reset, formState: { errors } } = useForm({ values: formData });
  const [openModal, setOpenModal] = useState(false);
  const handleAddMembers = () => {
    setOpenModal(true);
    // if (comments) {
    //     setFormData({
    //         remarks: comments
    //     })
    // }
  }
  const [bannerImageValue, setBannerImageValue] = useState(null)
  const [bannerImageName, setBannerImageName] = useState(null)
  const [bannerImageURL, setBannerImageURL] = useState(null)
  let logoselecetdFile = ''
  const handleFileUpload = (event, filekey, fileName, setdata, setName, setUrl) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      if (logoselecetdFile) {
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type
        var imagedatatype = imagetype.split("/")
        var img_crt_type = imagedatatype[1]
        if (img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png") {
          setValue(filekey, "")
          setValue(fileName, "")
          setName('')
          setUrl('')
          if (event.target === undefined) {
            setdata(event)
          } else {
            setdata(event.target.files[0])
          }
          var fileValue = logoselecetdFile
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(',')
            var ImageValue = spl[1]
            var img_name = fileValue.name
            setUrl(logourl1)
            setValue(filekey, ImageValue)
            setValue(fileName, img_name)
            setName(img_name)
            clearErrors(filekey);
            clearErrors(fileName);
          }
        } else {
          notifyService('danger', 'File Format Invalided', 'Please check your file format')
        }
      }
    } else {

      // setValue(filekey, "")
      // setValue(fileName, "")
      // setName('')
      // setUrl('')
      // inputRef.current.value = '';
    }

  }
  const onSubmit = (data) => {
    // setPageLoading(true)
    // var req = {
    //   // id: id,
    //   remarks: data.remarks,
    // };
    // apiService('assign/save', req, "post").then((res) => {
    //   setPageLoading(false)
    //   if (res) {
    //     if (res.data.responseStatus === "Success") {
    //       // listApi()
    //       handleCloseModal()
    //       notifyService("success", "Success", "");
    //     }
    //   }
    // });
  }
  const onError = (data) => {
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  const handleCloseModal = () => {
    reset()
    setOpenModal(false);
  };
  const linkedInPattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9-]+\/?$/;
  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        sx={{
          textTransform: "none",
          background: "#0061D4",
          borderRadius: "10px",
        }}
        onClick={handleAddMembers}
      >
        Add Members
      </Button>
      <Dialog open={openModal} maxWidth="lg" onClose={handleCloseModal}>
        <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className='d-flex'>
            <DialogTitle>Add Team Member</DialogTitle>
            <div className='ml-auto'>
              <CloseIcon onClick={handleCloseModal} className='m-3 c-pointer' />
            </div>

          </div>
          <DialogContent>
            <div className='row' >
              <div className='col-lg-6 col-md-6 col-12 mb-4'>
                <div className='input_background'>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Name is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Full Name"
                        placeholder="Please Enter FullName"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.name && errors.name.message}
                  </FormHelperText>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12 mb-4'>
                <div className='input_background'>
                  <Controller
                    name={'designation'}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Designation required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Designation"
                        placeholder="Please Enter Designation"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.designation && errors.designation.message}
                  </FormHelperText>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12 mb-4'>
                <div className='input_background mb-4'>
                  <Controller
                    name="linkedIn"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: false,
                      // pattern: {
                      //   value: linkedInPattern,
                      //   message: "Invalid LinkedIn profile URL"
                      // }
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="LinkedIn"
                        placeholder="Please Enter LinkedIn Link"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.linkedIn && errors.linkedIn.message}
                  </FormHelperText>
                </div>
                <div className='input_background'>
                  <Controller
                    name={'twitter'}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Twitter required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Twitter"
                        placeholder="Please Enter Twitter Link"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.twitter && errors.twitter.message}
                  </FormHelperText>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12 mb-4'>
                <div className='input_background'>
                  <Controller
                    name="imageName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Profile Picture is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Upload Profile Picture"
                        placeholder="Upload Image"
                        fullWidth
                        type="text"
                        {...field}
                        disabled
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.imageName && errors.imageName.message}
                  </FormHelperText>
                  <div className='d-flex align-items-center w-100 my-3'>
                    <p className='font-sm'>recommended aspect ratio 4:3
                      max size upto 5mb</p>
                    <label className='ml-auto lbl_btn'
                      onChange={(e) => handleFileUpload(e, "image", "imageName", setBannerImageValue, setBannerImageName, setBannerImageURL)}
                      href="#file-upload">
                      <img src='/images/admin/upload.png' className='mr-3' alt='' />
                      Upload Image
                      <VisuallyHiddenInput type="file" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions >
            <div className='text-center mb-3 w-100'>
              <Button onClick={handleCloseModal} className="discard-btn">Close</Button>
              <Button
                type="submit" className="publish-button"
              >
                <span>Save</span>
              </Button>
            </div>

          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
export default InvestorTeamAdd