import React from 'react'
import MentorSessionFilters from '../MentorSessionFilters'
import { Box, Button, Dialog, FormControl, Grid, InputBase, InputLabel, MenuItem, Pagination, Paper, Select, Stack, ToggleButton, styled } from '@mui/material';
import { FaRegCalendar } from "react-icons/fa6";
import { IoIosList } from "react-icons/io";
import CloseIcon from '@mui/icons-material/Close';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { useContext } from 'react';
import DataContext from '../../../../../context/DataContext';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenRequestModal from './MenRequestModal';
import { useEffect } from 'react';
import apiService from '../../../../../api/apiService';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '24px',
    backgroundColor: 'rgb(255 255 255)',
    border: '1px solid #00000017',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#d9d9d9',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '19ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`custom-tabpanel-${index}`}
            aria-labelledby={`custom-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `custom-tab-${index}`,
        'aria-controls': `custom-tabpanel-${index}`,
    };
}




const MenRequestList = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const supportList = [
        {
            id:1,
            StartupName: "Aura Lab Pvt Ltd",
            ProfileRole: "Fintech | MVP Stage",
            Date: "09 May , 2024",
            Time: "11.00 A.M - 12:00 P.M",
        },
        {
            id:2,
            StartupName: "Aura Lab Pvt Ltd",
            ProfileRole: "Fintech | MVP Stage",
            Date: "09 May , 2024",
            Time: "11.00 A.M - 12:00 P.M",
        },
        {
            id:3,
            StartupName: "Aura Lab Pvt Ltd",
            ProfileRole: "Fintech | MVP Stage",
            Date: "09 May , 2024",
            Time: "11.00 A.M - 12:00 P.M",
        },
    ];

    const { navigator, setSearchSupportInputValue, setSelectedSupportPeriod,selectedSupportPeriod, setPageLoading, } = useContext(DataContext);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [daysInMonth, setDaysInMonth] = useState([]);

    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const dates = [
        {
            date: 2,
            events: [
                {
                    title: "Event Investor Connect",
                    location: "StartupTN Office",
                    time: "11:00PM - 12:00PM",
                },
            ],
        },
        {
            date: 11,
            events: [
                {
                    title: "Session Future Of Fintech",
                    location: "Online Webinar",
                    time: "02:00PM - 04:00PM",
                },
            ],
        },
        {
            date: 14,
            events: [
                {
                    title: "Appointments With ABC Startup",
                    location: "Online Webinar",
                    time: "11-12PM",
                },
            ],
        },
    ];

    useEffect(() => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const daysArray = [];

        for (
            let i = 0;
            i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1);
            i++
        ) {
            daysArray.push(null);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push(i);
        }

        setDaysInMonth(daysArray);
    }, [currentDate]);

    const changeMonth = (offset) => {
        const newDate = new Date(
            currentDate.setMonth(currentDate.getMonth() + offset)
        );
        setCurrentDate(newDate);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    };

 

    // const [activemainTabs, setActiveMainTabs] = useState("secs-btn-1");
    // const handleButtonClicks = (btnClass3) => {
    //     setActiveMainTabs(btnClass3);
    // };

    const [activemainTabs, setActiveMainTabs] = useState(0);

    const handleButtonClicks = (newValue) => {
        setActiveMainTabs(newValue);
    };

    const [selectedPeriod, setSelectedPeriod] = useState("All");
    const handleSelectPeriod = (value) => {
      setSelectedPeriod(value);
    };
    const handleSearch = (e) => {
        setSearchInputValue(e.target.value);
      };
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchInputValue, setSearchInputValue] = useState("");
    useDidMountEffect(() => {
        requirestList();
      }, [page, selectedSupportPeriod, searchInputValue, selectedPeriod]);
      const [sessionLists, setSessionLists] = useState([]);
      const [sessionval, setSessionval] = useState([]);
      var id = localStorage.getItem("id");
      const requirestList = () => {
        setPageLoading(true);
        var data = {
          listSize: 6,
          pageNumber: page,
          userId: id,
          searchString: searchInputValue,
          period: selectedPeriod,
        };
        apiService("session/request/list", data, "post")
          .then((result) => {
            setPageLoading(false);
            if (result) {
              if (result.data.responsemodelList) {
                setSessionLists(result.data.responsemodelList);
                setTotalPage(result.data.totalPages);
              }
              if (result.data.responsemodelList) {
                setSessionval(result.data.responsemodelList);
              }
            }
          })
          .catch((err) => {});
      };
      const handleRequerstDelete = (id) => {
        
        setPageLoading(true)
        apiService(`session/request/approve?id=${id}&status=0`, "", "get")
          .then((result) => {
            setPageLoading(false)
            if (result && result.data) {
              var data = result.data;
              requirestList();
            }
          })
          .catch((err) => { });
      };
    return (
        <>
            <MentorSessionFilters name={'request'} />

            <section>
                <div className='sec-clr-surya'>
                    <div className='sec-filters-event'>
                        <h5>Requests</h5>
                        <Search>
                            <SearchIconWrapper>
                                <img src="/images/admin/search-black.png" alt="" />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search Sessions"
                                inputProps={{ 'aria-label': 'Search Sessions' }}
                                onChange={handleSearch}
                                type="search"
                            />
                        </Search>
                        {/* <div className="all-btn-bg">
                            <button
                                className={`secs-btn-1 ${activemainTabs === "secs-btn-1" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClicks("secs-btn-1")}
                            >
                                <span>
                                    <IoIosList className="lists-icons" />
                                </span>
                                List
                            </button>
                            <button
                                className={`secs-btn-2 ${activemainTabs === "secs-btn-2" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClicks("secs-btn-2")}
                            >
                                <span>
                                    <FaRegCalendar className="calander-icons" />
                                </span>
                                Calender View
                            </button>
                        </div> */}
                        <div className="all-btn-bg">
                            <Tabs
                                value={activemainTabs}
                                onChange={(event, newValue) => setActiveMainTabs(newValue)}
                                aria-label="custom tabs example"
                            >
                                <Tab className='tab-clr'
                                    value={0}
                                    // label="List"
                                    // className={`secs-btn-1 ${activemainTabs === 0 ? "active" : ""}`}
                                    label={
                                        <div className={`secs-btn-1 ${activemainTabs === 0 ? "active" : ""}`}>
                                            <IoIosList className="lists-icons" />
                                            List
                                        </div>
                                    }
                                    onClick={() => handleButtonClicks(0)}
                                />
                                <Tab className='tab-clr'
                                    value={1}
                                    // label="Calendar View"
                                    // className={`secs-btn-2 ${activemainTabs === 1 ? "active" : ""}`}
                                    label={
                                        <div className={`secs-btn-2 ${activemainTabs === 1 ? "active" : ""}`}>
                                            <FaRegCalendar className="calander-icons" />
                                            Calendar View
                                        </div>
                                    }
                                    onClick={() => handleButtonClicks(1)}
                                />
                            </Tabs>
                        </div>
                        <div>
                            <FormControl
                                variant="outlined"
                                size="small"
                                sx={{ width: 120 }}
                            >
                                <InputLabel id="dropdown-label">This Week</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    label="Select Option"
                                    variant="outlined"
                                    value={selectedPeriod}
                                    style={{ borderRadius: 10 }}
                                    onChange={(e) => handleSelectPeriod(e.target.value)}
                                >
                                    <MenuItem value={"All"}>All</MenuItem>
                                    <MenuItem value={"This Week"}>This Week</MenuItem>
                                    <MenuItem value={"This Month"}>This Month</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div >
                            <ToggleButton className="toggle-fil">{<img src="/images/filter icon.svg" alt="" />}</ToggleButton>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <CustomTabPanel value={activemainTabs} index={0}>
                    <section>
                        <div className='mt-4'>
                            <Grid container spacing={3}>
                                {sessionLists && sessionLists.map((list, i) => (
                                    <Grid item xs={12} key={i}>
                                        <Paper sx={{ padding: '20px', borderRadius: '20px', position: 'relative' }}>
                                            <Box className='mentor-req'>
                                                <Box>
                                                    <span className="list-1-sur">Aura Lab Pvt Ltd</span>
                                                    <div>
                                                        <span className="list-ran-sur">Fintech | MVP Stage</span>
                                                    </div>
                                                </Box>
                                                <Box>
                                                    <div className="items">
                                                        <div className="loc" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src="/images/location_svg.png" alt="Location" style={{ marginRight: '8px' }} />
                                                            <span className="list-ran1">{list.showDate}</span>
                                                        </div>
                                                        <div className="clo" style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                                            <img src="/images/clock-check.png" alt="Clock" style={{ marginRight: '8px' }} />
                                                            <span className="list-ran1">{list.showStartTime} - {list.showEndTime}</span>
                                                        </div>
                                                    </div>
                                                </Box>
                                                <Box>
                                                    <div className="buttons">
                                                        <Button className="dec" onClick={() => handleRequerstDelete(list.id)}>Decline</Button>
                                                        <Button className="availability" onClick={handleOpen}>Choose Availability</Button>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {totalPage > 1 && (
                    <div className="event-pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPage}
                          page={page}
                          onChange={handleChange}
                        />
                      </Stack>
                    </div>
                  )}
                </Grid>
                        </div>
                    </section>
                </CustomTabPanel>
                <CustomTabPanel value={activemainTabs} index={1} >
                    <section>
                        <div className="calendar">
                            <div className="calendar-header">
                                {daysOfWeek.map((day) => (
                                    <div key={day} className="calendar-header-day">
                                        {day}
                                    </div>
                                ))}
                            </div>
                            <div className="calendar-body">
                                {daysInMonth.map((day, index) => {
                                    const dateEvents = dates.find((d) => d.date === day);
                                    return (
                                        <div key={index} className="calendar-day">
                                            {day && (
                                                <>
                                                    {dateEvents &&
                                                        dateEvents.events.map((event, eventIndex) => (
                                                            <div
                                                                key={eventIndex}
                                                                className={`calendar-event calendar-event-${event.title
                                                                    .split(" ")[0]
                                                                    .toLowerCase()}`}
                                                            >
                                                                <div className="event-title">{event.title}</div>
                                                                {event.location && (
                                                                    <div className="event-location">{event.location}</div>
                                                                )}
                                                                <div className="event-time">{event.time}</div>
                                                            </div>
                                                        ))}
                                                    <div className="calendar-date">{day}</div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="calendar-controls">
                                <Button className="year-btn" onClick={() => changeMonth(-1)}>
                                    <FaChevronLeft />
                                </Button>
                                <span>
                                    {currentDate.toLocaleDateString("en-US", {
                                        month: "long",
                                        year: "numeric",
                                    })}
                                </span>
                                <Button className="year-btn" onClick={() => changeMonth(1)}>
                                    <FaChevronRight />
                                </Button>
                            </div>
                        </div>
                    </section>
                </CustomTabPanel>
            </section>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                sx={{
                    '& .MuiPaper-root': {
                        p: 4,
                        borderRadius: 3
                    }
                }}>
                <MenRequestModal open={open} onClose={handleClose} />
            </Dialog>
        </>
    )
}

export default MenRequestList