import { Button, Card, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const InitiativeTab = () => {
  const navigate = useNavigate();
  const cardData = [
    {
      id: 1,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd1.png",
      title: "Management Strategy",
    },
    {
      id: 2,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd2.png",
      title: "Financial ",
    },
    {
      id: 3,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd3.png",
      title: "Financial ",
    },
    {
      id: 4,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd4.png",
      title: "Financial ",
    },
    {
      id: 5,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd5.png",
      title: "Financial ",
    },
    {
      id: 6,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd6.png",
      title: "Financial ",
    },
    {
      id: 7,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd7.png",
      title: "Financial ",
    },
    {
      id: 8,
      imag: "/images/tncatayst_logos/tn-cat-blu-crd8.png",
      title: "Financial ",
    },
  ];

  const handlenow = () => {
    navigate('/login')
  }

  const cardItems = [
    {
      heading: "Scale-Up Incubators",
      descr:
        "Scaleup Incubator equips Tamil Nadu Incubation Managers with strategies for incubation management, sustainability plans, TNIMM, and offers expert mentoring and international ecosystem visits.",
    },
    {
      heading: "IM Hustle",
      descr:
        "IM Hustle fosters peer learning and networking among Incubation Managers, enhancing skills, motivation, and cross-sector knowledge through discussions and feedback.",
    },
    {
      heading: "IM Hustle",
      descr:
        "StartupTN's Virtual Help Desk supports Tamil Nadu Incubators with 1:1 virtual meetings, fostering an incubation-friendly ecosystem.",
    },
    {
      heading: "IM-Podcast",
      descr:
        "The Incubation Managers Podcast showcases Tamil Nadu incubators, offering insights from experts to empower and inspire incubation managers and accelerators.",
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const numCardsPerPage = 4;
  const totalCards = cardData.length;

  const nextSlide = () => {
    const newIndex = currentIndex + numCardsPerPage;
    if (newIndex <= cardData.length - 1) {
      setCurrentIndex(newIndex);
    }
  };

  const prevSlide = () => {
    const newIndex = currentIndex - numCardsPerPage;
    if (newIndex >= 0) {
      setCurrentIndex(newIndex);
    }
  };
  return (
    <div>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={5}
          className="mt-5"
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="left-side-smartcardPage mt-5">
              <h4>What is TNIMM Model ?</h4>
              <p>
                StartupTN has come up with an initiative to assess business
                incubators across the State by meticulously identifying various
                parameters and criteria to promote incubators systematically by
                giving the incubators in the State with maturity levels by
                following a Maturity Model. Maturity Models are considered
                effective in assessing learning and knowledge organisations for
                various levels. With an objective to develop a framework for
                assessing incubators, a Panel of Experts with rich experience as
                Heads of Incubators, Entrepreneurs, Mentors and Investors was
                created.
              </p>
              <p>
                The panel along with the StartupTN team has developed the Tamil
                Nadu Maturity Model framework to assess the maturity level of
                incubators. This framework was developed based on inputs from
                these experts as well as by studying similar models used for
                incubators in India and abroad
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="smart-crd-img">
              <img
                className="smartcrdimag img-fluid"
                src={"/images/book-res.png "}
                alt="TNCatalyst"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      <section class="assessment-section mt-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12" style={{ width: "70%" }}>
              <div class="text-center home-title mb-3">
                <h1>TNIMM Assessment Domains</h1>
              </div>
              {/* <div class="container mt-5">
                <div id="#widgetsContent" class="initive-card-container middle">
                  {cardData &&
                    cardData
                      .slice(currentIndex, currentIndex + numCardsPerPage)
                      .map((a, i) => (
                        <div
                          class="col-lg-3 col-md-4 col-sm-6 initive-card "
                          key={i}
                        >
                          <div class="initive-card-number">{a.id}</div>
                          <img class="asscard-img" src={a.imag} alt="" />

                          <p class="initive-card-cont" align="center">
                            {a.title}
                          </p>
                        </div>
                      ))}
                </div>
              </div> */}
              <div className="crd-containers mt-5">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={5}
              >
                {cardData &&
                  cardData
                    .slice(currentIndex, currentIndex + numCardsPerPage)
                    .map((a, i) => (
                      <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <div className="incu-card-out">
                        <div class="initive-card-numbers ">{a.id}</div>
                        <div className="text-center">
                          <img class="asscard-imgs mt-2" src={a.imag} alt="" />
                          </div>
                          <p class="initive-card-conts mt-5" align="center">
                            {a.title}
                          </p>
                        </div>
                      </Grid>
                    ))}
              </Grid>
              </div>
            </div>
          </div>
        </div>

        <div className="  arrows-init-tab mt-5">
          {currentIndex > 0 && (
            <a onClick={prevSlide}>
              <img
                src="/images/tncatayst_logos/Arrow -le.png "
                alt="Previous"
                className="prev-arrws"
              />
            </a>
          )}
          {currentIndex + numCardsPerPage < totalCards && (
            <a onClick={nextSlide}>
              <img
                src="/images/tncatayst_logos/Arrow -ri.png"
                alt="Next"
                className="prev-arrws"
              />
            </a>
          )}
        </div>
      </section>
      <div className="join-us-now-sec">
        <Container>
          <h3 className="disc-ini">Discover Other Iniatives</h3>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={5}
          >
            {cardItems &&
              cardItems.map((a, i) => (
                <Grid item xs={12} sm={12} md={6} lg={3} xl={3} key={i}>
                  <div className="resource-card-divs-init-tab-tncat text-center">
                    <div className="layer-crd-back-img-Tncat">
                      <div className="resource-paras mt-2">
                        <h6 className="tn-initab-h6">{a.heading}</h6>
                        <p className="tn-initab-p">{a.descr}</p>
                      </div>
                    </div>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        className="hustlers-crd-actions"
                        // onClick={() =>
                        //   handleKnowMoreClick(service.smartCardId)
                        // }
                      >
                        Join Now
                      </Button>
                    </div>
                  </div>
                </Grid>
              ))}
          </Grid>
        </Container>
      </div>
      <div className="why-resource-sec mt-5">
        <div className="why-sm-title">
          <h3>
            <span> TamilNadu Startup Incubators Summit</span>
          </h3>
        </div>
        <div className="why-sm-paras">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="why-sm-texts text-center">
                  <div className="why-sm-icns">
                    <img
                      src={"/images/tncatayst_logos/facilate-knowl.png "}
                      alt="Acess Key"
                    />
                  </div>
                  <div className="why-sm-det">
                    <h6>Facilitate Knowledge Exchange</h6>
                    <p className="text-justify">
                      Engage in thought-provoking discussions, sharing best
                      practices, and exploring innovative strategies to enhance
                      incubation programs
                    </p>
                  </div>
                </div>
              </Grid>
              {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="why-sm-texts text-center">
                  <div className="why-sm-icns">
                    <img
                      src={"/images/tncatayst_logos/Networking.png "}
                      alt="comprehensive data"
                    />
                  </div>
                  <div className="why-sm-det">
                    <h6>Networking Opportunities</h6>
                    <p className="text-justify">
                      Connect with a diverse community of incubation managers,
                      fostering collaboration and the exchange of ideas.
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="why-sm-texts1 text-center">
                  <div className="why-sm-icns">
                    <img
                      src={"/images/tncatayst_logos/insights.png "}
                      alt="exclusive events"
                    />
                  </div>
                  <div className="why-sm-det">
                    <h6>Insights from Experts</h6>
                    <p className="text-justify">
                      Gain valuable insights from keynote speakers, successful
                      entrepreneurs, and influencers shaping the future of
                      startup ecosystems.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div className="join-us-now-sec">
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="join-us-now-box">
                <div className="join-us-now-content">
                  <h3>Let's Get Started</h3>
                  <p>MentorTN</p>
                  <p>Right Mentor for the right founder!</p>
                  <Button
                    variant="contained"
                    size="large"
                    className="join-now-btn"
                    onClick={handlenow}
                  >
                    Join us Now
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default InitiativeTab;
