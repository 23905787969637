import { FormControl, MenuItem, Select, styled, InputBase, ToggleButton, InputLabel } from '@mui/material'
import React, { useState } from 'react'

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "24px",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid #00000017",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#d9d9d9",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "25ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

const HistoryFilter = () => {
    const [searchInputValue, setSearchInputValue] = useState("");
    const [selectedPeriod, setSelectedPeriod] = useState("All");

    const handleSearch = (e) => {
        setSearchInputValue(e.target.value);
    };
    const handleSelectPeriod = (value) => {
        setSelectedPeriod(value);
      };
    return (
        <div className='filter-sections-his'>
            <div className="search-div">
                <Search>
                    <SearchIconWrapper>
                        <img src="/images/admin/search-black.png" alt="" />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search "
                        inputProps={{ "aria-label": "search" }}
                        onChange={handleSearch}
                        type="search"
                    />
                </Search>
            </div>
            <div>
                <FormControl variant="outlined" size="small" sx={{ width: 120, marginLeft: '8px' }}>
                <InputLabel id="dropdown-label">Duration</InputLabel>
                    <Select
                        labelId="dropdown-label"
                        label="Select Option"
                          value={selectedPeriod}
                        variant="outlined"
                        style={{ borderRadius: 10 }}
                      onChange={(e) => handleSelectPeriod(e.target.value)}
                    >
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={"This Week"}>This Week</MenuItem>
                        <MenuItem value={"This Month"}>This Month</MenuItem>
                    </Select>
                </FormControl>
                <ToggleButton className="toggle-fil ml-3" >
                    {<img src="/images/filter icon.svg" alt="" />}
                </ToggleButton>

            </div>

        </div>
    )
}

export default HistoryFilter