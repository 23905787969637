import React from 'react'

const Norecordsfound = ({ name }) => {
    return (
        <div className='text-center w-100 m-5 nodatacss'>
           
            {/* <div>
                <img src="/images/tamilnadu_logo_high.svg" alt="tamilnadu_logo_high.svg" className='logo_dash img-fluid' />
            </div> */}
            <div className='mt-5'>
                <img src="/images/oneTn/black_oneTn.png" alt="OneTN_Logo"/>
            </div>
            <h5 className='mb-4'>{name}</h5>
        </div>
    )
}

export default Norecordsfound