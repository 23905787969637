import React, { useState } from 'react'
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import dayjs from "dayjs";
import { Button, Divider, Menu, ToggleButton } from '@mui/material';
const CustomDateRangePicker = ({setStartDate,setEndDate}) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenDatePicker = (event) => {
        setAnchorElUser(event.currentTarget);        
    };
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    
    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
    };
    const handleClear = () => {
        setSelectionRange({
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        });
        setAnchorElUser(null);
        setStartDate("");
        setEndDate("");
    };
    const handleApply = () => {
        if (selectionRange.startDate && selectionRange.endDate) {
            const formattedStartDate = dayjs(selectionRange.startDate).format("YYYY-MM-DD");
            const formattedEndDate = dayjs(selectionRange.endDate).format("YYYY-MM-DD");
            if (formattedStartDate === formattedEndDate) {
                setStartDate(formattedStartDate);
                setEndDate("");
            } else {
                setStartDate(formattedStartDate);
                setEndDate(formattedEndDate);
            }
        }
        setAnchorElUser(null);
    };
    return (
        <>
            <ToggleButton className="toggle-eve" onClick={handleOpenDatePicker}>{<img src="/images/event-calender.svg" alt="" />}</ToggleButton>
            <Menu sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleClear}
            >
                <div className="date-div">
                    <DateRange onChange={handleSelect} ranges={[selectionRange]} rangeColors={["#FFB525"]} className="calender-divs" />
                    <Divider />
                    <div className="act-btn pr-3">
                        <Button onClick={handleClear} size="small" className="clear-date"> Clear </Button>
                        <Button onClick={handleApply} variant="contained" size="small" className="appl-btn" > Apply </Button>
                    </div>
                </div>
            </Menu>
        </>
    )
}

export default CustomDateRangePicker