import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const StartupThamizha = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`Funding  >  Startup Thamizha  `}</span>
                  <h1 className="banner-text-gradient">Startup Thamizha </h1>
                  <h6 className="fund-title">Spotlight on Innovation!</h6>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info1">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info1"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage1">
                <h4>What is Startup Thamizha?</h4>
                <p>
                  Step into the electrifying world of Startup Thamizha, where
                  dreams are realized, and the future of business is shaped.
                  This high-staked thrilling TV show brings together visionary
                  entrepreneurs and astute investors in a battle of wits,
                  innovation, and ambition.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag-mix-blend img-fluid"
                  src={"/images/thamizha.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
          {/* <div className="smartcrd-details-div ">
            <div className="">
              <div className="">
                <img
                  src="/images/TANSEED 6.0.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className=""></div>
            </div>
          </div> */}
        </Container>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Startup Thamizha</h4>
                    <p>Spotlight on Innovation!</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default StartupThamizha;
