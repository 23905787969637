import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const InvMentoringAll = () => {

  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState([]);

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const dates = [
    {
      date: 2,
      events: [
        {
          title: "Event Investor Connect",
          location: "StartupTN Office",
          time: "11:00PM - 12:00PM",
        },
      ],
    },
    {
      date: 11,
      events: [
        {
          title: "Session Future Of Fintech",
          location: "Online Webinar",
          time: "02:00PM - 04:00PM",
        },
      ],
    },
    {
      date: 14,
      events: [
        {
          title: "Appointments With ABC Startup",
          location: "Online Webinar",
          time: "11-12PM",
        },
      ],
    },
  ];

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const daysArray = [];

    for (
      let i = 0;
      i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1);
      i++
    ) {
      daysArray.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setDaysInMonth(daysArray);
  }, [currentDate]);

  const changeMonth = (offset) => {
    const newDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + offset)
    );
    setCurrentDate(newDate);
  };

  return (
    <>
      <section>
        <div className="calendar">
          <div className="calendar-header">
            {daysOfWeek.map((day) => (
              <div key={day} className="calendar-header-day">
                {day}
              </div>
            ))}
          </div>
          <div className="calendar-body">
            {daysInMonth.map((day, index) => {
              const dateEvents = dates.find((d) => d.date === day);
              return (
                <div key={index} className="calendar-day">
                  {day && (
                    <>
                      {dateEvents &&
                        dateEvents.events.map((event, eventIndex) => (
                          <div
                            key={eventIndex}
                            className={`calendar-event calendar-event-${event.title
                              .split(" ")[0]
                              .toLowerCase()}`}
                          >
                            <div className="event-title">{event.title}</div>
                            {event.location && (
                              <div className="event-location">{event.location}</div>
                            )}
                            <div className="event-time">{event.time}</div>
                          </div>
                        ))}
                      <div className="calendar-date">{day}</div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <div className="calendar-controls">
            <Button className="year-btn" onClick={() => changeMonth(-1)}>
              <FaChevronLeft />
            </Button>
            <span>
              {currentDate.toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </span>
            <Button className="year-btn" onClick={() => changeMonth(1)}>
              <FaChevronRight />
            </Button>
          </div>
        </div>
      </section>
    </>
  )
}

export default InvMentoringAll