import React, { useState } from 'react'
import InvestorEventFilters from '../../InvestorEventFilters'
import { NavLink, Outlet } from 'react-router-dom'
import { FaRegCalendar } from "react-icons/fa6";
import { IoIosList } from "react-icons/io";
import { TabContext } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Tabs, TextField } from '@mui/material';
import InvCreateSessionsModal from '../InvCreateSessionsModal';

const InvMentoringSessions = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    };

    const [activemainTabs, setActiveMainTabs] = useState("secs-btn-1");
    const handleButtonClicks = (btnClass3) => {
        setActiveMainTabs(btnClass3);
    };

    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div>
            <div className='sec-clr-surya'>
                <div className='sec-filters-mentoring'>
                    <TabContext value={value} className="tab-div">
                        <div className="inv-name-tab-surya">
                            <Box className="inv-tabs-boxs-surya">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    className='scroll-inv-tabs'
                                >
                                    <NavLink
                                        to={"/investor/event-session/mentoring/all"}
                                        className="profile-tab-sty-sur"
                                    >
                                        <h5>All Mentoring</h5>
                                    </NavLink>
                                    <Divider orientation='vertical' flexItem />
                                    <NavLink
                                        to={"/investor/event-session/mentoring/requested"}
                                        className="profile-tab-sty-sur"
                                    >
                                        <h5>Requests
                                            <Badge color="error" badgeContent={10} className='ml-4'>
                                            </Badge>
                                        </h5>
                                    </NavLink>
                                </Tabs>
                            </Box>
                        </div>
                    </TabContext>

                    <div className="frt-fil">
                        <div className="all-btn-bg">
                            <button
                                className={`secs-btn-1 ${activemainTabs === "secs-btn-1" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClicks("secs-btn-1")}
                            >
                                <span>
                                    <IoIosList className="lists-icons" />
                                </span>
                                List
                            </button>
                            <button
                                className={`secs-btn-2 ${activemainTabs === "secs-btn-2" ? "active" : ""
                                    }`}
                                onClick={() => handleButtonClicks("secs-btn-2")}
                            >
                                <span>
                                    <FaRegCalendar className="calander-icons" />
                                </span>
                                Calender View
                            </button>
                        </div>
                    </div>
                    <button className='invest-appiont-btn' onClick={handleOpen}>Create Session</button>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="md"
                    fullWidth
                    sx={{
                        '& .MuiPaper-root': {
                            p: 4,
                            borderRadius: 3
                        }
                    }}
                >
                    <InvCreateSessionsModal open={open} onClose={handleClose} />
                </Dialog>
            </div>
            <Outlet></Outlet>
        </div>
    )
}

export default InvMentoringSessions