import React, { useContext, useEffect, useState } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'
import { Button, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DataContext from '../../../context/DataContext';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import notifyService from '../../../api/notifySerivce';

const Profilerole = () => {
  const { type } = useParams()
  const { navigator } = useContext(DataContext)
  const { handleSubmit, control, watch, setValue, clearErrors, formState: { errors }, } = useForm({});
  const [data, setData] = useState({})
  const [roleDetails, setSoleDetails] = useState([])
  useDidMountEffect(() => {
    Aos.init();
    profileGet()
  }, [])
  const profileGet = () => {
    axios.get('/json/profiledetails.json', '').then((result) => {
      if (result.data) {

        const values = result.data.find(name => name.profileName === type);

        setData(values)
        setSoleDetails(values.role)

      }
    }).catch((err) => {

    });
  }
  const handleprofile = () => {
    navigator('/profile')
  }

  const [selectedRole, setSelectedRole] = useState("");
  const handleBoxClick = (role) => {
    setSelectedRole(role);
    setValue(role);
    localStorage.setItem('role', role)

  };
  const handleSignupPage = () => {
    if (selectedRole !== '') {
      navigator(`/sign-up`)
    } else {
      notifyService('danger', `Select any ${data.profileType} role`)
    }

  }

  // const [value, setValue] = useState('');

  // const handleChange = (index) => (event) => {

  //   handleBoxClick(data.role[index], event.target.value);
  // };

  return (
    <div className='profile-typebg'>
      <div data-aos="fade-right" data-aos-delay="200" className='container'>
        <div className='row m-0'>
          <div className='col-lg-3 col-md-3 pl-0 py-2 line'>
            <h5 className='gry-clr  mb-4'>Select your Profile Type</h5>
            <div className='box-blue'>
              <KeyboardBackspaceIcon onClick={handleprofile} />
              <h5 className='font-weight-bold text-uppercase'>{data.profileName}</h5>
              {/* <h5 className='font-weight-bold '>{data.profileType}</h5> */}
            </div>
          </div>
          <div className='col-lg-9 col-md-9 py-2'>
            <h5 className='gry-clr' style={{position:'relative'}}>Select your Role</h5>
            <div className='prof-select-btn-up text-right'>
              <button className='next-btn' onClick={handleSignupPage}>Continue</button>
            </div>
            <div className='vector-bg1 row'>
              {
                roleDetails.length > 0 && (
                  roleDetails.map((data, index) => (
                    <div className='col-lg-4 col-md-6 col-12 mt-3'>
                      <div className={selectedRole === data.roleValue ? 'box activeRolebtn' : 'box'} onClick={() => handleBoxClick(data.roleValue)}>
                        <div className='radio-btn'>
                          <FormControl>
                            <RadioGroup value={selectedRole === data.roleValue ? data.roleValue : ''}>
                              <FormControlLabel value={data.roleValue} control={<Radio />} />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <center><p className='radiotext font-weight-bold'>{data.roleName}</p></center>
                      </div>
                    </div>
                  ))
                )
              }
              {
                selectedRole === 'Others' && (
                  <>
                    <div className='roletext'>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Controller
                          name="role"
                          control={control}
                          defaultValue=""
                          rules={{ required: "role is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              // label="Role"
                              placeholder="Enter your Role"
                              fullWidth
                              type="text"
                              {...field}
                              InputProps={{
                                endAdornment: (
                                  <SendRoundedIcon className='inputicon' onClick={() => { }} />
                                )
                              }}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.role && errors.role.message}
                        </FormHelperText>
                      </Grid>
                    </div>
                  </>
                )
              }
              {/* <div className='pl-0'>
              <div className='boxx'>
                <center><p className='font-weight-bold m-0'>DPIIT<br /> Startups</p></center>
              </div>
            </div>
            <div className='pl-4'>
              <div className='boxx'>
                <center><p className='font-weight-bold m-0'>Non - DPIIT <br /> Startups</p></center>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        {/* <div className='text-right'>
          <button className='next-btn' onClick={handleSignupPage}>Continue</button>
        </div> */}

      </div>
    </div>
  )
}

export default Profilerole