import { TabContext, TabPanel } from '@mui/lab';
import { Badge, Box, Dialog, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import MentorSessionModal from './MentorSessionModal';

const MentorSessionFilters = ({name,sessionList}) => {

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
    
  };
var sessionPending = localStorage.getItem('sessionPending')

  return (
    <section className=''>
      <div className='investor-filters'>
        <TabContext value={value} className="tab-div">
          <div className="inv-name-tab">
            <Box className="inv-tabs-boxs-surya-v1">
              <Tabs
                value={value}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                className='scroll-inv-tabs'
              >
                <NavLink
                  to={"/mentor/session/list"}
                  className="profile-tab-sty"
                >
                  Session
                </NavLink>
                <NavLink
                  to={"/mentor/session/request"}
                  className="profile-tab-sty"
                >
                  Requests <Badge color="error" badgeContent={sessionPending} className='ml-4'></Badge>
                </NavLink>
              </Tabs>
              <button className='invest-appiont-btn' onClick={handleOpen}>Create Session</button>
            </Box>
          </div>
        </TabContext>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            p: 4,
            borderRadius: 3
          }
        }}
      >
        <MentorSessionModal open={open} onClose={handleClose} sessionList={sessionList} />
      </Dialog>
    </section>
  )
}

export default MentorSessionFilters