import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Telecom = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTNsector-Telecom.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">
            Telecom, Networking & Hardware
          </h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    PLI Scheme under Atmanirbhar Bharat Abhiyan
                  </h5>
                  <br />

                  <p>
                    Production Linked Incentive (PLI) Scheme worth Rs 12,195
                    crore for manufacturing of telecom and networking products.
                    Incentives worth more than Rs 4,000 crore have been
                    earmarked for the Design-Led Manufacturing Scheme of the
                    existing PLI Scheme.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>BharatNet Project</h5>
                  <br />
                  <p>
                    Under the BharatNet Project, 1,77,550 village panchayats
                    have been made service ready till June 2022. Scope of
                    BharatNet Project has been extended to all inhabited
                    villages in the country.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Prime Minister Wi-Fi Access Network Interface (PM-WANI)
                  </h5>
                  <br />
                  <p>
                    Provision of public Wi-Fi service through Public Data
                    Offices (PDOs) spread across the country to accelerate the
                    expansion of broadband Internet services
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Semicon India Programme</h5>
                  <br />
                  <ul>
                    <li>
                      Schemes for setting up Semiconductor Fabs, Display Fabs,
                      compound semiconductors, OSAT / ATMP, Design Linked
                      Incentive (DLI).
                    </li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Schemes for Promotion of Electronics Manufacturing
                  </h5>
                  <br />
                  <ul>
                    <li>Production Linked Incentive (PLI) Schemes for</li>
                    <br />
                    <ul>
                      <li>
                        Large Scale Electronics Manufacturing and IT Hardware,
                        Scheme for Promotion of Manufacturing of Electronic
                        Components and Semiconductors (SPECS),
                      </li>
                      <br />
                      <li>
                        Modified Electronics Manufacturing Clusters Scheme (EMC
                        2.0)
                      </li>
                      <br />
                    </ul>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />
                  <p>
                    <b>
                      {" "}
                      Tamil Nadu Electronics Hardware Manufacturing Policy 2020:
                    </b>{" "}
                    This policy encourages electronics system design and
                    manufacturing companies to invest in the State by providing
                    various incentives, infrastructure support and subsidies.
                  </p>
                  <br />
                  <p>
                    <b>Electronics Manufacturing Cluster:</b> The State
                    Government has established Electronics Manufacturing
                    Clusters to provide infrastructure and common facilities for
                    electronics manufacturing companies, promoting the growth of
                    the hardware sector. These clusters offer a conducive
                    environment for hardware businesses, helping reduce
                    operational cost and increase efficiency.
                  </p>
                  <p>
                    <b>Tamil Nadu FibreNet Corporation (TANFINET):</b> TANFINET
                    aims to provide affordable high-speed broadband Internet
                    connectivity to every household and business in Tamil Nadu.
                    It is a major initiative in the telecom and networking
                    sector.
                  </p>
                  <p>
                    <b>Export Promotion:</b> Tamil Nadu offers various
                    incentives and support for hardware and electronics
                    companies engaged in export activities. These incentives
                    include financial assistance, export marketing support and
                    incentives for achieving export targets.
                  </p>
                  <p>
                    <b>FaMe TN</b> provides a convenient platform for connecting
                    MSMEs in Tamil Nadu with the respective EPC, in addition to
                    providing handholding support through field offices in the
                    districts. FaMe TN sponsors MSMEs for participation in
                    international exhibitions, trade fairs and buyer-seller
                    meets organised by the EPCs. FaMe TN undertakes knowledge
                    sharing and training programmes in association with various
                    EPCs for promoting exports from the MSME sector.
                  </p>
                  <p>
                    <b>Guidance Tamil Nadu:</b> The Tamil Nadu Industrial
                    Guidance and Export Promotion Bureau, also known as Guidance
                    Tamil Nadu, assists in promoting and facilitating
                    investments in the State.
                  </p>
                  <p>
                    <b>SIPCOT EMCs:</b> Several Electronic Manufacturing
                    Clusters in Tamil Nadu are managed by the State Industries
                    Promotion Corporation of Tamil Nadu, a government agency
                    under MSME department, responsible for promoting industrial
                    development in the State. Some of the EMCs managed by SIPCOT
                    include the Sriperumbudur Electronics Manufacturing Cluster
                    and the Oragadam Electronics Manufacturing Cluster.
                  </p>
                  <p>
                    <b>TIDCO EMCs:</b> The Tamil Nadu Industrial Development
                    Corporation is another key agency involved in promoting
                    industrial development in the State. TIDCO manages various
                    industrial clusters, including EMCs. An example is the
                    Tiruvallur EMC.
                  </p>
                  <p>
                    <b>ELCOT:</b> Electronics Corporation of Tamil Nadu is
                    another government organisation involved in the management
                    and development of electronics-related initiatives,
                    including EMCs.
                  </p>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <ul>
                    <li>
                      Tamil Nadu Centre of Excellence for Advanced Manufacturing
                      – TANCAM
                      <a
                        href="https://www.tancam.in/"
                        target="_blank"
                        className="ml-2"
                      >
                        https://www.tancam.in/
                      </a>{" "}
                    </li>
                    <br />
                    <li>
                      Tamilnadu smart and advanced manufacturing centre- TANSAM
                      <a
                        href="https://tansam.org/"
                        target="_blank"
                        className="ml-2"
                      >
                        https://tansam.org/
                      </a>
                    </li>
                    <br />
                    <li>
                      Tamilnadu Advance Manufacturing Centre of Excellence –
                      TAMCOE
                      https://in.linkedin.com/company/tamilnadu-advance-manufacturing-centre-of-excellence
                    </li>
                    <br />
                    <li>
                      IIT Research Park, Chennai –
                      <a
                        href="https://tansam.org"
                        target="_blank"
                        className="ml-2"
                      >
                        https://tansam.org/
                      </a>
                    </li>
                    <br />
                    <li>
                      Forge Forward –
                      <a
                        href="https://www.forgeforward.in/"
                        target="_blank"
                        className="ml-2"
                      >
                        https://www.forgeforward.in/
                      </a>
                    </li>
                    <br />
                  </ul>
                  <p>
                    In Tamil Nadu, there are about 800 Startups and 33
                    incubation centres in this sector.
                  </p>
                  <br />
                  <p>
                    Here are some specific examples of how Startups in Tamil
                    Nadu are benefiting from the State’s Telecom, Networking and
                    Hardware infrastructure:
                  </p>
                  <br />
                  <ul>
                    <li>
                      <b>Airtel X Labs:</b> Airtel X Labs is an innovation hub
                      set up by Airtel, one of the leading telecom companies in
                      India. The hub is located in Chennai, Tamil Nadu, and
                      supports startups in developing innovative telecom
                      solutions.
                    </li>
                    <br />
                    <li>
                      <b>BSNL Startup Accelerator:</b> BSNL, the state-owned
                      telecom company in India, has launched a startup
                      accelerator in Chennai, Tamil Nadu. The accelerator
                      provides startups with access to BSNL’s infrastructure and
                      resources to help them develop and commercialize their
                      products and services.
                    </li>
                    <br />
                    <li>
                      <b>Dell Innovation Center:</b> Dell has set up an
                      innovation center in Chennai, Tamil Nadu. The center works
                      with startups to develop and commercialize innovative
                      hardware solutions.
                    </li>
                    <br />
                    <li>
                      <b>HP Startup Garage:</b> HP has set up a startup garage
                      in Chennai, Tamil Nadu. The garage provides startups with
                      access to HP’s infrastructure and resources to help them
                      develop and commercialize their products and services.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Policies</h5>
                  <br />
                  <ul>
                    <li>National Policy on Electronics 2019</li>
                    <br />
                    <li>
                      Tamil Nadu Electronics Hardware Manufacturing Policy: 2020
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Telecom;
