import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Healthcare = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/Healthcare-and-Life-Sciences.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
          <h3 className="banner-title-sector">Healthcare & Life Sciences</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4><br />
                  <ul>
                    <li>Guidance Tamil Nadu, in collaboration with American Tamil Entrepreneurs Association (ATEA), has set up a Digital Accelerator Programme to attract Startups from the U.S. and in India to start business in Tamil Nadu.</li><br />
                    <li>Birac Equity Funding – SEED Fund (https://www.birac.nic.in/seedFundNew.php), LEAP Fund ( https://www.birac.nic.in/leapFundNew.php), BioAngels Program (https://www.birac.nic.in/Bioangels.php) , Fund of Funds – AcE (https://www.birac.nic.in/aceFundNew.php)</li><br />
                    <li>Industrial Ecosystem Fund: A Rs. 500-crore fund supporting small infrastructural projects for power supply, roads, water supply, and more, benefiting manufacturing projects in Life Sciences.</li><br />
                    <li>Tamil Nadu Emerging Sector Seed Fund: With a Rs. 500-crore corpus, this fund supports emerging sectors like Biotechnology, Pharmaceuticals, Nutraceuticals, Medical Textiles, and Medical Devices, fostering investment proposals in these areas.</li><br />
                    <li>Research & Technology Fund: A Rs. 100-crore fund dedicated to aiding research and technology adoption in sunrise sectors, including Life Sciences, to support R&D and industrial projects.</li><br />
                    <li>Skill Development: The government, with help from JICA, is setting up Apex Skill Development Centres. These centres will provide high-level training in sectors like Healthcare, Medical Devices and Biotechnology. They will also run a “finishing school” for quick industry-ready training.</li><br />
                    <li>National Institutes: The State Government will expedite the formation of national institutes in disciplines closely aligned with Pharmaceuticals, Medical Devices and Biotechnology.</li><br />
                    <li>Single Window Portal: A user-friendly online portal, the Single Window Portal (TNSWP), simplifies business processes. It offers over 200 services across 38 departments, making everything digital and efficient. This includes clearances for medical devices and more.</li><br />
                    <li>Business Facilitation Rules: The State updated its Business Facilitation Rules in August 2021, making it easier for businesses by consolidating licences and clearances.</li><br />
                    <li>DL2 Licence: The State is speeding up the process of granting DL2 licences, especially for Methanol used in pharmaceuticals. The timeline was reduced from 90 days to 30 days in August 2021, and licencing will be part of the Single Window Portal.</li><br />
                  </ul>
                  <h4 style={{ color: "#39364e" }}>Incentives</h4><br />
                  <li>Fixed Capital Subsidy: Companies can choose from fixed, flexible or turnover-based subsidies disbursed in equal annual instalments, starting from commercial production or upon reaching an investment threshold of Rs. 50 crore and 50 jobs.</li><br />
                  <li>Flexible Capital Subsidy: This scheme offers a Sunrise Booster of up to 7.5% of Eligible Fixed Assets (EFA), with higher subsidies for sub-large projects in sectors like Pharmaceuticals, Medical Devices, etc.</li><br />
                  <li>Turnover-based Subsidy: This is available for mega and ultra mega projects creating a minimum of 2000 jobs and exceeding Rs. 500 crore investment, as a percentage of annual turnover, up to 4% of cumulative investment for 10 years.</li><br />
                  <li>Training Subsidy: Training subsidy of Rs. 4000 per month for 6 months per worker and Rs. 6000 for certain categories for skilling is provided for residents of Tamil Nadu.</li><br />
                  <li>Land Allotment: Concessional rates for land allotment in specified districts, with 50% subsidy on private land in “C” districts (up to 50 acres) for manufacturing.</li><br />
                  <li>Certification Subsidy: A subsidy of 50% of the cost incurred for obtaining certifications, limited to Rs. 50 lakh for sub-large projects and Rs. 1.25 crore for large, mega and ultra mega projects.</li><br />
                  <li>Intellectual Property Support: Reimbursement of 50% of expenses for patents, copyrights, trademarks, etc., of up to Rs. 50 lakh for sub-large projects and Rs. 1.25 crore for larger projects during the investment period.</li><br />
                  <li>Overseas Training Subsidy: A one-time subsidy of up to Rs. 50,000 per recruit for overseas training within three years of commercial production.</li><br />
                  <li>R&D Incentives: TNIP 2021 includes R&D as part of Eligible Fixed Assets, providing various incentives like land cost incentives, quality certification incentives and more to promote research and development activities in Life Sciences.</li><br />
                  <li>R&D Training Incentive: A sum of Rs. 10,000 per person per month for 12 months for R&D personnel.</li><br />
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4> <br />
                  <ul>
                    <li>Sri Ramachandra BIRAC-BIONEST incubators</li><br />
                    <li>IIT Madras- BIoincubator</li><br />
                    <li>Tamil Nadu Veterinary and Animal Sciences University – Veterinary Incubation Foundation</li><br />
                    <li>PSG-STEP, Coimbatore</li><br />
                    <li>Vellore Institute of Technology-Technology Business Incubator</li><br />
                    <li>Golden Jubilee Biotech Park for Women Society</li><br />
                    <li>Crescent Innovation and Incubation Council</li><br />
                    <li>SASTRA TBI</li><br />
                    <li>Healthcare Technology and Healthcare Centre</li><br />
                  </ul >
                  <h4 style={{ color: "#39364e" }}>Infrastructure</h4><br />
                  <h6><b>Bio Parks</b></h6><br />
                  <p>Tamil Nadu has several bio parks and research incubation parks equipped with modern infrastructure and easy-to-use facilities. These parks are ideal for Startups in the biotechnology, medtech and pharmaceutical sectors.</p><br />
                  <h6><b>TICEL Bio Park</b></h6><br />
                  <p>TIDCO Centre for Life Sciences – TICEL Bio Park in Chennai and Coimbatore, established jointly by Tamil Nadu Industrial Development Corporation and TIDEL Park Limited, provides commercial spaces for companies into biotech and pharmaceutical research and development. It has a state-of-the-art Biotechnology Core Instrumentation Facility in Chennai, which has upstream and downstream biotech equipment, open for use by academia, Startups, MSMEs and industries for research and pilot-scale production. TICEL Innovation Hub announced for Chennai and Coimbatore in April 2023 will support research and skill development for Life Sciences students and researchers, while facilitating incubation for Life Sciences Startups.</p><br />
                  <h6><b>Incubation Parks for Life Sciences</b></h6><br />
                  <p>These parks, including the one at IIT-Madras and the Golden Jubilee Biotech Park for Women, provide state-of-the-art infrastructure and analytical facilities to support Startups and entrepreneurs. While IITM supports Medtech startups. Golden Jubilee Park supports Biotechnology and Life Sciences Startups run by women entrepreneurs.</p><br />
                  <h6><b>Pharmaceutical Parks</b></h6><br />
                  <p>A mega pharma cluster park has been established at Tindivanam by TANSIDCO under Mega Cluster Development Scheme of Government of Tamil Nadu for boosting the manufacture of pharmaceutical formulations. The park offers common facilities for testing with common effluent and sewage treatment plants. Tindivanam Pharma Park will help to promote growth in the biotech and pharmaceutical sectors.</p><br />
                  <h6><b>Medical Device Parks</b></h6><br />
                  <p>The State Industries Promotion Corporation of Tamilnadu (SIPCOT) is developing a 350-acre dedicated park for manufacturing medical devices. This park aims to enhance indigenous manufacturing capacity, reduce costs and promote innovation. It is envisioned as a one-stop facility with an integrated ecosystem to facilitate manufacturing, approvals, stimulate innovation and R&D, develop new technologies, prototyping and commercialisation activities. It will house common facilities with Gamma irradiation facility, Rapid prototyping and 3D Printing, metal grade moulding and finishing centres, calibration and validation centre, EMI/EMC testing centres, incubation and skill development centres etc. Along with this government initiative, private players are also expected to open medical device parks in the State.</p><br />
                  <h6><b>Medical Textile Parks</b></h6><br />
                  <p>Tamil Nadu is actively supporting the growth of medical textile manufacturing. Integrated Technical Textile Parks offer common infrastructure, uninterrupted utilities and roads to encourage research and production in this sector.</p><br />
                  <h6><b>Centre of Excellence for Organic Livestock Production</b></h6><br />
                  <p>The State has announced in the Tamil Nadu Life Sciences Promotion Policy 2022 that it plans to establish a centre to promote organic livestock production through biotechnology. It will disseminate specialized knowledge and provide organic certification services for animal product manufacturers.</p><br />
                  <h6><b>TIDCO’s Centre of Excellence</b></h6><br />
                  <p>TIDCO’s CoEs – TANSAM, TANCAM and TAMCOE – established in association with Siemens, Dassault and GE respectively at TIDEL Park, Chennai, offer state-of-the-art facilities for Medtech and Healthcare Startups and MSMEs in designing, prototyping, validation, simulation and reverse engineering of Medtech devices, 3D metal printing and additive manufacturing as well as AR/VR modules in healthcare etc.</p><br />
                  <p>These initiatives in Tamil Nadu provide a strong foundation for Startups in various sectors, facilitating research, development and manufacturing, while ensuring access to essential resources and infrastructure.</p><br />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Healthcare
