import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Chemicals = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTN-Sectors-Chemicals-and-Industries.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Chemicals & Materials</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <p>
                    The Tamil Nadu government has a number of initiatives in
                    place to support the chemical sector. They include:
                  </p>
                  <ul>
                    <li>
                      Tamil Nadu Chemicals and Petrochemicals Policy 2021 : This
                      policy aims to attract investment and promote the growth
                      of the chemical and petrochemical sector in Tamil Nadu. It
                      provides a number of incentives to companies, such as land
                      subsidies, capital subsidies and tax breaks.
                    </li>
                    <br />
                    <li>
                      Tamil Nadu Chemicals and Petrochemicals Corporation
                      (TNCPC): TNCPC is a State-owned corporation that promotes
                      the development of the chemical and petrochemical sector
                      in Tamil Nadu. It provides a range of services to
                      companies in the sector, such as land acquisition,
                      infrastructure development and financial assistance.
                    </li>
                    <br />
                    <li>
                      Tamil Nadu Petrochemicals Complex (TNPC): TNPC, located in
                      Cuddalore, produces a range of petrochemical products,
                      such as ethylene, propylene and butadiene. It is a major
                      supplier of petrochemical products to industries in Tamil
                      Nadu and other parts of India.
                    </li>
                    <br />
                    <li>
                      Tamil Nadu Chemicals and Petrochemicals Manufacturing
                      Cluster (TCPMC): TCPMC is a cluster of chemical and
                      petrochemical companies located in Cuddalore. It provides
                      a number of benefits to companies, such as shared
                      infrastructure, common effluent treatment facilities and
                      access to skilled labour.
                    </li>
                    <br />
                  </ul>
                  <h6>
                    Salient features of the Tamil Nadu Chemicals and
                    Petrochemicals Policy 2021:{" "}
                  </h6>
                  <br />
                  <ul>
                    <li>
                      Land subsidy of up to 50% for setting up new chemical and
                      petrochemical plants in Tamil Nadu.
                    </li>
                    <br />
                    <li>
                      Capital subsidy of up to 25% for setting up new chemical
                      and petrochemical plants in Tamil Nadu.
                    </li>
                    <br />
                    <li>
                      Tax breaks of up to 100% for the first 5 years of
                      operation for new chemical and petrochemical plants in
                      Tamil Nadu.
                    </li>
                    <br />
                  </ul>
                  <h6 style={{ color: "#39364e" }}>
                    Tamil Nadu Budget 2023-24 has a number of announcements for
                    the chemical industry. They include:
                  </h6>
                  <br />
                  <ul>
                    <li>
                      Reduction in stamp duty on registration of land for
                      chemical industries: The stamp duty on registration of
                      land for chemical industries has been reduced from 7% to
                      5%. This will make it cheaper for chemical companies to
                      acquire land in Tamil Nadu and set up new plants.
                    </li>
                    <br />
                    <li>
                      Subsidy for setting up new chemical industries: The Tamil
                      Nadu Government will provide a subsidy of up to 25% of the
                      capital investment for setting up new chemical industries
                      in the State. This will help to attract investment and
                      promote the growth of the chemical sector in Tamil Nadu.
                    </li>
                    <br />
                    <li>
                      Focus on research and development: The Tamil Nadu
                      Government will establish a Centre of Excellence for
                      Petrochemicals in the State to focus on research and
                      development in the petrochemical sector and help to
                      develop new technologies and products.
                    </li>
                    <br />
                    <li>
                      Training and development programmes for the chemical
                      sector workforce: The Tamil Nadu Government will launch
                      training and development programmes to build a skilled
                      workforce for the chemical sector. This will help to
                      ensure that chemical companies in Tamil Nadu have access
                      to the skilled workforce to grow and prosper.
                    </li>
                    <br />
                    <li>
                      Reduction in power tariffs for chemical industries: The
                      Tamil Nadu Government will reduce power tariffs for
                      chemical industries by 10%. This will help to reduce the
                      cost of doing business for them in Tamil Nadu.
                    </li>
                    <br />
                    <li>
                      Focus on infrastructure development: The Tamil Nadu
                      Government will invest heavily in infrastructure
                      development, such as roads and ports. This will improve
                      the connectivity of chemical plants in Tamil Nadu and make
                      it easier to transport chemicals to domestic and
                      international markets.
                    </li>
                    <br />
                  </ul>
                  <h6 style={{ color: "#39364e" }}>
                    The Union Government has implemented a number of schemes to
                    support the chemical sector. Some of the key initiatives
                    include:
                  </h6>
                  <br />
                  <ul>
                    <li>
                      Production Linked Incentive (PLI) Scheme for Advanced
                      Chemistry Cell (ACC) Battery Storage: This scheme aims to
                      attract investment and promote the manufacturing of ACC
                      batteries in India. It will provide financial incentives
                      to companies that set up manufacturing facilities.
                    </li>
                    <br />
                    <li>
                      Scheme for Promotion of Manufacturing of Critical
                      KSMs/APIs in India: This scheme aims to reduce India’s
                      dependence on imports of key starting materials (KSMs) and
                      active pharmaceutical ingredients (APIs). It will provide
                      financial incentives to companies that set up
                      manufacturing facilities.
                    </li>
                    <br />
                    <li>
                      National Policy on Petrochemicals: This policy aims to
                      promote the growth and development of the petrochemical
                      industry in India. It focuses on attracting investment,
                      promoting research and development and improving the
                      competitiveness of the petrochemical industry.
                    </li>
                    <br />
                    <li>
                      Chemical Policy, 2011: This policy aims to promote the
                      sustainable growth of the chemical industry in India. It
                      focuses on promoting innovation, improving safety and
                      environmental standards and developing the chemical
                      industry in rural areas.
                    </li>
                    <br />
                    <li>
                      Petroleum, Chemicals and Petrochemicals Investment Region
                      (PCPIR) Scheme: This scheme aims to create world-class
                      industrial infrastructure for the petroleum, chemicals and
                      petrochemicals sector. It provides financial incentives to
                      States to develop PCPIRs.
                    </li>
                    <br />
                    <li>
                      Chemicals Promotion and Development Scheme (CPDS): It
                      provides financial assistance to chemical companies for a
                      variety of activities, such as setting up new
                      manufacturing facilities, expanding existing ones and
                      undertaking research and development.
                    </li>
                    <br />
                    <li>
                      National Petrochemical Awards: They are presented annually
                      to recognize excellence in the petrochemical industry. The
                      awards are given in a variety of categories, such as best
                      petrochemical plant, best petrochemical product and best
                      petrochemical research project.
                    </li>
                    <br />
                    <li>
                      Chemicals Promotion and Development Scheme (CPDS): This
                      scheme provides financial assistance to companies for the
                      development of new chemical products and processes,
                      besides upgradation of existing chemical plants.
                    </li>
                    <br />
                    <li>
                      Plastic Parks: The Union Government has set up Plastic
                      Parks in various parts of the country. They provide
                      infrastructure and other facilities to companies that are
                      engaged in the manufacturing and processing of plastics.
                    </li>
                    <br />
                    <li>
                      Centre of Excellence (COE) in Petrochemicals: COEs provide
                      research and development support to the petrochemical
                      industry.
                    </li>
                    <br />
                  </ul>
                  <h6 style={{ color: "#39364e" }}>
                    The Union Budget 2023-24 has a number of initiatives for the
                    chemical industry. They include:
                  </h6>
                  <br />
                  <ul>
                    <li>
                      Reduction in customs duty: The customs duty on denatured
                      ethyl alcohol, acid-grade fluorspar and crude glycerin has
                      been reduced. This will make these chemicals cheaper for
                      Indian companies and help to boost the competitiveness of
                      the chemical industry.
                    </li>
                    <br />
                    <li>
                      Extension of concessional duty on lithium-ion cells: The
                      concessional duty on lithium-ion cells for batteries has
                      been extended for another year. This will support the
                      growth of the electric vehicle industry and the demand for
                      lithium-ion batteries.
                    </li>
                    <br />
                    <li>
                      Increase in credit guarantee for MSMEs: The government has
                      increased the credit guarantee for MSMEs from Rs 1 lakh
                      crore to Rs 2 lakh crore. This will make it easier for
                      MSMEs in the chemical sector to access credit and grow
                      their businesses.
                    </li>
                    <br />
                    <li>
                      Focus on infrastructure development: The government has
                      announced a number of initiatives to develop
                      infrastructure, such as roads, railways and ports. This
                      will improve the connectivity of chemical plants and make
                      it easier to transport chemicals to domestic and
                      international markets.
                    </li>
                    <br />
                    <li>
                      Reduction in corporate tax: The corporate tax rate for
                      domestic companies has been reduced from 30% to 25%. This
                      will reduce the cost of doing business for chemical
                      companies and make them more competitive.
                    </li>
                    <br />
                    <li>
                      Increase in capital expenditure: The government has
                      increased its capital expenditure by 35.4%. This will
                      boost the demand for chemicals used in infrastructure
                      development.
                    </li>
                    <br />
                    <li>
                      Focus on research and development: The government has
                      announced a number of initiatives to promote research and
                      development in various sectors, including the chemical
                      sector. This will help to develop new chemical products
                      and processes and make the Indian chemical industry more
                      competitive.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <h6>
                    Incubators and R&D institutions for chemical industries in
                    Tamil Nadu:
                  </h6>
                  <br />
                  <ul>
                    <li>Chemical Industries Association (CIA)</li>
                    <br />
                    <li>Tamil Nadu Petrochemicals Complex (TNPC)</li>
                    <br />
                    <li>
                      Tamil Nadu Chemicals and Petrochemicals Manufacturing
                      Cluster (TCPMC)
                    </li>
                    <br />
                    <li>Anna University – Chennai</li>
                    <br />
                    <li>Indian Institute of Technology Madras (IIT Madras)</li>
                    <br />
                  </ul>
                  <h6>
                    Incubators and R&D institutions for chemical industries in
                    India:
                  </h6>
                  <br />
                  <ul>
                    <li>National Chemical Laboratory (NCL)</li>
                    <br />
                    <li>
                      Central Institute of Plastics Engineering & Technology
                      (CIPET)
                    </li>
                    <br />
                    <li>Indian Institute of Technology Bombay (IIT Bombay)</li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Chemicals;
