import React from 'react'
import { Autocomplete, Box, FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, Paper, TableHead, TableRow, TextField, Typography, Button } from '@mui/material'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, Pie, PieChart } from 'recharts';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const data = [
    { name: 'Startuptn', uv: 20 },
    { name: 'ITINT', uv: 22 },
    { name: 'TANCAM', uv: 35 },
    { name: 'TANCOE', uv: 30 },
    { name: 'Fort', uv: 25 },
    { name: 'SIDCO', uv: 28 },
    { name: 'TNGCC', uv: 24 },
    { name: 'EDII', uv: 35 },
    { name: 'TANSOE', uv: 27 },
    { name: 'TNFMTC', uv: 26 },
    { name: 'InnovateTN', uv: 23 },
    { name: 'TICEL', uv: 29 },
];

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '40px' }}>
                <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};


const data1 = [
    { name: 'Unsolved', value: 32 },
    { name: 'Solved', value: 68 },
];

const COLORS = ['#FF0000', '#0000FF']; // Red for Unsolved, Blue for Solved
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="black" textAnchor="middle" dominantBaseline="central">
            {index === 0 ? `${(percent * 100).toFixed(0)}%` : ''}
        </text>
    );
};


const choose = [
    'Closed'
]


const AdminDashboard = () => {
    const maxValue = Math.max(...data.map(item => item.uv));
    const [formData, setFormData] = useState('')
    const { handleSubmit, control, setValue, reset, setError, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });


    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                        value: {
                            show: true,
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#373d3f',
                            offsetY: 16,
                            formatter: function (val) {
                                return val;
                            }
                        },
                        name: {
                            show: true,
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#373d3f',
                            offsetY: -10,
                            text: 'Default Label'
                        },
                        total: {
                            show: true,
                            label: 'Interventions',
                            fontSize: '16px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#373d3f',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                            }
                        }
                    }
                }
            }
        },
        labels: ['Dept 1', 'Dept 2', 'Dept 3', 'Dept 4', 'Dept 5'],
        legend: {
            position: 'bottom'
        }
    });
    const [series, setSeries] = useState([44, 55, 41, 17, 15, 55]);

    const [chartData, setChartData] = useState({
        series: [44, 55],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: '#373d3f',
                                offsetY: 16,
                                formatter: function (val) {
                                    return val;
                                }
                            },
                            name: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: '#373d3f',
                                offsetY: -10,
                                text: 'Default Label'
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: '#373d3f',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                                }
                            }
                        },
                    },
                },
            },
            dataLabels: {
                enabled: true,
            },
            fill: {
                type: 'gradient',
            },
            legend: {
                show: false,
            },
            // legend: {
            //     formatter: (val, opts) => {
            //         return `${val} - ${opts.w.globals.series[opts.seriesIndex]}`;
            //     },
            // },
            // title: {
            //     text: 'Gradient Donut with custom Start-angle',
            // },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                            show: false,
                        },
                    },
                },
            ],
            colors: ['#ff0000', '#4623e9']
        },
    });


    const [chartData1, setChartData1] = useState({
        series: [44, 55],
        options: {
            chart: {
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            value: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: '#373d3f',
                                offsetY: 16,
                                formatter: function (val) {
                                    return val;
                                }
                            },
                            name: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: '#373d3f',
                                offsetY: -10,
                                text: 'Default Label'
                            },
                            total: {
                                show: true,
                                label: 'Total',
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                color: '#373d3f',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                                }
                            }
                        },
                    },
                },
            },
            // dataLabels: {
            //     enabled: true, // Enable data labels to show values inside the donut
            //     formatter: function(val, opts) {
            //         return opts.w.config.labels[opts.seriesIndex] + ' - ' + val;
            //     },
            // },
            fill: {
                type: 'gradient',
            },
            legend: {
                show: true,
                position: 'bottom',
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
            colors: ['#E6B080', '#2349DF'],
        },
        labels: ['557 Engagements', '437 Engagements'],
    });

    return (
        <>
            <section>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item sm={12} md={12} lg={8}>
                        <div className='bar-graph'>
                            <div className='bar-head'>
                                <span>
                                    <h5>Total Engagements across Departments</h5>
                                    <span className='bar-chart'>Monthly</span>
                                </span>
                                <div className='support-dashboard-close mb-5'>
                                    <Controller
                                        sx={{ width: '200' }}
                                        name="category"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Required" }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                disableClearable
                                                disablePortal
                                                {...field}
                                                options={choose}
                                                // options={eventCategoryList.map((name) => name.category)}
                                                // onChange={(e, selectedOptions) =>
                                                //   handleSelectEventCategory(selectedOptions)
                                                // }
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Choose " />
                                                )}
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.category && errors.category.message}
                                    </FormHelperText>
                                </div>
                            </div>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={data}>
                                    <XAxis dataKey="name" axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Bar dataKey="uv" fill="#8884d8" radius={[10, 10, 0, 0]}>
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.name === 'EDII' ? '#0088FE' : '#C4C4C4'} />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid item sm={12} md={12} lg={6}>
                                <div className='big-donut-chart'>
                                    <h5 className='mb-3'>Total Interventions</h5>
                                    <ReactApexChart options={options} series={series} type="donut" />
                                </div>
                            </Grid>
                            <Grid item sm={12} md={12} lg={6}>
                                <div className='donut-chart-1'>
                                    <h5 className='padding-head'>Total Engagements Ticket</h5>
                                    {/* <img src='/images/donut-graph.png' alt='' className='img-fluid' /> */}
                                    <div className='pb-3' >
                                        <ReactApexChart
                                            options={chartData1.options}
                                            series={chartData1.series}
                                            type="donut"
                                            width={350}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid item sm={12} md={12} lg={4}>
                        <div className='donut-chart'>
                            <h5 className='mb-3'>Total Unsolved Ticket</h5>
                            {/* <img src='/images/donut-graph.png' alt='' className='img-fluid' /> */}
                            <div id="chart">
                                <ReactApexChart
                                    options={chartData.options}
                                    series={chartData.series}
                                    type="donut"
                                    width={300}
                                />
                            </div>
                            <div id="html-dist"></div>
                        </div>

                        <div className='unsolved-tic'>
                            <h5 className='my-3'>Unsolved TIckets</h5>
                            <Box className="widget-red-box">
                                <h3 className="red-clrr">84</h3>
                                <p className="red-clrr-1">Currently</p>
                            </Box>
                            <p>By Category</p>
                            <div className='dept-css'>
                                <p>Department 1</p>
                                <p>54</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 2</p>
                                <p>24</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 3</p>
                                <p>08</p>
                            </div>
                            <div className='dept-css'>
                                <p>Department 4</p>
                                <p>08</p>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </section>

            <section>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item sm={12} md={12} lg={5}>
                        <h6 className='my-4'>Upcoming Meetings</h6>
                        <div className='dashboard-content'>
                            <h5 className='bluee-clrr mt-3'>Agenda Meeting 2024</h5>
                            <div className='dashboard-content-dflex mt-4'>
                                <div>
                                    <p className='lightgray-clr m-0'>Start Time</p>
                                    <p className=''>24 Sep , 03.30 PM</p>
                                </div>
                                <div>
                                    <p className='lightgray-clr m-0'>End Time</p>
                                    <p className=''>24 Sep , 06.30 PM</p>
                                </div>
                            </div>
                            <div className='dashboard-content-dflex mt-3'>
                                <div>
                                    <p className='lightgray-clr m-0'>Venue</p>
                                    <p className=''>Secretariat </p>
                                </div>
                                <div className='dashboard-con-mar'>
                                    <p className='lightgray-clr m-0'>Department</p>
                                    <p className=''>OneTN</p>
                                </div>
                            </div>

                            <div className='mt-3'>
                                <Button className="discard-btn">Add to Calendar</Button>
                                <Button className="admin-join-button">Join</Button>
                            </div>

                        </div>
                    </Grid>
                    <Grid item sm={12} md={12} lg={7} >
                        <h6 className='my-4'>Support Tickets</h6>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-dashboard">
                                    <p>Total Support Raised</p>
                                    <h3 className="bluee-clrr">43</h3>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-dashboard">
                                    <p>Unsolved</p>
                                    <h3 className="bluee-clrr">11</h3>
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={4} lg={4}>
                                <Box className="widget-box-dashboard">
                                    <p>Closed</p>
                                    <h3 className="bluee-clrr">32</h3>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </>
    )
}

export default AdminDashboard