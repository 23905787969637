import { Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";
import Norecordsfound from "../../../common-pages/Norecordsfound";
import { Link } from "react-router-dom";


const Newsletter = () => {
    const { setPageLoading } = useContext(DataContext)
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [showAllnewsletter, setShowAllnewsletter] = useState(20);
    useDidMountEffect(() => {
        listApi();
    }, [page, searchInputValue, categoryId, showAllnewsletter]);
    const [eventList, setEventList] = useState([]);

    const listApi = () => {
        setEventList([])
        setPageLoading(true)
        var req = {
            searchString: searchInputValue,
            categoryId: categoryId,
            listSize: showAllnewsletter,
            pageNumber: page,
        };
        apiService(`newsletter/home/list`, req, "unauthpost")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    if (result.data.newsletter) {
                        setEventList(result.data.newsletter);
                        setTotalPage(result.data.totalPages)
                    }
                }
            })
            .catch((err) => { });
    };
    const handleChange = (event, value) => {
        setPage(value);
    };
    const handleToggleList = () => {
        if (totalPage > 1) {
            setShowAllnewsletter(showAllnewsletter + 20);
        } else {
            setShowAllnewsletter(20);
        }
    };
    return (
        <>
            <div className="event-title">
                <h2 className="event-titles">Newsletters</h2>
                <div className="event-line">
                    <img src="/images/event-line.png" alt="" />
                </div>
            </div>
            <Grid container direction="row" alignItems="flex-start" justifyContent="center" >
                {/* <Grid item sm={12} md={3} lg={3}><Mediafilter setSearchInputValue={setSearchInputValue} setCategoryId={setCategoryId} type={'newsletter'} seachLableName={'Enter Keywords'} /></Grid> */}
                <Grid item sm={12} md={12} lg={12} >
                    <div className="container">
                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
                            {eventList.length > 0 ?
                                eventList.map((item, i) => (
                                    <Grid item sm={12} md={4} lg={3} key={i}>
                                        <div className="news-list">
                                            <Link to={item.fileUrl} target="_blank">
                                                <img src={item.imageUrl ? item.imageUrl :  '/images/oneTn/white-image.png'} className="newsletter-img img-fluid" alt="" />
                                            </Link>
                                            <div className="news-title">
                                                <p className="mb-0">{item.title}, {item.date}</p>
                                            </div>
                                        </div>
                                    </Grid>
                                )) : (
                                    <Norecordsfound name="Newsletters Not Found" />
                                )
                            }

                        </Grid>
                        {eventList.length >= 20 && (
                            <div className="text-center">
                                <button
                                    onClick={handleToggleList}
                                    style={{
                                        background: 'linear-gradient(90deg, rgba(218, 69, 137, 1) 11%, rgba(224, 87, 128, 1) 24%, rgba(248, 161, 91, 1) 98%)',
                                        color: "#fff",
                                        fontSize: '12px',
                                        padding: "8px 20px",
                                        border: "none",
                                        borderRadius: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    {totalPage > 1 ? "View More" : "View Less"}
                                </button>
                            </div>
                        )}
                    </div>
                </Grid>
            </Grid>
        </>

    );
};
export default Newsletter