import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Climatetech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/startupTN-Sector-Climate.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
        <h3 className="banner-title-sector">Climatetech & Cleantech</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <ul>
                    <li>National Solar Mission (NSM): Launched in 2010, NSM aims to promote the development of solar energy in India. It includes various schemes and incentives to boost solar power generation and encourage solar Startups.</li>
                    <li>Pradhan Mantri Ujjwala Yojana (PMUY): This scheme aims to provide clean cooking fuel (LPG) to rural households, reducing indoor air pollution and deforestation. It indirectly supports Startups working on clean cooking solutions.</li>
                    <li>FAME (Faster Adoption and Manufacturing of Hybrid and Electric Vehicles) India Scheme: FAME India promotes the adoption of electric vehicles and charging infrastructure. This has led to the emergence of EV Startups in the clean energy sector.</li>
                    <li>National Clean Air Programme (NCAP): NCAP aims to improve air quality in Indian cities. It indirectly promotes Startups that develop clean energy and transportation solutions.</li>
                    <li>Atal Innovation Mission (AIM): AIM promotes innovation and entrepreneurship in various sectors, including clean energy. It supports incubators, accelerators and Startups through various programmes.</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Union Budget 2023 Highlights</h5>
                  <ul>
                    <li>Green Growth identified as one of the nodes in the SAPTARISHI (7 priorities).</li>
                    <li>$2.4 Bn National Hydrogen Mission for production of 5 MMT by 2030.</li>
                    <li>4 GWh Battery Energy Storage Systems supported through Viability Gap Funding</li>
                    <li>Pumped Storage Projects with a detailed framework to be formulated.</li>
                    <li>$1.02/2.5 Bn Central Sector Support for ISTS infrastructure for 13 GW of renewable energy from Ladakh.</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <ul>
                    <li>Tamil Nadu Government has been proactive in promoting clean energy and sustainable practices. It offers incentives, subsidies and favourable policies to Startups in the clean energy sector. The Tamil Nadu Solar Energy Policy and Wind Energy Policy provide a supportive environment for Startups.</li>
                    <li>Realising the importance and need for renewable energy, it set up a separate agency, the Tamil Nadu Energy Development Agency (TEDA), in 1985 to promote the use of new and renewable sources of energy and to implement projects therefore.</li>
                    <li>It has released a Climate Action Plan for Chennai to serve as a roadmap for the city to become carbon neutral by 2050, two decades before the nation’s target of net zero by 2070.</li>

                    <li>By 2050, the city aims to include electrification of 100% of the MTC bus fleet, expanding urban nature to cover 35% of the city and retrofitting existing slum housing for heat resilience to become carbon neutral.</li>
                    <li>Tamil Nadu Solar Energy Policy: The State has its solar policy, offering incentives, subsidies and tax benefits to promote solar power generation and solar Startups.</li>
                    <li>Tamil Nadu New and Renewable Energy Development Corporation: This plays a crucial role in implementing renewable energy projects and supporting clean energy Startups in the State.</li>
                    <li>Energy Conservation Building Code (ECBC): Tamil Nadu has adopted ECBC to promote energy-efficient building designs. This indirectly supports Startups working on energy-efficient technologies and solutions.</li>
                    <li>Tamil Nadu Industrial Policy 2021: The latest industrial policy of Tamil Nadu includes provisions for promoting green and sustainable industries, which align with climate action and clean energy goals.</li>
                    <li>Tamil Nadu Green Climate Fund (GCF): The State has set up a Rs 1000 crore Tamil Nadu Green Climate Fund to support climate change mitigation and greening projects. The objective is to lead the country in climate justice also.</li>
                    <li>Energy Purchase Agreements (EPAs): The Tamil Nadu Generation and Distribution Corporation (TANGEDCO) offers EPAs to renewable energy generators, ensuring a reliable market and payment mechanism for Startups involved in clean energy production.</li>

                    <li>Research and Development Grants: Clean energy Startups often require funding for research and development. The government offers grants and financial support for R&D initiatives aimed at developing innovative solutions in these sectors.</li>
                    <li>Renewable Energy Purchase Obligation (RPO): Tamil Nadu has set RPO targets for renewable energy consumption by specific industries. This creates demand for clean energy Startups’ products and services.</li>
                    <li>Regulatory Support: The government works on simplifying regulatory processes related to clean energy and climate action, making it easier for Startups to navigate permits, licences and compliance requirements.</li>

                  </ul>
                  <h5 style={{ color: "#39364e" }}>Policies</h5>
                  <ul>
                    <li>TN Solar Energy Policy 2019</li>
                    <li>Environment and Climate Change Policy 2022-23</li>
                    <li>National Green Hydrogen Mission 2023</li>
                    <li>National Wind Solar Hybrid Policy</li>
                    <li>National Action Plan on Climate Change</li>
                    <li>Mission LiFE (Lifestyle for Environment)</li>
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <ul>
                    <li><b>Skilled Workforce:</b> Tamil Nadu has a well-educated and technically skilled workforce. The presence of prestigious educational institutions and research centres in the State ensures a steady stream of talent for Clean Energy Startups.</li>
                    <li><b>Robust Infrastructure:</b> The State has reliable infrastructure, including a well-connected road network, ports and access to raw materials. This infrastructure supports the development, manufacture and distribution of clean energy technologies.</li>
                    <li><b>Access to Markets:</b> Tamil Nadu’s strategic location provides easy access to both domestic and international markets. This benefits Startups looking to scale their clean energy solutions beyond State borders.</li>
                    <li><b>Established Manufacturing Hub:</b> The State has a manufacturing hub history, which extends to the clean energy sector. It offers a conducive ecosystem for Startups involved in the production of renewable energy equipment, creating opportunities for innovation and growth.</li>
                    <li><b>Research and Development:</b> Tamil Nadu houses various research institutions and innovation centres that focus on clean energy and climate action. Startups can collaborate with these institutions for research, development and testing of their technologies.</li>
                    <li><b>Local Demand:</b> The State’s commitment to clean energy and sustainability is reflected in the growing local demand for clean energy solutions. Startups can cater to this demand and simultaneously test their products for scalability.</li>
                    <li><b>Supportive Ecosystem:</b> Tamil Nadu has a growing ecosystem of incubators, accelerators and venture capital firms specialising in clean energy and climate action. This ecosystem provides Startups with mentorship, funding opportunities and networking resources.</li>
                    <li><b>Environmental Awareness:</b> The people of Tamil Nadu are increasingly conscious of environmental issues and are supportive of clean energy initiatives. This awareness creates a market pull for clean energy solutions and encourages entrepreneurship.</li>

                  </ul>
                  <h5 style={{ color: "#39364e" }}>Infrastructure</h5>
                  <p>R & D institutions and agencies available for Climate Action and Clean Energy sector:</p>
                  <h5 style={{ color: "#39364e" }}>Indian Institute of Technology Madras (IIT Madras)</h5>
                  <p>Battery and Supercapacitor Research Laboratory: This lab focuses on advanced energy storage systems, including lithium-ion batteries and supercapacitors.</p>
                  <p>Solar Energy Lab: IIT-Madras has a dedicated solar energy lab for research in solar photovoltaics and concentrated solar power.</p>


                  <h5 style={{ color: "#39364e" }}>Anna University, Chennai</h5>
                  <p>Centre for Climate Change and Disaster Management, Anna University, Chennai: This centre has been established for the first time in the country to strengthen understanding of climate change and our capacity to manage and adapt to it.</p>
                  <p>Centre for Environmental Studies: Anna University’s Centre for Environmental Studies conducts research in various aspects of clean energy, including solar and wind power, energy efficiency, and environmental sustainability.</p>
                  <p>Institute for Energy Studies: This institute serves as a hub for learning of higher standards and gaining hands-on experience in energy engineering.</p>

                  <h5 style={{ color: "#39364e" }}>Central Electrochemical Research Institute (CECRI), Karaikudi</h5>
                  <p>Energy Storage Research Laboratory: This laboratory focuses on research in energy storage technologies, including advanced batteries and fuel cells.</p>
                  <h5 style={{ color: "#39364e" }}>National Institute of Technology (NIT) Tiruchi</h5>
                  <p>Energy and Environment Research Group: This entity conducts research in clean energy technologies, including solar, wind and energy-efficient systems.</p>
                  <h5 style={{ color: "#39364e" }}>Centre for Wind Energy Technology (C-WET)</h5>
                  <p>Wind Turbine Test Station: C-WET has a wind turbine test station in Kayathar, Thoothukudi district, which is essential for clean energy Startups working in wind energy technology.</p>
                  <h5 style={{ color: "#39364e" }}>Tamil Nadu Energy Development Agency (TEDA)</h5>
                  <p>Solar Photovoltaic Test Laboratory: TEDA’s laboratory offers testing and certification services for solar energy equipment, ensuring adherence to quality standards.</p>

                  <h5 style={{ color: "#39364e" }}>Indian Renewable Energy Development Agency (IREDA) Research and Development Wing</h5>
                  <p>Clean Energy Testing and Certification Laboratory: IREDA’s R&D wing in Chennai provides testing and certification services for various clean energy technologies.</p>
                  <h5 style={{ color: "#39364e" }}>Indian Institute of Crop Processing Technology (IICPT)</h5>
                  <p>Food Processing Laboratory: IICPT, located in Thanjavur, focuses on sustainable food processing technologies. Startups in the clean energy and food sectors can collaborate here.</p>
                  <h5 style={{ color: "#39364e" }}>Indian Institute of Information Technology Design and Manufacturing (IIITDM) Kancheepuram</h5>
                  <p>IIITDM-Kancheepuram focuses on research in sustainable design, including clean energy technologies and energy-efficient systems.</p>

                  <h5 style={{ color: "#39364e" }}>Tamil Nadu Agricultural University (TNAU)</h5>
                  <p>TNAU conducts research in sustainable agriculture and renewable energy integration in agriculture, which can be beneficial for Startups in the agriculture-based clean energy sector.</p>
                  <h5 style={{ color: "#39364e" }}>Institute of Remote Sensing (IRS), Anna University</h5>
                  <p>IRS specialises in remote sensing applications and environmental monitoring, which can support clean energy projects involving land-use planning and resource assessment.</p>
                  <h5 style={{ color: "#39364e" }}>Tamil Nadu Pollution Control Board (TNPCB):</h5>
                  <p>While not an R&D institution, TNPCB plays a role in environmental monitoring and regulation. Startups involved in clean energy must comply with environmental standards and TNPCB provides guidance and regulatory support.</p>
                  <h5 style={{ color: "#39364e" }}>National Institute of Solar Energy (NISE)</h5>
                  <p>NISE, located in Gurgaon, Haryana, is the apex national institution for solar energy research and development. It offers training, testing and demonstration facilities for solar technologies.</p>
                  <h5 style={{ color: "#39364e" }}>Indian Institute of Technology (IIT) Bombay</h5>
                  <p>IIT-Bombay has several research centres and laboratories focused on sustainable energy, including the Solar Energy Research Enclave and the Energy Science and Engineering Department</p>

                  <h5 style={{ color: "#39364e" }}>Indian Institute of Tropical Meteorology (IITM), Pune</h5>
                  <p>IITM is dedicated to atmospheric and climate research, providing valuable insights into India’s climate patterns and variability.</p>

                  <h5 style={{ color: "#39364e" }}>Indian Institute of Technology (IIT) Delhi</h5>
                  <p>IIT-Delhi’s Centre for Energy Studies conducts research on renewable energy technologies, energy policy and energy-efficient systems.</p>
                  <h5 style={{ color: "#39364e" }}>Indian Institute of Science (IISc), Bengaluru</h5>
                  <p>IISc has a Sustainable Technologies and Policy Programme (STAPP) that conducts research on sustainable energy solutions and climate policy.</p>
                  <h5 style={{ color: "#39364e" }}>National Institute of Wind Energy (NIWE)</h5>
                  <p>NIWE, located in Chennai, specialises in wind energy research and development and offers testing and certification services for wind turbines.</p>
                  <h5 style={{ color: "#39364e" }}>Central Power Research Institute (CPRI)</h5>
                  <p>CPRI, with multiple locations in India, focuses on research and testing in the power sector, including renewable energy systems and grid integration.

                  </p>
                  <h5 style={{ color: "#39364e" }}>The Energy and Resources Institute (TERI)</h5>
                  <p>TERI, headquartered in New Delhi, conducts research in sustainable development, climate change and clean energy technologies.</p>
                  <h5 style={{ color: "#39364e" }}>National Environmental Engineering Research Institute (NEERI)</h5>
                  <p>NEERI, with multiple centres across India, conducts research on air and water pollution control, waste management and sustainable technologies.</p>
                  <h5 style={{ color: "#39364e" }}>Indian Institute of Technology (IIT) Kanpur</h5>
                  <p>IIT Kanpur’s Centre for Environmental Science and Engineering (CESE) is involved in research related to environmental sustainability and climate action.</p>
                  <h5 style={{ color: "#39364e" }}>National Institute for Interdisciplinary Science and Technology (NIIST)</h5>
                  <p>NIIST, based in Thiruvananthapuram, Kerala, conducts research in clean energy, including biomass and biofuels.</p>
                  <h5 style={{ color: "#39364e" }}>Tata Energy Research Institute (TERI), New Delhi</h5>
                  <p>TERI, New Delhi, offers climate-related academic programmes and conducts research in climate change mitigation and adaptation.</p>

                  <p>Sardar Swaran Singh National Institute of Renewable Energy (SSS-NIRE), Kapurthala (Punjab), carries out research, design, development, testing, standardisation and technology demonstration.</p>
                  <h5 style={{ color: "#39364e" }}>Other Resources</h5>
                  <ul>
                    <li>Solar Energy Corporation of India</li>
                    <li>Association of Renewable Energy Agencies of States (AREAS)</li>
                    <li>Clean Energy International Incubation Centre powered by Social Alpha Incubator</li>
                    <li>Villgro Innovations Foundation, Chennai</li>
                    <li>AIC – Raise</li>
                  </ul>
                </div>
              </TabPanel>

            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Climatetech
