import React from 'react';
import { MdKeyboardArrowDown } from "react-icons/md";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from 'react-router-dom';
import ApplicationSteeper from './ApplicationSteeper';

const ApplicationStatus = ({ handleappstatusClose }) => {
    return (
        <div>
            <div className="app-status-dialog">
                <span
                    style={{ color: "#2260FF", cursor: "pointer" }}
                    onClick={handleappstatusClose}
                >
                    <ArrowBackIcon /> Back
                </span>
            </div>
            <div className='text-center'>
                <h6 className="tit-dial">Application Status</h6>
            </div>
            <div className="appl-dia-blu-box mt-5">
                <div className="contets-appl">
                    <div className="status-hed">
                        <h6>TANSEED 6.0</h6>
                        <p className='applied-date-tit'>Applied:<span className='apply-date'>25 May, 2024</span></p>
                    </div>
                    <div>
                        <button className='app-sta-btns'>In Progress</button>
                        <p className="sup-his-tick m-0">
                            <span className='appl-link'>{`View Application >`}</span>
                        </p>
                    </div>
                </div>
                <div className='steeprs my-4'>
                    <ApplicationSteeper/>
                </div>
                <div className="appl-dia-summ mt-4">
                    <div>
                        <p className="sup-his-tick m-0">
                            <span className='appl-link'>View Summary <span><MdKeyboardArrowDown /></span></span>
                        </p>
                    </div>
                    <div className="d-flex">
                        <Link to="#">
                            <p className="sup-his-tick m-0">
                                <img src={'/images/historypage/Group 1707479650.png'} alt="Support icon" className="Suppo-icon mr-2" />
                                <span className='suppo-link1'>Raise support ticket</span>
                            </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationStatus;
