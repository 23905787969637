import React, { useState, useContext } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {
  Paper,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Grid } from "@mui/material";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import cryptodecryptJS from "../../../hooks/cryptodecryptJS";
import NewslettersFilter from "./NewslettersFilter";
import { Link } from "react-router-dom";

const NewslettersList = () => {
  const {
    navigator,
    setPageLoading,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    hasPrivilege,
  } = useContext(DataContext);
  useDidMountEffect(() => {
    setStartDate("");
    setEndDate("");
  }, []);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);

  const [selectedNewsletterIndex, setSelectedNewsletterIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [newsletterData, setNewsletterData] = useState([]);
  let departmentId = localStorage.getItem("departmentId");
  useDidMountEffect(() => {
    listApi();
  }, [searchInputValue, page, startDate, endDate]);

  const listApi = () => {
    setPageLoading(true);
    setNewsletterData([]);
    var data = {
      listSize: 5,
      pageNumber: page,
      departmentId: departmentId,
      searchString: searchInputValue,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
    };
    apiService("newsletter/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data) {
          setTotalPage(result.data.totalPages);
          if (result?.data?.newsletter) {
            setNewsletterData(result.data.newsletter);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching Newsletters:", err);
      });
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  useDidMountEffect(() => {
    if (newsletterData.length > 0) {
      setSelectedNewsletter(newsletterData[selectedNewsletterIndex]);
    } else {
      setSelectedNewsletter(null);
      setSelectedNewsletterIndex(0);
    }
  }, [newsletterData]);
  const handleNewsletterClick = (event, index) => {
    setSelectedNewsletter(event);
    setSelectedNewsletterIndex(index);
  };

  const [opens, setOpens] = useState(false);
  const [NewsletterId, setNewsletterId] = useState(0);
  const handleViewNewsletterDetails = (val) => {
    setNewsletterId(val);
    setOpens(true);
  };
  const handleNewsletterClose = () => {
    setOpens(false);
  };
  const handleEditNewsletterDetails = (id) => {
    navigator(`/admin/newsletter/${btoa(id)}`);
  };
  const [openModal, setOpenModal] = useState(false);
  const [newslettesrId, setNewslettersId] = useState([]);
  const handleOpenModal = (id) => {
    setOpenModal(true);
    setNewslettersId(id);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleDeleteNewsletterDetails = () => {
    apiService(`newsletter/remove?id=${newslettesrId}`, "", "delete")
      .then((result) => {
        if (result?.data?.responseStatus === "Success") {
          handleCloseModal()
          listApi();
        }
      })
      .catch((err) => { });
  };
  return (
    <div className="admin-event-page">
      <NewslettersFilter setSearchInputValue={setSearchInputValue} />
      <div className="event-datas-list py-5">
        {newsletterData.length > 0 ? (
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                {newsletterData.length > 0 && (
                  <div className="table_main_div">
                    <TableContainer component={Paper}>
                      <Table
                        sx={{
                          borderCollapse: "separate",
                          borderSpacing: "0 10px",
                        }}
                      >
                        <TableBody sx={{ position: "relative" }}>
                          {newsletterData.map((list, index) => (
                            <>
                              <TableRow
                                sx={{
                                  position: "relative",
                                  "&:after": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                    borderRadius: "20px",
                                    pointerEvents: "none",
                                    marginBottom: "-1px",
                                  },
                                  "& > *": {
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    "&:last-of-type": {
                                      borderTopRightRadius: "20px",
                                      borderBottomRightRadius: "20px",
                                    },
                                  },
                                  marginBottom: "10px",
                                }}
                                key={index}
                                className={
                                  selectedNewsletterIndex === index
                                    ? "Sup-list selected"
                                    : "Sup-list"
                                }
                                onClick={() =>
                                  handleNewsletterClick(list, index)
                                }
                              >
                                <TableCell>
                                  <div className="px-3">
                                    <Typography
                                      variant="h6"
                                      className="list-2-clr"
                                    >
                                      {list.title}
                                    </Typography>

                                  </div>

                                </TableCell>
                                <TableCell>
                                  <div className="events-date-box">
                                    {list.imageName && (
                                      <img
                                        src={list.imageUrl}
                                        alt="Newsleter_Image"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          objectFit: "contain",
                                        }}
                                      />
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div>
                                  <span className="list-2-black" style={{color:list.publish === true ? '#00AA00' : "orangered",fontWeight:'500'}}>{list.publish === true ? "Published":"Not Published"}</span>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                      {totalPage > 1 && (
                        <div className="event-pagination">
                          <Stack spacing={2}>
                            <Pagination
                              count={totalPage}
                              page={page}
                              onChange={handleChange}
                            />
                          </Stack>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                {selectedNewsletter && (
                  <Card sx={{ maxWidth: 385 }} className="card-event-list">
                    <img
                      src={
                        selectedNewsletter.imageUrl
                          ? selectedNewsletter.imageUrl
                          : "/images/oneTn/white-image.png"
                      }
                      alt="Newsleter_Image"
                      style={{ width: "100%" }}
                    />
                    <div className="crd-lists">
                      <div className="card-event-det">
                        <h6>{selectedNewsletter.title}</h6>
                      </div>
                    </div>
                    <div className="admin-crd-actions">
                      <Link to={selectedNewsletter.fileUrl} target="_blank">
                        <button className="admin-viewbtn">View</button>
                      </Link>
                      {hasPrivilege("63") && (
                        <button
                          className="ml-3 admin-editbtn "
                          onClick={() =>
                            handleEditNewsletterDetails(selectedNewsletter.id)
                          }
                        >
                          Edit
                        </button>
                      )}
                      {hasPrivilege("64") && (
                        <button
                          className="ml-3 admin-deletebtn"
                          onClick={() =>
                            handleOpenModal(selectedNewsletter.id)
                          }
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </Card>
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <div className="text-center nodatacss_admin">
              <h5 className="mb-4">Newsletter data not found</h5>
              <div>
                <img
                  src="/images/blue horizontal.png"
                  alt="blue horizontal.png"
                  className="logo_dash img-fluid"
                />
              </div>
              <div className="mt-5">
                <h3 className="title_p opa">OneTN</h3>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={"sm"}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <center>
            <img src="/images/Banner/attention.gif" alt="" className="alert-image" />
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4 className="text-center p-3">Are you sure ?</h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-2">
          <Button
            className="remove-api-modal-cancel"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button className="remove-api-modal-confirm" onClick={() => handleDeleteNewsletterDetails()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewslettersList;
