import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AadukalamPage = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`Literacy  >  Aaduklam`}</span>
                  <h1 className="banner-text-gradient">Aadukalam</h1>
                  <h6 className="fund-title">
                    Pitching is a key skill for a successful entrepreneurial
                    journey
                  </h6>
                  <div className="banner-content-actions">
                    <button className="banner-viewbtn">Get in Touch</button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info5">
      <div className="layer-aadu">
        <Container>
        
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info1"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage1">
                <h4>What is Aadukalam?</h4>
                <p>
                  Aadukalam is an intensive one-day training session that
                  improves the pitching skills and helps startup founders
                  understand the right components of a great pitch. This
                  fundamental bootcamp provides plenty of practice on the basics
                  of pitching and enables startups to get their pitch right. The
                  founders learn how to communicate an idea to investors,
                  mentors and other stakeholders in the ecosystem.
                </p>
                <ul>
                  <li>Mock pitch session</li>
                  <li>Mentoring</li>
                  <li>Networking</li>
                  <li>Investor connect</li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/startupLogo.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
         
          {/* <div className="smartcrd-details-div ">
            <div className="">
              <div className="">
                <img
                  src="/images/TANSEED 6.0.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className=""></div>
            </div>
          </div> */}
        </Container>
        </div>
        <div className="why-adukalam-sec">
          <div className="container">
            <div className="tab-aadu ">
              <Box className="">
                <div className="aadduu">
                  <h2 className="att">Who Should Attend Aadukalam?</h2>
                  <div>
                    <ul>
                      <li>Who needs practice and expert advice on pitching</li>
                      <li>Who has an idea for a startup</li>
                      <li>Who is an early-stage company</li>
                      <li>Who needs mentoring</li>
                      <li>Who is looking for an investor.</li>
                    </ul>
                  </div>
                </div>
              </Box>
            </div>
            <div className="aadu-title">
              <p>
                Aadukalam is for startups, particularly with unique and strong
                ideas, that can be pitched with clarity. The same is conducted
                periodically by the regional hubs in association with the
                locally based ecosystem partners like educational institutions,
                incubators, industrial associations, etc. Around fifty startups
                participate in this day-long session. The session will be either
                co-sponsored or co-organised.
              </p>
            </div>
          </div>
        </div>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Adukalam</h4>
                    <p>
                      Pitching is a key skill for a successful entrepreneurial
                      journey
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AadukalamPage;
