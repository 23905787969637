import { Autocomplete, Button, Checkbox, FormControlLabel, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import DataContext from '../../../context/DataContext'


const Quickbrief = () => {
    const { navigator, validateEmail, validateNumberonly, setPageLoading, handleNextClick } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, watch, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });

    useDidMountEffect(() => {
        handleNextClick(100)
    }, [])
    const onSubmit = (data) => {
        console.log('Form Data:', data);
    };
    const onError = (event) => {
        console.log('error Data:', event);
    }
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='signup-data_div'>
                        <div className='forms-title d-flex'>
                            <span className='path_sapn'>Quick Brief</span>
                        </div>
                        <div className='signup_box_div mt-4'>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div>
                                    <div className='mb-3'>
                                        <p><b>Brief Description about your Startup</b></p>
                                        <div>
                                            <div className='my-3'>
                                                <Controller
                                                    name="description"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Description is required" }}
                                                    render={({ field }) => (
                                                        <TextField
                                                        {...field}
                                                            id="outlined-multiline-static"
                                                            label="Enter your Description"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            defaultValue=""
                                                        />
                                                    )}
                                                />
                                                <FormHelperText className="text-danger">
                                                    {errors.description && errors.description.message}
                                                </FormHelperText>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-5'>
                                        <button className='next-btn' type="submit">Next</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Quickbrief
