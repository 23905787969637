import React, { useContext, useState, useRef } from "react";

import { Button, Container, Grid, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import axios from "axios";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import { MdOutlineArrowCircleLeft } from "react-icons/md";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { IoArrowBackCircleOutline } from "react-icons/io5";





const HomeServicesSection = () => {
    const { navigator } = useContext(DataContext)
    const [tabDetails, setTabDetails] = useState([])
    const [tabIndex, setTabIndex] = useState(1)
    const [tabDetailsList, setTabDetailsList] = useState([])
    const [selectedtab, setSelectedtab] = useState("")
    const serviceContainerRef = useRef(null);

    useDidMountEffect(() => {
        // getApi()
        ShowgetApi()
    }, [])
    // const getApi = () => {
    //     axios.get('./json/serviceslist.json', '').then((result) => {

    //         if (result.data) {
    //             var data = result.data[0]
    //             setTabIndex(0 + 1)
    //             if (data) {
    //                 setTabDetails(data)
    //                 setTabSerive(data.serviceList)
    //             }
    //             setTabDetailsList(result.data)
    //         }
    //     })
    // }
    // const getApi = () => {
    //     setTabSerive([])
    //     var req = {}
    //     apiService(`home/services`, req, "unauthpost")
    //         .then((result) => {
    //             if (result && result.data) {
    //                 //  var data = result.data[0]
    //                 setTabIndex(0 + 1)
    //                 setTabDetailsList(result.data)
    //                 if (result.data[0].projects) {
    //                     setTabSerive(result.data[0].projects)
    //                     setTabDetails(result.data[0].name)

    //                 }
    //             }

    //         }).catch((err) => {

    //         });
    // }
    const [programs, setPrograms] = useState([])
    const ShowgetApi = () => {
        apiService(`home/service/list `, '', "unauthget")
            .then((result) => {
                if (result && result.data) {
                    var data = result.data

                    if (data.services) {
                        setPrograms(data.services)
                    }
                }
            }).catch((err) => {
                console.log(err);

            });
    }

    // const handleTabDetails = (index) => {
    //     var data = tabDetailsList[index]
    //     setTabIndex(index + 1)
    //     if (data) {
    //         setTabDetails(data)
    //         setTabSerive(data.serviceList)
    //     }

    // }

    const handleTabDetails = (value, index) => {
        setTabIndex(index + 1)
        setTabDetails(value)
        setSelectedtab(value)
        ShowgetApi(value)
        debugger
    }
    const handleRouteValue = (value, id) => {
        navigator(`/explore/${value}/${btoa(id)}`)
        debugger
    }
   
    const [currentIndex, setCurrentIndex] = useState(0);
    const numCardsPerPage = 4;
    

    const prevSlide = () => {
        const newIndex = currentIndex - numCardsPerPage;
        if (newIndex >= 0) {
            setCurrentIndex(newIndex);
        }
        serviceContainerRef.current.scrollTo({
            left: serviceContainerRef.current.scrollLeft + (serviceContainerRef.current.offsetWidth / numCardsPerPage),
            behavior: 'smooth'
        });
    };
    return (
        <section className="service-offers-section">
            <Container>
                <div className="Services-header-details">
                    <h3>Services <span className="gradient-text">We Offer</span></h3>
                    <p className="px-lg-5 px-md-5 px-0">Here is our directory of services based on our various policies to support Startups.</p>
                </div>

            </Container>
            <div className="mx-5">

                <div className="tab-data-details">
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2}>
                        {programs.map((program, index) => (
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                                <div className="service_data_new">
                                    <div className="card-image">
                                        <img src={program.imageName} alt="imag" />
                                    </div>

                                    <h4>{program.service}</h4>
                                    <div>
                                        <img src="/images/oneTn/Rectangle 23452.png" alt="line" />
                                    </div>
                                    <div className="chip-home-service">

                                    </div>
                                    <div className="home-Services-para color-gray">
                                        <p >{program.description}</p>

                                    </div>
                                    {/* <p>Know More</p> */}


                                    <p className="know-more1-new c-pointer" onClick={() => handleRouteValue(program.service, program.id)}>Explore all</p>



                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </section>
    )
}

export default HomeServicesSection