import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Signuplayout from '../../auth-module/signup-details-page/Signuplayout'
import BasicinfoInvestment from './organization-info-page/BasicinfoInvestment'
import AddressInvestment from './organization-info-page/AddressInvestment'
import SociallinksInvestment from './organization-info-page/SociallinksInvestment'
import MoreinfoInvestment from './organization-info-page/MoreinfoInvestment'
// import FounderdetailsInvestment from './team-info-page/FounderdetailsInvestment'
// import TeamsizeInvestment from './team-info-page/TeamsizeInvestment'

const Investmentroutes = () => {
    return (
        <main>
            <Routes>
                <Route path='/investor' element={<Signuplayout />}>
                    <Route path="/investor" element={<Navigate replace to="/investor/basicinfo" />} />
                    <Route path='/investor/basicinfo' element={<BasicinfoInvestment />} />
                    <Route path='/investor/address' element={<AddressInvestment />} />
                    <Route path='/investor/social-links' element={<SociallinksInvestment />} />
                    <Route path='/investor/moreinfo' element={<MoreinfoInvestment />} />
                    {/* <Route path='/investor/founder' element={<FounderdetailsInvestment />} />
                    <Route path='/investor/teamsize' element={<TeamsizeInvestment />} /> */}
                </Route>
            </Routes>
        </main>
    )
}

export default Investmentroutes