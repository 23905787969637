import React, { useContext, useState } from "react";
import { Grid, Pagination } from "@mui/material";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DataContext from "../../../../context/DataContext";

import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import apiService from "../../../../api/apiService";
const MentorRateReviewPage = () => {
  useDidMountEffect(() => { }, [])
  const { navigator, setPageLoading } = useContext(DataContext)
  const [value, setValue] = useState('');
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  useDidMountEffect(() => {
    ratingList();
  }, [page]);
  const [rating, setRating] = useState([]);
  const [count, setCount] = useState('');
  var id = localStorage.getItem("id");
  const ratingList = () => {
    setPageLoading(true)
    var data = {
      listSize: 6,
      pageNumber: page,
      userid:id
    };
    
    apiService("rating/list", data, "post")
      .then((result) => {
        
        setPageLoading(false)
        if (result) {
          if (result.data) {
            setCount(result.data)
            setTotalPage(result.data.totalPages);
            if(result && result.data && result.data.averageRating){
              setValue(result.data.averageRating)
            }
          
          }
          if (result.data.responsemodelList) {
            setRating(result.data.responsemodelList);
          }
          
        }
      })
      .catch((err) => { });
  };
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div className="investment-whole-div">
            <div className="profile-box2">
              <h5 style={{ fontWeight: "600" }} className="mb-3">Ratings & Reviews</h5>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <h5 className="color-blue"><img src={"/images/star-rate.png"} />{count.averageRating} / 5 ({count.count})</h5>
                <div className="my-3">
                <table
                  className="table"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <tbody>
                    {rating.length > 0 ? (
                      rating.map((item, index) => (
                        <tr className="mentor-boxs"  key={index} style={{ marginBottom: '10px' }}>
                          <td
                            style={{
                              padding: "0.75rem",
                              verticalAlign: "top",
                              width: "150px",
                            }}
                          >
                            <div className="">
                              <img
                                src={item.reviewerLogo}
                                className="review_logo"
                                alt=""
                              />
                            </div>
                          </td>
                          <td
                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                          >
                            <div className="news-letter-details">
                              <div className="d-flex">
                                <div>
                                  <h5>{item.reviewerName}</h5>

                                  <p className="news-letter-source font-sm">
                                    {item.review}
                                  </p>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </td>
                          <td
                            style={{
                              padding: "0.75rem",
                              verticalAlign: "top",
                              width: "120px",
                            }}
                          >
                            <div>
                              <p className="ml-auto list-para mr-5">
                                <img src={"/images/star-rate.png"} alt="image"/>{" "}
                                {item.point}
                              </p>
                              {/* <span className="ml-auto font-sm">{item.createdTime}</span> */}
                            </div>
                          </td>
                          <div className="grid-border"></div>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {totalPage > 1 && (
                      <div className="event-pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={totalPage}
                            page={page}
                            onChange={handleChange}
                          />
                        </Stack>
                      </div>
                    )}
                  </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <div className="set-ratingbox">
            <div>
              <h6 className="avg-rating mb-3">Average Rating</h6>
              <div className="avg-rate-div">
                <h1>
                {count.averageRating}
                  <span>
                    <Rating
                      name="simple-controlled"
                      value={value}
                      
                    />
                  </span>
                </h1>
                <p className="para">Average Rating on this year</p>
              </div>
              <div>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <div className="prog-flexs">
                    <span>5</span>
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      color="success"
                      sx={{ width: `${100}%` ,mx:1}}
                      
                    />
                    <span>{count.fiveStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>4</span>
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      color="error"
                      sx={{ width: `${70}%`,mx:1 }}
                    />
                    <span>{count.fourStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>3</span>
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      color="warning"
                      sx={{ width: `${50}%`,mx:1 }}
                    />
                    <span>{count.threeStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>2</span>
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      color="info"
                      sx={{ width: `${25}%`,mx:1 }}
                    />
                    <span>{count.twoStar}</span>
                  </div>
                  <div className="prog-flexs">
                    <span>1</span>
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      color="error"
                      sx={{ width: `${2}%`,mx:1 }}
                    />
                   <span>{count.oneStar}</span>
                  </div>
                </Stack>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default MentorRateReviewPage