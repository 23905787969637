import React from "react";
import InvestorStartupFilters from "../InvestorStartupFilters";
import { Checkbox, Grid } from "@mui/material";

const DDPhase = () => {
  const cardData = [
    {
      id: 1,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",

      title: "50 Fin",
      subhead: "Fintech",
      title2: "Phase 1",
    },
    {
      id: 2,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",
      title: "50 Fin",
      subhead: "Fintech",
      title2: "Phase 1",
    },
    {
      id: 3,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",
      title: "50 Fin",
      subhead: "Fintech",
      title2: "Phase 1",
    },
    {
      id: 4,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",
      title: "50 Fin",
      subhead: "Fintech",
      title2: "Phase 1",
    },
    {
      id: 5,
      logoSrc: "/images/GREENPOD_LOGO1 (1).png",
      title: "50 Fin",
      subhead: "Fintech",
      title2: "Phase 1",
    },
  ];

  return (
    <div>
      <InvestorStartupFilters name={"DDphase"} />
      <div className="ddphase-section mt-3">
        {cardData &&
          cardData.map((item, i) => (
            <div className="DD-box-3 mb-3" key={i}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-betrween"
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="check-ddphase">
                    <div>
                      <Checkbox
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    </div>
                    <div className="ddphase-imag">
                      <img src={item.logoSrc} alt="logo" className="DD-logo" />
                    </div>
                    <div className="ddphase-text">
                      <p className="mb-0">{item.title}</p>
                      <span>{item.subhead}</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="center-ddphase-det">
                    <div>
                      <img src={"/images/web-url.png"} alt="globe" />
                    </div>
                    <div className="ml-4">
                      <span>{item.title2}</span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="inv-crd-acts">
                    <button className="inv-view-btons-dd">View Profile</button>
                  </div>
                </Grid>
              </Grid>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DDPhase;
