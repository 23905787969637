import { Autocomplete, FormHelperText, Grid, TextField, } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from "../../../../api/notifySerivce";
import cryptoJS from "../../../../hooks/cryptoJS";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
const BasicinfoInnovators = () => {
  const { navigator, validateEmail, validateNumberonly, validatePhoneNumber, handleNextClick, isWhitespace, validatePANNumber, checkUppernumeric } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, formState: { errors }, } = useForm({ values: formData });
  let role = localStorage.getItem("role");
  useDidMountEffect(() => {
    handleNextClick(50);
    dropdownApi()
    sectorsdropdownApi()
  }, []);
  const [natureofEntity1, setNatureofEntity1] = useState([])
  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${'entities'}`, '', 'unauthget').then((result) => {
      if (result && result.data && result.data.dropDownValues && result.data.dropDownValues.values) {
        var values = result.data.dropDownValues.values
        setNatureofEntity1(values)
      }
    }).catch((err) => {

    });
  }
  const [sectors, setSectors] = useState([])
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
        if (result && result.data && result.data.sectors) {
          var values = result.data.sectors;
          setSectors(values);
        }
      })
      .catch((err) => { });
  };


  useDidMountEffect(() => {
    if (id !== null) {
      getApi()
    }
  }, [])
  const [selectedValues, setSelectedValues] = useState([])
  var id = localStorage.getItem("id");
  var type = localStorage.getItem('type')
  const getApi = () => {
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        if (result && result.data) {
          var data = result.data
          if (data.phone) {
            data.phone = cryptodecryptJS(data.phone);
          }
          if (data.email) {
            data.email = cryptodecryptJS(data.email);
          }
          if (data.pan) {
            data.pan = cryptodecryptJS(data.pan)
            // setPanTickIcon(1)
          }
          if (data.experience === 0) {
            data.experience = ''
          }
          setFormData(data)
          if (data.focusedSector) {
            setSelectedValues(data.focusedSector)
          }
        }
      }).catch((err) => {
      });
  }
  const handleSelectSector = (value) => {
    setValue("focusedSector", value);
    clearErrors('focusedSector');
    setSelectedValues(value);

  };
  const onSubmit = (data) => {
    console.log("Form Data:", data);
    let ids = [];
    data?.focusedSector?.forEach((name) => {
      sectors?.forEach((val) => {
        if (name === val?.name) {
          ids?.push({ name, id: val.id.toString() });
        }
      });
    });
    const getIds = ids.map((val) => val.id)
    data.persona = type
    data.userid = id
    data.phone = cryptoJS(data.phone)
    data.email = cryptoJS(data.email.toLowerCase())
    data.type = localStorage.getItem("role");
    data.pan = cryptoJS(data.pan.toUpperCase());
    const payload = {
      ...data, focusedSectorIds: getIds
    }
    apiService('userprofile/save', payload, 'post').then((result) => {
      if (result.data.responseStatus === 'Success') {
        navigator('/innovators/address')
      }
    }
    ).catch((err) => {
    });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService("danger", "Submit Failed", "Please check the mandatory fields");
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">Basic Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">1</span>/5
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  {
                    (role === "INNOVATOR" || role === "RESEARCHER") ? (<Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Full Name is required", validate: {
                            noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Full Name *"
                            placeholder="Enter Full Name"
                            fullWidth
                            type="text"
                            {...field}
                            error={Boolean(errors.name &&
                              errors.name)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.name &&
                          errors.name.message}
                      </FormHelperText>
                    </Grid>
                    ) : (<></>)}
                  {
                    (role === "RANDD-AGENCY") ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Company Name is required", validate: {
                              noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Company Name *"
                              placeholder="Enter Company Name"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(errors.name &&
                                errors.name)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.name &&
                            errors.name.message}
                        </FormHelperText>
                      </Grid>
                    ) : (<></>)}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Mail Address is required",
                        validate: validateEmail,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Mail Address *"
                          placeholder="Enter  Mail Address"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.email &&
                            errors.email)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </Grid>
                  {
                    (role === "INNOVATOR" || role === "RESEARCHER") ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                        <Controller
                          name="phone"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Phone Number is required",
                            validate: validatePhoneNumber,
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Phone Number *"
                              placeholder="Enter Phone Number"
                              fullWidth
                              type="text"
                              {...field}
                              onKeyDown={(e) => validateNumberonly(e)}
                              inputProps={
                                { maxLength: 10, minLength: 10 }
                              }
                              error={Boolean(errors.phone &&
                                errors.phone)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.phone && errors.phone.message}
                        </FormHelperText>
                      </Grid>
                    ) : (<></>)}
                  {
                    (role === "INNOVATOR" || role === "RESEARCHER") ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="educationQualification"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Education/Qualification is required", validate: {
                              noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Education/Qualification *"
                              placeholder="Enter Education/Qualification"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(errors.educationQualification &&
                                errors.educationQualification)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.educationQualification &&
                            errors.educationQualification.message}
                        </FormHelperText>
                      </Grid>
                    ) : (<></>)}

                  {
                    (role === "INNOVATOR" || role === "RESEARCHER") ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="experience"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Experience is required" }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Experience *"
                              placeholder="Enter Experience"
                              fullWidth
                              type="text"
                              {...field}
                              onKeyDown={(e) => validateNumberonly(e)}
                              inputProps={
                                { maxLength: 2, minLength: 1 }
                              }
                              error={Boolean(errors.experience &&
                                errors.experience)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.experience &&
                            errors.experience.message}
                        </FormHelperText>
                      </Grid>
                    ) : (<></>)}

                  {
                    (role === "RANDD-AGENCY") ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="researchEntityName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Research Entity Name is required", validate: {
                              noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Research Entity Name *"
                              placeholder="Enter Research Entity Name"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(errors.researchEntityName &&
                                errors.researchEntityName)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.researchEntityName &&
                            errors.researchEntityName.message}
                        </FormHelperText>
                      </Grid>
                    ) : (<></>)}

                  {
                    (role === "RESEARCHER") ? (
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="researchArea"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              disableClearable
                              options={natureofEntity1}
                              getOptionLabel={(option) => option}
                              defaultValue={[]}
                              value={value || []}
                              onChange={(event, newValue) => onChange(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Research of Area *"
                                  placeholder="Select Research of Area"
                                  error={!!error}
                                  helperText={
                                    error ? "Research of Area is required" : ""
                                  }
                                />
                              )}
                              fullWidth
                              margin="normal"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.researchArea && errors.researchArea.message}
                        </FormHelperText>
                      </Grid>
                    ) : (<></>)}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="focusedSector"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          {...field}
                          disableClearable
                          multiple={true}
                          options={sectors.map((list) => list.name)}
                          value={selectedValues}
                          onChange={(e, selectedOption) =>
                            handleSelectSector(selectedOption)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Focus Sector *"
                              placeholder="Select Focus Sector"
                              error={!!error}
                              helperText={
                                error ? "Focus Sector is required" : ""
                              }
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.focusedSector && errors.focusedSector.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="pan"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "PAN is required",
                        // validate: validatePANNumber
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="PAN *"
                          placeholder="Enter PAN"
                          fullWidth
                          type="text"
                          // inputProps={{ maxLength: 10 }}
                          // onKeyDown={(e) => checkUppernumeric(e)}
                          // onInput={(e) => {
                          //   handlePanChange(e);
                          // }}
                          {...field}
                          error={Boolean(errors.pan && errors.pan)}
                        // InputProps={{
                        //   endAdornment: panTickIcon === 1 ? (
                        //     <InputAdornment position="end">
                        //       <CheckCircleIcon color="success" />
                        //     </InputAdornment>
                        //   ) : panTickIcon === 2 ? (
                        //     <InputAdornment position="end">
                        //       <CloseIcon color="error" />
                        //     </InputAdornment>
                        //   ) : null,
                        // }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pan && errors.pan.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicinfoInnovators;

