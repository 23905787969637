import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";

import DataContext from "../../../../context/DataContext";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


const MentorAbout = ({ name ,url}) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const dep = ["Mentors"];
  const handleDepartment = (val) => {};
  const navigate = useNavigate();

  const aboutItems = [
    {
      aboutProgram: "One-on-One",
      aboutTitle:
        "One-on-one startup mentoring involves personalized guidance and support from experienced mentors to help entrepreneurs navigate challenges and optimize their business strategies.",
    },
    {
      aboutProgram: "Group Mentoring",
      aboutTitle:
        "Startup group mentoring facilitates collaborative learning and networking among a group of entrepreneurs guided by experienced mentors, fostering diverse perspectives and shared insights to accelerate business growth.",
    },
    {
      aboutProgram: "Cohort Mentoring",
      aboutTitle:
        "Cohort-based startup mentoring programs offer structured learning experiences where entrepreneurs progress through the program as a group, receiving guidance, feedback, and support from mentors and peers, fostering accountability and community.",
    },
    {
      aboutProgram: "Peer learning",
      aboutTitle:
        "Startup peer learning fosters knowledge exchange and mutual support among entrepreneurs within a community, enabling them to share experiences, insights, and resources to collectively overcome challenges and drive success.",
    },
    {
      aboutProgram: "Ask me Anything Sessions",
      aboutTitle:
        "Ask me anything (AMA) sessions offer an interactive platform for entrepreneurs to directly engage with experienced mentors, seeking advice, insights, and guidance on various aspects of their startup journey in real-time.",
    },
    {
      aboutProgram: "Reverse Mentoring",
      aboutTitle:
        "Reverse mentoring involves a non-traditional mentoring dynamic where younger or less experienced individuals mentor older or more experienced counterparts, typically focusing on sharing expertise in areas such as technology, social media, or cultural trends",
    },
  ];

  const handlebacked = () => {
    navigate(`/initiative/${url}/mentortn/home`)
  }

  return (
    <div className="smartCard-page">
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}  >  MentorTN`}</span>
                <h1 className="banner-text-gradient">MentorTN</h1>
                <h6>Right Mentor for the right founder!</h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Join Us</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <div className="mt-2">
                <Button className="about-backed" onClick={handlebacked}><FaArrowLeft />
                 Back</Button>
              </div>
              <div className="left-side-smartcardPage mt-5">
                <h4>Why Mentoring?</h4>
                <p>
                  Startups need mentoring for guidance, networking, and support.
                  Mentors provide valuable insights, help navigate challenges,
                  and offer access to networks and resources. They offer
                  accountability, help avoid pitfalls, and foster personal and
                  professional growth, ultimately increasing the startup's
                  chances of success in a competitive landscape.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="mentor-actions ">
                <button className="menotr-viewbtn">Join Mentoring</button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="join-us-now-sec1">
        <Container>
          <div className="service-crd mt-4">
            <Container>
              <Grid container spacing={3}>
                {aboutItems.map((item, i) => (
                  <Grid item xs={12} sm={6} md={3} key={i} className="aboutings">
                    <Card className="abouted-listed-crds1">
                      <div className="service-card-back-layers1">
                        <div className="abouted-crd-back-img1">
                          <div className="abouted-tam">
                            <p className="abouted-title-text">
                              {item.aboutProgram}
                            </p>
                            <p className="abouted-title-design">
                              {item.aboutTitle}
                            </p>
                          </div>
                        </div>
                      </div>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          padding: "24px 0px 0px 0px",
                        }}
                      >
                        <div className="mt-2">
                          <Button className="abouts-now">Join Now</Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default MentorAbout;
