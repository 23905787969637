import { InputBase, TextField, alpha, styled } from "@mui/material";
import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Grid,
} from "@mui/material";
import SupportFooter from "./SupportFooter";
// import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import apiService from "../../../api/apiService";
import DataContext from "../../../../context/DataContext";

const SupportTicketLayout = () => {
  const { setSearchInputValue } = useContext(DataContext)
  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  return (
    <div>
      <div className="container-fluid flu-class">
        <div className="bg-img"></div>
        <div className="set_size">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item sm={12} md={8} lg={8} xl={8}>
              <div className="title">
                <h2>Support Ticket</h2>
              </div>
            </Grid>
            <Grid item sm={12} md={4} lg={4} xl={4}>
              <div className="searchField">
                <FormControl fullWidth sx={{ width: 300 }}>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">
                        <BiSearchAlt sx={{ fontSize: '20px' }} />
                      </InputAdornment>
                    }
                    type="search"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </div>
        <Outlet></Outlet>
      </div>
      <SupportFooter />
    </div>
  );
};

export default SupportTicketLayout;
