import { Button, DialogContent, DialogTitle, FormHelperText, Grid, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DataContext from '../../../../context/DataContext';
import cryptoJS from '../../../../hooks/cryptoJS';
import apiService from '../../../../api/apiService';
import Captchatext from '../../../common-pages/Captchatext';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const VerifyPage = ({ handleClose, path }) => {
    const { handleSubmit, control, formState: { errors }, } = useForm({ mode: 'onBlur' });
    const { navigator, validateEmail, setPageLoading, captchaValue, setCaptchaValue } = useContext(DataContext);
    useDidMountEffect(() => {
        setCaptchaValue('')
    }, [])
    const onSubmit = (data) => {
        if (!captchaValue) {
            notifyService('danger', 'Enter Captcha', 'Please Verify  Captcha')
            return;
        }
        setPageLoading(true);
        var getform = data;
        getform.email = cryptoJS(data.email.toLowerCase());
        apiService('password/reset', getform, 'unauthpost').then((res) => {
            setPageLoading(false);
            if (res && res.data.responseStatus) {
                localStorage.setItem('logDetail', JSON.stringify(getform));
                handleClose()
                if (path === 'coreteam') {
                    navigator('/coreteam/verify');
                } else {
                    navigator('/sign-up/verify');
                }

            }
        });
    };

    const onError = (errors) => {
        console.log('Validation errors:', errors);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className='d-flex justify-content-between'>
                    <DialogTitle> Verify ! </DialogTitle>
                </div>
                <DialogContent sx={{ width: '400px' }}>
                    <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Email Address is required',
                                    validate: validateEmail,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Email Address"
                                        placeholder="Enter Email Address"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        error={Boolean(errors.email && errors.email)}
                                    />
                                )}
                            />
                            <FormHelperText className="text-danger">
                                {errors.email && errors.email.message}
                            </FormHelperText>

                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <Captchatext />
                        </Grid>
                        <div className='verify-actions'>
                            <Button variant='outlined' onClick={() => handleClose()}>Close</Button>
                            <Button type="submit" variant='contained' className='form-btn b-green-btn ml-3'>Send OTP</Button>
                        </div>
                    </Grid>
                </DialogContent>
            </form>
        </div>
    );
};

export default VerifyPage;
