import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Autoplay } from "swiper/modules";
import { FaPause, FaPlay } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import $ from "jquery";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Footer from "../../home-module/footer-page/Footer";
const GCC = ({ name }) => {
  const { navigator, setPageLoading, validateEmail } = useContext(DataContext);
  const [expanded, setExpanded] = useState("panel1");

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [eventList, setEventList] = useState([]);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const navigate = useNavigate();
  const handlePlayPause = () => {
      if (videoRef.current) {
          if (isPlaying) {
              videoRef.current.pause();
          } else {
              videoRef.current.play();
          }
          setIsPlaying(!isPlaying);
      }
  };
  useEffect(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
          const handleVideoEnd = () => {
              setIsPlaying(false);
          };

          videoElement.addEventListener('ended', handleVideoEnd);

          return () => {
              videoElement.removeEventListener('ended', handleVideoEnd);
          };
      }
  }, []);
  const handlenow = () => {
    navigate('/login')
}
  const cardItems = [
    {
      cardimg: "/images/Key-1.png",
      cardTitle: "Co-Working Spaces",
      cardDetails:
        "Top-tier workstations with high-speed internet and meeting room access. A collaborative space for networking and idea-sharing with fellow entrepreneurs.",
    },
    {
      cardimg: "/images/Key-2.png",
      cardTitle: "Exclusive Support Desk",
      cardDetails:
        "Comprehensive operational support for smooth business functioning. Liaison services to bridge communication between founders and stakeholders.",
    },
    {
      cardimg: "/images/Key-3.png",
      cardTitle: "Legal Guidance",
      cardDetails:
        "Startups receive expert legal support for navigating company setup, licensing, immigration, and local laws and ensure startups meet regulatory standards, minimizing risks .",
    },
    {
      cardimg: "/images/Key-4.png",
      cardTitle: "Access to Capital",
      cardDetails:
        "Facilitates startup funding through venture capital, angel investors, and strategic partnerships and connects startups with investors to secure essential growth capital.",
    },
    {
      cardimg: "/images/Key-5.png",
      cardTitle: "Business Consulting",
      cardDetails:
        "Startups receive tailored advice on market entry, business development, and growth strategies and Business advisors offer in-depth knowledge on market dynamics, regulations, and potential opportunities.",
    },
    {
      cardimg: "/images/Key-2.png",
      cardTitle: "Exclusive Support Desk",
      cardDetails:
        "Comprehensive operational support for smooth business functioning. Liaison services to bridge communication between founders and stakeholders.",
    },
    {
      cardimg: "/images/Key-3.png",
      cardTitle: "Legal Guidance",
      cardDetails:
        "Startups receive expert legal support for navigating company setup, licensing, immigration, and local laws and ensure startups meet regulatory standards, minimizing risks .",
    },
    {
      cardimg: "/images/Key-4.png",
      cardTitle: "Access to Capital",
      cardDetails:
        "Facilitates startup funding through venture capital, angel investors, and strategic partnerships and connects startups with investors to secure essential growth capital.",
    },
    {
      cardimg: "/images/Key-5.png",
      cardTitle: "Business Consulting",
      cardDetails:
        "Startups receive tailored advice on market entry, business development, and growth strategies and Business advisors offer in-depth knowledge on market dynamics, regulations, and potential opportunities.",
    },

  ];
  const additionalBenefits = [
    {
      circle:"/images/circle-1.png",
      title: 'Opportunities for Networking',
      content: [
        {
          content1: 'Industry Events: The GCC hosts networking opportunities, workshops, and informational seminars for your startup to link up with industry experts, potential clients, investors, and other entrepreneurs.',
          content2: 'Strategic connections: Events provide an opportunity for young companies to create strategic partnerships and alliances that are necessary for business development and market presence'
        }
      ]
    },
    {
      circle:"/images/circle-2.png",
      title: 'Soft Landing',
      content: [
        {
          content1: 'Business Facilitation: The GCC assists in all soft-landing services, which include business connections, mentoring networks, and local market navigation support.',
          content2: 'Holistic support: From registering the entity and compliance certifications to access to the market and operational support is offered so that startups can ensure their transition smoothly to be successful.'
        }
      ]
    },
    {
      circle:"/images/circle-3.png",
      title: 'Programs for Capacity Building',
      content: [
        {
          content1: 'Skill Development: Activities conducted to provide capacity-building support for the enhancement of skills and capabilities of startup founders through programs of mentoring sessions, workshops on skill development, and investor connect.',
          content2: 'Resource Access: The resources gained by the startup shall include feasibility studies, business model optimization, expansion planning, and analysis of market trends.'
        }
      ]
    },
    {
      circle:"/images/circle-2.png",
      title: 'Marketing and Branding ',
      content: [
        {
          content1: 'Brand building: GCC provides marketing and branding facilities to startups by making them known by creating brand awareness, attracting customers, and placing the right product or services with safety.',
          content2: 'Strategic Positioning: Services on market-entry campaigns, public relations, and digital marketing strategy aimed at the targeted audience.'
        }
      ]
    },
    {
      circle:"/images/circle-1.png",
      title: 'Mentorship and Coaching',
      content: [
        {
          content1: 'Experienced Mentors: Provide startups with qualified mentoring services and industry experts that give them essential insights, advice, and guidance along their growth journey.',
          content2: 'Continuous Support: Ongoing mentorship will ensure that start-ups continue to receive support and feedback perpetually as they go through the challenges, moving toward realizing their business objectives'
        }
      ]
    },
    {
      circle:"/images/circle-2.png",
      title: 'Partnerships and Collaborations',
      content: [
        {
          content1: 'Strategic alliances: GCC enables the business to enter strategic alliances, partnerships, and collaborations with local enterprises and organizations that fortify market presence and offer new growth opportunities.',
          content2: 'Resulting collaborative projects : Startups can implement collaborative projects innovatively that grows their business.'
        }
      ]
    },
    {
      circle:"/images/circle-3.png",
      title: 'Ongoing Support and Resources',
      content: [
        {
          content1: 'Continued Support: GCC provides ongoing support and resources by providing education materials based on what growing startups require.',
          content2: 'Workshops on Education: Startups can access online instruments, educational workshops, and industry reports to remain informed and knowledgeable for success.'
        }
      ]
    }
  ]
  useEffect(() => {
    size();
  }, []);
  const [screenSize, setScreenSize] = useState(4);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };
  const colors = ["#6100FF", "#00A3FF", "#8B44E5",];
  return (
    <div >
     <div className="gccbanner-page">
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="banner-text">
                            <div className="gcco-chip-text">
                                <span>{`BeyondTN  >  GCC`}</span>
                                <h1 className="banner-text-gradient">StartupTN - GLobal</h1>
                                <h4>(GLOBAL CO-ORDINATION CENTER)</h4>
                                <h6>
                                    The Global Coordination Centre (GCC) of StartupTN is a premier hub designed to elevate Tamil Nadu startups onto the global stage. It offers co-working spaces, expert business consultation, networking opportunities, legal and regulatory guidance, and access to capital and strategic partnerships. Serving as a conduit, the GCC enables startups to explore and thrive in international markets.
                                </h6>
                                <div className="banner-content-actions">
                                    <button className="banner-viewbtn">Apply for GCC</button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* <Container>
                    <div className="dubai-set">
                        <h2 className="chap-dub">Dubai Chapter</h2>
                        <p className="dub-glob mt-4">The Global Coordination Centre is a strategic initiative developed with Startup Middle East to provide comprehensive support to the Startups of Tamil Nadu interested in exploring and penetrating the market of the Middle East region. Located in Dubai, UAE, the Global Coordination Centre is an intrinsic base and pivotal to offer the bouquet of services that can enable market-entry businesses in the growth phase of Tamil Nadu.</p>
                    </div>
                    <div className="containerv2">
                        <div className="dubai-background">
                            {!isPlaying ? <img src="/images/Banner/video-template.webp" className="dubai-thumbnail" alt="thumbnail-image" /> : <></>}

                            <video ref={videoRef} className="dubai-video">
                                <source src="/images/Banner/vid-1.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <button className="dubai-play-button" onClick={handlePlayPause}>
                                {isPlaying ? <FaPause /> : <FaPlay />}
                            </button>
                        </div>
                    </div>
                </Container> */}
      <div className="smart-card-info">
        <section className="Key-Offerings-bg">
          <Container>

            <div className="service-partners-titles text-center">
              <h4>Key Offerings</h4>
              <div className="Offerings">
                <img src="/images/Key-img.png" alt="" />
              </div>
              <p>
                Explore the key offerings that make our center a strategic asset for your business journey.</p>
            </div>
            <div className="mt-5 Offerings-padding">
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Swiper
                  slidesPerView={screenSize}
                  spaceBetween={30}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="my-Swiper"
                  style={{ width: '100%', margin: '0px auto' }}
                >
                  {cardItems &&
                    cardItems.map((a, index) => (
                      <SwiperSlide key={index}>
                        <div className="Offerings-box">
                          <img
                            src={a.cardimg}
                            alt={a.cardTitle}
                            className="card-image "
                          />
                          <div className="key-box-content">
                            <h5 className="key-cardtitle  mb-4">{a.cardTitle}</h5>
                            <span className="key-carddetail  mb-4">{a.cardDetails}</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </Grid>
            </div>
          </Container>
        </section>
        <Container>
          <div className="my-5 Offerings-padding">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing ={5}
            >
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <div className="Additional-Benefits-div mt-4">
                  <h4>Additional Benefits </h4>
                  <span>What Benefits You Get from GCC</span>
                  <button>Explore all <GoArrowRight /></button>
                </div>
              </Grid>
              {additionalBenefits &&
                    additionalBenefits.map((val, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
               
                <div className="Additional-Benefits-div mt-3">
                  <img src={val.circle} alt="" />
                  <h6 className="mt-4">{val.title}</h6>
                  <ul className="additionalBenefits-li">
          {val.content.map((item, contentIndex) => (
            <React.Fragment key={contentIndex}>
              {item.content1 && <li>{item.content1}</li>}
              {item.content2 && <li>{item.content2}</li>}
            </React.Fragment>
          ))}
        </ul>
                 
                </div>
              </Grid>
                ))}
            </Grid>
          </div>
        </Container>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>Let’s Get Started</h3>
                    <p>Beyond TN </p>
                    <p>
                      Bridging Tamil Nadu Startups with Global opportunities
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <Footer/>
    </div>
  );
};
export default GCC;
