import { FormHelperText, TextField, Grid, Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import DataContext from '../../../context/DataContext'
import notifyService from '../../../api/notifySerivce'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Viewimage from '../../../api/Viewimage'
import apiService from '../../../api/apiService'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import cryptoJS from '../../../hooks/cryptoJS'
import CloudDoneIcon from '@mui/icons-material/CloudDone';

const NodalProfileupdate = ({ }) => {
    const {
        navigator,
        setPageLoading,
        VisuallyHiddenInput,
        isWhitespace,
        urlPattern, updateopen, setUpdateopen, updateModalopend, handleModalClosed, responseCode
    } = useContext(DataContext);
    const [formData, setFormData] = useState({})
    var depId = localStorage.getItem('departmentId')
    const {
        handleSubmit,
        control,
        setValue,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm({ values: formData });
    useDidMountEffect(() => {
        departmentGetApi()
    }, []);


    const [open, setOpen] = useState(false);
    const [viewImage, setViewImage] = useState("");
    const [viewImageName, setViewImageName] = useState("");
    const handleClickOpen = (value, name) => {
        setViewImage(value);
        setViewImageName(name);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    let logoselecetdFile = "";
    const handleFileUpload = (event, filekey, fileName, fileUrl, format) => {
        if (event !== null) {
            if (event.target === undefined) {
                logoselecetdFile = event
            } else {
                logoselecetdFile = event.target.files[0]
            }
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (logoselecetdFile) {
                if (logoselecetdFile.size < maxFileSize) {
                    var reader = new FileReader();
                    var imagetype = logoselecetdFile.type;
                    var imagedatatype = imagetype.split("/");
                    var img_crt_type = imagedatatype[1];
                    if (
                        ((format === 'file') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
                        ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
                    ) {
                        setValue(filekey, "");
                        setValue(fileName, "");
                        setValue(fileUrl, '')
                        var fileValue = logoselecetdFile;
                        reader.readAsDataURL(logoselecetdFile);
                        reader.onload = () => {
                            var logourl1 = reader.result;
                            var spl = logourl1.split(",");
                            var ImageValue = spl[1];
                            var img_name = fileValue.name;
                            // setUrl(logourl1)
                            setValue(filekey, ImageValue);
                            setValue(fileName, img_name);
                            setValue(fileUrl, logourl1)
                            clearErrors(filekey);
                            clearErrors(fileName);
                            clearErrors(fileUrl);
                            // 
                            // clearErrors('dpiitCertificateName')
                        };
                    } else {
                        notifyService(
                            "danger",
                            "File Format Invalided",
                            "Please check your file format"
                        );
                    }
                } else {
                    notifyService(
                        "danger",
                        "File Size Exceeded",
                        `Maximum file size allowed is 5 MB`
                    );
                }
            }
        }
    };

    const departmentGetApi = (id) => {
        setFormData({})
        setPageLoading(true)
        apiService(`department/get?id=${Number(depId)}`, "", "get")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    var data = result.data

                    if (data.logoName) {
                        data.logoUrl = data.logoName
                    }
                    setFormData(data)

                }
            })
            .catch((err) => { });
    }
    const login = () => {

        let storedData = JSON.parse(localStorage.getItem('userDatas'));
        let getForm = storedData
        getForm.type = storedData.type;
        getForm.userName = storedData.userName;
        getForm.password = storedData.password;
        debugger
        setPageLoading(true);
        apiService('login', getForm, 'post').then((res) => {
            localStorage.setItem('continueWith', false)
            setPageLoading(false);
            responseCode(res)



        }).catch((err) => {

        })
    };
    const onSubmit = (datas) => {
        console.log(datas);
        setPageLoading(true);
        apiService(`department/update`, datas, "post")
            .then((result) => {
                setPageLoading(false);
                if (result?.data?.responseStatus === "Success") {
                    setUpdateopen(false)
                    navigator("/admin/project/list");
                    notifyService("success", "Success", "Department Details Updated");
                    login()
                }

            }).catch((err) => {
                console.log(err)
            });


    }
    const onError = (event) => {
        console.log("error Data:", event);
        notifyService(
            "danger",
            "Submit Failed",
            "Please check the mandatory fields"
        );
    };
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div >
                    <p><b>Please Update your Profile</b></p>
                </div>
                <div>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <div className="input_background">
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Department Name is required', validate: {
                                            noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Department Name *"
                                            placeholder="Enter Department Name"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            error={Boolean(errors.name && errors.name)}
                                            inputProps={{
                                                maxLength: 100,
                                                readOnly: true
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.name && errors.name.message}
                                </FormHelperText>
                            </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <div className="input_background">
                                <Controller
                                    name="shortName"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: false, 
                                        // validate: {
                                        //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                        // },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Department (Short Name)"
                                            placeholder="Enter Department (Short Name)"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            error={Boolean(errors.shortName && errors.shortName)}
                                            inputProps={{
                                                maxLength: 9,
                                            }}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.shortName && errors.shortName.message}
                                </FormHelperText>
                            </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <div className="input_background">
                                <Controller
                                    name="departmentCategory"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Category is required', validate: {
                                            noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Department Category *"
                                            placeholder="Enter Department category"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            error={Boolean(errors.departmentCategory && errors.departmentCategory)}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.departmentCategory && errors.departmentCategory.message}
                                </FormHelperText>
                            </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <div className="input_background">
                                <Controller
                                    name="about"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Description is required",
                                        validate: {
                                            noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                        },

                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id="outlined-multiline-static"
                                            placeholder="Enter your Description "
                                            label="Description *"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            inputProps={
                                                { maxLength: 500 }
                                            }
                                            defaultValue=""
                                            error={Boolean(errors.about && errors.about)}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.about && errors.about.message}
                                </FormHelperText>
                            </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                            <div className="input_background">
                                <Controller
                                    name="webSite"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "website is required",
                                        validate: {
                                            pattern: (value) =>
                                                /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/\S*)?$/.test(value) || "Please enter valid  Website"

                                        }
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            variant="outlined"
                                            label="Website URL *"
                                            placeholder="Enter Website URL"
                                            fullWidth
                                            type="text"
                                            {...field}
                                            error={Boolean(errors.webSite && errors.webSite)}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.webSite && errors.webSite.message}
                                </FormHelperText>
                            </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {getValues('logoUrl') ? (
                                <>
                                    <div className="input_background">
                                        <p className="mb-3">Upload Department Logo</p>
                                        <div className="row">
                                            <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3 text-center">
                                                <Controller
                                                    name="logoName"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Project logo  is required" }}
                                                    render={({ field }) => (
                                                        <Button
                                                            component="label"
                                                            variant="contained"
                                                            color='success'
                                                            {...field}
                                                            sx={{ width: '90%',textTransform:'none',fontSize:'11px',padding:'8px 0px' }}
                                                            onChange={(e) =>
                                                                handleFileUpload(e, "logo", "logoName", "logoUrl", 'image')
                                                            }
                                                            startIcon={<CloudDoneIcon  />}
                                                            href="#file-upload"
                                                        >
                                                           Uploaded ( Click to Change)
                                                            <VisuallyHiddenInput type="file" />
                                                        </Button>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                                                <Button
                                                    className="c-pointer ml-2"
                                                    size="medium"
                                                    sx={{ width: '90%' }}
                                                    variant="outlined"
                                                    onClick={() =>
                                                        handleClickOpen(
                                                            getValues("logo"),
                                                            getValues("logoUrl"),
                                                        )
                                                    }
                                                >
                                                    Preview
                                                </Button>
                                            </Grid>
                                        </div>
                                        <div>
                                            <span className="font-sm d-flex  mt-1 ">
                                                Recommended Image Size 126 X 100 (Px)<br />
                                                Max size upto 5mb <br />
                                                Accepted Format: jpeg, jpg, png
                                                <span className="text-danger">*</span>
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="input_background">
                                    <Controller
                                        name="logoName"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: "Department logo is required" }}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                label="Department logo *"
                                                placeholder="Upload logo"
                                                fullWidth
                                                type="text"
                                                {...field}
                                                disabled
                                            />
                                        )}
                                    />
                                    <FormHelperText className="text-danger">
                                        {errors.logoName && errors.logoName.message}
                                    </FormHelperText>
                                    <div className="d-flex align-items-center w-100 my-3">
                                        <p className="font-sm">
                                            Recommended Image Size 1192 X 300 (Px)<br />
                                            Max size upto 5mb <br />
                                            Accepted Format: jpeg, jpg, png
                                        </p>
                                        <label
                                            className="ml-auto lbl_btn"
                                            onChange={(e) =>
                                                handleFileUpload(e, "logo", "logoName", "logoUrl", 'image')
                                            }
                                            href="#file-upload"
                                        >
                                            <img
                                                src="/images/admin/upload.png"
                                                className="mr-3"
                                                alt=""
                                            />
                                            Upload
                                            <VisuallyHiddenInput type="file" />
                                        </label>
                                    </div>
                                </div>
                            )}
                            
                        </Grid>
                    </Grid>
                </div>
                <div className="text-center mt-5">
                    <Button className="discard-btn" onClick={() => handleModalClosed()}>
                        Close
                    </Button>
                    <Button type="submit" className="publish-button">
                        Save
                    </Button>
                </div>
            </form>
            <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
            />
        </div>
    )
}

export default NodalProfileupdate
