import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

const InvestorEventFilters = () => {

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <section className=''>
        <div className='investor-filters'>
          <TabContext value={value} className="tab-div">
            <div className="inv-name-tab-surya">
              <Box className="inv-tabs-boxs-surya">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  className='scroll-inv-tabs'
                >
                  <NavLink
                    to={"/investor/event-session/notification"}
                    className="profile-tab-sty-surya"
                  >
                    Event Notification
                  </NavLink>
                  <NavLink
                    to={"/investor/event-session/appointments"}
                    className="profile-tab-sty-surya"
                  >
                    Appointments
                  </NavLink>
                  <NavLink
                    to={"/investor/event-session/mentoring"}
                    className="profile-tab-sty-surya"
                  >
                    Mentoring Sessions
                  </NavLink>
                  <NavLink
                    to={"/investor/event-session/my-events"}
                    className="profile-tab-sty-surya"
                  >
                    My events
                  </NavLink>
                </Tabs>
              </Box>
            </div>
          </TabContext>
        </div>
      </section>
    </>
  )
}

export default InvestorEventFilters