import React from 'react'

import { Outlet } from 'react-router-dom'
import Navbar from '../../home-module/navbar-page/Navbar'
import Footer from '../../home-module/footer-page/Footer'
// import Navbar from '../../home-module/Navbar'

const Medialayout = () => {
  return (
    <div >
      <Navbar/>
      <Outlet></Outlet>
      <Footer />
    </div>
  )
}

export default Medialayout
