import React, { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import DataContext from '../context/DataContext';
import Profilelayout from './auth-module/profile-type-page/Profilelayout';
import Profiletype from './auth-module/profile-type-page/Profiletype';
import Profilerole from './auth-module/profile-type-page/Profilerole';
import Signup from './auth-module/signup-details-page/signup-page/Signup';
import Signuplayout from './auth-module/signup-details-page/Signuplayout';
import Verifyotp from './auth-module/signup-details-page/signup-page/Verifyotp';
import Startuproutes from './profile-details/startup-profile/Startuproutes';
import Ecosystemroutes from './profile-details/ecosystem-enablers-profile/Ecosystemroutes';
import ServiceproviderRoutes from './profile-details/service-providers-profile/ServiceproviderRoutes';
import Aspirantsroutes from './profile-details/aspirants-profile/Aspirantsroutes';
import Innovatorroutes from './profile-details/innovators-profile/Innovatorroutes';
import Mentorsroutes from './profile-details/mentors-profile/Mentorsroutes';
import Investmentroutes from './profile-details/investment-firms-profile/Investmentroutes';
import Login from './auth-module/login-details-page/Login';
import Adminroutes from './admin-module/Adminroutes';
import Userroutes from './user-module/Userroutes';
import CoreTeamRoutes from './profile-details/core-team-register/CoreTeamRoutes';
import Mediaroute from './Media-module/Mediaroute';
import EcoSystemPage from './home-module/eco-system-page/EcoSystemPage';
import HomePage from './home-module/home-page/HomePage';
// import SmartCardPage from './home-module/smart-card-page/SmartCardPage';
import LinkedInCallback from './auth-module/continue-with/LinkedInCallback';
import InitiativeRoutes from './initiative-module/InitiativeRoutes';
import Undermaintenance from './common-pages/under-maintenance-page/Undermaintenance';
// import SupportSmartCardService from './home-module/smart-card-page/SupportSmartCardService';
// import AboutCompany from './home-module/smart-card-page/AboutCompany';
import SmartCardRoute from './smart-card-module/SmartCardRoute';
import ExplorePage from './home-module/explore-page/ExplorePage';
import TermsAndCondition from './home-module/home-page/TermsAndCondition';
import PrivacyPolicy from './home-module/home-page/PrivacyPolicy';
import CoreteamSignup from './auth-module/signup-details-page/coreteam-page/CoreteamSignup';
import CoreteamVerifyotp from './auth-module/signup-details-page/coreteam-page/CoreteamVerifyotp';

import Sectortaps from './home-module/eco-system-page/Sectortaps';
import Aerospace from './home-module/eco-system-page/eco-system-components/Aerospace';
import FoodTech from './home-module/eco-system-page/eco-system-components/FoodTech';
import Alml from './home-module/eco-system-page/eco-system-components/Alml';
import Artculture from './home-module/eco-system-page/eco-system-components/Artculture';
import Automotive from './home-module/eco-system-page/eco-system-components/Automotive';
import Blueeconomy from './home-module/eco-system-page/eco-system-components/Blueeconomy';
import Tender from './home-module/Side-menu-pages/Tender';
import Careers from './home-module/Side-menu-pages/Careers';
import PoliciesNotification from './home-module/Side-menu-pages/PoliciesNotification';
import PublicProcurement from './home-module/Side-menu-pages/PublicProcurement';
import Hrtech from './home-module/eco-system-page/eco-system-components/Hrtech';
import Itites from './home-module/eco-system-page/eco-system-components/Itites';
import Climatetech from './home-module/eco-system-page/eco-system-components/Climatetech';
import Proplegal from './home-module/eco-system-page/eco-system-components/Proplegal';
import Retailtech from './home-module/eco-system-page/eco-system-components/Retailtech';
import Smartcities from './home-module/eco-system-page/eco-system-components/Smartcities';
import Socialimpact from './home-module/eco-system-page/eco-system-components/Socialimpact';
import Sports from './home-module/eco-system-page/eco-system-components/Sports';
import Supplychain from './home-module/eco-system-page/eco-system-components/Supplychain';
import Telecom from './home-module/eco-system-page/eco-system-components/Telecom';
import Websector from './home-module/eco-system-page/eco-system-components/Websector';
import Travel from './home-module/eco-system-page/eco-system-components/Travel';
import Textiletech from './home-module/eco-system-page/eco-system-components/Textiletech';
import Chemicals from './home-module/eco-system-page/eco-system-components/Chemicals';
import Circulareconomy from './home-module/eco-system-page/eco-system-components/Circulareconomy';
import MarketingTech from './home-module/eco-system-page/eco-system-components/MarketingTech';
import Datamining from './home-module/eco-system-page/eco-system-components/Datamining';
import Edtech from './home-module/eco-system-page/eco-system-components/Edtech';
import Femtech from './home-module/eco-system-page/eco-system-components/Femtech';
import Fintech from './home-module/eco-system-page/eco-system-components/Fintech';
import Healthcare from './home-module/eco-system-page/eco-system-components/Healthcare';
import Industry from './home-module/eco-system-page/eco-system-components/Industry';
import Lifestyle from './home-module/eco-system-page/eco-system-components/Lifestyle';
import Mediaandentertainment from './home-module/eco-system-page/eco-system-components/Mediaandentertainment';
import ResourcePage from './home-module/resource-page/ResourcePage';
import GrievanceMechanism from './home-module/Side-menu-pages/GrievanceMechanism';
import ContactUs from './home-module/Side-menu-pages/ContactUs';
import ComingSoon from './common-pages/coming-soon-page/ComingSoon';
// import Fest from './home-module/home-page/Tn_fest/Fest';
// import Festlayout from './home-module/home-page/Tn_fest/Festlayout';
// import TnfestPrivacypolicy from './home-module/home-page/Tn_fest/TnfestPrivacypolicy';
// import Tnfestrefundandcancellation from './home-module/home-page/Tn_fest/Tnfestrefundandcancellation';
// import Tnfesttesrmsandcondition from './home-module/home-page/Tn_fest/Tnfesttesrmsandcondition';
// import Tnfestticketcollectionpolicy from './home-module/home-page/Tn_fest/Tnfestticketcollectionpolicy';
// import TnfestProductpricing from './home-module/home-page/Tn_fest/TnfestProductpricing';
import SessionTimeoutPopup from './common-pages/SessionTimeoutPopup'
import DepartmentPage from './home-module/department-page/DepartmentPage';
import UpdatePersona from './auth-module/login-details-page/UpdatePersona';
import { Dialog, DialogContent } from '@mui/material';
const Baseroute = () => {
  const { pageLoading, showPopup, setShowPopup,navigator } = useContext(DataContext)
  const [tokOpen, setTokOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  useEffect(() => {
    checkAuthToken(); 
  }, []);
  const handleClickOpen = () => {
    setTokOpen(true);
  };

  const handleModalClose = () => {
    setTokOpen(false);
  };
 
    const extractAuthToken = (token) => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };
   
    const checkAuthToken = () => {
      const authToken = localStorage.getItem('jwttoken'); 
      const tokenData = extractAuthToken(authToken);
      if (authToken && tokenData) {
        const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
        if (currentTime >= tokenData.exp) {
          console.log("Token expired, redirecting to login...");
          setTokOpen(true);
          localStorage.clear(); 
          setTimeout(() => {
            handleModalClose()
          }, 3000);
          navigator('/login');
         
        }
      } 
      // else {
      //   console.log("No valid token found, redirecting to login...");
      //   setTokOpen(true);
      //   debugger
      //   localStorage.clear(); 
      //   setTimeout(() => {
      //     handleModalClose()
      //   }, 3000);
      //   navigator('/login');
      // }
    };
  const extendSession = () => {
    setShowPopup(false);
    sessionStorage.removeItem('session')
    localStorage.clear()
    sessionStorage.clear()
    window.location.assign('/')
    // Logic to extend the session goes here
  };
  const onContinue = () => {
    sessionStorage.removeItem('session')
    setShowPopup(false)
  }
  return (
    <div>
      {pageLoading && (
        <div className='loader_div'>
          <div class="ring"></div>
          <div class="ring"></div>
          <div class="ring"></div>
        </div>
      )}
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path='/terms-condition' element={<TermsAndCondition />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          {/* <Route path='/tnfest-privacy-policy' element={<TnfestPrivacypolicy />} /> */}
          {/* <Route path='/tnfest-terms-condition' element={<Tnfesttesrmsandcondition />} /> */}
          {/* <Route path='/tnfest-refund-cancellation' element={<Tnfestrefundandcancellation />} /> */}
          {/* <Route path='/tnfest-ticketcollection-policy' element={<Tnfestticketcollectionpolicy />} /> */}
          {/* <Route path='/tnfest-product-policy' element={<TnfestProductpricing />} /> */}
          <Route path="/department" element={<DepartmentPage />} />
          <Route path="/ecosystem-info" element={<EcoSystemPage />} />
          <Route path="/resource-page" element={<ResourcePage />} />
          <Route path='/grievance-mechanism' element={<GrievanceMechanism />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          {/* <Route path='/fest' element={<Fest/>}/> */}
          {/* <Route path='/fest' element={<Festlayout />} /> */}

          <Route path='/tender' element={<Tender />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/policy-notification' element={<ResourcePage />} />
          <Route path='/public-procurement' element={<PublicProcurement />} />
          <Route path="/ecosystem-info/aerospace/:name" element={<Aerospace />} />
          <Route path="/ecosystem-info/foodtech/:name" element={<FoodTech />} />
          <Route path="/ecosystem-info/al-ml/:name" element={<Alml />} />
          <Route path="/ecosystem-info/artculture/:name" element={<Artculture />} />
          <Route path="/ecosystem-info/automotive/:name" element={<Automotive />} />
          <Route path="/ecosystem-info/blue-economy/:name" element={<Blueeconomy />} />
          <Route path="/ecosystem-info/hrtechsmartworkforce/:name" element={<Hrtech />} />
          <Route path="/ecosystem-info/itites/:name" element={<Itites />} />
          <Route path="/ecosystem-info/climatetech/:name" element={<Climatetech />} />
          <Route path="/ecosystem-info/prop/:name" element={<Proplegal />} />
          <Route path="/ecosystem-info/retailtech/:name" element={<Retailtech />} />
          <Route path="/ecosystem-info/smartcities/:name" element={<Smartcities />} />
          <Route path="/ecosystem-info/socialimpact/:name" element={<Socialimpact />} />
          <Route path="/ecosystem-info/sports/:name" element={<Sports />} />
          <Route path="/ecosystem-info/supplychain/:name" element={<Supplychain />} />
          <Route path="/ecosystem-info/telecom/:name" element={<Telecom />} />
          <Route path="/ecosystem-info/websector/:name" element={<Websector />} />
          <Route path="/ecosystem-info/travel/:name" element={<Travel />} />
          <Route path="/ecosystem-info/textiletech/:name" element={<Textiletech />} />
          <Route path="/ecosystem-info/chemicals/:name" element={<Chemicals />} />
          <Route path="/ecosystem-info/circulareconomy/:name" element={<Circulareconomy />} />
          <Route path="/ecosystem-info/marketingtech/:name" element={<MarketingTech />} />
          <Route path="/ecosystem-info/datamining/:name" element={<Datamining />} />
          <Route path="/ecosystem-info/edtech/:name" element={<Edtech />} />
          <Route path="/ecosystem-info/femtech/:name" element={<Femtech />} />
          <Route path="/ecosystem-info/fintech/:name" element={<Fintech />} />
          <Route path="/ecosystem-info/healthcare/:name" element={<Healthcare />} />
          <Route path="/ecosystem-info/industry/:name" element={<Industry />} />
          <Route path="/ecosystem-info/lifestyle/:name" element={<Lifestyle />} />
          <Route path="/ecosystem-info/mediaandentertainment/:name" element={<Mediaandentertainment />} />

          <Route path='/login' element={<Login />} />
          <Route path='/letsstart/:id' element={<UpdatePersona />} />
          <Route path='/login/linkedin' element={<LinkedInCallback />} />
          <Route path='/profile' element={<Profilelayout />}>
            <Route path="/profile" element={<Navigate replace to="/profile/type" />} />
            <Route path='/profile/type' element={<Profiletype />} />
            <Route path='/profile/:type' element={<Profilerole />} />
          </Route>
          <Route path='/sign-up' element={<Signuplayout />}>
            <Route path="/sign-up" element={<Navigate replace to="/sign-up/info" />} />
            <Route path='/sign-up/info' element={<Signup />} />
            <Route path='/sign-up/verify' element={<Verifyotp />} />
          </Route>
          <Route path='/coreteam' element={<Signuplayout />}>
            <Route path="/coreteam" element={<Navigate replace to="/coreteam/sign-up" />} />
            <Route path='/coreteam/sign-up' element={<CoreteamSignup />} />
            <Route path='/coreteam/verify' element={<CoreteamVerifyotp />} />
          </Route>
          <Route path='/explore/:name/:id' element={<ExplorePage path="filter"/>} />
          <Route path='/explore/:name' element={<ExplorePage path="service"/>} />

        </Routes>
        <SmartCardRoute />
        <Adminroutes />
        <Startuproutes />
        <Aspirantsroutes />
        <Ecosystemroutes />
        <ServiceproviderRoutes />
        <Innovatorroutes />
        <Mentorsroutes />
        <Investmentroutes />
        <Userroutes />
        <Mediaroute />
        <InitiativeRoutes />
        <SessionTimeoutPopup show={showPopup} onClose={extendSession} onContinue={onContinue} />
      </main>
      <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={tokOpen}
          onClose={handleModalClose}
        >

          <DialogContent>
          <div className="text-center">
              <img src={'/images/logo/expired.gif'} alt="" className="sucess-image" />
              <h4>Whoops, Your session has expired.</h4>
              <p>No Worry,simply login again</p>
            </div>
          </DialogContent>
        </Dialog>
    </div>
  )
}

export default Baseroute