import axios from 'axios';
import React from 'react'
import useDidMountEffect from '../../../hooks/useDidMountEffect';

const LinkedInCallback = () => {
    const callBack = `${process.env.REACT_APP_LOGINCALLBACK}`;
    useDidMountEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            getToken(code);
        }
    }, []);
    const getToken = async (code) => {
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('code', code);
        params.append('redirect_uri', callBack);
        params.append('client_id', '865drszkyuas84');
        params.append('client_secret', 'mr8Bf0DzY3XElto2');
        fetch('https://www.linkedin.com/oauth/v2/accessToken', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params.toString(),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorInfo => Promise.reject(errorInfo));
                }
                return response.json();
            })
            .then(data => {
                console.log('Access token:', data);
            })
            .catch(error => {
                console.error('Error fetching the access token:', error);
            });

    };
    return <div>Loading...</div>;
};


export default LinkedInCallback