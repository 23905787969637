import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Mediaandentertainment = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTNsectorMedia-Entertainment.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Media & Entertainment</h3>
        </div>
        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <ul>
                    <li>
                      The Union Government has enhanced the scope of foreign
                      direct investment (FDI) by allowing 100% FDI in various
                      sectors such as Teleports, DTH, Multi-System Operator,
                      cable networks in DAS areas, mobile TV and
                      Headend-in-the-Sky Broadcasting Services. This move brings
                      a much-needed boost to the industry and opens up new
                      opportunities for growth.
                    </li>
                    <br />
                    <li>
                      Moreover, recognising the importance of the film industry,
                      it has been granted industry status, making it easier for
                      filmmakers to access institutional finance.
                    </li>
                    <br />
                    <li>
                      To simplify the process further, the Ministry of
                      Information and Broadcasting has established the Film
                      Facilitation Office (FFO) as a one-stop solution for
                      producers and production companies. The FFO acts as a
                      single window clearance and facilitation point, assisting
                      them in obtaining the necessary filming permissions.
                    </li>
                    <br />
                    <li>
                      In December 2020, the Film Media Units were merged under
                      one corporation, leading to the convergence of activities
                      and resources. This strategic move ensures better synergy
                      and efficiency, ultimately helping each media unit achieve
                      its mandate more effectively.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Tamil Nadu Government</h5>
                  <br />
                  <ul>
                    <li>
                      The Tamil Nadu Government is in the process of rolling out
                      an ambitious plan of providing high-speed broadband
                      connectivity to 12,500 villages. The availability of
                      high-speed Internet will result in increased consumption
                      of entertainment content.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4> <br />
                  <p>
                    The growth of the Media and Entertainment industry in India
                    is driven by a number of factors:
                  </p>
                  <br />
                  <ul>
                    <li>
                      <span>
                        <b>Adequate manpower:</b>
                      </span>{" "}
                      India has a large and growing pool of skilled manpower in
                      the Media and Entertainment sector, thanks to prominent
                      institutes and universities that offer courses in visual
                      communication, mass communication, animation, VFX, gaming
                      and comics.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Government support:</b>
                      </span>
                      The Union Government has set up an AVGC Task Force to work
                      for the development of the sector by increasing the
                      required manpower and improving infrastructure facilities.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Increasing consumer spending:</b>
                      </span>
                      India’s consumer spending is increasing rapidly, and this
                      is creating a fertile ground for the Media and
                      Entertainment industry to grow. India’s consumer spending
                      for 2022 was $2.49 trillion, and it is expected to exceed
                      $4 trillion by 2023.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>High-speed Internet connectivity:</b>
                      </span>
                      India has made significant progress in expanding its
                      high-speed Internet connectivity. This is essential for
                      the growth of the Media and Entertainment industry, as it
                      allows users to access and consume entertainment content
                      easily.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Many stories to be told: </b>
                      </span>
                      India’s stories, myths and legends provide a wealth of
                      inspiration for content creators.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Mediaandentertainment;
