import React, { useContext, useState } from "react";
import Navbar from "../../home-module/navbar-page/Navbar";
import { Container, Divider, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import notifyService from "../../../api/notifySerivce";
import { FaArrowLeft } from "react-icons/fa6";

const AdminEventViewdetails = () => {
  let token = localStorage.getItem("jwttoken");
  let useId = localStorage.getItem("userId");
  const { id } = useParams();
  let categoryId;
  if (id !== undefined) {
    categoryId = atob(id);
  }
  useDidMountEffect(() => {
    getApi();
  }, []);
  const { setPageLoading, navigator } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const getApi = () => {
    setPageLoading(true);
    var id = categoryId;
    apiService(`event/home/get?id=${id}`, "", "unauthget")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRegister = (val, url) => {
    if (token) {
      var req = {
        userId: Number(useId),
        eventId: val,
      };
      apiService(`event/count`, req, "post");

      if (url !== undefined) {
        const fullUrl = /^https?:\/\//.test(url) ? url : `https://${url}`;

        window.open(fullUrl, "_blank");
      }
    } else {
      navigator("/login");
    }
  };
  const handleMap = (url) => {
    if (url !== undefined) {
      window.open(url, "_blank");
    }
  };

  const isRegistrationClosed = (registrationEndDate) => {
    if (!registrationEndDate) return true;

    const currentDate = new Date();
    const registrationEnd = new Date(registrationEndDate);
    currentDate.setHours(0, 0, 0, 0);
    registrationEnd.setHours(0, 0, 0, 0);
    return currentDate > registrationEnd;
  };
  //   const handleCopyUrl = () => {
  //     const currentUrl = window.location.href;
  //     debugger;
  //   if (navigator.clipboard) {
  //     navigator.clipboard
  //       .writeText(currentUrl)
  //       .then(() => {
  //         notifyService("success", "URL copied to clipboard!");
  //       })
  //       .catch((err) => {
  //         console.error("Failed to copy: ", err);
  //       });
  //   }
  // }

  const handleCopyUrl = () => {
    const currentUrl = window.location.href;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          notifyService("success", "URL copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      // Fallback
      const tempInput = document.createElement("textarea");
      tempInput.value = currentUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand("copy");
        notifyService("success", "URL copied to clipboard!");
      } catch (err) {
        console.error("Fallback copy failed: ", err);
      } finally {
        document.body.removeChild(tempInput);
      }
    }
  };

  const backBtn = () => {
    var eventLoc = localStorage.getItem("EventLocation")
    navigator(eventLoc)
    localStorage.removeItem("EventLocation")
  }
  const formatDate = (apiDate) => {
    if (!apiDate) return "-";
    const date = new Date(apiDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <div className="bg-color ">
      <>
        <Navbar />
      </>
      <div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Container>
              <div className="main-title">
                <div>
                  <p className="event-backed-btns mb-0" onClick={backBtn}>
                    <FaArrowLeft />
                    <span style={{ marginLeft: "5px" }}>Back</span>
                  </p>
                </div>
                <span className="small-tit">EVENT DETAILS</span>
              </div>
              <Grid
                container
                spacing={2}
                direction="row"
                className="event-register-box2"
              >
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <div>
                    <img
                      src={formData.imageName}
                      alt="Event_image"
                      className="event_det_img"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <div className="event-title-boxs">
                    <div>
                      <h2 style={{ marginBottom: "0px" }}>{formData.title}</h2>
                    </div>
                    <div className="">
                      {formData.paid === false ? (<span className="reg-titles">Free</span>) : (<span className="reg-titles">₹{formData.amount}</span>)}
                    </div>
                  </div>
                  <Divider />
                  <div className="event-class">
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={9} lg={9}>
                        <div>
                          <div className="event-clockss">
                            <div>
                              <h6 className="event-time mt-2">
                                <img
                                  src="/images/calendarimg.png"
                                  alt=""
                                  className="mr-2"
                                />
                                {formData.startMonth ? formData.startMonth : ""} {formData.startDay ? formData.startDay : ""} {formData.endMonth ? `- ${formData.endMonth}` : ""} {formData.endDay ? formData.endDay : ""}
                              </h6>
                            </div>
                            <div>
                              <h6 className="event-time mt-2">
                                <img
                                  src="/images/clock-icon.png"
                                  alt=""
                                  className="mr-2"
                                />
                                {formData.time ? formData.time : "-"}
                              </h6>
                            </div>

                          </div>
                          <div className="item-location">
                            {formData.district ? (
                              <h6 className="event-location-register">
                                <img
                                  src="/images/location-icon.png"
                                  alt=""
                                  className="mr-2"
                                  onClick={() => handleMap(formData.mapLink)}
                                />
                                {formData.venue} ,&nbsp;{formData.district}&nbsp;
                                {formData.pinCode}
                              </h6>
                            ) : (
                              <h6 className="event-location-register">
                                <img
                                  src="/images/online-symbal.png"
                                  alt=""
                                  className="mr-2"
                                />
                                {formData.mode}
                              </h6>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div>
                          <div className="event-title-regs">
                            <div>
                              {!isRegistrationClosed(formData.registrationEndDate) ? (
                                <button
                                  className="reg-btn"
                                  onClick={() =>
                                    handleRegister(formData.id, formData.redirectUrl)
                                  }
                                >
                                  Register
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <h6 className="mt-3 mb-0 " style={{textAlign:'end',color:'#000',fontWeight:'500'}}> 
                            Ends on:&nbsp;{formData.registrationEndDate ? formatDate(formData.registrationEndDate) : "-"}
                          </h6>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </div>
      <div className="mt-2">
        <Container>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={{ paddingLeft: '0' }}>
              <div className="event-register-box3">
                <div>
                  <h4 className="event-share">About Event</h4>
                </div>
                <Divider />
                <div
                  className="event-shared-btn"
                  dangerouslySetInnerHTML={{
                    __html: formData.description,
                  }}
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="event-register-box4 mb-3">
                <div>
                  <h4 className="event-share">Share this Event</h4>
                </div>
                <Divider />
                <div className="event-shar-btn">
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopyUrl}
                  >
                    <ContentPasteIcon onClick={handleCopyUrl}/>
                  </Button> */}
                  <div onClick={handleCopyUrl} style={{ cursor: 'pointer' }}>
                    <ContentPasteIcon /><span style={{ fontSize: '13px' }}> - (Click here to copy the URL)</span>
                  </div>
                </div>
              </div>
              {formData.mode === 'Offline' &&
                (<div className="event-register-box4" style={{ wordBreak: 'break-word' }}>
                  <div>
                    <h4 className="event-share">Venue</h4>
                  </div>
                  <Divider />
                  <div>
                    <p className="event-district">{formData.district}</p>
                  </div>
                  <div className="event-shar-btn">
                    <div
                      className="map-boxdetailmap"
                      style={{ height: "129px" }}
                      dangerouslySetInnerHTML={{ __html: formData.mapLink }}
                    >
                      {/* {formData.mapLink} */}
                    </div>
                  </div>
                </div>)
              }
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="event-footer">
        <span>
          Never Miss a Beat – Events, News, Blogs and More at Your Fingertips! |
          Follow Us for the Latest Updates{" "}
          <img src="/images/icon/twiiter-icon.png" alt="Twitter" className="events-icons" />
          <FacebookIcon className="events-icons" />
          <WhatsAppIcon className="events-icons" />
          <InstagramIcon className="events-icons" />
          <LinkedInIcon className="events-icons" />
        </span>
      </div>
    </div>
  );
};

export default AdminEventViewdetails;
