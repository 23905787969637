import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const PreincubationTab = () => {
  const navigate = useNavigate();
  const handlenow = () => {
    navigate('/login')
  }
  return (
    <div>
      <div className="inc-tab-bgimg">
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={5}
            className="mt-5"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage mt-5">
                <h4>Pre-Incubation Center</h4>
                <p>
                  StartupTN Pre Incubation Centres (PIC) is visioned to support
                  early-stage startups and scalable innovations in Idea
                  Validation, Mentorship, Training and Workshops, Networking
                  Opportunities till Proof-of-Concept Support.
                </p>
                <p>
                  Institutions that are interested to help aspiring
                  entrepreneurs in Prototype Development, guidance and mentor
                  startups with the support of experienced entrepreneurs,
                  conduct workshops, seminars and training sessions on various
                  aspects of entrepreneurship can set up a StartupTN Pre
                  Incubation Centre. StartupTN has proposed to establish 100
                  Pre-Incubation centres in Tier II, III and IV Cities of Tamil
                  Nadu over a period of four years where a formal Incubation
                  Centre is yet to be created.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="smartcrdimag img-fluid"
                  src={"/images/tncatayst_logos/incub-imag.png"}
                  alt="TNCatalyst"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
        </div>
        <div className="how-works-div">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className="works-section mt-5">
                  <h4>Evaluation Process</h4>
                  <p>StartupTN Pre-Incubation Center Evaluation Process</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="layer-bg-smcrd">
                  <div className="works-mesh-layer"></div>
                  <div className="how-works-details">
                    <div className="sm-crd-process">
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-sc-logo.png"}
                              alt="registration"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              01 <span>Submission of EOI </span>
                            </h3>
                            <p>
                              Submitting an Expression of Interest to
                              demonstrate intent to participate.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-sc-logo-2.png"}
                              alt="signup"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              02 <span> Internal Screening</span>
                            </h3>
                            <p>
                              Conducting an initial eligibility and potential
                              assessment.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-3.png"}
                              alt="fill-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              03{" "}
                              <span>Presentation to Management Commitee</span>
                            </h3>
                            <p>Proposing ideas to management.</p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-4.png"}
                              alt="submit-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <div>
                              <h3>
                                04{" "}
                                <span>Shortlisted Proposals Annoucement</span>
                              </h3>
                            </div>
                            <div>
                              <p>
                                Announcing proposals selected for the next
                                phase.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-5.png "}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              05{" "}
                              <span>
                                Facility visit &Formation of Legal entity for
                                PIC
                              </span>
                            </h3>
                            <p></p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-6.png"}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              06 <span>Signing of MOU for PIC</span>
                            </h3>
                            <p>
                              Formalizing the agreement with a Memorandum of
                              Understanding.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-7.png"}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              07 <span>Sanction order </span>
                            </h3>
                            <p>
                              Once you’ve provided all the necessary
                              information, click the submit application for
                              TANSEED 6.0 button.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-8.png"}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              08{" "}
                              <span>
                                Administartive approval and release of Funds{" "}
                              </span>
                            </h3>
                            <p>
                              Once you’ve provided all the necessary
                              information, click the submit application for
                              TANSEED 6.0 button.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-9.png"}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              09 <span>Implementation </span>
                            </h3>
                            <p>
                              Once you’ve provided all the necessary
                              information, click the submit application for
                              TANSEED 6.0 button.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm-crd-process-steps">
                        <div className="process-div">
                          <div>
                            <img
                              src={"/images/tncatayst_logos/tn-cat-logo-10.png"}
                              alt="track-application"
                              className="process-img"
                            />
                          </div>
                          <div className="process-text">
                            <h3>
                              10 <span>Quarterly review of PIC </span>
                            </h3>
                            <p>
                              Once you’ve provided all the necessary
                              information, click the submit application for
                              TANSEED 6.0 button.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="backg-color-new-tnc mt-5">
          <Container>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div className="banner-content-new-tn">
                  <h2> Apply before 15 July 2024, 5:00 PM</h2>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="applieds">
                  <button className="avail-btn-newd-tn">Apply Now</button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>Let's Get Started</h3>
                    <p>MentorTN</p>
                    <p>Right Mentor for the right founder!</p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
   
    </div>
  );
};

export default PreincubationTab;
