import { Autocomplete, FormControl,FormHelperText,Grid,TextField,} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from "../../../../api/notifySerivce";
const AddressMenteors = () => {
  const { navigator,validateNumberonly,setPageLoading,handleNextClick,isWhitespace,validatePincode} = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, getValues,formState: { errors },} = useForm({ values: formData });
  useDidMountEffect(() => {
    handleNextClick(70);
    dropdownApi();
    statesdropdownApi();
    countrydropdownApi();
  }, []);
  useDidMountEffect(() => {
    getApi();
  }, []);
  const [district, setDistrict] = useState([]);
  const dropdownApi = () => {
    apiService(`home/district/list?hubId=${0}`, "", "get")
      .then((result) => {
        if (result && result.data && result.data.districts) {
              var values = result.data.districts;
              setDistrict(values);
        }
      })
      .catch((err) => { });
  };
  const [states, setStates] = useState([]);
  const statesdropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"states"}`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.dropDownValues ) {
              var dropDown = result.data.dropDownValues;
              setStates(dropDown.values);
        }
      })
      .catch((err) => {});
  };
  const [country, setCountry] = useState([]);
  const countrydropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"country"}`, "", "unauthget")
      .then((result) => {
        if (result && result.data && result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setCountry(dropDown.values);
        }
      })
      .catch((err) => {});
  };
  const getApi = () => {
    setPageLoading(true)
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          data.country = data.country ? data.country : "India"
          data.state = data.state ? data.state : "Tamil Nadu"
          setFormData(data);
        }
      })
      .catch((err) => {});
  };
  const [countryValue, setCountryValue] = useState("");
  const handleSelectCountryType = (value) => {
    setCountryValue(value);
    setValue("country", value);
    clearErrors("country");
    if (value === "India") {
      setValue("state", "Tamil Nadu"); 
    } else {
      setValue("state", "");
      clearErrors("state");
    }
    setValue("district", "");
    clearErrors("district");
    setValue("pincode", "");
    clearErrors("pincode");
  };
  const handleSelectState = (value) => {
    setValue("state", value);
    clearErrors("state");
    setValue("district", "");
    clearErrors("district");
    setValue("pincode", "");
    clearErrors("pincode");
  };
  const handleSelectDistrict = (value) => {
    setValue("district", value);
    clearErrors("district");
  };
  // const [stateValue, setStateValue] = useState("");
  // const handleSelectCity = (value) => {
  //   setStateValue(value);
  //   setValue("district", value);
  //   clearErrors("district");
  //   setValue("pincode", "");
  //   clearErrors("pincode");
  // };
  const onSubmit = (data) => {
    if(data.country === 'India'){
      if(data.state == "Tamil Nadu"){
        const districtId = district.filter(val => val.name === data.district);
        data.districtId = districtId[0].id.toString()
      } else {
        data.districtId = 0;
      }
    }  else {
      data.districtId = 0;
    }
    apiService("userprofile/save", data, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          navigator("/mentors/social-links");
        }
      })
      .catch((err) => {});
  };
  const onError = (event) => {
    notifyService("danger", "Submit Failed", "Please check the mandatory fields" );
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">Address</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">2</span>/4
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <FormControl sx={{ width: "100%" }}>
                      <Controller
                        name="country"
                        control={control}
                        defaultValue="India"
                        rules={{ required: "Country is required" }}
                        render={({ field }) => (
                          <Autocomplete
                            disableClearable
                            disablePortal
                            {...field}
                            options={country}
                            onChange={(e, selectedOptions) =>
                              handleSelectCountryType(selectedOptions)
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Country *" error={Boolean(errors.country && errors.country)} />
                            )}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.country && errors.country.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {
                    getValues("country") === "India" ? (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                          <FormControl sx={{ width: "100%" }}>
                            <Controller
                              name="state"
                              control={control}
                              defaultValue="Tamil Nadu"
                              rules={{ required: "State is required" }}
                              render={({ field }) => (
                                <Autocomplete
                                  disableClearable
                                  disablePortal
                                  {...field}
                                  options={states}
                                  onChange={(e, selectedOptions) =>
                                    handleSelectState(selectedOptions)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} label="State *" error={Boolean(errors.state && errors.state)}/>
                                  )}
                                />
                              )}
                            />
                            <FormHelperText className="text-danger">
                              {errors.state && errors.state.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )
                  }
                  {getValues("country") === "Others" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}className="mb-3">
                        <FormControl sx={{ width: "100%" }}>
                          <Controller
                            name="otherCountry"
                            control={control}
                            defaultValue=""
                            rules={{ required: false,
                            //   validate: {
                            //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            // },
                             }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                variant="outlined"
                                label="Country Name "
                                placeholder="Enter Country Name"
                                fullWidth
                                error={Boolean(errors.otherCountry && errors.otherCountry)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.otherCountry && errors.otherCountry.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item lg={12} md={12}sm={12}xs={12}className="mb-3">
                        <FormControl sx={{ width: "100%" }}>
                          <Controller
                            name="state"
                            control={control}
                            defaultValue=""
                            rules={{ required: false,
                            //   validate: {
                            //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            // },
                             }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                variant="outlined"
                                label="State "
                                placeholder="Enter State"
                                fullWidth
                                error={Boolean(errors.state && errors.state)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.state && errors.state.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {getValues("country") === "India" &&
                  getValues("state") === "Tamil Nadu" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}className="mb-3">
                         <FormControl sx={{ width: "100%" }}>
                          <Controller
                            name="district"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({ field, fieldState: { error } }) => (
                              <Autocomplete
                              {...field}
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                options={district.map((option) => option.name)}
                                onChange={(e, selectedOption) =>
                                  handleSelectDistrict(selectedOption)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="District*"
                                    error={Boolean(errors.district && errors.district)}
                                    helperText={
                                      error ? "District is required" : ""
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.district && errors.district.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {getValues("state") !== "Tamil Nadu" &&
                  getValues("country") === "India" ? (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        <Controller
                          name="district"
                          control={control}
                          defaultValue=""
                          rules={{ required: false,
                          //   validate: {
                          //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                          // },
                           }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="District *"
                              placeholder="Enter District"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(errors.district && errors.district)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.district && errors.district.message}
                        </FormHelperText>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  {getValues("country") === "India" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="pincode"
                        control={control}
                        defaultValue=""
                        rules={{ required: false,
                          validate: validatePincode
                         }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Pincode "
                            placeholder="Enter Pincode"
                            fullWidth
                            type="text"
                            {...field}
                            error={Boolean(errors.pincode && errors.pincode)}
                            onKeyDown={(e) => validateNumberonly(e)}
                            inputProps={{ maxLength: 6, minLength: 6 }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.pincode && errors.pincode.message}
                      </FormHelperText>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {getValues("country") === "Others" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="pincode"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Zip Code"
                            placeholder="Enter Zip Code"
                            fullWidth
                            type="text"
                            {...field}
                            error={Boolean(errors.pincode && errors.pincode)}
                            onKeyDown={(e) => validateNumberonly(e)}
                            inputProps={{ maxLength: 11, minLength: 5 }}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.pincode && errors.pincode.message}
                      </FormHelperText>
                    </Grid>
                    
                  ) 
                  : (
                    <></>
                  )}
                   <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="address"
                      control={control}
                      defaultValue=""
                      rules={{ required: false,
                      //   validate: {
                      //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                      // },
                       }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Address *"
                          placeholder="Enter Address"
                          fullWidth
                          type="text"
                           error={Boolean(errors.address && errors.address)}
                          {...field}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.address && errors.address.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default AddressMenteors;
