import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Socialimpact = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/Social-Impact-Rural-Livelihood-Sustainability-1.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">
            Social Impact, Rural Livelihood & Sustainability
          </h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <p>
                    The Union Government has rolled out many initiatives for
                    supporting innovation-driven Startups, especially those in
                    the Social Impact sector. They include the Rs 100 billion
                    Fund of Funds, easing of regulations, simplifying the exit
                    process, setting up of innovation and tinkering labs and
                    encouraging private incubators with funding. The other
                    incentives and schemes include Scheme for Funding High
                    Risk-High Reward Research, Promoting Innovations in
                    Individuals, Startups and MSMEs (PRISM), NewGen Innovation
                    and Entrepreneurship Development Centre, Biotechnology
                    Ignition Grant, Small Business Innovation Research
                    Initiative, Technology Development Programme, Agro
                    Processing Cluster Scheme, Raw Material Assistance Scheme, A
                    Scheme for Promotion of Innovation, Rural Industries and
                    Entrepreneurship (ASPIRE), Trade Related Entrepreneurship
                    Assistance and Development Scheme for Women, Venture Capital
                    Assistance Scheme and., Technology Development Fund.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />
                  <p>
                    The Government of Tamil Nadu provides a variety of
                    incentives and programmes to support companies with a social
                    impact. These programmes provide grants, coaching, tax
                    breaks and access to cutting-edge facilities with a view to
                    increasing the contribution of Startups to the State’s
                    economy and social capital. StartupTN offers a special
                    package of assistance for Rural Impact Startups. The package
                    includes financial assistance of Rs 15 lakh under TANSEED,
                    mentorship and incubation facilities.
                  </p>
                  <br />
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <p>
                    Success in the social impact sector requires the right
                    resources and support. Tamil Nadu’s ecosystem offers a wide
                    array of resources, including incubators and accelerators.
                    There are also specialised programmes that provide
                    mentorship, funding and access to networks. The State is
                    known for multiple funding opportunities in the form of
                    grants, venture capital and loans that are tailored to
                    Startups’ needs.
                  </p>
                  <br />
                  <p>
                    <b>Research and Development Centres:</b> Entrepreneurs can
                    collaborate with research institutions to utilise their R
                    and D facilities for developing cutting-edge solutions.
                  </p>
                  <br />
                  <p>
                    <b>Skilled Workforce:</b>The State has a pool of talented
                    professionals who are passionate about making a difference.
                    Networking Events: There are abundant opportunities to
                    engage with like-minded individuals, investors and experts
                    through conferences, seminars and workshops.
                  </p>
                  <br />
                  <p>
                    <b>Government Liaison:</b>There are dedicated support teams
                    to help the entrepreneur to navigate regulatory procedures
                    and access incentives.
                  </p>
                  <br />
                  <p>
                    Tamil Nadu welcomes Social Impact Startups with open arms,
                    encouraging them to lead the way in creating a sustainable
                    and inclusive future for all. This will be a transformative
                    journey for the entrepreneurs to make a lasting difference
                    in the lives of citizens.
                  </p>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Socialimpact;
