import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const Qrscanner = () => {
    const [scannerResult, setScannerResult] = useState(null);

    useEffect(() => {
        // Initialize scanner once
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 5,
        });

        // Render scanner
        scanner.render(onScanSuccess, onScanError);

        // Define success callback
        function onScanSuccess(result) {
            setScannerResult(result);
            // Optional: Clear scanner to prevent multiple scans
            if (scanner) {
                try {
                    scanner.clear();
                } catch (error) {
                    console.error('Error clearing scanner:', error);
                }
            }
        }

        // Define error callback
        function onScanError(err) {
            console.warn(err);
        }

        // Clean up the scanner when component unmounts
        return () => {
            if (scanner) {
                try {
                    scanner.stop();
                } catch (error) {
                    console.error('Error stopping scanner:', error);
                }
            }
        };
    }, []); // Empty dependency array ensures useEffect runs only once

    return (
        <div>
            <h1>QR Scanner</h1>
            {scannerResult ? (
                <div>Success: {scannerResult}</div>
            ) : (
                <div id='reader'></div>
            )}
        </div>
    );
};

export default Qrscanner;
