import React, { useContext, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FaUserPlus } from "react-icons/fa6";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from "../../../../api/notifySerivce";
import Eventeammember from "./Eventeammember";
import CCAvenueIntegration from "../../../common-pages/CCAvenueIntegration";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";

const Eventregister = ({ categoryId, setOpens, via }) => {
  const { setPageLoading, fullWidth } = useContext(DataContext);
  const [open, setOpen] = useState(false);
  const [openss, setOpenss] = useState(false);
  const handlParticipants = () => {
    setOpen(true);
  };
  const handleReportClose = () => {
    setOpen(false);
  };
  const [formData, setFormData] = useState({});
  const { control, setValue, handleSubmit, getValues } = useForm({ values: formData });
  const {
    fields: itemFields,
    append: addItem,
  } = useFieldArray({
    control,
    name: "founder",
  });
  if (itemFields.length === 0) {
    addItem();
  }

  useDidMountEffect(() => {
    getApi();
  }, []);

  const [founder, setFounder] = useState([]);
  const getApi = () => {
    setPageLoading(true)
    var id = categoryId
    apiService(`event/home/get?id=${id}`, "", "unauthget")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
          data.founder.forEach(element => {
            if (element.email) {
              element.email = cryptodecryptJS(element.email);
            }
            if (element.phone) {
              element.phone = cryptodecryptJS(element.phone);
            }
          });
          setFounder(data.founder);

        }
      })
      .catch((err) => { });
  };
  const [checkedList, setCheckedList] = useState([])
  const handleCheck = (e, name, id) => {
    setValue(name, e.target.checked ? true : false);
    const { checked } = e.target;
    if (checked) {
      var founder = getValues(id)
      setCheckedList([...checkedList, founder]);
    } else {
      var founderData = getValues(id)
      setCheckedList(checkedList => checkedList.filter((item) => item !== founderData));
      if (checkedList.length === 1) {
        setCheckedList([])
      }
    }
  };

  const onSubmits = (data) => {


    if (checkedList.length > 0) {
      setPageLoading(true)
      var req = {
        id: data.id,
        founder: data.founder.map(f => ({
          id: f.id,
          participant: f.participant
        }))
      };
      apiService(`event/register`, req, "post")
        .then((result) => {
          setPageLoading(false)
          if (result && result.data) {
            setOpenss(true);
            getApi();
          }
        })
        .catch((err) => { });
    }
    else {
      notifyService('danger', 'Please Select At Least One Team Members')
    }

  };
  const handleClose = () => {
    setOpens(false)
    setOpenss(false);
  };

  return (
    <div>
      <div className="event-details-title">
        <h3>Event Details</h3>
      </div>
      <div className="event-modal-box-1">
        <div className="modal-box-1">
          <div className="modal-img">
            <img src={formData.imageName} class="card-img-top" alt="..." />
          </div>
          <div className="ml-lg-3 w-100">
            <div className="d-flex">
              <h5>{formData.title}</h5>
              <div className="img-tops ml-auto">
                <span>{formData.category}</span>
              </div>
            </div>

            <p className="">{formData.description}</p>
            <div className="modal-location">
              {formData.location ? (
                <span className="event-location">{formData.location}</span>
              ) : (
                <span className="event-location">
                  <img
                    src="/images/online-symbal.png"
                    alt=""
                    className="mr-2"
                  />
                  Online
                </span>
              )
              }
              <h6 className="event-time-12 mt-2 ml-lg-4 ml-md-4 ml-0">
                {formData.formattedTime}
              </h6>
            </div>
            {/* <div className="reg-fees">
              <div>
                {formData.paid === false ? (
                  <span className="event-free">Free</span>
                ) : (
                  <span className="event-free">₹{formData.amount}</span>
                )}
              </div>
            </div> */}
          </div>

        </div>
      </div>
      {/* {
        via !== 'admin' ? (
          <form onSubmit={handleSubmit(onSubmits)}>
            <div className="event-modal-box-1 mt-3">
              <div className="event-details-title">
                <h5>Select Team Members To Participants</h5>
              </div>

              <div class="table-container">
                <table class="table">
                  <thead></thead>
                  <tbody>
                    {founder &&
                      founder.map((item, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <p className="m-0">{item.name}</p>
                            <span className="table-span">{item.designation}</span>
                          </td>
                          
                          <td>
                            <p className="table-email">{item.email}</p>
                          </td>
                          <td>

                            <Controller
                              name={`founder.${index}.participant`}
                              control={control}
                              defaultValue={false}
                              rules={{ required: false }}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value === true}
                                  color="success"
                                  onChange={(e) =>
                                    handleCheck(e, `founder.${index}.participant`, `founder.${index}.id`)
                                  }
                                />
                              )}
                            />

                            <p></p>
                            

                           
                          
                          </td>
                        </tr>
                      ))}

                  
                  </tbody>
                </table>
              </div>
              {
                formData.showRegisterButton === true && (
                  <div className="text-center py-3">
                    <Button
                      className="team-btn"
                      variant="outlined"
                      startIcon={<FaUserPlus className="ml-2" />}
                      onClick={() => handlParticipants()}
                    >
                      Add Team Member
                    </Button>
                    {
                      checkedList.length > 0 && (
                        <>

                          {
                            formData.paid === true ? (
                              <CCAvenueIntegration />
                            ) : (
                              <Button
                                type="submit"
                                variant="contained"
                                className="ml-lg-3 ml-md-3 ml-2 mt-lg-0 mt-md-0 mt-3"
                                sx={{ borderRadius: "50px", padding: "10px 40px" }}
                              >
                                Book Now
                              </Button>
                            )
                          }
                        </>

                      )
                    }



                  </div>
                )
              }
            </div>

          </form>
        ) : (
          <></>
        )
      } */}

      <Dialog fullWidth={fullWidth} maxWidth={'lg'} open={open}>
        <DialogContent>
          <div>
            <span
              style={{ color: "#2260FF", cursor: "pointer" }}
              onClick={handleReportClose}
            >
              <ArrowBackIcon /> Back
            </span>
          </div>
          <Eventeammember setOpen={setOpen} getApi={getApi} />
        </DialogContent>
      </Dialog>

      <React.Fragment>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={'sm'}
          open={openss}
        >

          <div className="gif-img text-center">
            <img src="/images/success.gif" alt="" />
            <h4 className="modal-success">Successfully Registered</h4>
          </div>
          <DialogActions style={{ justifyContent: "center" }} className="p-5">
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
};

export default Eventregister;
