import { Box, Button, Grid, Paper } from '@mui/material';
import React from 'react'

const InvMentoringRequested = () => {
  const supportList = [
    {
      StartupName: "Aura Lab Pvt Ltd",
      ProfileRole: "Fintech | MVP Stage",
      Date: "09 May , 2024",
      Time: "11.00 A.M - 12:00 P.M",
    },
    {
      StartupName: "Aura Lab Pvt Ltd",
      ProfileRole: "Fintech | MVP Stage",
      Date: "09 May , 2024",
      Time: "11.00 A.M - 12:00 P.M",
    },
    {
      StartupName: "Aura Lab Pvt Ltd",
      ProfileRole: "Fintech | MVP Stage",
      Date: "09 May , 2024",
      Time: "11.00 A.M - 12:00 P.M",
    },
  ];
  return (
    <>
      <div className='mt-4'>
        <Grid container spacing={3}>
          {supportList.map((list, i) => (
            <Grid item xs={12} key={i}>
              <Paper sx={{ padding: '20px', borderRadius: '20px', position: 'relative' }}>
                <Box className='mentor-req'>
                  <Box>
                    <span className="list-1-sur">{list.StartupName}</span>
                    <div>
                      <span className="list-ran-sur">{list.ProfileRole}</span>
                    </div>
                  </Box>
                  <Box>
                    <div className="items">
                      <div className="loc" style={{ display: 'flex', alignItems: 'center' }}>
                        <img src="/images/location_svg.png" alt="Location" style={{ marginRight: '8px' }} />
                        <span className="list-ran1">{list.Date}</span>
                      </div>
                      <div className="clo" style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                        <img src="/images/clock-check.png" alt="Clock" style={{ marginRight: '8px' }} />
                        <span className="list-ran1">{list.Time}</span>
                      </div>
                    </div>
                  </Box>
                  <Box>
                    <div className="buttons">
                      <Button className="dec">Decline</Button>
                      <Button className="acc">Accept</Button>
                    </div>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  )
}

export default InvMentoringRequested