import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Travel = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTN-sector-Toursim.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Travel & Tourism</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div
                  className="sector-page-bg"
                >
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <p>
                    The Government of India has taken a number of initiatives to
                    promote the Travel and Tourism sector in the country. They
                    include:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Swadesh Darshan: This scheme aims to develop thematic
                      tourist circuits in the country, such as the Buddhist
                      Circuit, the Ramayana Circuit and the Tribal Circuit.
                    </li>
                    <br />
                    <li>
                      PRASAD: This scheme aims to develop and beautify
                      pilgrimage sites.
                    </li>
                    <br />
                    <li>
                      Dekho Apna Desh: This scheme aims to promote domestic
                      tourism by encouraging Indians to travel to different
                      parts of the country.
                    </li>
                    <br />
                    <li>
                      Incredible India!: This campaign is aimed at promoting
                      India as a tourist destination among international
                      travellers.
                    </li>
                    <br />
                    <li>
                      e-Tourist Visa: This scheme allows tourists from certain
                      countries to obtain an electronic visa to India online.
                    </li>
                    <br />
                    <li>
                      Tourist Infrastructure Development Scheme: This scheme
                      provides financial assistance to state governments and
                      other organisations for the development of tourist
                      infrastructure, such as roads, airports and hotels.
                    </li>
                    <br />
                  </ul>
                  <p>
                    In addition to these schemes, the Union Government also
                    provides a number of other incentives to the Travel and
                    Tourism sector, such as tax breaks and subsidies.
                  </p>
                  <br />
                  <p>
                    The Union Government’s initiatives have helped to boost
                    tourist arrivals to India in recent years. In 2022, India
                    received over 8.8 million foreign tourists, a significant
                    increase from the previous year.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Budget 2023</h5>
                  <br />
                  <ul>
                    <li>
                      50 tourist destinations to be developed as a whole package
                      for domestic and international tourism
                    </li>
                    <br />
                    <li>
                      Sector-specific skilling and entrepreneurship development
                      to be dovetailed to achieve the objectives of ‘Dekho Apna
                      Desh’
                    </li>
                    <br />
                    <li>
                      Tourism infrastructure and amenities to be facilitated in
                      border villages through the Vibrant Villages Programme
                    </li>
                    <br />
                    <li>
                      Unity Malls to be set up in State capitals or prominent
                      tourist centres
                    </li>
                    <br />
                    <li>An outlay of US$ 170.85 million for Swadesh Darshan</li>
                    <br />
                    <li>
                      US$ 2.1 billion is allocated to the Ministry of Tourism as
                      it holds huge opportunities for jobs and entrepreneurship
                      for youth
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />
                  <p>
                    The Tamil Nadu Government has taken several initiatives to
                    promote and develop the tourism sector. Investments in
                    infrastructure, marketing, and tourism promotion have
                    boosted the sector’s growth.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Some of these initiatives are
                  </h5>
                  <br />
                  <ul>
                    <li>
                      Development of tourist infrastructure: The Tamil Nadu
                      Government has invested heavily in the development of
                      tourist infrastructure, such as roads, airports, hotels
                      and tourist attractions.
                    </li>
                    <br />
                    <li>
                      Marketing and promotion: It is marketing and promoting the
                      State as a tourist destination to domestic and
                      international travellers. It has launched a number of
                      tourism campaigns, such as ‘Tamil Nadu: Your Destination
                      for Heritage, Culture, and Adventure.’
                    </li>
                    <br />
                    <li>
                      Incentives to tourism businesses: It is providing
                      incentives, such as subsidies, to tourism businesses. It
                      provides a subsidy to tour operators who organise tours to
                      rural areas.
                    </li>
                    <br />
                  </ul>
                  <p>
                    Some special initiatives of the Tamil Nadu Government in the
                    Travel and Tourism sector
                  </p>
                  <br />
                  <ul>
                    <li>
                      Tamil Nadu Tourism Policy: The Tamil Nadu Tourism Policy
                      aims to create an enabling environment to promote
                      inclusive tourism growth by incorporating sustainability
                      as an underlying principle in all its activities and
                      initiatives. The policy proposes strategic initiatives
                      that accelerate economic growth through multiplier effect.
                    </li>
                    <br />
                    <li>
                      Tourism Destination Development Scheme: This scheme aims
                      to identify and develop tourist destinations in the State
                      on the principles of integrated and sustainable
                      development with focus on enhancement of tourist
                      experiences at the destination.
                    </li>
                    <br />
                    <li>
                      Adventure and Eco-Camping Development Scheme: This scheme
                      aims to promote adventure tourism and eco-camping at
                      lesser-known destinations in the State.
                    </li>
                    <br />
                    <li>
                      Skill Development for Tourism Sector: The Tamil Nadu
                      Tourism Department has identified a list of skill
                      development courses to equip the youth with the requisite
                      skill sets in various tourism-related activities and
                      benefit from employment opportunities in the sector.
                    </li>
                    <br />
                  </ul>
                  <p>
                    In addition to the above initiatives, the State Government
                    is also working on a number of other projects to promote the
                    Travel and Tourism sector in the state. They include
                  </p>
                  <br />
                  <ul>
                    <li>
                      Development of a new tourism circuit in the Western Ghats:
                      This circuit will focus on promoting tourism in the
                      lesser-known hill stations of the Western
                      Ghats.Development of a new tourism circuit in the Cauvery
                      Delta: This circuit will focus on promoting tourism in the
                      rural areas of the Cauvery Delta.
                    </li>
                    <br />
                    <li>
                      Development of a new tourism circuit in the southern
                      districts: This circuit will focus on promoting tourism in
                      the coastal areas of the southern districts.
                    </li>
                    <br />
                  </ul>
                  <p>
                    The State Government is also working on a number of
                    initiatives to promote sustainable tourism. These
                    initiatives include
                  </p>
                  <br />
                  <ul>
                    <li>
                      Development of eco-friendly tourist resorts: The
                      government is working with private investors to develop
                      eco-friendly tourist resorts in the State.
                    </li>
                    <br />
                    <li>
                      Promoting responsible tourism practices: The government is
                      working with tourism businesses to promote responsible
                      tourism practices, such as minimising environmental impact
                      and benefiting local communities.
                    </li>
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div
                className="sector-page-bg"
                >
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <p>
                    The State Government has taken several steps to promote the
                    tourism industry and there are a number of resources
                    available to support businesses in the sector. The
                    organisations and initiatives that support the Travel and
                    Tourism industry in Tamil Nadu include:
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Tamil Nadu Tourism Development Corporation
                  </h5>
                  <br />
                  <p>
                    TTDC is a government-owned corporation that plays a key role
                    in promoting and developing tourism in the State. It was
                    established with the aim of promoting tourism in Tamil Nadu
                    and providing services for visitors from around the world.
                    It boasts the largest chain of hotels in South India, with
                    53 properties and a fleet of 14 coaches. TTDC offers a range
                    of services, including hotels, tours, youth hostels,
                    restaurants, boat houses, telescope houses, petrol stations,
                    coaches for tours, and an exhibition site at Island Ground.
                    TTDC offers a variety of package tours that cover the entire
                    Southern States, allowing tourists to explore the diverse
                    attractions of the region.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Tourism Information Centers
                  </h5>
                  <p>
                    TTDC operates a network of Tourism Information Centres
                    (TICs) across Tamil Nadu. They provide information and
                    assistance to tourists on a variety of topics, including
                    travel itineraries and tourist attractions.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Tamil Nadu Tourism Police
                  </h5>
                  <p>
                    It is a specialised unit of the State police that is
                    responsible for the safety and security of tourists. Tourism
                    Police provide a range of services, including tourist
                    assistance, lost and found services and security at tourist
                    attractions.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Travel Agencies and Tour Operators
                  </h5>
                  <p>
                    There are numerous travel agencies and tour operators who
                    offer customizable tour packages for Tamil Nadu. They can
                    help plan your trip based on your interests and budget.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Local Apps</h5>
                  <br />
                  <p>
                    Local travel apps provide information on attractions,
                    restaurants and transportation options. Apps like “Tamil
                    Nadu Tourism” or popular travel apps like TripAdvisor can be
                    useful.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Transportation</h5>
                  <br />
                  <p>
                    Tamil Nadu has a good network of roads, railways and
                    airports. It is also home to four international airports, in
                    Chennai, Coimbatore, Tiruchi and Madurai
                  </p>
                  <h5 style={{ color: "#39364e" }}>Hospitality Industry</h5>
                  <br />
                  <p>
                    The hospitality industry includes popular hotels,
                    restaurants and tour guides.
                  </p>
                  <h5 style={{ color: "#39364e" }}> Tourist attractions</h5>
                  <br />
                  <p>
                    Tamil Nadu is home to a number of popular tourist
                    attractions, including temples, beaches, hill stations,
                    wildlife sanctuaries and cultural heritage sites.
                  </p>
                  <ul>
                    <li>
                      <b>Places of worship :</b> Meenakshi Amman Temple,
                      Madurai; Brihadeeswarar Temple, Thanjavur; Airavatesvara
                      Temple, Darasuram; Ranganathaswamy Temple, Srirangam;
                      Dhandayuthapaniswamy Temple, Palani; Mariamman Temple,
                      Samayapuram; Our Lady of Health Church, Velankanni; Nagore
                      Dargah
                    </li>
                    <br />
                    <li>
                      <b>Beaches:</b> Marina, Kovalam, Chennai; Mahabalipuram
                      Beach; Silver Beach, Cuddalore
                    </li>
                    <br />
                    <li>
                      <b>Hill stations:</b> Udhagamandalam, Kodaikanal, Yercaud,
                      Top Slip
                    </li>
                    <br />
                    <li>
                      <b>Wildlife sanctuaries :</b> Mudumalai National Park,
                      Bandipur National Park, Sathyamangalam Wildlife Sanctuary
                    </li>
                    <br />
                    <li>
                      <b>Cultural heritage sites:</b> Mahabalipuram, Thanjavur,
                      Chettinad
                    </li>
                    <br />
                  </ul>{" "}
                  <br />
                  <p>
                    Overall, Tamil Nadu has a strong resource base for the
                    Travel and Tourism sector. The State has a rich natural and
                    cultural heritage, a skilled workforce, well-developed
                    infrastructure and the support of the government and other
                    organisations. This makes Tamil Nadu an ideal destination
                    for businesses looking to invest in the Travel and Tourism
                    sector.
                  </p>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Travel;
