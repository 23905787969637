import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Proplegal = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTnSector-PropTech-LegalTech-RegTech.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">PropTech, LegalTech & RegTech</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>PropTech</h5>
                  <p>
                    Several Ministry of Housing and Urban Affairs’ programmes
                    and missions have been established to support Make in India
                    and sector-focused growth. In order to address the lack of
                    urban housing among the Economically Weaker Section/Lower
                    Income Group and Middle-Income Group categories, including
                    slum dwellers, the Pradhan Mantri Awas Yojana – Urban
                    (PMAY-U) programme has been established. A Housing
                    Technology Sub-Mission was started under this programme to
                    help with the adoption and promotion of contemporary,
                    environment-friendly and disaster-resilient technologies.
                    Five incubators have been set up to assist prospective
                    future technologies in the Indian real estate market.
                  </p>
                  <p>
                    Real Estate Investment Trust and the introduction of a
                    stress fund called SWAMIH to finish housing projects that
                    have stagnated have been created. The Government’s
                    Affordable Rental Housing Complex (ARHC) programme, which is
                    building homes for migrant workers, will also open up
                    commercial chances for the sector. Also, the Model Tenancy
                    Act creates a lot of demand for rental housing in the
                    country.
                  </p>
                  <p>
                    Startup India, AGNIi (Accelerating Growth of New India’s
                    Innovations), and Invest India have partnered with JLL to
                    create JLL IDEAs, a real estate PropTech accelerator, which
                    will support entrepreneurs with cutting-edge and innovative
                    technology. The initiative intends to build India’s largest
                    platform for technology convergence in the real estate
                    sector and will host numerous PropTech hackathons and
                    innovation challenges to choose the finest real estate
                    businesses. It has a $100 million investment fund.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>LegalTech</h5>
                  <p>
                    A committee established by NITI Aayog to develop a
                    comprehensive action plan for Online Dispute Resolution
                    (ODR) in India and an effective implementation structure in
                    response to the growing importance of ODR on a global scale
                    came out with its recommendations in 2021. Additionally, it
                    highlighted how Startups have recently changed the way
                    justice is delivered. It called for greater private sector
                    involvement and supported the development of LegalTech
                    clusters across the nation as some of its recommendations.
                    The Ministry of Law and Justice also supports the growth of
                    LegalTech and recognises Startups in this domain.
                  </p>
                  <h5 style={{ color: "#39364e" }}>
                    <b>State Government </b>
                  </h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>PropTech</h5>
                  <p>
                    Tamil Nadu Housing and Urban Development Department and
                    other departments are implementing various housing projects
                    with support from financial institutions such as Asian
                    Development Bank and World Bank (i) to relocate vulnerable
                    communities living in high-risk areas to safe shelter; (ii)
                    provide affordable, suitable housing for urban poor
                    households and migrant workers from the economically weaker
                    sections and lower-income group and (iii) promote access to
                    inclusive, resilient and sustainable housing and urban
                    development in Tamil Nadu.
                  </p>
                  <p>
                    Tamil Nadu has an agreement with UNEP to put urban cooling
                    projects into action. The initiative intends to put into
                    action comprehensive action plans, such as promoting urban
                    greenery, preparing for extreme heat and improving urban
                    architecture.
                  </p>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <ul>
                    <li>
                      Universities and vocational institutions offer education
                      and training programmes that are specifically suited to
                      the needs of the industry.
                    </li>
                    <br />
                    <li>
                      For the expansion of this industry, qualified engineers,
                      scientists, researchers, lawyers and technicians are
                      abundantly available.
                    </li>
                    <br />
                    <li>
                      For professionals in the sector, training facilities and
                      educational institutions offer continual education and
                      skill development.
                    </li>
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Proplegal;
