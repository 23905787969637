import React, { useContext, useState } from 'react'
import BudgetFilter from './BudgetFilter'
import { Grid, Pagination, Stack } from '@mui/material'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
import DataContext from '../../../context/DataContext'
import apiService from '../../../api/apiService'
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import { styled } from '@mui/material/styles';
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//     height: 10,
//     borderRadius: 5,
//     width: '90%',
//     [`&.${linearProgressClasses.colorPrimary}`]: {
//         backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//     },
//     [`& .${linearProgressClasses.bar}`]: {
//         borderRadius: 5,
//         backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//     },
// }));
// <BorderLinearProgress variant="determinate" value={67} />
const BudgetAllocation = () => {
    const { setPageLoading, navigator, hasPrivilege } = useContext(DataContext)

    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [budgetData, setBudgetData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(0)
    const [sortBy, setSortBy] = useState(true)
    const [departmentId, setDepartmentId] = useState(0);
    useDidMountEffect(() => {
        listApi();
    }, [searchInputValue, page, selectedPeriod, sortBy, departmentId]);

    const listApi = () => {
        setPageLoading(true)
        setBudgetData([])
        var data = {
            listSize: 10,
            pageNumber: page,
            searchString: searchInputValue,
            period: selectedPeriod,
            sort: sortBy ? 'asc' : 'desc',
            departmentId: departmentId
        };
        apiService("budget/list", data, "post")
            .then((result) => {
                setPageLoading(false)
                if (result && result.data) {
                    setTotalPage(result.data.totalPages);
                    if (result.data.budgets) {
                        setBudgetData(result.data.budgets);
                    }
                }
            })
            .catch((err) => {
                console.error("Error fetching events:", err);
            });
    };
    const handleChange = (event, value) => {
        setPage(value);
    };
    const handleEditBtn = (id) => {
        navigator(`/admin/budget/update/${btoa(id)}`)
    }
    const handleDeleteBtn = (id) => {
        apiService(`budget/deactivate?id=${id}`, '', 'delete')
            .then((result) => {
                if (result && result.data && result.data.responseStatus === "Success") {
                    listApi()
                }
            }).catch((err) => {

            });
    }
    return (
        <>
            <BudgetFilter
                setSearchInputValue={setSearchInputValue}
                setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod}
                setSortBy={setSortBy} sortBy={sortBy}
                setDepartmentId={setDepartmentId}
                name={'allocation'}
            />

            <section>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                >
                    {
                        budgetData.length > 0 ? (
                            <>
                                {
                                    budgetData.map((budget, index) => (
                                        <Grid key={index} item sm={12} md={6} lg={6}>
                                            <div className='budget-allo'>
                                                <div className='budget-flex'>
                                                    <div>
                                                        <img src='/images/Startup-img-budget.png' alt='' className='img-fluid' />
                                                        <span className='bud-title ml-3'>{budget.department}</span>
                                                    </div>
                                                    <div>
                                                        <h5 className='bluee-clrr'>{budget.amount} {budget.qty}</h5>
                                                    </div>
                                                </div>
                                                <div className='set_max_hig'>
                                                    {
                                                        budget.budgetBreakdown.length > 0 && (
                                                            budget.budgetBreakdown.map((list, i) => (
                                                                <div key={i}>
                                                                    <p className='progress-title mt-3 mb-0'>{list.title}</p>
                                                                    <div class="progresss">
                                                                        <div class="progress-bar-budget" role="progressbar" style={{ width: `${list.percent}%` }} aria-valuenow={list.percent} aria-valuemin="0" aria-valuemax="100">{list.percent}%</div>
                                                                        <span>{list.amount} {list.qty}</span>
                                                                    </div>
                                                                </div>
                                                            ))

                                                        )
                                                    }
                                                </div>
                                                <div className='setbtn_div text-center mt-3'>
                                                    {
                                                        hasPrivilege('36') && (
                                                            <EditIcon
                                                                sx={{
                                                                    cursor: "pointer",
                                                                    marginLeft: "5px",
                                                                    color: 'green'
                                                                }}
                                                                onClick={() => handleEditBtn(budget.id)}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        hasPrivilege('37') && (
                                                            <DeleteForeverIcon
                                                                sx={{
                                                                    cursor: "pointer",
                                                                    marginLeft: "5px",
                                                                    color: 'red'
                                                                }}
                                                                onClick={() => handleDeleteBtn(budget.id)}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Grid>
                                    ))
                                }

                                {totalPage > 1 && (
                                    <Grid item sm={12} md={12} lg={12}>
                                        <div className="event-pagination">
                                            <Stack spacing={2}>
                                                <Pagination
                                                    count={totalPage}
                                                    page={page}
                                                    onChange={handleChange}
                                                />
                                            </Stack>
                                        </div>
                                    </Grid>
                                )}
                            </>
                        ) : (
                            <></>
                        )
                    }

                </Grid>
            </section>
        </>
    )
}

export default BudgetAllocation