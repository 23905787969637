import React, { useContext, useState } from "react";
import MentorSessionFilters from "../MentorSessionFilters";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  ToggleButton,
  styled,
} from "@mui/material";
import { FaRegCalendar } from "react-icons/fa";
import { IoIosList } from "react-icons/io";
import CloseIcon from "@mui/icons-material/Close";
import DataContext from "../../../../../context/DataContext";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import apiService from "../../../../../api/apiService";
import useDidMountEffect from "../../../../../hooks/useDidMountEffect";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "16ch",
      "&:focus": {
        width: "18ch",
      },
    },
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MenSessionList = () => {
  const {
    setSearchSupportInputValue,
    setPageLoading,
    selectedSupportPeriod,
    setSelectedSupportPeriod,
  } = useContext(DataContext);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysInMonth, setDaysInMonth] = useState([]);

  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const dates = [
    {
      date: 2,
      events: [
        {
          title: "Event Investor Connect",
          location: "StartupTN Office",
          time: "11:00PM - 12:00PM",
        },
      ],
    },
    {
      date: 11,
      events: [
        {
          title: "Session Future Of Fintech",
          location: "Online Webinar",
          time: "02:00PM - 04:00PM",
        },
      ],
    },
    {
      date: 14,
      events: [
        {
          title: "Appointments With ABC Startup",
          location: "Online Webinar",
          time: "11-12PM",
        },
      ],
    },
  ];

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const daysArray = [];

    for (
      let i = 0;
      i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1);
      i++
    ) {
      daysArray.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setDaysInMonth(daysArray);
  }, [currentDate]);

  const changeMonth = (offset) => {
    const newDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + offset)
    );
    setCurrentDate(newDate);
  };

  const [activemainTabs, setActiveMainTabs] = useState(0);

  const handleButtonClicks = (newValue) => {
    
    setActiveMainTabs(newValue);
  };
  const [selectedPeriod, setSelectedPeriod] = useState("All");
  const handleSelectPeriod = (value) => {
    setSelectedPeriod(value);
  };

  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");

  useDidMountEffect(() => {
    sessionList();
  }, [page, selectedSupportPeriod, searchInputValue, selectedPeriod]);
  const [sessionLists, setSessionLists] = useState("");
  const [sessionval, setSessionval] = useState([]);
  var id = localStorage.getItem("id");
  const sessionList = () => {
    setPageLoading(true);
    var data = {
      listSize: 6,
      pageNumber: page,
      userId: id,
      searchString: searchInputValue,
      period: selectedPeriod,
    };
    apiService("session/list", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result) {
          if (result.data) {
            setSessionLists(result.data);

            setTotalPage(result.data.totalPages);
            localStorage.setItem('sessionPending',result.data.pendingRequest)
          }
          if (result.data.responsemodelList) {
            setSessionval(result.data.responsemodelList);
          }
        }
      })
      .catch((err) => {});
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <MentorSessionFilters name={"session"} sessionList={sessionList}/>

      <section>
        <div className="sec-clr-surya">
          <div className="sec-filters-event">
            <h5>Sessions</h5>
            <Search>
              <SearchIconWrapper>
                <img src="/images/admin/search-black.png" alt="" />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Sessions"
                inputProps={{ "aria-label": "Search Sessions" }}
                onChange={handleSearch}
                type="search"
              />
            </Search>
            <div className="all-btn-bg">
              <Tabs
                value={activemainTabs}
                onChange={(event, newValue) => setActiveMainTabs(newValue)}
                aria-label="custom tabs example"
              >
                <Tab
                  className="tab-clr"
                  value={0}
                  // label="List"
                  // className={`secs-btn-1 ${activemainTabs === 0 ? "active" : ""}`}
                  label={
                    <div
                      className={`secs-btn-1 ${
                        activemainTabs === 0 ? "active" : ""
                      }`}
                    >
                      <IoIosList className="lists-icons" />
                      List
                    </div>
                  }
                  onClick={() => handleButtonClicks(0)}
                />
                <Tab
                  className="tab-clr"
                  value={1}
                  // label="Calendar View"
                  // className={`secs-btn-2 ${activemainTabs === 1 ? "active" : ""}`}
                  label={
                    <div
                      className={`secs-btn-2 ${
                        activemainTabs === 1 ? "active" : ""
                      }`}
                    >
                      <FaRegCalendar className="calander-icons" />
                      Calendar View
                    </div>
                  }
                  onClick={() => handleButtonClicks(1)}
                />
              </Tabs>
            </div>
            <div>
              <FormControl variant="outlined" size="small" sx={{ width: 120 }}>
                <InputLabel id="dropdown-label">This Week</InputLabel>
                <Select
                  labelId="dropdown-label"
                  label="Select Option"
                  variant="outlined"
                  value={selectedPeriod}
                  style={{ borderRadius: 10 }}
                  onChange={(e) => handleSelectPeriod(e.target.value)}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"This Week"}>This Week</MenuItem>
                  <MenuItem value={"This Month"}>This Month</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <ToggleButton className="toggle-fil">
                <img src="/images/filter icon.svg" alt="" />
              </ToggleButton>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item sm={12} md={4} lg={3}>
            <Box className="widget-box">
              <p>Total Sessions</p>
              <h3 className="bluee-clrr">{sessionLists.totalSession}</h3>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <Box className="widget-box">
              <p>Upcoming Sessions</p>
              <h3 className="bluee-clrr">{sessionLists.upcomingSession}</h3>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <Box className="widget-box">
              <p>Total Mentoring Hours</p>
              <h3 className="bluee-clrr">
                {sessionLists.totalMentoringHours} hrs
              </h3>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} lg={3}>
            <Box className="widget-box">
              <p>Pending Request</p>
              <h3 className="bluee-clrr">{sessionLists.pendingRequest}</h3>
            </Box>
          </Grid>
        </Grid>
      </section>

      <section>
        <CustomTabPanel value={activemainTabs} index={0} className="p-0">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-12">
              <div className="profile-box2">
                <h5 style={{ fontWeight: "600" }}>Upcoming Sessions</h5>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table
                    className="table"
                    style={{ width: "100%", borderCollapse: "collapse" }}
                  >
                    <tbody>
                      {sessionval && sessionval.map((list, index) => (
                        <tr>
                          <td
                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                            key={index}
                          >
                            <div className="d-flex align-items-center profile-img">
                              <img
                                src={list.logoName}
                                alt=""
                                className="review_logo"
                              />
                              <div className="ml-3">
                                <p className="list-para mb-0">{list.title}</p>

                                <span
                                  style={{ fontSize: "10px", color: "#B3B3B3" }}
                                >
                                  <span className="mr-3">
                                    <img
                                      src="/images/box-calender.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    {list.showDate}
                                  </span>
                                  <span>
                                    <img
                                      src="/images/box-time.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    {list.showStartTime} - {list.showEndTime}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </td>

                          <td
                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                          >
                            <div className="soc-logos">
                              <button className="mentor_view_details">
                                View Details
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {totalPage > 1 && (
                    <div className="event-pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPage}
                          page={page}
                          onChange={handleChange}
                        />
                      </Stack>
                    </div>
                  )}
                </Grid>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <section>
                <div className="containersession">
                  <div className="headersession">
                    <div className="bluee-clrr">Today</div>
                    <div className="bluee-clrr">October 2024</div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">10 AM</div>
                    <div className="eventsession"></div>
                    <div className="event"></div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">11 AM</div>
                    <div className="eventsession">
                      <div className="event lightblue">
                        <div>One to One Mentoring Session</div>
                        <div>Aneesh - StartupTN</div>
                      </div>
                    </div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">12 PM</div>
                    <div className="eventsession"></div>
                    <div className="event"></div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">1 PM</div>
                    <div className="eventsession"></div>
                    <div className="event"></div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">2 PM</div>
                    <div className="eventsession">
                      <div className="event lightpurple">
                        <div>Group Session</div>
                        <div>20 Mentees</div>
                      </div>
                    </div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">3 PM</div>
                    <div className="eventsession"></div>
                    <div className="event"></div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">4 PM</div>
                    <div className="eventsession"></div>
                    <div className="event"></div>
                  </div>
                  <div className="timeSlot">
                    <div className="time">5 PM</div>
                    <div className="eventsession"></div>
                    <div className="event"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        className='px-0'
                    >
                        <Grid item sm={12} md={6} lg={8}>
                            
                        </Grid>
                        <Grid item sm={12} md={6} lg={4}>
                            
                        </Grid>
                    </Grid> */}
        </CustomTabPanel>
        <CustomTabPanel value={activemainTabs} index={1}>
          <section>
            <div className="calendar">
              <div className="calendar-header">
                {daysOfWeek.map((day) => (
                  <div key={day} className="calendar-header-day">
                    {day}
                  </div>
                ))}
              </div>
              <div className="calendar-body">
                {daysInMonth.map((day, index) => {
                  const dateEvents = dates.find((d) => d.date === day);
                  return (
                    <div key={index} className="calendar-day">
                      {day && (
                        <>
                          {dateEvents &&
                            dateEvents.events.map((event, eventIndex) => (
                              <div
                                key={eventIndex}
                                className={`calendar-event calendar-event-${event.title
                                  .split(" ")[0]
                                  .toLowerCase()}`}
                              >
                                <div className="event-title">{event.title}</div>
                                {event.location && (
                                  <div className="event-location">
                                    {event.location}
                                  </div>
                                )}
                                <div className="event-time">{event.time}</div>
                              </div>
                            ))}
                          <div className="calendar-date">{day}</div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="calendar-controls">
                <Button className="year-btn" onClick={() => changeMonth(-1)}>
                  <FaChevronLeft />
                </Button>
                <span>
                  {currentDate.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <Button className="year-btn" onClick={() => changeMonth(1)}>
                  <FaChevronRight />
                </Button>
              </div>
            </div>
          </section>
        </CustomTabPanel>
      </section>
    </>
  );
};

export default MenSessionList;
