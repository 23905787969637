import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Circulareconomy = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/Circular-Economy-1.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Circular Economy</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h6>Union Government</h6>
                  <br />
                  <p>
                    India’s policy framework to promote the Circular Economy is
                    based on a multi-pronged approach that includes regulatory
                    measures, financial incentives, awareness campaigns and
                    capacity building.
                  </p>
                  <br />
                  <h6>National Resource Efficiency Policy (NREP)</h6>
                  <br />
                  <p>
                    One of the most important policies to achieve the goal of a
                    Circular Economy is the National Resource Efficiency Policy.
                    It was launched in 2019 with the objective of promoting
                    sustainable production and consumption patterns, enhancing
                    resource efficiency and reducing the environmental impact of
                    economic activities. The policy includes measures to
                    encourage the adoption of circular business models, such as
                    product-as-a-service, leasing and sharing and promotes the
                    use of recycled materials.
                  </p>
                  <br />
                  <h6>Swachh Bharat Mission (SBM)</h6>
                  <br />
                  <p>
                    This is another critical policy intervention when it comes
                    to aspects of waste segregation and recycling. The Swachh
                    Bharat Mission was launched in 2014 with the objective of
                    promoting cleanliness, hygiene and waste management. The
                    mission includes initiatives to promote waste segregation,
                    recycling and composting and aims to make India a
                    “zero-waste” country.
                  </p>
                  <br />
                  Atal Innovation Mission
                  <p>
                    Atal Innovation Mission was launched in 2016 to promote
                    innovation and entrepreneurship in the country. It includes
                    initiatives to support the development of circular business
                    models and encourage the adoption of sustainable
                    technologies.
                  </p>
                  <br />
                  <h6>Financial Incentives</h6>
                  <br />
                  <p>
                    In addition to these policy initiatives, a number of
                    interventions that revolve around financial incentives for
                    the efficient utilisation of resources have been made. The
                    Union Government has implemented various financial
                    incentives to encourage the adoption of circular business
                    models and promote sustainable consumption patterns. These
                    incentives include tax benefits, subsidies and low-interest
                    loans to the recycling industry.
                  </p>
                  <br />
                  <h6>National Green Hydrogen Mission</h6>
                  <br />
                  <p>
                    A budget of Rs 19,700 crore has been allocated for the
                    National Green Hydrogen Mission. This mission aims to
                    support the transition of the economy towards low carbon
                    intensity, reduce reliance on fossil fuel imports and
                    position the nation as a technological and market leader in
                    this emerging industry.
                  </p>
                  <br />
                  <h6>GOBARdhan Scheme</h6>
                  <br />
                  <p>
                    As part of the GOBARdhan Scheme, 500 new “waste to wealth”
                    plants arel being established, comprising 200 compressed
                    biogas plants and 300 community/cluster-based plants.
                  </p>
                  <br />
                  <h6>Bhartiya Prakritik Kheti Bio-Input Resource Centres</h6>
                  <br />
                  <p>
                    The Union Government plans the transition of one crore
                    families to natural farming by establishing 10,000 bio-input
                    resource centres under the Bharatiya Prakritik Krishi
                    Paddati, which has been scaled up as National Mission on
                    Natural Farming.
                  </p>
                  <br />
                  <h6>State Government</h6>
                  <br />
                  <p>
                    A Rs 1000-crore Green Fund has been created to invest in
                    circular economy, renewable energy and other projects aimed
                    at mitigating climate change impact.
                  </p>
                  <br />
                  <p>
                    StartupTN offers special packages of assistance for Green
                    Tech Startups (Startups in the fields of Sustainability,
                    Climate Change, Renewable Energy, and Circular Economy). The
                    package includes funding, training and mentorship. The
                    assistance under TANSEED has been enhanced from Rs. 10 lakh
                    to Rs. 15 lakh.
                  </p>
                  <br />
                  <p>
                    Tamil Nadu has implemented a scheme to promote circularity
                    in the State’s leather clusters for solid waste management.
                  </p>
                  <p>
                    In an attempt to control indiscriminate disposal of e-waste,
                    the Tamil Nadu Pollution Control Board has licensed four
                    e-waste processing facilities and 38 waste dismantlers in
                    the State.
                  </p>
                  <br />
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <h6>Incubator and R & D Institutions</h6>
                  <br />
                  <ul>
                    <li>
                      Central Institute of Petrochemicals Engineering &
                      Technology (CIPET)
                    </li>
                    <br />
                    <li>
                      National Environmental Engineering Research Institute
                      (NEERI) Laboratory, Chennai
                    </li>
                    <br />
                    <li>
                      NIFT-TEA Incubator-Knitwear Fashion Institute, Tiruppur
                    </li>
                    <br />
                    <li>AIC RAISE – Rathnam Incubation Centre, Coimbatore</li>
                    <br />
                    <li>ICAR National Research Centre for Banana, Tiruchi</li>
                    <br />
                    <li>Circular Economy Cell, NITI Aayog.</li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Circulareconomy;
