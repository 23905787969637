import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Femtech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss"  style={{ background: `url('/images/sector-images/StartupTN-sector-FemTech.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
          <h3 className="banner-title-sector">FemTech</h3>
        </div>
        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4><br />
                  <p>Tamil Nadu has been a pioneer in empowerment of women. It has taken sustained efforts in the form of gender-inclusive legislative interventions and women-centric schemes to ensure a pride of place in society for women.</p>
                  <p>The State is No. 1 in women entrepreneurship with 13.51 per cent of its women population operating enterprises (Sixth Economic Census 2019). Tamil Nadu also has the most women entrepreneur-owned enterprises (10.87 lakh) in the country as per the 2021 edition of The Status of Women and Men in India, a publication of the Union Ministry of Statistics and Programme Implementation.</p>
                  <p>However, the presence of women in innovation-driven Startups is proportionately low. The incentives announced in the special package will attract more women to the Tamil Nadu Startup ecosystem. For the purposes of this package, a Women-led Startup shall be a Startup owned by women or co-owned by a group of women having a minimum equity of 75% in the enterprise.</p>
                  <h4 style={{ color: "#39364e" }}>Assistance to women entrepreneurs</h4><br />
                  <ul>
                    <li>Assistance under the TANSEED scheme of StartupTN to be increased from Rs. 10 lakh to Rs. 15 lakh</li><br />
                    <li>25% of total TANSEED assistance earmarked for Women-led Startups</li><br />
                    <li>Eligible for Matching Grant Programme under Vaazhndhu Kaattuvom Project of the Department of Rural Development and Panchayat Raj</li><br />
                    <li>Focussed Angel Investor Circles under Tamil Angels platform of StartupTN</li><br />
                    <li>Exclusive co-working spaces in State-supported incubation centres and State universities. The facilities will be made available with rent and membership free of cost for the first year, subject to a maximum subsidy of Rs. 2 lakh</li><br />
                    <li>A web portal integrating Startup ecosystem enablers supporting Women-led Startups</li><br />
                    <li>Entrepreneurship Development and Innovation Institute will implement a special entrepreneurship training programme for aspiring and existing Women-led Startups</li><br />
                    <li>Women who had run a business or were earlier top executives in a corporate entity will be invited to mentor Women-led Startups/aspirants in a special mentorship programme</li><br />
                    <li>Exclusive annual acceleration programme for Women-led and FemTech Startups</li><br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4><br />
                  <p>India has the third highest number of FemTech firms globally at 4.9% (FemTech Analytics 2022 Q4). Additionally, 43% of the FemTech firms in Asia are based in India (FemTech Analytics 2022 Q2), making it the country with the highest percentage of FemTech firms in Asia. Despite the proportion of FemTech firms, India is ranked only seventh globally in the amount of investment into the industry. This is due to a lower number of investors in the region, with only 8% of global FemTech investors based in the Asia-Pacific region.</p>
                  <p>There are multiple methods for FemTech businesses to grow in the future. One way is through developing more affordable solutions and using outreach programmes so that the products can be used by more women in developing countries. Another method is through B2B partnering with established hospitals, healthcare companies and NGOs to utilise their already established distribution network (Frost and Sullivan 2020). One of the main technologies spurring growth and innovation in the industry is wearable technology.</p>
                  <p>FemTech could use wearable technology such as smartwatches for menstrual cycle tracking or to help with fertility monitoring. Another trend is the integration of artificial intelligence and machine learning into various digital tools provided by FemTech. As these technologies develop, it will be easier to get more accurate information and personalised care virtually, such as diet and fitness plans.</p>
                  <p>This increased personalisation will also make it easier for FemTech Startups to expand their customer base. Furthermore, increased research into chronic conditions or diseases affecting women, such as cervical cancer, endometriosis and PCOS, will prompt more FemTech companies to provide solutions to manage these currently underdiagnosed conditions. By creating more at-home testing or screening options, the industry could also help provide more access to healthcare for women in rural regions.</p>
                  <p>The emergence of more women-focused VC firms or angel investing networks will also help to reduce the issue of a lack of funding in the industry. These investors are estimated to be twice as likely to invest in women-led Startups (FemHealth Insights). This will also lead to more investors interested in the FemTech industry, which has many women-led enterprises.</p>
                  <p>FemTech is a rapidly evolving industry with a high future growth projection. To help increase investment in and awareness of the industry, it should not be viewed as a niche market but rather as an industry that can improve women’s health outcomes by tackling their currently unmet healthcare needs.</p>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Femtech
