import { TabContext, TabPanel } from '@mui/lab'
import { Autocomplete, Button, Box, FormHelperText, Grid, InputBase, Tab, Tabs, TextField, ToggleButton, styled, MenuItem, Select, FormControl, InputLabel } from '@mui/material'
import React from 'react'
import { useContext } from 'react';
import DataContext from '../../../../context/DataContext';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '24px',
  backgroundColor: 'rgb(255 255 255)',
  border: '1px solid #00000017',
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#d9d9d9',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '18ch',
      },
    },
  },
}));

const OpenInnovationGovernment = () => {
  const { navigator, setSearchSupportInputValue, setSelectedSupportPeriod } = useContext(DataContext);
  const { handleSubmit, control, clearErrors, reset, formState: { errors } } = useForm({})
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = (e) => {
    setSearchSupportInputValue(e.target.value);
  };

  const data = [
    'Option 1',
    'Option 2'
  ]

  return (
    <>
      <section className='container mt-5'>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item sm={12} md={6} lg={6}>
            <h3 className='blueee-clrr font-weight-bold mb-3'>Government</h3>
            <p >
              Government Departments / Agencies can post their requirements in the portal to
              make use of innovative solutions for their public service delivery from startups and innovators.
            </p>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <center>
              <div className="float-right mt-5 pb-5">
                <button className="bule-clr-button">Post Challenge</button>
              </div>
            </center>
          </Grid>
        </Grid>
      </section>

      <section className='container mt-5'>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={3} lg={3}>
            {/* <Box className='border-rad' sx={{ }}>
              <Controller
                name="requirement"
                control={control}
                defaultValue=""
                rules={{ required: "Requirement is required" }}
                render={({ field }) => (
                  <Autocomplete
                    sx={{ borderRadius: '37%' }}
                    disableClearable
                    disablePortal
                    {...field}
                    options={data}
                    // options={eventCategoryList.map((name) => name.category)}
                    // onChange={(e, selectedOptions) =>
                    //   handleSelectEventCategory(selectedOptions)
                    // }
                    renderInput={(params) => (
                      <TextField {...params} label="Requirement " />
                    )}
                  />
                )}
              />
              <FormHelperText className="text-danger">
                {errors.requirement && errors.requirement.message}
              </FormHelperText>
            </Box> */}
            <FormControl variant="outlined" fullWidth >
              <InputLabel id="dropdown-label">Requirement</InputLabel>
              <Select
                labelId="dropdown-label"
                label="Select Option"
                // value={selectedPeriod}
                variant="outlined"
                style={{ borderRadius: 30 }}
              // onChange={(e) => handleSelectPeriod(e.target.value)}
              >
                <MenuItem value={"All"}>All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <TabContext value={value} className="tab-div">
              <div className="inv-name-tab-surya">
                <Box className="inv-tabs-boxs-surya-v3">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className='scroll-inv-tabs'
                  >
                    <Tab
                      label="Live Challenge"
                      value="1"
                      style={{
                        backgroundColor: value === "1" ? "#083FB2" : "",
                        color: value === "1" ? "white" : "#000",
                      }}
                      className="setFont"
                    />
                    <Tab
                      label="Past Challenge"
                      value="2"
                      style={{
                        backgroundColor: value === "2" ? "#083FB2" : "",
                        color: value === "2" ? "white" : "#000",
                      }}
                      className="setFont"
                    />
                  </Tabs>

                  <span className='tab-dflex'>
                    <Search>
                      <SearchIconWrapper>
                        <img src="/images/admin/search-black.png" alt="" />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search Challenge"
                        inputProps={{ 'aria-label': 'Search Challenge' }}
                        onChange={handleSearch}
                        type="search"
                      />
                    </Search>
                    <ToggleButton className="toggle-fil ml-3">{<img src="/images/filter icon.svg" alt="" />}</ToggleButton>
                  </span>
                </Box>
              </div>
              <TabPanel value="1" className="catalyst-tab"></TabPanel>
              <TabPanel value="2" className="catalyst-tab"></TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </section>


      <section className='container mt-5'>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item sm={12} md={3} lg={3}>
            <div className="event-box-surya-v1">
              <div className="layer-crd-back-img-sur">
                <center><img src='/images/tnsdc-logo2 1.png' alt='' className='img-fluid' /></center>
                <p className='p-2' style={{ fontSize: '14px' }} >TamilNadu Skill Developement Corporation</p>
                <div className='cardflex p-2'>
                  <div>
                    <p className='light-bul m-0'> Requirement</p>
                    <p style={{ fontSize: '12px' }}>Developement of IT Tools</p>
                  </div>
                  <div>
                    <p className='light-bul m-0'> End Date</p>
                    <p style={{ fontSize: '12px' }}>30th June 2024</p>
                  </div>
                </div>
              </div>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "75%",
                    marginTop: "20px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  View More
                </Button>
              </center>
            </div>
          </Grid>
          <Grid item sm={12} md={3} lg={3}>
            <div className="event-box-surya-v1">
              <div className="layer-crd-back-img-sur">
                <center><img src='/images/co-optex.png' alt='' className='img-fluid' /></center>
                <p className='p-2' style={{ fontSize: '14px' }} >Co-optex</p>
                <div className='cardflex p-2'>
                  <div>
                    <p className='light-bul m-0'> Requirement</p>
                    <p style={{ fontSize: '12px' }}>Developement of IT Tools</p>
                  </div>
                  <div>
                    <p className='light-bul m-0'> End Date</p>
                    <p style={{ fontSize: '12px' }}>30th June 2024</p>
                  </div>
                </div>
              </div>
              <center>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#020671",
                    width: "75%",
                    marginTop: "20px",
                    textTransform: "none",
                    "&:hover": { backgroundColor: "#020671" },
                  }}
                >
                  View More
                </Button>
              </center>
            </div>
          </Grid>
          <Grid item sm={12} md={3} lg={3}></Grid>
          <Grid item sm={12} md={3} lg={3}></Grid>
        </Grid>
      </section>
    </>
  )
}

export default OpenInnovationGovernment
