import { Button, Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Navbar from "../../../home-module/navbar-page/Navbar";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
const BlogDetails = ({ blogId, via }) => {
  const navigator = useNavigate();
  const { id } = useParams();
  debugger;
  let ids;
  if (id !== undefined) {
    ids = atob(id);
  }
  useDidMountEffect(() => {
    BlogGet();
  }, []);
  const [blogValue, setBlogValue] = useState({});
  const [blogReference, setBlogReference] = useState([]);
  // var blogId = localStorage.getItem(blogId)
  const BlogGet = () => {
    apiService(`blog/get?id=${blogId ? blogId : ids}`, "", "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            setBlogValue(result.data);
            if (result.data.references) {
              setBlogReference(result.data.references);
            }
          }
        }
      })
      .catch((err) => {});
  };

  const handleBackBtn = () => {
    navigator(`/events/list`);
  };
  return (
    <div>
      {via !== "admin" && <Navbar />}
      <div className={via !== "admin" && "back-color"}>
        {via !== "admin" && (
          <>
            <div className="back-layer"></div>
            <div className="back-layer1"></div>
          </>
        )}

        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Container>
              <div
                className={
                  via !== "admin" ? "blog-details-bg" : "my-4 p-relative"
                }
              >
                <div className="image-container">
                  <img
                    src={
                      blogValue.imageUrl
                        ? blogValue.imageUrl
                        : "/images/blog-details-bg.png"
                    }
                    alt=""
                    className="img-fluid blog-res-img"
                  />
                  <div className="overlay"></div>
                </div>
                <div className="blog-details-title">
                  <span>Blogs</span>
                  <h2 className="mt-3">{blogValue.title}</h2>
                </div>
              </div>
            </Container>
          </Grid>
        </Grid>
        {via !== "admin" && (
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
        )}

        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="blog-details-margin">
              <div className="blog-details-content mt-3">
                <h5>{blogValue.title}</h5>
                <span> {blogValue.createdTime}</span>
              </div>
              <div>
                <p className="blog-details-para mt-2">
                  {blogValue.description}
                </p>
              </div>
              <div className="blog-box">
                <div className="blog_img-div">
                  <img
                    src={
                      blogValue.authorImageUrl
                        ? blogValue.authorImageUrl
                        : "/images/user.png"
                    }
                    alt=""
                    className="blog_img"
                  />
                </div>
                <div className="blog_name_details">
                  <p className="hubname mb-0" style={{ color: "#cbcbcb" }}>
                    {" "}
                    Blog Author
                  </p>
                  <p className="hubhead mb-0"> {blogValue.author}</p>
                  <div>
                    {blogValue.authorEmail && (
                      <>
                        <img src="/images/blog-mail.png" alt="" />
                        <span className="ml-2 blog-box-val">
                          {cryptodecryptJS(blogValue.authorEmail)}
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {blogValue.authorLinkedin && (
                      <>
                        <Link
                          to={blogValue.authorLinkedin}
                          target="_blank"
                          rel="auther-linkedin"
                        >
                          <img
                            src="/images/blog-linkedin.png"
                            alt=""
                            className="linekein-blog img-fluid"
                          />
                        </Link>
                        {/* <span className="blog-box-val" style={{ overflowWrap: 'anywhere' }}>
                          {blogValue.authorLinkedin}
                        </span> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BlogDetails;
