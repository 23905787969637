import { Divider } from '@mui/material'
import React from 'react'

import { Link } from 'react-router-dom';
import InvestorTeamAdd from './InvestorTeamAdd';
const InvestorTeamList = () => {
  return (
    <div>
      <header className='team_header'>
        <div className='d-flex align-items-center'>
          <h4>Team Member</h4>
          <div className='ml-auto'>
            <InvestorTeamAdd />
            
          </div>
        </div>
      </header>
      <div className='team_list_div'>
        <div className='team_list_data'>
          <div className='d-flex align-items-center'>
            <img src='/images/stevejobs.png' className='team_logo' alt='' />
            <div className='team_name_dat'>
              <h5 className='mb-0 color-blue'>Sanjay Mehta</h5>
              <p className='mb-0 font-sm'>Founder & Partner</p>
            </div>
            <div className='ml-auto'>
              <Link to={''} target='blank'><img src='/images/linkedin-logo.png' alt='' className='m-3' /></Link>
              <Link to={''} target='blank'><img src='/images/twitter.png' alt='' className='m-3' /></Link>
            </div>
          </div>
        </div>
        <Divider />
        <div className='team_list_data'>
          <div className='d-flex align-items-center'>
            <img src='/images/stevejobs.png' className='team_logo' alt='' />
            <div className='team_name_dat'>
              <h5 className='mb-0 color-blue'>Sanjay Mehta</h5>
              <p className='mb-0 font-sm'>Founder & Partner</p>
            </div>
            <div className='ml-auto'>
              <Link to={''} target='blank'><img src='/images/linkedin-logo.png' alt='' className='m-3' /></Link>
              <Link to={''} target='blank'><img src='/images/twitter.png' alt='' className='m-3' /></Link>
            </div>
          </div>
        </div>
        <Divider />
      </div>
    </div>
  )
}

export default InvestorTeamList