import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { FaCircleArrowRight } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IoIosAddCircle } from "react-icons/io";
import Navbar from "../../home-module/navbar-page/Navbar";

const SmartShgpage = () => {
  const navigate = useNavigate();

  const handlenow = () => {
    navigate('/login')
  }
  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
      <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text mt-5">
              <div className="sm-banner-chip-text">
                <span>{`Equitable Growth  >  SHG`}</span>
                <h1 className="banner-text-gradient">SMART SHG <br />
                  THOZHILANANGU</h1>
                <h6>
                  Empowering women economically is imperative for an
                  inclusive and progressive society
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Get in Touch</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <div className="container acce-content-sec ">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ paddingBottom: "5px" }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-accePage">
                <h4>What is Smart SHG Thozhilanangu?</h4>
                <p>
                  Smart SHG, known as ‘Thozhilanangu’, is an initiative by StartupTN
                  towards inculcating the entrepreneurial culture among
                  the women Self Help Groups (SHG).The aim is to promote mass
                  entrepreneurship among women from economically weaker sections.
                </p>
                <ul>
                  <li>Setting up infrastructure</li>
                  <li>Procurement</li>
                  <li>Outsourcing</li>
                </ul>
                {/* <div className="left-content-actions">
                  <button className="ser-viewbtn">Explore Services</button>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="acce-imag "
                  src={"/images/Girlimage.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>

        </div>
        <div className="container-fluid">
          <div className="why-accel-sec1">
            <div className="shg-bg">
                <h3>The Concept</h3>
                <p>The concept proposes to work on hub and spoke model. The enterprises with successful track record and suitable 
                  resources will be the hub. Micro enterprises formed from SHGs will be the spokes connected to main hubs. The 
                  ‘hubs’ will directly procure from the ‘spokes’.
                </p>
                <p className="m-0 p-0">This above model involves any of the following:</p>
                <ul className="m-0 p-0">
                  <li>Converting SHG members into entrepreneurs</li>
                  <li>Outsourcing orders to women SHG groups</li>
                </ul>
                <p>StartupTN is coordinating the formation of Smart SHGs. StartupTN is coordinating the formation of Smart SHGs. It 
                  further follows up with the process of setting up infrastructure, procurement, outsourcing and related activities</p>
            </div>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>SMART SHG THOZHILANANGU</h3>
                    <p>
                      Empowering Women Entrepreneurs Infrastructure, Procurement, Outsourcing
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default SmartShgpage