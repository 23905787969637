import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tabs,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../../context/DataContext";
import notifyService from "../../../../../api/notifySerivce";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import apiService from "../../../../../api/apiService";
const MenRequestModal = ({ open, onClose }) => {
  const today = dayjs();
  const tomorrow = dayjs().add(0, "day");
  const { setPageLoading, VisuallyHiddenInput } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ values: formData });

  const onSubmit = (data) => {
    setPageLoading(true);
    data.date = data.date.format("YYYY-MM-DD");
    data.startTime = data.startTime.format("HH:mm");
    data.endTime = data.endTime.format("HH:mm");
    
    apiService("session/create", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data && result.data.responseStatus === "Success") {
          onClose();
        }
      })
      .catch((err) => {});
  };
  const onError = (data) => {
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };
  const [startdate, setStartDate] = useState(null);
  const handleStartDateFormat = (newValue) => {
    setStartDate(newValue);
    setValue("date", newValue);
    clearErrors("date");
    // const datePart = newValue.format('YYYY-MM-DD');
  };
  const [startTime, setStartTime] = useState(null);
  const handleStartTimeFormat = (newValue) => {
    setStartTime(newValue);
    setValue("startTime", newValue);
    clearErrors("startTime");
    // const timePart = newValue.format('HH:mm');
  };
  const [endTime, setEndTime] = useState(null);
  const handleEndTimeFormat = (newValue) => {
    setEndTime(newValue);
    setValue("endTime", newValue);
    clearErrors("endTime");
    // const timePart = newValue.format('HH:mm');
  };
  return (
    <>
      <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <DialogTitle>
          Add Appointment
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 44,
              top: 30,
              backgroundColor: "red",
              borderRadius: "50%",
              color: "white",
              padding: "1px",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="m-0">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box className="bx-sh">
                <FormControl
                  component="fieldset"
                  fullWidth
                  error={!!errors.modeOfEngagement}
                >
                  <FormLabel component="legend">Mode of Engagement</FormLabel>
                  <Controller
                    name="modeOfEngagement"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select a mode of engagement" }}
                    render={({ field }) => (
                      <RadioGroup
                        row
                        {...field}
                        aria-label="mode-of-engagement"
                      >
                        <FormControlLabel
                          value="online"
                          control={<Radio />}
                          label="Online"
                        />
                        <FormControlLabel
                          value="offline"
                          control={<Radio />}
                          label="Offline"
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.modeOfEngagement && (
                    <FormHelperText>
                      {errors.modeOfEngagement.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className="bx-sh">
                <Controller
                  name={`date`}
                  control={control}
                  rules={{ required: "Start Date is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]} fullWidth>
                        <DatePicker
                          label="Start Date"
                          format="DD/MM/YYYY"
                          minDate={tomorrow}
                          value={startdate}
                          {...field}
                          sx={{ width: "100%" }}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) =>
                            handleStartDateFormat(newValue)
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.date && errors.date.message}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
              <Box className="bx-sh">
                <Controller
                  name={`startTime`}
                  control={control}
                  rules={{ required: "Start Time is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]} fullWidth>
                        <TimePicker
                          label="Start Time "
                          format="HH:mm"
                          value={startTime}
                          {...field}
                          sx={{ width: "100%" }}
                          inputFormat="HH:mm"
                          onChange={(newValue) =>
                            handleStartTimeFormat(newValue)
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.startTime && errors.startTime.message}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
              <Box className="bx-sh">
                <Controller
                  name={`endTime`}
                  control={control}
                  rules={{ required: "End Time is required" }}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]} fullWidth>
                        <TimePicker
                          label="End Time "
                          format="HH:mm"
                          value={endTime}
                          {...field}
                          sx={{ width: "100%" }}
                          inputFormat="HH:mm"
                          onChange={(newValue) => handleEndTimeFormat(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                />
                <FormHelperText className="text-danger">
                  {errors.endTime && errors.endTime.message}
                </FormHelperText>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className="text-center mb-3 w-100">
            {/* <Button onClick={onClose} className="discard-btn">Close</Button> */}
            <Button type="submit" className="publish-button">
              <span>Book the Slots</span>
            </Button>
          </div>
        </DialogActions>
      </form>
    </>
  );
};

export default MenRequestModal;
