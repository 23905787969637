import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Signuplayout from '../../auth-module/signup-details-page/Signuplayout';
import Basicinfo from './organization-info-page/Basicinfo';
import Address from './organization-info-page/Address';
import Sociallinks from './organization-info-page/Sociallinks';
import Documents from './organization-info-page/Documents';
import Foundedetails from './team-info-page/Foundedetails';
import Teamsize from './team-info-page/Teamsize';
import Moreinfo from './organization-info-page/Moreinfo';

const Startuproutes = () => {
    return (
        <main>
            <Routes>
                <Route path='/startups' element={<Signuplayout />}>
                    <Route path="/startups" element={<Navigate replace to="/startups/basicinfo" />} />
                    <Route path='/startups/basicinfo' element={<Basicinfo />} />
                    <Route path='/startups/address' element={<Address />} />
                    <Route path='/startups/social-links' element={<Sociallinks />} />                    
                    <Route path='/startups/documents' element={<Documents />} />
                    <Route path='/startups/moreinfo' element={<Moreinfo />} />
                    <Route path='/startups/founder' element={<Foundedetails url={'startup'} navigate={'/startup/profile'}/>} />
                    {/* <Route path='/startups/teamsize' element={<Teamsize />} /> */}
                </Route>
            </Routes>
        </main>
    )
}

export default Startuproutes