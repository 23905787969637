import React from 'react'

const InvNewsMediaList = () => {
  return (
    <div>
      <div className="profile-box3">
        <div className="text-center">
          <h5 style={{ fontWeight: '600' }}>Add News & Medias</h5>
        </div>
      </div>
      <div className="profile-box3 mt-2">
        <div className="text-center">
          <h5 style={{ fontWeight: '600' }}>Add Organization Video</h5>
        </div>
      </div>
      <div className="profile-box3 mt-2">
        <div className="text-center">
          <h5 style={{ fontWeight: '600' }}>Add Testimonials</h5>
        </div>
      </div>
    </div>
  )
}

export default InvNewsMediaList