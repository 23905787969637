import React from 'react'
import './undermaintenance.css'
import apiService from '../../../api/apiService'
import { useNavigate } from "react-router-dom";
import Navbar from '../../home-module/navbar-page/Navbar';
import { Container, Grid } from '@mui/material';
import UndermaintainNavbar from './UndermaintainNavbar';

const Undermaintenance = () => {
  const navigate = useNavigate();
  const upApi = () => {
    // apiService(`actuator/health`, '', 'get').then((res) => {
    //   navigate(-1)
    // })
    localStorage.clear()
    sessionStorage.clear()
    window.location.assign('/login')
  }
  return (
    // <div class="under_maintaine">
    //     <div class="boxunder">
    //         <div class="animation">
    //             <div class="one spin-one"></div>
    //             <div class="two spin-two"></div>
    //             <div class="three spin-one"></div>
    //         </div>
    //         <h1>Under maintenance</h1>
    //         <p>We apologize for the inconvenience, but our website is currently undergoing scheduled maintenance. We'll be back online as soon as possible.</p>
    //         <p>If you need immediate assistance, please contact us at tanfund@startuptn.in</p>
    //         <p>Thank you for your patience.</p>
    //         <p className='refbtn' onClick={upApi} target="_blank">Refresh</p>
    //     </div>
    // </div>
    <div className="back-color1 pb-5">
      <UndermaintainNavbar />
      <div className="back-layer"></div>
      <div className="back-layer1"></div>
      <div className="under-layer"></div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Container>

            <div className="main-title">
              <div className='text-center'>
                <img src="/images/under-maintance.png" alt="" className='img-fluid' />
              </div>
              <div className='under-title'>
                <h5 className="big-title mb-0 mt-2">Website Under Maintenance</h5>
                <div className="underimg">
                  <img src={"./images/Vector (1).png"} alt="vector" />
                </div>
              </div>
              <div className='under-para mt-4'>
                <p className='m-0'>We're currently performing scheduled maintenance to improve your experience.Thank you for your patience.</p>
                <span>We'll be back soon!</span>
                <p className='mt-3'>For any urgent inquiries, please contact us at :<a href='#'> tech@startuptn.in</a></p>
                <p className='refbtn' 
                onClick={upApi} target="_blank"
                >Refresh</p>
              </div>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

export default Undermaintenance