import { Autocomplete, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import DataContext from '../../../../context/DataContext'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import apiService from '../../../../api/apiService'
import notifyService from '../../../../api/notifySerivce'

const Moreinfo = () => {
  const { navigator, setPageLoading, handleNextClick ,isWhitespace} = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, formState: { errors }, } = useForm({ values: formData });

  useDidMountEffect(() => {
    handleNextClick(75)
    dropdownApi()
  }, [])
  const [startupStages, setStartupStages] = useState([])
  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${'stages'}`, '', 'unauthget').then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.dropDownValues) {
            var dropDown = result.data.dropDownValues
            setStartupStages(dropDown.values)
          }
        }
      }
    }).catch((err) => {

    });
  }
  useDidMountEffect(() => {
    getApi()
  }, [])
  const getApi = () => {
    setPageLoading(true)
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data
          setFormData(data)
        }

      }).catch((err) => {

      });
  }
  const onSubmit = (data) => {

    console.log('Form Data:', data);
    
    apiService('userprofile/save', data, 'post').then((result) => {
      if (result && result.data && result.data.responseStatus === 'Success') {
        navigator('/startups/founder')
      }
    }).catch((err) => {

    });
  };
  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Organization Info {`>`} <span className='path_sapn'>More Info</span></p>
              <p className='ml-auto'><span className='count_num'>5</span>/5</p>
            </div>
            <div className='signup_box_div mt-4'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <Controller
                      name="currentStage"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                        disableClearable
                          options={startupStages}
                          getOptionLabel={(option) => option}
                          defaultValue={[]}
                          value={value || []}
                          onChange={(event, newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Current Stage "
                              placeholder="Select Current Stage"

                              helperText={error ? 'Current Stage is required' : ''}
                              error={Boolean(errors.currentStage && errors.currentStage)}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.currentStage && errors.currentStage.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <p>
                      What is the problem you are trying to solve?
                    </p>
                    <div>
                      <div className='my-3'>
                        <Controller
                          name="problemResolve"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: false,
                            //  validate: {
                            //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                            // },
                    
                        }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-multiline-static"
                              label="Enter your Description "
                              fullWidth
                              multiline
                              {...field}
                              rows={4}
                              inputProps={
                                { maxLength: 500}
                              }
                              defaultValue=""
                              error={Boolean(errors.problemResolve && errors.problemResolve)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.problemResolve && errors.problemResolve.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <div className='mb-3'>
                      <p><b>Brief Description about your Startup</b></p>
                      <div>
                        <div className='my-3'>
                          <Controller
                            name="about"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: false, 
                              // validate: {
                              //     noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                              // },
                      
                          }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="outlined-multiline-static"
                                label="Enter your Description "
                                fullWidth
                                multiline
                                rows={4}
                                inputProps={
                                  { maxLength: 500}
                                }
                                defaultValue=""
                                error={Boolean(errors.about && errors.about)}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.about && errors.about.message}
                          </FormHelperText>
                        </div>
                      </div>
                    </div>
                  </Grid>                  
                  <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <button className='next-btn' type="submit">Next</button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Moreinfo