import { Autocomplete,FormHelperText,Grid,InputAdornment,TextField,} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from "../../../../api/notifySerivce";
import cryptoJS from "../../../../hooks/cryptoJS";
import cryptodecryptJS from "../../../../hooks/cryptodecryptJS";
const BasicinfoInvestment = () => {
  const { navigator,validateEmail,isWhitespace,validateNumberonly, setPageLoading,handleNextClick, validatePhoneNumber, validatePANNumber,checkUppernumeric} = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors, formState: { errors },} = useForm({ values: formData });

  useDidMountEffect(() => {
    handleNextClick(50);
    designationdropdownApi()
    sectorsdropdownApi();
  }, []);
  const [designationList, setDesignationList] = useState([]);
  const designationdropdownApi = () => {
    apiService("home/designation/list?type=USER", "", "get")
      .then((result) => {
        if (result && result.data && result.data.designations) {
              var values = result.data.designations;
              setDesignationList(values);
        }
      })
      .catch((err) => {});
  };
  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
            if (result && result.data) {
              var values = result.data;
              setSectors(values.sectors);
            }
      })
      .catch((err) => {});
  };
  useDidMountEffect(() => {
    if (id !== null) {
      getApi();
    }
  }, []);
  const [selectedValues, setSelectedValues] = useState([]);
  let roleval = localStorage.getItem("role");
  var id = localStorage.getItem("id");
  var type = localStorage.getItem('type')
  const getApi = () => {
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          if (data.phone) {
            data.phone = cryptodecryptJS(data.phone);
          }
          if (data.email) {
            data.email = cryptodecryptJS(data.email);
          }
          if (data.pan) {
            data.pan = cryptodecryptJS(data.pan)
            // setPanTickIcon(1)
          }
          setFormData(data);
          if (data.focusedSector) {
            setSelectedValues(data.focusedSector);
          }
          if (data.designation) {
            setDesignationValues(data.designation);
          }
        }
      })
      .catch((err) => {});
  };
  const handleSelectSector = (value) => {
    setValue("focusedSector", value);
    clearErrors('focusedSector');
    setSelectedValues(value);
  };
 
  const [designationValues, setDesignationValues] = useState([]);
  const handleDesignation = (value) => {
    setValue("designation", value);
    clearErrors("designation");
    setDesignationValues(value);
  }
  const onSubmit = (data) => {
    console.log("Form Data:", data);
    let ids = [];
    const selectedDesignation = designationList.find(
      (designation) => designation.name === data.designation
    );
    const designationIdString = selectedDesignation ? selectedDesignation.id.toString() : null;
    data?.focusedSector?.forEach((name) => {
      sectors?.forEach((val) => {
        if (name === val?.name) {
          ids?.push({ name, id: val.id.toString() });
        }
      });
    });
    const getIds = ids.map((val) => val.id);
    data.type = localStorage.getItem("role");
    data.persona = type
    data.userid = id
    data.phone = cryptoJS(data.phone)
    data.email = cryptoJS(data.email.toLowerCase())
    data.pan = cryptoJS(data.pan.toUpperCase());
    const payload = {
      ...data,
      focusedSectorIds: getIds,
    };
    apiService("userprofile/save", payload, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          navigator("/investor/address");
        }
      })
      .catch((err) => {});
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService("danger", "Submit Failed", "Please check the mandatory fields" );
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">Basic Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">1</span>/4
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      // rules={{
                      //   required:
                      //     roleval === "ANGEL-INVESTOR"
                      //       ? "Full Name is required"
                      //       : roleval === "VC-INST-INVESTOR"
                      //       ? "VC Name is required"
                      //       : roleval === "ANGELNETWORKS-FAMILYOFFICE"
                      //       ? "Angel Network Name is required"
                      //       : roleval === "PRIVATE-EQUITY"
                      //       ? "Private Equity Name is required"
                      //       : roleval === "REVENUE-FINANCING"
                      //       ? "Revenue Based Financing Name is required"
                      //       : roleval === "VENTURE-DEBT"
                      //       ? "Venture Debt Name is required"
                      //       : "",
                           
                      // }}
                      rules={{
                        required: roleval === "ANGEL-INVESTOR"
                              ? "Full Name is required"
                              : roleval === "VC-INST-INVESTOR"
                              ? "VC Name is required"
                              : roleval === "ANGELNETWORKS-FAMILYOFFICE"
                              ? "Angel Network Name is required"
                              : roleval === "PRIVATE-EQUITY"
                              ? "Private Equity Name is required"
                              : roleval === "REVENUE-FINANCING"
                              ? "Revenue Based Financing Name is required"
                              : roleval === "VENTURE-DEBT"
                              ? "Venture Debt Name is required"
                              : "", validate: {
                            noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        },
                    }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label={
                            roleval === "ANGEL-INVESTOR"
                              ? "Full Name *"
                              : roleval === "VC-INST-INVESTOR"
                              ? "VC Name *"
                              : roleval === "ANGELNETWORKS-FAMILYOFFICE"
                              ? "Angel Network Name *"
                              : roleval === "PRIVATE-EQUITY"
                              ? "Private Equity Name *"
                              : roleval === "REVENUE-FINANCING"
                              ? "Revenue Based Financing Name *"
                              : roleval === "VENTURE-DEBT"
                              ? "Venture Debt Name *"
                              : ""
                          }
                          placeholder={
                            roleval === "ANGEL-INVESTOR"
                              ? "Enter Full Name"
                              : roleval === "VC-INST-INVESTOR"
                              ? "Enter VC Name"
                              : roleval === "ANGELNETWORKS-FAMILYOFFICE"
                              ? "Enter Angel Network Name"
                              : roleval === "PRIVATE-EQUITY"
                              ? "Enter Private Equity Name"
                              : roleval === "REVENUE-FINANCING"
                              ? "Enter Revenue Based Financing Name"
                              : roleval === "VENTURE-DEBT"
                              ? "Enter Venture Debt Name"
                              : ""
                          }
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.name && errors.name)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.name && errors.name.message}
                    </FormHelperText>
                  </Grid>
                  {roleval === "ANGELNETWORKS-FAMILYOFFICE" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="leadInvestor"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Lead Investor is required",
                          validate: {
                            noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        },
                         }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Lead Investor *"
                            placeholder="Enter Lead Investor"
                            fullWidth
                            type="text"
                            {...field}
                            error={Boolean(errors.leadInvestor && errors.leadInvestor)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.leadInvestor && errors.leadInvestor.message}
                      </FormHelperText>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Mail ID is required",
                        validate: validateEmail,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Mail ID *"
                          placeholder="Mail ID"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.email && errors.email)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.email && errors.email.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Phone Number is required",
                        validate: validatePhoneNumber,
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Phone Number *"
                          placeholder="Enter Phone Number"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.phone && errors.phone)}
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.phone && errors.phone.message}
                    </FormHelperText>
                  </Grid>
                  {roleval === "ANGEL-INVESTOR" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="organisationName"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Organization name is required",
                          validate: {
                            noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                        },
                        }}
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            label="Organization name *"
                            placeholder="Enter Organization name"
                            fullWidth
                            type="text"
                            {...field}
                            error={Boolean(errors.organisationName && errors.organisationName)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.organisationName &&
                          errors.organisationName.message}
                      </FormHelperText>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {roleval === "ANGEL-INVESTOR" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                   <Controller
                      name="designation"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Designation is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disableClearable
                          options={designationList.map((list) => list.name)}
                          value={designationValues}
                          onChange={(e, selectedOption) =>
                            handleDesignation(selectedOption)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Designation *"
                              placeholder="Select Designation"
                              error={Boolean(errors.designation && errors.designation)}
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger ml-0">
                      {errors.designation && errors.designation.message}
                    </FormHelperText>
                  </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="focusedSector"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          {...field}
                          multiple={true}
                          disableClearable
                          options={sectors.map((list) => list.name)}
                          value={selectedValues}
                          onChange={(e, selectedOption) =>
                            handleSelectSector(selectedOption)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Focused Sector *"
                              placeholder="Select Focus Sector"
                              error={!!error}
                              helperText={
                                error ? "Focus Sector is required" : ""
                              }
                              
                            />
                          )}
                          fullWidth
                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.focusedSector && errors.focusedSector.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="pan"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "PAN is required",
                        // validate: validatePANNumber
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="PAN *"
                          placeholder="Enter PAN"
                          fullWidth
                          type="text"
                          // inputProps={{ maxLength: 10 }}
                          // onKeyDown={(e) => checkUppernumeric(e)}
                          // onInput={(e) => {
                          //   handlePanChange(e);
                          // }}
                          {...field}
                          error={Boolean(errors.pan && errors.pan)}
                        // InputProps={{
                        //   endAdornment: panTickIcon === 1 ? (
                        //     <InputAdornment position="end">
                        //       <CheckCircleIcon color="success" />
                        //     </InputAdornment>
                        //   ) : panTickIcon === 2 ? (
                        //     <InputAdornment position="end">
                        //       <CloseIcon color="error" />
                        //     </InputAdornment>
                        //   ) : null,
                        // }}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pan && errors.pan.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center" >
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default BasicinfoInvestment;
