import React from "react";

import {
  Button,
 
  Container,
 
  Grid,
} from "@mui/material";

import Navbar from "../../home-module/navbar-page/Navbar";
import { useNavigate } from "react-router-dom";

const SupportAcceleratorPage = ({name}) => {

  const navigate = useNavigate();
 
  const handlenow = () => {
    navigate('/login')
  }

  return (
    <div className="smartCard-page">
      <Navbar />
      <div className="radial-back-layer">
      <div className="round-layers"></div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="banner-text">
              <div className="sm-banner-chip-text">
                <span>{`${name}  >  Accelerator`}</span>
                <h1 className="banner-text-gradient">ACCELERATOR</h1>
                <h6>
                  Startup Accelerator is a programme under Incubation and
                  Acceleration initiative of Startup
                </h6>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Get in Touch</button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="smart-card-info">
        <div className="container acce-content-sec ">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ paddingBottom: "5px" }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-accePage">
                <h4>What is Startup - Accelerator ?</h4>
                <p>
                  The Startup Accelerator offers access to a diverse network
                  of mentors and experienced entrepreneurs to help refine your
                  product, strengthen your business model, and connect with
                  investors. Joining the accelerator means becoming part of a
                  supportive community. The program’s goal is to fast-track your
                  startup’s growth through intensive mentorship, structured
                  support, and valuable resources over a set period. Applicants
                  are expected to have more than just an idea—ideally, they
                  should have a prototype or minimum viable product.
                </p>
                {/* <div className="left-content-actions">
                  <button className="ser-viewbtn">Explore Services</button>
                </div> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="smart-crd-img">
                <img
                  className="acce-imag "
                  src={"/images/accelerator-img.png"}
                  alt="smart-card"
                />
              </div>
            </Grid>
          </Grid>
         
        </div>
        <div className="why-accel-sec">
          <div className="why-accel-title">
            <h4>
              <span> Startup Accelerator </span>program supports the early
              stage founders by
            </h4>
          </div>
          <div className="why-sm-paras">
            <Container>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img src={"/images/acce-access-key.png"} alt="Acess Key" />
                    </div>
                    <div className="why-sm-det">
                      <h6>Providing Playbook</h6>
                      <p className="acce-paras">
                        Access proven strategies and insights from successful
                        startups to guide your journey.
                      </p>
                    </div>
                  </div>
                </Grid>
                {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts text-center">
                    <div className="why-sm-icns">
                      <img
                        src={"/images/acce-Group.png"}
                        alt="comprehensive data"
                      />
                    </div>
                    <div className="why-sm-det">
                      <h6>Making Connections</h6>
                      <p className="acce-paras">
                        Connect directly with investors to secure the funding
                        you need to grow.
                      </p>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className="why-sm-texts1 text-center">
                    <div className="why-sm-icns">
                      <img
                        src={"/images/acce-presentation.png"}
                        alt="exclusive events"
                      />
                    </div>
                    <div className="why-sm-det">
                      <h6>Explaining Pitch</h6>
                      <p className="acce-paras">
                        Learn the most effective pitching techniques to
                        captivate and convince potential investors.
                      </p>
                    </div>
                  </div>
                </Grid>
                <div className="acce-sub-paras">
                  <p>
                    Accelerators also connect the startups to subject-matter
                    experts to help them gain multiple domain expertise
                  </p>
                </div>
              </Grid>
            </Container>
          </div>
        </div>
        <div className="join-us-now-sec">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h3>Let’s Get Started</h3>
                    <p>
                    Startup ACCELERATOR  - Fuel Your Startup's Growth with Expert Guidance
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SupportAcceleratorPage;
