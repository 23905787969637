import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
} from "@mui/material";
import { FaPlus } from "react-icons/fa6";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaUserPlus } from "react-icons/fa6";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaSave } from "react-icons/fa";
import { FormControl, TextField } from "@mui/material";
import { MdDeleteOutline, MdOutlineAdd } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import notifyService from "../../../../api/notifySerivce";

const Eventeammember = ({ setOpen, getApi }) => {
  const { validateEmail, validateNumberonly } = useContext(DataContext);
  const [designationList, setDesignationList] = useState([]);
  const [designationValues, setDesignationValues] = useState([]);

  const [formData, setFormData] = useState({});
  useDidMountEffect(() => {
    addFounderBtn();
    DesignationApi();
  }, []);
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: formData,
  });
  const {
    fields: itemFields,
    append: addItem,
    remove: delItem,
  } = useFieldArray({
    control,
    name: "founder",
  });
  const onSubmit = (data) => {
    console.log(data);
    apiService("startup/founder/save", data, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          getApi();
          reset();
          setOpen(false);
        }
      })
      .catch((err) => { });
  };
  if (itemFields.length === 0) {
    addItem();
  }
  const handleReportClose = () => {
    setOpen(false);
  };

  const addFounderBtn = () => {
    addItem({
      id: 0,
      name: "",
      email: "",
      phone: "",
      designation: "",
    });
  };

  const [designation, setDesignation] = useState([]);
  let designationsValue
  const DesignationApi = () => {
    apiService(`home/designation/list`, "", "get")
      .then((result) => {
        if (result && result.data) {
          if (result.data.designations) {
            setDesignation(result.data.designations);
            designationsValue = result.data.designations
          }
        }
      })
      .catch((err) => { });
  };

  const handleSelectDesignation = (value, index) => {
    setValue(`founder.${index}.designation`, value);
    clearErrors(`founder.${index}.designation`);
  };
  return (
    <div className="event-modal-box-1 mt-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="Participants-flex">
          <h3>Add Participants</h3>
          <Button
            variant="contained"
            startIcon={
              <FaPlus className="ml-2" style={{ fontSize: "16px" }} />
            }
            sx={{
              borderRadius: "8px",
              padding: "2px 11px",
              textTransform: "none",
            }}
            onClick={() => addFounderBtn()}
          >
            Add
          </Button>
        </div>
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "fixed",
              bottom: "40px",
              right: "40px",
              zIndex: 0,
            }}
          >
            <MdOutlineAdd
              style={{
                fontSize: "30px",
                background: "#36454F",
                color: "#fff",
                borderRadius: "20px",
                padding: "5px",
              }}
              onClick={() => addItem()}
            />
          </div>
        </div>
        {itemFields &&
          itemFields.map((item, index) => (
            <>
              <Grid
                container
                direction="flex"
                justifyContent="center"
                alignItems="flex-start"
                key={item.id}
              >
                <Grid item lg={3} md={6} xs={12} sm={6}>
                  <FormControl>
                    <Controller
                      name={`founder.${index}.name`}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Name is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Name *"
                          type="text"
                          error={Boolean(
                            errors.founder?.[index]?.name &&
                            errors.founder?.[index]?.name
                          )}
                        />
                      )}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.founder?.[index]?.name &&
                        errors.founder?.[index]?.name?.message}
                    </p>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <FormControl>
                    {/* <Controller
                        name={`founder.${index}.designation`}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Designation is required",
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            label="Designation"
                            type="text"
                            
                          />
                        )}
                      />
                      <p style={{ color: "red", textAlign: "left" }}>
                        {errors.founder?.[index]?.designation &&
                          errors.founder?.[index]?.designation?.message}
                      </p> */}
                    <Controller
                      name={`founder.${index}.designation`}
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <Autocomplete
                          {...field}
                          size="small"
                          fullWidth
                          disableClearable
                          sx={{ width: 210 }}
                          options={designation.map((option) => option.name)}
                          onChange={(event, newValue) =>
                            handleSelectDesignation(newValue, index)
                          }
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              size="small"
                              {...params}
                              label="Designation *"
                              placeholder="Select Designation"
                              error={!!error}
                              helperText={
                                error ? "Designation is required" : ""
                              }
                            />
                          )}

                          margin="normal"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.founder?.[index]?.designation &&
                        errors.founder?.[index]?.designation.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={3} md={6} xs={12} sm={6}>
                  <FormControl>
                    <Controller
                      name={`founder.${index}.email`}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Email is required",
                        validate: validateEmail,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Email *"
                          type="email"
                          error={Boolean(
                            errors.founder?.[index]?.email &&
                            errors.founder?.[index]?.email
                          )}
                        />
                      )}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.founder?.[index]?.email &&
                        errors.founder?.[index]?.email?.message}
                    </p>
                  </FormControl>
                </Grid>
                <Grid item lg={2} md={6} xs={12} sm={6}>
                  <FormControl>
                    <Controller
                      name={`founder.${index}.phone`}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "phone is required",
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label="Phone Number"
                          onKeyDown={(e) => validateNumberonly(e)}
                          inputProps={{ maxLength: 10 }}
                          error={Boolean(
                            errors.founder?.[index]?.phone &&
                            errors.founder?.[index]?.phone
                          )}
                        />
                      )}
                    />
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors.founder?.[index]?.phone &&
                        errors.founder?.[index]?.phone?.message}
                    </p>
                  </FormControl>
                </Grid>
                <Grid item lg={1} md={6} xs={12} sm={6} className="text-center">
                  <MdDeleteOutline
                    onClick={() => delItem(index)}
                    className="modal-delete"
                  />
                </Grid>
              </Grid>
              <div className="grid-border"></div>
            </>
          ))}

        <div className="text-center">
          <Button
            type="submit"
            variant="contained"
            startIcon={
              <FaSave className="ml-2" style={{ fontSize: "16px" }} />
            }
            sx={{
              borderRadius: "8px",
              padding: "7px 11px",
              textTransform: "none",
            }}
          // onClick={() => handlParticipants()}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Eventeammember;
