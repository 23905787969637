import React, { useState } from "react";
import apiService from "../../../api/apiService";
import { InputBase, styled } from "@mui/material";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "24px",
  backgroundColor: "rgb(255 255 255)",
  border: "1px solid #00000017",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#d9d9d9",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    // [theme.breakpoints.up("sm")]: {
    //     width: "18ch",
    //     "&:focus": {
    //       width: "25ch",
    //     },
    //   },
  },
}));
const SearchableDropdown = ({
  isOpen,
  setIsOpen,
  handleOffDropDown,
  lblName,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [seachValue, setSearchValue] = useState("");
  const handleSearchChange = (event) => {
    const value = event.target.value;
    if (value !== "") {
      setIsOpen(false);
      setSearchValue(value);
      var req = {
        searchString: value,
      };
      apiService("user/search", req, "post")
        .then((result) => {
          if (result && result.data && result.data.responsemodelList) {
            setFilteredOptions(result.data.responsemodelList);
            if (value !== "") {
              setIsOpen(true);
            }
          }
        })
        .catch((err) => {});
    } else {
      setIsOpen(false);
      setSearchValue("");
      setFilteredOptions([]);
    }
  };

  const handleOptionClick = (option, name) => {
    setSearchValue(name);
    handleOffDropDown(option);
  };
  return (
    <div className="admin dropdown">
      <div className="search-div">
        <Search>
          <SearchIconWrapper>
            <img src="/images/admin/search-black.png" alt="" />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={lblName}
            inputProps={{ "aria-label": "search" }}
            value={seachValue}
            onChange={handleSearchChange}
            type="search"
          />
        </Search>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={() => handleOptionClick(option, option.firstName)}
              >
                {option.firstName}
              </div>
            ))
          ) : (
            <div className="dropdown-item">No options found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
