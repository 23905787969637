import { Autocomplete, Button, Fab, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import DataContext from '../../../../context/DataContext'

const DocumentsEcosystem = () => {
    const { navigator, handleNextClick } = useContext(DataContext)
    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, watch, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });
    const [checkMethod, setCheckMethod] = useState('save')
    const [readyOnlyValue, setReadyOnlyValue] = useState(false);
    const roleval = localStorage.getItem('role')
    useDidMountEffect(() => {
        handleNextClick(90)
    }, [])
    const onSubmit = (data) => {
        console.log('Form Data:', data);
        navigator('/ecosystem/moreinfo')
    };
    const onError = (event) => {
        console.log('error Data:', event);
    }
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className='signup-data_div'>
                        <div className='forms-title d-flex'>
                            <p>Organization Info {`>`} <span className='path_sapn'>Documents</span></p>
                            <p className='ml-auto'><span className='count_num'>4</span>/5</p>
                        </div>
                        <div className='signup_box_div mt-4'>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <Grid container spacing={1}>


                                    <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                                        <button className='next-btn' type="submit">Next</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default DocumentsEcosystem
