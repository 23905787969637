import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Websector = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives'?'1':'2');

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };
  const handleBackBtn = () => {
      navigator(`/ecosystem-info`);
    };
  return (
    <div>
    <Navbar />
    <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/StartupTnSectors-Web-3.0-Blockchain.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
        <h3 className="banner-title-sector">Web 3.0, Blockchain, VR/AR</h3>
        </div>

        <div className="mt-5">
        <div className="back-blog">
  <div className="allticket-list">
    <Button className="bloggingss-btnss" onClick={handleBackBtn}>
      <FaArrowLeft />
      <span style={{ marginLeft: "5px" }}>Back</span>
    </Button>
  </div>
</div>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Container maxWidth="md">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="fullWidth"
                                textColor="inherit"

                                // TabIndicatorProps={{
                                //     sx: {
                                //         borderBottom: "3px solid #083fb2",
                                //     },
                                // }}
                                TabIndicatorProps={{
                                    sx: {
                                        height: 2, 
                                        backgroundColor: "#083fb2", 
                                        borderBottom: "3px solid #083fb2",  
                                        width: "100px",  
                                    },
                                }}
                            >
                                <Tab
                                    label="Initiatives"
                                    value="1"
                                    className="resource-tab-style"
                                    sx={{
                                        color: value === "1" ? "#0a2a5b" : "#757575",
                                        "&:hover": {
                                            color: "#0a2a5b",
                                        },
                                        "&.Mui-selected": {
                                            color: "#0a2a5b",
                                            border: '1px solid lightgray'
                                        },
                                    }}
                                />
                                <Tab
                                    label="Resources"
                                    value="2"
                                    className="resource-tab-style"
                                    sx={{

                                        "&:hover": {
                                            color: "#0a2a5b",
                                        },
                                        "&.Mui-selected": {
                                            color: "#0a2a5b",
                                            border: '1px solid lightgray'
                                        },

                                    }}
                                />

                            </Tabs>
                        </Box>
                    </Container>
                    <TabPanel value="1" sx={{ padding: "16px" }}>
                    <div className="sector-page-bg"  >
                <h5 style={{color:"#39364e"}}>Initiatives</h5><br/>
                <ul>
                    <li>Tamil Nadu is a frontrunner in leveraging technology to offer cutting-edge solutions to governance problems in India. Some of the noteworthy recent policy actions include Tamil Nadu Cyber Security Policy 2020; Safe and Ethical Artificial Intelligence Policy 2020, ICT Policy of Tamil Nadu 2018 and e-Governance Policy of Tamil Nadu – 2017.</li><br />
                    <li>Another significant project is the Tamil Nadu Data Policy 2022, which intends to offer a framework for data-owning departments in the Tamil Nadu Government to share data with all stakeholders in a transparent manner while also protecting privacy in accordance with existing laws.</li><br />
                    <li>Tamil Nadu Telecom Infrastructure Policy 2022 aims at developing a robust and secure state-of-the-art telecommunication network that would provide seamless coverage by easing the process of application, approval and installation of telecom infrastructure.</li><br />
                    <li>Safe and Ethical Artificial Intelligence Policy 2020 recommends the Six-Dimensional TAM-DEF Framework for evaluation of AI-based systems such as transparency & audit, accountability & legal issues, misuse protection, digital divide & data deficit, ethics and fairness & equity, ensure that the evaluation is aligned to democratic values.</li><br />
                    <li>Tamil Nadu Data Centre Policy which aims to make Tamil Nadu numero uno in global data centre infrastructure.</li><br />
                    <li>Tamil Nadu R&D Policy 2022 aims to transform Tamil Nadu into a knowledge-based economy and increase private sector R&D expenditure by 2030.</li><br />
                    <li>Tamil Nadu has also announced a new industrial policy, targeting INR 10 trillion in investments by 2025.</li><br />
                    </ul> 
                    </div>
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "16px" }}>
                    <div  className="sector-page-bg">
                <h5 style={{color:"#39364e"}}>Resources</h5><br/>
                <p>Tamil Nadu leads all Indian states in ICT-enabled governance, having successfully implemented a number of State Government e-Governance programmes and National e-Governance Plan projects (NeGP).</p><br />
                <ul>
                    <li>Strong industrial base and infrastructure</li><br />
                    <li>World class connectivity</li><br />
                    <li>Abundant supply of power</li><br />
                    <li>World class health care</li><br />
                    <li>Top grade educational institutions</li><br />
                    <li>Outstanding human resources</li><br />
                    <li>Neo-TIDEL Parks in Tier-2 and Tier-3 regions</li><br />
                    <li>Out of 55 SEZs in Tamil Nadu, Chennai has 18 within city boundaries</li><br />
                    <li>SIPCOT is planning to establish 11 new industrial parks in different parts of the State in automobile, chemical engineering and several other sectors.</li><br />
                    <li>Upcoming data centre projects (as reported in media)</li><br />
                    <li>Adani Enterprises Data Centre (phase II)</li><br />
                    <li>L&T Data Centre in Chennaiy</li><br />
                    <li>STT Global Data Centres, Singapore (phase II)</li><br />
                    <li>Tapaz Info Parks, Singapore</li><br />
                    <li>CtrlS Data Centre</li><br />
                    <li>Nxtra by Airtel Data Centre (phase II)</li><br />
                    <li>Yotta and other data centres</li><br />
                    <li>Chennai has a 9 per cent market share in India in the GCC sector that generates a revenue of over USD 35 billion with over 1,430 centres</li><br />
                    <li>Banking, telecom, eCommerce-related large MNCs have their GCCs in Chennai</li><br />
                    <li>India’s First Emerging and DeepTech Innovation Network (iTNT Hub)</li><br />
                    <li>IT campuses consisting of Information Technology buildings as well as infrastructure facilities are established in Tier-II regions, such as Madurai, Tiruchirappalli, Hosur, Salem, Coimbatore and Tirunelveli.</li><br />
                    <li>TN is a major hub for data centres as it has signed MoUs for investments worth Rs 9,000 crore with large companies for setting up of their data centres in the State, especially in Chennai.</li><br />
                    <li>Various MNCs have already set up their GCCs and some of them are looking at Tamil Nadu as a GCC destination. Tamil Nadu’s unique ecosystem such as academia, Startups, service providers, industry bodies and the government, abundance of talent, Tier-I & Tier-II city infrastructure and continuous government support act as growth enablers.</li><br />
                    </ul> 
                    </div>

                    </TabPanel>

                </TabContext>
            </Box>

        </div>
    </div>
    <Footer />
</div>
  )
}

export default Websector
