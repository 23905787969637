import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, Tabs, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useContext } from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DataContext from '../../../../context/DataContext';
import notifyService from '../../../../api/notifySerivce';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import apiService from '../../../../api/apiService';
import { MuiFileInput } from 'mui-file-input';
const MentorSessionModal = ({ open, onClose,sessionList }) => {
  const today = dayjs();
const tomorrow = dayjs().add(0, 'day');
  const { setPageLoading, VisuallyHiddenInput } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, clearErrors,getValues, reset, formState: { errors } } = useForm({ values: formData });

  const onSubmit = (data) => {
    setPageLoading(true);
  data.date = data.date.format('YYYY-MM-DD');
  data.startTime = data.startTime.format('HH:mm');
  data.endTime = data.endTime.format('HH:mm');
  
  apiService('session/create', data, 'post').then((result) => {
    setPageLoading(false);
    if (result && result.data && result.data.responseStatus === "Success") {
      onClose();
      sessionList()
    }
  }).catch((err) => {

  });
  }
  const onError = (data) => {
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  const [startdate, setStartDate] = useState(null)
  const handleStartDateFormat = (newValue) => {
    setStartDate(newValue)
    setValue('date', newValue)
    clearErrors('date');
    // const datePart = newValue.format('YYYY-MM-DD');
  }
  const [startTime, setStartTime] = useState(null)
  const handleStartTimeFormat = (newValue) => {
    setStartTime(newValue)
    setValue('startTime', newValue)
    clearErrors('startTime');
    // const timePart = newValue.format('HH:mm');
  }
  const [endTime, setEndTime] = useState(null)
  const handleEndTimeFormat = (newValue) => {
    setEndTime(newValue)
    setValue('endTime', newValue)
    clearErrors('endTime');
    // const timePart = newValue.format('HH:mm');
  }
  const [logoValue, setLogoValue] = useState(null);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const [imageUpload, setImageUpload] = useState("");
  const MAX_FILE_SIZE_MB = 5;
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, setdata) => {
    if (event !== null) {
      
      if (event.target === undefined) {
        logoselecetdFile = event;
        if (logoselecetdFile) {
          if (logoselecetdFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
            notifyService(
              "danger",
              "File Size Exceeded",
              `Maximum file size allowed is ${MAX_FILE_SIZE_MB} MB`
            );
            return;
          }
        }
      } else {
        logoselecetdFile = event.target.files[0];
      
      }
      if (logoselecetdFile) {
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpg" ||
          img_crt_type === "png" ||
          img_crt_type === "jpeg"
        ) {
          setValue(filekey, "");
          setValue(fileName, "");
          // setName('')
          // setUrl('')
          if (event.target === undefined) {
            setdata(event);
          } else {
            setdata(event.target.files[0]);
          }
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            // setUrl(logourl1)
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            // setName(img_name)
            clearErrors(filekey);
            clearErrors(fileName);
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    } else {
      // setValue(filekey, "")
      // setValue(fileName, "")
      // setName('')
      // setUrl('')
      // inputRef.current.value = '';
    }
  };
  const handleClickOpen = (value, name) => {
    
    setViewImage(value);
    setViewImageName(name);

    // setOpen(true);
  };

  return (
    <>
      <section>
        <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
          <DialogTitle>
            Create Session
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 44,
                top: 30,
                backgroundColor: 'red',
                borderRadius: '50%',
                color: 'white',
                padding: '1px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className='m-0'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box className='bx-sh'>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Title is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Add Title"
                        placeholder="Please Enter Title"
                        fullWidth
                        type="text"
                        {...field}
                        error={Boolean(errors.title && errors.title)}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.title && errors.title.message}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
              <Box className='bx-sh'>
                      <Controller
                        name={`date`}
                        control={control}
                        rules={{ required: 'Start Date is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} fullWidth>
                              <DatePicker label="Start Date" format="DD/MM/YYYY"
                                minDate={tomorrow}
                                value={startdate}
                                {...field}
                               
                                sx={{ width: "100%" }}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue) => handleStartDateFormat(newValue)}
                                renderInput={(params) => <TextField {...params}  error={Boolean(errors.date && errors.date)} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.date &&
                          errors.date.message}
                      </FormHelperText>
                    </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
              <Box className='bx-sh'>
                      <Controller
                        name={`startTime`}
                        control={control}
                        rules={{ required: 'Start Time is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']} fullWidth>
                              <TimePicker label="Start Time " format="HH:mm"
                                value={startTime}
                                {...field}
                               
                                sx={{ width: "100%" }}
                                inputFormat="HH:mm"
                                onChange={(newValue) => handleStartTimeFormat(newValue)}
                                renderInput={(params) => <TextField {...params}  error={Boolean(errors.startTime && errors.startTime)}/>}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.startTime &&
                          errors.startTime.message}
                      </FormHelperText>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="mb-3">
                  <Box className='bx-sh'>
                      <Controller
                        name={`endTime`}
                        control={control}
                        rules={{ required: 'End Time is required' }}
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']} fullWidth>
                              <TimePicker label="End Time " format="HH:mm"
                                value={endTime}
                                {...field}
                               
                                sx={{ width: "100%" }}
                                inputFormat="HH:mm"
                                onChange={(newValue) => handleEndTimeFormat(newValue)}
                                renderInput={(params) => <TextField {...params}  error={Boolean(errors.endTime && errors.endTime)} />}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.endTime &&
                          errors.endTime.message}
                      </FormHelperText>
                    </Box>
                  </Grid>
                 
              <Grid item xs={12} sm={6}>
                <Box className='bx-sh'>
                  <Controller
                    name="sector"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Industry/Sector is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Industry/Sector"
                        placeholder="Please Enter Industry/Sector"
                        fullWidth
                        type="text"
                        {...field}
                        error={Boolean(errors.sector && errors.sector)}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.sector && errors.sector.message}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className='bx-sh'>
                  <Controller
                    name="specialization"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Function/Specialization is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Function/Specialization"
                        placeholder="Please Enter Function/Specialization"
                        fullWidth
                        type="text"
                        {...field}
                        error={Boolean(errors.specialization && errors.specialization)}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.specialization && errors.specialization.message}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className='bx-sh'>
                  <Controller
                    name="startupStage"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Stage of Startup is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Stage of Startup"
                        placeholder="Please Enter Stage of Startup"
                        fullWidth
                        type="text"
                        {...field}
                        error={Boolean(errors.startupStage && errors.startupStage)}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.startupStage && errors.startupStage.message}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className='bx-sh'>
                  {/* <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">Mode of Engagement</FormLabel>
                  <RadioGroup row aria-label="mode-of-engagement" name="mode-of-engagement">
                    <FormControlLabel value="online" control={<Radio />} label="Online" />
                    <FormControlLabel value="offline" control={<Radio />} label="Offline" />
                  </RadioGroup>
                </FormControl> */}
                  <FormControl component="fieldset" fullWidth error={!!errors.modeOfEngagement}>
                    <FormLabel component="legend">Mode of Engagement</FormLabel>
                    <Controller
                      name="mode"
                      control={control}
                      defaultValue=""
                      rules={{ required: 'Please select a mode of engagement' }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          {...field}
                          aria-label="mode-of-engagement"
                        >
                          <FormControlLabel value="online" control={<Radio />} label="Online" />
                          <FormControlLabel value="offline" control={<Radio />} label="Offline" />
                        </RadioGroup>
                      )}
                    />
                    {errors.mode && (
                      <FormHelperText className='text-danger'>{errors.mode.message}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
               <Grid  item xs={12} sm={6} className="mb-3">
               <Box className='bx-sh'>
                  {imageUpload ? (
                    <>
              <label >Upload Section Logo<span className="text-danger ml-1">*</span></label>
                    <div className="row">
                      <Grid item lg={7} md={7} sm={12} xs={12} className="mb-3">
                        
                        <Controller
                          name="logo"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Session Logo is required" }}
                          render={({ field }) => (
                            <Button
                              component="label"
                              variant="contained"
                              
                              {...field}
                              fullWidth
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "logo",
                                  "logoName",
                                  setLogoValue
                                )
                              }
                              startIcon={<CloudUploadIcon />}
                              href="#file-upload"
                              // disabled={readyOnlyValue}
                            >
                              Change
                              <VisuallyHiddenInput type="file" />
                            </Button>
                          )}
                        />
                        
                      </Grid>
                      <Grid item lg={5} md={5} sm={12} xs={12} className="mb-3 logo-btn-margin">
                        <Button
                          className="view_doc_btn_document c-pointer"
                         
                         size="medium"
                         variant="outlined"
                          onClick={() =>
                            handleClickOpen(
                              getValues("logo"),
                              getValues("companyLogoURL")
                            )
                          }
                        >
                          Preview
                        </Button>
                      </Grid>
                      <div>
                          <span className="fn-12   mt-1 ">
                            Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)
                            
                          </span>
                        </div>
                    </div>
                    </>
                  ) : (
                    <div className="form-group mt-2">
                      <Controller
                        name="logo"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Session Logo is required" }}
                        render={({ field }) => (
                          <MuiFileInput
                            {...field}
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                "logo",
                                "logoName",
                                setLogoValue
                              )
                            }
                            value={logoValue}
                            variant="outlined"
                            fullWidth
                            label="Upload Section Logo *"
                            placeholder="Upload Section Logo"
                            error={Boolean(errors.logo && errors.logo)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.logo && errors.logo.message}
                      </FormHelperText>
                      <div>
                      
                        <span className="font-sm">
                          Maximum 5 mb allowed (Accepted Format: jpeg, jpg, png){" "}
                          <span className="text-danger">*</span>
                        </span>
                      </div>
                    </div>
                  )}
                    </Box>
                </Grid>
            </Grid>
          </DialogContent>
          <DialogActions >
            <div className='text-center mb-3 w-100'>
              <Button onClick={onClose} className="discard-btn">Close</Button>
              <Button
                type="submit" className="publish-button"
              >
                <span>Save</span>
              </Button>
            </div>
          </DialogActions>
        </form>
      </section>
    </>
  )
}

export default MentorSessionModal