import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Aerospace = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/Aerospace-Defence-SpaceTech-banner.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">
            Aerospace, Defence & SpaceTech
          </h3>
        </div>
        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h4 style={{ color: "#39364e" }}>Union Government</h4>
                  <br />
                  <p>
                    The Union Government of India has implemented several
                    significant initiatives to promote and strengthen the
                    Aerospace and Defence sector in the country. These
                    initiatives are aimed at enhancing self-reliance in defence
                    production, encouraging research and development and
                    fostering collaboration between domestic and international
                    entities.
                  </p>
                  <p>
                    Here are some key Union Government initiatives related to
                    the Aerospace and Defence sector:
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Make in India</h5>
                  <br />
                  <p>
                    The “Make in India” initiative includes a specific focus on
                    defence production. It encourages domestic manufacturing,
                    promotes private sector participation and aims to reduce
                    India’s dependence on defence imports.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Defence Procurement Policy (DPP)
                  </h5>
                  <br />
                  <p>
                    The Union Government periodically revises the DPP to
                    streamline and simplify the defence procurement process,
                    making it more transparent and efficient for both domestic
                    and international defence companies.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Defence Production and Export Promotion Policy (DPEPP)
                  </h5>
                  <br />
                  <p>
                    This policy aims to promote defence exports, incentivize
                    domestic defence production and facilitate collaboration
                    between public and private sectors. It sets ambitious
                    targets for defence production and export.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Strategic Partnership (SP) Model
                  </h5>
                  <br />
                  <p>
                    The SP model identifies specific segments of the defence
                    industry (aircraft, submarines, helicopters, armored
                    vehicles and ammunition) where private sector companies can
                    collaborate with foreign Original Equipment Manufacturers
                    (OEMs) for technology transfer and indigenous production.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Investment Promotion</h5>
                  <br />
                  <p>
                    The government has raised the cap on foreign direct
                    investment (FDI) in the defence sector, allowing for greater
                    foreign participation and investment in Indian defence
                    companies.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Defence Industrial Corridors
                  </h5>
                  <br />
                  <p>
                    India has established two defence industrial corridors—one
                    in Uttar Pradesh (Bundelkhand Defence Industrial Corridor)
                    and one in Tamil Nadu (Southern Defence Corridor). These
                    corridors aim to attract defence-manufacturing investments,
                    create jobs and promote defence research and development.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Technology Development Fund (TDF):
                  </h5>
                  <br />
                  <p>
                    TDF supports research and development efforts in the private
                    sector by providing financial assistance for projects
                    related to critical defence technologies.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Defence Research and Development Organisation (DRDO) Support
                  </h5>
                  <br />
                  <p>
                    The Union Government continues to fund and support DRDO in
                    its mission to develop cutting-edge defence technologies,
                    including missiles, aircraft and electronic warfare systems.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Export Promotion</h5>
                  <br />
                  <p>
                    The government provides various incentives and support to
                    promote defence exports, including participation in
                    international defence exhibitions and the creation of a
                    dedicated defence export promotion agency (DEPA).
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Defence Procurement Procedure (DPP) Offset Policy
                  </h5>
                  <br />
                  <p>
                    The offset policy requires foreign defence suppliers to
                    invest a certain percentage of the contract value in India’s
                    defence manufacturing and technology sector, leading to
                    technology transfer and local production.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Indian Defence Technology Startups
                  </h5>
                  <br />
                  <p>
                    Initiatives like the Innovations for Defence Excellence
                    (iDEX) programme aim to foster innovation by collaborating
                    with Startups and small and medium enterprises (SMEs) in
                    developing defence technologies.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Skill Development</h5>
                  <br />
                  <p>
                    The government has launched skill development programmes to
                    train the workforce in advanced Aerospace and Defence
                    technologies, ensuring a skilled talent pool for the sector.
                  </p>
                  <p>
                    These initiatives of the Union Government collectively
                    strive to boost indigenous defence production, enhance
                    defence capabilities, encourage foreign investments and
                    promote India as a major player in the global Aerospace and
                    Defence industry. These measures align with India’s goal of
                    achieving self-reliance and strengthening its national
                    security infrastructure.
                  </p>
                  <br />
                  <h4 style={{ color: "#39364e" }}>State Government</h4>
                  <br />
                  <p>
                    The Tamil Nadu Government has been actively promoting the
                    Aerospace and Defence sector through various initiatives
                    aimed at attracting investments, fostering innovation and
                    creating a conducive business environment.
                  </p>
                  <br />
                  <p>
                    Here are some key government initiatives related to the
                    Aerospace and Defence sector in Tamil Nadu:
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Southern Defence Corridor
                  </h5>
                  <br />
                  <p>
                    Tamil Nadu is a vital component of the Southern Defence
                    Corridor, a government-led initiative that aims to promote
                    defence manufacturing and related industries in the southern
                    region of India. This corridor encompasses Tamil Nadu,
                    Andhra Pradesh and Telangana, with focus on creating a
                    defence-manufacturing ecosystem, attracting investments and
                    generating employment opportunities.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Aerospace and Defence Policy
                  </h5>
                  <br />
                  <p>
                    The Tamil Nadu Aerospace and Defence Industrial Policy,
                    introduced by the State Government, provides a comprehensive
                    framework for promoting the Aerospace and Defence sector. It
                    offers incentives, concessions and support measures to
                    encourage investment in research and development,
                    manufacturing and export of Aerospace and Defence products.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Aerospace Parks and SEZs</h5>
                  <br />
                  <p>
                    Tamil Nadu has established dedicated aerospace parks and
                    special economic zones (SEZs) to facilitate the growth of
                    the Aerospace and Defence industry. These zones offer
                    excellent infrastructure, tax benefits and a streamlined
                    approval process for companies operating in them.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Skill Development Initiatives
                  </h5>
                  <br />
                  <p>
                    The State Government has launched several skill development
                    programmes in collaboration with educational institutions
                    and industry partners. These initiatives aim to train and
                    upskill the workforce in Aerospace and Defence-related
                    technologies, ensuring a steady supply of skilled
                    professionals.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Investor-Friendly Policies
                  </h5>
                  <br />
                  <p>
                    Tamil Nadu offers a range of incentives, including tax
                    concessions and subsidies, to attract Aerospace and Defence
                    companies. These policies create a favorable business
                    environment and reduce the cost of setting up and operating
                    an industry in the State.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Ease of Doing Business</h5>
                  <br />
                  <p>
                    The State Government has implemented measures to simplify
                    and expedite various administrative processes, such as land
                    acquisition, permits and licences, making it easier for
                    companies to do business in Tamil Nadu.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Research and Development Collaboration
                  </h5>
                  <br />
                  <p>
                    The government encourages collaboration among defence
                    companies, research institutions and educational
                    establishments. It provides support for joint research and
                    development projects, fostering innovation and technology
                    transfer.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Infrastructure Development
                  </h5>
                  <br />
                  <p>
                    Tamil Nadu has made significant investments in
                    infrastructure development, including transportation,
                    connectivity and utilities, to support the Aerospace and
                    Defence industry’s growth.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    International Collaboration
                  </h5>
                  <br />
                  <p>
                    The State Government actively seeks international
                    collaborations and partnerships in the Aerospace and Defence
                    sector to bring in global expertise and investments.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Export Promotion</h5>
                  <br />
                  <p>
                    Initiatives to promote the export of Aerospace and Defence
                    products and technologies are in place. These include
                    support for participation in international trade shows and
                    exhibitions.
                  </p>
                  <p>
                    These government initiatives collectively create an
                    ecosystem that encourages Aerospace and Defence companies to
                    invest, innovate and operate in Tamil Nadu. By providing
                    incentives, infrastructure and a skilled workforce, the
                    government aims to position the State as a prominent player
                    in India’s Aerospace and Defence industry.
                  </p>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <p>
                    The Aerospace and Defence industry relies on a wide range of
                    resources to operate effectively and stay at the forefront
                    of technology and innovation. These resources encompass
                    everything, from personnel and funding to materials,
                    research facilities and regulatory frameworks.
                  </p>
                  <p>
                    Here is a breakdown of some key resources available to the
                    Aerospace and Defence sector:
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Talent and Workforce</h5>
                  <br />
                  <ul>
                    <li>
                      Skilled engineers, scientists, researchers and technicians
                      are essential resources for designing, manufacturing and
                      maintaining Aerospace and Defence systems.
                    </li>
                    <br />
                    <li>
                      Universities and vocational institutions provide education
                      and training tailored to Aerospace and Defence needs.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Funding and Investment</h5>
                  <br />
                  <ul>
                    <li>
                      Tamil Nadu saw ₹4,900 crore investment in the Aerospace
                      and Defence sector in the last two years and the committed
                      investment is ₹15,000 crore.
                    </li>
                    <br />
                    <li>
                      Government funding, grants and contracts play a crucial
                      role in financing research, development and procurement in
                      the Aerospace and Defence industry.
                    </li>
                    <br />
                    <li>
                      Private sector investment, venture capital and
                      public-private partnerships also contribute to financial
                      resources.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Funding and Investment</h5>
                  <br />
                  <ul>
                    <li>
                      Tamil Nadu saw ₹4,900 crore investment in the Aerospace
                      and Defence sector in the last two years and the committed
                      investment is ₹15,000 crore.
                    </li>
                    <br />
                    <li>
                      Government funding, grants and contracts play a crucial
                      role in financing research, development and procurement in
                      the Aerospace and Defence industry.
                    </li>
                    <br />
                    <li>
                      Private sector investment, venture capital and
                      public-private partnerships also contribute to financial
                      resources.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Materials and Manufacturing
                  </h5>
                  <br />
                  <ul>
                    <li>
                      Advanced materials, such as composites, alloys and
                      specialized coatings, are fundamental to building
                      lightweight yet robust Aerospace and Defence systems.
                    </li>
                    <br />
                    <li>
                      Manufacturing facilities, including aerospace-grade
                      production plants and 3D printing capabilities, are
                      essential resources.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Research and Development Facilities
                  </h5>
                  <br />
                  <ul>
                    <li>
                      Aerospace and Defence companies often maintain their own
                      research and development centers for innovation and
                      testing.
                    </li>
                    <br />
                    <li>
                      Government research agencies and laboratories provide
                      access to cutting-edge facilities and expertise.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Supply Chain</h5>
                  <br />
                  <ul>
                    <li>
                      A robust supply chain network ensures the timely delivery
                      of components, parts and materials.
                    </li>
                    <br />
                    <li>
                      Suppliers and subcontractors are vital resources for
                      meeting production demands.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Regulatory Frameworks</h5>
                  <br />
                  <ul>
                    <li>
                      Government agencies, such as the Federal Aviation
                      Administration (FAA) in the United States, establish and
                      enforce safety and certification standards.
                    </li>
                    <br />
                    <li>
                      International agreements and organisations, like ICAO
                      (International Civil Aviation Organisation), help to
                      standardise regulations globally.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Testing and Certification
                  </h5>
                  <br />
                  <ul>
                    <li>
                      Facilities for testing Aerospace and Defence systems are
                      essential for ensuring safety and reliability.
                    </li>
                    <br />
                    <li>
                      Certification authorities, like the FAA or EASA (European
                      Union Aviation Safety Agency), provide the necessary
                      approvals.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Information Technology and Data
                  </h5>
                  <br />
                  <ul>
                    <li>
                      High-performance computing and data analytics are critical
                      for simulations, modeling and data-driven decision-making.
                    </li>
                    <br />
                    <li>
                      Cybersecurity measures protect sensitive data and
                      intellectual property.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    International Collaboration
                  </h5>
                  <br />
                  <ul>
                    <li>
                      Collaboration with other countries and international
                      organisations facilitates joint research, development and
                      production efforts.
                    </li>
                    <br />
                    <li>
                      Shared resources and expertise can lead to cost savings
                      and innovation.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Infrastructure</h5>
                  <br />
                  <ul>
                    <li>
                      Airports, runways and air traffic control systems are
                      essential for aerospace operations.
                    </li>
                    <br />
                    <li>
                      Military bases and facilities are required for defense
                      operations and testing.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Training and Education</h5>
                  <br />
                  <ul>
                    <li>
                      Training centres and educational institutions provide
                      ongoing education and skill development for industry
                      professionals.
                    </li>
                    <br />
                    <li>
                      Simulators and training aircraft are used for pilot and
                      crew training.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>
                    Testing Ranges and Facilities
                  </h5>
                  <br />
                  <ul>
                    <li>
                      Aerospace and Defence companies often use specialised
                      testing ranges and facilities for flight testing and
                      experiments.
                    </li>
                    <br />
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Maintenance and Support</h5>
                  <br />
                  <ul>
                    <li>
                      Maintenance, repair, and overhaul (MRO) facilities ensure
                      the long-term operational readiness of Aerospace and
                      Defence systems.
                    </li>
                    <br />
                    <li>
                      Simulators and training aircraft are used for pilot and
                      crew training.
                    </li>
                    <br />
                  </ul>
                  <p>
                    These resources collectively support the Aerospace and
                    Defence industry’s ability to design, produce and maintain
                    cutting-edge technologies and systems for both civil and
                    military purposes. Collaboration among governments, private
                    companies and research institutions is often key to
                    advancing the sector’s capabilities.
                  </p>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aerospace;
