import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DataContext from '../../../context/DataContext';
import { Controller, useForm } from 'react-hook-form';
import { FormHelperText, TextField } from '@mui/material';
import apiService from '../../../api/apiService';
import useDidMountEffect from '../../../hooks/useDidMountEffect';


const MaskDialog = () => {

    const { navigator,maskshow, setMaskshow, handlemaskClickOpen, handlemaskClose, isWhitespace,userProfileId,showViewbtn,getApi,setShowViewbtn } = useContext(DataContext)
    const { handleSubmit, control, formState: { errors },reset } = useForm({});
    let jwttoken = localStorage.getItem("jwttoken");
    let id = localStorage.getItem("id");

    useDidMountEffect(() => {
       
    }, [showViewbtn]);

    const onSubmit = (data) => {
        var req={
            userId : id,
            purpose : data.purpose
        }
            apiService('user/profileview/purpose/save',req,"post")
            .then((result) => {
                if (result.data.responseStatus === "Success") {
                    getApi();
                    setShowViewbtn(0)
                }
            }).catch((err) => {
                
            });
        handlemaskClose()
        reset()
        debugger
    }

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={maskshow}
                onClose={handlemaskClose}
            > <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>Reason to View</DialogTitle>
                    <DialogContent>
                        <div>
                            <div className='my-3'>
                                <Controller
                                    name="purpose"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'purpose is required', validate: {
                                            noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                                        },

                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            id="outlined-multiline-static"
                                            label="Enter your purpose *"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            inputProps={
                                                { maxLength: 500 }
                                            }
                                            defaultValue=""
                                            error={Boolean(errors.purpose && errors.purpose)}
                                        />
                                    )}
                                />
                                <FormHelperText className="text-danger">
                                    {errors.purpose && errors.purpose.message}
                                </FormHelperText>
                            </div>
                        </div>
                    </DialogContent>
                    <div className='text-center pb-3'>
                        <Button
                            variant="outlined"
                            className="edit-btn-profile"
                            sx={{
                                textTransform: "none",
                                border: "1px solid",
                                borderColor: "#020671",
                                color: "#020671",
                                padding: "6px 20px",
                            }}
                            type="submit"
                        >
                            Submit
                        </Button>
                        </div>
                </form>
            </Dialog>
        </div>
    )
}

export default MaskDialog
