import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const BeyondTn = ({name}) => {
  const [expanded, setExpanded] = useState("panel1");
  const [screenSize, setScreenSize] = useState(4);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [activeTab, setActiveTab] = useState("0");

  const [value, setValue] = useState("0");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    size();
  }, []);

  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(4);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(4);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(4);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(4);
    } else {
      setScreenSize(1);
    }
  };

  const handlenow = () => {
    navigate('/login')
  }
const viewMore = () => {
  navigate('/initiative/marketaccess/beyondtn/gcc')
}
  return (
    <div className="smartCard-page">
      {/* <Navbar /> */}
      <div className="radial-back-layer">
        <div className="round-layers"></div>
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="banner-text">
                <div className="sm-banner-chip-text1">
                  <span>{`${name}  >  BeyondTN  `}</span>
                  <h1 className="banner-text-gradient">Beyond TN </h1>
                  <h6 className="fund-title">
                    Bridging Tamil Nadu Startups with Global opportunities
                  </h6>
                </div>
                <div className="banner-content-actions">
                  <button className="banner-viewbtn">Get in Touch</button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="smart-card-info1">
        <Container>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info1"
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="left-side-smartcardPage1">
                <h4>What is BeyondTN ?</h4>
                <p className="para-all">
                  To facilitate a soft landing, and to attract investments from
                  Global Investors for Tamil Nadu Startups, ‘Global Coordination
                  Centres’ under Beyond TN will be established strategically
                  across the Globe in a phased manner. These offices will be the
                  facilitating and liaison offices for homegrown Startups. These
                  Global Coordination Centres will also operate as a gateway to
                  NRIs and NRI Tamils settled overseas who aspire to start a
                  venture in Tamil Nadu.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="smart-crd-img">
                <img
                  className=" img-fluid"
                  src={"/images/tamilnadu_logo_high.svg"}
                  alt="startupLogo"
                />
              </div>
            </Grid>
          </Grid>
         
        </Container>
        <Container>
        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            className="tanseed-info1"
          >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="smart-crd-img">
              <div className="dubai-imgs">
            <img src="/images/dubai-img.png" className="img-fluid" alt="thumbnail-image" />
            </div>
           </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="left-side-smartcardPage1">
                        <h4 className="chap-dubs">Dubai Desk</h4>
                        <p className="dub-globs mt-4">The Global Coordination Centre is a strategic initiative developed with Startup Middle East to provide comprehensive support to the Startups of Tamil Nadu interested in exploring and penetrating the market of the Middle East region. Located in Dubai, UAE, the Global Coordination Centre is an intrinsic base and pivotal to offer the bouquet of services that can enable market-entry businesses in the growth phase of Tamil Nadu.</p>
                        <div className="Additional-Benefits-div mt-4">
                        <button onClick={viewMore}>View More</button>
                        </div>
                    </div>
            </Grid>
          
            </Grid>
                  
                </Container>
        <div className="join-us-now-sec1">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="join-us-now-box">
                  <div className="join-us-now-content">
                    <h4>Let’s Get Started</h4>
                    <p>
                      Beyond TN 
                      </p>
                      <p>
                      Bridging Tamil Nadu Startups with Global
                      opportunities
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      className="join-now-btn"
                      onClick={handlenow}
                    >
                      Join us Now
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default BeyondTn;
