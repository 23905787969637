import React, { useContext, useState } from "react";

import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { data } from "jquery";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const Map = () => {
  const [selectedDistrict, setSelectedDistrict] = useState("");

  return (
    <>
      {/* <div className="map-div-bg">
        <img
          src="/images/map/Thiruvallur.png"
          alt=""
          className="img-fluid Thiruvallur"
        />
        <img
          src="/images/map/Chennai.png"
          alt=""
          className="img-fluid Chennai c-pointer"
        />
        <img
          src="/images/map/Chengalpattu.png"
          alt=""
          className="img-fluid Chengalpattu"
        />
        <img
          src="/images/map/Kancheepuram.png"
          alt=""
          className="img-fluid Kancheepuram"
        />
        <img
          src="/images/map/Viluppuram.png"
          alt=""
          className="img-fluid Viluppuram"
        />
        <img
          src="/images/map/Mayiladuthurai.png"
          alt=""
          className="img-fluid Mayiladuthurai"
        />
        <img
          src="/images/map/Ariyalur.png"
          alt=""
          className="img-fluid Ariyalur"
        />
        <img
          src="/images/map/Perambalur.png"
          alt=""
          className="img-fluid Perambalur"
        />
        <img
          src="/images/map/Ranipet.png"
          alt=""
          className="img-fluid Ranipet"
        />
        <img
          src="/images/map/Vellore.png"
          alt=""
          className="img-fluid Vellore"
        />
        <img
          src="/images/map/Tiruvannamalai.png"
          alt=""
          className="img-fluid Tiruvannamalai"
        />
        <img
          src="/images/map/Tirupathur.png"
          alt=""
          className="img-fluid Tirupathur"
        />
        <img
          src="/images/map/Krishnagiri.png"
          alt=""
          className="img-fluid Krishnagiri c-pointer"
        />
        <img
          src="/images/map/Dharmapuri.png"
          alt=""
          className="img-fluid Dharmapuri"
        />
        <img
          src="/images/map/Kallakurichi.png"
          alt=""
          className="img-fluid Kallakurichi"
        />
        <img
          src="/images/map/Cuddalore.png"
          alt=""
          className="img-fluid Cuddalore c-pointer"
        />
        <img
          src="/images/map/Salem.png"
          alt=""
          className="img-fluid Salem c-pointer"
        />
        <img
          src="/images/map/Erode.png"
          alt=""
          className="img-fluid Erode c-pointer"
        />
        <img
          src="/images/map/Nilgiris.png"
          alt=""
          className="img-fluid Nilgiris"
        />
        <img
          src="/images/map/Thanjavur.png"
          alt=""
          className="img-fluid Thanjavur c-pointer"
        />
        <img
          src="/images/map/Tiruvarur.png"
          alt=""
          className="img-fluid Tiruvarur"
        />
        <img
          src="/images/map/Nagapattinam.png"
          alt=""
          className="img-fluid Nagapattinam"
        />
        <img
          src="/images/map/Tiruchirappalli.png"
          alt=""
          className="img-fluid Tiruchirappalli c-pointer"
        />
        <img
          src="/images/map/Namakkal.png"
          alt=""
          className="img-fluid Namakkal"
        />
        <img src="/images/map/Karur.png" alt="" className="img-fluid Karur" />
        <img
          src="/images/map/Tiruppur.png"
          alt=""
          className="img-fluid Tiruppur"
        />
        <img
          src="/images/map/Coimbatore.png"
          alt=""
          className="img-fluid Coimbatore c-pointer"
        />
        <img
          src="/images/map/Dindigul.png"
          alt=""
          className="img-fluid Dindigul"
        />
        <img
          src="/images/map/Pudukkottai.png"
          alt=""
          className="img-fluid Pudukkottai"
        />
        <img
          src="/images/map/Madurai.png"
          alt=""
          className="img-fluid Madurai c-pointer"
        />
        <img src="/images/map/Theni.png" alt="" className="img-fluid Theni" />
        <img
          src="/images/map/Sivagangai.png"
          alt=""
          className="img-fluid Sivagangai"
        />
        <img
          src="/images/map/Ramanathapuram.png"
          alt=""
          className="img-fluid Ramanathapuram"
        />
        <img
          src="/images/map/Virudhunagar.png"
          alt=""
          className="img-fluid Virudhunagar"
        />
        <img
          src="/images/map/Tenkasi.png"
          alt=""
          className="img-fluid Tenkasi"
        />
        <img
          src="/images/map/Thoothukudi.png"
          alt=""
          className="img-fluid Thoothukudi"
        />
        <img
          src="/images/map/Tirunelveli.png"
          alt=""
          className="img-fluid Tirunelveli c-pointer"
        />
        <img
          src="/images/map/Kanyakumari.png"
          alt=""
          className="img-fluid Kanyakumari"
        />

        <img
          src="/images/map/Chennai_hub.png"
          alt=""
          className="img-fluid Chennai_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Chennai Metro Hub" ? "block" : "none",
          }}
        />
        <img
          src="/images/map/Hosur_hub.png"
          alt=""
          className="img-fluid Hosur_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Hosur Regional Hub" ? "block" : "none",
          }}
        />
        <img
          src="/images/map/Cuddalore_hub.png"
          alt=""
          className="img-fluid Cuddalore_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Cuddalore Regional Hub" ? "block" : "none",
          }}
        />
        <img
          src="/images/map/Salem_hub.png"
          alt=""
          className="img-fluid Salem_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Salem Regional Hub" ? "block" : "none",
          }}
        />
        <img
          src="/images/map/Erode_hub.png"
          alt=""
          className="img-fluid Erode_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Erode Regional Hub" ? "block" : "none",
          }}
        />
        <img
          src="/images/map/Coimbatore_hub.png"
          alt=""
          className="img-fluid Coimbatore_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Coimbatore Satelite Office"
                ? "block"
                : "none",
          }}
        />
        <img
          src="/images/map/Tiruchirappalli_hub.png"
          alt=""
          className="img-fluid Tiruchirappalli_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Tiruchirappalli Satellite Hub"
                ? "block"
                : "none",
          }}
        />
        <img
          src="/images/map/Thanjavur_hub.png"
          alt=""
          className="img-fluid Thanjavur_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Thanjavur Regional Hub" ? "block" : "none",
          }}
        />
        <img
          src="/images/map/Madurai_hub.png"
          alt=""
          className="img-fluid Madurai_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Madurai Regional Hub" ? "block" : "none",
          }}
        />
        <img
          src="/images/map/Tirunelveli_hub.png"
          alt=""
          className="img-fluid Tirunelveli_hub c-pointer"
          style={{
            display:
              selectedDistrict === "Thirunelveli Regional Hub"
                ? "block"
                : "none",
          }}
        />
      </div> */}
  
     
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Container>
              <div className='map-div'>
                <div className="map-div-bg">
                 
                    <img
                      src="/images/map/map-bg.png"
                      alt=""
                      className="img-fluid map-bg"
                    />
                    <img
                      src="/images/map/Thiruvallur.png"
                      alt=""
                      className="img-fluid Thiruvallur"
                    />
                    <img
                      src="/images/map/Chennai.png"
                      alt=""
                      className="img-fluid Chennai c-pointer"
                    />
                    <img
                      src="/images/map/Chengalpattu.png"
                      alt=""
                      className="img-fluid Chengalpattu"
                    />
                    <img
                      src="/images/map/Kancheepuram.png"
                      alt=""
                      className="img-fluid Kancheepuram"
                    />
                    <img
                      src="/images/map/Viluppuram.png"
                      alt=""
                      className="img-fluid Viluppuram"
                    />
                    <img
                      src="/images/map/Mayiladuthurai.png"
                      alt=""
                      className="img-fluid Mayiladuthurai"
                    />
                    <img
                      src="/images/map/Ariyalur.png"
                      alt=""
                      className="img-fluid Ariyalur"
                    />
                    <img
                      src="/images/map/Perambalur.png"
                      alt=""
                      className="img-fluid Perambalur"
                    />
                    <img
                      src="/images/map/Ranipet.png"
                      alt=""
                      className="img-fluid Ranipet"
                    />
                    <img
                      src="/images/map/Vellore.png"
                      alt=""
                      className="img-fluid Vellore"
                    />
                    <img
                      src="/images/map/Tiruvannamalai.png"
                      alt=""
                      className="img-fluid Tiruvannamalai"
                    />
                    <img
                      src="/images/map/Tirupathur.png"
                      alt=""
                      className="img-fluid Tirupathur"
                    />
                    <img
                      src="/images/map/Krishnagiri.png"
                      alt=""
                      className="img-fluid Krishnagiri c-pointer"
                    />
                    <img
                      src="/images/map/Dharmapuri.png"
                      alt=""
                      className="img-fluid Dharmapuri"
                    />
                    <img
                      src="/images/map/Kallakurichi.png"
                      alt=""
                      className="img-fluid Kallakurichi"
                    />
                    <img
                      src="/images/map/Cuddalore.png"
                      alt=""
                      className="img-fluid Cuddalore c-pointer"
                    />
                    <img
                      src="/images/map/Salem.png"
                      alt=""
                      className="img-fluid Salem c-pointer"
                    />
                    <img
                      src="/images/map/Erode.png"
                      alt=""
                      className="img-fluid Erode c-pointer"
                    />
                    <img
                      src="/images/map/Nilgiris.png"
                      alt=""
                      className="img-fluid Nilgiris"
                    />
                    <img
                      src="/images/map/Thanjavur.png"
                      alt=""
                      className="img-fluid Thanjavur c-pointer"
                    />
                    <img
                      src="/images/map/Tiruvarur.png"
                      alt=""
                      className="img-fluid Tiruvarur"
                    />
                    <img
                      src="/images/map/Nagapattinam.png"
                      alt=""
                      className="img-fluid Nagapattinam"
                    />
                    <img
                      src="/images/map/Tiruchirappalli.png"
                      alt=""
                      className="img-fluid Tiruchirappalli c-pointer"
                    />

                    <img
                      src="/images/map/Namakkal.png"
                      alt=""
                      className="img-fluid Namakkal"
                    />
                    <img
                      src="/images/map/Karur.png"
                      alt=""
                      className="img-fluid Karur"
                    />
                    <img
                      src="/images/map/Tiruppur.png"
                      alt=""
                      className="img-fluid Tiruppur"
                    />
                    <img
                      src="/images/map/Coimbatore.png"
                      alt=""
                      className="img-fluid Coimbatore c-pointer"
                    />
                    <img
                      src="/images/map/Dindigul.png"
                      alt=""
                      className="img-fluid Dindigul"
                    />
                    <img
                      src="/images/map/Pudukkottai.png"
                      alt=""
                      className="img-fluid Pudukkottai"
                    />
                    <img
                      src="/images/map/Madurai.png"
                      alt=""
                      className="img-fluid Madurai c-pointer"
                    />
                    <img
                      src="/images/map/Theni.png"
                      alt=""
                      className="img-fluid Theni"
                    />
                    <img
                      src="/images/map/Sivagangai.png"
                      alt=""
                      className="img-fluid Sivagangai"
                    />
                    <img
                      src="/images/map/Ramanathapuram.png"
                      alt=""
                      className="img-fluid Ramanathapuram"
                    />
                    <img
                      src="/images/map/Virudhunagar.png"
                      alt=""
                      className="img-fluid Virudhunagar"
                    />
                    <img
                      src="/images/map/Tenkasi.png"
                      alt=""
                      className="img-fluid Tenkasi"
                    />
                    <img
                      src="/images/map/Thoothukudi.png"
                      alt=""
                      className="img-fluid Thoothukudi"
                    />
                    <img
                      src="/images/map/Tirunelveli.png"
                      alt=""
                      className="img-fluid Tirunelveli c-pointer"
                    />
                    <img
                      src="/images/map/Kanyakumari.png"
                      alt=""
                      className="img-fluid Kanyakumari"
                    />

                    <img
                      src="/images/map/Chennai_hub.png"
                      alt=""
                      className="img-fluid Chennai_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Chennai Metro Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Hosur_hub.png"
                      alt=""
                      className="img-fluid Hosur_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Hosur Regional Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Cuddalore_hub.png"
                      alt=""
                      className="img-fluid Cuddalore_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Cuddalore Regional Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Salem_hub.png"
                      alt=""
                      className="img-fluid Salem_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Salem Regional Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Erode_hub.png"
                      alt=""
                      className="img-fluid Erode_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Erode Regional Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Coimbatore_hub.png"
                      alt=""
                      className="img-fluid Coimbatore_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Coimbatore Satelite Office"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Tiruchirappalli_hub.png"
                      alt=""
                      className="img-fluid Tiruchirappalli_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Tiruchirappalli Satellite Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Thanjavur_hub.png"
                      alt=""
                      className="img-fluid Thanjavur_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Thanjavur Regional Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Madurai_hub.png"
                      alt=""
                      className="img-fluid Madurai_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Madurai Regional Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                    <img
                      src="/images/map/Tirunelveli_hub.png"
                      alt=""
                      className="img-fluid Tirunelveli_hub c-pointer"
                      style={{
                        display:
                          selectedDistrict === "Thirunelveli Regional Hub"
                            ? "block"
                            : "none",
                      }}
                    />
                 
                </div>
                </div>
                
              </Container>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
              <div className="incubator-card">
                <div className="map-rec-text">
                  <img
                    src="/images/startup-white.png "
                    alt="StartupTN Logo"
                    className="map-logo-right"
                  />
                  <div className="tex-mp">
                    <p>Total No. Of. Incubators</p>
                    <h1>124</h1>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
      
    
    </>
  );
};

export default Map;
