import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import DataContext from '../../../context/DataContext'

const Profilelayout = () => {
  const { handleHomePage } = useContext(DataContext)
  return (
    <div>
      <div className='row m-0'>
        <div className='col-lg-3 col-md-3 p-0'>
          <div className='left-box'>
            <div className='logo-img'>
            {/* nav-logo-profile1 */}
            {/* <img src="/images/tamilnadu_logo_white_high.svg" alt="" className="img-fluid startup_img1" onClick={handleHomePage}/> */}
              <img src='/images/oneTn/new_logo.png' className='img-fluid startup_img p-5' alt='logo' onClick={handleHomePage}/>
            </div>
          </div>
        </div>
        <div className='col-lg-9 col-md-9 py-5 profile-pad'>
          <div className='ml-4'>
            <h6 className='gry-clr ml-3'>Let’s Empowering your Journey with OneTN</h6>
            <h2 className='Your-res d-flex  pr-3 ml-3'>Your Complete Ecosystem: <article><span className='text-ani1 font-weight-bold'>Mentoring<br />Funding<br />Enabling<br />Nurturing</span></article></h2>
          </div>
          <div className='mt-3'>            
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profilelayout