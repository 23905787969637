import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Datamining = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/Data-Mining-Analytics.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
        <h3 className="banner-title-sector">Data Mining & Analytics</h3>
        </div>
        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4><br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5><br />
                  <ul>
                    <li>India has been investing heavily in big data to increase the efficiency of its governance since 2014. About 75% of big data is helping
                      government departments to improve the lifestyle of its citizens. The government has undertaken many initiatives
                      to create large platforms.</li><br />
                    <li>Department of Science & Technology (DST) has recently initiated a new programme to promote Big Data Science, Technology and
                      Applications. For fostering research in this high potential emerging area, a BDI programme support scheme is launched.
                      Following are broad streams under which financial support shall be provided to quality proposals in Grant-InAid mode.</li><br />
                  </ul>
                  <h4>Tamil Nadu Government</h4>
                  <h6 style={{ lineHeight: '30px' }}><b>Data Policy</b></h6>
                  <p style={{ lineHeight: '30px' }}>Tamil Nadu Government is acutely aware of the need for good quality data in enabling Good Governance to the citizens. The Economic Advisory
                    Council has encouraged us to use data for improving governance. The use and sharing of open data will also improve transparency.
                    We have committed ourselves to use data to improve decision making, transparency and providing services to the citizens</p>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4><br />
                  <ul>
                    <li>Creating a robust workforce for the development of the data mining and analytics sector in India requires a strategic approach that considers various factors such as skills, infrastructure, roles, and industry trends.</li><br />
                    <li>Managed office and coworking space provider Work Easy Space Solutions Private Limited is looking at operationalizing one lakh sqft in Coimbatore, Tenkasi, Madurai, Salem and Trichy in next nine months to one year.
                      the average rental per sqft in Chennai is ₹60-₹65, it is ₹30 -₹35 per sqft in tier 2 and 3 locations. Overall, the set-up cost for companies in tier 2 & 3 cities, which includes real estate and human capital,
                      is 20%-30% cheaper than in tier 1 cities.The state government’s Elcot IT-SEZ parks are also benefiting with increased bookings
                      for its new IT parks and those under construction in Coimbatore, Trichy and Madurai.The state government too recently announced
                      a tidel park for Madurai, even as it spearheads construction of tidel neo parks (mini IT parks) in Ooty, Vellore, Tirupur,
                      Villupuram, Tuticorin, Salem and Thanjavur.</li><br />
                    <li>Specialist staffing firm Xpheno, shared total employment in the state’s technology sector crossed the one million (10 lakh) mark in
                      the quarter ended June 2022, TN contributes nearly 1/6th of India’s tech workforce, but is still behind Karnataka and Maharashtra,
                      which are tied at first spot TN provided more than 7.5 lakh jobs. The more than 30% growth since March 2020 has been powered by
                      hyperactive hiring in tech and establishment of more global capability centres in TN. Large-scale work-from-home migration during
                      the pandemic has also contributed to the rise in the state’s active tech workforce. The growth seen beyond Chennai, with many
                      tier-2 and tier-3 centres attracting tech talent, is an important factor for sustainable human capital growth. This will also
                      drive a comparative cost advantage for enterprises seeking tech services Application full stack development and data analytics
                      are top skills of the techies here Reverse migration is a massive opportunity for enterprises to capitalize on low operating
                      costs in satellite offices based out of these location. The average starting pay package too has been rising depending on the
                      college and skill sets.Data mining and analytics require a skilled team of data scientists, analysts, and software engineers.
                      You will need to budget for competitive salaries, benefits, and training for your employees.</li><br />
                    <li>As enterprises and industries increasingly rely on edge computing for low-latency applications, tier-II cities are poised to
                      become crucial players in India’s data centre landscape, Cities like Pune, Ahmedabad, Jaipur, and Coimbatore are seen to be
                      ideal choices for expansion of the data centre market. “These cities also have relatively lower operational costs, including
                      real estate, power, and labour, compared to markets like Mumbai, Many global players are evaluating expansion into smaller
                      cities by setting up mini DCs or ‘edge data centres’, typically of less than 5 MW in size. Large DCs tend to be over 100 MW.
                      This rapid data center expansion creates a significant demand for a skilled workforce equipped with specialized capabilities
                      spanning structural design, cooling design, sustainability experts, edge compute consultants, AIOps, and automation.A startup
                      hub facility that will house all amenities and support needed by entrepreneurs to start a business is coming up at
                      Nandambakkam, Chennai, at a cost of Rs75 crore. Data security is crucial in this field. Invest in robust cybersecurity
                      measures to protect client data.</li><br />
                    <li>Policy makers have historically focused on getting the maximum out of the state’s traditional strengths, heavy industries
                      and MSMEs. But earlier this year, the state’s budget set the tone for a new TN that is also focused on the innovation
                      economy. The current government has recognised that the state needs the new-age technologies sector and startups to
                      compete with other industrialised states and become a $1 trillion economy. As a first step, the government revamped the
                      erstwhile Entrepreneurship Development Institute and set upTANSIM ( Tamil Nadu Startup AndInnovation Mission), a separate
                      body with a professional, non-political appointee to steer the startup ecosystem in the state. TANSIM has made inclusive
                      entrepreneurship its focus and has taken the message of entrepreneurship being “a way of life” to every corner of the
                      state. Startup challenges,pitch events, and incubation efforts are also being strengthened to support the goal of creating 10,000 new startups in the next 3-4 years. The state budget in March allocated more than Rs. 150 crore for the startup ecosystem, which included a corpus of Rs. 50 crore for TANSIM to support startups by Scheduled Caste And Scheduled Tribe (SC and ST) entrepreneurs. It also set up a Rs. 50 crore ‘Emerging Sector Seed Fund’ to make equity investments undeserving startups based in the state. Procedures Have also been relaxed for the government to directly procure innovative products from startups. While these have all emerged as the right signals to make TN a serious contender in the knowledge economy, the state houses only five out of the country’s 100 unicorns (startups with over $1 billion valuation).The government has created effective structures and picked the right set of leaders to kick-start the startup ecosystem 2.0 in the state. The state is now at $290 bn GSDP and we are now targeting $1tn by 2030. Having a conducive entrepreneurship environment is an important aspect to make this happen.</li><br />
                    <li>You’ll need working capital to cover day-to-day operational expenses until your business becomes profitable. This includes salaries, rent, utilities, and other ongoing costs. It’s challenging to provide an exact figure without specific details about your business plan, the scale of operations, and the location in India where you intend to establish your company. However, a rough estimate for a small to medium-sized data mining and analytics company in Chennai could range from INR 50 lakhs to INR 2 crores or more, depending on the factors mentioned above</li><br />
                    <li>It’s advisable to consult with a financial advisor or business consultant who can help you create a more accurate financial projection based on your unique business requirements and market conditions at the time of your startup. Additionally, consider seeking funding through investors, loans, or grants to support by government for your initial capital needs.</li><br />
                  </ul>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Datamining
