import { useEffect, useRef } from 'react';

const useDidMountEffect = (func, deps) => {
    const didMount = useRef(true);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    
}

export default useDidMountEffect;