import React, { useContext, useEffect, useState } from "react";
import { Grid, Button,FormHelperText, FormControl,} from "@mui/material";
import "swiper/css";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";
import DataContext from "../../../../context/DataContext";
import { Controller, useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import cryptoJS from "../../../../hooks/cryptoJS";
const MentorProfilePage = () => {
  const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, validatePhoneNumber, fullScreen, modalstyle,
    linkedInPattern, validateEmail,getApi, formData,upcoming, handleOpenEdit, editBtn,setEditBtn,rating, handleEditBtn, editKey, setEditKey } = useContext(DataContext);
  const {  handleSubmit, control, formState: { errors }, } = useForm({ values: formData });
  const [para, setPara] = useState("");
  const [founder, setFounder] = useState([]);
  const [screenSize, setScreenSize] = useState(3);
  const [userData, setUserData] = useState([]);
  const size = () => {
    if (window.innerWidth > 1440) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1024) {
      setScreenSize(3);
    } else if (window.innerWidth <= 1024 && window.innerWidth > 992) {
      setScreenSize(2);
    } else if (window.innerWidth <= 992 && window.innerWidth > 768) {
      setScreenSize(2);
    } else if (window.innerWidth <= 768 && window.innerWidth > 575) {
      setScreenSize(2);
    } else {
      setScreenSize(1);
    }
  };
  useEffect(() => {
    size();
  }, []);
  useDidMountEffect(() => {
    getApi();
    
  }, []);
  const onSubmit = (data) => {
    
    console.log(data, "edt data");
    data.phone = cryptoJS(data.phone);
    data.email = cryptoJS(data.email);
    apiService("userprofile/save", data, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          if (editKey === "") {
            setEditBtn(false);
          } else {
            setEditKey("");
          }
          getApi();
        }
        
      })
      .catch((err) => {});
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={3}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="investment-whole-div">
              <div className="profile-box2">
                <div className="mentor-about-editing">
                  <div>
                    <h5>About</h5>
                  </div>
                  <div>
                    {editKey === "about" ? (
                      <div>
                        <Button type="submit">
                          <DoneOutlineIcon
                            sx={{ fontSize: "16px", cursor: "pointer" }}
                          />
                        </Button>
                      </div>
                    ) : (
                      <>
                        {editBtn === true && (
                          <EditIcon
                            sx={{
                              fontSize: "14px",
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            onClick={() => handleEditBtn("about")}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                {editKey === "about" ? (
                  <div className="d-inline-block">
                    <div className="d-flex align-items-start">
                      <div>
                        <Controller
                          name="about"
                          control={control}
                          defaultValue=""
                          rules={{ required: `Brief is required` }}
                          render={({ field }) => (
                            <textarea
                              type="text"
                              {...field}
                              className="mentor-area"
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.about && errors.about.message}
                        </FormHelperText>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span className="men-prof-name">
                    <p className="invest-para">{formData.about}</p>
                  </span>
                )}
              </div>
              <div className="profile-box2 mt-2">
                <h5 style={{ fontWeight: "600" }}>Upcoming Sessions</h5>

                <div style={{ width: "100%", overflowX: "auto" }}>
                  <table
                    className="table"
                    style={{ width: "100%", borderCollapse: "collapse" }}
                  >
                    <tbody>
                      {upcoming.map((list, index) => (
                        <tr>
                          <td
                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                            key={index}
                          >
                            <div className="d-flex align-items-center profile-img">
                              <img
                                src={list.logoName}
                                alt=""
                                className="review_logo"
                              />
                              <div className="ml-3">
                                <p className="list-para mb-0">{list.title}</p>

                                <span
                                  style={{ fontSize: "10px", color: "#B3B3B3" }}
                                >
                                  <span className="mr-3">
                                    <img
                                      src="/images/box-calender.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    {list.showDate}
                                  </span>
                                  <span>
                                    <img
                                      src="/images/box-time.png"
                                      alt=""
                                      className="mr-2"
                                    />
                                    {list.showStartTime} - {list.showEndTime}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </td>

                          <td
                            style={{ padding: "0.75rem", verticalAlign: "top" }}
                          >
                            <div className="soc-logos">
                              <button className="mentor_view_details">
                                View Details
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="profile-box2 mt-2">
                <h5 style={{ fontWeight: "600" }} className="mb-3">
                  Ratings & Reviews
                </h5>
                <div style={{ width: "100%", overflowX: "auto" }}>
                  <h5 className="color-blue">
                    <img src={"/images/star-rate.png"} alt="" />{" "}
                    {formData.averageRating} / 5 (
                    {formData.totalReviews})
                  </h5>

                  <table
                    className="table"
                    style={{ width: "100%", borderCollapse: "collapse" }}
                  >
                    <tbody>
                      {rating.length > 0 ? (
                        rating.map((item, index) => (
                          <tr
                            className="mentor-boxs"
                            key={index}
                            style={{ marginBottom: "10px" }}
                          >
                            <td
                              style={{
                                padding: "0.75rem",
                                verticalAlign: "top",
                                width: "150px",
                              }}
                            >
                              <div className="">
                                <img
                                  src={item.reviewerLogo}
                                  className="review_logo"
                                  alt=""
                                />
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "0.75rem",
                                verticalAlign: "top",
                              }}
                            >
                              <div className="news-letter-details">
                                <div className="d-flex">
                                  <div>
                                    <h5>{item.reviewerName}</h5>

                                    <p className="news-letter-source font-sm">
                                      {item.review}
                                    </p>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "0.75rem",
                                verticalAlign: "top",
                                width: "120px",
                              }}
                            >
                              <div>
                                <p className="ml-auto list-para mr-5">
                                  <img src={"/images/star-rate.png"} alt=""/>{" "}
                                  {item.point}
                                </p>
                                {/* <span className="ml-auto font-sm">{item.createdTime}</span> */}
                              </div>
                            </td>
                            <div className="grid-border"></div>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="profile-box1">
              <div>
                <h6 style={{ fontWeight: "600" }}>Key Highlights</h6>
                <ul className="p-0">
                  <li className="d-flex align-items-start justify-content-start">
                    <div className="key-img mt-2">
                      <img src={"/images/pie-chart.png"} alt="pieChart" />
                    </div>
                    <div className="ml-3">
                      <>
                        <span className="list-span">Focused Sector</span>
                      </>
                      <>
                        <p className="list-para ">
                          {formData.focusedSector}
                        </p>
                      </>
                    </div>
                  </li>
                  <li className="d-flex align-items-start justify-content-start">
                    <div className="key-img mt-2">
                      <img src={"/images/Function.png"} alt="pieChart" />
                    </div>
                    <div className="ml-3">
                      <>
                        <span className="list-span">
                          Function / Specialization
                        </span>
                      </>
                      <>
                        {formData.specialization ? (
                          <p className="list-para">
                            {editKey === "specialization" ? (
                              <div className="d-inline-block">
                                <div className="d-flex align-items-start">
                                  <div>
                                    <FormControl sx={{ width: 200 }}>
                                      <Controller
                                        name="specialization"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: `Specialization is required`,
                                        }}
                                        render={({ field }) => (
                                          <input
                                            placeholder="Enter Specialization"
                                            type="text"
                                            {...field}
                                            className="sml-inp"
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.specialization &&
                                          errors.specialization.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <Button type="submit">
                                    <DoneOutlineIcon
                                      sx={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <span>
                                {" "}
                                {formData.specialization}{" "}
                                {editBtn === true && (
                                  <EditIcon
                                    sx={{ fontSize: "14px", cursor: "pointer" }}
                                    onClick={() =>
                                      handleEditBtn("specialization")
                                    }
                                  />
                                )}
                              </span>
                            )}
                          </p>
                        ) : (
                          <p className="list-para">-</p>
                        )}
                      </>
                    </div>
                  </li>

                  <li className="d-flex align-items-start justify-content-start">
                    <div className="key-img mt-2">
                      <img src={"/images/Availability.png"} alt="pieChart" />
                    </div>
                    <div className="ml-3">
                      <>
                        <span className="list-span">Mode of Availability</span>
                      </>
                      <>
                        {formData.mode ? (
                          <p className="list-para">
                            {editKey === "mode" ? (
                              <div className="d-inline-block">
                                <div className="d-flex align-items-start">
                                  <div>
                                    <FormControl sx={{ width: 200 }}>
                                      <Controller
                                        name="mode"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: `Mode is required`,
                                        }}
                                        render={({ field }) => (
                                          <input
                                            placeholder="Enter Mode"
                                            type="text"
                                            {...field}
                                            className="sml-inp"
                                          />
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.mode && errors.mode.message}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <Button type="submit">
                                    <DoneOutlineIcon
                                      sx={{
                                        fontSize: "16px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Button>
                                </div>
                              </div>
                            ) : (
                              <span>
                                {" "}
                                {formData.mode}{" "}
                                {editBtn === true && (
                                  <EditIcon
                                    sx={{ fontSize: "14px", cursor: "pointer" }}
                                    onClick={() => handleEditBtn("mode")}
                                  />
                                )}
                              </span>
                            )}
                          </p>
                        ) : (
                          <p className="list-para">-</p>
                        )}
                      </>
                    </div>
                  </li>
                  <li className="d-flex align-items-start justify-content-start">
                    <div className="key-img mt-2">
                      <img src={"/images/star.png"} alt="pieChart" />
                    </div>
                    <div className="ml-3">
                      <>
                        <span className="list-span">Ratings</span>
                      </>
                      <>
                        <p className="list-para">
                          <img src={"/images/star-rate.png"} alt=""/>{" "}
                          {formData.averageRating} / 5 (
                          {formData.totalReviews})
                        </p>
                      </>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default MentorProfilePage;
