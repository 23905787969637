import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../../home-module/navbar-page/Navbar";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const StartupProfileLayout = () => {
    // const navigator = useNavigate()
    // let path
    // useDidMountEffect(() => {
    //     if (!localStorage.getItem('jwttoken')) navigator('/login');
    //     path = window.location.hash        
    // }, [])
  return ( 
    <div className="bg-color">
      <div >
      <Navbar />
      <Outlet></Outlet>      
      </div>
    </div>
  );
};

export default StartupProfileLayout