import React from 'react'
import { Outlet } from 'react-router-dom'
import AdminSupportFilters from '../AdminSupportFilters'

const StartupSupportLayout = () => {
    return (
        <div>
            <AdminSupportFilters name={'startup'}/>
            <Outlet></Outlet>
        </div>
    )
}

export default StartupSupportLayout