import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Lifestyle = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/StartupTnSectorLifestyle-Personalcare-D2C.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Life Style, Personal Care</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Initiatives</h4>
                  <br />
                  <ul>
                    <li>
                      <span>
                        <b>Make in India Campaign:</b>
                      </span>
                      The Union Government’s “Make in India” campaign lays
                      emphasis on local manufacture and production of
                      commodities, including personal care and lifestyle
                      products. It promotes indigenous products and businesses
                      in order to boost domestic production, create jobs and
                      reduce imports.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Regulatory Framework:</b>
                      </span>
                      In partnership with agencies such as the Food Safety and
                      Standards Authority of India (FSSAI), the government has
                      developed regulatory standards and procedures for the
                      production, labelling and marketing of personal care and
                      cosmetic items. This safeguards the product’s quality and
                      the user’s safety.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Startup India: </b>
                      </span>
                      Startup India offers assistance and incentives to firms in
                      the Lifestyle and Personal Care sector. They include
                      monetary incentives, tax breaks and simplified regulatory
                      compliance, all of which promote industrial innovation and
                      entrepreneurship.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Skill Development Programmes:</b>
                      </span>
                      The State Government funds skill development efforts to
                      teach people how to be beautiful and take care of
                      themselves. These programmes help to produce a skilled
                      workforce for the industry, which leads to greater job
                      opportunities.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Export Promotion:</b>
                      </span>
                      The government assists sector exporters through
                      initiatives such as the Merchandise Exports from India
                      Scheme (MEIS). These initiatives are intended to promote
                      the export of personal care and recreational items to
                      international markets.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Promotion of Ayurveda and Herbal Products:</b>
                      </span>
                      In keeping with the global trend towards natural and
                      herbal products, both the Union and State governments
                      foster the manufacturing and sale of Ayurvedic and herbal
                      personal care products. They provide funding for research
                      and development in this domain.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Consumer Awareness Campaigns:</b>
                      </span>
                      Awareness campaigns are conducted in the State to educate
                      the public about the importance of using safe and
                      certified personal care products. These campaigns aim to
                      protect consumers from counterfeit and inferior goods.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Ease of Doing Business:</b>
                      </span>
                      Licensing and regulatory requirements have been simplified
                      to ensure ease of doing business. This makes it easier to
                      establish and expand personal care and lifestyle
                      businesses.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Financial Support:</b>
                      </span>
                      Financial institutions and banks provide credit to
                      entrepreneurs and businesses in the industry, supporting
                      investment and expansion.
                    </li>
                    <br />
                    <li>
                      <span>
                        <b>Skill Development in Traditional Crafts:</b>
                      </span>
                      Initiatives to protect and promote handicrafts and
                      traditional personal care products (e.g., Siddha Ayurvedic
                      items) are organised, providing employment to local
                      artists.
                    </li>
                    <br />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h4 style={{ color: "#39364e" }}>Resources</h4> <br />
                  <h6>
                    <b>
                      Government Ministry/ Department supporting Lifestyle and
                      Personal Care Market
                    </b>
                  </h6>
                  <a href="">
                    Department for Promotion of Industry and Internal Trade
                    (DPIIT)
                  </a>
                  <br />
                  <a href="">Ministry of Health and Family Welfare</a>
                  <br />
                  <a href="">
                    Central Drugs Standard Control Organisation (CDSCO)
                  </a>
                  <br />
                  <a href="">
                    Ministry of Consumer Affairs, Food & Public Distribution of
                    India
                  </a>
                  <br />
                  <a href="">Ministry of Chemicals and Fertilizers</a>
                  <br />
                  <h6>
                    <b>
                      Industry Associations supporting Lifestyle and Personal
                      Care Market
                    </b>
                  </h6>
                  <br />
                  <a href="">Indian Beauty & Hygiene Association</a>
                  <br />
                  <a href="">Feminine and Infant Hygiene Association</a>
                  <br />
                  <a href="">Indian Soaps and Toiletries Maker’s Association</a>
                  <br />
                  <a href="">The Food and Drug Administration (FDA)</a>
                  <br />
                  <h5>
                    <b>
                      Market Factors supporting Lifestyle and Personal Care
                      Market
                    </b>
                  </h5>
                  <br />
                  <p>
                    India’s direct-to-consumer (D2C) market, which is likely to
                    reach a size of $100 Bn by 2025, has grown exponentially in
                    the last few years. Several factors, including higher
                    Internet penetration, growth of digital infrastructure and
                    rise in the number of millennials, have shored up the D2C
                    and Lifestyle brands.
                  </p>
                  <br />
                  <p>
                    Home to more than 190 Mn digital shoppers, India has the
                    world’s third largest online shopping base. It is this
                    burgeoning ecosystem that the new-age D2C and lifestyle
                    brands aim to capitalise on, on the back of the growing
                    appetite of Indian consumers for innovation and waning
                    loyalty towards traditional players.
                  </p>
                  <br />
                  <p>
                    Of this, fashion and clothing Startups have the highest
                    potential and are expected to grow to $43.2 Bn by 2025,
                    according to an Inc42 report.
                  </p>
                  <br />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Lifestyle;
