import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Smartcities = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/startuptn-banner-smart-cities.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Smart Cities & E-Governance</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <p>
                    The Smart Cities Mission is a major initiative of the Union
                    Government to transform the country’s urban areas. Some of
                    the key initiatives under this programme include:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Development of smart transportation systems, such as
                      intelligent traffic management systems and public
                      transportation systems.
                    </li>
                    <br />
                    <li>
                      Development of smart energy management systems, such as
                      smart grids and renewable energy systems.
                    </li>
                    <br />
                    <li>
                      Development of smart water management systems, such as
                      water conservation and rainwater harvesting systems.
                    </li>
                    <li>
                      Development of smart waste management systems, such as
                      waste segregation and recycling systems.
                    </li>
                    <li>
                      Development of smart city apps and other ICT solutions to
                      improve the delivery of government services and citizen
                      engagement.
                    </li>
                  </ul>{" "}
                  <br />
                  <p>
                    The e-governance initiative of the Union Government is a
                    broad programme aimed at using ICT to improve the delivery
                    of government services to citizens and businesses. It
                    encompasses a wide range of initiatives, including:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Online government services: Income Tax e-Filing portal,
                      Passport Seva portal and Digilocker platform. These
                      services allow citizens to access a wide range of
                      government services without having to visit a government
                      office.
                    </li>
                    <br />
                    <li>
                      Mobile government apps: The government has launched a
                      number of mobile apps, such as the UMANG app and the Bhim
                      app. These apps allow citizens to access government
                      services on their smartphones.
                    </li>
                    <br />
                    <li>
                      Open data: Open data initiatives, such as the India Data
                      Portal, make government data available to the public. The
                      available data can be used by businesses and individuals
                      to develop innovative products and services.
                    </li>
                    <li>
                      Digital identity management: The government is working on
                      developing a digital identity management system for India.
                      This system will provide citizens with a unique digital
                      identity that can be used to access government and private
                      sector services.
                    </li>
                  </ul>{" "}
                  <br />
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />
                  <p>
                    The Tamil Nadu Government has selected 11 cities for
                    development under the Smart Cities Mission. They are
                    Chennai, Coimbatore, Madurai, Salem, Thanjavur, Vellore,
                    Tiruppur, Thoothukudi, Tirunelveli, Tiruchi and Erode. It
                    has prepared a Smart City Proposal for each of the 11
                    cities. The SCPs outline the specific projects and
                    initiatives that will be implemented in each city to make it
                    more livable, efficient and sustainable.
                  </p>
                  <br />
                  <p>
                    Some of the e-governance projects initiated in Tamil Nadu
                    are:
                  </p>
                  <ul>
                    <li>
                      E-Sevai: E-Sevai is a one-stop shop for government
                      services.
                    </li>
                    <br />
                    <li>
                      Tamil Nadu e-Governance Agency (TNeGA): TNeGA is a nodal
                      agency for e-governance in the State.
                    </li>
                    <br />
                    <li>
                      Tamil Nadu e-Governance Mission (TNeGM): TNeGM aims to
                      make Tamil Nadu a leader in e-governance. TNeGM has
                      launched the following initiatives:
                    </li>
                    <br />
                    <ul>
                      <li>
                        Tamil Nadu Digital Infrastructure (TNDI): TNDI is a
                        State-wide data centre and cloud computing
                        infrastructure that will provide government agencies
                        with access to secure and reliable IT services.
                      </li>
                      <br />
                      <li>
                        Tamil Nadu e-Governance Academy (TNeGA): TNeGA is a
                        training and capacity building academy for e-governance
                        professionals.
                      </li>
                      <br />
                      <li>
                        Tamil Nadu Blockchain Innovation Centre (TNBIC): TNBIC
                        is a centre of excellence for blockchain research and
                        development.
                      </li>
                      <br />
                    </ul>
                    <li>
                      The Tamil Nadu Government is committed to using technology
                      to improve the delivery of government services to its
                      citizens.The initiatives of the State Government include
                    </li>
                    <br />
                    <li>
                      Development of smart transportation systems, such as
                      intelligent traffic management systems, public
                      transportation systems and non-motorised transport
                      infrastructure.
                    </li>
                    <br />
                    <li>
                      Development of smart energy management systems, such as
                      smart grids, renewable energy systems and energy-efficient
                      buildings.
                    </li>
                    <br />
                    <li>
                      Development of smart water management systems, such as
                      water conservation and rainwater harvesting systems
                    </li>
                    <br />
                    <li>
                      Development of smart waste management systems, such as
                      waste segregation and recycling systems.
                    </li>
                    <br />
                    <li>
                      Development of smart city apps and other ICT solutions to
                      improve the delivery of government services and citizen
                      engagement.
                    </li>
                    <br />
                  </ul>{" "}
                  <br />
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <ul>
                    <li>
                      Human resources: Tamil Nadu has a large pool of skilled
                      human resources, including engineers, scientists, and IT
                      professionals.
                    </li>
                    <br />
                    <li>
                      Technological resources: Tamil Nadu has a strong IT
                      infrastructure and a number of leading IT companies have a
                      presence in the State.
                    </li>
                    <br />
                    <li>
                      Institutional resources: Tamil Nadu has a number of
                      institutions that can support the implementation of Smart
                      City programmes, such as the Tamil Nadu e-Governance
                      Agency (TNeGA) and the Tamil Nadu e-Governance Mission
                      (TNeGM).
                    </li>
                    <li>
                      Legal and regulatory resources: Tamil Nadu has a number of
                      laws and regulations in place that support the
                      implementation of e-governance initiatives, such as the
                      Tamil Nadu Information Technology Act.
                    </li>
                  </ul>{" "}
                  <br />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Smartcities;
