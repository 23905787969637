import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Typography,
  Stack,
  Pagination,
} from "@mui/material";
import React, { useContext, useState } from "react";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import apiService from "../../../../api/apiService";

const StartupSupportList = () => {
  const {
    navigator,
    getSupportStatus,
    supportType,
    setSupportType,
    readStatus,
    setReadStatus,
    setPageLoading,
    searchSupportInputValue,
    selectedSupportPeriod,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sortBy,
    setSortBy,
  } = useContext(DataContext);
  useDidMountEffect(() => {
    setSupportType("All Support");
    setReadStatus(0);
    setStartDate("");
    setEndDate("");
    setSortBy(true);
  }, []);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  useDidMountEffect(() => {
    listApi();
  }, [
    page,
    supportType,
    readStatus,
    searchSupportInputValue,
    selectedSupportPeriod,
    startDate,
    endDate,
    sortBy,
  ]);
  const [supportList, setSupportList] = useState([]);
  let depId = localStorage.getItem('departmentId')
  const listApi = () => {
    setPageLoading(true);
    setSupportList([]);
    var req = {
      departmentId:Number(depId),
      listSize: 12,
      pageNumber: page,
      // supportType: supportType,
      status: readStatus,
      searchString: searchSupportInputValue,
      period: selectedSupportPeriod,
      type: 0,
      startDate: startDate ? startDate + "T00:00:00.174Z" : "",
      endDate: endDate 
      ? endDate + "T00:00:00.174Z" 
      : (startDate ? startDate + "T00:00:00.174Z" : ""),
      sort: sortBy ? "asc" : "desc",
    
    };
    apiService("support/ticket/admin/list", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result && result.data) {
          setTotalPage(result.data.totalPages);
          if (result.data.responseDTOList) {
            setSupportList(result.data.responseDTOList);
          }
        }
      })
      .catch((err) => {});
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleDetails = (id) => {
    navigator(`/admin/support/startup/${btoa(id)}`);
  };

  return (
    <>
      <div className="table_main_div">
        {supportList.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
              <TableHead>
                <TableRow className="bar">
                  <TableCell>Ticket No.</TableCell>
                  {/* <TableCell>Organization Name</TableCell> */}
                  <TableCell>Query Title</TableCell>
                  <TableCell>Project</TableCell>
                  {/* <TableCell>Due Date</TableCell> */}
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ position: "relative" }}>
                {supportList.map((list, i) => (
                  <>
                    <TableRow
                      sx={{
                        position: "relative",
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          border: "1px solid rgba(224, 224, 224, 1)",
                          borderRadius: "20px",
                          pointerEvents: "none",
                          marginBottom: "-1px",
                        },
                        "& > *": {
                          padding: "10px",
                          backgroundColor: "#fff",
                          "&:last-of-type": {
                            borderTopRightRadius: "20px",
                            borderBottomRightRadius: "20px",
                          },
                        },
                        marginBottom: "10px",
                      }}
                      key={i}
                      className="Sup-list"
                      onClick={() => handleDetails(list.id)}
                    >
                      <TableCell className="list-1">
                        <span className="list-1-clr">{list.ticketNo}</span>
                      </TableCell>
                      {/* <TableCell>
                          <Typography variant="h6" className="list-2-clr">{list.serviceName}</Typography>
                          <div className="list-2-dflex">
                            <span className="list-2-grey">User Profile : </span>
                            <span className="list-2-black">{list.persona}</span>
                          </div>
                          <div>
                            <span className="list-2-grey">Smartcard ID:</span>
                            <span className="list-2-black">{list.smartCardNumber}</span>
                          </div>
                        </TableCell> */}
                      <TableCell sx={{width: '50%'}}>
                        <b>{list.queryTitle ? list.queryTitle : "-"}</b>
                      </TableCell>
                      <TableCell>
                        {list.projectName ? (
                          <span className="list-4-clr">{list.projectName}</span>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      {/* <TableCell>
                        {list.dueDate ? (
                          <span
                            className={`list-5-clr set-${list.dueDateColor}`}
                          >
                            {list.dueDate}
                          </span>
                        ) : (
                          "-"
                        )}
                      </TableCell> */}
                      <TableCell align="center">
                        <span className="list-6-clr">
                          {getSupportStatus(list.status)}
                        </span>
                      </TableCell>
                      <span className="date mt-2 mr-3">
                        {list.creationTime}
                      </span>
                    </TableRow>
                    <span className="mt-2 mr-3"> </span>
                  </>
                ))}
              </TableBody>
            </Table>
            {totalPage > 1 && (
              <div className="event-pagination">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            )}
          </TableContainer>
        ) : (
          <div className="text-center nodatacss_admin">
            <h5 className="mb-4">Startup support data not found</h5>
            <div>
              <img
                src="/images/blue horizontal.png"
                alt="blue horizontal.png"
                className="logo_dash img-fluid"
              />
            </div>
            <div className="mt-5">
              <h3 className="title_p opa">
                {/* StartupTN 3.0 */}
                OneTN
              </h3>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StartupSupportList;
