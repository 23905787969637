import React, { useContext, useState } from 'react'
import { FormHelperText, Grid, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import DataContext from '../../../../context/DataContext'
import apiService from '../../../../api/apiService'
import notifyService from '../../../../api/notifySerivce'

const SociallinksAspirants = () => {
  const { navigator, setPageLoading, handleNextClick, linkedInPattern } = useContext(DataContext)
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });

  var id = localStorage.getItem("id");
  var type = localStorage.getItem('type')

  useDidMountEffect(() => {
    handleNextClick(85)
    getApi()
  }, [])

  const getApi = () => {
    setPageLoading(true)
    apiService(`userprofile/get?persona=${type}&userid=${id}`, '', 'get').then((result) => {
      setPageLoading(false)
      if (result && result.data) {
        var data = result.data
        setFormData(data)
      }

    }).catch((err) => {

    });
  }
  const onSubmit = (data) => {
    console.log('Form Data:', data);
    setPageLoading(true)
    apiService('userprofile/save', data, 'post').then((result) => {
      setPageLoading(false)
      if (result.data.responseStatus === 'Success') {

        navigator('/aspirants/documents')
      }
    }).catch((err) => {

    });
  };
  const onError = (event) => {
    console.log('error Data:', event);
    // notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className='signup-data_div'>
            <div className='forms-title d-flex'>
              <p>Organization Info {`>`} <span className='path_sapn'>Social Links</span></p>
              <p className='ml-auto'><span className='count_num'>3</span>/5</p>
            </div>
            <div className='signup_box_div mt-4'>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <div className='d-flex align-item-center'>
                      <div className='div_round'>
                        <img src='/images/websiteurl.png' alt='' />
                      </div>
                      <div className='w-100'>
                        <Controller
                          name="website"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Website URL"
                              placeholder="Enter Website URL"
                              fullWidth
                              type="text"
                              {...field}
                              onKeyDown={handleKeyDown}
                              error={Boolean(errors.website && errors.website)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.website && errors.website.message}
                        </FormHelperText>
                      </div>
                    </div>


                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <div className='d-flex align-item-center'>
                      <div className='div_round'>
                        <img src='/images/linkedin.png' alt='' />
                      </div>
                      <div className='w-100'>
                        <Controller
                          name="linkedIn"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: false,
                            // pattern: {
                            //   value: linkedInPattern,
                            //   message: "Invalid LinkedIn profile URL"
                            // }
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="LinkedIn"
                              placeholder="Enter LinkedIn"
                              fullWidth
                              type="text"
                              {...field}
                              error={Boolean(errors.linkedIn && errors.linkedIn)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.linkedIn && errors.linkedIn.message}
                        </FormHelperText>
                      </div>
                    </div>


                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <div className='d-flex align-item-center'>
                      <div className='div_round'>
                        <img src='/images/twitter.png' alt='' />
                      </div>
                      <div className='w-100'>
                        <Controller
                          name="twitter"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Twitter / X"
                              placeholder="Enter Twitter / X"
                              fullWidth
                              type="text"
                              {...field}
                              onKeyDown={handleKeyDown}
                              error={Boolean(errors.twitter && errors.twitter)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.twitter && errors.twitter.message}
                        </FormHelperText>
                      </div>
                    </div>


                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='mb-3'>
                    <div className='d-flex align-item-center'>
                      <div className='div_round'>
                        <img src='/images/facebook.png' alt='' />
                      </div>
                      <div className='w-100'>
                        <Controller
                          name="facebook"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Facebook"
                              placeholder="Enter Facebook"
                              fullWidth
                              type="text"
                              {...field}
                              onKeyDown={handleKeyDown}
                              error={Boolean(errors.facebook && errors.facebook)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.facebook && errors.facebook.message}
                        </FormHelperText>
                      </div>
                    </div>


                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='text-center'>
                    <button className='next-btn' type="submit">Next</button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SociallinksAspirants