import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const MarketingTech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/Marekting-Tech.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Marketing Tech & MICE</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h6>Union Government</h6>
                  <br />
                  <p>
                    The Union Ministry of Tourism launched its campaign “Meet in
                    India” from Khajuraho, which is one of the identified iconic
                    tourist destinations of the country. Recognising India’s
                    huge potential as an MICE destination, ‘Meet in India’ is
                    the distinct sub-brand under ‘Incredible India’ to promote
                    the country as an MICE destination.{" "}
                  </p>
                  <br />

                  <p>
                    Tamil Nadu Government’s Department of Tourism has identified
                    ten tourism segments for development and promotion. They are
                    Heritage & Pilgrimage Tourism, Coastal Tourism, Adventure
                    Tourism, Eco & Wildlife Tourism, Medical and Wellness
                    Tourism, MICE Tourism, Rural and Plantation Tourism, Caravan
                    Tourism, Cultural Tourism and Culinary Tourism
                  </p>
                  <br />
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <p>
                    The Marketing Technology Timeline chronicles significant
                    developments in three categories: marketing channels, the
                    tools marketers use to manage these channels and data
                    available to marketers.
                  </p>
                  <br />

                  <p>
                    MarTech is an industry, in addition to being a variety of
                    platforms or software.. Thousands of companies, large and
                    small, offer a variety of marketing software solutions to
                    aid marketers in their quest to identify potential buyers,
                    close sales and provide customer service.
                  </p>
                  <br />

                  <p>
                    Tamil Nadu’s infrastructure resources are rich and termed as
                    an advantage as it has three major sea ports (Chennai,
                    Ennore and Thoothukudi) and 19 minor sea ports, The State’s
                    rail network density is higher than the national average. It
                    has four international airports (Chennai, Tiruchi, Madurai
                    and Coimbatore) and two domestic airports (Salem and
                    Thoothukudi). Eighty-five per cent of passenger traffic
                    takes place in the State on its road network of 66,039 km.
                  </p>
                  <br />
                  <p>
                    The State Government is taking proactive measures in
                    promoting MICE Tourism. A spectacular destination of ancient
                    wisdom bestowed with the eternal grace of nature, Tamil Nadu
                    is being promoted as an enchanting destination for
                    holidaying in southern India and tourism is a major
                    contributor to the state’s economy.
                  </p>
                  <br />

                  <p>
                    More than 2.5 crore domestic tourists and 18 lakhs foreign
                    tourists visit the state every year. 30% of the
                    international tourists visiting India also visit Tamil Nadu.
                    The state has been able to maintain impressive tourist
                    arrivals and offers a relatively secure environment for
                    business and leisure. Consistency in policies and a
                    business-friendly approach has helped the State Government
                    to attract a good investment in tourism.
                  </p>
                  <br />

                  <p>
                    To enhance the MICE Sector and to appreciate its variety of
                    attractions, the Government of Tamil Nadu has initiated a
                    number of schemes and launched marketing campaigns. The
                    advertisement campaign is designed to represent core
                    strength of Tamil Nadu like its heritage, history, culture,
                    spirituality and peace. Tamil Nadu has a strong industrial
                    base, seaport and airports, star hotels, spas, stadium,
                    ayurvedic centres and recreation facilities. It has
                    wonderful amenities for MICE Tourism. It will attract large
                    segments of Businessmen, Industrialists, Investors,
                    Specialists, and Artists etc. to make Tamil Nadu as a hub
                    for their national and international activities.
                  </p>
                  <br />

                  <p>
                    Chennai and Coimbatore are rising as popular conference
                    centres. MICE tourists have a potential to become high
                    spending tourists. Tamil Nadu Trade Promotion Organization
                    has constructed a colossal convention centre with the state
                    of art amenities in the extensive Chennai Trade centre
                    campus. It is close to the International Airport. There are
                    a few five-star hotels in close locality. Chennai Convention
                    Centre in cool and green environ perfectly refreshes the
                    mind and balancing blends it with the business while holding
                    a conference or conducting an event. It provides an ideal
                    setting for a meeting with friends or colleagues. Presence
                    of good information technology, excellent connectivity, and
                    good infrastructure are the major advantages for MICE
                    Tourism.
                  </p>
                  <br />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MarketingTech;
