import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import DataContext from '../../../../context/DataContext';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import { Button, DialogActions, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const AdminVenueAdd = ({ handleVenueClose, venueId, listApi }) => {
    const { setPageLoading, validateNumberonly } = useContext(DataContext)
    const [formData, setFormData] = useState({})
    const { handleSubmit, control, reset, formState: { errors } } = useForm({ values: formData });
    useDidMountEffect(() => {
        if (venueId) {
            getApi()
        }

    }, [])
    const getApi = () => {
        apiService(`venue/get?id=${venueId}`, '', 'get').then((result) => {
            if (result && result.data) {
                setFormData(result.data)
            }
        }).catch((err) => {

        });
    }
    const onSubmit = (data) => {
        setPageLoading(true)
        apiService('venue/save', data, "post").then((res) => {
            setPageLoading(false)
            if (res) {
                if (res.data.responseStatus === "Success") {
                    reset()
                    listApi()
                    handleVenueClose();
                    notifyService("success", "Success", "");
                }
            }
        });
    }
    const onError = (data) => {
        notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
    }
    return (
        <form className="signin-form text-center" onSubmit={handleSubmit(onSubmit, onError)}>
            <DialogTitle>{venueId ? 'Update' : 'Add'} Venue</DialogTitle>
            <DialogContent>
                <div className='row' >
                    <div className='col-12 mb-3' >
                        <div className='input_background'>
                            <Controller name="name" control={control} defaultValue=""
                                rules={{
                                    required: `Venue Name is required`,
                                    validate: (value) =>
                                        value.trim() !== "" || "Venue Name cannot contain only spaces"
                                }}
                                render={({ field }) =>
                                    <TextField
                                        variant="outlined"
                                        label="Venue Name *"
                                        placeholder="Enter Venue Name"
                                        fullWidth
                                        type="text"
                                        {...field}
                                    />
                                } />
                            <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                        </div>
                    </div>
                    <div className='col-12 mb-3' >
                        <div className='input_background'>
                            <Controller name="location" control={control} defaultValue="" rules={{
                                required: `Location is required`,
                                validate: (value) =>
                                    value.trim() !== "" || "Location cannot contain only spaces"
                            }}
                                render={({ field }) =>
                                    <TextField
                                        variant="outlined"
                                        label="Location *"
                                        placeholder="Enter Location"
                                        fullWidth
                                        type="text"
                                        {...field}
                                    />
                                } />
                            <FormHelperText className='text-danger'>{errors.location && errors.location.message}</FormHelperText>
                        </div>
                    </div>
                    <div className='col-12 mb-3' >
                        <div className='input_background'>
                            <Controller
                                name="pincode"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Pincode is required",
                                    validate: (value) =>
                                        value.trim() !== "" || "Location cannot contain only spaces"
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Pincode *"
                                        placeholder="Enter Pincode"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        onKeyDown={(e) => validateNumberonly(e)}
                                        inputProps={{ maxLength: 6, minLength: 6 }}
                                    />
                                )}
                            />
                            <FormHelperText className="text-danger">
                                {errors.pincode && errors.pincode.message}
                            </FormHelperText>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions >
                <div className='text-center mb-3 w-100'>
                    <Button className="discard-btn" onClick={() => handleVenueClose()}>
                        Close
                    </Button>
                    <Button type="submit" className="publish-button">
                        {venueId ? 'Update' : 'Save'}
                    </Button>
                </div>

            </DialogActions>
        </form>
    )
}
export default AdminVenueAdd