import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Signuplayout from '../../auth-module/signup-details-page/Signuplayout'
import BasicinfoEcosystem from './organization-info-page/BasicinfoEcosystem'
import AddressEcosystem from './organization-info-page/AddressEcosystem'
import SociallinksEcosystem from './organization-info-page/SociallinksEcosystem'
import DocumentsEcosystem from './organization-info-page/DocumentsEcosystem'
import MoreinfoEcosystem from './organization-info-page/MoreinfoEcosystem'
// import FounderDetailsEcosystem from './team-info-page/FounderDetailsEcosystem'
import Foundedetails from '../startup-profile/team-info-page/Foundedetails'
const Ecosystemroutes = () => {
    return (
        <main>
            <Routes>
                <Route path='/ecosystem' element={<Signuplayout />}>
                    <Route path="/ecosystem" element={<Navigate replace to="/ecosystem/basicinfo" />} />
                    <Route path='/ecosystem/basicinfo' element={<BasicinfoEcosystem />} />
                    <Route path='/ecosystem/address' element={ <AddressEcosystem /> } />
                    <Route path='/ecosystem/social-links' element={ <SociallinksEcosystem /> } />
                    <Route path='/ecosystem/documents' element={ <DocumentsEcosystem /> } />
                    <Route path='/ecosystem/moreinfo' element={ <MoreinfoEcosystem /> } />
                    <Route path='/ecosystem/founder' element={ <Foundedetails url={'mediablogngo'} navigate={'/ecosystem/profile'}/> } />
                </Route>
            </Routes>
        </main>
    )
}

export default Ecosystemroutes