import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Userprofile from "./Userprofile";
import UserSchemeservice from "./UserSchemeservice";
import DataContext from "../../../../context/DataContext";

const UserProfileTab = ({ names,path }) => {
  const { userProfileId } = useContext(DataContext);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <div className="container-fluid flu-class">
        <div className="bg-img"></div>
        <div className={userProfileId !== 0 ? 'user-tabs' : 'user-tabsss'}>
          <div style={{ width: '100%' }}>
            {/* <TabContext value={value}>
              {
                userProfileId === 0 && (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Profile" value="1" style={{
                        backgroundColor: value === "1" ? "#020671" : "white",
                        color: value === "1" ? "white" : "#000",
                        borderRadius: '10px 10px 0px 0px',
                        padding: '0px 80px',
                        height: '65px',
                        fontSize: '18px',
                        textTransform: "none",
                      }} />
                      <Tab label="Schemes and Services " value="2" style={{
                        backgroundColor: value === "2" ? "#020671" : "white",
                        color: value === "2" ? "white" : "#000",
                        borderRadius: '10px 10px 0px 0px',
                        textTransform: "none",
                        fontSize: '18px',
                      }} />
                    </TabList>
                  </Box>
                )
              }

              <TabPanel value="1" sx={{ padding: '0px' }}>
                <Userprofile names={names} path={path}/>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: '0px' }}>
                <UserSchemeservice />
              </TabPanel>
            </TabContext> */}
             <Userprofile names={names} path={path}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileTab;
