import { Button, Grid, Box, Divider, FormHelperText, Autocomplete, FormControl, TextField, DialogTitle, InputAdornment } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
// import Modal from "@mui/material/Modal";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import UserProfileReport from "./UserProfileReport";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { CiGlobe } from "react-icons/ci";
// import { styled } from "@mui/material/styles";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import notifyService from "../../../../api/notifySerivce";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import downloadPDF from "../../../../hooks/downloadPDF";
import cryptoJS from "../../../../hooks/cryptoJS";
import MaskDialog from "../../Mask-Dialogbox/MaskDialog";
// import JsBarcode from 'jsbarcode';
// import QRCode from 'qrcode.react';
import Qrcode from "../Qr-Barcode/Qrcode";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CloseIcon from '@mui/icons-material/Close';
// import { Swiper, SwiperSlide } from "swiper/react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Eventsandbookings from "./Eventsandbookings";
import Logsandactivities from "./Logsandactivities";
import HistoryFilter from "./HistoryFilter";
import { FaArrowLeft } from "react-icons/fa6";
const HistoryCardPage = () => {
  const { navigator, validateNumberonly, setPageLoading, VisuallyHiddenInput, validatePhoneNumber, fullScreen, modalstyle,
    linkedInPattern, validateEmail,
    getApi, para, formData, handleOpenEdit, editBtn, setEditBtn, handleEditBtn, editKey, setEditKey, userProfileId, showViewbtn, handlemaskClickOpen, showUidNumber } = useContext(DataContext);


  const { handleSubmit, control, getValues, setValue, clearErrors, formState: { errors } } = useForm({ values: formData });
  const {
    fields: founderFields,
    append: founderAppend,
    remove: founderRemove,
  } = useFieldArray({
    control,
    name: "founders",
  });
  // var founder = getValues('founders')
  // if (founder) {
  //   setEditKey(founder.length)
  // } else {
  //   setEditKey(0)
  // }


  useDidMountEffect(() => {
    getApi();
  }, [userProfileId]);

  useDidMountEffect(() => {
    if (userProfileId === 0) {
      businessModelsdropdownApi();
      sectordropdownApi();
      DesignationApi()
      dropdownGenderApi()
    }
  }, []);
  const [businessModelList, setBusinessModelList] = useState([]);
  const businessModelsdropdownApi = () => {
    apiService(
      `admin/dropdownvalues/get?key=${"businessmodel"}`,
      "",
      "unauthget"
    )
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setBusinessModelList(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [sectors, setSectors] = useState([]);
  const sectordropdownApi = () => {
    apiService("home/sector/list", "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.sectors) {
              setSectors(result.data.sectors);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [designation, setDesignation] = useState([]);
  const DesignationApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"designation"}`, "", "get")
      .then((result) => {
        if (result && result.data) {
          if (result.data.designations) {
            setDesignation(result.data.designations);
            // designationsValue = result.data.designations
          }
        }
      })
      .catch((err) => { });
  };
  const [gender, setGender] = useState([]);
  const dropdownGenderApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"gender"}`, "", "unauthget")
      .then((result) => {

        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setGender(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };
  const [opens, setOpens] = useState(false);
  const handleReportOpen = () => {
    setOpens(true);
  };
  const handleReportClose = () => {
    setOpens(false);
  };

  const service = [
    "Company Incorporated Certificate",
    "PAN CARD",
    "Funding Projection",
    "BDD Report",
    "Community Certificate",
    "DPIIT Certificate",
    "IT & GST Certificate",
    "Others",
  ];
  const [activeButtons, setActiveButtons] = useState(null);
  const handleButtonClicks = (service) => {
    setActiveButtons(service);
  };


  useDidMountEffect(() => {
    setActiveButtons("Company Incorporated Certificate");
    handleButtonClick("TANSEED 1.0 Beneficiery");
    dropdownApi();
  }, []);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (service) => {
    setActiveButton(service);
    if (service === "Document Room") {
      setOpen(true);
    } else if (service === "Report") {
      handleReportOpen();
    } else if (service === 'History Card') {
      navigator(`/startup/history-card`)
    }
  };



  const [showFull, setShowFull] = useState(false);

  const toggleReadMore = () => {
    setShowFull(!showFull);
  };
  // const fullText = "Apple Inc. is a global Apple Inc. is a globalApple Inc. is a globalApple Inc. is a global";
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };
  const wordLimit = 30;
  const displayText = showFull ? para : truncateText(para, wordLimit);
  const isTruncated = para.split(" ").length > wordLimit;

  let name = localStorage.getItem("name");
  const supportPage = () => {
    navigator(`/startup/support/${name}/add`);
  };

  const [startupStages, setStartupStages] = useState([]);
  const dropdownApi = () => {
    apiService(`admin/dropdownvalues/get?key=${"stages"}`, "", "unauthget")
      .then((result) => {
        if (result) {
          if (result.data) {
            if (result.data.dropDownValues) {
              var dropDown = result.data.dropDownValues;
              setStartupStages(dropDown.values);
            }
          }
        }
      })
      .catch((err) => { });
  };


  // const [editBtn, setEditBtn] = useState(false);
  // const handleOpenEdit = () => {
  //   setEditBtn(true);
  // };
  // const [editKey, setEditKey] = useState("");
  // const handleEditBtn = (key) => {
  //   setEditKey(key);
  // };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileUrl) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpg" ||
            img_crt_type === "png" ||
            img_crt_type === "jpeg"
          ) {
            setValue(filekey, "");
            setValue(fileName, "");
            setValue(fileUrl, '')
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1)
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const handleSelectSector = (value) => {
    setValue("sector", value);
    clearErrors("sector");
  };

  const addFounderBtn = () => {
    var founder = getValues('founders')
    if (founder) {
      setEditKey(founder.length)
    } else {
      setEditKey(0)
    }
    // setEditKey(founder.length)
    if (founder.length > 0 && founder[founder.length - 1].emailVerify === true) {
      founderAppend({
        id: 0,
        type: localStorage.getItem("role"),
        name: "",
        email: "",
        phone: "",
        gender: "",
        designation: "",
        profile: "",
        profileName: "",
        profileUrl: "",
      });
    }

  };
  const founderDetailsRemove = (index) => {
    var id = getValues(`founders.${index}.id`);
    if (id !== 0) {
      apiService(`userprofile/founder/remove?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          founderRemove(index);
          if (index === 0) {
            founderAppend()
          }
          getApi()

        }
      }).catch((err) => {
      });
    } else {
      founderRemove(index);
    }

  };
  const handleSelectDesignation = (value, index) => {
    setValue(`founders.${index}.designation`, value);
    clearErrors(`founders.${index}.designation`);
  };

  const redirectUrl = localStorage.getItem('conferencePath');
  const onSubmit = (data) => {
    const sector = sectors.filter((val) => val.name === data.sector);
    data.sectorId = sector[0].id.toString();
    data.phone = cryptoJS(data.phone);
    data.email = cryptoJS(data.email);
    if (data.founders) {
      data.founders.forEach(founder => {
        if (founder.email) {
          founder.email = cryptoJS(founder.email);
        }
        if (founder.phone) {
          founder.phone = cryptoJS(founder.phone);
        }
      });
    }
    // data.phone = cryptoJS(data.phone)
    // data.email = cryptoJS(data.email)
    apiService("userprofile/save", data, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          if (editKey === "") {
            setEditBtn(false);
          } else {
            setEditKey("");
          }
          if (redirectUrl) {
            // navigator(`/${redirectUrl}`);
            window.location.href = redirectUrl
            localStorage.removeItem('conferencePath');
            debugger
          }
          getApi();
        }
      })
      .catch((err) => { });
  };
  const onError = (e) => {
    console.log(e)
  };

  const [qrModalopen, setQrModalOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const handleClickOpen = () => {
    setQrModalOpen(true);
  };

  const handleModalClose = () => {
    setQrModalOpen(false);
  };
  // const downloadBarcode = () => {
  //   const link = document.createElement('a');
  //   link.setAttribute('target', '_blank');
  //   link.setAttribute('href', formData.barcode);
  //   link.setAttribute('download', formData.barcode); // Set desired file name here
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // }
  // const downloadQrcode = () => {
  //   const link = document.createElement('a');
  //   link.setAttribute('target', '_blank');
  //   link.setAttribute('href', formData.qr);
  //   link.setAttribute('download', formData.qr); // Set desired file name here
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // }
  // var val = formData.uid
  const [emailVerify, setEmailVerify] = useState(null)
  const handleEmailVerfication = (value, index) => {
    setEmailVerify(null)
    if (value.length > 3) {
      var email = value.toLowerCase()
      apiService(`founder/email/verify?email=${cryptoJS(email)}`, '', 'get').then((result) => {
        if (result && result.data && result.data.responseStatus === 'Success') {
          setEmailVerify(true)
          setValue(`founders.${index}.emailVerify`, true)
        } else {
          setEmailVerify(false)
          notifyService('danger', 'Error', result.data.responseMessage)
          setValue(`founders.${index}.emailVerify`, false)
        }
      }).catch((err) => {
        setEmailVerify(null)
        setValue(`founders.${index}.emailVerify`, null)
      });
    }
  }

  const [historytabValue, setHistorytabValue] = useState("1");

  const handlehistoryChange = (event, newValue) => {
    setHistorytabValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/startup/profile`)
  }
  return (
    <div>
      <div className="container-fluid flu-class">
        <div className="bg-img"></div>
        <div className="user-profile">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="user-box">
              <div className="first-box">

                <div className="all-userdetails">
                  <div className="d-flex align-items-center">
                    {/* <div>
                  <img
                    className="user-brandlogo img-fluid"
                    src={formData.companyLogoURL}
                    alt="logo"
                  />
                </div> */}
                    <div>
                      {editKey === "imageName" ? (
                        <div className="form-group mt-2">
                          <div className="logo_div">
                            <img
                              src={getValues('imageUrl')}
                              className="user_logo"
                              alt={getValues('imageName')}
                            />
                            <div className="camera_icon">
                              <Controller
                                name="imageName"
                                control={control}
                                defaultValue=""
                                rules={{ required: `logo is required` }}
                                render={({ field }) => (
                                  <Button
                                    {...field}
                                    onChange={(e) =>
                                      handleFileUpload(e, "image", "imageName", "imageUrl")
                                    }
                                    component="label"
                                    startIcon={<CameraAltIcon />}
                                  >
                                    <VisuallyHiddenInput type="file" />
                                  </Button>
                                )}
                              />
                            </div>
                            <FormHelperText className="text-danger">
                              {errors.imageName && errors.imageName.errorMessage}
                            </FormHelperText>
                          </div>

                          <div className="img-btn-div">
                            <Button type="submit" sx={{ marginLeft: "10px" }}>
                              <DoneOutlineIcon
                                sx={{ fontSize: "16px", cursor: "pointer" }}
                              />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <span>
                          {" "}
                          <img
                            className="user-brandlogo img-fluid"
                            src={
                              formData.imageName
                                ? formData.imageName
                                : "/images/user.png"
                            }
                            alt="logo"
                          />
                          {editBtn === true && (
                            <EditIcon
                              sx={{
                                fontSize: "14px",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              onClick={() => handleEditBtn("imageName")}
                            />
                          )}
                        </span>
                      )}
                    </div>
                    <div className="brand-details ml-3">
                      {/* <h4 className="font-weight-bold">
                    {formData.startupName}
                    <span>
                      <img src={"/images/verified-tick.png"} alt="verified" />
                    </span>
                  </h4> */}
                      {editKey === "name" ? (
                        <div className="d-inline-block">
                          <div className="d-flex align-items-start">
                            <div>
                              <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                rules={{ required: `Name is required` }}
                                render={({ field }) => (
                                  <input
                                    placeholder="Enter name"
                                    type="text"
                                    {...field}
                                    className="sml-inp"
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.name && errors.name.message}
                              </FormHelperText>
                            </div>
                            <Button type="submit">
                              <DoneOutlineIcon
                                sx={{ fontSize: "16px", cursor: "pointer" }}
                              />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <span className="inv-prof-name">
                          <h4 className="font-weight-bold">
                            {formData.name}

                            <span>
                              <img
                                src={"/images/verified-tick.png"}
                                alt="verified"
                              />
                            </span>
                          </h4>
                          {editBtn === true && (
                            <EditIcon
                              sx={{
                                fontSize: "14px",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              onClick={() => handleEditBtn("name")}
                            />
                          )}
                        </span>
                      )}
                      <p className="para mb-0">
                        {editKey === "Sector" ? (
                          <div className="d-inline-block">
                            <div className="d-flex align-items-start">
                              <div>
                                <FormControl sx={{ width: 300 }}>
                                  <Controller
                                    name="sector"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({ field, fieldState: { error } }) => (
                                      <Autocomplete
                                        {...field}
                                        // multiple={data.multiple}
                                        options={sectors.map(
                                          (option) => option.name
                                        )}
                                        onChange={(event, newValue) =>
                                          handleSelectSector(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Sector *"
                                            placeholder="Select Sector"
                                            error={!!error}
                                            helperText={
                                              error ? "Sector is required" : ""
                                            }
                                          />
                                        )}
                                        fullWidth
                                        margin="normal"
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.sector && errors.sector.errorMessage}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                              <Button type="submit">
                                <DoneOutlineIcon
                                  sx={{ fontSize: "16px", cursor: "pointer" }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span>
                            <span>
                              {formData.sector && formData.sector}

                              {/* <span style={{ color: "gray" }}>& More</span> */}
                            </span>

                            {editBtn === true && (
                              <EditIcon
                                sx={{ fontSize: "14px", cursor: "pointer" }}
                                onClick={() => handleEditBtn("Sector")}
                              />
                            )}
                          </span>
                        )}
                        |
                        {editKey === "businessModel" ? (
                          <div className="d-inline-block">
                            <div className="d-flex align-items-start">
                              <div>
                                <FormControl sx={{ width: 150 }}>
                                  <Controller
                                    name="businessModel"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: true }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <Autocomplete
                                        options={businessModelList}
                                        getOptionLabel={(option) => option}
                                        defaultValue={[]}
                                        value={value || []}
                                        onChange={(event, newValue) =>
                                          onChange(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Business Model *"
                                            placeholder="Seletct Business Model"
                                            error={!!error}
                                            helperText={
                                              error
                                                ? "Business Model is required"
                                                : ""
                                            }
                                          />
                                        )}
                                        fullWidth
                                        margin="normal"
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.businessModel &&
                                      errors.businessModel.message}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                              <Button type="submit">
                                <DoneOutlineIcon
                                  sx={{ fontSize: "16px", cursor: "pointer" }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span>
                            {" "}
                            {formData.businessModel}{" "}
                            {editBtn === true && (
                              <EditIcon
                                sx={{ fontSize: "14px", cursor: "pointer" }}
                                onClick={() => handleEditBtn("businessModel")}
                              />
                            )}
                          </span>
                        )}{" "}
                        |{" "}
                        {editKey === "currentStage" ? (
                          <div className="d-inline-block">
                            <div className="d-flex align-items-start">
                              <div>
                                <FormControl sx={{ width: 150 }}>
                                  <Controller
                                    name="currentStage"
                                    control={control}
                                    defaultValue=""
                                    fullWidth
                                    rules={{
                                      required: `Current Stage is required`,
                                    }}
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <Autocomplete
                                        size="small"
                                        disableClearable
                                        disablePortal
                                        options={startupStages}
                                        // getOptionLabel={(option) => option}
                                        // defaultValue={[]}
                                        value={value || []}
                                        onChange={(event, newValue) =>
                                          onChange(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Current Stage *"
                                            placeholder="Seletct Current Stage"
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.currentStage &&
                                      errors.currentStage.message}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                              <Button type="submit">
                                <DoneOutlineIcon
                                  sx={{ fontSize: "16px", cursor: "pointer" }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span>
                            {formData.currentStage}{" "}
                            {editBtn === true && (
                              <EditIcon
                                sx={{ fontSize: "14px", cursor: "pointer" }}
                                onClick={() => handleEditBtn("currentStage")}
                              />
                            )}
                          </span>
                        )}
                      </p>
                      <span style={{ fontSize: "12px", color: "gray" }}>
                        {formData.state}, {formData.country}
                      </span>
                      {/* <div className="d-flex align-items-center">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      style={{ width: "80%", backgroundColor: "#020671" }}
                      aria-valuenow="80"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <span id="progressValue">80%</span>
                </div> */}
                      {/* <div style={{ position: "relative" }} className="profile-progress">
                  <BorderLinearProgress>
                    <ProgressFill value={progress} />
                  </BorderLinearProgress>
                  <span className="ml-2">80%</span>
                </div>
                <span className="Profile-complete">Profile Completion</span> */}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <img
                        className="user-cardlogo"
                        src={"/images/debitCrd.png"}
                        alt="bankCards"
                      />
                    </div>
                    <div className="brand-details ml-3">
                      <p className="par m-0">SmartCard No.</p>

                      <span
                        style={{
                          color: "#020671",
                          fontSize: "16px",
                          fontWeight: "600",
                          cursor: 'pointer'
                        }}
                        onClick={handleClickOpen}
                      >
                        {formData.uid}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="mr-2">
                        <span style={{ fontSize: "10px" }}>
                          Engagement Level :
                          <span
                            style={{
                              color: "#020671",
                              fontWeight: "600",
                              fontSize: "12px",
                            }}
                          >
                            ACE
                          </span>
                        </span>
                      </div>
                      <div>
                        <span style={{ fontSize: "10px" }}>
                          Member Since :
                          <span
                            style={{
                              color: "#020671",
                              fontWeight: "600",
                              fontSize: "12px",
                            }}
                          >
                            {formData.memberSince}
                          </span>
                        </span>
                      </div>
                    </div>
                    {/* <div className="det-flex mt-3">
                  <div className="mobile-resp">
                    <div>
                      <FaPhoneAlt className="ml-2" style={{
                        color: "#020671",
                      }} />
                    </div>
                    <div>
                      <span className="ml-2" style={{ fontSize: "14px" }}>+91 {formData.phone}</span>
                    </div>
                  </div>
                  <div className="mobile-resp">
                    <div>
                      <MdOutlineMail className="ml-2" style={{
                        color: "#020671",
                      }} />
                    </div>
                    <div>
                      <span className="ml-2" style={{ fontSize: "14px" }}>{formData.email}</span>
                    </div>
                  </div>
                  <div className="mobile-resp">
                    <div>
                      <CiGlobe className="ml-2" style={{
                        color: "#020671",
                      }} />
                    </div>

                    <div>
                      <span className="ml-2" style={{ fontSize: "14px" }}>{formData.website}</span>
                    </div>
                  </div>
                </div> */}
                    <div className="det-flex mt-3">
                      <div className="mobile-resp">
                        <span className="ml-2" style={{ fontSize: "14px" }}>
                          {editKey === "linkedIn" ? (
                            <div className="d-inline-block">
                              <div className="d-flex align-items-start">
                                <div>
                                  <FormControl sx={{ width: 150 }}>
                                    <Controller
                                      name="linkedIn"
                                      control={control}
                                      defaultValue=""
                                      rules={{ value: linkedInPattern, message: "Invalid LinkedIn profile URL" }}
                                      render={({ field }) => (
                                        <TextField
                                          variant="outlined"
                                          label="LinkedIn"
                                          placeholder="Enter LinkedIn"
                                          fullWidth
                                          type="text"
                                          {...field}
                                        />
                                      )}
                                    />
                                    <FormHelperText className="text-danger">
                                      {errors.linkedIn && errors.linkedIn.message}
                                    </FormHelperText>
                                  </FormControl>
                                </div>
                                <Button type="submit">
                                  <DoneOutlineIcon
                                    sx={{
                                      fontSize: "16px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <span>
                              <a
                                href={formData.linkedIn}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img src="/images/linkedin-logo.png" alt="linkedin logo" />
                              </a>
                              {/* {formData.linkedIn}{" "} */}
                              {editBtn === true && (
                                <EditIcon
                                  sx={{
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleEditBtn("linkedIn")
                                  }
                                />
                              )}
                            </span>
                          )}
                        </span>
                      </div>
                      {editKey === "phone" ? (
                        <div className="d-inline-block">
                          <div className="d-flex align-items-start">
                            <div>
                              <FormControl sx={{ width: 150 }}>
                                <Controller
                                  name="phone"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: `Phone is required`,
                                    maxLength: {
                                      value: 10,
                                      validate: validatePhoneNumber,
                                    },
                                    minLength: {
                                      value: 10,
                                      validate: validatePhoneNumber,
                                    },
                                  }}
                                  render={({ field }) => (
                                    <TextField
                                      variant="outlined"
                                      label="Phone Number *"
                                      placeholder="Enter Phone Number"
                                      fullWidth
                                      type="text"
                                      {...field}
                                      onKeyDown={(e) => validateNumberonly(e)}
                                      inputProps={{ maxLength: 10 }}
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.phone && errors.phone.message}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            <Button type="submit">
                              <DoneOutlineIcon
                                sx={{ fontSize: "16px", cursor: "pointer" }}
                              />
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <span>
                          <FaPhoneAlt
                            className="mx-1"
                            style={{
                              color: "#020671",
                            }}
                          />
                          {
                            showViewbtn !== 0 ? (
                              <span className="blur_css">+91 XXXXX-XXXXX</span>
                            ) : (
                              <span>+91 {formData.phone}</span>
                            )
                          }

                          {editBtn === true && (
                            <EditIcon
                              sx={{
                                fontSize: "14px",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              onClick={() => handleEditBtn("phone")}
                            />
                          )}
                        </span>
                      )}
                      <div className="mobile-resp">
                        <div>
                          <MdOutlineMail
                            className="ml-2"
                            style={{
                              color: "#020671",
                            }}
                          />
                        </div>
                        <div>
                          {
                            showViewbtn !== 0 ? (
                              <span className="blur_css ml-2" style={{ fontSize: "14px" }}>XXXXXXXXXXX</span>
                            ) : (
                              <span className="ml-2" style={{ fontSize: "14px" }}>{formData.email}</span>
                            )
                          }
                        </div>
                      </div>
                      <div className="mobile-resp">
                        <div>
                          <CiGlobe
                            className="ml-2"
                            style={{
                              color: "#020671",
                            }}
                          />
                        </div>
                        {editKey === "website" ? (
                          <div className="d-inline-block">
                            <div className="d-flex align-items-start">
                              <div>
                                <FormControl sx={{ width: 150 }}>
                                  <Controller
                                    name="website"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: `Website is required`,
                                    }}
                                    render={({ field }) => (
                                      <input
                                        placeholder="Enter Website"
                                        type="text"
                                        {...field}
                                        className="sml-inp"
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.website && errors.website.message}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                              <Button type="submit">
                                <DoneOutlineIcon
                                  sx={{
                                    fontSize: "16px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <span className="ml-2">
                            {" "}
                            {formData.website}{" "}
                            {editBtn === true && (
                              <EditIcon
                                sx={{
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditBtn("website")}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                    {showViewbtn !== 0 && (
                      <div className="text-right mt-3">
                        <Button
                          variant="outlined"
                          className="edit-btn-profile"
                          sx={{
                            textTransform: "none",
                            border: "1px solid",
                            borderColor: "#020671",
                            color: "#020671",
                            padding: "6px 30px",
                          }}
                          onClick={handlemaskClickOpen}
                        >
                          View Contact
                        </Button>
                      </div>
                    )}
                    {(userProfileId === 0) && (
                      <div className="profile-btn  mt-3">
                        {/* <div className="mr-3 edit-btn-res">
                          {editBtn === true && (
                            <Button
                              variant="outlined"
                              className="edit-btn-profile"
                              sx={{
                                textTransform: "none",
                                border: "1px solid",
                                borderColor: "#020671",
                                color: "#020671",
                                padding: "6px 20px",
                              }}
                              type="submit"
                            >
                              Save Profile
                            </Button>
                          )}
                          {(editBtn === false) && (
                            <Button
                              variant="outlined"
                              className="edit-btn-profile"
                              sx={{
                                textTransform: "none",
                                border: "1px solid",
                                borderColor: "#020671",
                                color: "#020671",
                                padding: "6px 20px",
                              }}
                              onClick={handleOpenEdit}
                            >
                              Edit Profile
                            </Button>
                          )}
                        </div> */}
                        <>
                          <Button
                            onClick={supportPage}
                            variant="contained"
                            sx={{
                              color: "#fff",
                              fontSize: "14px",
                              background: "#020671",
                              textTransform: "none",
                              "&:hover": { backgroundColor: "#020671" },
                            }}
                          >
                            Raise Support Ticket
                          </Button>
                        </>
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <div className="mt-2">
                <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={5}>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Button className="back-btns" onClick={handleBackBtn}>
                      <FaArrowLeft />
                      <span style={{ marginLeft: "5px" }}>Back</span>
                    </Button>
                    <div className="history-crd mt-2">
                      <h4 className="histry-tit">History Card</h4>
                    </div>
                    <div className="histry-crd-info-div">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="histry-crd-info mt-3">
                            <h3>15hrs</h3>
                            <p>Total Engagement Hours</p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="histry-crd-info mt-3">
                            <h3>18</h3>
                            <p>No. of Interventions</p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="histry-crd-info mt-3">
                            <h3>20L</h3>
                            <p>Revenue Generated (in INR)</p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="histry-crd-info mt-3">
                            <h3>17K</h3>
                            <p>Amount Saved (in INR)</p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="histry-crd-info mt-3">
                            <h3>15L</h3>
                            <p> Fund Raised So Far</p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="histry-crd-info mt-3">
                            <h3>15</h3>
                            <p>  Events / Webinar Attended </p>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <div className="histry-crd-info mt-3">
                            <h3><img src={'/images/historypage/star.png'} alt="..." style={{ width: '17px', marginRight: '5px' }} />4.5<span style={{ color: '#000', fontSize: '12px' }}>/5</span></h3>
                            <p>Ratings</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                    <div className="tab-div-history mt-5">
                      <div style={{ width: '100%', marginTop: "35px" }} >
                        <TabContext value={historytabValue}>
                          <Box sx={{ borderBottom: 0, borderColor: "transparent" }}>
                            <TabList
                              onChange={handlehistoryChange}
                              aria-label="lab API tabs example"
                            >
                              <Tab label="Events & Bookings" value="1" style={{
                                backgroundColor: historytabValue === "1" ? "#020671" : "white",
                                color: historytabValue === "1" ? "white" : "#000",
                                borderRadius: '10px 10px 0px 0px',
                                padding: '0px 22px',
                                height: '40px',
                                fontSize: '14px',
                                textTransform: "none",
                              }} />
                              <Tab label="Logs & Activities" value="2" style={{
                                backgroundColor: historytabValue === "2" ? "#020671" : "white",
                                color: historytabValue === "2" ? "white" : "#000",
                                borderRadius: '10px 10px 0px 0px',
                                padding: '0px 22px',
                                height: '40px',
                                fontSize: '14px',
                                textTransform: "none",
                              }} />
                            </TabList>
                          </Box>
                          <TabPanel value="1" sx={{ padding: '0px' }}>
                            <div className="mt-3">
                              <HistoryFilter />
                            </div>
                            <Eventsandbookings />
                          </TabPanel>
                          <TabPanel value="2" sx={{ padding: '0px' }}>
                            <div className="mt-3">
                              <HistoryFilter />
                            </div>
                            <Logsandactivities />
                          </TabPanel>
                        </TabContext>
                      </div>
                    </div>
                  </Grid>

                </Grid>
              </div>
            </div>
          </form>
          {/* Qr-modal */}
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={qrModalopen}
            onClose={handleModalClose}
          >

            <DialogContent>

              <Qrcode />
            </DialogContent>
          </Dialog>
          <div>
            <MaskDialog />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryCardPage