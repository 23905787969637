import { Button } from '@mui/material'
import React from 'react'

const DepartmentDetails = ({ handleDepartmentClose }) => {
    return (
        <div>
            <img src='/images/startup-department.png' alt='' className='img-fluid'/>
            <div className='text-center mb-3'>
                <Button onClick={handleDepartmentClose} className="publish-button">Close</Button>
            </div>
        </div>
    )
}

export default DepartmentDetails