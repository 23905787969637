import { Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { MuiFileInput } from "mui-file-input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import notifyService from "../../../../api/notifySerivce";
import Viewimage from "../../../../api/Viewimage";

const Documents = () => {
  const { navigator, setPageLoading, VisuallyHiddenInput, checkAlphanumeric, handleNextClick,isWhitespace,validateCinOrPanNo,validatePANNumber } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, getValues, clearErrors, formState: { errors }, } = useForm({ values: formData });
  const role = localStorage.getItem("role");
  useDidMountEffect(() => {
    handleNextClick(67);
    getApi();
  }, []);

  const getApi = () => {
    setPageLoading(true)
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
        }
        if (data.pitchdeckUrl) {
          data.pitchdeckName = data.pitchdeckUrl
        }
      })
      .catch((err) => { });
  };


  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName) => {
    if (event !== null) {
      // const logoselecetdFile = event.target ? event.target.files[0] : event;
      // if (logoselecetdFile) {
      //   if (logoselecetdFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
      //     notifyService(
      //       "danger",
      //       "File Size Exceeded",
      //       `Maximum file size allowed is ${MAX_FILE_SIZE_MB} MB`
      //     );
      //     return;
      //   }
      // }
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (img_crt_type === "pdf") {
            setValue(filekey, "");
            setValue(fileName, "");
            // setValue(fileUrl, '')
            // setName("");
            // setUrl("");
            // if (event.target === undefined) {
            //   setdata(event);
            // } else {
            //   setdata(event.target.files[0]);
            // }
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1);
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              // setValue(fileUrl, logourl1)
              // setName(img_name);
              clearErrors(filekey);
              clearErrors(fileName);
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const [fileLable, setFileLable] = useState("");
  const handleClickOpen = (value, name, lable) => {
    setViewImage(value);
    setViewImageName(name);
    setFileLable(lable);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    setPageLoading(true)
    console.log("Form Data:", data);
    
    apiService("userprofile/save", data, "post")
      .then((result) => {
        setPageLoading(false)
        if (result && result.data && result.data.responseStatus === "Success") {
          navigator("/startups/moreinfo");
        }
      })
      .catch((err) => { });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">Documents</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">4</span>/5
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  {role === "NON-DPIIT" ? (
                    // <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    //   <Controller
                    //     name="dpiitNo"
                    //     control={control}
                    //     defaultValue=""
                    //     // rules={{
                    //     //   required: "DPIIT Registration No. is required",
                    //     // }}
                    //     rules={{
                    //       required: 'DPIIT Registration No is required', validate: {
                    //           noWhitespace: value => !isWhitespace(value) || 'Whitespace not allowed'
                    //       },
                  
                    //   }}
                    //     render={({ field }) => (
                    //       <TextField
                    //         variant="outlined"
                    //         label="DPIIT Registration No. *"
                    //         placeholder="Enter DPIIT Registration No."
                    //         fullWidth
                    //         type="text"
                    //         {...field}
                    //         error={Boolean(errors.dpiitNo && errors.dpiitNo)}
                    //       />
                    //     )}
                    //   />
                    //   <FormHelperText className="text-danger">
                    //     {errors.dpiitNo && errors.dpiitNo.message}
                    //   </FormHelperText>
                    // </Grid>
                    
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="dpiitCertifiedSupport"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Do you want our support to get DPIIT Certified?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              {...field}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  
                  
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="gstNumber"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: false, 
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="GST No."
                          placeholder="Enter GST No"
                          fullWidth
                          type="text"
                          inputProps={{ maxLength: 21}}
                          onKeyDown={(e) => checkAlphanumeric(e)}
                          {...field}
                          error={Boolean(errors.gstNumber && errors.gstNumber)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.gstNumber && errors.gstNumber.message}
                    </FormHelperText>
                    
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="gstAuthorizedName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: false,                       
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="GST Authorized Name"
                          placeholder="Enter Authorized Name"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.gstAuthorizedName && errors.gstAuthorizedName)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.gstAuthorizedName && errors.gstAuthorizedName.message}
                    </FormHelperText>
                    
                  </Grid>
                  {/* {role !== "DPIIT" && (
                   
                  )} */}

                  {/* {role === "DPIIT" ? (
                    <></>
                  ) : (
                    <> */}
                      <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                        {getValues('pitchdeckUrl') ? (
                          <>
                            <div className="px-3">
                              <p className="mb-0">Pitch Deck </p>
                              <div className="row">
                                <Grid
                                  item
                                  lg={7}
                                  md={7}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Controller
                                    name="pitchdeckName"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "pitchDeck File is required" }}
                                    render={({ field }) => (
                                      <Button
                                        component="label"
                                        variant="contained"
                                        {...field}
                                        fullWidth
                                        onChange={(e) =>
                                          handleFileUpload(e, "pitchdeck", "pitchdeckName")
                                        }
                                        startIcon={<CloudUploadIcon />}
                                        href="#file-upload"
                                      >
                                        Change
                                        <VisuallyHiddenInput type="file" />
                                      </Button>
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  lg={5}
                                  md={5}
                                  sm={12}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Button
                                    className="c-pointer ml-2"
                                    size="medium"
                                    fullWidth
                                    variant="outlined"
                                    onClick={() =>
                                      handleClickOpen(
                                        getValues("pitchdeck"),
                                        getValues("pitchdeckUrl"),
                                      )
                                    }
                                  >
                                    Preview
                                  </Button>
                                </Grid>
                              </div>
                            </div>
                            <div>
                              <span className="font-sm d-flex  mt-1 ">
                                Maximum 5 mb allowed doc (Accepted Format PDF){" "}
                                <span className="text-danger">*</span>
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="form-group mt-2">
                            <Controller
                            name="pitchdeckName"
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <TextField
                                variant="outlined"
                                label="Pitch Deck "
                                placeholder="Upload your Pitch Deck"
                                fullWidth
                                type="text"
                                {...field}
                                disabled
                                error={Boolean(errors.pitchdeckName && errors.pitchdeckName)}
                              />
                            )}
                          />
                           <FormHelperText className="text-danger">
                              {errors.pitchdeckName && errors.pitchdeckName.message}
                            </FormHelperText>
                          <div className='d-flex align-items-center w-100 my-3'>
                            <p className='font-sm'>Maximum 5 mb allowed doc <br />(Accepted Format PDF)</p>
                            <label className='ml-auto lbl_btn'
                              onChange={(e) => handleFileUpload(e, "pitchdeck", "pitchdeckName")}
                              href="#file-upload">
                              <img src='/images/admin/upload.png' className='mr-3' alt='' />
                              Upload PDF
                              <VisuallyHiddenInput type="file" />
                            </label>
                          </div>
                          </div>
                        )}
                      </Grid>
                    {/* </>
                  )
                  } */}

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="text-center"
                  >
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
              <Viewimage
                viewImage={viewImage}
                viewImageName={viewImageName}
                open={open}
                handleClose={handleClose}
                fileLable={fileLable}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Documents;
