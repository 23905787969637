import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Retailtech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === "initiatives" ? "1" : "2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div
          className="bg-img-tabss"
          style={{
            background: `url('/images/sector-images/RetailTech.png')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h3 className="banner-title-sector">Retail Tech, D2C</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"
                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: "1px solid lightgray",
                        },
                      }}
                    />
                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Digital Payments & Technology
                  </h5>
                  <br />
                  <p>
                    The government is backing technologies such as UPI, RuPay,
                    DigiLocker and eKYC to help promote digital transactions,
                    increase adoption in smaller cities, as well drive
                    innovation in this space. In the Budget 2023-24, it was
                    declared that in 2022, digital payments showed an increase
                    of 76% in transactions and 91% in value. Further, it was
                    announced that fiscal support of Rs 1,500 crore for this
                    digital public infrastructure will continue in 2023-24.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>Digital Infrastructure</h5>
                  <br />
                  <p>
                    E-commerce is set to benefit from government initiatives to
                    boost rural broadband penetration within the ambit of
                    Digital India. BharatNet is one such programme, with an
                    outlay of Rs 61000 crore, aimed to bring broadband
                    connectivity to India’s 2,50,000 gram panchayats
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    One District One Product Programme
                  </h5>
                  <br />
                  <p>
                    The government has launched the ODOP programme with the aim
                    of selecting, branding and promoting One Product from each
                    district of the country to enable holistic socioeconomic
                    growth across all regions.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>
                    Consumer Protection (E-Commerce) Rules, 2020
                  </h5>
                  <br />
                  <p>
                    These rules delineate roles and responsibilities for
                    marketplace and sellers and reinforce competition and
                    efficiency within the Indian e-commerce market
                  </p>
                  <h5 style={{ color: "#39364e" }}>
                    Open Network for Digital Commerce (ONDC)
                  </h5>
                  <br />

                  <p>
                    An open network protocol for e-commerce in India, ONDC, is
                    an initiative backed by DPIIT, Ministry of Commerce and
                    Industry. Its aim is to change the market from an
                    operator-driven ecosystem to a facilitator-driven network.
                    Presently, it is available in 273 cities and has 35,000
                    sellers on the network.
                  </p>
                  <br />
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <br />

                  <p>
                    Tamil Nadu Single Window Portal: This is a one-stop shop for
                    obtaining all business-related licences/clearances/approvals
                    in a time-bound manner.
                  </p>
                  <br />
                  <p>
                    Tamil Nadu Skill Development Corporation offers skilling
                    programmes to enhance the employability of youth and match
                    the expectations of the industry.
                  </p>
                  <br />
                  <p>
                    Fame TN has signed an agreement with Flipkart/Walmart for
                    promotion of e-commerce among MSMEs through a supplier
                    development programme. The objective is to expand the
                    capabilities of MSMEs through intensive training and support
                    and equip them to scale up their businesses to be part of
                    domestic and global supply chains.
                  </p>
                  <br />
                  <p>
                    Fame TN has also entered into an understanding with the
                    Indo-German Chamber of Commerce for collaboration between
                    MSMEs in Tamil Nadu and businesses in Germany to facilitate
                    interaction, linkages, technology collaboration and export
                    opportunities.
                  </p>
                  <br />
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <br />
                  <p>
                    Several deemed universities and colleges offer courses in
                    retail management. Retailers Association of India is an
                    advocate for retailing in India and operates with the
                    objective of supporting employment growth and career
                    opportunities in retail, promoting and sustaining retail
                    investments and enhancing consumer choice and industry
                    competitiveness.
                  </p>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Retailtech;
