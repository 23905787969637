import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Tabs,
  TextField,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { BiSearchAlt } from "react-icons/bi";
import { MdArrowDropDownCircle } from "react-icons/md";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Chip from "@mui/material/Chip";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaRegCalendar } from "react-icons/fa6";
import { IoIosList } from "react-icons/io";
import { FiGrid } from "react-icons/fi";

const InvestorStartupFilters = ({ name }) => {
  const [value, setValue] = useState("1");
  const [searchInputValue, setSearchInputValue] = useState();
  const [showForm, setShowForm] = useState(false);
  const [sectorId, setSectorId] = useState();
  const [activeButtonMain, setActiveButtonMain] = useState("secs-btn-1");

  const handleButtonClicks = (btnClass3) => {
    setActiveButtonMain(btnClass3);
  };

  useDidMountEffect(() => {
    sectorsdropdownApi();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSearch = (e) => {
    setSearchInputValue(e.target.value);
  };
  const [sectors, setSectors] = useState([]);
  const sectorsdropdownApi = () => {
    apiService(`home/sector/list`, "", "get")
      .then((result) => {
        if (result) {
          if (result.data) {
            setSectors(result.data.sectors);
          }
        }
      })
      .catch((err) => {});
  };

  const [sectorValue, setSectorValue] = useState("");
  const [selectSect, setSelectSect] = useState(false);
  const handleSelectSector = (e, name) => {
    setSectorValue(name);
    var value = sectors.filter((option) => option.name === name);
    setSectorId(value[0].id);
    setSelectSect(true);
  };

  const handleDeleteSector = () => {
    setSectorValue("");
    setSectorId(0);
    setSelectSect(false);
  };

  return (
    <div>
      <TabContext value={value} className="tab-div">
        <div className="inv-name-tab">
          <Box className="inv-tabs-boxs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className="scroll-inv-tabs"
            >
              <NavLink
                to={"/investor/startup/assigned"}
                className="profile-tab-sty"
              >
                Assigned Startups
              </NavLink>

              <NavLink
                to={"/investor/startup/portfolio"}
                className="profile-tab-sty"
              >
                Portfolio Startups
              </NavLink>

              <NavLink
                to={"/investor/startup/ddPhase"}
                className="profile-tab-sty"
              >
                DD Phase
              </NavLink>

              <NavLink
                to={"/investor/startup/iccohorts"}
                className="profile-tab-sty"
              >
                IC Cohorts
              </NavLink>

              <NavLink
                to={"/investor/startup/my-favourites"}
                className="profile-tab-sty"
              >
                My Favourites
              </NavLink>
            </Tabs>
          </Box>
        </div>
      </TabContext>

      <div className="profile-box2 mt-3">
        <div className="inv-dynamic-text">
          <div>
            {name === "assignedStartups" && (
              <h5>Assigned Startups (TANFUND)</h5>
            )}
            {name === "portfoliostartups" && <h5>Portfolio Startups</h5>}
            {name === "DDphase" && <h5>DD Phase</h5>}
            {name === "Iccohorts" &&  (
              <h5>IC Cohorts</h5>
            )}
            {name === "Iccohortslist" &&  (
              <h5>IC Cohorts 1</h5>
            )}
            {name === "myfavourites" && <h5>My Favourites</h5>}
          </div>
          <div>
            {name !== "Iccohorts" &&
              name !== "assignedStartups" &&
              name !== "Iccohortslist" && (
                <Button variant="contained" className="inv-filter-butns">
                  + Add Startups
                </Button>
              )}
            {name === "Iccohortslist" && (
              <>
                <div className="icc-details">
              
                  <p>
                    <span>Created by : </span>Sai Ranganathan StartupTN
                  </p>
                  <div className="icc-subhead-dis">
                    <p>
                      <span>Updated :</span> 04 May, 2024
                    </p>
                    <p className="blue-sub-icc ml-5">
                      <span>Total :</span> 33 Startups
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {name !== "Iccohorts" && name !== "Iccohortslist" && (
          <div className="inv-startup-filters-divs mt-3">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                {/* <div className="search-bar-inv-prof">
                  <div className="gray-back-search-inv-prof ">
                    <span>
                      <BiSearchAlt
                        style={{ fontSize: "18px", marginLeft: "5px" }}
                      />
                      <span className="eco-search"> Search Startups</span>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="search-input"
                    onChange={handleSearch}
                  />
                </div> */}
                   <div class="search-bar">
        <BiSearchAlt className="upd-search-eco"/> <input type="text" class="search-input" onChange={handleSearch} placeholder="Search Keyword" autoFocus={true}/>
      </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <div className="list-item-calender-button">
                  {name !== "assignedStartups" &&
                    name !== "Iccohorts" &&
                    name !== "Iccohortslist" && (
                      <div className="frt-fil">
                        <div className="all-btn-bg">
                          <button
                            className={`secs-btn-1 ${
                              activeButtonMain === "secs-btn-1" ? "active" : ""
                            }`}
                            onClick={() => handleButtonClicks("secs-btn-1")}
                          >
                            <span>
                              <IoIosList className="lists-icons" />
                            </span>
                            List
                          </button>
                          <button
                            className={`secs-btn-2 ${
                              activeButtonMain === "secs-btn-2" ? "active" : ""
                            }`}
                            onClick={() => handleButtonClicks("secs-btn-2")}
                          >
                            <span>
                              <FiGrid className="calandar-icons" />
                            </span>
                            Card View
                          </button>
                        </div>
                      </div>
                    )}

                  <div className="filter-btns c-pointer ml-5">
                    <span>
                      <img
                        src={"/images/filtericn.png"}
                        alt="filtericn"
                        onClick={() => setShowForm(!showForm)}
                      />
                    </span>
                    <p
                      className="filter-text"
                      onClick={() => setShowForm(!showForm)}
                    >
                      Filters
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className="mt-3">
              {showForm && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      value={sectorValue}
                      options={sectors.map((option) => option.name)}
                      popupIcon={<MdArrowDropDownCircle />}
                      onChange={(e, selectedOption) =>
                        handleSelectSector(e, selectedOption)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sector"
                          placeholder="Sector"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      value={sectorValue}
                      options={sectors.map((option) => option.name)}
                      popupIcon={<MdArrowDropDownCircle />}
                      onChange={(e, selectedOption) =>
                        handleSelectSector(e, selectedOption)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=" Industry"
                          placeholder="Choose Industry"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Autocomplete
                      disableClearable
                      disablePortal
                      id="combo-box-demo"
                      value={sectorValue}
                      options={sectors.map((option) => option.name)}
                      popupIcon={<MdArrowDropDownCircle />}
                      onChange={(e, selectedOption) =>
                        handleSelectSector(e, selectedOption)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Current Growth Stage"
                          placeholder="Select Current Growth Stage"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </div>

            <div className="inv-chip-section mt-3">
              <div className="section-flex-inv">
                {sectorValue && (
                  <>
                    <div>
                      <Chip
                        label={sectorValue}
                        onDelete={handleDeleteSector}
                        deleteIcon={
                          <RiCloseCircleFill style={{ color: "#fff" }} />
                        }
                        sx={{ background: "#0d1232", color: "#fff" }}
                      />
                    </div>
                    <div className="inv-chip-selection">
                      <p>
                        Showing Results : <span>32 </span>
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvestorStartupFilters;
