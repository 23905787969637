import React, { useContext, useState } from 'react'
import OtpInput from 'react-otp-input';
import Timer from './Timer';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import DataContext from '../../../../context/DataContext';
import { useForm } from 'react-hook-form';
import { FormHelperText, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import cryptoJS from '../../../../hooks/cryptoJS';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
// import Delegatefreepass from '../../../home-module/home-page/Tn_fest/PassDialog/Delegatefreepass';
const Verifyotp = () => {
    const { navigator, handleNextClick, setPageLoading, setProfileDialogBox } = useContext(DataContext)

    const [formData, setFormData] = useState({});
    const { handleSubmit, control, setValue, watch, setError, clearErrors, formState: { errors }, } = useForm({ values: formData });
    const [otp, setOtp] = useState('');
    const [formValidate, setFormValidate] = useState(false)
    useDidMountEffect(() => {
        handleNextClick(15)
    }, [])
    const handleCheckotp = (value) => {
        if (value.length === 6) {
            setPageLoading(true);
            setFormValidate(false);
            setOtp(value);
            var getform = JSON.parse(localStorage.getItem('logDetail'));
            var req = {
                "email": getform.email,
                "otp": cryptoJS(value)
            }
            apiService(`user/register/verify`, req, 'unauthpost').then((res) => {
                setPageLoading(false);
                responseCode(res)
                if (res && res.data) { }
                else {
                    setFormValidate(true);
                }
            });
        } else {
            setFormValidate(true);
            setOtp(value);
        }
    };
    const onSubmit = (data) => {
        if (otp.length === 6) {
            setPageLoading(true);
            setFormValidate(false)
            var getform = JSON.parse(localStorage.getItem('logDetail'));
            var req = {
                "email": getform.email,
                "otp": cryptoJS(data.otp)
            }
            apiService('user/register/verify', req, 'unauthpost').then((res) => {
                setPageLoading(false);
                responseCode(res)
            })
        } else {
            setFormValidate(true)
        }
    };
    const responseCode = (res) => {
        if (res && res.data) {
            var type = localStorage.getItem('type')
            var name = res.data.firstName + `${res.data.lastName ? res.data.lastName : ''}`
            localStorage.setItem('jwttoken', res.data.token)
            localStorage.setItem('id', res.data.id)
            localStorage.setItem('profileId', res.data.id)
            localStorage.setItem('profile', res.data.profileUpdated ? res.data.profileUpdated : false)
            localStorage.setItem('role', res.data.role)
            localStorage.setItem('privileges', JSON.stringify(res.data.privileges))
            localStorage.setItem('name', name)
            localStorage.setItem('email', res.data.email)
            if (type === 'STARTUP') {
                navigator('/startups/basicinfo')
            } else if (type === 'ASPIRANT') {
                navigator('/aspirants/basicinfo')
            } else if (type === 'MENTOR') {
                navigator('/mentors/basicinfo')
            } else if (type === 'INNOVATOR') {
                navigator('/innovators/basicinfo')
            } else if (type === 'INVESTOR') {
                navigator('/investor/basicinfo')
            } else if (type === 'STARTUP-ECO') {
                navigator('/ecosystem/basicinfo')
            } else if (type === 'STARTUPS-SERVPROV') {
                navigator('/service-provider/basicinfo')
            }

        }
    }
    const onError = (event) => {
        console.log('error Data:', event);
    }
    const [seconds, setSeconds] = useState(60);
    const resenOtp = () => {
        var gmail = localStorage.getItem('emailenc')
        if (gmail === null) {
            setOtp('')
            var getform = JSON.parse(localStorage.getItem('logDetail'))
            apiService(`user/register/resend/otp`, getform, 'unauthpost').then((res) => {
                if (res) {
                    if (res.data.responseStatus === "Success") {
                        localStorage.setItem('logDetail', JSON.stringify(getform))
                        notifyService('success', 'Success', 'OTP resent successfully !!')
                        setSeconds(60)
                    }
                }
            })
        } else {
            setPageLoading(true)
            var req = {
                email: localStorage.getItem('emailenc')
            }
            apiService('password/reset', req, "unauthpost").then((res) => {
                setPageLoading(false)
                if (res) {
                    if (res.data) {
                        localStorage.setItem('logDetail', JSON.stringify(getform))
                        notifyService('success', 'Success', 'OTP resent successfully !!')
                        setSeconds(120)
                    }
                }
            });
        }

    }
    return (
        <div>
            <Grid container spacing={1}>
                {/* <Grid item lg={6} md={6} sm={6} xs={6}>
          <button className='continue_btn'>Continue with <img src='/images/logo/startupindia_logo.png' alt='' /></button>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <button className='continue_btn'>Continue with <img src='/images/logo/Google_logo.png' alt='' /></button>
        </Grid> */}
                <Grid item xs={12} className='mt-5'>
                    <div className='signup-data_div'>
                        <div className='signup-title'>
                            <p>Signup - Verify OTP</p>

                        </div>
                        <div className="text-center">
                            <span >Please input the OTP sent to your registered email and whatsapp.</span>
                        </div>
                        <div className='signup_box_div'>
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <Grid container spacing={1}>
                                    <Grid xs={12} className='py-1 mt-2'>
                                        <div className="base">
                                            <div className="otp-base">
                                                <OtpInput
                                                    value={otp}
                                                    onChange={(value) => {
                                                        handleCheckotp(value);
                                                    }}
                                                    numInputs={6}
                                                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                                                    shouldAutoFocus={true}
                                                    renderInput={(props) => <input {...props} autoFocus type='password' />
                                                    }
                                                />
                                            </div>
                                            <div className='py-3 text-center'>
                                                {
                                                    formValidate === true && (<FormHelperText className='text-center text-danger-white'>OTP is required</FormHelperText>)
                                                }
                                                {/* <p>{t('signin.didnotReceiveOtp')} <span className='resend_span' onClick={resenOtp}>{t('signin.resendOtp')}</span></p> */}
                                                <Timer resent={resenOtp} seconds={seconds} setSeconds={setSeconds} />
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} className='d-flex align-item-center'>
                                        <p className='mb-0'>Have an Account?- <Link className='c-blue' to={'/login'}>Login</Link></p>
                                        <button className='ml-auto getstart-btn' type="submit">Get Started</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {/* <Delegatefreepass /> */}
        </div>
    )
}

export default Verifyotp