import { Button, FormHelperText, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft } from "react-icons/fa6";
import DataContext from "../../../context/DataContext";
import notifyService from "../../../api/notifySerivce";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { useParams } from "react-router-dom";
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Viewimage from "../../../api/Viewimage";

const LatestNewsAdd = () => {
  const { id } = useParams();
  let latestnewsId;
  if (id !== undefined) {
    latestnewsId = atob(id);
  }
  const { navigator, setPageLoading, VisuallyHiddenInput } =
    useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  let departmentId = localStorage.getItem("departmentId");
  useDidMountEffect(() => {
    if (latestnewsId) {
      getApi(latestnewsId);
    }
  }, []);
  const [publishId, setPublishId] = useState(0)
  const getApi = (val) => {
    setPageLoading(true);
    apiService(`news/get?id=${val}`, "", "get")
      .then((result) => {
        setPageLoading(false);
        if (result?.data) {
          var data = result.data;
          if (data.publish) {
            setPublishBtn(data.publish)
          }
          if(data.id){
            setPublishId(data.id)
          }
          setFormData(data);
        }
      })
      .catch((err) => {
        console.log(err);

      });
  };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, fileUrl, format) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event
      } else {
        logoselecetdFile = event.target.files[0]
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            ((format === 'file') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "pdf")) ||
            ((format === 'image') && (img_crt_type === "jpeg" || img_crt_type === "jpg" || img_crt_type === "png"))
          ) {
            var currentImageName = getValues(fileName);
            if (currentImageName && currentImageName !== logoselecetdFile.name) {
              notifyService(
                "success",
                "Image Updated",
                "The image has been successfully changed."
              );
            }
            setValue(filekey, "");
            setValue(fileName, "");
            setValue(fileUrl, '')
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              // setUrl(logourl1)
              setValue(filekey, ImageValue);
              setValue(fileName, img_name);
              setValue(fileUrl, logourl1)
              clearErrors(filekey);
              clearErrors(fileName);
              clearErrors(fileUrl);
              // 
              // clearErrors('dpiitCertificateName')
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalided",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const handleClickOpen = (value, name) => {
    setViewImage(value);
    setViewImageName(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [publishBtn, setPublishBtn] = useState(false)
  const onSubmit = (data, isPublish = false) => {
    setPageLoading(true);
    console.log(data);
    data.departmentId = departmentId;
    data.publish = publishBtn
    apiService("news/save", data, "post")
      .then((result) => {
        setPageLoading(false);
        if (result?.data?.id) {
          setPublishId(result.data.id)
        }
        if (result?.data?.responseStatus === "Success") {
          // backBtn();
          if (isPublish === true) {
            publishEvent();
          }
          getApi(result.data.id)
        }
      })
      .catch((err) => {
        console.log(err);
        
       });
  };
  const onError = (event) => {
    console.log("error Data:", event);
    notifyService(
      "danger",
      "Submit Failed",
      "Please check the mandatory fields"
    );
  };
  const handlDiscard = () => {
    reset();
    navigator("admin/latestnews/list");
  };
  const backBtn = () => {
    reset();
    navigator("admin/latestnews/list");
  };
  const handlePublish = (e) => {
    e.preventDefault();
    const data = getValues();
    debugger
    onSubmit(data, true);
  };

  const publishEvent = () => {
    const req = { id: publishId, type: "news" };
    setPageLoading(true);
    apiService("publish", req, "post")
      .then((res) => {
        setPageLoading(false);
        if (res?.data?.responseStatus === "Success") {
          handlDiscard();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <main className="event_add_section">
      <div className="event_add_header mb-4">
        <div className="d-flex w-100 align-items-center">
          <div>
            <p className="back-btns mb-0" onClick={backBtn}>
              <FaArrowLeft />
              <span style={{ marginLeft: "5px" }}>Back</span>
            </p>
          </div>
          {latestnewsId ? (
            <div className="m-auto">
              <h4 className="font-color-black">{formData.title}</h4>
            </div>
          ) : (
            <div className="m-auto">
              <h4 className="font-color-black"> Create Latest News & Updates</h4>
            </div>
          )}
        </div>
      </div>
      <p className="font-color-black">Latest News & Update Details</p>
      <div className="">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="event_add_details_div mb-4">
            <Grid container spacing={3}>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className="input_background mb-4">
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Latest News Title is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Latest News Title *"
                        placeholder="Please Enter Latest News Title"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.title && errors.title.message}
                  </FormHelperText>
                </div>
                <div className="input_background mb-4">
                  <Controller
                    name="source"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Source Name is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Source Name *"
                        placeholder="Please Enter Source Name"
                        fullWidth
                        type="text"
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.source && errors.source.message}
                  </FormHelperText>
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                {/* <div className="input_background">
                  <Controller
                    name="fileName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Latest News Banner is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Upload Latest News Banner *"
                        placeholder="Upload Image"
                        fullWidth
                        type="text"
                        {...field}
                        disabled
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.fileName && errors.fileName.message}
                  </FormHelperText>
                  <div className="d-flex align-items-center w-100 my-3">
                    <p className="font-sm">
                      Recommended aspect ratio 4:3 <br />
                      Max size upto 5mb <br />
                      Accepted Format: jpeg, jpg, png
                    </p>
                    <label
                      className="ml-auto lbl_btn"
                      onChange={(e) => handleFileUpload(e, "file", "fileName")}
                      href="#file-upload"
                    >
                      <img
                        src="/images/admin/upload.png"
                        className="mr-3"
                        alt=""
                      />
                      Upload
                      <VisuallyHiddenInput type="file" />
                    </label>
                  </div>
                </div> */}
                {getValues('fileUrl') ? (
                  <>
                    <div className="input_background">
                      <p className="mb-3">Uploaded Latest News Banner<span className="text-danger ml-1">*</span></p>
                      <div className="row">
                        <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3 text-center">
                          <Controller
                            name="fileName"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Latest News Banner is required" }}
                            render={({ field }) => (
                              <Button
                                component="label"
                                variant="contained"
                                color="success"
                                {...field}
                                sx={{ width: '90%', fontSize: '11px', textTransform: 'none', padding: '8px 0px' }}
                                onChange={(e) =>
                                  handleFileUpload(e, "file", "fileName", "fileUrl", 'image')
                                }
                                startIcon={<CloudDoneIcon />}
                                href="#file-upload"
                              >
                                Uploaded ( Click to Change)
                                <VisuallyHiddenInput type="file" />
                              </Button>
                            )}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} className="mb-3">
                          <Button
                            className="c-pointer ml-2"
                            size="medium"
                            sx={{ width: '90%' }}
                            variant="outlined"
                            onClick={() =>
                              handleClickOpen(
                                getValues("file"),
                                getValues("fileUrl"),
                              )
                            }
                          >
                            Preview
                          </Button>
                        </Grid>
                      </div>
                      <div>
                        <span className="font-sm d-flex  mt-1 ">
                          Recommended aspect ratio 4:3<br />
                          Max size upto 5mb <br />
                          Accepted Format: jpeg, jpg, png
                          <span className="text-danger">*</span>
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="input_background">
                    <Controller
                      name="fileName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Latest News Banner is required" }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Upload Latest News Banner *"
                          placeholder="Upload Image"
                          fullWidth
                          type="text"
                          {...field}
                          disabled
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.fileName && errors.fileName.message}
                    </FormHelperText>
                    <div className="d-flex align-items-center w-100 my-3">
                      <p className="font-sm">
                        Recommended aspect ratio 4:3 <br />
                        Max size upto 5mb <br />
                        Accepted Format: jpeg, jpg, png
                      </p>
                      <label
                        className="ml-auto lbl_btn"
                        onChange={(e) =>
                          handleFileUpload(e, "file", "fileName", "fileUrl", 'image')
                        }
                        href="#file-upload"
                      >
                        <img
                          src="/images/admin/upload.png"
                          className="mr-3"
                          alt=""
                        />
                        Upload
                        <VisuallyHiddenInput type="file" />
                      </label>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="input_background mb-4">
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Latest News Description is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Latest News Description *"
                        placeholder="Please Enter Latest News Description"
                        fullWidth
                        type="text"
                        {...field}
                        multiline
                        rows={6}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.description && errors.description.message}
                  </FormHelperText>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="text-center">
            <Button className="discard-btn" onClick={() => handlDiscard()}>
              Discard
            </Button>
            <Button type="submit" className="publish-button">
              {publishBtn === false ? "Save as Draft" : "Save & Publish"}
            </Button>
            {(publishBtn === false  && publishId !== 0) && (<Button className="publish-button-draft" onClick={(e) => handlePublish(e)}>
              Publish
            </Button>)}
          </div>
        </form>
      </div>
      <Viewimage
        viewImage={viewImage}
        viewImageName={viewImageName}
        open={open}
        handleClose={handleClose}
      />
    </main>
  );
};

export default LatestNewsAdd;
