import { Button, FormHelperText, Grid, Icon, TextField, } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DataContext from "../../../../context/DataContext";
import apiService from "../../../../api/apiService";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import notifyService from "../../../../api/notifySerivce";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MuiFileInput } from "mui-file-input";
import Viewimage from "../../../../api/Viewimage";
const MoreinfoInnovators = () => {
  const { navigator, handleNextClick, isWhitespace, VisuallyHiddenInput } = useContext(DataContext);
  const [formData, setFormData] = useState({});
  const { handleSubmit, control, setValue, watch, clearErrors, getValues, formState: { errors } } = useForm({ values: formData });
  let role = localStorage.getItem("role");
  useDidMountEffect(() => {
    handleNextClick(80);
    getApi();
  }, []);
  const getApi = () => {
    var id = localStorage.getItem("id");
    var type = localStorage.getItem('type')
    apiService(`userprofile/get?persona=${type}&userid=${id}`, "", "get")
      .then((result) => {
        if (result && result.data) {
          var data = result.data;
          setFormData(data);
          data.patents.forEach(element => {
            if (element.documentName) {
              element.documentUrl = element.documentName
            }
          });
        }
      })
      .catch((err) => { });
  };
  let logoselecetdFile = "";
  const handleFileUpload = (event, filekey, fileName, arrayName, index) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      if (logoselecetdFile) {
        if (logoselecetdFile.size < maxFileSize) {
          var reader = new FileReader();
          var imagetype = logoselecetdFile.type;
          var imagedatatype = imagetype.split("/");
          var img_crt_type = imagedatatype[1];
          if (
            img_crt_type === "jpeg" ||
            img_crt_type === "jpg" ||
            img_crt_type === "png"
          ) {
            var fileValue = logoselecetdFile;
            reader.readAsDataURL(logoselecetdFile);
            reader.onload = () => {
              var logourl1 = reader.result;
              var spl = logourl1.split(",");
              var ImageValue = spl[1];
              var img_name = fileValue.name;
              if (arrayName !== "") {
                setValue(`${arrayName}.${index}.${filekey}`, ImageValue);
                setValue(`${arrayName}.${index}.${fileName}`, img_name);
                clearErrors(`${arrayName}.${index}.${filekey}`);
                clearErrors(`${arrayName}.${index}.${fileName}`);
              } else {
                setValue(filekey, ImageValue);
                setValue(fileName, img_name);
                clearErrors(filekey);
                clearErrors(fileName);
              }
            };
          } else {
            notifyService(
              "danger",
              "File Format Invalid",
              "Please check your file format"
            );
          }
        } else {
          notifyService(
            "danger",
            "File Size Exceeded",
            `Maximum file size allowed is 5 MB`
          );
        }
      }
    };
  }
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const [viewImageName, setViewImageName] = useState("");
  const handleClickOpen = (value, name) => {
    setViewImage(value);
    setViewImageName(name);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [showTextField, setShowTextField] = useState(false);
  const handleRadioChange = (event) => {
    setShowTextField(event.target.value);
    setValue("patented", event.target.value);
    clearErrors("patented");
    if (event.target.value === true) {
      addBenefitsDetailsBtn()
    } else {
      benefitsRemove();
    }
  };
  const {
    fields: benefitsFields,
    append: benefitsAppend,
    remove: benefitsRemove,
  } = useFieldArray({ control, name: "patents" });
  const addBenefitsDetailsBtn = () => {
    benefitsAppend({
      id: 0,
      patentName: "",
      document: "",
      documentName: "",
      documentUrl: "",
    });
  };
  const removeBenefitsDetailsBtn = (index) => {
    var id = getValues(`patents.${index}.id`);

    if (id !== 0) {

      apiService(`user/remove/patent?id=${id}`, '', 'delete').then((result) => {
        if (result && result.data) {
          benefitsRemove(index);
          getApi()
        }
      }).catch((err) => {
      });
    } else {
      benefitsRemove(index);
    }
    if (index === 0) {
      addBenefitsDetailsBtn()
    }
  };
  const educationalInstitution = watch("educationalInstitution", false);
  const handleFilter2 = (event) => {
    const iseducationalInstitution = event.target.value === "true";
    setValue("educationalInstitution", iseducationalInstitution);
    if (!iseducationalInstitution) {
      setValue("educationalInstitution", "");
    }
  };
  const studiesCheck = watch("studiesCheck", false);
  const handleFilter4 = (event) => {
    const isstudiesCheck = event.target.value === "true";
    setValue("studiesCheck", isstudiesCheck);
    if (!isstudiesCheck) {
      setValue("studiesCheck", "");
    }
  };
  const patents = watch("patented", false);
  const handleFilter1 = (event) => {
    const ispatents = event.target.value === "true";
    setValue("patented", ispatents);
    // if (!ispatents) {
    //   setValue("patented", false);
    // }
    // setValue("patented", event.target.value);
    clearErrors("patented");
    if (ispatents === true) {
      addBenefitsDetailsBtn()
    } else {
      benefitsRemove();
    }
  };
  const onSubmit = (data) => {
    data.techTransfer = data.techTransfer === 'true' ? true : false
    console.log("Form Data:", data);
    apiService('userprofile/save', data, "post")
      .then((result) => {
        if (result.data.responseStatus === "Success") {
          if (role === "RANDD-AGENCY") {
            navigator("/innovators/founder");
          } else {
            navigator("/innovators/quick-brief");
          }
        }
      })
      .catch((err) => { });
  };
  const onError = (event) => {
    console.log('error Data:', event);
    notifyService('danger', 'Submit Failed', 'Please check the mandatory fields')
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="signup-data_div">
            <div className="forms-title d-flex">
              <p>
                Organization Info {`>`}{" "}
                <span className="path_sapn">More Info</span>
              </p>
              <p className="ml-auto">
                <span className="count_num">4</span>/5
              </p>
            </div>
            <div className="signup_box_div mt-4">
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="patented"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl component="fieldset" className="d-flex">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Do you have any patents?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            {...field}
                            value={patents ? "true" : "false"}
                            onChange={handleFilter1}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="Yes"
                              // onChange={handleRadioChange}
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {patents && (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <div className="d-flex align-items-center">
                        <p className="point_Lable">
                          List out the patents
                          <span className="c-red">*</span>{" "}
                        </p>
                        <div className="ml-auto">
                          <Icon
                            color="primary"
                            sx={{ fontSize: 40, cursor: "pointer" }}
                            onClick={addBenefitsDetailsBtn}
                          >
                            add_circle
                          </Icon>
                        </div>
                      </div>
                      {benefitsFields.map((field, index, type) => (
                        <div className="row align-items-center set-add-box" key={field.id}>
                          <div className="col-lg-10 col-md-8 col-12 p-0">
                            <div className="form-groups">
                              <Controller
                                name={`patents.${index}.patentName`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  required:
                                    "List out the patents is required",
                                }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="List out the patents* "
                                    fullWidth
                                    {...field}
                                    defaultValue=""
                                    error={Boolean(errors.patents?.[index]?.patentName && errors.patents?.[index]?.patentName)}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.patents?.[index]?.patentName && errors.patents?.[index]?.patentName.message}
                              </FormHelperText>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4 col-12">
                            <Button
                              className="red_btn"
                              onClick={() =>
                                removeBenefitsDetailsBtn(index)
                              }
                            >
                              <DeleteForeverIcon />
                            </Button>
                          </div>
                          <div className="col-lg-10 col-md-8 col-12 p-0">
                            <div className="form-group file-name mt-2" key={index}>
                              {getValues(`patents.${index}.documentUrl`) ? (
                                <>
                                  <div className="row">
                                    <label className="ml-3">Upload Profile Image<span className="text-danger ml-1">*</span></label>
                                    <div className="col-lg-7 col-md-5 mb-3" >
                                      <Controller
                                        name={`patents.${index}.documentName`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Button
                                            component="label"
                                            variant="contained"
                                            {...field}
                                            fullWidth
                                            onChange={(e) =>
                                              handleFileUpload(
                                                e,
                                                "document",
                                                "documentName",
                                                "patents",
                                                index,
                                              )
                                            }
                                            startIcon={<CloudUploadIcon />}
                                            href="#file-upload"
                                          >
                                            Change
                                            <VisuallyHiddenInput type="file" />
                                          </Button>
                                        )}
                                      />
                                    </div>
                                    <div className="col-lg-5 col-md-5 mb-3">
                                      <Button
                                        className="view_doc_btn_document c-pointer"

                                        size="medium"
                                        variant="outlined"
                                        onClick={() =>
                                          handleClickOpen(
                                            getValues(`patents.${index}.document`),
                                            getValues(`patents.${index}.documentUrl`)
                                          )
                                        }
                                      >
                                        Preview
                                      </Button>
                                    </div>
                                  </div>
                                  <div>
                                    <span className="font-sm   mt-1 ">
                                      Maximum 5 mb allowed doc (Accepted Format: jpeg, jpg, png)
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <div className="form-group mt-2">
                                  <Controller
                                    name={`patents.${index}.documentName`}
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Profile Image is required" }}
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        label="Upload Profile Image"
                                        placeholder="Upload Profile Image"
                                        fullWidth
                                        type="text"
                                        {...field}
                                        disabled
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.patents?.[index]?.documentName && errors.patents?.[index]?.documentName.message}
                                  </FormHelperText>
                                  <div className='d-flex align-items-center w-100 my-3'>
                                    <p className='font-sm'>
                                      Recommended aspect ratio 4:3 <br />
                                      Max size upto 5mb <br />
                                      Accepted Format: jpeg, jpg, png</p>
                                    <label className='ml-auto lbl_btn'
                                      onChange={(e) =>
                                        handleFileUpload(
                                          e,
                                          "document",
                                          "documentName",
                                          "patents",
                                          index,
                                        )
                                      }
                                      href="#file-upload">
                                      <img src='/images/admin/upload.png' className='mr-3' alt='' />
                                      Upload Image
                                      <VisuallyHiddenInput type="file" />
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Grid>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="achievements"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Innovation achievements is required", validate: {
                          noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="Can you share any notable achievements or successes related to innovation lifecycle *"
                          placeholder="Can you share any notable achievements or successes related to innovation lifecycle"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.achievements && errors.achievements)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.achievements && errors.achievements.message}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="techTransfer"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl>
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            Do you require any support in terms of tech
                            transfer, from StartupTN?
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            {...field}
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {role === "INNOVATOR" || role === "RESEARCHER" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="challenges"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Will you be interested to take part in StartupTN's
                              open innovation challenges?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              {...field}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {role === "RANDD-AGENCY" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="marketPlace"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: false,
                        }}
                        render={({ field }) => (
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Will you be interested to showcase technogies
                              developed in StartupTN's Tech Marketplace?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              {...field}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {role === "RANDD-AGENCY" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <p>
                        {" "}
                        Are there any case studies or success stories you can
                        share that demonstrate your agency's capabilities?
                      </p>
                      <Controller
                        name="studiesCheck"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FormControl component="fieldset" className="d-flex">
                            <RadioGroup
                              {...field}
                              row
                              value={studiesCheck ? "true" : "false"}
                              onChange={handleFilter4}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.studiesCheck && errors.studiesCheck.message}
                      </FormHelperText>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {studiesCheck && (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <div>
                        <Controller
                          name="studies"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Success stories is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-multiline-static"
                              label="Studies / Success stories *"
                              fullWidth
                              {...field}
                              defaultValue=""
                              error={Boolean(errors.studies && errors.studies)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.studies && errors.studies.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                  )}

                  {role === "RANDD-AGENCY" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="challenges"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Will you be interested to host StartupTN's open
                              innovation challenges? *
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              {...field}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {role === "RESEARCHER" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <p>
                        Are you a part of any educational institution? If Yes,
                        please mention the name
                      </p>
                      <Controller
                        name="educationalInstitution"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FormControl component="fieldset" className="d-flex">
                            <RadioGroup
                              {...field}
                              row
                              value={educationalInstitution ? "true" : "false"}
                              onChange={handleFilter2}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.educationalInstitution &&
                          errors.educationalInstitution.message}
                      </FormHelperText>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {educationalInstitution && (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <div>
                        <Controller
                          name="institutionName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Institution Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              id="outlined-multiline-static"
                              label="Institution Name *"
                              fullWidth
                              {...field}
                              defaultValue=""
                              error={Boolean(errors.institutionName && errors.institutionName)}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.institutionName &&
                            errors.institutionName.message}
                        </FormHelperText>
                      </div>
                    </Grid>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                    <Controller
                      name="expectation"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Expectation is required", validate: {
                          noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          label="What is your expectation from StartupTN? *"
                          placeholder="What is your expectation from StartupTN?"
                          fullWidth
                          type="text"
                          {...field}
                          error={Boolean(errors.expectation && errors.expectation)}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.expectation && errors.expectation.message}
                    </FormHelperText>
                  </Grid>
                  {role === "RANDD-AGENCY" ? (
                    <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                      <Controller
                        name="technologies"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "List of technologies is required", validate: {
                            noWhitespace: value => !isWhitespace(value) || 'WhiteSpace not allowed'
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            id="outlined-multiline-static"
                            label="List out the technologies *"
                            fullWidth
                            multiline
                            rows={4}
                            {...field}
                            defaultValue=""
                            error={Boolean(errors.technologies && errors.technologies)}
                          />
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.technologies && errors.technologies.message}
                      </FormHelperText>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
                    <button className="next-btn" type="submit">
                      Next
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
      <Viewimage
        viewImage={viewImage}
        viewImageName={viewImageName}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};

export default MoreinfoInnovators;
