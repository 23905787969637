import React from 'react'
import './sesstiontimeout.css'
import { Button } from '@mui/material'
import useDidMountEffect from '../../../hooks/useDidMountEffect'
const Sesstiontimeout = () => {
    useDidMountEffect(() => {
        setTimeout(() => {
            logout()
        }, 500);
    }, [])
    const logout = () => {
        localStorage.clear()
        sessionStorage.clear()
        window.location.assign('/login')
    }
    return (
        <div className='sesstion_div'>
            <div className='text-center'>
                <img src='/images/timeout.gif' className='img-fluid gifimg' alt=''/>
                <h4>Session Timeout</h4>
                <p>You weren't clicking around any more, so we logged you out for security reasons. To get back in,
                    just login again</p>
                <div class="text-center mt-3">
                    <Button variant="contained" className='log_btn_sesstion' onClick={logout}>Login</Button>
                </div>
            </div>

        </div >
    )
}

export default Sesstiontimeout