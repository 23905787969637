import { Button, Container, Grid } from '@mui/material'
import React from 'react'
import Navbar from '../../../home-module/navbar-page/Navbar'
import { useState } from 'react'
import apiService from '../../../../api/apiService'
import useDidMountEffect from '../../../../hooks/useDidMountEffect'
import { useContext } from 'react'
import DataContext from '../../../../context/DataContext'
import { useNavigate } from 'react-router-dom'

const OpenInnovationHome = ({ name }) => {
    const { navigator } = useContext(DataContext);
    const navigate = useNavigate();
    useDidMountEffect(() => {
        getServiceApi()
    }, [])

    const [serviceData, setServiceData] = useState([])
    const getServiceApi = () => {
        var req = {
            "listSize": 25,
            "pageNumber": 1
        }
        apiService('smartcard/list', req, 'unauthpost').then((result) => {
            // setPageLoading(false)
            if (result && result.data) {
                if (result.data.responsemodelList) {
                    setServiceData(result.data.responsemodelList);
                }
            }
        }).catch((err) => {

        });
    }

    const handleChallenge = () => {
        navigator('/initiative/innovation/challenge')
    }

    const handlenow = () => {
        navigate('/login')
      }
    

    return (
        <div className="smartCard-page">
            <Navbar />
            <div className="radial-back-layer">
                <div className="round-layers"></div>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="banner-text">
                            <div className="sm-banner-chip-text">
                                <span>{`${name}   >  Open Innovation`}</span>
                                <h1 className="banner-text-gradient">Open Innovation</h1>
                                <h6>Tamil Nadu’s solution to Global solution</h6>
                                <div className="banner-content-actions">
                                    <button className="banner-viewbtn" onClick={handleChallenge} >Explore Challenge</button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="smart-card-info">
                <div className="grid-1-hacka">
                    <div className="hack-layer"></div>
                    <Container>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="left-side-smartcardPage mt-5">
                                    <h4>What is Open Innovation ?</h4>
                                    <p className="mt-3">
                                        Open Innovation Portal connects startups with industry-specific issues, accelerates the development
                                        of cutting-edge solutions, driving mutual growth and innovation for corporates and startups as well.
                                        Whether you are a corporate seeking fresh perspectives or a startup looking to solve impactful problems,
                                        open innovation portal is your gateway to meaningful collaboration and transformative success.
                                    </p>
                                </div>
                                <div className="left-content-actions pb-5">
                                    <button className="ser-viewbtn">Explore Challenge</button>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <div className="s2g-crd-img">
                                    <img
                                        className="img-fluid"
                                        src={"/images/human-mind 1.png"}
                                        alt="smart-card"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className='container mt-4'>
                    <div className="smartcrd-details-div">
                        <div className="service-partner-overlay"></div>
                        <div className="det-lines">
                            <div className="smcrd-services">
                                <h3>
                                    Our <span>Stakeholders</span>
                                </h3>
                                {/* <p>
                                    Over 100+ Service Partners Onboarded Successfully – Join the
                                    Revolution!"
                                </p> */}
                                <div className="partners-company-logos-scroll">
                                    <div className="partners-company-logos">
                                        {serviceData.map((logo, i) => (
                                            <div className="partners-company-sec" key={i}>
                                                <img
                                                    src={logo.companyLogoName}
                                                    alt={logo.subCategory}
                                                    className="part-logos"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className='bg-grid'>
                    <div className="container">
                        <center>
                            <div className="rec-box">
                                <div
                                    className="text-center"
                                //   data-aos="fade-up"
                                //   data-aos-delay="2100"
                                >
                                    <img
                                        alt=""
                                        src="/images/tnsdc-logo2 1.png"
                                        className="mar-top img-fluid ban-img"
                                    />
                                    <h1 className="rec-bx-head text-center mt-4">
                                        Requirements on Development of IT Tools
                                    </h1>
                                    <h4>
                                        <span className="bluee-clrr">End Date :</span>
                                        <span className="yellow-clr-sur font-weight-bold">
                                            {" "}
                                            15th July 2024
                                        </span>
                                    </h4>
                                    <button
                                        className="btn-sub text-center mb-3"
                                    >
                                        Explore challenges
                                    </button>
                                </div>
                            </div>
                        </center>
                    </div>
                </section>

                <div className="how-works-div">
                    <Container>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="center"
                            spacing={2}
                        >
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <div className="works-section mt-5">
                                    <h1 className='blueee-clrr'>Process Structure</h1>
                                    {/* <p>
                                        Hackathons have some clear advantages over traditional
                                        innovation processes. They promote multidisciplinary
                                        collaboration and have shorter innovation cycles that are
                                        better suited to address fast-changing startup ecosystem
                                    </p> */}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <div className="layer-bg-smcrd">
                                    <div className="works-mesh-layer"></div>
                                    <div className="how-works-details">
                                        <div className="sm-crd-process">
                                            <div className="sm-crd-process-steps">
                                                <div className="process-div">
                                                    <div>
                                                        <img
                                                            src={"/images/OIN-1.png"}
                                                            alt="registration"
                                                            className="process-img"
                                                        />
                                                    </div>
                                                    <div className="process-text">
                                                        <h3>
                                                            01 <span>Application Phase </span>
                                                        </h3>
                                                        <p>
                                                            Receiving applications from Innovators and Startups
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm-crd-process-steps">
                                                <div className="process-div">
                                                    <div>
                                                        <img
                                                            src={"/images/OIN-2.png"}
                                                            alt="signup"
                                                            className="process-img"
                                                        />
                                                    </div>
                                                    <div className="process-text">
                                                        <h3>
                                                            02 <span>Screening</span>
                                                        </h3>
                                                        <p>
                                                            Applications undergo screening based on predefined criteria
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm-crd-process-steps">
                                                <div className="process-div">
                                                    <div>
                                                        <img
                                                            src={"/images/OIN-3.png"}
                                                            alt="fill-application"
                                                            className="process-img"
                                                        />
                                                    </div>
                                                    <div className="process-text">
                                                        <h3>
                                                            03 <span>Demo Day Preparation</span>
                                                        </h3>
                                                        <p>
                                                            Shortlisted applicants are notified and prepared for the live pitch presentation
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm-crd-process-steps">
                                                <div className="process-div">
                                                    <div>
                                                        <img
                                                            src={"/images/OIN-4.png"}
                                                            alt="submit-application"
                                                            className="process-img"
                                                        />
                                                    </div>
                                                    <div className="process-text">
                                                        <div>
                                                            <h3>
                                                                04 <span>Demo Day</span>
                                                            </h3>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                Shortlisted applicants pitch their solutions to key stakeholders
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm-crd-process-steps">
                                                <div className="process-div">
                                                    <div>
                                                        <img
                                                            src={"/images/OIN-5.png"}
                                                            alt="track-application"
                                                            className="process-img"
                                                        />
                                                    </div>
                                                    <div className="process-text">
                                                        <h3>
                                                            05 <span>Winner Annoucement</span>
                                                        </h3>
                                                        <p>
                                                            The judging panel deliberates the winners, pave the way for potential support and collaboration.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <section className="">
                    <div className="rec-2-box">
                        <h1 className="white-clr text-center font-weight-bolder pt-5">
                            Unleash the innovation together
                        </h1>
                        <h4 className="white-clr text-center font-weight-bold mt-3">
                            CHALLENGES UNLOCKED
                        </h4>
                        <div className="ver-ani">
                            <div className=" mt-5">
                                <article>
                                    <span className="text-ani font-weight-bold">
                                        CORPORATES
                                        <br />
                                        GOVERNMENT
                                        <br />
                                        RESEARCH INSTITUTIONS
                                    </span>
                                </article>
                            </div>
                            <img
                                alt=""
                                src="/images/keyhole.png"
                                className="rec-2-img mt-4 img-fluid"
                            />
                            <div className="mt-5">
                                <article>
                                    <span className="text-ani1 font-weight-bold">
                                        INNOVATORS
                                        <br />
                                        STARTUPS
                                        <br />
                                        INNOVATORS
                                    </span>
                                </article>
                            </div>
                        </div>
                        <center>
                            <button className="bule-clr-button my-5">
                                Ready to Innovate!
                            </button>
                        </center>
                    </div>
                </section>

                <div className='join-us-now-sec'>
                    <Container>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="join-us-now-box">
                                    <div className="join-us-now-content">
                                        <h3>Let’s Get Started </h3>
                                        <p>MentorTN</p>
                                        <p>Right Mentor for the right founder!</p>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            className="join-now-btn"
                                            onClick={handlenow}
                                        >
                                            Join us Now
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default OpenInnovationHome