// import React from 'react'
// import { Link } from 'react-router-dom'

// const Eventsandbookings = () => {
//     return (
//         <div className="modal-box-his mt-3">
//             <div className="modal-img-his">
//                 <img src={'/images/historypage/Thiruvizha Web Banner (6) 1.png'} class="card-img-top" alt="..." />
//             </div>
//             <div className="ml-lg-3 w-100">
//                 <div className="d-flex">
//                     <p>Startup Thiruvizha (TN Startup Fest 2024)</p>
//                 </div>
//                 <div className="modal-location-his">
//                     Tammukam, Madurai
//                     <h6 className="event-time mt-2 ml-lg-4 ml-md-4 ml-0">
//                         Sep 28 & Sep 29, 2024  |   10:00 AM
//                     </h6>
//                 </div>
//                 <div className="reg-fees">
//                     <div className='d-flex'>
//                         <span className="event-free">₹ 1000</span>
//                         <div className="img-tops ml-2">
//                             <span>Conference Pass</span>
//                         </div>
//                     </div>

//                 </div>
//                 <div>
//                     <Link><p><span className="mr-2"><img src={'/images/historypage/Group 1707479650.png'} alt="..." /></span>Raise support ticket</p></Link>
//                 </div>
//             </div>

//         </div>
//     )
// }

// export default Eventsandbookings

import React from 'react';
import { Link } from 'react-router-dom';

const Eventsandbookings = () => {
  return (
    <div className="container-his mt-3">
      <div className="row  align-items-center justify-content-center his-event">
        <div className="col-lg-3 col-md-4 col-sm-12">
          <img src={'/images/historypage/Thiruvizha Web Banner (6) 1.png'} className="img-fluid" alt="Event banner" />
        </div>
        <div className="col-lg-9 col-md-8 col-sm-12">
          <div className="fest-his-head mb-2">
            <p className="event-tit m-0">Startup Thiruvizha (TN Startup Fest 2024)</p>
          </div>
          <div className="location-event-his mb-3">
            <span className="even-loca">Tammukam, Madurai</span>
            <h6 className="event-time ml-3">
              Sep 28 & Sep 29, 2024 | 10:00 AM
            </h6>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-center mb-3">
            <div className="rate-sec-His">
              <span className="event-amot  m-0">1000/-</span>
              <span className="conf-back ml-2">Conference Pass</span>
              <span className="conf-share-icn ml-2"> <img src={'/images/historypage/share.png'}/></span>
              <span className="conf-cale-icn ml-2"> <img src={'/images/historypage/cale.png'}/></span>
            </div>
          </div>
          <div className="d-flex">
            <Link to="#">
              <p className="sup-his-tick m-0">
                <img src={'/images/historypage/Group 1707479650.png'} alt="Support icon" className="Suppo-icon mr-2" />
               <span className='suppo-link'>Raise support ticket</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="row  align-items-center justify-content-center his-event mt-2">
        <div className="col-lg-3 col-md-4 col-sm-12">
          <img src={'/images/historypage/Thiruvizha Web Banner (6) 1.png'} className="img-fluid" alt="Event banner" />
        </div>
        <div className="col-lg-9 col-md-8 col-sm-12">
          <div className="fest-his-head mb-2">
            <p className="event-tit m-0">Startup Thiruvizha (TN Startup Fest 2024)</p>
          </div>
          <div className="location-event-his mb-3">
            <span className="even-loca">Tammukam, Madurai</span>
            <h6 className="event-time ml-3">
              Sep 28 & Sep 29, 2024 | 10:00 AM
            </h6>
          </div>
          <div className="d-flex flex-column flex-md-row align-items-center mb-3">
            <div className="rate-sec-His">
              <span className="event-amot  m-0">15000/-</span>
              <span className="conf-back ml-2">Designer Stall</span>
              <span className="conf-share-icn ml-2"> <img src={'/images/historypage/share.png'}/></span>
              <span className="conf-cale-icn ml-2"> <img src={'/images/historypage/cale.png'}/></span>
            </div>
          </div>
          <div className="d-flex">
            <Link to="#">
              <p className="sup-his-tick m-0">
                <img src={'/images/historypage/Group 1707479650.png'} alt="Support icon" className="Suppo-icon mr-2" />
               <span className='suppo-link'>Raise support ticket</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eventsandbookings;
