import React, { useState } from 'react'
import MeetingFilter from './MeetingFilter'
import { Grid } from "@mui/material";
import { Button, CardActionArea, CardActions, Container, Dialog, Paper, DialogContent, DialogContentText, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Menu } from "@mui/material";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
const MeetingReport = () => {
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [meetingsData, setMeetingsData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("This Week")
  const [sortBy, setSortBy] = useState(true)
  const [activemainTabs, setActiveMainTabs] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const MeetingReport = [
    {
      title: "Agenda Report 2024",
      startup: "EDII",
      data: "18 Mar, 24",
      download: "Download-(18.33 MB)"
    },
    {
      title: "Minutes Report 2024",
      startup: "StartupTN",
      data: "18 Mar, 24",
      download: "Download-(18.33 MB)"
    },
    {
      title: "Board Meeting Report 24",
      startup: "iTNT",
      data: "18 Mar, 24",
      download: "Download-(18.33 MB)"
    },
    {
      title: "Agenda Report 2024",
      startup: "EDII",
      data: "18 Mar, 24",
      download: "Download-(18.33 MB)"
    }
  ]
  return (
    <div>
      <MeetingFilter
        setSearchInputValue={setSearchInputValue}
        setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod}
        setSortBy={setSortBy} sortBy={sortBy}
        setDepartmentId={setDepartmentId}
        setActiveMainTabs={setActiveMainTabs} activemainTabs={activemainTabs}
      />

      <div className="event-datas-list py-5">
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className='mt-3'>
              <h6>Meeting Reports</h6>
            </div>
            <div className="table_main_div">
              <TableContainer component={Paper}>
                <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                  <TableBody sx={{ position: 'relative' }}>
                    {MeetingReport.map((list, index) => (
                      <>
                        <TableRow
                          sx={{
                            position: 'relative',
                            '&:after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              border: '1px solid rgba(224, 224, 224, 1)',
                              borderRadius: '20px',
                              pointerEvents: 'none',
                              marginBottom: '-1px',
                            },
                            '& > *': {
                              padding: '10px',
                              backgroundColor: '#fff',
                              '&:last-of-type': { borderTopRightRadius: '20px', borderBottomRightRadius: '20px' },
                            },
                            marginBottom: '10px',
                          }}
                          key={index}
                        >
                          <TableCell style={{ border: "none" }}>
                            <div className="px-3">

                              <div className="list-2-dflex">
                                <span className="meeting-report-title">{list.title}</span>

                              </div>
                              <div>
                                <span className="list-2-grey">{list.startup}</span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            <div className="events-date-box">
                              <div className=" mr-2">
                                <p className='m-0 list-2-grey'>Date</p>
                                <p className='m-0 meeting-report-dates'>{list.data}</p>
                              </div>

                            </div>
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            <div className="events-time-box mr-3">
                              <div className="">
                                <p className='list-2-grey'>

                                  Reports in PDF
                                </p>
                                <p className='report-blue'>

                                  {list.download}
                                </p>

                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>

              </TableContainer>
            </div>

          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
            <div className='mt-3'>
              <h6>Upcoming Meetings</h6>
            </div>
            <div className="meeting-pending-details">
              <h5 className="mb-4">Agenta Meeting 2024</h5>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={2}
              >
                <Grid item lg={6} md={6} xs={12} sm={12}>
                  <div>
                    <span>Start Time</span>
                    <p>24 Sep , 03.30 PM</p>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12} sm={12}>
                  <div>
                    <span>End Time</span>
                    <p>24 Sep , 06.30 PM</p>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12} sm={12}>
                  <div>
                    <span>Venue</span>
                    <p>Secretariat</p>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} xs={12} sm={12}>
                  <div>
                    <span>Department</span>
                    <p>OneTN</p>
                  </div>
                </Grid>
              </Grid>

              <div>
                <button className="add-calender-btn ">Add to Calender</button>
                <button className="meeting-join-btn ml-3">Join</button>
              </div>
            </div>
          </Grid>

        </Grid>
      </div>
    </div>
  )
}

export default MeetingReport