import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { Button, Container } from "@mui/material";
import Navbar from "../../navbar-page/Navbar";
import Footer from "../../footer-page/Footer";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";

const Hrtech = () => {
  const { name } = useParams();
  const navigator = useNavigate();
  const [value, setValue] = useState(name === 'initiatives' ? '1' : '2');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBackBtn = () => {
    navigator(`/ecosystem-info`);
  };
  return (
    <div>
      <Navbar />
      <div className="container-fluid flu-class">
        <div className="bg-img-tabss" style={{ background: `url('/images/sector-images/StartupTnSetorHRTech-Smart-Workforce.png')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'center' }}>
        <h3 className="banner-title-sector">HRTech & Smart Workforce</h3>
        </div>

        <div className="mt-5">
          <div className="back-blog">
            <div className="allticket-list">
              <Button className="bloggingss-btnss" onClick={handleBackBtn}>
                <FaArrowLeft />
                <span style={{ marginLeft: "5px" }}>Back</span>
              </Button>
            </div>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Container maxWidth="md">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="fullWidth"
                    textColor="inherit"

                    // TabIndicatorProps={{
                    //     sx: {
                    //         borderBottom: "3px solid #083fb2",
                    //     },
                    // }}
                    TabIndicatorProps={{
                      sx: {
                        height: 2,
                        backgroundColor: "#083fb2",
                        borderBottom: "3px solid #083fb2",
                        width: "100px",
                      },
                    }}
                  >
                    <Tab
                      label="Initiatives"
                      value="1"
                      className="resource-tab-style"
                      sx={{
                        color: value === "1" ? "#0a2a5b" : "#757575",
                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },
                      }}
                    />
                    <Tab
                      label="Resources"
                      value="2"
                      className="resource-tab-style"
                      sx={{

                        "&:hover": {
                          color: "#0a2a5b",
                        },
                        "&.Mui-selected": {
                          color: "#0a2a5b",
                          border: '1px solid lightgray'
                        },

                      }}
                    />

                  </Tabs>
                </Box>
              </Container>
              <TabPanel value="1" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Initiatives</h5>
                  <h5 style={{ color: "#39364e" }}>Union Government</h5>
                  <ul>
                    <li><b>National Career Service (NCS):</b> The Ministry of Labour and Employment launched the National Career Service (NCS) portal to provide a wide range of employment and career-related services. The portal includes features like job searching, career counselling and skill development, making use of technology to connect job seekers and employers.</li>
                    <li><b>Skill India:</b> The Skill India initiative aims to enhance the employability of India’s youth by providing them with skills and training. Various digital platforms and tools have been developed under this initiative to offer online courses and skill development programs.</li>
                    <li><b>Pradhan Mantri Kaushal Vikas Yojana:</b> The flagship Pradhan Mantri Kaushal Vikas Yojana (PMKVY) was launched in 2015 to provide short-term training, skilling through ITIs and under the apprenticeship scheme. Since 2015, the government has trained over 10 million youth under this scheme.</li>
                    <li><b>Digital India:</b> The Digital India programme includes various components aimed at enhancing digital literacy and technology adoption across the country. This can indirectly impact HR Tech as it promotes the use of digital platforms and services.</li>
                    <li><b>TEJAS Initiative for Skilling:</b> TEJAS (Training for Emirates Jobs and Skills), a Skill India International Project to train overseas Indias, was launched at the Dubai Expo, 2020. It aims at skilling, certification and overseas employment of Indians and creating pathways to enable the Indian workforce to get equipped for skill and market requirements in the UAE.</li>
                    <li>The Union Budget 2023 also mentions the launch of a new national recruitment agency to streamline the recruitment process for government jobs and a national skill mission to provide training and employment opportunities to the youth.</li>
                    <li>The Union Budget 2023 emphasises the upskilling and reskilling of government employees through the “Karm Yogi” initiative.</li>
                    <li><b>Tribal Welfare:</b> Centres for skill development have been set up at Udhagamandalam and Sankarapuram. Programmes include textiles, leather, hospitality, health, soft skills and language. The centres train 25,000 candidates each year. Free training is provided along with a monthly stipend and 50% of the candidates are assured employment. The total outlay for the programmes run by Tamil Nadu Adi Dravidar Housing Development Corporation (TAHDCO) is Rs. 25 crore.</li>
                    <li><b>Differently Abled Persons:</b>A number of schemes are in effect in 12 districts, including Chennai. Skill development for differently abled persons is provided by NGOs in the remaining districts. The total outlay for the scheme is Rs. 3,344.43 lakh. The courses vary in duration, between three months and two years, in fields such as medical technology, electronics, computers, teacher training, book binding, chalk training and weaving. Trainee strength varies from 10 to 456 across programmes.</li>
                    <li><b>Women and Child Welfare:</b> The Women and Child Welfare Department has set up teacher training institutes, where 40 candidates drawn from government homes are offered two-year courses. Twenty five tailor training centres have been set up in 19 districts for 500 trainees. Centres have been set up in Salem, Namakkal and Tiruvannamalai focusing exclusively on tribal women. Vocational education is also provided to 120 children from government homes under the Jan Shikshan Sansthan in courses such as tailoring, computers and beautician. Smaller schemes train children from government homes in batches of 10 in bakeries run by the Chennai Mission. Additionally, the Rajiv Gandhi National Institute of Youth Development at Sriperumpudur trains youth. The Department of Employment and Training runs the Community Polytechnic Scheme, which has 21 trainees in trades such as wire bag manufacture and knitting. Children/women discharged from government homes are provided vocational education and training in services and repair.</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>State Government</h5>
                  <ul>
                    <li><b>State Employment Exchange:</b> https://tnvelaivaaippu.gov.in/Empower/ is the State Employment Exchange portal that provides job-related services to job seekers and employers.</li>
                    <li><b>State Skill Development:</b> https://www.tnskill.tn.gov.in/ is the state government portal for skill development. Tamil Nadu Skill Development Corporation was formed with a vision to transform the State into a skill hub by skilling the youth to enhance their employability and match the expectations of the industry.</li>
                    <li><b>Naan Mudhalvan:</b> Naan Mudhalvan is a massive industry-relevant skill enhancement initiative of the State Government. The primary goal of the scheme is to identify, train and provide career and academic guidance to talented students. It also provides dynamic information for college students on courses and relevant information about industry-specific skill offerings.</li>
                    <li><b>Training and development programmes of State Government:</b> Study Circles functioning at the District Employment and Career Guidance Centres offer free coaching and guidance to aspirants of competitive exminations. Coaching classes are also conducted in universities and affiliated arts and science colleges for final year students to enable them take up competitive examinations.</li>
                  </ul>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "16px" }}>
                <div className="sector-page-bg">
                  <h5 style={{ color: "#39364e" }}>Resources</h5>
                  <h5 style={{ color: "#39364e" }}>As per the Startup India record the following are the incubators focusing on the HR industry.</h5>
                  <ul>
                    <li>JISIC Foundation, Chennai-Periyapalayam Highway, Tiruvallur, Tamil Nadu</li>
                    <li>Crescent Innovation and Incubation Council, Vandalur, GST Road, Chennai</li>
                    <li>MCC – MRF INNOVATION PARK, Chennai, Tamil Nadu</li>
                    <li>Forge Accelerator, Coimbatore, Tamil Nadu</li>
                    <li>Turiyam Bizlytics – Accelerator</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>HR Smart WorkForce Marketplace</h5>
                  <ul>
                    <li>Indeed – Job search India</li>
                    <li>Fiverr – Freelance Services Marketplace</li>
                    <li>LinkedIn – Leveraging Professional network</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Training institutes run by the State Government</h5>
                  <ul>
                    <li>Anna Administrative Staff College, Chennai</li>
                    <li>Civil Service Training Institute, Bhavanisagar</li>
                    <li>All India Civil Services Coaching Centre, Chennai</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>The State also has</h5>
                  <ul>
                    <li>2 – Central Universities</li>
                    <li>22 – State Universities</li>
                    <li>700 – Arts and Science Colleges</li>
                    <li>576 – Engineering Colleges</li>
                    <li>1552 – Industrial Training Institutes & Schools</li>
                    <li>501 – Polytechnic Institutes</li>
                    <li>27 – Medical Colleges</li>
                  </ul>
                  <h5 style={{ color: "#39364e" }}>Some of the premier institutes at Tamil Nadu are</h5>
                  <h5 style={{ color: "#39364e" }}>Indian Institute of Technology (IIT)-Madras</h5>
                  <p>Indian Institute of Technology-Madras is a top-ranked institution in the country and produces high quality engineers and scientists. It offers undergraduate, postgraduate and doctoral programmes, providing students with a comprehensive education in various engineering disciplines, sciences and technology-related fields.</p>
                  <h5 style={{ color: "#39364e" }}>Anna University</h5>
                  <p>Anna University located in Chennai is another prominent institution known for its excellence in engineering, technology and allied fields. Anna University’s emphasis on research and innovation has resulted in numerous patents and collaborations with industries. The institution’s close ties with the industrial sector have led to the development of practical skills among its students, making them highly employable. Additionally, the university has contributed significantly to research in areas like renewable energy, materials science and information technology.</p>
                  <h5 style={{ color: "#39364e" }}>National Institutes of Technology (NIT)</h5>
                  <p>The National Institute of Technology-Tiruchi is a premier engineering institution that offers undergraduate, postgraduate and doctoral programmes in various engineering disciplines. It is instrumental in producing engineers and technologists who contribute to the growth of India’s infrastructure and technology sectors. It has a strong focus on research and development, collaborating with industries and government organisations to address real world challenges.</p>
                  <p>The presence of these high-end institutions has enriched Tamil Nadu’s educational landscape and enhanced its global reputation in the field of higher education. These institutions not only produce highly skilled professionals but also drive innovation, research and technological advancements that benefit the world at large. They serve as the catalyst for economic growth and development, helping Tamil Nadu emerge as a knowledge and technology powerhouse.</p>
                  <h5 style={{ color: "#39364e" }}>Demographics</h5>
                  <p>68% of Tamil Nadu’s population is in the working age group of 15-59 years. Tamil Nadu, the 6th most populated State, is home to about 6% of the country’s total population. The State’s population is 72.2 mn. It has the third highest sex ratio among all States in India.</p>
                  <p>Tamil Nadu is home to the highest number of factories, with 38,800 units, along with the highest number of operational SEZs (50) in India. It has a well developed infrastructure with an excellent road and rail network. The State’s coastline is the second longest in India and has 4 major ports. With the State’s infrastructure suitable for both industry and trade and commerce and having well maintained educational infrastructure to bring in specialised, trained and educated youth, it serves as the major supply and demand ground for the workforce.</p>
                  <p>The presence of high-end educational institutions like IIT, Anna University, NIT and other deemed universities has contributed to the State’s growth and development of education, research and innovation.</p>
                </div>
              </TabPanel>

            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};


export default Hrtech
